import { memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';

import { styles, stylesForm } from '../styles';
import { IFieldProps } from '../types';

function CardCvcField({ stripe, elements, setCvcError, updateValidation, setAsyncError }: RequireAtLeastOne<IFieldProps, 'setCvcError'>) {
  stripe?.elements({
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Lato:300',
      },
    ],
  });

  useEffect(() => {
    const cardCode = elements?.create('cardCvc', {
      style: stylesForm,
      placeholder: 'CVV code',
    });

    cardCode?.mount('#card-cvc');

    cardCode?.on('change', function (event: StripeCardCvcElementChangeEvent) {
      updateValidation(event?.complete);
      setCvcError(event?.error?.message || '');
      setAsyncError(null);
    });
  });

  return (
    <Box data-id="cvv" sx={styles.card.wrap}>
      <label htmlFor="card-number-element" style={styles.card.label}>
        CVV
      </label>
      <Box style={styles.card.inputWrap}>
        <div id="card-cvc" />
      </Box>
    </Box>
  );
}

export default memo(CardCvcField);
