import { GetDependentByIdQuery, GetPartyByIdQuery, MemberRelationship } from '../../../../../../app/graphql/_generated/hooks';
import { extractContactInfo } from '../../NMEPrimaryMemberConfirmationPage/NMEPrimaryContactInfoConfirmationPage.utils';
import { DependentMemberContactInfo } from './DependentDetail.types';

export const extractDependentInfo = ({ member }: GetDependentByIdQuery): DependentMemberContactInfo => {
  return {
    ...extractContactInfo(member as GetPartyByIdQuery),
    id: member?._id ?? '',
    memberRelationship: member?.member_relationship ?? MemberRelationship.Undefined,
    partyId: member?.party?._id ?? '',
  };
};
