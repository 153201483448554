import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { getCurrentBillId } from '../../../features/bills/billsSlice';

const styles = {
  wrapper: {
    background: '#ffffff',
    padding: '0 16px',
    height: 'calc(100vh - 250px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {},
  title: {
    fontSize: '32px',
    fontWeight: '600',
    margin: '30px 0 15px',
    textAlign: 'center',
  },
  text: {
    fontSize: '15px',
    fontWeight: '400',
    margin: '0 0 25px 0',
    textAlign: 'center',
  },
  btn: { width: 'calc(100% - 32px)' },

  textBtn: {
    width: '100%',
    marginTop: '10px',
    textTransform: 'none !important',
  },
};

function AddSsnError() {
  const navigate = useNavigate();
  const currentBillId = useAppSelector(getCurrentBillId);

  return (
    <Box sx={styles.wrapper}>
      <Box component="img" alt="Warning!" src="/images/warning.svg" sx={styles.icon} />
      <Typography sx={styles.title}>Something Went Wrong</Typography>
      <Typography sx={styles.text}>
        We are unable to add a bank account at this time. Please add a card if you would like to continue.
      </Typography>
      {currentBillId ? (
        <>
          <Button
            sx={styles.btn}
            variant="outlined"
            onClick={() => {
              navigate('/payment-method/add/card');
            }}
          >
            Add Card
          </Button>
          <Button
            color="primary"
            variant="text"
            sx={styles.textBtn}
            onClick={() => {
              navigate(`/pay-bill/${currentBillId}`);
            }}
          >
            View bill
          </Button>
        </>
      ) : (
        <>
          <Button
            sx={{ ...styles.btn, marginBottom: '25px' }}
            variant="outlined"
            onClick={() => {
              navigate('/payment-method/add/card');
            }}
          >
            Add Card
          </Button>
          <Button
            sx={styles.btn}
            variant="text"
            onClick={() => {
              navigate('/');
            }}
          >
            Go Home
          </Button>
        </>
      )}
    </Box>
  );
}

export default AddSsnError;
