export const faqs = {
  paymentProcessing: {
    title: 'Payment Processing',
    items: [
      {
        id: 1,
        question: 'How can I pay my bill?',
        answer: 'Outstanding bills can be paid using a credit / debit card or a bank account that is registered in your name.',
      },
      {
        id: 2,
        question: 'How long does it take to process a payment?',
        answer:
          'Payments made using a credit / debit cards are authorized and processed immediately after payment is submitted. Payments made using a bank account are processed within 1-2 business days and will be placed in a pending status until processing is complete.',
      },
      {
        id: 3,
        question: 'Can I cancel a payment?',
        answer: () => (
          <span>
            Payments begin processing immediately after they are submitted and cannot be cancelled. If you need to request a refund, please
            send an email to <a href="mailTo:tellus@support.nomihealth.com">tellus@support.nomihealth.com</a> and a member of our team will
            assist you.
          </span>
        ),
      },
    ],
  },
  paymentsMethods: {
    title: 'Payments Methods',
    items: [
      {
        id: 4,
        question: 'Do I have to add a payment method before receiving services?',
        answer:
          'No, we encourage you to add a payment method beforehand to save yourself time during checkout, but it is not required. Payment methods can also be added during the bill payment process if that is preferred.',
      },
      {
        id: 5,
        question: 'Is it safe to save a payment method?',
        answer:
          'Yes, all card and bank account information is secured using encryption. We also actively monitor your account to identify unauthorized use.',
      },
    ],
  },
};
