export interface Coordinate {
  latitude: number;
  longitude: number;
}

export interface CoordinateWithZipCode extends Coordinate {
  zipCode: string;
}

export enum ResultsTab {
  Locations = 'locations',
  Providers = 'providers',
}
