import { Box, Divider, List, Stack, Typography } from '@mui/material';
import { styles } from './ProviderSearch.styles';
import { PracticeFacilityForDisplay } from './ProviderSearchPage.types';
import { useNavigate } from 'react-router-dom';
import { SpecialtyToTaxonomies } from '../../app/graphql/_generated/hooks';

interface PracticeFacilityListProps {
  activePracticeFacilityId: string;
  practiceFacilities: PracticeFacilityForDisplay[];
  zipCode: string;
  specialtyToTaxonomies: SpecialtyToTaxonomies | null;
  isPublicSearch?: boolean;
}

export default function PracticeFacilityList({
  activePracticeFacilityId,
  practiceFacilities,
  zipCode,
  specialtyToTaxonomies,
  isPublicSearch,
}: PracticeFacilityListProps) {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.listHeader}>Showing {practiceFacilities.length} results</Typography>
        <Divider />
      </Box>
      <List sx={styles.searchResultsContainer}>
        {practiceFacilities.map((practiceFacility, index) => {
          const detailsPageUrl = isPublicSearch
            ? `/public-practice-facility/${practiceFacility.id}`
            : `/practice-facility/${practiceFacility.id}`;

          return (
            <Box
              key={`${practiceFacility.name}-${practiceFacility.coordinates}-${index}`}
              onClick={() =>
                navigate(detailsPageUrl, {
                  state: {
                    searchState: {
                      zipCode,
                      specialtyToTaxonomies,
                      searchResults: practiceFacilities,
                    },
                  },
                })
              }
              sx={{ ...styles.listItemWrap, order: activePracticeFacilityId === practiceFacility.id ? '0' : '1' }}
              data-id={`practice-facility-${practiceFacility.id}`}
            >
              <Stack sx={styles.listItemContainer}>
                {activePracticeFacilityId === practiceFacility.id && <Box sx={styles.listItemActiveIndicator} />}
                <Typography sx={styles.listItemHeader}>{practiceFacility.name}</Typography>
                <Stack direction="row" alignItems="baseline">
                  <Stack>
                    <Typography sx={styles.listItemDetails}>{practiceFacility.firstAddressLine}</Typography>
                    <Typography sx={styles.listItemDetails}>{practiceFacility.secondAddressLine}</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: '1', flexShrink: '1' }} />
                  <Stack direction="row" spacing=".125rem">
                    <Typography sx={styles.listItemDistance}>~{practiceFacility.distanceInMiles}</Typography>
                    <Typography sx={styles.listItemDistance}>Mi.</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={styles.listDivider} />
            </Box>
          );
        })}
      </List>
    </>
  );
}
