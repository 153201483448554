import { Theme } from '@mui/material';

export const styles = {
  footer: {
    background: (theme: Theme) => theme.palette.common.white,
    padding: '1rem',
    width: '100%',
    borderTop: '1px solid #EFF1F4',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '25px',
  },
  optionTitle: {
    color: (theme: Theme) => theme.palette.custom.link,
    lineHeight: '1.25rem',
    fontSize: '.75rem',
    fontWeight: '700',
  },
  icon: {
    height: '26px',
    padding: '2px 20px 2px 20px',
  },
  fooTextWrap: {},
  fooText: {
    color: (theme: Theme) => theme.palette.text.primary,
    lineHeight: '1.25rem',
    fontSize: '.75rem',
    '> a': {
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  socLinks: {
    ml: '30px',
    '> a': {
      mr: '10px',
    },
  },
};
