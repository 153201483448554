import { Box, Typography, Link, useMediaQuery } from '@mui/material';
import { styles } from './Footer.styles';
import { LEGAL_LINKS, SOCIAL_LINKS } from '../../app/constants';

function Footer() {
  // change flex direction on mobile screens
  const isMobileView = useMediaQuery('(max-width: 600px)');

  return (
    <Box sx={[styles.footer, isMobileView && { flexDirection: 'column' }]}>
      <Box display="flex" flexDirection="column" alignItems={isMobileView ? 'center' : 'flex-start'}>
        <Box
          component="img"
          data-id="logo"
          alt="Logo"
          src="/images/new-logo.svg"
          sx={{ filter: 'invert(22%) sepia(12%) saturate(1176%) hue-rotate(182deg) brightness(35%) contrast(90%)', mb: '10px' }}
        />
        <Box display="flex" flexDirection={isMobileView ? 'column' : 'row'} alignItems={isMobileView ? 'center' : 'flex-start'}>
          <Typography sx={styles.fooText}>©{new Date().getFullYear()} Nomi Health. All Rights Reserved.</Typography>
          <Typography sx={styles.fooText}>
            {!isMobileView && ` | `}
            <Link target="blank" href={LEGAL_LINKS.privacy}>
              Privacy
            </Link>{' '}
            |{' '}
            <Link target="blank" href={LEGAL_LINKS.hipaa}>
              HIPAA
            </Link>{' '}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
        <Typography sx={styles.fooText}>Connect with us</Typography>
        <Box sx={styles.socLinks}>
          <Link target="blank" href={SOCIAL_LINKS.fb}>
            <Box component="img" data-id="facebook" alt="Facebook Logo" src="/images/fb.svg" />
          </Link>
          <Link target="blank" href={SOCIAL_LINKS.tw}>
            <Box component="img" data-id="twitter" alt="Twitter Logo" src="/images/tw.svg" />
          </Link>
          <Link target="blank" href={SOCIAL_LINKS.ln}>
            <Box component="img" data-id="LinkedIn" alt="LinkedIn Logo" src="/images/ln.svg" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
