import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { welcomeTitleSx } from '../../../NMEWelcomePage/WelcomePage.styles';
import { useGetDependentsQuery } from '../../../../../../app/graphql/_generated/hooks';
import { ModalSpinner } from '@nomi-health-inc/components-ui';
import { getCurrentUserPartyId } from '../../../../../../app/graphql/auth.utils';
import { Fragment, useMemo, useState } from 'react';
import { extractDependentsInfo } from './DependentsList.utils';
import { minorCallToActionTextSx } from '../../MemberContactInfoConfirmationForm/NMEMemberContactInfoConfirmationForm.styles';
import { NMEPrimaryActionButton } from '../../../../../../components/NMEActionButton';
import { Content, OnBoardingPageWrapper, stylesFromTheme } from '../../../styles';
import { ConfirmedDependent, IDependents } from './DependentsList.types';
import { DependentItem } from './DependentItem/DependentItem';
import { ROUTES } from '../../../../../../constants/routes';
import { NMEFloatingCard } from '../../../../../../components/NMEFloatingCard';
import { useNavigate } from 'react-router-dom';
import { sessionStorage } from '../../../../../../app/storageApi';
import { storage_keys } from '../../../../../../app/constants';

export function DependentsListPage() {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const navigate = useNavigate();

  const [dependents, setDependents] = useState<IDependents>([]);

  const { loading } = useGetDependentsQuery({
    variables: {
      member: {
        party_id: getCurrentUserPartyId(),
      },
    },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => setDependents(extractDependentsInfo(data)),
  });

  const isAllDependentConfirmed = useMemo(() => {
    return dependents.every((dependent) => {
      const confirmedDependents = sessionStorage.getObjValue<Array<ConfirmedDependent>>(storage_keys.confirmed_dependents);

      return confirmedDependents?.some((el) => el.id === dependent.id && el.isConfirmed);
    });
  }, [dependents]);

  return (
    <>
      <ModalSpinner loading={loading} text="Processing" />
      <OnBoardingPageWrapper>
        <Content>
          <Typography sx={welcomeTitleSx} component="h1">
            Confirm contact info
          </Typography>

          <NMEFloatingCard>
            <Typography fontSize="1.25rem" fontWeight={500}>
              Your Dependents
            </Typography>

            <DependentsContainer>
              {dependents.map(({ firstName, lastName, id, isConfirmed }, index) => (
                <Fragment key={id}>
                  <DependentItem
                    firstName={firstName}
                    isConfirmed={!!isConfirmed}
                    lastName={lastName}
                    onPress={() => navigate(`${ROUTES.dependents}/${id}`)}
                  />
                  {dependents.length - 1 !== index && <Divider />}
                </Fragment>
              ))}
            </DependentsContainer>
          </NMEFloatingCard>

          <Typography margin="16px 6px 0 6px" sx={minorCallToActionTextSx}>
            If your information has changed be sure to contact your HR department with updates.
          </Typography>
        </Content>

        <Stack sx={styles.footerSection}>
          <NMEPrimaryActionButton
            disabled={!isAllDependentConfirmed}
            data-id="confirmation-button"
            onClick={() => navigate(ROUTES.welcome)}
          >
            Continue
          </NMEPrimaryActionButton>
        </Stack>
      </OnBoardingPageWrapper>
    </>
  );
}

export const DependentsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 18,
  gap: 18,
}));

export const Divider = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.custom.darkBlue,
  height: 0.3,
}));
