import { useEffect } from 'react';

export function useFaviconAndTitle(newFaviconURL: string, newTitle: string) {
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

    link.href = newFaviconURL;
    document.title = newTitle;
  }, [newFaviconURL, newTitle]);
}
