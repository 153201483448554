import { Fragment, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import LoadingScreen from '../../pages/Loading';

import { useAuth } from '../../features/authV2/AuthContext';

const styles = {
  link: {
    fontSize: '16px',
    fontWeight: '500',
    width: '100%',
    padding: '14px 10px',
  },
  icon: {
    mb: -0.5,
    ml: 1,
  },
  menuItem: { padding: 0 },
};

interface MenuItem {
  onClick: () => void;
  render: string | JSX.Element;
}

export default function AccountMenu() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems: MenuItem[] = [
    {
      onClick: () => navigate('/payment-history'),
      render: 'Payment History',
    },
    {
      onClick: () => navigate('/contact-us'),
      render: 'Contact us',
    },
    {
      onClick: () => navigate('/faq'),
      render: 'Frequently Asked Questions',
    },
    {
      onClick: () => {
        setLoading(true);
        logout();
      },
      render: 'Log Out',
    },
  ];

  return (
    <Fragment>
      {loading && <LoadingScreen />}
      <Tooltip title="Account settings">
        <IconButton
          color="primary"
          onClick={handleClick}
          sx={{ paddingRight: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 12px rgba(66, 84, 124, 0.4))',
            mt: 0.5,
            borderRadius: 2.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map(({ onClick, render }: MenuItem) => (
          <MenuItem data-id="menu-item" key={render.toString()} sx={styles.menuItem}>
            <Link sx={styles.link} underline="none" onClick={onClick}>
              {render}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
