import { Box, Button } from '@mui/material';
import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import PageTitle from '../components/PageTitle';
import PaymentMethodSelect from '../components/partials/pay-method/PaymentMethodSelect';
import { setPaymentMethod } from '../features/paymentMethods/paymentMethodSlice';
import { useUserPaymentMethods } from '../utils/gqlResultsFormatters/useUserPaymentMethods.hook';
import LoadingScreen from './Loading';
import { storage_keys } from '../app/constants';

const styles = {
  btn: {
    width: '100%',
    marginTop: '50px',
  },
};

function SelectPaymentPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const savedPM = sessionStorage.getItem(storage_keys.default_payment_method);

  const { loading, paymentMethods } = useUserPaymentMethods();

  useEffect(() => {
    if (savedPM && paymentMethods?.length) {
      dispatch(setPaymentMethod(paymentMethods.find((payMethod) => payMethod?.account_id === savedPM)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle title="Select Payment Method" />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box
          sx={{
            padding: '0 16px',
            marginTop: '50px',
          }}
        >
          {paymentMethods?.length &&
            paymentMethods?.map((payMethod) => payMethod && <PaymentMethodSelect key={payMethod.account_id} payMethod={payMethod} />)}
          <Button
            data-id="add-pm-btn"
            color="primary"
            variant="contained"
            sx={styles.btn}
            onClick={() => {
              navigate('/payment-method/add', { replace: true });
            }}
          >
            Add Payment Method
          </Button>
        </Box>
      )}
    </>
  );
}

export default memo(SelectPaymentPage);
