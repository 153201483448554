import { Box, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const termsCheckboxContainerSx: SxProps<Theme> = {
  marginBottom: ({ spacing }: Theme) => spacing(3.5),
  alignItems: 'center',
  flexDirection: 'row',
};

export const TermsAndConditionsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const otpDeviceButtonSx: SxProps<Theme> = {
  marginTop: '20px',
};
