import { memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';

import { styles, stylesForm } from '../styles';
import { IFieldProps } from '../types';

function CardExpField({ stripe, elements, setExpError, updateValidation, setAsyncError }: RequireAtLeastOne<IFieldProps, 'setExpError'>) {
  stripe?.elements({
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Lato:300',
      },
    ],
  });

  useEffect(() => {
    const cardExp = elements?.create('cardExpiry', {
      style: stylesForm,
      placeholder: 'MM/YY',
    });

    cardExp?.mount('#card-exp');

    cardExp?.on('change', function (event: StripeCardExpiryElementChangeEvent) {
      updateValidation(event?.complete);
      setExpError(event?.error?.message || '');
      setAsyncError(null);
    });
  });

  return (
    <Box data-id="exp" sx={styles.card.wrap}>
      <label htmlFor="card-number-element" style={styles.card.label}>
        Expiration Date
      </label>
      <Box style={styles.card.inputWrap}>
        <div id="card-exp" />
      </Box>
    </Box>
  );
}

export default memo(CardExpField);
