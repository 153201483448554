import { useFeatureFlags } from './features/featureFlags/FeatureFlagsContext';
import NMEApp from './NMEApp';
import OldMemberExperienceApp from './OldMemberExperienceApp';
import { ROUTES } from './constants/routes';
import { useEffect } from 'react';

function App() {
  const { areShowingNewMemberExperience, setAreShowingNewMemberExperience } = useFeatureFlags();

  useEffect(() => {
    const initialPathLoaded = window.location.pathname.toLowerCase();

    if (initialPathLoaded === ROUTES.tellus.toLowerCase()) {
      setAreShowingNewMemberExperience(false);
      window.location.replace(ROUTES.root);
    } else if (initialPathLoaded === ROUTES.networks.toLowerCase()) {
      setAreShowingNewMemberExperience(true);
      window.location.replace(ROUTES.root);
    }
  }, [setAreShowingNewMemberExperience]);

  return areShowingNewMemberExperience ? <NMEApp /> : <OldMemberExperienceApp />;
}

export default App;
