import { GetPartyByIdQuery, IPartyContactFragment } from '../../../../../app/graphql/_generated/hooks';
import { IPrimaryMemberContactInfo } from './NMEPrimaryContactInfoConfirmationPage.types';

function isActive(contact: IPartyContactFragment) {
  if (!new Date(contact.effective_dates?.to_date ?? '')?.getTime()) {
    return true;
  }

  const currentMillisSinceEpoch = new Date().getTime();
  const expiryMillisSinceEpoch = new Date(contact.effective_dates?.to_date ?? '')?.getTime();

  return expiryMillisSinceEpoch > currentMillisSinceEpoch;
}

export const extractContactInfo = (data: GetPartyByIdQuery): IPrimaryMemberContactInfo => {
  const names = data?.party?.person?.current_name.names;
  const preferredName = data?.party?.person?.current_name.preferred_name;

  const primaryMemberContacts = data?.party?.contacts?.filter((c: IPartyContactFragment) => c.is_primary && isActive(c));

  const emailContact = primaryMemberContacts?.find((c: IPartyContactFragment) => c.contact?.email_address?.email);
  const email = emailContact?.contact?.email_address?.email;

  const addressContact: IPartyContactFragment | undefined = primaryMemberContacts?.find(
    (c: IPartyContactFragment) => c.contact?.postal_address
  );
  const address = addressContact?.contact?.postal_address;
  const fullStreetAddress = [address?.street_address1, address?.street_address2, address?.unit_number].filter((s) => s).join(' ');

  const city = address?.city_town;
  const state = address?.state_province;
  const zip = address?.postal_code;

  const phoneContact = primaryMemberContacts?.find((c: IPartyContactFragment) => c.contact?.phone_number?.phone);
  const phone = phoneContact?.contact?.phone_number?.phone;

  const dateOfBirth = data?.party?.person?.birth_date;

  return {
    firstName: names && names.length >= 1 ? names[0] : '',
    lastName: names && names.length >= 2 ? names[1] : '',
    preferredName: preferredName || undefined,
    email: email || '',
    mailingAddress: fullStreetAddress || '',
    city: city || '',
    state: state || '',
    zipCode: zip || '',
    phoneNumber: phone || '',
    dateOfBirth: dateOfBirth || '',
  };
};
