import { SxProps, Theme } from '@mui/material';

export const hospitalIconSx: SxProps<Theme> = {
  marginTop: '2rem',
  width: '50px',
  height: '40px',
};

export const emptyStateTitleSx: SxProps<Theme> = {
  marginTop: '1rem',
  fontWeight: '700',
};

export const emptyStateBlurbSx: SxProps<Theme> = {
  lineHeight: '22px',
};

export const emptyStateContactEmailSx: SxProps<Theme> = {
  marginTop: '1.75rem',
  color: (theme) => theme.palette.custom.veryDarkBlue,
};
