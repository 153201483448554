import { SxProps, Theme } from '@mui/material';

export const dialogSx: SxProps<Theme> = {
  '.MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
};

export const nomiLogoContainerSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '15rem',
  height: '15rem',
};
