import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface StyledContainerProps {
  areShowingNewMemberExperience: boolean;
}

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'areShowingNewMemberExperience',
})<StyledContainerProps>(({ theme, areShowingNewMemberExperience }) => {
  const { darkBlue, textColor } = theme.palette.custom;
  const { inputBackground } = theme.palette.form;
  const headerHeightCompensation = 76 / 2; // 76px - header height

  if (areShowingNewMemberExperience) {
    return {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      flexGrow: 1,
      backgroundImage: `url("/images/header-pattern.svg"),
  linear-gradient(to bottom, ${darkBlue} 0%, ${darkBlue} calc(50% - ${headerHeightCompensation}px), white calc(50% - ${headerHeightCompensation}px), white 100%)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right top',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url("/images/header-pattern.svg"),
  linear-gradient(to bottom, ${darkBlue} 0%, ${darkBlue} 40%, white 40%, white 100%)`,
      },
    };
  } else {
    return {
      minHeight: 'calc(100vh - 110px)',
      flexGrow: 1,
      backgroundImage: `url("/images/header-pattern.svg"),
  linear-gradient(to bottom, ${textColor} 0%, ${textColor} 45%, ${inputBackground} 45%, ${inputBackground} 100%)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right top',
      width: '100%',
    };
  }
});

export const StyledContentWrap = styled(Box)(() => ({
  maxWidth: 'calc(600px + 6rem)',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flex: 1,
}));
