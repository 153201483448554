import { Box, Link, Stack, Typography } from '@mui/material';
import { Condition } from '@nomi-health-inc/components-ui';
import { detailHeaderSx, detailLinkSx, detailValueSx, iconContainerSx } from './ContactDetail.styles';

interface ContactDetailProps {
  contact: {
    type: string;
    values: (string | undefined)[];
    iconPath: string;
    iconAlt: string;
  };
}

export function ContactDetail({ contact }: ContactDetailProps) {
  const getContactComponent = (value: string | undefined, index: number) => {
    const key = `${contact.type}-${index}`;

    if (value && contact.type === 'Phone') {
      return (
        <Link key={key} href={'tel:' + value} sx={detailLinkSx}>
          {value}
        </Link>
      );
    } else if (value && contact.type === 'Website') {
      return (
        <Link key={key} href={value} rel="noreferrer" target="_blank" sx={detailLinkSx}>
          {value}
        </Link>
      );
    }

    return (
      <Typography key={key} sx={detailValueSx}>
        {value || '-'}
      </Typography>
    );
  };

  return (
    <Stack direction="row" alignItems="flex-start">
      <Stack flex="39" direction="row" alignItems="flex-start">
        <Box component="img" alt={contact.iconAlt} src={contact.iconPath} sx={iconContainerSx} />
        <Typography sx={detailHeaderSx}>{contact.type}</Typography>
      </Stack>
      <Stack flex="61">
        <Condition when={contact.values.length === 0}>
          <Typography sx={detailValueSx}>-</Typography>
        </Condition>
        <Condition when={contact.values.length > 0}>{contact.values?.map((value, index) => getContactComponent(value, index))}</Condition>
      </Stack>
    </Stack>
  );
}
