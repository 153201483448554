import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import type { MapRef } from 'react-map-gl';
import { CoordinateWithZipCode, ResultsTab } from './NewProviderSearchPage.types';
import NewProviderSearchMap from './NewProviderSearchMap';
import { latLongOfOklahomaCityZipCode, zipCodeInOklahomaCity } from '../ProviderSearchPage.consts';
import NewProviderSearchForm, { IProviderSearchForm } from './NewProviderSearchForm';
import {
  beneathMapSectionSx,
  boldInfoText,
  floatingCardSx,
  infoBoxSx,
  infoTextSx,
  providerSearchContainerSx,
} from './NewProviderSearch.styles';
import { Condition } from '@nomi-health-inc/components-ui';
import NewProviderSearchResults from './ResultsList/NewProviderSearchResults';
import { useSpecialtiesInNetwork } from '../useSpecialtiesInNetwork';
import { ComponentBeingDisplayed, LocationListElement, ProviderListElement } from './NewProviderSearch.types';
import { NewFRNProviderDetails } from './Details/NewFRNProviderDetails';
import { useNewPublicProviderSearch } from './useNewProviderSearch';
import { ZIP_CODE_WITH_SURROUNDINGS_ZOOM_LEVEL } from './NewProviderSearch.consts';
import { NewCCNProviderDetails } from './Details/NewCCNProviderDetails';
import { NewFRNLocationDetails } from './Details/NewFRNLocationDetails';
import { NewCCNLocationDetails } from './Details/NewCCNLocationDetails';
import { AnimatedNomiLogoLoadingModal } from '../../LinearLoadingModal/AnimatedNomiLogoLoadingModal';
import { getUserLatCoordinateWithZipCode } from './NewProviderSearch.utils';

export function NewProviderSearch() {
  const [componentBeingDisplayed, setComponentBeingDisplayed] = useState(ComponentBeingDisplayed.SearchForm);
  const [resultsTabBeingDisplayed, setResultsTabBeingDisplayed] = useState<ResultsTab>(ResultsTab.Locations);
  const [activePracticeFacilityId, setActivePracticeFacilityId] = useState<string | null>(null);
  const [activeProviderId, setActiveProviderId] = useState<string | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const [selectedProviderDetails, setSelectedProviderDetails] = useState<ProviderListElement | null>(null);
  const [areLoadingSearchResults, setAreLoadingSearchResults] = useState<boolean>(false);
  const [practiceFacilitySearchResults, setPracticeFacilitySearchResults] = useState<LocationListElement[]>([]);
  const [providerSearchResults, setProviderSearchResults] = useState<ProviderListElement[]>([]);
  const [isGroupIDForCCNNetworkOnly, setIsGroupIDForCCNNetworkOnly] = useState<boolean>(false);
  const [wasGroupIdNotFound, setWasGroupIdNotFound] = useState<boolean>(false);

  const mapRef = useRef<MapRef | null>(null);
  const [currentUserPosition, setCurrentUserPosition] = useState<CoordinateWithZipCode | null>(null);
  const [previousSearchParameters, setPreviousSearchParameters] = useState<IProviderSearchForm | null>(null);

  const areInIframe = window !== window.parent;
  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const areOnVerySmallMobileDevice = useMediaQuery('(max-height:709px)');
  const areOnSmallMobileDevice = useMediaQuery('(max-height:764px)');
  const areOnMediumMobileDevice = useMediaQuery('(max-height:859px)');

  const percentageHeightOfMap = () => {
    if (!areOnMobileDevice) {
      return 100;
    }

    if (areOnVerySmallMobileDevice) {
      return 25;
    }

    if (areOnSmallMobileDevice) {
      return 30;
    }

    if (areOnMediumMobileDevice) {
      return 35;
    }

    return 41;
  };

  const { executeSearch } = useNewPublicProviderSearch();
  const { getSpecialtiesInNetwork, allSpecialtyToTaxonomies } = useSpecialtiesInNetwork();

  const handleSearchAgainButtonPress = useCallback(() => {
    setComponentBeingDisplayed(ComponentBeingDisplayed.SearchForm);
    setPracticeFacilitySearchResults([]);
    setProviderSearchResults([]);
    setActiveProviderId(null);
    setActivePracticeFacilityId(null);
  }, []);

  const handleLocationClick = useCallback(
    (locationID: string) => {
      const details = practiceFacilitySearchResults.find((r) => r.id === locationID);

      if (!details) {
        return;
      }

      setActivePracticeFacilityId(details.id);
      setSelectedLocationId(details.id);

      const selectedPracticeFacilityCoords = {
        lat: details.coordinates[1],
        lng: details.coordinates[0],
      };

      mapRef.current?.setCenter(selectedPracticeFacilityCoords);

      setComponentBeingDisplayed(
        isGroupIDForCCNNetworkOnly ? ComponentBeingDisplayed.CCNLocationDetails : ComponentBeingDisplayed.FRNLocationDetails
      );
    },
    [practiceFacilitySearchResults, isGroupIDForCCNNetworkOnly]
  );

  const handleProviderClick = useCallback(
    (providerID: string) => {
      const details = providerSearchResults.find((r) => r.id === providerID);

      if (!details) {
        return;
      }

      setActiveProviderId(details.id);
      setSelectedProviderDetails(details);

      const selectedProviderCoords = {
        lat: details.coordinates[1],
        lng: details.coordinates[0],
      };

      mapRef.current?.setCenter(selectedProviderCoords);

      setComponentBeingDisplayed(
        isGroupIDForCCNNetworkOnly ? ComponentBeingDisplayed.CCNProviderDetails : ComponentBeingDisplayed.FRNProviderDetails
      );
    },
    [providerSearchResults, isGroupIDForCCNNetworkOnly]
  );

  const handleBackFromDetailsComponent = useCallback(() => {
    setComponentBeingDisplayed(ComponentBeingDisplayed.SearchResults);
    setActiveProviderId(null);
    setActivePracticeFacilityId(null);
    setSelectedLocationId(null);
    setSelectedProviderDetails(null);
  }, []);

  const handleSearchFormSubmit = useCallback(
    async (data: IProviderSearchForm) => {
      let specialtyToTaxonomies = null;

      if (data.selectedSpecialtyName) {
        specialtyToTaxonomies = allSpecialtyToTaxonomies.find((entry) => entry.specialty === data.selectedSpecialtyName);

        if (!specialtyToTaxonomies || !specialtyToTaxonomies.taxonomies) {
          return;
        }
      }

      setWasGroupIdNotFound(false);
      setAreLoadingSearchResults(true);

      const sanitizedGroupID = data.groupID.replaceAll(/[ -]/g, '');

      const response = await executeSearch(data.zipCode, specialtyToTaxonomies, sanitizedGroupID, data.providerOrOfficeSearchTerm);

      if (!response) {
        setAreLoadingSearchResults(false);
        return;
      }

      if (response.wasGroupIdInvalid) {
        setWasGroupIdNotFound(true);
      } else {
        setPreviousSearchParameters({ ...data, groupID: sanitizedGroupID });
        setIsGroupIDForCCNNetworkOnly(response.wasGroupIdForCostContainmentNetwork);
        setProviderSearchResults(response.providers);
        setPracticeFacilitySearchResults(response.practiceFacilities);
        setResultsTabBeingDisplayed(
          !response.practiceFacilities.length && response.providers.length ? ResultsTab.Providers : ResultsTab.Locations
        );
        setComponentBeingDisplayed(ComponentBeingDisplayed.SearchResults);
      }

      setAreLoadingSearchResults(false);
    },
    [allSpecialtyToTaxonomies, executeSearch, setPreviousSearchParameters, setComponentBeingDisplayed]
  );

  const getPreviousSearchParameters = useCallback((): IProviderSearchForm | null => {
    return previousSearchParameters;
  }, [previousSearchParameters]);

  const handleCenterMapOnCurrentUserLocation = useCallback(() => {
    if (!currentUserPosition) {
      return;
    }

    mapRef.current?.setCenter({
      lat: currentUserPosition.latitude,
      lng: currentUserPosition.longitude,
    });
    mapRef.current?.setZoom(ZIP_CODE_WITH_SURROUNDINGS_ZOOM_LEVEL);
  }, [currentUserPosition]);

  const handleClickOfSearchResultOnMap = useCallback(
    (id: string) => {
      resultsTabBeingDisplayed === ResultsTab.Locations ? setActivePracticeFacilityId(id) : setActiveProviderId(id);
    },
    [resultsTabBeingDisplayed]
  );

  const clearSpecialtyAndExecuteSearchAgain = useCallback(async () => {
    if (!previousSearchParameters) {
      return;
    }

    setPreviousSearchParameters({
      ...previousSearchParameters,
      selectedSpecialtyName: '',
    });

    setAreLoadingSearchResults(true);

    const response = await executeSearch(
      previousSearchParameters.zipCode,
      null,
      previousSearchParameters.groupID,
      previousSearchParameters.providerOrOfficeSearchTerm
    );

    if (!response) {
      setProviderSearchResults([]);
      setPracticeFacilitySearchResults([]);
      setAreLoadingSearchResults(false);
      return;
    }

    const willCurrentTabHaveData =
      resultsTabBeingDisplayed === ResultsTab.Locations ? response.practiceFacilities.length > 0 : response.providers.length > 0;

    if (!willCurrentTabHaveData) {
      const doesOtherTabHaveData =
        resultsTabBeingDisplayed === ResultsTab.Locations ? response.providers.length > 0 : response.practiceFacilities.length > 0;

      if (doesOtherTabHaveData) {
        setResultsTabBeingDisplayed(resultsTabBeingDisplayed === ResultsTab.Locations ? ResultsTab.Providers : ResultsTab.Locations);
      }
    }
    setProviderSearchResults(response.providers);
    setPracticeFacilitySearchResults(response.practiceFacilities);

    setAreLoadingSearchResults(false);
  }, [executeSearch, resultsTabBeingDisplayed, previousSearchParameters]);

  useEffect(() => {
    getUserLatCoordinateWithZipCode().then((userLatLongWithZip) => {
      if (userLatLongWithZip) {
        setCurrentUserPosition(userLatLongWithZip);
      } else {
        setCurrentUserPosition({
          latitude: latLongOfOklahomaCityZipCode.lat,
          longitude: latLongOfOklahomaCityZipCode.lng,
          zipCode: zipCodeInOklahomaCity,
        });
      }
    });
  }, []);

  useEffect(() => {
    getSpecialtiesInNetwork(true);
  }, [getSpecialtiesInNetwork]);

  return (
    <Box sx={[providerSearchContainerSx, areInIframe && areOnMobileDevice && { minHeight: '740px' }]}>
      <AnimatedNomiLogoLoadingModal loading={areLoadingSearchResults} />
      <Box sx={{ height: `${percentageHeightOfMap()}%`, minHeight: `${percentageHeightOfMap()}%` }}>
        <NewProviderSearchMap
          selectedSearchResultId={resultsTabBeingDisplayed === ResultsTab.Locations ? selectedLocationId : selectedProviderDetails?.id}
          activeSearchResultId={resultsTabBeingDisplayed === ResultsTab.Locations ? activePracticeFacilityId : activeProviderId}
          chosenUserPosition={currentUserPosition}
          zipCodeBeingSearched={previousSearchParameters?.zipCode}
          mapRef={mapRef}
          searchResults={resultsTabBeingDisplayed === ResultsTab.Locations ? practiceFacilitySearchResults : providerSearchResults}
          setActiveSearchResultId={handleClickOfSearchResultOnMap}
        />
      </Box>
      <Condition when={!areOnMobileDevice}>
        <Box sx={infoBoxSx}>
          <Typography sx={infoTextSx}>
            Part of a High Deductible Plan? Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>
      <Box sx={[areOnMobileDevice ? beneathMapSectionSx(100 - percentageHeightOfMap()) : floatingCardSx]}>
        <Condition when={componentBeingDisplayed === ComponentBeingDisplayed.SearchForm}>
          <NewProviderSearchForm
            onFormSubmit={handleSearchFormSubmit}
            getPreviousSearchParameters={getPreviousSearchParameters}
            currentUserLocationZipCode={currentUserPosition?.zipCode || null}
            centerMapOnCurrentUserLocation={handleCenterMapOnCurrentUserLocation}
            providerSpecialties={allSpecialtyToTaxonomies || []}
            wasGroupIdInvalid={wasGroupIdNotFound}
          />
        </Condition>
        <Condition when={componentBeingDisplayed === ComponentBeingDisplayed.SearchResults}>
          <NewProviderSearchResults
            onSearchAgainButtonPress={handleSearchAgainButtonPress}
            specialtyBeingSearched={previousSearchParameters?.selectedSpecialtyName}
            onSpecialtyCleared={clearSpecialtyAndExecuteSearchAgain}
            zipCodeBeingSearched={previousSearchParameters?.zipCode || ''}
            resultsTabBeingDisplayed={resultsTabBeingDisplayed}
            onResultsTabChange={setResultsTabBeingDisplayed}
            activeLocationId={activePracticeFacilityId}
            activeProviderId={activeProviderId}
            locationList={practiceFacilitySearchResults}
            providerList={providerSearchResults}
            handleLocationClick={handleLocationClick}
            handleProviderClick={handleProviderClick}
            groupId={previousSearchParameters?.groupID || ''}
            areLoading={areLoadingSearchResults}
          />
        </Condition>
        <Condition when={componentBeingDisplayed === ComponentBeingDisplayed.FRNLocationDetails}>
          <NewFRNLocationDetails practiceFacilityId={selectedLocationId} onBackButtonClick={handleBackFromDetailsComponent} />
        </Condition>
        <Condition when={componentBeingDisplayed === ComponentBeingDisplayed.CCNLocationDetails}>
          <NewCCNLocationDetails practiceFacilityId={selectedLocationId} onBackButtonClick={handleBackFromDetailsComponent} />
        </Condition>
        <Condition when={componentBeingDisplayed === ComponentBeingDisplayed.FRNProviderDetails}>
          <NewFRNProviderDetails
            providerDetails={selectedProviderDetails}
            specialtyToTaxonomies={allSpecialtyToTaxonomies}
            onBackButtonClick={handleBackFromDetailsComponent}
          />
        </Condition>
        <Condition when={componentBeingDisplayed === ComponentBeingDisplayed.CCNProviderDetails}>
          <NewCCNProviderDetails details={selectedProviderDetails} onBackButtonClick={handleBackFromDetailsComponent} />
        </Condition>
      </Box>
    </Box>
  );
}
