import { Theme } from '@mui/material';

export const primaryActionButtonSx = (theme: Theme) => {
  return {
    marginBottom: '36px',
    height: '52px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '6rem',
      paddingRight: '6rem',
    },
  };
};
