import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserPartyId } from '../app/graphql/auth.utils';
import { useGetPaymentMethodsLazyQuery } from '../app/graphql/_generated/hooks';
import { useAppDispatch } from '../app/hooks';
import PageTitle from '../components/PageTitle';
import PaymentMethodRemove from '../components/partials/pay-method/PaymentMethodRemove';
import { PaymentMethod } from '../features/api/paymentMethods/types';
import { savePaymentMethods } from '../features/paymentMethods/paymentMethodSlice';
import { getPaymentMethodsQueryResultsFormatter } from '../utils/gqlResultsFormatters/paymentMethods';
import { useUserPaymentMethods } from '../utils/gqlResultsFormatters/useUserPaymentMethods.hook';
import LoadingScreen from './Loading';

const styles = {
  btn: {
    width: '100%',
    margin: '50px 0',
  },
  empty: {
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'italic',
  },
};

function AllPaymentsPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const party_id = getCurrentUserPartyId();
  const { paymentMethods: allPaymentMethods = [] } = useUserPaymentMethods() || [];

  const [getPaymentMethodsQuery, { loading }] = useGetPaymentMethodsLazyQuery({
    variables: { id: party_id },
    onCompleted: (result) => {
      const pmList = getPaymentMethodsQueryResultsFormatter(result) as PaymentMethod[];

      dispatch(savePaymentMethods(pmList));
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getPaymentMethodsQuery();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle backUrl="/" title="Payment Methods" />
      {loading && <LoadingScreen />}
      <Box
        sx={{
          padding: '0 16px',
          marginTop: '50px',
        }}
      >
        {allPaymentMethods.length ? (
          allPaymentMethods.map((payMethod) => payMethod && <PaymentMethodRemove key={payMethod?.account_id} payMethod={payMethod} />)
        ) : (
          <Typography sx={styles.empty}>No Payment Methods</Typography>
        )}
        <Button
          data-id="add-pm-btn"
          color="primary"
          variant="contained"
          sx={styles.btn}
          onClick={() => {
            navigate('/payment-method/add');
          }}
        >
          Add Payment Method
        </Button>
      </Box>
    </>
  );
}

export default AllPaymentsPage;
