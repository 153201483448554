import { createContext, useContext } from 'react';

export interface IRestrictedContext {
  checkIfUserHasAcceptedTerms: () => Promise<boolean>;
  checkIfUserHasAddedSsn: () => Promise<boolean>;
}

export const RestrictedContext = createContext<IRestrictedContext>({} as IRestrictedContext);

export const useRestricted = () => {
  return useContext(RestrictedContext);
};
