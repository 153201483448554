import { Box, Button, Checkbox, Grid, Link, Typography } from '@mui/material';
import { ModalSpinner } from '@nomi-health-inc/components-ui';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserPartyId } from '../../../app/graphql/auth.utils';
import { PolicyType, useAcceptPolicyMutation } from '../../../app/graphql/_generated/hooks';
import { clearAuthStorage, localStorage } from '../../../app/storageApi';
import GuttersContainer from '../../../components/GuttersContainer';
import { PageWrapper } from '../../../components/PageWrapper';
import { styles } from './styles';
import { useRestricted } from '../../../features/authV2/RestrictedContext';
import { storage_keys } from '../../../app/constants';

function TermsPage() {
  const navigate = useNavigate();
  const storedBillId = localStorage.getItem(storage_keys.bill_to_pay);
  const { checkIfUserHasAcceptedTerms } = useRestricted();
  const [checkingIfUserHasAcceptedTermsAlready, setCheckingIfUserHasAcceptedTermsAlready] = useState(true);
  const [checked, setChecked] = useState(false);
  const [acceptPolicy, { loading }] = useAcceptPolicyMutation({
    onError: () => {
      navigate('/error', {
        state: { text: 'Something Went Wrong', description: 'We encountered an error when creating your account.', action: 'go-back' },
      });
    },
  });

  useEffect(() => {
    checkIfUserHasAcceptedTerms().then((hasUserAcceptedTerms: boolean) => {
      if (hasUserAcceptedTerms) {
        if (storedBillId) {
          navigate(`/pay-bill/${storedBillId}`);
        } else {
          navigate('/');
        }
      }

      setCheckingIfUserHasAcceptedTermsAlready(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    const partyId = getCurrentUserPartyId();

    if (!partyId) {
      clearAuthStorage();
      navigate('/sign-in');
    }

    acceptPolicy({
      variables: {
        party_id: partyId,
        policy_type: PolicyType.PTermsAndConditions,
      },
      onCompleted: () => {
        localStorage.setItem(partyId, {
          ...JSON.parse(localStorage.getItem(partyId) as string),
          ...{ termsAgreement: true },
        });
        navigate('/');
      },
    });
  };

  return (
    <GuttersContainer container spacing={2}>
      <Grid item xs={12}>
        <PageWrapper>
          <Typography sx={styles.title} component="h1">
            Create Account
          </Typography>
          <Box sx={styles.contentBlock}>
            <Typography sx={styles.serviceInfo}>Terms & Conditions</Typography>
            <Box sx={{ mt: '25px', ml: '12px', w: 'max-content' }}>
              <Link data-id="policy" underline="none" target="_blank" sx={styles.link} href="https://nomihealth.com/privacypolicy">
                VIEW PRIVACY POLICY
                <Box component="img" alt="View Privacy Policy" src="/images/link-icon.svg" />
              </Link>
            </Box>
            <Box sx={{ w: 'max-content', ml: '12px' }}>
              <Link data-id="hipaa" sx={styles.link} href="https://nomihealth.com/hipaa" underline="none" target="_blank">
                VIEW HIPAA POLICY
                <Box component="img" alt="View HIPPA POLICY" src="/images/link-icon.svg" />
              </Link>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '30px 0 0 0' }}>
              <Checkbox checked={checked} onClick={() => setChecked(!checked)} />
              <Typography sx={{ fontSize: '16px' }}>
                I agree to the{' '}
                <Link data-id="terms" href="https://nomihealth.com/terms-and-conditions/" target="_blank" underline="none">
                  Terms & Conditions
                </Link>
              </Typography>
            </Box>
          </Box>
          <Button
            data-id="continue"
            onClick={() => onSubmit()}
            color="primary"
            disabled={!checked}
            variant="contained"
            sx={styles.continueBtn}
          >
            Continue
          </Button>
          <ModalSpinner loading={loading || checkingIfUserHasAcceptedTermsAlready} text="Processing" />
        </PageWrapper>
      </Grid>
    </GuttersContainer>
  );
}

export default TermsPage;
