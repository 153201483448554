import * as yup from 'yup';

export const BankAccSchema = yup
  .object({
    accType: yup.string().required('Account Type is required'),
    routingNumber: yup
      .string()
      .required('Routing Number is required')
      .length(9, 'Please enter a valid routing number')
      .matches(/^[0-9]+$/, 'Routing number must be only digits'),
    accNumber: yup
      .string()
      .required('Account Number is required')
      .min(8, 'Account Number can not be less then 8 digits')
      .max(17, 'Account Number can not be more then 17 digits')
      .matches(/^[0-9]+$/, 'Account Number must be only digits'),
    accNumber2: yup
      .string()
      .oneOf([yup.ref('accNumber'), null], 'Account Number must match')
      .required('Confirm Account Number is required'),
  })
  .required();
