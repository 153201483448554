/* eslint-disable no-console */
import jwtDecode from 'jwt-decode';
import { storage_keys } from '../constants';
import { authStorage } from '../storageApi';

export const getCurrentUserPartyId = (): string => {
  const token = authStorage.getItem(storage_keys.id_token);

  if (!token) {
    return '';
  }

  return getPartyIdFromToken(token);
};

export const getPartyIdFromToken = (token: string): string => {
  return getDataFromToken(token).party_id || '';
};

type JwtData = {
  party_id: string;
  email: string;
  exp: number;
};

export const getDataFromToken = (token: string): JwtData => {
  try {
    return jwtDecode<JwtData>(token);
  } catch (error) {
    console.error('Error parsing jwt token');
    console.error(error);

    return { party_id: '', email: '', exp: 0 };
  }
};
