import { MutableRefObject, useCallback, useState } from 'react';
import { Box, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import type { MapRef } from 'react-map-gl';
import Map, { Marker, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CURRENT_ENVIRONMENT } from '../../app/environments';
import { styles } from './ProviderSearch.styles';
import { Coordinate, PracticeFacilityForDisplay } from './ProviderSearchPage.types';
import { Condition } from '@nomi-health-inc/components-ui';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { DEFAULT_ZOOM_LEVEL, LIGHT_MAP_STYLE } from '../../constants/map';

function ProviderSearchMap({
  isPublicSearch,
  activePracticeFacilityId,
  centerMapOnUserLocation,
  chosenUserPosition,
  mapCenterCoordinate,
  mapRef,
  searchResults,
  setActivePracticeFacilityId,
  setMapCenterCoordinate,
}: {
  isPublicSearch: boolean;
  activePracticeFacilityId: string;
  centerMapOnUserLocation: () => void;
  chosenUserPosition: Coordinate | null;
  mapCenterCoordinate: Coordinate | null;
  mapRef: MutableRefObject<MapRef | null>;
  searchResults: PracticeFacilityForDisplay[];
  setActivePracticeFacilityId: (id: string) => void;
  setMapCenterCoordinate: ({ latitude, longitude }: { latitude: number; longitude: number }) => void;
}) {
  const navigate = useNavigate();

  const [mapZoomLevel, setZoomLevel] = useState<number>(DEFAULT_ZOOM_LEVEL);

  const navigateToHomeScreen = useCallback(() => {
    navigate(ROUTES.root);
  }, [navigate]);

  const handleMapDrag = useCallback(
    (event: ViewStateChangeEvent) => {
      setMapCenterCoordinate({ latitude: event.viewState.latitude, longitude: event.viewState.longitude });
    },
    [setMapCenterCoordinate]
  );

  const handleMapViewStateChange = useCallback(
    (event: ViewStateChangeEvent) => {
      setZoomLevel(event.viewState.zoom);
    },
    [setZoomLevel]
  );

  const handleLocateMeMapButton = useCallback(() => {
    if (chosenUserPosition) {
      setMapCenterCoordinate(chosenUserPosition);
      setZoomLevel(DEFAULT_ZOOM_LEVEL);
    } else {
      centerMapOnUserLocation();
    }
  }, [chosenUserPosition, centerMapOnUserLocation, setMapCenterCoordinate]);

  const isTabletOrDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  const shouldShowBackButton = !isPublicSearch;
  const shouldShowProviderSearchTitle = (isPublicSearch && !isDesktop) || (!isPublicSearch && isTablet);

  return (
    <Box sx={styles.columnContainer}>
      <Condition when={shouldShowProviderSearchTitle}>
        <Typography
          sx={[styles.titleText, styles.maxWidth, isPublicSearch ? { fontFamily: 'Harmonia Sans' } : { fontFamily: 'Wulkan Display' }]}
        >
          Provider Search
        </Typography>
      </Condition>
      <Box sx={[styles.mapContainer, isTabletOrDesktop ? styles.largeRoundedMap : styles.smallMapHeight]}>
        {mapCenterCoordinate && (
          <Map
            style={{ position: 'absolute', top: '0', bottom: '0', width: '100%' }}
            ref={mapRef}
            mapboxAccessToken={CURRENT_ENVIRONMENT.MAPBOX_API_TOKEN}
            longitude={mapCenterCoordinate.longitude}
            latitude={mapCenterCoordinate.latitude}
            onDrag={handleMapDrag}
            onZoomEnd={handleMapViewStateChange}
            mapStyle={LIGHT_MAP_STYLE}
            zoom={mapZoomLevel}
          >
            {chosenUserPosition && (
              <Marker key="userPosition" latitude={chosenUserPosition.latitude} longitude={chosenUserPosition.longitude}>
                <Box component="img" src="/images/map/user-dot-nomi-cyan.svg" />
              </Marker>
            )}
            {searchResults.length &&
              searchResults.map((practiceFacility, index) => {
                return (
                  <Marker
                    key={`marker-${practiceFacility.id}-${index}`}
                    latitude={practiceFacility?.coordinates[1]}
                    longitude={practiceFacility?.coordinates[0]}
                  >
                    <Box
                      component="img"
                      src={`/images/map/${activePracticeFacilityId === practiceFacility.id ? 'pin-active' : 'pin-default'}.svg`}
                      onClick={() => {
                        setActivePracticeFacilityId(practiceFacility.id);
                      }}
                    />
                  </Marker>
                );
              })}
          </Map>
        )}
        <Condition when={shouldShowBackButton}>
          <IconButton aria-label="back" onClick={navigateToHomeScreen} sx={styles.backButton}>
            <Box component="img" alt="Back" src="/images/back-arrow-icon.svg" />
          </IconButton>
        </Condition>
        <Box sx={styles.mapControlsBox}>
          <IconButton aria-label="map-user-location" onClick={handleLocateMeMapButton} sx={styles.userLocationButton}>
            <Box component="img" alt="map-user-location" src="/images/map/locator.svg" />
          </IconButton>
          <Box sx={styles.zoomBox}>
            <IconButton
              aria-label="zoom-decrease"
              onClick={() => setZoomLevel(mapZoomLevel >= 1 ? mapZoomLevel - 1 : 0)}
              sx={styles.zoomIcon}
            >
              <Box component="img" alt="zoom-decrease" src="/images/map/zoom-minus.svg" />
            </IconButton>
            <IconButton
              aria-label="zoom-increase"
              onClick={() => setZoomLevel(mapZoomLevel <= 24 ? mapZoomLevel + 1 : 25)}
              sx={styles.zoomIcon}
            >
              <Box component="img" alt="zoom-increase" src="/images/map/zoom-plus.svg" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ProviderSearchMap;
