import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, useTheme, FormHelperText } from '@mui/material';
import { DatePicker, Input } from '@nomi-health-inc/components-ui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MIN_DATE, NMEFirstNameAndDateOfBirthFormSchema } from './NMEFirstNameAndDateOfBirthFormSchema';
import { IFirstNameAndDateOfBirthFormData } from './NMEFirstNameAndDateOfBirthForm.types';
import { stylesFromTheme } from '../../styles';
import { NMEFloatingCard, NMEFloatingCardTitleStyled } from '../../../../../components/NMEFloatingCard';
import { NMEPageTitle } from '../../../../../components/NMEPageTitle';
import { useCallback, useEffect } from 'react';

interface IFirstNameAndDateOfBirthFormProps {
  onSubmit: (data: IFirstNameAndDateOfBirthFormData) => Promise<void>;
  shouldDisableSubmitButton: boolean;
  noConfirmDevices: boolean;
}

function NMEFirstNameAndDateOfBirthForm(props: IFirstNameAndDateOfBirthFormProps) {
  const { onSubmit, shouldDisableSubmitButton, noConfirmDevices } = props;
  const theme = useTheme();
  const styles = stylesFromTheme(theme);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors, isValid: isFormValid },
    setError,
  } = useForm<IFirstNameAndDateOfBirthFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(NMEFirstNameAndDateOfBirthFormSchema),
  });

  const onFirstNameAndDateOfBirthSubmit = useCallback<SubmitHandler<IFirstNameAndDateOfBirthFormData>>(
    async (data) => {
      try {
        await onSubmit(data);
      } catch (err) {
        if (!(err instanceof Error)) {
          return;
        }

        setError('root', {
          message: err.message,
        });
      }
    },
    [onSubmit, setError]
  );

  useEffect(() => {
    if (noConfirmDevices) {
      setError('root', { type: 'email', message: "The user doesn't have any confirmation methods, please contact support" });
    }
  }, [noConfirmDevices, setError]);

  return (
    <Box sx={styles.contentLoginWrap}>
      <Box sx={styles.contentLoginBlock}>
        <NMEPageTitle>Login</NMEPageTitle>
        <NMEFloatingCard>
          <NMEFloatingCardTitleStyled>
            In order to securely access your account, please provide the following information.
          </NMEFloatingCardTitleStyled>
          <Box sx={styles.input}>
            <Controller
              control={control}
              {...register('firstName')}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  sx={styles.input}
                  variant="filled"
                  label="First Name"
                  placeholder="First Name"
                  helperText={formErrors.firstName?.message}
                  error={!!formErrors.firstName}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box sx={styles.input}>
            <Controller
              control={control}
              {...register('dateOfBirth')}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  value={field.value ?? null}
                  fullWidth
                  size="small"
                  id="datepicker"
                  variant="filled"
                  label="Date of Birth"
                  error={!!formErrors.dateOfBirth}
                  errorMessage={formErrors.dateOfBirth?.message}
                  minDate={MIN_DATE}
                  maxDate={new Date()}
                />
              )}
            />
          </Box>

          {!!formErrors.root?.message && <FormHelperText error>{formErrors.root?.message}</FormHelperText>}
        </NMEFloatingCard>
      </Box>
      <Box sx={styles.footerSection}>
        <Button
          color="primary"
          variant="contained"
          sx={styles.continueButton}
          disabled={!isFormValid || shouldDisableSubmitButton}
          onClick={handleSubmit(onFirstNameAndDateOfBirthSubmit)}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default NMEFirstNameAndDateOfBirthForm;
