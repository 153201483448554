import axios from 'axios';
import {
  IAxiosErrorReason,
  ILoginRequestBody,
  ILoginResponseBody,
  IRequestOTPRequestBody,
  ISendOTPRequestBody,
  ISendOTPResponse,
  LoginResponseBodyWithStatus,
} from './types';
import { CURRENT_ENVIRONMENT } from '../../environments';

export const URLS = {
  login: `${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/auth/login`,
  logout: `${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/auth/logout`,
  requestOTP: (pingFlowId: string) => ` ${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/auth/${pingFlowId}/device`,
  sendOTP: (pingFlowId: string) => ` ${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/auth/${pingFlowId}/otp`,
};

export const newMemberExperienceLogin = async (
  email: string,
  firstName?: string,
  dateOfBirth?: string
): Promise<LoginResponseBodyWithStatus> => {
  const requestBody: ILoginRequestBody = {
    email,
    first_name: firstName,
    dob: dateOfBirth,
  };

  const response = await axios.post<ILoginResponseBody>(URLS.login, requestBody).catch(({ response }: IAxiosErrorReason) => {
    if (response.status === 409) {
      throw new Error('Account with email already exists');
    }

    if (response.status === 404) {
      throw new Error('Account does not exist');
    }

    throw new Error('Login unsuccessful');
  });

  if ((response.status !== 200 && response.status !== 202) || !response.data) {
    throw new Error('Login unsuccessful');
  }

  return { status: response.status, ...response.data };
};

export const requestOTP = async (pingFlowId: string, deviceId: string): Promise<void> => {
  const requestBody: IRequestOTPRequestBody = {
    device_id: deviceId,
  };

  await axios.post(URLS.requestOTP(pingFlowId), requestBody).catch(({ response }: IAxiosErrorReason) => {
    // eslint-disable-next-line no-console
    console.log('Error occurred during request of OTP to be sent', response);
  });
};

export const sendOTP = async (pingFlowId: string, otp: string): Promise<ISendOTPResponse> => {
  const requestBody: ISendOTPRequestBody = {
    otp,
  };

  const response = await axios.post<ISendOTPResponse>(URLS.sendOTP(pingFlowId), requestBody).catch(({ response }: IAxiosErrorReason) => {
    // eslint-disable-next-line no-console
    console.log('Error occurred during OTP post', response);
  });

  if (!response || response.status !== 200 || !response.data) {
    throw new Error('Incorrect passcode');
  }

  return response.data;
};
