import { GetDependentsQuery, GetPartyByIdQuery } from '../../../../../../app/graphql/_generated/hooks';
import { ConfirmedDependent, IDependents } from './DependentsList.types';
import { extractContactInfo } from '../../NMEPrimaryMemberConfirmationPage/NMEPrimaryContactInfoConfirmationPage.utils';
import { sessionStorage } from '../../../../../../app/storageApi';
import { storage_keys } from '../../../../../../app/constants';

export const extractDependentsInfo = (dependents: GetDependentsQuery): IDependents => {
  if (!dependents?.memberByFields?.dependent_members?.length) {
    return [];
  }

  return dependents?.memberByFields?.dependent_members?.map((dependent) => {
    const confirmedDependents = sessionStorage.getObjValue<Array<ConfirmedDependent>>(storage_keys.confirmed_dependents);

    return {
      ...extractContactInfo(dependent as GetPartyByIdQuery),
      id: dependent._id,
      isConfirmed: !!confirmedDependents?.find((item) => item.id === dependent._id && item.isConfirmed),
    };
  });
};
