import { useState } from 'react';
import { Box, Button, ButtonBase, Divider, Typography } from '@mui/material';
import { BankPaymentMethod, CardPaymentMethod } from '../BankPaymentMethod';
import CustomizedDialog from '../../Dialog';
import { PaymentMethod } from '../../../features/api/paymentMethods/types';
import { styles } from './styles';

import {
  GetPaymentMethodsDocument,
  useRemovePayMethodMutation,
  useRemoveStripePayMethodMutation,
} from '../../../app/graphql/_generated/hooks';
import { getCurrentUserPartyId } from '../../../app/graphql/auth.utils';
import LoadingScreen from '../../../pages/Loading';
import { useAppDispatch } from '../../../app/hooks';
import { setPaymentMethod } from '../../../features/paymentMethods/paymentMethodSlice';
import { storage_keys } from '../../../app/constants';

export interface PaymentMethodProps {
  payMethod: PaymentMethod;
}

function PaymentMethodRemove({ payMethod }: PaymentMethodProps) {
  const dispatch = useAppDispatch();
  const partyId = getCurrentUserPartyId();

  const [removePayMethod, { loading: removingBA }] = useRemovePayMethodMutation();
  const [removeStripePayMethod, { loading: removingStripe }] = useRemoveStripePayMethodMutation();

  let name = payMethod?.bank_account?.name;

  if (payMethod && payMethod.account_type === 'STRIPE_CUSTOMER') {
    name = payMethod ? payMethod?.brand : '';
  }

  const [open, setOpen] = useState(false);

  const handleClickRemove = () => {
    const accId = payMethod.account_id;

    if (accId === sessionStorage.getItem(storage_keys.default_payment_method)) {
      sessionStorage.removeItem(storage_keys.default_payment_method);
      dispatch(setPaymentMethod(null));
    }
    payMethod?.account_type === 'STRIPE_CUSTOMER'
      ? removeStripePayMethod({
          variables: { id: accId },
          refetchQueries: [{ query: GetPaymentMethodsDocument, variables: { id: partyId } }],
        })
      : removePayMethod({
          variables: { party_id: partyId || '', account_id: accId },
          refetchQueries: [{ query: GetPaymentMethodsDocument, variables: { id: partyId } }],
        });
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {(removingStripe || removingBA) && <LoadingScreen />}
      <Box sx={styles.wrapper}>
        {payMethod?.account_type === 'STRIPE_CUSTOMER' ? <CardPaymentMethod data={payMethod} /> : <BankPaymentMethod data={payMethod} />}
        {payMethod?.status === 'HOLD' ? (
          <Typography sx={styles.verificationText}>Verification in Progress</Typography>
        ) : (
          <ButtonBase data-id="remove-pm-btn" sx={styles.button} onClick={handleClickOpen}>
            Remove
          </ButtonBase>
        )}
      </Box>
      <Divider sx={{ m: '25px 0' }} />
      <CustomizedDialog data-id="confirm-dialog" open={open} onClose={handleClose} title={`Remove ${name}`}>
        <Typography sx={styles.confirmText}>Are you sure you want to remove this payment method from your account?</Typography>
        <Button data-id="remove-btn" color="error" variant="contained" fullWidth onClick={handleClickRemove}>
          Remove
        </Button>
        <Button data-id="cancel-btn" color="primary" variant="text" fullWidth onClick={() => handleClose()}>
          Cancel
        </Button>
      </CustomizedDialog>
    </Box>
  );
}

export default PaymentMethodRemove;
