import { Box, List, Stack, Typography } from '@mui/material';
import {
  listItemActiveIndicatorBarSx,
  listItemContainerSx,
  listItemDetailsSx,
  listItemDistanceSx,
  listItemHeaderSx,
  listItemWrapSx,
  searchResultsContainerSx,
} from './NewProviderList.styles';
import { ProviderListElement } from '../NewProviderSearch.types';
import { Condition } from '@nomi-health-inc/components-ui';
import { EmptyState } from './EmptyState';
import { useEffect } from 'react';

interface NewProviderListProps {
  activeProviderId: string | null;
  providerList: ProviderListElement[];
  onProviderClick: (providerId: string) => void;
}

export function NewProviderList({ activeProviderId, providerList, onProviderClick }: NewProviderListProps) {
  useEffect(() => {
    if (!activeProviderId) {
      return;
    }

    document.getElementById(activeProviderId)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }, [activeProviderId]);

  return (
    <List sx={searchResultsContainerSx}>
      <Condition when={providerList.length === 0}>
        <EmptyState />
      </Condition>
      <Condition when={providerList.length > 0}>
        {providerList.map((provider, index) => (
          <Box
            id={`${provider.id}`}
            key={`${provider.name}-${provider.coordinates}-${index}`}
            onClick={() => onProviderClick(provider.id)}
            sx={listItemWrapSx}
            data-id={`provider-${provider.id}`}
          >
            {activeProviderId === provider.id && <Box sx={listItemActiveIndicatorBarSx} />}
            <Stack sx={listItemContainerSx}>
              <Stack direction="row" alignItems="baseline">
                <Typography sx={listItemHeaderSx}>{provider.name}</Typography>
                <Box sx={{ flexGrow: '1', flexShrink: '1' }} />
                <Condition when={provider.distanceInMiles !== null}>
                  <Stack direction="row" spacing=".125rem">
                    <Typography sx={listItemDistanceSx}>~{provider.distanceInMiles}</Typography>
                    <Typography sx={listItemDistanceSx}>Mi.</Typography>
                  </Stack>
                </Condition>
              </Stack>
              <Stack>
                <Typography sx={listItemDetailsSx}>{provider.firstAddressLine}</Typography>
                <Typography sx={listItemDetailsSx}>{provider.secondAddressLine}</Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Condition>
    </List>
  );
}
