import { Theme } from '@mui/material';

export const styles = {
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  flexGrow: {
    flexGrow: '1',
  },
  rowReverseContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100vw',
    height: '100%',
    maxHeight: '528px',
    maxWidth: '1000px',
    marginTop: 0,
    marginX: 'auto',
  },
  slimContainer: {
    maxWidth: '400px',
  },
  mapHeightContainer: {
    height: '100%',
    maxHeight: 'calc(528px - 48px)',
  },
  contentBelowMapAndTitleContainer: {
    height: '100%',
    maxHeight: '450px',
  },
  contentBelowMapAndHeaderContainer: {
    height: '100%',
    maxHeight: 'calc(100vh - 373.5px - 80px)',
  },
  backButton: {
    position: 'absolute',
    top: '1.5rem',
    left: '1.25rem',
    padding: '0',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '2.25rem',
    lineHeight: '125%',
    width: '100%',
    paddingBottom: '1rem',
  },
  wideTitleText: {
    maxWidth: '1300px',
  },
  extraPadding: {
    padding: '0 1.25rem',
  },
  mapControlsBox: {
    position: 'absolute',
    top: '1.125rem',
    right: '1.56rem',
    width: '5.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  zoomBox: {
    backgroundColor: (theme: Theme) => theme.palette.custom.white,
    borderRadius: '0.875rem',
    padding: '0 4px',
  },
  zoomIcon: {
    padding: '0',
    width: '1.5rem',
    height: '1.5rem',
    marginTop: '-0.125rem',
  },
  userLocationButton: {
    padding: '0',
    backgroundColor: (theme: Theme) => theme.palette.custom.white,
    borderRadius: '50%',
    marginRight: '0.5rem',
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette.custom.white,
    },
  },
  mapContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    background: (theme: Theme) => theme.palette.custom.lightGrey,
    flexShrink: 0,
  },
  largeRoundedMap: {
    borderRadius: '20px',
    overflow: 'hidden',
    height: '528px',
    background: (theme: Theme) => theme.palette.custom.lightGrey,
    width: (theme: Theme) => theme.breakpoints.values.sm,
  },
  smallMapHeight: {
    height: '373.5px',
  },
  maxWidth: {
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
  },
  searchFieldsContainer: {
    display: 'flex',
    height: '84px',
    flexShrink: 0,
    width: '100%',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
    padding: '1rem 1.25rem 0 1.25rem',
  },
  providerEmptySearchResultsContainer: {
    width: '100%',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
    alignItems: 'center',
    padding: '0',
    flexGrow: 1,
    minHeight: '19%',
  },
  magnifyingGlassIcon: {
    width: '4rem',
    height: '4rem',
  },
  emptySearchResultsTitle: {
    color: (theme: Theme) => theme.palette.custom.darkBlue,
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  emptySearchResultsDescription: {
    width: '62%',
    color: '#1E1F22',
    fontWeight: 300,
    fontSize: '.75rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
  searchResultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
    padding: '0',
    overflow: 'auto',
  },
  listItemWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  listItemContainer: {
    flexShrink: '0',
    width: '100%',
    padding: '1.5rem 1.5rem 1.5rem 2rem',
    position: 'relative',
  },
  listItemActiveIndicator: {
    position: 'absolute',
    left: '12px',
    width: '8px',
    height: '100%',
    top: 0,
    backgroundColor: (theme: Theme) => theme.palette.custom.link,
  },
  primaryButtonContainer: {
    flexShrink: 0,
    width: '100%',
    maxWidth: '328px',
    margin: '.5rem 1.25rem .75rem 1.25rem',
  },
  listItemHeader: {
    color: (theme: Theme) => theme.palette.custom.veryDarkBlue,
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 'normal',
  },
  listItemDetails: {
    color: '#1E1F22',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: 'normal',
  },
  listItemDistance: {
    color: (theme: Theme) => theme.palette.custom.heavyGrey,
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: 'normal',
  },
  searchFieldHeader: {
    color: (theme: Theme) => theme.palette.custom.darkBlue,
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '1.125rem',
    marginBottom: '.25rem',
  },
  headerContainer: {
    width: '100%',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
    padding: '2.5rem 1.25rem 0 1.25rem',
  },
  listHeader: {
    color: (theme: Theme) => theme.palette.custom.darkBlue,
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '1rem',
    paddingBottom: '1rem',
  },
  listDivider: {
    width: 'calc(100% - 2.5rem)',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
  },
  listItemDivider: {
    alignSelf: 'center',
    width: '100%',
    marginLeft: '1.25rem',
    marginRight: '1.25rem',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
  },
  specialtyDropdown: {
    background: (theme: Theme) => theme.palette.custom.lightGrey,
    borderRadius: '8px',
    color: (theme: Theme) => theme.palette.custom.darkBlue,
    fontSize: '.875rem',
  },
  dropdownIcon: {
    margin: '.15rem .25rem .25rem 0',
  },
};
