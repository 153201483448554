import { SxProps, Theme } from '@mui/material';

export const containerSx: SxProps<Theme> = {
  display: 'flex',
  marginTop: '24px',
};

export const columnContainerSx: SxProps<Theme> = {
  display: 'flex',
  marginTop: '24px',
  flexDirection: 'column',
};

export const headerTextSx: SxProps<Theme> = {
  marginBottom: '4px',
  lineHeight: '24px',
  fontSize: '20px',
  fontWeight: '700',
};

export const addressInfoTextSx: SxProps<Theme> = {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  textDecoration: 'none',
};
