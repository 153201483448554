import {
  INewPracticeFacilitySearchResult,
  INewProviderAndFacilityResult,
  IPracticeFacilityDetailsResponse,
  IProviderAtFacility,
} from '../../../app/rest-api/public-provider-search/types';
import { LocationDetails, LocationListElement, ProviderListElement } from './NewProviderSearch.types';
import { numberOfMetersInAMile } from '../ProviderSearchPage.consts';
import { ContactType, ProviderType } from '../../../app/graphql/_generated/hooks';
import { formatPhone } from '../../../utils/format-phone';
import { v4 as uuidv4 } from 'uuid';
import { CoordinateWithZipCode } from './NewProviderSearchPage.types';
import { getZipCodeFromLatLong } from '../../../app/third-party-api/mapbox';

export const getUserLatCoordinateWithZipCode = async (): Promise<CoordinateWithZipCode | null> => {
  if (!navigator.permissions || !navigator.permissions.query) {
    return Promise.resolve(null);
  }

  const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

  if (permissionStatus.state === 'denied') {
    return Promise.resolve(null);
  }

  const userLatLong = await getUserCoordinates();

  if (!userLatLong) {
    return null;
  }

  const userZipCode = await getZipCodeFromLatLong(userLatLong.latitude, userLatLong.longitude);

  if (!userZipCode) {
    return null;
  }

  return {
    latitude: userLatLong.latitude,
    longitude: userLatLong.longitude,
    zipCode: userZipCode,
  };
};

const getUserCoordinates = (): Promise<GeolocationCoordinates | null> => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (userLocationCoordinates) => {
        resolve(userLocationCoordinates.coords);
      },
      () => resolve(null),
      {
        enableHighAccuracy: false,
        maximumAge: 100 * 60,
      }
    );
  });
};

export function convertNewPublicSearchRestAPIResponseToLocationsToDisplay(
  results: INewPracticeFacilitySearchResult[]
): LocationListElement[] {
  const locations: LocationListElement[] = [];

  results?.forEach((searchResult: INewPracticeFacilitySearchResult) => {
    const locationToDisplay = convertToLocationListElement(searchResult);

    if (locationToDisplay) {
      locations.push(locationToDisplay);
    }
  });

  return locations;
}

export function convertNewPublicSearchRestAPIResponseToProvidersToDisplay(results: INewProviderAndFacilityResult[]): ProviderListElement[] {
  const providers: ProviderListElement[] = [];

  results?.forEach((searchResult: INewProviderAndFacilityResult) => {
    const providerToDisplay = convertToProviderListElement(searchResult);

    if (providerToDisplay) {
      providers.push(providerToDisplay);
    }
  });

  return providers;
}

function convertToLocationListElement(searchResult: INewPracticeFacilitySearchResult): LocationListElement | null {
  const id = searchResult._id;
  const name = searchResult.name || '';
  const postalAddress = searchResult.postal_address;
  const streetAddress1 = postalAddress?.street_address1 || '';
  const streetAddress2 = postalAddress?.street_address2 || '';
  const city = postalAddress?.city_town || '';
  const state = postalAddress?.state_province || '';
  const zip = postalAddress?.postal_code || '';
  const coordinates = searchResult.geolocation?.coordinates;
  const distanceInMetersFromUser = searchResult.geolocation?.distance;

  if (!id || !name || !streetAddress1 || !city || !state || !zip || !coordinates || coordinates.length < 2 || !distanceInMetersFromUser) {
    return null;
  }

  return {
    type: 'location',
    id: id.toString(),
    name,
    firstAddressLine: streetAddress2 ? `${streetAddress1} ${streetAddress2}`.trimEnd() : `${streetAddress1}`.trimEnd(),
    secondAddressLine: `${city}, ${state} ${zip}`,
    coordinates: coordinates, // [longitude, latitude]
    distanceInMiles: distanceInMetersFromUser === undefined ? null : Math.ceil(distanceInMetersFromUser / numberOfMetersInAMile),
    numberOfProviders: searchResult.providers?.length ?? 0,
  };
}

function convertToProviderListElement(searchResult: INewProviderAndFacilityResult): ProviderListElement | null {
  const practiceFacilityId = searchResult.practice_facility?._id;
  let name = searchResult.provider?.names?.join(' ') || '';
  const providerType = searchResult.provider?.type;

  if (providerType && providerType !== ProviderType.PUnknownType) {
    const titleAsString = mapProviderTypeToString(providerType);

    name = `${name}, ${titleAsString}`;
  }

  const postalAddress = searchResult.practice_facility?.postal_address;
  const streetAddress1 = postalAddress?.street_address1 || '';
  const streetAddress2 = postalAddress?.street_address2 || '';
  const city = postalAddress?.city_town || '';
  const state = postalAddress?.state_province || '';
  const zip = postalAddress?.postal_code || '';
  const coordinates = searchResult.practice_facility?.geolocation?.coordinates;
  const distanceInMetersFromUser = searchResult.practice_facility?.geolocation?.distance;
  const referenceCodeIds = searchResult?.provider?.reference_code_ids;

  if (
    !practiceFacilityId ||
    !name ||
    !streetAddress1 ||
    !city ||
    !state ||
    !zip ||
    !coordinates ||
    coordinates.length < 2 ||
    !distanceInMetersFromUser
  ) {
    return null;
  }

  return {
    type: 'provider',
    id: uuidv4(),
    practiceFacilityId,
    name,
    firstAddressLine: `${streetAddress1} ${streetAddress2}`.trim(),
    secondAddressLine: `${city}, ${state} ${zip}`,
    coordinates: coordinates, // [longitude, latitude]
    distanceInMiles: distanceInMetersFromUser === undefined ? null : Math.ceil(distanceInMetersFromUser / numberOfMetersInAMile),
    referenceCodeIds: referenceCodeIds || [],
  };
}

export function extractLocationDetails(data: IPracticeFacilityDetailsResponse): LocationDetails {
  const facility = data?.facility_model;
  const facilityName = facility?.name || '';
  const postalAddress = facility?.location?.postal_address;
  const facilityStreetAddress1 = postalAddress?.street_address1 || '';
  const facilityStreetAddress2 = postalAddress?.street_address2 || '';
  const facilityStreetAddress = `${facilityStreetAddress1} ${facilityStreetAddress2}`.trimEnd();
  const city = postalAddress?.city_town || '';
  const state = postalAddress?.state_province || '';
  const zip = postalAddress?.postal_code || '';

  const practiceFacility = data;
  const practiceFacilityName = practiceFacility?.name || '';
  const practiceFacilityStreetAddress2 = practiceFacility?.street_address_2 || '';
  const phoneContact = practiceFacility?.contacts?.find((c) => c.is_primary && c.contact_model?.contact_type === ContactType.PhoneNumber);
  const phone = phoneContact?.contact_model?.phone_number?.phone;
  const websiteContact = practiceFacility?.contacts?.find(
    (c) => c.is_primary && c.contact_model?.contact_type === ContactType.WebsiteAddress
  );
  const website = websiteContact?.contact_model?.website_address?.website;

  const providerFullNames: string[] = [];

  practiceFacility?.providers_at_facility?.forEach((p: IProviderAtFacility) => {
    const providerNames = p?.provider?.party?.person?.current_name?.names;
    const providerType = p?.provider?.type;

    if (!providerNames || providerNames.length === 0) {
      return;
    }

    const fullName = providerNames.join(' ');

    if (providerType && providerType !== ProviderType.PUnknownType) {
      const titleAsString = mapProviderTypeToString(providerType);

      providerFullNames.push(`${fullName}, ${titleAsString}`);
    } else {
      providerFullNames.push(fullName);
    }
  });

  const specialties: string[] = [];

  practiceFacility?.taxonomy_ref_codes?.forEach((code) => {
    if (code.provider_speciality_taxonomy_code?.display_name) {
      specialties.push(code.provider_speciality_taxonomy_code?.display_name);
    }
  });

  return {
    name: practiceFacilityName || facilityName || '',
    firstAddressLine: `${facilityStreetAddress} ${practiceFacilityStreetAddress2}`.trimEnd(),
    secondAddressLine: `${city}, ${state} ${zip}`,
    phone: phone ? formatPhone(phone) : '',
    website: website || '',
    providers: providerFullNames,
    specialties: specialties,
  };
}

const mapProviderTypeToString = (type: ProviderType) => {
  switch (type) {
    case ProviderType.PArnp:
      return 'ARNP';

    case ProviderType.PDo:
      return 'DO';

    case ProviderType.PMd:
      return 'MD';

    case ProviderType.PPa:
      return 'PA';
  }
};
