import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRestricted } from './RestrictedContext';
import { storage_keys } from '../../app/constants';

function RestrictedGuardRoute(): JSX.Element | null {
  const { checkIfUserHasAcceptedTerms, checkIfUserHasAddedSsn } = useRestricted();
  const { pathname } = useLocation();

  const [shouldShowTermsPage, setShouldShowTermsPage] = useState<null | boolean>(null);
  const [shouldShowSsnPage, setShouldShowSsnPage] = useState<null | boolean>(null);
  const storedBillId = localStorage.getItem(storage_keys.bill_to_pay);

  useEffect(() => {
    checkIfUserHasAcceptedTerms().then((hasUserAcceptedTerms) => {
      setShouldShowTermsPage(!hasUserAcceptedTerms);
    });
    checkIfUserHasAddedSsn().then((hasUserAddedSsn) => {
      setShouldShowSsnPage(!hasUserAddedSsn);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (shouldShowTermsPage === null) {
    return null;
  }

  if (shouldShowTermsPage) {
    return <Navigate to="/auth/terms" replace />;
  } else if (shouldShowSsnPage) {
    return <Navigate to="/add-ssn" replace />;
  }

  if (pathname === '/auth/terms' || pathname === '/add-ssn') {
    if (storedBillId) {
      return <Navigate to={`/pay-bill/${storedBillId}`} replace />;
    }
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default RestrictedGuardRoute;
