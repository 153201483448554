import { GetPaymentMethodsQuery } from '../../app/graphql/_generated/hooks';
import { IFinancialAccountFragment } from '../../app/graphql/_generated/hooks';
import { PaymentMethod } from '../../features/api/paymentMethods/types';

export function getPaymentMethodsQueryResultsFormatter(data: GetPaymentMethodsQuery) {
  // filtering is written to exclude statuses instead of including, because stripe accounts don't have status fields
  // might be updated
  const filteredAccounts =
    data.party?.accounts?.accounts?.filter(
      (account) =>
        account?.status !== 'TERMINATED' &&
        account?.status !== 'ACCOUNT_STATUS_UNKNOWN' &&
        account?.status !== 'UNVERIFIED' &&
        account?.status !== 'BANK_ACCOUNT_REMOVED' &&
        account?.status !== 'DENIED' &&
        account?.account_type !== 'STRIPE_CUSTOMER'
    ) || [];
  const stripeAccount = data.party?.accounts?.accounts?.find((account) => account?.account_type === 'STRIPE_CUSTOMER');
  const stripeAccounts =
    stripeAccount?.stripe_customer_account?.map<IFinancialAccountFragment | null>((account) => {
      return {
        ...account,
        ...{
          account_id: account?.id,
          account_type: 'STRIPE_CUSTOMER',
          status: stripeAccount.status,
        },
      } as IFinancialAccountFragment;
    }) || [];
  const result = [...filteredAccounts, ...stripeAccounts].sort((acc1, acc2) => {
    // TODO: FIX types (audit prop exist in ACC1 and ACC2 for sure, but TS shows error)
    // set 1 milisecond for edge when one of the date is not set
    // eslint-disable-next-line
    // @ts-ignore
    const date1 = acc1?.audit?.created_at || 1;
    // eslint-disable-next-line
    // @ts-ignore
    const date2 = acc2?.audit?.created_at || 1;

    // set default utc to 0 it handle edge of the edgecases when date is not valid
    const utc1 = new Date(date1).valueOf() || 0;
    const utc2 = new Date(date2).valueOf() || 0;

    return utc2 - utc1;
  });

  return result as PaymentMethod[];
}
