import { GetPracticeFacilityByIdQuery, ProviderType } from '../../app/graphql/_generated/hooks';

export type PracticeFacilityDetailsData = {
  practiceFacilityName: string;
  latLong?: number[];
  practiceFacilityStreetAddress2: string;
  facilityName: string;
  facilityAddress: FacilityAddress;
  providerFullNames: string[];
};

export type FacilityAddress = {
  street_address1: string;
  street_address2?: string | undefined | null;
  city_town: string;
  state_province: string;
  postal_code: string;
};

const defaultAddress: FacilityAddress = {
  street_address1: '',
  street_address2: '',
  city_town: '',
  postal_code: '',
  state_province: '',
};

const mapProviderTypeToString = (type: ProviderType) => {
  switch (type) {
    case ProviderType.PArnp:
      return 'ARNP';

    case ProviderType.PDo:
      return 'DO';

    case ProviderType.PMd:
      return 'MD';

    case ProviderType.PPa:
      return 'PA';
  }
};

export function extractData(data: GetPracticeFacilityByIdQuery): PracticeFacilityDetailsData {
  const facility = data?.practiceFacility?.facility_model;
  const facilityName = facility?.name;
  const facilityAddress = facility?.location?.postal_address || defaultAddress;

  const practiceFacility = data?.practiceFacility;
  const practiceFacilityName = practiceFacility?.name;
  const practiceFacilityStreetAddress2 = practiceFacility?.street_address_2;

  const providerFullNames: string[] = [];

  practiceFacility?.providers_at_facility?.forEach((p) => {
    const providerNames = p?.provider?.party?.person?.current_name.names;
    const providerType = p?.provider?.type;

    if (!providerNames || providerNames.length === 0) {
      return;
    }

    const fullName = providerNames.join(' ');

    if (providerType && providerType !== ProviderType.PUnknownType) {
      const titleAsString = mapProviderTypeToString(providerType);

      providerFullNames.push(`${fullName}, ${titleAsString}`);
    } else {
      providerFullNames.push(fullName);
    }
  });

  return {
    practiceFacilityName: practiceFacilityName || '',
    practiceFacilityStreetAddress2: practiceFacilityStreetAddress2 || '',
    facilityName: facilityName || practiceFacilityName || '',
    facilityAddress: facilityAddress,
    providerFullNames: providerFullNames,
  };
}
