import { Box, ButtonBase, Divider, Typography } from '@mui/material';
import { BankPaymentMethod, CardPaymentMethod } from '../BankPaymentMethod';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { PaymentMethod } from '../../../features/api/paymentMethods/types';
import { selectCurrentPM, setPaymentMethod } from '../../../features/paymentMethods/paymentMethodSlice';
import { styles } from './styles';
import isAfter from 'date-fns/isAfter';
import { storage_keys } from '../../../app/constants';

export interface PaymentMethodProps {
  payMethod: PaymentMethod;
}

function PaymentMethodSelect({ payMethod }: PaymentMethodProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentPaymentMethod = useAppSelector(selectCurrentPM);
  const showBtn =
    payMethod &&
    payMethod.account_type === 'STRIPE_CUSTOMER' &&
    isAfter(new Date(Number(payMethod?.exp_year), Number(payMethod?.exp_month), 1), new Date());
  const showSelect =
    currentPaymentMethod?.account_id !== payMethod?.account_id &&
    (payMethod && 'brand' in payMethod ? showBtn : true) &&
    payMethod?.status !== 'HOLD';

  return (
    <Box key={payMethod?.account_id}>
      <Box sx={styles.wrapper}>
        {payMethod && payMethod.account_type === 'STRIPE_CUSTOMER' ? (
          <CardPaymentMethod data={payMethod} />
        ) : (
          <BankPaymentMethod data={payMethod} />
        )}
        {showSelect ? (
          <ButtonBase
            data-id="select-pm-btn"
            sx={styles.button}
            onClick={() => {
              dispatch(setPaymentMethod(payMethod));
              sessionStorage.setItem(storage_keys.default_payment_method, String(payMethod?.account_id));
              navigate(-1);
            }}
          >
            Select
          </ButtonBase>
        ) : (
          payMethod?.status === 'HOLD' && (
            <Typography data-id="verification-status" sx={styles.verificationText}>
              Verification in Progress
            </Typography>
          )
        )}
      </Box>
      <Divider sx={{ m: '25px 0' }} />
    </Box>
  );
}

export default PaymentMethodSelect;
