import { Theme } from '@mui/material';

export const styles = {
  pageContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: (theme: Theme) => theme.palette.custom.textColor,
    backgroundSize: 'cover',
  },
  contentContainer: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  idCardSectionContainer: {
    width: '100%',
    padding: '0 1rem 1.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  activitySectionContainer: {
    backgroundColor: (theme: Theme) => theme.palette.custom.lightGrey,
    width: '100%',
    display: 'flex',
    flexGrow: '1',
    padding: '1.5rem 1rem',
  },
  button: {
    width: '300px',
    backgroundColor: 'white',
  },
};
