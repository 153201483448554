export const styles = {
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  backButton: {
    position: 'absolute',
    top: '105px',
    left: '20px',
    padding: '0',
  },
};
