import { Theme, Typography } from '@mui/material';
import BoxWithShadow from '../boxes/BoxWithShadow';
import ShortBillInfo from './ShortBillInfo';
import { useNavigate } from 'react-router-dom';
import { BillState, GetInvoicesQuery, IBillFragment, IInvoiceFragment } from '../../app/graphql/_generated/hooks';
import { IBillWithInvoiceData } from '../../pages/types';

const styles = {
  empty: {
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '0 15%',
    color: (theme: Theme) => theme.palette.custom.grey,
  },
};

function RecentBills(data: GetInvoicesQuery) {
  const navigate = useNavigate();

  const reducedBills =
    data?.invoices?.results?.reduce((acc: IBillWithInvoiceData[], invoice: IInvoiceFragment) => {
      const billsWithInvoiceId =
        invoice?.bills?.map((bill: IBillFragment | null) => ({ ...bill, invoice_id: invoice._id, encounter: invoice.encounter })) || [];

      return [...acc, ...billsWithInvoiceId];
    }, []) || [];
  const recentBill = reducedBills && reducedBills.length ? reducedBills[reducedBills.length - 1] : null;

  const onPressOnShortBill = (bill: IBillWithInvoiceData) => () => {
    if (bill.bill_state === BillState.BillPaid || bill.bill_state === BillState.BillPending) {
      navigate(`/bills/${bill.invoice_id}`);
    } else {
      navigate(`/pay-bill/${bill.invoice_id}`);
    }
  };

  return (
    <BoxWithShadow data-id="recent-bills" title="Outstanding Bills" linkText="See All" linkUrl="/bills">
      {recentBill ? (
        <ShortBillInfo bill={recentBill} encounter={recentBill.encounter} onPress={onPressOnShortBill(recentBill)} />
      ) : (
        <Typography sx={styles.empty}>You will see your outstanding bill details here after receiving services</Typography>
      )}
    </BoxWithShadow>
  );
}

export default RecentBills;
