export type AccountType = 'savings' | 'checking';

export enum OTPDeviceType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface ILoginRequestBody {
  email: string;
  first_name?: string;
  dob?: string;
}

export type LoginResponseBodyWithStatus = ILoginResponseBody & { status: number };

export interface ILoginResponseBody {
  id: string;
  default_device_id: string;
  devices: ILoginDevice[];
}

export interface ILoginDevice {
  id: string;
  email?: string;
  phone?: string;
  status: string;
  type: OTPDeviceType;
}

export interface IRequestOTPRequestBody {
  device_id: string;
}

export interface ISendOTPRequestBody {
  otp: string;
}

export interface ISendOTPResponse {
  access_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
}

export interface IAxiosErrorReason {
  response: { status: number };
}
