export const styles = {
  box: { padding: '0 16px' },
  title: {
    fontFamily: 'Cera Pro',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '25px',
    color: '#343D4F',
  },
  empty: {
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '0 15%',
    color: '#78849E',
  },
};
