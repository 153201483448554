import { Theme } from '@mui/material';

export const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    color: (theme: Theme) => theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Cera Pro',
    marginLeft: '15px',
  },
  link: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    width: '100%',
    padding: '10px 0',
    display: 'block',
  },
  confirmText: {
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '20px',
  },
  verificationText: {
    fontSize: '12px',
    fontWeight: '300',
    fontStyle: 'italic',
    color: '#343D4F',
  },
};
