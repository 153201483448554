import { useEffect } from 'react';
import { Box } from '@mui/material';
import { getCurrentUserPartyId } from '../app/graphql/auth.utils';
import {
  BillState,
  BillType,
  SortDirection,
  useGetAlloyAndBankStatusesLazyQuery,
  useGetInvoicesQuery,
  useGetPaymentMethodsLazyQuery,
  useGetPlanUtilizationLazyQuery,
  useGetUserNameByPartyIdLazyQuery,
} from '../app/graphql/_generated/hooks';
import { useAppDispatch } from '../app/hooks';
import DefaultPaymentMethod from '../components/partials/DefaultPaymentMethod';
import RecentBillsBlock from '../components/partials/RecentBills';
import { RemainingVisits } from '../components/RemainingVisits';
import UserMenu from '../components/UserMenu';
import { PaymentMethod } from '../features/api/paymentMethods/types';
import { saveCurrentBillId, savePlanUtilization } from '../features/bills/billsSlice';
import { savePaymentMethods } from '../features/paymentMethods/paymentMethodSlice';
import { getPaymentMethodsQueryResultsFormatter } from '../utils/gqlResultsFormatters/paymentMethods';
import { getPlanUtilizationQueryResultFormatter } from '../utils/gqlResultsFormatters/planUtilization';
import LoadingScreen from './Loading';

function TellusHomePage() {
  const dispatch = useAppDispatch();
  const currentUserPartyId = getCurrentUserPartyId();

  const [getPlanUtilization, { loading: planLoading }] = useGetPlanUtilizationLazyQuery();
  const [getPaymentMethods, { loading: pmLoading }] = useGetPaymentMethodsLazyQuery();
  const [getUserNameByPartyId, { loading: userLoading }] = useGetUserNameByPartyIdLazyQuery();
  const [getAlloyAndBankStatuses, { loading: statusesLoading }] = useGetAlloyAndBankStatusesLazyQuery();
  const { data: latestInvoice, loading: invoiceLoading } = useGetInvoicesQuery({
    variables: {
      filter: {
        bill_state: [
          BillState.BillUnknown,
          BillState.BillSubmitted,
          BillState.BillPending,
          BillState.BillAdjudicated,
          BillState.BillFailed,
        ],
        bill_type: [BillType.Member],
        party_id: currentUserPartyId,
      },
      options: { limit: 1, offset: 0, sort: [{ field: 'audit.created_at', direction: SortDirection.Desc }] },
      bill_type: [BillType.Member],
      bill_state: [BillState.BillUnknown, BillState.BillSubmitted, BillState.BillPending, BillState.BillAdjudicated, BillState.BillFailed],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    dispatch(saveCurrentBillId(''));
    if (currentUserPartyId) {
      getUserNameByPartyId({ variables: { id: currentUserPartyId } });
      getPlanUtilization({
        variables: { id: currentUserPartyId },
        onCompleted: (result) => {
          const planUtilization = getPlanUtilizationQueryResultFormatter(result);

          dispatch(savePlanUtilization(planUtilization));
        },
      });
      getPaymentMethods({
        variables: { id: currentUserPartyId },
        onCompleted: (result) => {
          const pmList = getPaymentMethodsQueryResultsFormatter(result) as PaymentMethod[];

          if (pmList) {
            dispatch(savePaymentMethods(pmList));
          }
        },
      });
      getAlloyAndBankStatuses({ variables: { id: currentUserPartyId } });
    }
  }, [dispatch, getAlloyAndBankStatuses, getPaymentMethods, getPlanUtilization, getUserNameByPartyId, currentUserPartyId]);
  return (
    <Box sx={{ padding: '0 16px' }}>
      {(planLoading || pmLoading || userLoading || statusesLoading || invoiceLoading) && <LoadingScreen />}
      <UserMenu />
      <RecentBillsBlock {...latestInvoice} />
      <RemainingVisits />
      <DefaultPaymentMethod />
    </Box>
  );
}

export default TellusHomePage;
