import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IPaymentMethods, PaymentMethod } from '../api/paymentMethods/types';
import { storage_keys } from '../../app/constants';

export interface PaymentMethodsState {
  methods: IPaymentMethods | null | undefined;
  current?: PaymentMethod | null;
  intent: string;
}

const initialState: PaymentMethodsState = {
  methods: [],
  current: null,
  intent: '',
};

export const paymentMethodsSlice = createSlice({
  name: 'payMethods',
  initialState,
  reducers: {
    savePaymentMethods: (state, action: PayloadAction<IPaymentMethods | undefined | null>) => {
      state.methods = action.payload;
      // save default payment method
      if (action.payload) {
        if (action.payload.length === 0) {
          state.current = null;
          sessionStorage.removeItem(storage_keys.default_payment_method);
        } else if (!state.current?.account_id) {
          const payMethod = action.payload.find((payMethod) => payMethod?.status !== 'HOLD');

          state.current = payMethod;
          sessionStorage.setItem(storage_keys.default_payment_method, String(payMethod?.account_id));
        }
      }
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod | undefined | null>) => {
      state.current = action.payload;
    },
    saveIntent: (state, action: PayloadAction<string>) => {
      state.intent = action.payload;
    },
    clearPaymentMethodsState: () => {
      return initialState;
    },
  },
});

export const { savePaymentMethods, setPaymentMethod, saveIntent } = paymentMethodsSlice.actions;

export const selectCurrentPM = (state: RootState) => state.payMethods.current;
export const stripeIntent = (state: RootState) => state.payMethods.intent;

export default paymentMethodsSlice.reducer;
