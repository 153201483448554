import { Box, Button, IconButton, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { NewLocationList } from './NewLocationList';
import { NewProviderList } from './NewProviderList';
import {
  groupCoverageContainerSx,
  groupCoverageIconSx,
  groupCoverageTextSx,
  headerAndZipCodeContainerSx,
  headerSx,
  leftChevronImageSx,
  resultsTabSwitcherSx,
  searchAgainButtonContainerSx,
  searchAgainButtonSx,
  searchResultListsContainerSx,
  searchResultsContainerSx,
  searchResultsHeaderControlsContainerSx,
  specialtyPillClearButtonSx,
  specialtyPillSx,
  specialtyPillTextSx,
  tabHeaderSx,
  underlinedTabHeaderSx,
  zipCodeContainerSx,
  zipCodeTextSx,
} from './NewProviderSearchResults.styles';
import { LocationListElement, ProviderListElement } from '../NewProviderSearch.types';
import { useCallback } from 'react';
import { Condition } from '@nomi-health-inc/components-ui';
import { ResultsTab } from '../NewProviderSearchPage.types';
import { boldInfoText, highDeductiblePlanSx } from '../NewProviderSearch.styles';
import ClearIcon from '@mui/icons-material/Clear';

function NewProviderSearchResults({
  onSearchAgainButtonPress,
  specialtyBeingSearched,
  onSpecialtyCleared,
  zipCodeBeingSearched,
  resultsTabBeingDisplayed,
  onResultsTabChange,
  activeLocationId,
  activeProviderId,
  locationList,
  providerList,
  handleLocationClick,
  handleProviderClick,
  groupId,
  areLoading,
}: {
  onSearchAgainButtonPress: () => void;
  specialtyBeingSearched?: string | null;
  onSpecialtyCleared: () => void;
  zipCodeBeingSearched: string;
  resultsTabBeingDisplayed: ResultsTab;
  onResultsTabChange: (newTab: ResultsTab) => void;
  activeLocationId: string | null;
  activeProviderId: string | null;
  locationList: LocationListElement[];
  providerList: ProviderListElement[];
  handleLocationClick: (locationId: string) => void;
  handleProviderClick: (providerId: string) => void;
  groupId: string;
  areLoading: boolean;
}) {
  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChangeToLocationsTab = useCallback(() => {
    onResultsTabChange(ResultsTab.Locations);
  }, [onResultsTabChange]);

  const handleChangeToProvidersTab = useCallback(() => {
    onResultsTabChange(ResultsTab.Providers);
  }, [onResultsTabChange]);

  return (
    <Box sx={searchResultsContainerSx}>
      <Condition when={areOnMobileDevice}>
        <Box sx={highDeductiblePlanSx}>
          <Typography variant="body5">Part of a High Deductible Plan?</Typography>
          <Typography variant="body5">
            Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>

      <Box sx={searchResultsHeaderControlsContainerSx}>
        <Box sx={searchAgainButtonContainerSx}>
          <Box component="img" alt="left-arrow" src="/images/chevron-left-blue.svg" sx={leftChevronImageSx} />
          <Button
            color="primary"
            variant="outlined"
            onClick={onSearchAgainButtonPress}
            sx={searchAgainButtonSx}
            data-id="searchAgainButton"
          >
            Search Again
          </Button>
        </Box>
        <Stack direction="row" sx={headerAndZipCodeContainerSx}>
          <Typography variant="body1" sx={headerSx}>
            Results
          </Typography>
          <Condition when={specialtyBeingSearched}>
            <Stack direction="row" sx={specialtyPillSx}>
              <Typography variant="body6" noWrap sx={specialtyPillTextSx}>
                {specialtyBeingSearched}
              </Typography>
              <IconButton disableRipple onClick={onSpecialtyCleared}>
                <ClearIcon sx={specialtyPillClearButtonSx} />
              </IconButton>
            </Stack>
          </Condition>
          <Box flexGrow={1} />
          <Box sx={zipCodeContainerSx}>
            <Box component="img" alt="zip-code-crosshairs-icon" src="/images/map/location-crosshairs.svg" />
            <Typography variant="subtitle2" sx={zipCodeTextSx}>
              {zipCodeBeingSearched}
            </Typography>
          </Box>
        </Stack>
        <Condition when={groupId === ''}>
          <Box sx={groupCoverageContainerSx}>
            <Box component="img" alt="warning-icon" src="/images/red-warning.svg" sx={groupCoverageIconSx} />
            <Typography variant="subtitle2" sx={groupCoverageTextSx}>
              To ensure coverage please enter your group ID
            </Typography>
          </Box>
        </Condition>
        <Stack spacing={3.5} direction="row" sx={resultsTabSwitcherSx}>
          <Typography
            onClick={handleChangeToLocationsTab}
            sx={resultsTabBeingDisplayed === ResultsTab.Locations ? underlinedTabHeaderSx : tabHeaderSx}
          >
            Locations
          </Typography>
          <Typography
            onClick={handleChangeToProvidersTab}
            sx={resultsTabBeingDisplayed === ResultsTab.Providers ? underlinedTabHeaderSx : tabHeaderSx}
          >
            Providers
          </Typography>
        </Stack>
      </Box>
      <Box sx={searchResultListsContainerSx}>
        <Condition when={resultsTabBeingDisplayed === ResultsTab.Locations && !areLoading}>
          <NewLocationList activeLocationId={activeLocationId} locationList={locationList} onLocationClick={handleLocationClick} />
        </Condition>
        <Condition when={resultsTabBeingDisplayed === ResultsTab.Providers && !areLoading}>
          <NewProviderList activeProviderId={activeProviderId} providerList={providerList} onProviderClick={handleProviderClick} />
        </Condition>
      </Box>
    </Box>
  );
}

export default NewProviderSearchResults;
