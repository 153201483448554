import { SxProps, Theme, inputBaseClasses } from '@mui/material';

import { CSSProperties } from 'react';

export const providerSearchContainerSx = (theme: Theme) => {
  return {
    height: '100dvh',
    maxHeight: '100dvh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      minHeight: '552px',
      height: '552px',
      width: '100vw',
    },
  };
};

export const mapContainerSx = () => {
  return {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: (theme: Theme) => theme.palette.custom.lightGrey,
  };
};

export const searchFormContainerSx = (theme: Theme) => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.25rem',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      padding: '2rem 1.25rem 2rem 1.25rem',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '1.25rem',
      fontSize: '1rem',
      fontWeight: '600',
    },
    '& .MuiFormHelperText-root': {
      height: '0px',
      marginTop: '0px',
    },
  };
};

export const highDeductiblePlanSx = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '1rem',
  };
};

export const mapControlsBoxSx = (theme: Theme) => {
  return {
    position: 'absolute',
    width: '5.875rem',
    display: 'flex',
    alignItems: 'center',
    bottom: '.375rem',
    right: '2.5rem',
    [theme.breakpoints.up('sm')]: {
      bottom: '1.5rem',
      right: '1.5rem',
    },
  };
};

export const zoomBoxSx = {
  backgroundColor: (theme: Theme) => theme.palette.custom.white,
  borderRadius: '0.875rem',
  padding: '0 4px',
} as SxProps<Theme>;

export const zoomIconSx = {
  padding: '0',
  width: '1.5rem',
  height: '1.5rem',
  marginTop: '-0.125rem',
} as SxProps<Theme>;

export const userLocationButtonSx = {
  padding: '0',
  backgroundColor: (theme: Theme) => theme.palette.custom.white,
  borderRadius: '50%',
  marginRight: '0.5rem',
  '&:hover': {
    backgroundColor: (theme: Theme) => theme.palette.custom.white,
  },
} as SxProps<Theme>;

export const mapPinProviderCountTextSx = {
  backgroundColor: (theme: Theme) => theme.palette.custom.white,
  borderRadius: '12px',
  border: (theme: Theme) => `2px solid ${theme.palette.custom.veryDarkBlue}`,
  position: 'absolute',
  top: '-6px',
  left: '12px',
  fontSize: '11px',
  lineHeight: '11px',
  fontWeight: '400',
  textAlign: 'center',
  fontColor: (theme: Theme) => `2px solid ${theme.palette.custom.veryDarkBlue}`,
  padding: '3px 4px 1px 4px',
} as SxProps<Theme>;

export const primaryButtonContainerSx = {
  display: 'flex',
  width: '100%',
  height: '2.5rem',
  marginTop: '2rem',
  backgroundColor: (theme: Theme) => theme.palette.custom.veryDarkBlue,
} as SxProps<Theme>;

export const searchFormHeaderSx = {
  fontWeight: 700,
  fontSize: '1.5rem',
  marginBottom: '1rem',
} as SxProps<Theme>;

export const dropdownClearButtonSx = {
  mb: '1px',
  mr: '5px',
  width: '16px',
  height: '16px',
} as SxProps<Theme>;

export const beneathMapSectionSx = (maxHeightPercentage: number) => {
  return {
    width: '100%',
    maxHeight: `${maxHeightPercentage}%`,
    flex: '1',
  };
};

export const floatingCardSx = {
  position: 'absolute',
  left: '23px',
  top: '23px',
  height: '506px',
  width: '375px',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  backgroundColor: (theme: Theme) => theme.palette.custom.white,
};

export const searchFormInputSx = {
  marginBottom: '1rem',
  [`& .${inputBaseClasses.root}`]: {
    height: '2.25rem',
    borderRadius: '2.25rem',
    backgroundColor: (theme: Theme) => theme.palette.custom.white,
  },
} as SxProps<Theme>;

export const infoTextSx = {
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '2.5rem',
  paddingRight: '0.25rem',
  '& .boldText': {
    fontWeight: '700',
    paddingLeft: '0.25rem',
  },
} as SxProps<Theme>;

export const boldInfoText = {
  fontWeight: '700',
  paddingLeft: '0.25rem',
} as SxProps<Theme>;

export const searchFormInputFieldsContainerSx = {
  width: '100%',
  alignItems: 'center',
  '& .MuiFormLabel-root': {
    paddingLeft: '1rem',
    lineHeight: '1.25rem',
    fontSize: '1rem',
    fontWeight: '600',
  },
} as SxProps<Theme>;

export const searchFormSx = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
} as CSSProperties;

export const searchFormSelectSx = {
  height: '2.25rem',
  marginBottom: '1rem',
  borderRadius: '2.25rem',
  backgroundColor: (theme: Theme) => theme.palette.custom.white,
  '& .MuiSelect-icon': {
    margin: '0.375rem 0.5rem 0.25rem 0.25rem',
    width: '.75rem',
  },
} as SxProps<Theme>;

export const infoBoxSx = {
  position: 'absolute',
  height: '2.5rem',
  width: '484px',
  top: '44px',
  left: '424px',
  backgroundColor: (theme: Theme) => theme.palette.custom.white,
  border: (theme: Theme) => {
    return `1px solid ${theme.palette.custom.veryDarkBlue}`;
  },
  borderRadius: '3.125rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
} as SxProps<Theme>;

export const locateIconSx = {
  cursor: 'pointer',
  width: '26px',
  marginRight: '-4px',
} as SxProps<Theme>;

export const selectLabelSx = {
  lineHeight: '1.25rem',
  fontSize: '1rem',
  fontWeight: '600',
  color: '#343D4F',
  paddingLeft: '1rem',
} as SxProps<Theme>;
