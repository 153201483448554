import { ReactNode } from 'react';
import { StyledContainer, StyledContentWrap } from './HorizontalBg.styles';
import GuttersContainer from '../GuttersContainer';
import { useFeatureFlags } from '../../features/featureFlags/FeatureFlagsContext';
import { TellusLogo } from '../TellusLogo/TellusLogo';
import { HeaderNav } from '../../components/Header';
import { Footer } from '../Footer';

function HorizontalBg({ blockLogo, children }: { blockLogo?: boolean; children: ReactNode }) {
  const { areShowingNewMemberExperience } = useFeatureFlags();

  return (
    <StyledContainer component="main" areShowingNewMemberExperience={areShowingNewMemberExperience}>
      {areShowingNewMemberExperience && <HeaderNav />}
      <GuttersContainer data-id="header-logo">{!areShowingNewMemberExperience && <TellusLogo blockLogo={blockLogo} />}</GuttersContainer>
      <StyledContentWrap>{children}</StyledContentWrap>
      <Footer />
    </StyledContainer>
  );
}

export default HorizontalBg;
