import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import type { MapRef } from 'react-map-gl';
import { Condition, ModalSpinner } from '@nomi-health-inc/components-ui';
import { getLatLongFromZipCode, getZipCodeFromLatLong } from '../../app/third-party-api/mapbox';
import { styles } from './ProviderSearch.styles';
import { latLongOfMiamiZipCode, zipCodeInMiami } from './ProviderSearchPage.consts';
import { SpecialtyToTaxonomies } from '../../app/graphql/_generated/hooks';
import ProviderSearchListSection from './ProviderSearchListSection';
import ProviderSearchMap from '../../components/ProviderSearch/ProviderSearchMap';
import { Coordinate, PracticeFacilityForDisplay } from './ProviderSearchPage.types';
import { useSpecialtiesInNetwork } from './useSpecialtiesInNetwork';
import { useProviderSearch } from './useProviderSearch';

export function ProviderSearch({ isPublicSearch }: { isPublicSearch?: boolean }) {
  const { state: navigationState } = useLocation();
  const mapRef = useRef<MapRef | null>(null);
  const [mapCenterCoordinate, setMapCenterCoordinate] = useState<Coordinate | null>(null);
  const [chosenUserPosition, setChosenUserPosition] = useState<Coordinate | null>(null);
  const [zipCode, setZipCode] = useState<string>('');
  const [selectedSpecialtyToTaxonomies, setSelectedSpecialtyToTaxonomies] = useState<SpecialtyToTaxonomies | null>(null);
  const [activePracticeFacilityId, setActivePracticeFacilityId] = useState<string>('');
  const [restoredPreviousSearchState, setRestoredPreviousSearchState] = useState<{
    zip: string;
    specialty: string;
    searchResults: PracticeFacilityForDisplay[];
  } | null>(null);

  const { searchForProviders, providerSearchResults, hasProviderSearchBeenExecuted, areLoadingProviderSearchResults } = useProviderSearch(
    isPublicSearch || false
  );
  const { getSpecialtiesInNetwork, allSpecialtyToTaxonomies } = useSpecialtiesInNetwork();

  const providersToDisplay = useMemo(() => {
    if (restoredPreviousSearchState?.searchResults) {
      return restoredPreviousSearchState?.searchResults;
    }

    return providerSearchResults;
  }, [restoredPreviousSearchState, providerSearchResults]);

  useEffect(() => {
    getSpecialtiesInNetwork(isPublicSearch || false);
  }, [getSpecialtiesInNetwork, isPublicSearch]);

  const centerMapOnUserLocation = useCallback((zip?: string) => {
    if (zip) {
      getLatLongFromZipCode(zip).then((coordinates) => {
        if (coordinates) {
          setMapCenterCoordinate({
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          });
          setChosenUserPosition({
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          });
        } else {
          setMapCenterCoordinate(latLongOfMiamiZipCode);
          setChosenUserPosition(latLongOfMiamiZipCode);
        }
      });
    } else {
      if (!navigator.geolocation || !navigator.geolocation.getCurrentPosition) {
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (userLocationCoordinates) => {
          const userLocationCoords = userLocationCoordinates.coords;

          getZipCodeFromLatLong(userLocationCoords.latitude, userLocationCoords.longitude).then((userLocationZipCode: string | null) => {
            if (userLocationZipCode) {
              setMapCenterCoordinate({
                latitude: userLocationCoords.latitude,
                longitude: userLocationCoords.longitude,
              });
              setChosenUserPosition({
                latitude: userLocationCoords.latitude,
                longitude: userLocationCoords.longitude,
              });
              setZipCode(userLocationZipCode);
            } else {
              setMapCenterCoordinate(latLongOfMiamiZipCode);
              setChosenUserPosition(latLongOfMiamiZipCode);
              setZipCode(zipCodeInMiami);
            }
          });
        },
        () => {
          setMapCenterCoordinate(latLongOfMiamiZipCode);
          setChosenUserPosition(latLongOfMiamiZipCode);
          setZipCode(zipCodeInMiami);
        },
        {
          enableHighAccuracy: false,
          maximumAge: 1000 * 60 * 60, // 1 hour in milliseconds
        }
      );
    }
  }, []);

  useEffect(() => {
    const shouldDisplayPreviousSearchResults = navigationState && navigationState.searchState;
    const { zip, specialty } = navigationState ?? {};

    if (zip && specialty) {
      centerMapOnZipCode(zip);
      searchForProviders(zip, specialty);
    } else if (shouldDisplayPreviousSearchResults) {
      const { zipCode, specialtyToTaxonomies, searchResults } = navigationState?.searchState ?? {};

      centerMapOnZipCode(zipCode);
      setRestoredPreviousSearchState({
        zip: zipCode,
        specialty: specialtyToTaxonomies?.specialty || '',
        searchResults,
      });
      setZipCode(zipCode);
      setSelectedSpecialtyToTaxonomies(specialtyToTaxonomies);
    } else {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'denied') {
            setMapCenterCoordinate(latLongOfMiamiZipCode);
            setChosenUserPosition(latLongOfMiamiZipCode);
            setZipCode(zipCodeInMiami);
          } else {
            centerMapOnUserLocation();
          }
        });
      } else {
        setMapCenterCoordinate(latLongOfMiamiZipCode);
        setChosenUserPosition(latLongOfMiamiZipCode);
        setZipCode(zipCodeInMiami);
      }
    }
  }, [navigationState, centerMapOnUserLocation, searchForProviders]);

  const centerMapOnZipCode = async (zipCode: string) => {
    const centerLatLongForZipCode = await getLatLongFromZipCode(zipCode);

    if (!centerLatLongForZipCode) {
      return;
    }
    setMapCenterCoordinate(centerLatLongForZipCode);
    setChosenUserPosition(centerLatLongForZipCode);
  };

  const updateMapBound = useCallback(
    (zipCode: string) => {
      if (providersToDisplay.length) {
        const lngArray = [] as number[];
        const latArray = [] as number[];

        let includeUserPositionMapZoom = true;

        providersToDisplay.forEach((provider) => {
          lngArray.push(provider.coordinates[0]);
          latArray.push(provider.coordinates[1]);

          if (provider.zipCode === zipCode) {
            includeUserPositionMapZoom = false;
          }
        });

        if (chosenUserPosition && includeUserPositionMapZoom) {
          lngArray.push(chosenUserPosition.longitude);
          latArray.push(chosenUserPosition.latitude);
        }

        const maxLng = Math.max(...lngArray);
        const minLng = Math.min(...lngArray);
        const maxLat = Math.max(...latArray);
        const minLat = Math.min(...latArray);

        if (maxLat && minLat && maxLng && minLng) {
          mapRef?.current?.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: { top: 40, bottom: 40, left: 20, right: 20 }, duration: 500 }
          );
          setMapCenterCoordinate({ longitude: (minLng + maxLng) / 2, latitude: (minLat + maxLat) / 2 });
        }
      }
    },
    [providersToDisplay, chosenUserPosition]
  );

  useEffect(() => {
    if (navigationState?.zip && navigationState?.specialty && zipCode === '' && selectedSpecialtyToTaxonomies === null) {
      setZipCode(navigationState.zip);
      setSelectedSpecialtyToTaxonomies(navigationState.specialty);
      searchForProviders(navigationState.zip, navigationState.specialty);
    }

    if (!hasProviderSearchBeenExecuted) {
      return;
    }

    if (
      restoredPreviousSearchState &&
      zipCode === restoredPreviousSearchState.zip &&
      selectedSpecialtyToTaxonomies?.specialty === restoredPreviousSearchState.specialty
    ) {
      return;
    }

    if (restoredPreviousSearchState) {
      setRestoredPreviousSearchState(null);
    }

    if (zipCode.length === 5) {
      centerMapOnUserLocation(zipCode);
    }

    searchForProviders(zipCode, selectedSpecialtyToTaxonomies);
  }, [
    hasProviderSearchBeenExecuted,
    restoredPreviousSearchState,
    zipCode,
    selectedSpecialtyToTaxonomies,
    navigationState,
    searchForProviders,
    centerMapOnUserLocation,
  ]);

  useEffect(() => {
    updateMapBound(zipCode);
  }, [providersToDisplay, chosenUserPosition, updateMapBound, zipCode]);

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box sx={[isDesktop ? styles.rowReverseContainer : styles.columnContainer, isTablet && styles.flexGrow]}>
      <ModalSpinner loading={areLoadingProviderSearchResults} text="Processing" />
      <ProviderSearchMap
        isPublicSearch={isPublicSearch || false}
        activePracticeFacilityId={activePracticeFacilityId}
        centerMapOnUserLocation={centerMapOnUserLocation}
        chosenUserPosition={chosenUserPosition}
        mapCenterCoordinate={mapCenterCoordinate}
        mapRef={mapRef}
        searchResults={providersToDisplay}
        setActivePracticeFacilityId={setActivePracticeFacilityId}
        setMapCenterCoordinate={setMapCenterCoordinate}
      />
      <Stack sx={[styles.flexGrow, styles.columnContainer]}>
        <Condition when={isDesktop}>
          <Typography
            sx={[
              styles.titleText,
              isDesktop && styles.extraPadding,
              isPublicSearch ? { fontFamily: 'Harmonia Sans' } : { fontFamily: 'Wulkan Display' },
            ]}
          >
            Provider Search
          </Typography>
        </Condition>
        <ProviderSearchListSection
          activePracticeFacilityId={activePracticeFacilityId}
          areConductingExpandingSearchForProviders={areLoadingProviderSearchResults}
          centerMapOnZipCode={centerMapOnZipCode}
          hasUserExecutedProviderSearchPreviously={hasProviderSearchBeenExecuted || restoredPreviousSearchState !== null}
          searchForProvidersWithExpandingSearchRadiuses={searchForProviders}
          setZipCode={setZipCode}
          updateSelectedSpecialty={setSelectedSpecialtyToTaxonomies}
          selectedSpecialty={selectedSpecialtyToTaxonomies}
          searchResults={providersToDisplay}
          zipCode={zipCode}
          isPublicSearch={isPublicSearch}
          providerSpecialties={allSpecialtyToTaxonomies || []}
        />
      </Stack>
    </Box>
  );
}
