import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styles } from './Header.styles';
import { ROUTES } from '../../constants/routes';
interface HeaderProps {
  titleText?: string;
}

function Header({ titleText }: HeaderProps) {
  const navigate = useNavigate();

  return (
    <Box sx={styles.wrap}>
      {titleText ? (
        <Typography sx={styles.title}>{titleText}</Typography>
      ) : (
        <Box
          component="img"
          data-id="logo"
          alt="Logo"
          src="/images/tellus-logo.svg"
          onClick={() => {
            navigate(ROUTES.root);
          }}
        />
      )}
    </Box>
  );
}

export default Header;
