import { ReactNode } from 'react';
import { storage_keys } from '../../app/constants';
import { localStorage } from '../../app/storageApi';
import { RestrictedContext } from './RestrictedContext';
import { PolicyType, useCheckSsnLazyQuery, useGetTermsAcceptanceStatusForUserLazyQuery } from '../../app/graphql/_generated/hooks';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';

function RestrictedProvider({ children }: { children: ReactNode }) {
  const [getTermsAcceptanceForUser] = useGetTermsAcceptanceStatusForUserLazyQuery();
  const [getSsnForUser] = useCheckSsnLazyQuery();

  const checkIfUserHasAcceptedTerms = async () => {
    if (hasUserAcceptedTermsPreviously()) {
      return true;
    }

    const partyIdFromJwtIdToken = getCurrentUserPartyId();

    if (!partyIdFromJwtIdToken) {
      return false;
    }

    const termsAcceptanceResponse = await getTermsAcceptanceForUser({ variables: { id: partyIdFromJwtIdToken } });
    const policies = termsAcceptanceResponse?.data?.party?.person?.policies;
    const userHasAcceptedTerms = policies && policies.some((p) => p && p.policy_type === PolicyType.PTermsAndConditions && p.accepted);

    if (!userHasAcceptedTerms) {
      return false;
    }

    localStorage.setItem(partyIdFromJwtIdToken, {
      ...JSON.parse(localStorage.getItem(partyIdFromJwtIdToken) as string),
      ...{ termsAgreement: true },
    });

    return true;
  };

  const hasUserAcceptedTermsPreviously = () => {
    const partyIdInLocalStorage = localStorage.getItem(storage_keys.user_party_id_key);
    const userSessionState = partyIdInLocalStorage ? localStorage.getItem(partyIdInLocalStorage) : null;

    if (userSessionState) {
      const hasUserAcceptedTermsBefore = JSON.parse(userSessionState).termsAgreement || false;

      if (hasUserAcceptedTermsBefore) {
        return true;
      }
    }

    return false;
  };

  const checkIfUserHasAddedSsn = async () => {
    if (hasUserAddedSsnPreviously()) {
      return true;
    }

    const partyIdFromJwtIdToken = getCurrentUserPartyId();

    if (!partyIdFromJwtIdToken) {
      return false;
    }

    const hasSsnResponse = await getSsnForUser({ variables: { id: partyIdFromJwtIdToken } });
    const userHasAddedSsn = hasSsnResponse?.data?.party?.government_ids?.length
      ? !!hasSsnResponse?.data?.party?.government_ids[0]?.id_type
      : false;

    if (!userHasAddedSsn) {
      return false;
    }

    localStorage.setItem(partyIdFromJwtIdToken, {
      ...JSON.parse(localStorage.getItem(partyIdFromJwtIdToken) as string),
      ...{ hasSsn: true },
    });

    return true;
  };

  const hasUserAddedSsnPreviously = () => {
    const partyIdInLocalStorage = localStorage.getItem(storage_keys.user_party_id_key);
    const userSessionState = partyIdInLocalStorage ? localStorage.getItem(partyIdInLocalStorage) : null;

    if (userSessionState) {
      const hasUserAddedSsnBefore = JSON.parse(userSessionState).hasSsn || false;

      if (hasUserAddedSsnBefore) {
        return true;
      }
    }

    return false;
  };

  return (
    <RestrictedContext.Provider value={{ checkIfUserHasAcceptedTerms, checkIfUserHasAddedSsn }}>{children}</RestrictedContext.Provider>
  );
}

export default RestrictedProvider;
