import { Box, Stack, Typography } from '@mui/material';
import { emptyStateBlurbSx, emptyStateContactEmailSx, emptyStateTitleSx, hospitalIconSx } from './EmptyState.styles';

export function EmptyState() {
  return (
    <Stack alignItems="center">
      <Box component="img" alt="hospital-icon" src="/images/hospital.svg" sx={hospitalIconSx} />
      <Typography variant="body3" sx={emptyStateTitleSx}>
        We didn't find anything
      </Typography>
      <Typography variant="body5" sx={emptyStateBlurbSx}>
        Try searching for something different or
      </Typography>
      <Typography variant="body5" sx={emptyStateBlurbSx}>
        contact us if you need any help.
      </Typography>
      <Typography variant="body5" sx={emptyStateContactEmailSx}>
        getcare@nomihealth.com
      </Typography>
    </Stack>
  );
}
