import { Box } from '@mui/material';

export interface PageWrapperProps {
  children: React.ReactNode;
}

const styles = {
  container: {
    maxWidth: '480px',
    mx: 'auto',
  },
};

export function PageWrapper({ children }: PageWrapperProps) {
  return <Box sx={styles.container}>{children}</Box>;
}
