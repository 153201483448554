import { GetAlloyAndBankStatusesQuery, ReferenceAccountFragment } from '../../app/graphql/_generated/hooks';

export function getAlloyStatus(data: GetAlloyAndBankStatusesQuery | undefined) {
  const referencesData = data?.complianceInfoForParty?.references as ReferenceAccountFragment[];
  const filteredBankData =
    referencesData?.filter(
      (account) => account.reference_type === 'ALLOY_EVALUATION' || account.reference_type === 'ALLOY_MANUAL_REVIEW'
    ) || [];

  const sortedByDateBankData = [...filteredBankData].sort((acc1, acc2) => {
    // set 1 milisecond for edge when one of the date is not set
    const date1 = acc1.effective_dates?.from_date || 1;
    const date2 = acc2.effective_dates?.from_date || 1;
    // set default utc to 0 it handle edge of the edgecases when date is not valid
    const utc1 = new Date(date1).valueOf() || 0;
    const utc2 = new Date(date2).valueOf() || 0;

    return utc2 - utc1;
  });

  return sortedByDateBankData.length ? sortedByDateBankData[0]?.decision === 'APPROVED' : false;
}
