import { Box, Typography } from '@mui/material';

const styles = {
  wrapper: {
    background: 'rgba(52, 61, 79, 0.4)',
    padding: '0',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '9999',
  },
  icon: {},
  innerBox: {
    background: '#ffffff',
    borderRadius: 4,
    padding: 4,
    width: '260px',
    height: '195px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    margin: '30px 0 15px',
  },
};

function Loading() {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.innerBox}>
        <Box component="img" alt="Loading" src="/images/spinner.svg" sx={styles.icon} />
        <Typography sx={styles.title}>Processing</Typography>
      </Box>
    </Box>
  );
}

export default Loading;
