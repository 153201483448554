import { Box, Button, Checkbox, Link, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { storage_keys } from '../../../../app/constants';
import { localStorage } from '../../../../app/storageApi';
import { NMEFloatingCard, NMEFloatingCardTitleStyled } from '../../../../components/NMEFloatingCard';
import { NMEPageTitle } from '../../../../components/NMEPageTitle';
import { sendOTPToDeviceType } from '../NMELoginPage/NMELoginPage.utils';
import { Content, stylesFromTheme } from '../styles';
import { otpDeviceButtonSx, TermsAndConditionsContainer, termsCheckboxContainerSx } from './NMEOTPDeviceAndTermsPage.styles';
import { ROUTES } from '../../../../constants/routes';
import { ILoginDevice, OTPDeviceType } from '../../../../app/rest-api/new-member-experience-login/types';

interface INewMemberExperienceOTPDeviceAndTermsPageNavigationState {
  shouldDisplayTermsAndConditionsCheckbox: boolean;
  pingFlowID: string;
  devices: ILoginDevice[];
}

export function NMEOTPDeviceAndTermsPage() {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const navigate = useNavigate();
  const location = useLocation();

  const { shouldDisplayTermsAndConditionsCheckbox, pingFlowID, devices } =
    location.state as INewMemberExperienceOTPDeviceAndTermsPageNavigationState;
  const isMultipleOtp = Boolean(devices.length && devices.length > 1);
  let otpMethod, otpValue;

  if (devices.length) {
    if (devices[0].type === 'EMAIL') {
      otpMethod = 'email';
      otpValue = devices[0].email;
    } else {
      otpMethod = 'phone number';
      otpValue = devices[0].phone;
    }
  }

  const [selectedOTPDeviceType, setSelectedOTPDeviceType] = useState<OTPDeviceType | null>(null);
  const [haveCheckedTermsCheckbox, setHaveCheckedTermsCheckbox] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    if (isMultipleOtp && (!selectedOTPDeviceType || (shouldDisplayTermsAndConditionsCheckbox && !haveCheckedTermsCheckbox))) {
      return;
    }

    if (shouldDisplayTermsAndConditionsCheckbox && haveCheckedTermsCheckbox) {
      localStorage.setItem(storage_keys.user_has_agreed_to_accept_terms, 'true');
    }
    if (isMultipleOtp && selectedOTPDeviceType) {
      localStorage.setItem(storage_keys.otp_device_type_preference, selectedOTPDeviceType);
      sendOTPToDeviceType(selectedOTPDeviceType, pingFlowID);
    } else {
      localStorage.setItem(storage_keys.saved_otp_method_id, devices[0].id);
      localStorage.setItem(storage_keys.otp_device_type_preference, devices[0].type);
    }

    navigate(ROUTES.otp, { state: { pingFlowID, isMultipleOtp } });
  }, [
    isMultipleOtp,
    selectedOTPDeviceType,
    shouldDisplayTermsAndConditionsCheckbox,
    haveCheckedTermsCheckbox,
    navigate,
    pingFlowID,
    devices,
  ]);

  const toggleTermsCheckbox = useCallback(() => {
    setHaveCheckedTermsCheckbox(!haveCheckedTermsCheckbox);
  }, [haveCheckedTermsCheckbox]);

  return (
    <Stack sx={styles.mainWrapper}>
      <Content>
        <Box data-id="page-title-container" style={styles.pageTitleContainer}>
          <NMEPageTitle>Let's get verified</NMEPageTitle>
        </Box>
        <Stack>
          <NMEFloatingCard>
            <NMEFloatingCardTitleStyled sx={{ height: isMultipleOtp ? '60px' : 'auto' }}>
              {isMultipleOtp ? (
                <Typography typography="body5">
                  For security purposes, we’ll send you a one-time-password to your device. How would you like to receive this code?
                </Typography>
              ) : (
                <Typography typography="body5">
                  For security purposes, we’ll send you a one-time-password to your {otpMethod} <strong>{otpValue}</strong>.
                </Typography>
              )}
            </NMEFloatingCardTitleStyled>
            {isMultipleOtp && (
              <>
                <Button
                  id="email-otp-device-button"
                  color="primary"
                  variant={selectedOTPDeviceType === OTPDeviceType.EMAIL ? 'contained' : 'outlined'}
                  onClick={() => setSelectedOTPDeviceType(OTPDeviceType.EMAIL)}
                  sx={otpDeviceButtonSx}
                  disableRipple
                >
                  Use Email
                </Button>
                <Button
                  id="sms-otp-device-button"
                  color="primary"
                  variant={selectedOTPDeviceType === OTPDeviceType.SMS ? 'contained' : 'outlined'}
                  onClick={() => setSelectedOTPDeviceType(OTPDeviceType.SMS)}
                  sx={otpDeviceButtonSx}
                  disableRipple
                >
                  Use Phone
                </Button>
              </>
            )}
          </NMEFloatingCard>

          <TermsAndConditionsContainer>
            <Link
              id="privacy-policy-link"
              href="https://nomihealth.com/privacypolicy"
              sx={styles.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              VIEW PRIVACY POLICY
              <Box component="img" alt="View Privacy Policy" src="images/up-right-arrow-icon.svg" />
            </Link>
            <Link id="hippa-policy-link" href="https://nomihealth.com/hipaa" sx={styles.link} rel="noopener noreferrer" target="_blank">
              VIEW HIPAA POLICY
              <Box component="img" alt="View HIPPA POLICY" src="images/up-right-arrow-icon.svg" />
            </Link>
          </TermsAndConditionsContainer>
        </Stack>
      </Content>
      <Stack sx={styles.footerSection}>
        {shouldDisplayTermsAndConditionsCheckbox && (
          <Stack sx={termsCheckboxContainerSx}>
            <Checkbox id="terms-and-conditions-checkbox" checked={haveCheckedTermsCheckbox} onClick={toggleTermsCheckbox} />
            <Typography sx={{ fontSize: '16px' }}>
              I agree to the{' '}
              <Link
                id="terms-and-conditions-link"
                href="https://nomihealth.com/terms-and-conditions/"
                rel="noopener noreferrer"
                target="_blank"
                underline="none"
              >
                Terms & Conditions
              </Link>
            </Typography>
          </Stack>
        )}
        <Button
          id="continue-button"
          type="submit"
          color="primary"
          variant="contained"
          sx={styles.continueButton}
          onClick={handleSubmit}
          disabled={
            isMultipleOtp && (selectedOTPDeviceType === null || (shouldDisplayTermsAndConditionsCheckbox && !haveCheckedTermsCheckbox))
          }
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}

export default NMEOTPDeviceAndTermsPage;
