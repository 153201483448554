import * as yup from 'yup';
import { numeric } from '../../../constants/regex';

export const NewProviderSearchFormSchema = yup.object({
  groupID: yup.string().trim().required('Group ID is required'),
  zipCode: yup
    .string()
    .trim()
    .required('ZIP code is required')
    .matches(numeric, 'Only digits are allowed')
    .length(5, 'ZIP code should be 5 digits'),
});
