import { Box, Button, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CONTACT_DATA } from '../../app/constants';
import { ROUTES } from '../../constants/routes';

const styles = {
  wrapper: {
    background: '#F8F9FD',
    padding: '0 16px',
    height: 'calc(100vh - 250px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {},
  title: {
    fontSize: '32px',
    fontWeight: '600',
    margin: '30px 0 15px',
    textAlign: 'center',
  },
  text: {
    fontSize: '15px',
    fontWeight: '400',
    margin: '0 0 25px 0',
    textAlign: 'center',
  },
  btn: { width: 'calc(100% - 32px)' },

  textBtn: {
    width: '100%',
    marginTop: '10px',
    textTransform: 'none !important',
  },
};

export interface ErrorProps {
  text: string;
  description?: string;
  action?: string;
}

function Error({ description, text, action }: ErrorProps) {
  const navigate = useNavigate();

  return (
    <Box data-id="error" sx={styles.wrapper}>
      <Box component="img" alt="Warning!" src="/images/warning.svg" sx={styles.icon} />
      <Typography sx={styles.title}>{text}</Typography>
      <Typography sx={styles.text}>{description}</Typography>
      <Typography sx={styles.text}>
        Please contact{' '}
        <Link href={`mailto:${CONTACT_DATA.nomiEmail}`} underline="none">
          {CONTACT_DATA.nomiEmail}
        </Link>{' '}
        for further assistance.
      </Typography>
      <>
        {action === 'go-back' && (
          <Button
            sx={styles.btn}
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </Button>
        )}
        {action === 'go-home' && (
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              navigate(ROUTES.root);
            }}
          >
            Go Home
          </Button>
        )}
      </>
    </Box>
  );
}

export default Error;
