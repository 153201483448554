import { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import { ModalSpinner } from '@nomi-health-inc/components-ui';
import { Header, HeaderNav } from '../../components/Header';
import { Footer } from '../../components/Footer';
import IdCard from '../../components/IdCard/IdCard';
import { SearchPracticeFacilityCard } from '../../components/SearchPracticeFacility';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';
import { useGetMemberIdCardUrlLazyQuery, useMemberByFieldQuery } from '../../app/graphql/_generated/hooks';
import { extractData, MemberDetailsData } from '../WalletPage.utils';
import { fetchIDCardPDFDataAndShare } from './NMEWalletPage.utils';
import { styles } from './NMEWalletPage.styles';

function NMEWalletPage() {
  const [memberDetails, setMemberDetails] = useState<MemberDetailsData | null>(null);

  const { loading } = useMemberByFieldQuery({
    variables: {
      member: { party_id: getCurrentUserPartyId() },
    },
    onCompleted: (data) => {
      setMemberDetails(extractData(data.memberByFields));
    },
  });

  const [runGetMemberIdCardURLQuery, { error: idCardRetrievalError, loading: areDownloadingIDCard }] = useGetMemberIdCardUrlLazyQuery();

  const handleShareIDCardButtonClick = useCallback(async () => {
    if (!memberDetails?.id) {
      return;
    }

    const urlResponse = await runGetMemberIdCardURLQuery({ variables: { memberId: memberDetails.id } });

    if (idCardRetrievalError || !urlResponse.data?.memberCardUrl) {
      // eslint-disable-next-line no-console
      console.log('Failed to download member ID card URL.', idCardRetrievalError || '');
      return;
    }

    fetchIDCardPDFDataAndShare(urlResponse.data.memberCardUrl, memberDetails?.names);
  }, [runGetMemberIdCardURLQuery, idCardRetrievalError, memberDetails?.id, memberDetails?.names]);

  return (
    <Box sx={styles.pageContainer}>
      <HeaderNav />
      <Header titleText={`Hi, ${memberDetails?.first_name || ''}`} />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.idCardSectionContainer}>
          {!loading && <IdCard memberDetails={memberDetails} />}
          <Button variant="contained" color="secondary" sx={styles.button} onClick={handleShareIDCardButtonClick}>
            Share ID Card
          </Button>
        </Box>
        <Box sx={styles.activitySectionContainer}>
          <SearchPracticeFacilityCard />
        </Box>
      </Box>
      <Footer />
      <ModalSpinner loading={loading || areDownloadingIDCard} text="Processing" />
    </Box>
  );
}

export default NMEWalletPage;
