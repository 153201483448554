import axios from 'axios';
import { CURRENT_ENVIRONMENT } from '../../environments';
import { IGeocodeResponse, IReverseGeocodeResponse } from './types';

export const URLS = {
  getZipCodeFromLatLong: (latitude: number, longitude: number) =>
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?country=us&types=postcode&access_token=${CURRENT_ENVIRONMENT.MAPBOX_API_TOKEN}&autocomplete=true`,
  getCenterCoordinateFromZipCode: (zipCode: string) =>
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?country=us&types=postcode&access_token=${CURRENT_ENVIRONMENT.MAPBOX_API_TOKEN}&autocomplete=true`,
};

export const getZipCodeFromLatLong = async (latitude: number, longitude: number) => {
  const response = await axios.get<IReverseGeocodeResponse>(URLS.getZipCodeFromLatLong(latitude, longitude));

  if (response.status !== 200 || !response.data?.features || response.data.features.length === 0) {
    return null;
  }

  return response.data.features[0].text;
};

export const getLatLongFromZipCode = async (zipCode: string) => {
  const response = await axios.get<IGeocodeResponse>(URLS.getCenterCoordinateFromZipCode(zipCode));

  if (
    response.status !== 200 ||
    !response.data?.features ||
    response.data.features.length === 0 ||
    response.data.features[0].center.length < 2
  ) {
    return null;
  }

  const coordinate = response.data.features[0].center; // [long, lat]

  return {
    longitude: coordinate[0],
    latitude: coordinate[1],
  };
};
