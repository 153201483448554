import { ILoginDevice, OTPDeviceType } from '../../../../app/rest-api/new-member-experience-login/types';
import { IActiveOTPDevices } from './NMELoginPage.types';
import { localStorage } from '../../../../app/storageApi';
import { storage_keys } from '../../../../app/constants';
import { requestOTP } from '../../../../app/rest-api/new-member-experience-login';

export const getActiveOTPDevices = (devices: ILoginDevice[]): IActiveOTPDevices => {
  const phone = devices.find((d) => d.status === 'ACTIVE' && d.type === OTPDeviceType.SMS);
  const email = devices.find((d) => d.status === 'ACTIVE' && d.type === OTPDeviceType.EMAIL);

  return {
    phone,
    email,
  };
};

export const sendOTPToDeviceType = async (deviceType: OTPDeviceType, pingFlowID: string) => {
  const device: ILoginDevice | null =
    deviceType === OTPDeviceType.SMS
      ? localStorage.getObjValue(storage_keys.sms_otp_device)
      : localStorage.getObjValue(storage_keys.email_otp_device);

  if (device) {
    localStorage.setItem(storage_keys.saved_otp_method_id, device.id);

    await requestOTP(pingFlowID, device.id);
  } else {
    // eslint-disable-next-line no-console
    console.log(`No active ${deviceType} OTP device for account.`);
  }
};
