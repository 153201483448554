import { Theme } from '@mui/material';

export const nomiLogoSx = (theme: Theme) => ({
  marginTop: theme.spacing(7),
  [theme.breakpoints.down('sm')]: {
    width: '130px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '160px',
  },
});
