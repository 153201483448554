import { Box, Button, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Condition } from '@nomi-health-inc/components-ui';
import {
  backButtonContainerSx,
  backButtonSx,
  careGuideBlurbContainerSx,
  careGuideContactInfoBackgroundSx,
  careGuideContactInfoCallToActionTitleSx,
  careGuideContactInfoDetailsSx,
  contactACareGuideBlurbSx,
  contactACareGuideTitleSx,
  contentContainerSx,
  detailsContainerSx,
  envelopeIconSx,
  locationNameSx,
  mobilePhoneIconSx,
  scrollableContentContainerSx,
} from './NewFRNLocationDetails.styles';
import { ChevronLeft } from '@mui/icons-material';
import { ContactDetail } from './ContactDetail';
import { usePracticeFacilityDetails } from './usePracticeFacilityDetails';
import { boldInfoText, highDeductiblePlanSx } from '../NewProviderSearch.styles';
import { AnimatedNomiLogoLoadingModal } from '../../../LinearLoadingModal/AnimatedNomiLogoLoadingModal';

interface NewFRNLocationDetailsProps {
  practiceFacilityId: string | null;
  onBackButtonClick: () => void;
}

export function NewFRNLocationDetails({ practiceFacilityId, onBackButtonClick }: NewFRNLocationDetailsProps) {
  const { locationDetails: details, loading } = usePracticeFacilityDetails(practiceFacilityId);

  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack sx={contentContainerSx}>
      <Condition when={areOnMobileDevice}>
        <Box sx={highDeductiblePlanSx}>
          <Typography variant="body5">Part of a High Deductible Plan?</Typography>
          <Typography variant="body5">
            Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>

      <Box sx={backButtonContainerSx}>
        <Button variant="text" onClick={onBackButtonClick} sx={backButtonSx}>
          <ChevronLeft />
          Go Back
        </Button>
      </Box>

      <AnimatedNomiLogoLoadingModal loading={loading} />

      <Box sx={scrollableContentContainerSx}>
        <Condition when={!loading}>
          <Stack spacing="1rem" sx={detailsContainerSx}>
            <Typography variant="h5" sx={locationNameSx}>
              {details?.name}
            </Typography>
            <ContactDetail
              contact={{
                type: 'Phone',
                values: [details?.phone],
                iconPath: '/images/phone.svg',
                iconAlt: 'phone-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Office',
                values: [details?.firstAddressLine, details?.secondAddressLine],
                iconPath: '/images/hospital.svg',
                iconAlt: 'hospital-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Website',
                values: [details?.website],
                iconPath: '/images/globe.svg',
                iconAlt: 'globe-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Specialties',
                values: details?.specialties || [],
                iconPath: '/images/hospital-user.svg',
                iconAlt: 'hospital-user-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Providers',
                values: details?.providers || [],
                iconPath: '/images/user-md.svg',
                iconAlt: 'doctor-icon',
              }}
            />
          </Stack>
          <Stack sx={careGuideBlurbContainerSx}>
            <Typography variant="body4" sx={contactACareGuideTitleSx}>
              Contact A Care Guide
            </Typography>
            <Typography sx={contactACareGuideBlurbSx}>
              Care guides can help you understand your costs, answer questions, and guide you through your journey to set an appointment
            </Typography>
          </Stack>
          <Stack alignItems="center" spacing={1} sx={careGuideContactInfoBackgroundSx}>
            <Typography sx={careGuideContactInfoCallToActionTitleSx}>Call, text, or email a care guide </Typography>
            <Stack direction="row" alignItems="center">
              <Box component="img" alt="mobile-phone-icon" src="/images/mobile-phone.svg" sx={mobilePhoneIconSx} />
              <Link href="tel:+18556011900" underline="always" sx={careGuideContactInfoDetailsSx}>
                855-601-1900
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box component="img" alt="envelope-icon" src="/images/envelope.svg" sx={envelopeIconSx} />
              <Link href="mailto:getcare@nomihealth.com" underline="always" sx={careGuideContactInfoDetailsSx}>
                getcare@nomihealth.com
              </Link>
            </Stack>
          </Stack>
        </Condition>
      </Box>
    </Stack>
  );
}
