import { SxProps, Theme } from '@mui/material';

export const iconContainerSx = {
  marginRight: '.5rem',
  width: '12px',
  height: '12px',
} as SxProps<Theme>;

export const detailHeaderSx = {
  color: (theme) => theme.palette.custom.darkBlue,
  fontSize: '.875rem',
  lineHeight: '1rem',
  fontWeight: '700',
} as SxProps<Theme>;

export const detailValueSx = {
  color: (theme) => theme.palette.custom.primaryGrey,
  fontSize: '.875rem',
  lineHeight: '1rem',
  fontWeight: '400',
} as SxProps<Theme>;

export const detailLinkSx = {
  fontSize: '.875rem',
  lineHeight: '1rem',
  fontWeight: '400',
} as SxProps<Theme>;
