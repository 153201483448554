import { Box, Button, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Condition } from '@nomi-health-inc/components-ui';
import { ProviderListElement } from '../NewProviderSearch.types';
import {
  backButtonContainerSx,
  backButtonSx,
  careGuideBlurbContainerSx,
  careGuideContactInfoBackgroundSx,
  careGuideContactInfoCallToActionTitleSx,
  careGuideContactInfoDetailsSx,
  contactACareGuideBlurbSx,
  contentContainerSx,
  detailsContainerSx,
  envelopeIconSx,
  mobilePhoneIconSx,
  providerNameSx,
  scrollableContentContainerSx,
} from './NewFRNProviderDetails.styles';
import { ChevronLeft } from '@mui/icons-material';
import { ContactDetail } from './ContactDetail';
import { contactACareGuideTitleSx } from './NewCCNProviderDetails.styles';
import { useCallback } from 'react';
import { SpecialtyToTaxonomies } from '../../../../app/graphql/_generated/hooks';
import { usePracticeFacilityDetails } from './usePracticeFacilityDetails';
import { boldInfoText, highDeductiblePlanSx } from '../NewProviderSearch.styles';
import { AnimatedNomiLogoLoadingModal } from '../../../LinearLoadingModal/AnimatedNomiLogoLoadingModal';

interface NewProviderDetailsProps {
  providerDetails: ProviderListElement | null;
  onBackButtonClick: () => void;
  specialtyToTaxonomies: SpecialtyToTaxonomies[];
}

export function NewFRNProviderDetails({ providerDetails, onBackButtonClick, specialtyToTaxonomies }: NewProviderDetailsProps) {
  const { locationDetails, loading } = usePracticeFacilityDetails(providerDetails?.practiceFacilityId);

  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const convertReferenceCodesToSpecialties = useCallback(
    (codes: string[] | undefined) => {
      if (!codes || codes.length === 0) {
        return [];
      }

      const specialties: string[] = [];

      for (const entry of specialtyToTaxonomies) {
        if (codes.length === specialties.length) {
          break;
        }

        for (const code of codes) {
          if (entry.taxonomies?.includes(code) && entry.specialty) {
            specialties.push(entry.specialty);
          }
        }
      }

      return specialties;
    },
    [specialtyToTaxonomies]
  );

  return (
    <Stack sx={contentContainerSx}>
      <Condition when={areOnMobileDevice}>
        <Box sx={highDeductiblePlanSx}>
          <Typography variant="body5">Part of a High Deductible Plan?</Typography>
          <Typography variant="body5">
            Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>

      <Box sx={backButtonContainerSx}>
        <Button variant="text" onClick={onBackButtonClick} sx={backButtonSx}>
          <ChevronLeft />
          Go Back
        </Button>
      </Box>

      <AnimatedNomiLogoLoadingModal loading={loading} />

      <Box sx={scrollableContentContainerSx}>
        <Condition when={!loading}>
          <Stack spacing="1rem" sx={detailsContainerSx}>
            <Typography variant="h5" sx={providerNameSx}>
              {providerDetails?.name}
            </Typography>
            <ContactDetail
              contact={{
                type: 'Phone',
                values: [locationDetails?.phone],
                iconPath: '/images/phone.svg',
                iconAlt: 'phone-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Office',
                values: [locationDetails?.firstAddressLine, locationDetails?.secondAddressLine],
                iconPath: '/images/hospital.svg',
                iconAlt: 'hospital-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Website',
                values: [locationDetails?.website],
                iconPath: '/images/globe.svg',
                iconAlt: 'globe-icon',
              }}
            />
            <ContactDetail
              contact={{
                type: 'Specialties',
                values: convertReferenceCodesToSpecialties(providerDetails?.referenceCodeIds) || [],
                iconPath: '/images/hospital-user.svg',
                iconAlt: 'hospital-user-icon',
              }}
            />
          </Stack>
          <Stack sx={careGuideBlurbContainerSx}>
            <Typography variant="body4" sx={contactACareGuideTitleSx}>
              Contact A Care Guide
            </Typography>
            <Typography sx={contactACareGuideBlurbSx}>
              Care guides can help you understand your costs, answer questions, and guide you through your journey to set an appointment
            </Typography>
          </Stack>
          <Stack alignItems="center" spacing={1} sx={careGuideContactInfoBackgroundSx}>
            <Typography sx={careGuideContactInfoCallToActionTitleSx}>Call, text, or email a care guide </Typography>
            <Stack direction="row" alignItems="center">
              <Box component="img" alt="mobile-phone-icon" src="/images/mobile-phone.svg" sx={mobilePhoneIconSx} />
              <Link href="tel:+18556011900" underline="always" sx={careGuideContactInfoDetailsSx}>
                855-601-1900
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box component="img" alt="envelope-icon" src="/images/envelope.svg" sx={envelopeIconSx} />
              <Link href="mailto:getcare@nomihealth.com" underline="always" sx={careGuideContactInfoDetailsSx}>
                getcare@nomihealth.com
              </Link>
            </Stack>
          </Stack>
        </Condition>
      </Box>
    </Stack>
  );
}
