export enum EnvironmentEnum {
  Sandbox = 'sandbox',
  Development = 'development',
  QualityAssurance = 'qualityassurance',
  Production = 'production',
}

export interface Variables {
  PUBLIC_BASE_URL: string;
  GQL_BASE_URL: string;
  PING: {
    ENVIRONMENT_ID: string;
    CLIENT_ID: string;
    TELLUS_CLIENT_ID: string;
    REDIRECT_URI: string;
  };
  MAPBOX_API_TOKEN: string;
  NETWORK_ID: string;
}

export const CURRENT_ENV_NAME: EnvironmentEnum = window?.config ? (window.config.env as EnvironmentEnum) : EnvironmentEnum.Sandbox;
export const constants: Record<EnvironmentEnum, Variables> = {
  [EnvironmentEnum.Sandbox]: {
    GQL_BASE_URL: 'https://stable-public-api.nomihealth-sandbox.net/',
    PUBLIC_BASE_URL: 'https://stable-public-api.nomihealth-sandbox.net/api/',
    PING: {
      ENVIRONMENT_ID: 'e5cbf992-0ae4-457b-9ad8-e293f553ab5b',
      CLIENT_ID: 'ab4dc308-8ed6-442d-99c1-7b453dcc983c',
      TELLUS_CLIENT_ID: '5482d9c2-1a51-4305-855c-32219f6d8817', //5482d9c2-1a51-4305-855c-32219f6d8817
      REDIRECT_URI: `${window.location.origin}/callback`,
    },
    MAPBOX_API_TOKEN: 'pk.eyJ1Ijoibm9taWhlYWx0aCIsImEiOiJja2twZ3llMTEwOHBuMnBwYXRxZGVkODF4In0.0mPaRYJ6tp41I0OaccLHAw',
    NETWORK_ID: '1',
  },
  [EnvironmentEnum.Development]: {
    GQL_BASE_URL: 'https://stable-public-api.nomihealth-dev.net/',
    PUBLIC_BASE_URL: 'https://stable-public-api.nomihealth-dev.net/api/',
    PING: {
      ENVIRONMENT_ID: 'e5cbf992-0ae4-457b-9ad8-e293f553ab5b',
      CLIENT_ID: 'ab4dc308-8ed6-442d-99c1-7b453dcc983c',
      TELLUS_CLIENT_ID: '5482d9c2-1a51-4305-855c-32219f6d8817', //5482d9c2-1a51-4305-855c-32219f6d8817
      REDIRECT_URI: `${window.location.origin}/callback`,
    },
    MAPBOX_API_TOKEN: 'pk.eyJ1Ijoibm9taWhlYWx0aCIsImEiOiJja2twZ3llMTEwOHBuMnBwYXRxZGVkODF4In0.0mPaRYJ6tp41I0OaccLHAw',
    NETWORK_ID: '3c064cff-29d6-4b07-ba40-a488fd1c649f',
  },
  [EnvironmentEnum.QualityAssurance]: {
    GQL_BASE_URL: 'https://stable-public-api.nomihealth-qa.net/',
    PUBLIC_BASE_URL: 'https://stable-public-api.nomihealth-qa.net/api/',
    PING: {
      ENVIRONMENT_ID: '44870ef0-9876-49d6-bfd3-c7bdabf99024',
      CLIENT_ID: '194db0c0-9d5b-4175-aee2-3f5e7056c82b',
      TELLUS_CLIENT_ID: 'b83d39fe-fdf6-4db2-8c68-22fc0746dbcb', //5482d9c2-1a51-4305-855c-32219f6d8817
      REDIRECT_URI: `${window.location.origin}/callback`,
    },
    MAPBOX_API_TOKEN: 'pk.eyJ1Ijoibm9taWhlYWx0aCIsImEiOiJja2twZ3llMTEwOHBuMnBwYXRxZGVkODF4In0.0mPaRYJ6tp41I0OaccLHAw',
    NETWORK_ID: '831173cd-ff48-4a52-a259-53cb90ee9abf',
  },
  [EnvironmentEnum.Production]: {
    PUBLIC_BASE_URL: 'https://stable-public-api.nomihealth-prod.net/api/',
    GQL_BASE_URL: 'https://stable-public-api.nomihealth-prod.net/',
    PING: {
      ENVIRONMENT_ID: 'b8b78540-87fe-4e76-b39b-299333fcbe4a',
      CLIENT_ID: 'c187f324-2ef7-4810-a02a-d26f9c0bffc3',
      TELLUS_CLIENT_ID: '98b7738f-907e-4294-95e7-f1c6aa0f0e5f',
      REDIRECT_URI: `${window.location.origin}/callback`,
    },
    MAPBOX_API_TOKEN: 'pk.eyJ1Ijoibm9taWhlYWx0aCIsImEiOiJja2twZ3llMTEwOHBuMnBwYXRxZGVkODF4In0.0mPaRYJ6tp41I0OaccLHAw',
    NETWORK_ID: 'f46abd05-06a0-4169-963e-c28103e6726a',
  },
};

export const CURRENT_ENVIRONMENT = constants[CURRENT_ENV_NAME];
