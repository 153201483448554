import { Theme } from '@mui/material';

export const styles = {
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
  },
  backButton: {
    marginLeft: '20px',
    padding: '0',
    alignSelf: 'baseline',
  },
  marginLeft: {
    marginLeft: '20px',
  },
  fullWidthContainer: {
    width: '100%',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
  },
};
