import { Box, Theme, Typography } from '@mui/material';
import { ICardPaymentMethod, PaymentMethod } from '../../features/api/paymentMethods/types';
import isAfter from 'date-fns/isAfter';
import { useMemo } from 'react';

export interface BankPaymentMethodProps {
  data: PaymentMethod;
}
export interface CardPaymentMethodProps {
  data: ICardPaymentMethod;
}

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '& :hover': {
      cursor: 'pointer',
    },
  },
  icon: { marginRight: '24px', marginLeft: '14px' },
  cardIcon: {
    width: '50px',
    marginRight: '10px',
  },
  type: {
    fontSize: '15px',
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  status: {
    fontStyle: 'italic',
    fontSize: '15px',
    fontWeight: '400',
    ml: '10px',
  },
  details: {
    color: (theme: Theme) => theme.palette.custom.grey,
    fontSize: '15px',
  },
  expired: {
    background: (theme: Theme) => theme.palette.error.main,
    color: (theme: Theme) => theme.palette.common.white,
    fontSize: '11px',
    fontWeight: 500,
    padding: '2px 6px',
    borderRadius: '8px',
  },
};

export function CardPaymentMethod({ data }: CardPaymentMethodProps) {
  // eslint-disable-next-line no-nested-ternary
  const icon = data?.brand === 'visa' ? 'visa' : data?.brand === 'mastercard' ? 'mastercard' : 'card-default';
  const notExpired = isAfter(new Date(Number(data?.exp_year), Number(data?.exp_month), 1), new Date());

  return (
    <Box data-id="card-pm" sx={styles.wrapper}>
      <Box component="img" alt="Credit card" src={`/images/card-logos/${icon}.svg`} sx={styles.cardIcon} />
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={styles.type}>{data?.brand}</Typography>
        </Box>
        <Typography sx={styles.details}>Credit •• {data?.last4}</Typography>
      </Box>
      <Box>{!notExpired && <Typography sx={styles.expired}>Expired</Typography>}</Box>
    </Box>
  );
}

// eslint-disable-next-line react/no-multi-comp
export function BankPaymentMethod({ data }: BankPaymentMethodProps) {
  const accountName = useMemo(() => {
    return `${data?.bank_account?.external_type === 'EXTERNAL_TYPE_SAVINGS' ? 'Savings' : 'Checking'} •• ${
      data?.bank_account?.account_number_last_four
    }`;
  }, [data]);

  return (
    <Box data-id="bank-pm" sx={styles.wrapper}>
      <Box component="img" alt="Bank" src="/images/bank-icon.svg" sx={styles.icon} />
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={styles.type}>{data?.bank_account?.bank_name}</Typography>
          <Typography sx={styles.status}>{data?.status === 'DENIED' && '(processing)'}</Typography>
        </Box>
        <Typography sx={styles.details}>{accountName}</Typography>
      </Box>
    </Box>
  );
}
