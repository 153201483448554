import { Typography } from '@mui/material';
import { ModalSpinner } from '@nomi-health-inc/components-ui';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage_keys } from '../../../../../app/constants';
import { getCurrentUserPartyId } from '../../../../../app/graphql/auth.utils';
import { useGetDependentsLazyQuery, useGetPartyByIdQuery, useUpdateContactInfoMutation } from '../../../../../app/graphql/_generated/hooks';
import GuttersContainer from '../../../../../components/GuttersContainer';
import { normalisePhoneNumber } from '../../../../../utils/format-phone';
import { welcomeTitleSx } from '../../NMEWelcomePage/WelcomePage.styles';
import { IEditablePrimaryMemberContactInfo, IPrimaryMemberContactInfo } from './NMEPrimaryContactInfoConfirmationPage.types';
import { extractContactInfo } from './NMEPrimaryContactInfoConfirmationPage.utils';
import NMEMemberContactInfoConfirmationForm from '../MemberContactInfoConfirmationForm';
import NMEPrimaryMemberContactInfoEditForm from './NMEPrimaryMemberContactInfoEditForm';
import { ROUTES } from '../../../../../constants/routes';

function NMEPrimaryContactInfoConfirmationPage() {
  const navigate = useNavigate();

  const [areEditing, setAreEditing] = useState<boolean>(false);
  const [initialContactInfo, setInitialContactInfo] = useState<IPrimaryMemberContactInfo | null>(null);
  const [updatedContactInfo, setUpdatedContactInfo] = useState<IEditablePrimaryMemberContactInfo | null>(null);

  const currentUserPartyId = getCurrentUserPartyId();
  const { loading: areLoadingContactInfo } = useGetPartyByIdQuery({
    variables: { id: currentUserPartyId },
    onCompleted: (data) => {
      setInitialContactInfo(extractContactInfo(data));
    },
  });
  const [fetchDependents, { loading: loadingDependents }] = useGetDependentsLazyQuery({
    variables: {
      member: {
        party_id: currentUserPartyId,
      },
    },
  });

  const [runUpdateContactInfoMutation, { loading: areUpdatingContactInfo, error: contactInfoUpdateError }] = useUpdateContactInfoMutation();

  const handleEditStart = useCallback(() => {
    setAreEditing(true);
  }, []);

  const handleEditAbort = useCallback(() => {
    setAreEditing(false);
  }, []);

  const handleEditCommit = useCallback((data: IEditablePrimaryMemberContactInfo) => {
    setUpdatedContactInfo(data);
    setAreEditing(false);
  }, []);

  const handleConfirmation = useCallback(async () => {
    if (updatedContactInfo) {
      await runUpdateContactInfoMutation({
        variables: {
          party_id: currentUserPartyId,
          preferred_name: updatedContactInfo.preferredName,
          email: updatedContactInfo.email,
          postal_address: updatedContactInfo.mailingAddress,
          city: updatedContactInfo.city,
          state: updatedContactInfo.state,
          zip: updatedContactInfo.zipCode,
          phone_number: normalisePhoneNumber(updatedContactInfo.phoneNumber),
        },
      });

      if (contactInfoUpdateError) {
        // eslint-disable-next-line no-console
        console.log('Failed to update contact information.', contactInfoUpdateError);
        return;
      }
    }

    const dependents = await fetchDependents();

    localStorage.removeItem(storage_keys.user_needs_to_confirm_contact_info);

    if (dependents?.data?.memberByFields?.dependent_members?.length) {
      navigate(ROUTES.dependents);
    } else {
      navigate(ROUTES.welcome);
    }
  }, [updatedContactInfo, fetchDependents, runUpdateContactInfoMutation, currentUserPartyId, contactInfoUpdateError, navigate]);

  const contactInfoToDisplay: IPrimaryMemberContactInfo = useMemo(() => {
    return {
      firstName: initialContactInfo?.firstName || '',
      lastName: initialContactInfo?.lastName || '',
      preferredName: updatedContactInfo ? updatedContactInfo.preferredName : initialContactInfo?.preferredName || '',
      email: updatedContactInfo?.email || initialContactInfo?.email || '',
      mailingAddress: updatedContactInfo?.mailingAddress || initialContactInfo?.mailingAddress || '',
      city: updatedContactInfo?.city || initialContactInfo?.city || '',
      state: updatedContactInfo?.state || initialContactInfo?.state || '',
      zipCode: updatedContactInfo?.zipCode || initialContactInfo?.zipCode || '',
      phoneNumber: updatedContactInfo?.phoneNumber || initialContactInfo?.phoneNumber || '',
      dateOfBirth: initialContactInfo?.dateOfBirth || '',
    };
  }, [initialContactInfo, updatedContactInfo]);

  return (
    <GuttersContainer>
      <Typography sx={welcomeTitleSx} component="h1">
        What is your preferred contact info?
      </Typography>

      {areEditing ? (
        <NMEPrimaryMemberContactInfoEditForm
          contactInfo={contactInfoToDisplay}
          onBackButtonPress={handleEditAbort}
          onSubmit={handleEditCommit}
        />
      ) : (
        <NMEMemberContactInfoConfirmationForm<IPrimaryMemberContactInfo>
          contactInfo={contactInfoToDisplay}
          onUpdateInformationButtonPress={handleEditStart}
          onSubmit={handleConfirmation}
          isLoading={areLoadingContactInfo}
          isPrimary
        />
      )}
      <ModalSpinner loading={areLoadingContactInfo || areUpdatingContactInfo || loadingDependents} />
    </GuttersContainer>
  );
}

export default NMEPrimaryContactInfoConfirmationPage;
