import axios from 'axios';
import {
  IAxiosErrorReason,
  INewProviderSearchQueryParams,
  IPracticeFacilitiesInAreaQueryParams,
  IPracticeFacilityDetailsResponse,
  IPracticeFacilityInArea,
  IProviderSearchResponseData,
  ISpecialtyWithTaxonomyCodes,
} from './types';
import { CURRENT_ENVIRONMENT } from '../../environments';
import { PublicSearchType } from '../../../components/ProviderSearch/NewProviderSearch/useNewProviderSearch';

export const URLS = {
  getPracticeFacilitiesInArea: ({ zip, taxonomies, searchRadiusInMeters, sortOrder, sortBy }: IPracticeFacilitiesInAreaQueryParams) =>
    `${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/practiceFacilitiesInArea${
      '?' +
      `zip_code=${zip}` +
      '&' +
      `${taxonomies.map((t) => `taxonomies=${t}`).join('&')}` +
      `&search_radius_in_meters=${searchRadiusInMeters}` +
      '&' +
      `sort-order-1=${sortOrder}` +
      '&' +
      `sort-by-1=${sortBy}`
    }`,
  newProviderSearch: ({ view, zip, distance, taxonomies, groupId, name, sortBy, sortOrder }: INewProviderSearchQueryParams) =>
    `${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/providerSearch${
      '?' +
      `view=${view}` +
      '&' +
      `zip=${zip}` +
      `${taxonomies.map((t) => `&taxonomies=${t}`).join('')}` +
      '&' +
      `distance=${distance}` +
      '&' +
      `group_id=${groupId || ''}` +
      '&' +
      `name=${name || ''}` +
      '&' +
      `sort-order-1=${sortOrder}` +
      '&' +
      `sort-by-1=${sortBy}`
    }`,
  getSpecialtiesForNetwork: `${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/networkSpecialties?network_id=${CURRENT_ENVIRONMENT.NETWORK_ID}`,
  getPracticeFacilityDetails: (id: string) => `${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}public/practiceDetails?practice_facility_id=${id}`,
};

export const getPracticeFacilitiesInArea = async (
  zip: string,
  taxonomies: string[],
  searchRadiusInMeters: number
): Promise<IPracticeFacilityInArea[]> => {
  const queryParamData: IPracticeFacilitiesInAreaQueryParams = {
    zip: zip,
    taxonomies: taxonomies,
    searchRadiusInMeters: searchRadiusInMeters,
    sortBy: 'distance',
    sortOrder: 'asc',
  };

  const response = await axios
    .get<{
      data: { practiceFacilitiesInArea: IPracticeFacilityInArea[] };
    }>(URLS.getPracticeFacilitiesInArea(queryParamData))
    .catch(({ response }: IAxiosErrorReason) => {
      // eslint-disable-next-line no-console
      console.log('Error occurred during practice facility search', response);
    });

  if (!response || response.status !== 200 || !response.data.data.practiceFacilitiesInArea) {
    throw new Error('Practice facility search unsuccessful');
  }

  return response.data.data.practiceFacilitiesInArea;
};

export const callNewProviderSearchPublicAPI = async (
  searchType: PublicSearchType,
  zip: string,
  searchRadiusInMeters: number,
  taxonomies: string[],
  groupId?: string,
  searchTerm?: string
): Promise<IProviderSearchResponseData> => {
  const queryParamData: INewProviderSearchQueryParams = {
    view: searchType === PublicSearchType.PracticeFacility ? 'LOCATION' : 'PROVIDER',
    zip: zip,
    distance: searchRadiusInMeters,
    taxonomies: taxonomies,
    groupId: groupId,
    name: searchTerm,
    sortBy: 'distance',
    sortOrder: 'asc',
  };

  const response = await axios
    .get<IProviderSearchResponseData>(URLS.newProviderSearch(queryParamData), {
      validateStatus: (status) => status === 200 || status === 500,
    })
    .catch((error) => {
      throw new Error(`Public search API returned status code: ${error.response.status} message: ${error.message}`);
    });

  if (!response || !response?.data) {
    if (response?.status) {
      throw new Error(`Public search API returned status code: ${response.status}`);
    } else {
      throw new Error('Public search API failed to return response.');
    }
  }

  return response.data;
};

export const getSpecialtiesForNetwork = async (): Promise<ISpecialtyWithTaxonomyCodes[]> => {
  const response = await axios
    .get<{ data: ISpecialtyWithTaxonomyCodes[] }>(URLS.getSpecialtiesForNetwork)
    .catch(({ response }: IAxiosErrorReason) => {
      // eslint-disable-next-line no-console
      console.log('Error occurred during specialties for network request', response);
    });

  if (!response || response.status !== 200 || !response.data.data) {
    throw new Error('Specialties for network request unsuccessful');
  }

  return response.data.data;
};

export const getPracticeFacilityDetails = async (practiceFacilityId: string): Promise<IPracticeFacilityDetailsResponse> => {
  const response = await axios
    .get<{
      data: { practiceFacility: IPracticeFacilityDetailsResponse };
    }>(URLS.getPracticeFacilityDetails(practiceFacilityId))
    .catch(({ response }: IAxiosErrorReason) => {
      // eslint-disable-next-line no-console
      console.log('Error occurred during practice facility details request', response);
    });

  if (!response || response.status !== 200 || !response.data || !response.data.data || !response.data.data.practiceFacility) {
    throw new Error('Practice facility details request unsuccessful');
  }

  return response.data.data.practiceFacility;
};
