import { Theme } from '@mui/material';

export const styles = {
  wrapper: {
    padding: '0 16px',
    alignItems: 'center',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
  },
  accordion: {
    border: 'none',
    boxShadow: 'none',
    minHeight: '50px',
    margin: '0 !important',
    width: '100%',
    '&::before': {
      content: 'none',
    },
  },
  details: {
    padding: '8px 0',
  },
  sectionTitle: {
    padding: 0,
    minHeight: 50,
    '& .Mui-expanded': {
      margin: '0 !important',
    },
    '&.Mui-expanded': {
      minHeight: '50px',
    },
  },
  sectionTitleText: {
    fontSize: '20px',
    fontWeight: '500',
    border: 'none',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '500',
  },
  innerAcc: {
    boxShadow: 'none',
    padding: 0,
    minHeight: '30px',
    '&::before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      margin: '12px 0 !important',
    },
  },
  innerAccTitle: {
    minHeight: '0px',
    '& .Mui-expanded': {
      margin: '0 !important',
    },
    '&.Mui-expanded': {
      minHeight: '0px',
      margin: '0 !important',
    },
  },
  innerAccText: {
    padding: '8px 16px 0 16px',
  },
  question: {
    fontSize: '14px',
    color: (theme: Theme) => theme.palette.custom.link,
  },
  answer: {
    fontSize: '12px',
  },
  arrow: {
    color: (theme: Theme) => theme.palette.custom.link,
  },
};
