import { Box, Button, Stack, Typography } from '@mui/material';
import { NMEPrimaryActionButton } from '../../../../../components/NMEActionButton';
import { NMEFloatingCard } from '../../../../../components/NMEFloatingCard';
import { longFormDate } from '../../../../../utils/format-date';
import { formatPhone } from '../../../../../utils/format-phone';
import {
  fieldHeaderSx,
  fieldValueSx,
  secondaryActionButtonContainerSx,
} from '../NMEPrimaryMemberConfirmationPage/NMEPrimaryContactInfoConfirmationPage.styles';
import { IPrimaryMemberContactInfo } from '../NMEPrimaryMemberConfirmationPage/NMEPrimaryContactInfoConfirmationPage.types';
import { minorCallToActionTextSx } from './NMEMemberContactInfoConfirmationForm.styles';
import { Condition } from '@nomi-health-inc/components-ui';
import { formatMemberRelationship } from './NMEMemberContactInfoConfirmation.utils';
import { DependentMemberContactInfo } from '../NMEDependents/DependentDetailPage/DependentDetail.types';

type IPrimaryMemberContactInfoFormProps<T> = {
  contactInfo: T extends IPrimaryMemberContactInfo ? IPrimaryMemberContactInfo : DependentMemberContactInfo;
  onUpdateInformationButtonPress: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  isPrimary?: boolean;
};

function NMEMemberContactInfoConfirmationForm<T>({
  contactInfo,
  onUpdateInformationButtonPress,
  onSubmit,
  isLoading,
  isPrimary,
}: IPrimaryMemberContactInfoFormProps<T>) {
  const { firstName, lastName, preferredName, email, mailingAddress, city, state, zipCode, phoneNumber, dateOfBirth } = contactInfo;

  return (
    <>
      <NMEFloatingCard>
        <Stack spacing="18px" mb="18px">
          <Box>
            <Typography sx={fieldHeaderSx}>First Name</Typography>
            <Typography sx={fieldValueSx}>{firstName}</Typography>
          </Box>
          <Box>
            <Typography sx={fieldHeaderSx}>Last Name</Typography>
            <Typography sx={fieldValueSx}>{lastName}</Typography>
          </Box>
          <Condition when={isPrimary}>
            <Box>
              <Typography sx={fieldHeaderSx}>Preferred Name</Typography>
              {preferredName ? (
                <Typography sx={fieldValueSx}>{preferredName}</Typography>
              ) : (
                <Typography sx={{ ...fieldValueSx, fontStyle: 'italic' }}>{isLoading ? '' : 'Not Provided'}</Typography>
              )}
            </Box>
          </Condition>
          <Condition when={!isPrimary}>
            <Box>
              <Typography sx={fieldHeaderSx}>Relationship</Typography>
              <Typography sx={fieldValueSx}>
                {formatMemberRelationship((contactInfo as DependentMemberContactInfo)?.memberRelationship)}
              </Typography>
            </Box>
          </Condition>
          <Condition when={isPrimary}>
            <Box>
              <Typography sx={fieldHeaderSx}>Email Address</Typography>
              <Typography sx={fieldValueSx}>{email}</Typography>
            </Box>
          </Condition>
          <Box>
            <Typography sx={fieldHeaderSx}>Street Address</Typography>
            <Typography sx={fieldValueSx}>{mailingAddress}</Typography>
          </Box>
          <Stack direction="row" width="100%">
            <Box sx={{ flexGrow: '1' }} mr="16px">
              <Typography sx={fieldHeaderSx}>City</Typography>
              <Typography sx={fieldValueSx}>{city}</Typography>
            </Box>
            <Box sx={{ width: '30%' }}>
              <Typography sx={fieldHeaderSx}>State</Typography>
              <Typography sx={fieldValueSx}>{state}</Typography>
            </Box>
          </Stack>
          <Box>
            <Typography sx={fieldHeaderSx}>ZIP</Typography>
            <Typography sx={fieldValueSx}>{zipCode}</Typography>
          </Box>
          <Box>
            <Typography sx={fieldHeaderSx}>Phone Number</Typography>
            <Typography sx={fieldValueSx}>{formatPhone(phoneNumber)}</Typography>
          </Box>
          <Box>
            <Typography sx={fieldHeaderSx}>Date of Birth</Typography>
            <Typography sx={fieldValueSx}>{longFormDate(dateOfBirth)}</Typography>
          </Box>
        </Stack>
      </NMEFloatingCard>
      <Typography margin="16px 6px 0 6px" sx={minorCallToActionTextSx}>
        If your information has changed be sure to contact your HR department with updates.
      </Typography>
      <Box sx={secondaryActionButtonContainerSx}>
        <Button data-id="update-information-button" onClick={onUpdateInformationButtonPress} disableRipple>
          Update Information
        </Button>
      </Box>
      <NMEPrimaryActionButton data-id="confirmation-button" onClick={onSubmit}>
        This information is correct
      </NMEPrimaryActionButton>
    </>
  );
}

export default NMEMemberContactInfoConfirmationForm;
