import { createTheme, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { ThemeProvider as NomiThemeProvider } from '@nomi-health-inc/components-ui';
import MuiCssBaseline from './overrides/CssBaseline';
import palette from './palette';

export const overrideComponents = (theme: Theme): Theme => ({
  ...theme,
  components: {
    ...theme.components,
    ...MuiCssBaseline(theme),
  },
});

function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <NomiThemeProvider>
        <MuiThemeProvider theme={(outerTheme) => overrideComponents(createTheme(outerTheme, palette))}>{children}</MuiThemeProvider>
      </NomiThemeProvider>
    </>
  );
}

export default ThemeProvider;
