import * as yup from 'yup';

export const emailValidityError =
  'Email address does not match our records. Please contact tellus@support.nomihealth.com for more information';

export const LoginSchema = yup
  .object({
    email: yup.string().required('Email address is required').email(emailValidityError),
  })
  .required();
