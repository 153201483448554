export interface LocationListElement {
  type: 'location';
  id: string;
  name: string;
  firstAddressLine: string;
  secondAddressLine: string;
  coordinates: number[]; // [longitude, latitude]
  distanceInMiles: number | null;
  numberOfProviders: number;
}

export interface LocationDetails {
  name: string;
  firstAddressLine: string;
  secondAddressLine: string;
  phone?: string;
  website?: string;
  providers: string[];
  specialties: string[];
}

export interface ProviderListElement {
  type: 'provider';
  id: string;
  practiceFacilityId: string;
  name: string;
  firstAddressLine: string;
  secondAddressLine: string;
  coordinates: number[]; // [longitude, latitude]
  distanceInMiles: number | null;
  referenceCodeIds: string[];
}

export enum ComponentBeingDisplayed {
  SearchForm = 'search-form',
  SearchResults = 'search-results',
  FRNLocationDetails = 'frn-location-details',
  CCNLocationDetails = 'ccn-location-details',
  FRNProviderDetails = 'frn-provider-details',
  CCNProviderDetails = 'ccn-provider-details',
}
