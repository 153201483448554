import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import LoadingScreen from './Loading';
import { styles } from './styles';
import PageTitle from '../components/PageTitle';
import ShortBillInfo from '../components/partials/ShortBillInfo';
import BoxWithShadow from '../components/boxes/BoxWithShadow';
import { IBillFragment, BillState, BillType, IInvoiceFragment, SortDirection, useGetInvoicesQuery } from '../app/graphql/_generated/hooks';
import { IBillWithInvoiceData } from './types';
import { getCurrentUserPartyId } from '../app/graphql/auth.utils';
import { Error } from '../components/Error';

function BillsPage() {
  const navigate = useNavigate();
  const partyId = getCurrentUserPartyId();

  const { data, loading, error } = useGetInvoicesQuery({
    variables: {
      filter: {
        bill_type: [BillType.Member],
        bill_state: [
          BillState.BillUnknown,
          BillState.BillSubmitted,
          BillState.BillPending,
          BillState.BillAdjudicated,
          BillState.BillFailed,
        ],
        party_id: partyId || '',
      },
      options: { limit: 50, offset: 0, sort: [{ field: 'audit.created_at', direction: SortDirection.Desc }] },
      bill_type: [BillType.Member],
      bill_state: [BillState.BillUnknown, BillState.BillSubmitted, BillState.BillPending, BillState.BillAdjudicated, BillState.BillFailed],
    },
    fetchPolicy: 'network-only',
  });

  const bills = useMemo(() => {
    return data?.invoices?.results?.reduce((acc: IBillWithInvoiceData[], invoice: IInvoiceFragment) => {
      const billsWithInvoiceId =
        invoice?.bills?.map((bill: IBillFragment | null) => ({ ...bill, invoice_id: invoice._id, encounter: invoice.encounter })) || [];

      return [...acc, ...billsWithInvoiceId];
    }, []);
  }, [data]);

  const isBills = bills?.length;

  const onPressOnShortBill = (bill?: IBillWithInvoiceData | null) => () => {
    if (!bill) {
      return;
    }
    if (bill.bill_state === BillState.BillPaid || bill.bill_state === BillState.BillPending) {
      if (bill?.invoice_id) {
        navigate(bill.invoice_id);
      }
    } else {
      navigate(`/pay-bill/${bill.invoice_id}`);
    }
  };

  return (
    <Fragment>
      <PageTitle backUrl="/" title="Outstanding Bills" />

      {loading && <LoadingScreen />}
      {!!error && <Error text="Something Went Wrong" description="Having trouble viewing you bills?" />}
      {!loading && !error && (
        <Fragment>
          <Box sx={styles.box}>
            {isBills ? (
              bills.map((bill) => {
                return (
                  <BoxWithShadow key={bill?.invoice_id}>
                    <ShortBillInfo bill={bill} encounter={bill.encounter} onPress={onPressOnShortBill(bill)} />
                  </BoxWithShadow>
                );
              })
            ) : (
              <BoxWithShadow>
                <Typography sx={styles.empty}>There are currently no unpaid bills</Typography>
              </BoxWithShadow>
            )}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}

export default BillsPage;
