import { format, parseISO, parse } from 'date-fns';

export const dateMMddyyyy = (dateStr?: string): string => {
  if (!dateStr) {
    return '';
  }
  return format(parseISO(dateStr), 'MM/dd/yyyy');
};

export const longFormDate = (date: string) => {
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());

  if (parsedDate.toString() === 'Invalid Date') {
    return '';
  }

  return format(parsedDate, 'd MMMM yyyy');
};
