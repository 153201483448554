import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CURRENT_ENVIRONMENT } from './app/environments';
import client from './app/graphql/apolloClient';
import { store } from './app/store';
import { ThemeProvider } from './components/ThemeProvider';
import AuthProvider from './features/authV2/AuthProvider';
import reportWebVitals from './reportWebVitals';
import RestrictedProvider from './features/authV2/RestrictedProvider';
import App from './App';
import FeatureFlagsProvider from './features/featureFlags/FeatureFlagsProvider';
import './features/analytics/DataDogAnalyticsInit';

const container = document.getElementById('root') as HTMLElement;
const root = container && createRoot(container);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const temp_client = new ApolloClient({
  uri: `${CURRENT_ENVIRONMENT.GQL_BASE_URL}graph/playground/query`,
  cache: new InMemoryCache(),
});

root.render(
  <StrictMode>
    <FeatureFlagsProvider>
      <ApolloProvider client={client}>
        <AuthProvider>
          <RestrictedProvider>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </LocalizationProvider>
            </Provider>
          </RestrictedProvider>
        </AuthProvider>
      </ApolloProvider>
    </FeatureFlagsProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
