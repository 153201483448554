import { Box, Divider, Typography, Container, Theme } from '@mui/material';
import { styles } from '../../pages/pay-bill/styles';
import BoxWithShadow from '../boxes/BoxWithShadow';
import { dateMMddyyyy } from '../../utils/format-date';
import { formatAmount } from '../../utils/format-amount';
import { BillByIdQuery } from '../../app/graphql/_generated/hooks';
import _get from 'lodash/get';

interface BillDetailsSectionProps {
  currentInvoice: BillByIdQuery | undefined;
}

export function BillDetailsSection({ currentInvoice }: BillDetailsSectionProps) {
  const currentBill = currentInvoice?.invoiceById?.bills?.find((bill) => bill?.bill_type === 'MEMBER');
  const sponsorBill = currentInvoice?.invoiceById?.bills?.find((bill) => bill?.bill_type === 'SPONSOR');

  const providerNames = _get(currentInvoice, 'invoiceById.line_items[0].rendering_provider.party.person.current_name.names', []).join(' ');

  const billAudit = currentBill?.audit_logs?.find(
    (log) => log?.remittance_state_transition?.to_state === 'BILL_PAID' || log?.remittance_state_transition?.to_state === 'BILL_PENDING'
  );

  const dateOfServiceDate = currentBill ? dateMMddyyyy(currentInvoice?.invoiceById?.encounter?.service?.service_time) : '';
  const dateOfPaymentDate = currentBill?.cash_payment ? dateOfServiceDate : dateMMddyyyy(billAudit?.transitioned_at_time ?? '');

  const isCashPayment = currentBill?.cash_payment;

  const bankName = currentBill?.payment_method_account
    ? `${currentBill?.payment_method_account?.bank_account?.external_type === 'EXTERNAL_TYPE_SAVINGS' ? 'Savings' : 'Checking'}  •• ${
        currentBill?.payment_method_account?.bank_account?.account_number_last_four
      }`
    : null;
  const cardName = currentBill?.payment_method_stripe
    ? `${currentBill?.payment_method_stripe?.brand} •• ${currentBill?.payment_method_stripe?.last4}`
    : null;

  return (
    <BoxWithShadow>
      <Container data-id="title-container" sx={styles.titleContainer}>
        <Typography sx={styles.title}>{currentInvoice?.invoiceById?.encounter?.practice_facility?.facility_model?.name}</Typography>
        <Typography sx={styles.subtitle}>Provider: {providerNames}</Typography>
        <Typography sx={styles.subtitle}>Date of Service: {dateOfServiceDate}</Typography>
      </Container>
      {currentInvoice?.invoiceById?.line_items && (
        <>
          <Typography sx={styles.serviceInfo}>Covered Services</Typography>
          {currentInvoice?.invoiceById?.line_items.map((service) => {
            return (
              <Box data-id="current-invoice" sx={styles.serviceInfoBlock} key={Math.random()}>
                <Box>
                  <Typography sx={styles.serviceInfoBlock.name}>
                    {service.long_tw_desc}
                    {service?.quantity && service.quantity > 1 && ` x ${service.quantity}`}
                  </Typography>
                </Box>
                <Typography sx={styles.serviceInfoBlock.price}>${formatAmount(service.charge_pennies)}</Typography>
              </Box>
            );
          })}
        </>
      )}
      {/* ------------------------------------------TODO UNCOVERED----------------------------------------- */}

      <Divider sx={styles.divider} />
      <Box data-id="price-block" sx={styles.priceBlock}>
        <Typography sx={{ color: (theme: Theme) => theme.palette.custom.lynch }}>Your Employer Pays:</Typography>
        <Typography data-id="employer-pays" sx={{ color: (theme: Theme) => theme.palette.custom.lynch }}>
          ${formatAmount(sponsorBill?.bill_total_pennies)}
        </Typography>
      </Box>
      <Box sx={styles.priceBlock}>
        <Typography>You Owe:</Typography>
        <Typography data-id="you-owe">${formatAmount(currentBill?.bill_total_pennies)}</Typography>
      </Box>
      {(currentBill?.cash_payment || currentBill?.payment_method) && (
        <>
          <Divider sx={styles.divider} />
          <Box data-id="current-bill-conclusion" sx={styles.conclusionBlock}>
            <Typography sx={styles.finalDataBlock.text}>Bill Paid Date:</Typography>
            <Typography sx={styles.finalDataBlock.text}>{dateOfPaymentDate}</Typography>
          </Box>
          <Box data-id="final-block" sx={styles.conclusionBlock}>
            <Typography sx={styles.finalDataBlock.text}>Payment Method:</Typography>
            <Typography sx={{ ...styles.finalDataBlock.text, textTransform: 'capitalize' }}>
              {isCashPayment ? 'Cash Pay' : bankName || cardName}
            </Typography>
          </Box>
          <Box sx={styles.conclusionBlock}>
            <Typography sx={styles.finalDataBlock.text}>Amount Paid:</Typography>
            <Typography sx={styles.finalDataBlock.text}>${formatAmount(currentBill?.bill_total_pennies)}</Typography>
          </Box>
        </>
      )}
    </BoxWithShadow>
  );
}
