import { Box } from '@mui/material';

function WithFooterWrapper({ children, footer }: { children?: React.ReactNode; footer?: React.ReactNode }) {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1, minHeight: '100vh', flexDirection: 'column' }}>
      {children}
      {footer || (
        <Box component="footer" display="flex" justifyContent="center" marginTop="auto">
          <Box component="img" sx={{ width: '5.25rem', height: 'auto', py: '2.5rem' }} src="/images/powered-by-nomi.svg"></Box>
        </Box>
      )}
    </Box>
  );
}

export default WithFooterWrapper;
