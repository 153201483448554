import { createContext, useContext } from 'react';

export interface IFeatureFlagsContext {
  setAreShowingNewMemberExperience: (newValue: boolean) => void;
  areShowingNewMemberExperience: boolean;
  logoutTimeout: number;
}

export const FeatureFlagsContext = createContext<IFeatureFlagsContext>({} as IFeatureFlagsContext);

export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};
