export const styles = {
  btn: {
    width: '100%',
    marginTop: '50px',
  },
  icon: {
    width: '1.125rem',
    height: '1.125rem',
  },
  infoTextBox: {
    marginTop: '40px',
    color: '#78849E',
  },
  infoTitle: {
    fontSize: '14px',
    fontWeight: '500',
  },
  infoText: {
    fontSize: '14px',
  },
  continueBtn: {
    width: '100%',
    marginTop: '14.5rem',
  },
  controlLabelText: {
    color: '#343D4F',
    fontSize: '16px',
    fontWeight: '500',
  },
  controlLabelErrorText: {
    color: '#343D4F',
    fontSize: '12px',
    fontWeight: '300',
  },
  warningIcon: {
    color: '#F0C31A',
  },
  verificationText: {
    fontSize: '12px',
    fontWeight: '300',
    fontStyle: 'italic',
    color: '#343D4F',
    margin: '-25px 0 5px 40px',
  },
};
