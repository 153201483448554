import BoxWithShadow from '../boxes/BoxWithShadow';
import { useAppSelector } from '../../app/hooks';
import { planUtilization } from '../../features/bills/billsSlice';
import { Box, Container, Typography } from '@mui/material';

const styles = {
  box: {
    display: 'flex',
  },
  text: {
    fontSize: '12px',
    textTransform: 'capitalize',
    lineHeight: '18px',
    color: '#343D4F',
  },
  labelContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftContainer: {
    width: '50%',
    padding: 0,
    paddingRight: 10,
    borderRight: '1px solid #CBD3E3',
  },
  rightSide: {
    width: '50%',
    padding: 0,
    paddingLeft: 10,
  },
  empty: {
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: 0,
    color: '#78849E',
  },
};

export function RemainingVisits() {
  const visits = useAppSelector(planUtilization);
  const half = Math.ceil(visits.length / 2);

  const firstHalf = visits.slice(0, half);
  const secondHalf = visits.slice(half);

  if (!visits.length) {
    return (
      <BoxWithShadow title="Remaining Visits">
        <Typography sx={styles.empty}>There are currently no remaining visits</Typography>
      </BoxWithShadow>
    );
  }

  return (
    <BoxWithShadow data-id="remaining-visits" title="Remaining Visits">
      <Box sx={styles.box}>
        <Container style={styles.leftContainer}>
          {firstHalf.map((visit) => (
            <Container data-id="remaining-visits-labels" key={visit.label} style={styles.labelContainer}>
              <Typography sx={styles.text}>{visit.label}</Typography>
              <Typography sx={styles.text}>{visit.quantity_remaining}</Typography>
            </Container>
          ))}
        </Container>
        <Container data-id="remaining-visits-values" style={styles.rightSide}>
          {secondHalf.map((visit) => (
            <Container key={visit.label} style={styles.labelContainer}>
              <Typography sx={styles.text}>{visit.label}</Typography>
              <Typography sx={styles.text}>{visit.quantity_remaining}</Typography>
            </Container>
          ))}
        </Container>
      </Box>
    </BoxWithShadow>
  );
}
