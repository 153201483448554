import { Checkbox, Stack, styled, Typography } from '@mui/material';

export const CheckboxStyled = styled(Checkbox)(() => ({
  '&.MuiCheckbox-colorPrimary.Mui-disabled': {
    opacity: 1,
  },
}));

export const Container = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

export const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.link,
  fontWeight: 500,
}));
