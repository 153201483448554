import { Box, Button, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Condition } from '@nomi-health-inc/components-ui';
import {
  backButtonContainerSx,
  backButtonSx,
  careGuideContactInfoBackgroundSx,
  careGuideContactInfoCallToActionTitleSx,
  careGuideContactInfoDetailsSx,
  contactACareGuideBlurbSx,
  contactACareGuideTitleSx,
  contentContainerSx,
  detailsContainerSx,
  envelopeIconSx,
  firstAddressLineSx,
  hospitalAddressContainerSx,
  hospitalIconSx,
  mobilePhoneIconSx,
  referralCallToActionTitleSx,
  scrollableContentContainerSx,
  secondAddressLineSx,
  submitAReferralButtonSx,
} from './NewCCNProviderDetails.styles';
import { ChevronLeft } from '@mui/icons-material';
import { ProviderListElement } from '../NewProviderSearch.types';
import { boldInfoText, highDeductiblePlanSx } from '../NewProviderSearch.styles';

interface NewProviderDetailsProps {
  details: ProviderListElement | null;
  onBackButtonClick: () => void;
}

export function NewCCNProviderDetails({ details, onBackButtonClick }: NewProviderDetailsProps) {
  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack sx={contentContainerSx}>
      <Condition when={areOnMobileDevice}>
        <Box sx={highDeductiblePlanSx}>
          <Typography variant="body5">Part of a High Deductible Plan?</Typography>
          <Typography variant="body5">
            Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>

      <Box sx={backButtonContainerSx}>
        <Button variant="text" onClick={onBackButtonClick} sx={backButtonSx}>
          <ChevronLeft />
          Go Back
        </Button>
      </Box>

      <Box sx={scrollableContentContainerSx}>
        <Box sx={detailsContainerSx}>
          <Typography variant="h5">{details?.name}</Typography>
          <Stack direction="row" alignItems="flex-start" sx={hospitalAddressContainerSx}>
            <Stack>
              <Stack direction="row" alignItems="center">
                <Box component="img" alt="hospital-icon" src="/images/hospital.svg" sx={hospitalIconSx} />
                <Typography sx={firstAddressLineSx}>{details?.firstAddressLine || '-'}</Typography>
              </Stack>
              <Typography sx={secondAddressLineSx}>{details?.secondAddressLine || '-'}</Typography>
            </Stack>
          </Stack>
          <Typography variant="body4" sx={contactACareGuideTitleSx}>
            Contact A Care Guide
          </Typography>
          <Typography sx={contactACareGuideBlurbSx}>
            Care guides can help you understand your costs, answer questions, and guide you through your journey to set an appointment
          </Typography>
        </Box>
        <Stack alignItems="center" spacing={1} sx={careGuideContactInfoBackgroundSx}>
          <Typography sx={careGuideContactInfoCallToActionTitleSx}>Call, text, or email a care guide </Typography>
          <Stack direction="row" alignItems="center">
            <Box component="img" alt="mobile-phone-icon" src="/images/mobile-phone.svg" sx={mobilePhoneIconSx} />
            <Link href="tel:+18556011900" underline="always" sx={careGuideContactInfoDetailsSx}>
              855-601-1900
            </Link>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box component="img" alt="envelope-icon" src="/images/envelope.svg" sx={envelopeIconSx} />
            <Link href="mailto:getcare@nomihealth.com" underline="always" sx={careGuideContactInfoDetailsSx}>
              getcare@nomihealth.com
            </Link>
          </Stack>
        </Stack>
        <Stack alignItems="center">
          <Typography sx={referralCallToActionTitleSx}>Already have a referral to send us?</Typography>
          <Button variant="text" href="https://nomihealth.jotform.com/232564708447059" target="_blank" sx={submitAReferralButtonSx}>
            Submit a referral
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
