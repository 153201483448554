import { Theme, Typography } from '@mui/material';
import { BillState } from '../../app/graphql/_generated/hooks';

const possibleStatus = {
  [BillState.BillPaid]: {
    color: (theme: Theme) => theme.palette.custom.lightGreen,
    text: (theme: Theme) => theme.palette.custom.textColor,
    title: 'Paid',
  },
  [BillState.BillPaidExternally]: {
    color: (theme: Theme) => theme.palette.custom.lightGreen,
    text: (theme: Theme) => theme.palette.custom.textColor,
    title: 'Paid Externally',
  },
  [BillState.BillPending]: {
    color: (theme: Theme) => theme.palette.custom.warningYellow,
    text: (theme: Theme) => theme.palette.custom.warningText,
    title: 'Pending',
  },
  [BillState.BillAdjudicated]: {
    color: (theme: Theme) => theme.palette.custom.errorBackground,
    text: (theme: Theme) => theme.palette.error.dark,
    title: 'Not Paid',
  },
  [BillState.BillFailed]: {
    color: (theme: Theme) => theme.palette.custom.errorBackground,
    text: (theme: Theme) => theme.palette.error.dark,
    title: 'Not Paid',
  },
  [BillState.BillSubmitted]: {
    color: (theme: Theme) => theme.palette.custom.errorBackground,
    text: (theme: Theme) => theme.palette.error.dark,
    title: 'Not Paid',
  },
  [BillState.BillPaymentCanceled]: {
    color: (theme: Theme) => theme.palette.custom.errorBackground,
    text: (theme: Theme) => theme.palette.error.dark,
    title: 'Payment Canceled',
  },
  [BillState.BillUnknown]: {
    color: (theme: Theme) => theme.palette.custom.errorBackground,
    text: (theme: Theme) => theme.palette.error.dark,
    title: 'Not Paid',
  },
};

const styles = {
  status: {
    fontSize: '11px',
    fontWeight: '500',
    color: (theme: Theme) => theme.palette.custom.white,
    borderRadius: '8px',
    padding: '4px',
  },
};

interface StatusLabelProps {
  status?: BillState | null;
}

export function StatusLabel({ status }: StatusLabelProps) {
  if (!status) {
    return null;
  }
  return (
    <Typography
      data-id="status-labels"
      sx={{
        ...styles.status,
        background: possibleStatus[status]?.color ?? possibleStatus[BillState.BillUnknown]?.color,
        color: possibleStatus[status]?.text ?? possibleStatus[BillState.BillUnknown]?.text,
      }}
    >
      {possibleStatus[status]?.title ?? possibleStatus[BillState.BillUnknown]?.title}
    </Typography>
  );
}
