import { Theme } from '@mui/material';

export const styles = {
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  mapContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    background: (theme: Theme) => theme.palette.custom.lightGrey,
    flexShrink: 0,
  },
  largeRoundedMap: {
    borderRadius: '20px',
    overflow: 'hidden',
    height: '528px',
    width: (theme: Theme) => theme.breakpoints.values.sm,
  },
  smallMapHeight: {
    height: '373.5px',
  },
  mapControlsBox: {
    position: 'absolute',
    top: '1.125rem',
    right: '1.56rem',
    width: '5.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  userLocationButton: {
    padding: '0',
    backgroundColor: (theme: Theme) => theme.palette.custom.white,
    borderRadius: '50%',
    marginRight: '0.5rem',
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette.custom.white,
    },
  },
  zoomBox: {
    backgroundColor: (theme: Theme) => theme.palette.custom.white,
    borderRadius: '0.875rem',
    padding: '0 4px',
  },
  zoomIcon: {
    padding: '0',
    width: '1.5rem',
    height: '1.5rem',
    marginTop: '-0.125rem',
  },
};
