import { Theme } from '@mui/material';

export const styles = {
  searchFieldsContainer: {
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    flexDirection: 'column',
  },
  searchFieldHeader: {
    color: (theme: Theme) => theme.palette.custom.darkBlue,
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: '1.125rem',
    marginBottom: '.25rem',
  },
  specialtyFieldWrap: {
    width: '58%',
    display: 'flex',
    flexDirection: 'column',
  },
  zipFieldWrap: {
    width: '38%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    mr: '4%',
  },
  zipInput: {
    maxHeight: '36px',
  },
  locationBtn: {
    width: '28px',
    height: '28px',
    position: 'absolute',
    bottom: '5px',
    right: '5px',
  },
  specialtyDropdown: {
    background: (theme: Theme) => theme.palette.custom.lightGrey,
    borderRadius: '8px',
    color: (theme: Theme) => theme.palette.custom.darkBlue,
    fontSize: '.875rem',
    height: '38px',
  },
  dropdownLabel: {
    fontSize: '0.75em',
  },
  dropdownIcon: {
    margin: '.15rem .25rem .25rem 0',
  },
  fieldsBlock: {
    display: 'flex',
    flexDirection: 'row',
    mt: '10px',
  },
  searchBtn: {
    mt: '1em',
  },
};
