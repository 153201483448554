import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { Input, MaskedInput, states } from '@nomi-health-inc/components-ui';
import { Controller, useForm } from 'react-hook-form';
import { stylesFromTheme } from '../../../styles';
import { NMEPrimaryMemberContactInfoEditFormSchema } from './NMEPrimaryMemberContactInfoEditFormSchema';
import { IEditablePrimaryMemberContactInfo, IPrimaryMemberContactInfo } from '../NMEPrimaryContactInfoConfirmationPage.types';
import { formatPhone } from '../../../../../../utils/format-phone';
import { longFormDate } from '../../../../../../utils/format-date';
import { NMEFloatingCard } from '../../../../../../components/NMEFloatingCard';
import { optionalTextSx, stateDropdownLabelSx } from './NMEPrimaryMemberContactInfoEditForm.styles';
import { fieldHeaderSx, fieldValueSx, secondaryActionButtonContainerSx } from '../NMEPrimaryContactInfoConfirmationPage.styles';
import { NMEPrimaryActionButton } from '../../../../../../components/NMEActionButton';
import { DownChevronIcon } from '../../../../../../components/ProviderSearch/DownChevronIcon';

type IPrimaryMemberContactInfoEditFormProps = {
  contactInfo: IPrimaryMemberContactInfo;
  onBackButtonPress: () => void;
  onSubmit: (data: IEditablePrimaryMemberContactInfo) => void;
};

function NMEPrimaryMemberContactInfoEditForm({ contactInfo, onBackButtonPress, onSubmit }: IPrimaryMemberContactInfoEditFormProps) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const { firstName, lastName, dateOfBirth } = contactInfo;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors, isValid: isFormValid },
  } = useForm<IEditablePrimaryMemberContactInfo>({
    mode: 'onTouched',
    resolver: yupResolver(NMEPrimaryMemberContactInfoEditFormSchema),
    values: {
      preferredName: contactInfo.preferredName,
      email: contactInfo.email,
      mailingAddress: contactInfo.mailingAddress,
      city: contactInfo.city,
      state: contactInfo.state,
      zipCode: contactInfo.zipCode,
      phoneNumber: formatPhone(contactInfo.phoneNumber),
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NMEFloatingCard>
        <Box mb="18px">
          <Typography sx={fieldHeaderSx}>First Name</Typography>
          <Typography sx={fieldValueSx}>{firstName}</Typography>
        </Box>
        <Box mb="18px">
          <Typography sx={fieldHeaderSx}>Last Name</Typography>
          <Typography sx={fieldValueSx}>{lastName}</Typography>
        </Box>
        <Box sx={{ ...styles.input, position: 'relative', marginTop: '0' }}>
          <Controller
            control={control}
            {...register('preferredName')}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  ref={null}
                  inputProps={{ 'data-id': 'preferred-name-input' }}
                  sx={{ ...styles.input, mb: '18px' }}
                  variant="filled"
                  label="Preferred Name"
                  placeholder="Preferred Name"
                  helperText={formErrors.preferredName?.message}
                  error={!!formErrors.preferredName}
                  fullWidth
                />
                <Typography sx={optionalTextSx}>optional</Typography>
              </>
            )}
          />
          <Controller
            control={control}
            {...register('email')}
            render={({ field }) => (
              <Input
                {...field}
                ref={null}
                inputProps={{ 'data-id': 'email-input' }}
                sx={{ ...styles.input, mb: '18px' }}
                variant="filled"
                label="Email Address"
                placeholder="Email Address"
                helperText={formErrors.email?.message}
                error={!!formErrors.email}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            {...register('mailingAddress')}
            render={({ field }) => (
              <Input
                {...field}
                ref={null}
                inputProps={{ 'data-id': 'mailing-address-input' }}
                sx={{ ...styles.input, mb: '18px' }}
                variant="filled"
                label="Street Address"
                placeholder="Street Address"
                helperText={formErrors.mailingAddress?.message}
                error={!!formErrors.mailingAddress}
                fullWidth
              />
            )}
          />
          <Stack direction="row" width="100%" mb="18px">
            <Box flexGrow="1" mr="16px">
              <Controller
                control={control}
                {...register('city')}
                render={({ field }) => (
                  <Input
                    {...field}
                    ref={null}
                    inputProps={{ 'data-id': 'city-input' }}
                    sx={styles.input}
                    variant="filled"
                    label="City"
                    placeholder="City"
                    helperText={formErrors.city?.message}
                    error={!!formErrors.city}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box width="40%">
              <Controller
                control={control}
                {...register('state')}
                render={({ field }) => (
                  <Box width="100%">
                    <Typography sx={stateDropdownLabelSx}>State</Typography>
                    <Select
                      {...field}
                      ref={null}
                      label="State"
                      variant="filled"
                      IconComponent={DownChevronIcon}
                      displayEmpty
                      renderValue={(value: string) => {
                        return value === '' ? 'Select' : value;
                      }}
                      style={{ width: '100%' }}
                      size="small"
                      inputProps={{ 'data-id': 'stateDropdown' }}
                    >
                      {states.map(({ fullName, value }) => {
                        return (
                          <MenuItem key={`state-menu-item-${fullName}`} value={value}>
                            {fullName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                )}
              />
            </Box>
          </Stack>
          <Controller
            control={control}
            {...register('zipCode')}
            render={({ field }) => (
              <Input
                {...field}
                ref={null}
                inputProps={{ 'data-id': 'zip-code-input' }}
                sx={{ ...styles.input, mb: '18px' }}
                variant="filled"
                label="ZIP"
                placeholder="ZIP"
                helperText={formErrors.zipCode?.message}
                error={!!formErrors.zipCode}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            {...register('phoneNumber')}
            render={({ field }) => (
              <MaskedInput
                {...field}
                ref={null}
                inputProps={{ 'data-id': 'mobile-number-input' }}
                sx={{ ...styles.input, mb: '18px' }}
                variant="filled"
                label="Phone Number"
                placeholder="Phone Number"
                format="(999) 999-9999"
                helperText={formErrors.phoneNumber?.message}
                error={!!formErrors.phoneNumber}
                fullWidth
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={fieldHeaderSx}>Date of Birth</Typography>
          <Typography sx={fieldValueSx}>{longFormDate(dateOfBirth)}</Typography>
        </Box>
      </NMEFloatingCard>
      <Box sx={secondaryActionButtonContainerSx}>
        <Button data-id="go-back-button" onClick={onBackButtonPress} disableRipple>
          Go Back
        </Button>
      </Box>
      <NMEPrimaryActionButton type="submit" data-id="save-button" disabled={!isFormValid}>
        Save
      </NMEPrimaryActionButton>
    </form>
  );
}

export default NMEPrimaryMemberContactInfoEditForm;
