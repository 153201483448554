import { useEffect, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styles } from './PublicPracticeFacilityDetailsPage.styles';
import PracticeFacilityDetails from '../../components/PracticeFacilityDetails/PracticeFacilityDetails';
import { ModalSpinner } from '@nomi-health-inc/components-ui';
import { getPracticeFacilityDetails } from '../../app/rest-api/public-provider-search';
import { extractData } from './PublicPracticeFacilityDetailsPage.utils';
import { PracticeFacilityDetailsData } from '../NMEPracticeFacilityDetailsPage/NMEPracticeFacilityDetailsPage.utils';
import PublicProviderDetailsMap from '../../components/PracticeFacilityDetails/FacilityDetailsMap/PublicProviderDetailsMap';
import PublicProviderSearchThemeProvider from '../../components/ThemeProvider/PublicProviderSearchThemeProvider';
import '../PublicProviderSearchPage/iFrameResizerContentUtil';

function PublicPracticeFacilityDetailsPage() {
  const params = useParams();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [practiceFacilityDetails, setPracticeFacilityDetails] = useState<PracticeFacilityDetailsData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!params.id) {
      return;
    }

    setLoading(true);

    getPracticeFacilityDetails(params.id)
      .then((detailsResponse) => {
        setPracticeFacilityDetails(extractData(detailsResponse));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.id]);

  return (
    <PublicProviderSearchThemeProvider>
      <Box sx={[isDesktop ? styles.reverseRowContainer : styles.columnContainer, styles.flexGrow]}>
        {!loading && practiceFacilityDetails && <PublicProviderDetailsMap practiceFacilityDetails={practiceFacilityDetails} />}
        {!loading && practiceFacilityDetails && (
          <PracticeFacilityDetails isPublicSearch={true} areDisplayingLeftOfMap={isDesktop} details={practiceFacilityDetails} />
        )}
        {isDesktop && <Box sx={{ width: '40px' }} />}
        <ModalSpinner loading={loading} text="Processing" />
      </Box>
    </PublicProviderSearchThemeProvider>
  );
}

export default PublicPracticeFacilityDetailsPage;
