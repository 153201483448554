import { Theme } from '@mui/material';

export const styles = {
  wrap: {
    background: (theme: Theme) => theme.palette.custom.textColor,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto 25px auto',
    minHeight: '80px',
    '& :hover': {
      cursor: 'pointer',
    },
    padding: '1rem',
    width: '100%',
  },
  title: {
    fontFamily: 'Wulkan Display',
    fontStyle: 'normal',
    fontWeight: '850',
    fontSize: '2.25rem',
    lineHeight: '125%',
    color: (theme: Theme) => theme.palette.common.white,
    width: '345px',
    margin: '0 auto',
  },
};
