export const normalisePhoneNumber = (phone: string) => {
  if (phone.substring(0, 2) === '+1') {
    return removeNonDigits(phone);
  }

  return `+1${removeNonDigits(phone)}`;
};

const removeNonDigits = (phone: string) => {
  return phone.replace(/\D/g, '');
};

export const removeCountryCodeIfPresent = (phone: string) => {
  if (phone.substring(0, 2) === '+1') {
    return phone.substring(2);
  }

  return phone;
};

export const formatPhone = (phone: string | undefined): string => {
  if (!phone) {
    return '';
  }

  return phone
    .replace(/\D+/g, '')
    .slice(-10)
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
