import * as yup from 'yup';

export const MIN_DATE = new Date('1900');

export const NMEFirstNameAndDateOfBirthFormSchema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    dateOfBirth: yup
      .date()
      .min(MIN_DATE, 'Date of birth must be in mm/dd/yyyy format')
      .typeError('Date of birth must be in mm/dd/yyyy format')
      .required('Date of birth is required'),
  })
  .required();
