import { Theme } from '@mui/material';

export const styles = {
  title: {
    fontSize: '36px',
    fontWeight: '700',
    color: (theme: Theme) => theme.palette.custom.white,
    marginTop: '70px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: (theme: Theme) => theme.palette.custom.textColorLight,
  },
  contentBlock: {
    background: (theme: Theme) => theme.palette.custom.white,
    border: '1px solid rgba(52, 61, 79, 0.1)',
    boxShadow: '0px 2px 8px rgba(66, 84, 124, 0.2)',
    borderRadius: '20px',
    padding: '24px 24px 36px',
    marginTop: '35px',
    minHeight: '210px',
    display: 'flex',
    flexDirection: 'column',
    text: {
      fontSize: '14px',
    },
    '& .MuiFormLabel-root': {
      marginTop: '10px',
      fontWeight: '500',
    },
  },
  input: {
    borderRadius: 'unset',
    marginTop: '25px',
    '& .MuiFormLabel-root': {
      fontWeight: '500',
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'left',
    },
    '& .MuiFilledInput-input': {
      borderRadius: 'inherit',
    },
  },
  continueBtn: {
    width: '100%',
    margin: '40px 0',
  },
  typeBtn: {
    width: '100%',
    marginTop: '20px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase !important',
    fontSize: '16px',
    fontWeight: '500',
    margin: '15px 0 0 0',
    padding: '0',
    height: '20px',
    ' img': { marginLeft: '10px' },
  },
  italicBtn: {
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'italic',
    padding: '8px 0 0 0',
    height: 'auto',
    marginLeft: 'auto',
    textAlign: 'right',
    display: 'block',
    borderRadius: 'unset',
  },
  radioForm: {
    marginTop: '20px',
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
    },
    '& .MuiButtonBase-root': {
      paddingTop: 0,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      marginBottom: '10px',
    },
  },
  errorText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0 -20px 0',
    color: '#D63541',
    '& svg': {
      marginRight: '10px',
    },
    '& p': {
      fontSize: '16px',
    },
  },
  // Bank Agreement Page
  agreementPage: {
    headerContainer: {
      height: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      background: (theme: Theme) => theme.palette.custom.textColor,
      padding: '0 2rem',
    },
    contentContainer: {
      marginTop: '1.5rem',
      padding: '0 2rem',
    },
    title: {
      fontSize: '36px',
      fontWeight: '700',
      color: (theme: Theme) => theme.palette.custom.white,
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: '400',
      color: (theme: Theme) => theme.palette.custom.textColorLight,
      marginBottom: '3rem',
    },
    text: {
      fontSize: '14px',
    },
    bold: {
      fontWeight: 700,
    },
    paragraph: {
      marginBottom: '1rem',
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      margin: '30px 0 0 0',
    },
    checkboxLabel: {
      fontSize: '16px',
    },
    button: {
      margin: '30px auto',
      width: '100%',
    },
  },
  serviceInfo: {
    fontSize: '24px',
    fontWeight: '500',
    marginTop: '15px',
    marginBottom: '-10px',
  },
};
