import { Theme } from '@mui/material';

export const styles = {
  wrap: {
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
    width: '100%',
    backgroundColor: (theme: Theme) => theme.palette.custom.darkBlue,
    mb: '30px',
  },
  burger: {
    color: (theme: Theme) => theme.palette.custom.white,
  },
  burgerIconSize: {
    width: 24,
    height: 24,
  },
  link: {
    color: (theme: Theme) => theme.palette.custom.white,
    fontSize: '1rem',
    fontWeight: '500',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    '> img ': {
      marginRight: '10px',
    },
  },
  menuPaper: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
  menuItem: {
    color: (theme: Theme) => theme.palette.custom.link,
    fontSize: '1rem',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'space-between',
    '> img': {
      mr: '10px',
    },
  },
  menuLink: {
    width: '100%',
    color: (theme: Theme) => theme.palette.custom.link,
    fontSize: '1rem',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    '> img': {
      mr: '10px',
    },
  },
};
