import { SxProps, Theme } from '@mui/material';

export const fieldHeaderSx: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.custom.primaryGrey,
  fontSize: '12px',
  fontWeight: 300,
  lineHeight: '20px',
};

export const fieldValueSx: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.custom.darkBlue,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  minHeight: '18px',
};

export const secondaryActionButtonContainerSx: SxProps<Theme> = {
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '52px',
  lineHeight: '24px',
  padding: '14px 0',
};
