export const ROUTES = {
  root: '/',
  signIn: '/sign-in',
  tellus: '/TellUs',
  networks: '/networks',
  otp: '/otp',
  otpConfirmationMethod: '/otp-device-and-terms',
  confirmPrimaryContactInfo: '/primary-member-contact-info-confirmation',
  dependents: '/dependents',
  confirmDependentContactInfo: '/dependent-contact-info-confirmation',
  providerSearch: '/provider-search',
  publicProviderSearch: '/public-provider-search',
  welcome: '/welcome',
  publicPracticeFacility: '/public-practice-facility',
  practiceFacility: '/practice-facility',
};
