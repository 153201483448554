import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PageTitle from '../../components/PageTitle';
import { faqs } from './mockData';
import { SectionProp, ItemProps } from './types';
import { styles } from './styles';

function FAQSection(section: SectionProp) {
  return (
    <Box data-id="faq">
      <Typography sx={styles.subtitle}>{section.title}</Typography>
      {section.items.map((item: ItemProps) => {
        return (
          <Accordion data-id={item.answer} key={`subsection-${item.id}`} sx={styles.innerAcc}>
            <AccordionSummary sx={styles.innerAccTitle}>
              <Typography sx={styles.question}>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.innerAccText}>
              <Typography sx={styles.answer}>{typeof item.answer === 'function' ? item.answer() : item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

// eslint-disable-next-line react/no-multi-comp
function FAQPage() {
  return (
    <>
      <PageTitle title="FAQ" />
      <Box sx={styles.wrapper}>
        {FAQSection(faqs.paymentProcessing)}
        {FAQSection(faqs.paymentsMethods)}
      </Box>
    </>
  );
}

export default FAQPage;
