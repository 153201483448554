import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ROUTES } from '../../constants/routes';

const routesWithActiveAnalytics = [ROUTES.publicProviderSearch];

export function DataDogAnalyticsRoute(): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    if (routesWithActiveAnalytics.includes(location.pathname)) {
      datadogRum.startView(location.pathname);
    }
  }, [location.pathname]);

  return <Outlet />;
}
