import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { getCurrentBillId } from '../features/bills/billsSlice';

const styles = {
  wrapper: {
    background: '#ffffff',
    padding: '0 16px',
    height: 'calc(100vh - 250px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {},
  title: {
    fontSize: '32px',
    fontWeight: '600',
    margin: '30px 0 15px',
  },
  text: {
    fontSize: '15px',
    fontWeight: '400',
    margin: '0 0 25px 0',
    textAlign: 'center',
  },
  btn: { width: 'calc(100% - 32px)' },

  textBtn: {
    width: '100%',
    marginTop: '10px',
    textTransform: 'none !important',
  },
};

interface ThankYouState {
  action: string;
  text: string;
}

function ThankYouScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { action, text } = location.state as ThankYouState;
  const currentBillId = useAppSelector(getCurrentBillId);

  return (
    <Box sx={styles.wrapper}>
      <Box component="img" alt="Thank you" src="/images/like.svg" sx={styles.icon} />
      <Typography sx={styles.title}>Thank You!</Typography>
      <Typography sx={styles.text}>{text}</Typography>
      {currentBillId ? (
        <>
          <Button
            sx={styles.btn}
            variant="outlined"
            onClick={() => {
              navigate('/payment-method/add');
            }}
          >
            Add Another Payment Method
          </Button>
          <Button
            color="primary"
            variant="text"
            sx={styles.textBtn}
            onClick={() => {
              navigate(`/pay-bill/${currentBillId}`);
            }}
          >
            View bill
          </Button>
        </>
      ) : (
        <>
          {action === 'go-home' && (
            <Button
              sx={styles.btn}
              variant="outlined"
              onClick={() => {
                navigate('/');
              }}
            >
              Go Home
            </Button>
          )}
        </>
      )}
    </Box>
  );
}

export default ThankYouScreen;
