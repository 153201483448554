import { SxProps, Theme } from '@mui/material';

export const sendToDifferentOTPMethodButtonSx: SxProps<Theme> = {
  position: 'absolute',
  height: '18px',
  top: 0,
  right: '-4px',
  padding: '0 8px',
  color: '#0D41C0',
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'italic',
};

export const sendNewCodeButtonContainerSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  mb: ({ spacing }: Theme) => spacing(-3),
};
