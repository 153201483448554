import { useEffect, useRef } from 'react';
import { useDebounce } from './useDebounce';

export const useIdleTimer = (timeoutInMin: number, onIdle: () => void) => {
  const idleTimerRef = useRef<NodeJS.Timeout | null>(null);
  const minute = 60 * 1000;

  const activityDetected = useDebounce(() => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
    idleTimerRef.current = setTimeout(onIdle, timeoutInMin * minute);
  }, 10 * 1000);

  useEffect(() => {
    document.addEventListener('mousemove', activityDetected);
    document.addEventListener('keydown', activityDetected);
    document.addEventListener('scroll', activityDetected);
    document.addEventListener('click', activityDetected);

    return () => {
      document.removeEventListener('mousemove', activityDetected);
      document.removeEventListener('keydown', activityDetected);
      document.removeEventListener('scroll', activityDetected);
      document.removeEventListener('click', activityDetected);
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
    };
  }, [activityDetected, onIdle, timeoutInMin]);

  return { reset: activityDetected };
};
