import { SxProps, Theme } from '@mui/material';

export const containerSx: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(2.5),
  borderRadius: '20px',
  border: '1px solid #343D4F',
  boxShadow: '0 0 10px 0px rgba(52, 61, 79, 0.10)',
  padding: '15px',
};

export const titleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.custom.darkBlue,
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: 'normal',
};

export const detailsSx: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(0.5),
  color: (theme) => theme.palette.custom.primaryGrey,
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
};

export const contactMethodContainerSx: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(1.5),
  color: (theme) => theme.palette.primary.main,
  direction: 'row',
  justifyContent: 'left',
  alignItems: 'center',
};

export const contactMethodSx: SxProps<Theme> = {
  color: '#1E1F22',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
};

export const contactInfoIconSx: SxProps<Theme> = {
  width: '20px',
  height: '20px',
};
