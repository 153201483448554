import { Box, List, Stack, Typography } from '@mui/material';
import {
  listItemActiveIndicatorBarSx,
  listItemContainerSx,
  listItemDetailsSx,
  listItemRightSideTextSx,
  listItemHeaderSx,
  listItemWrapSx,
  searchResultsContainerSx,
} from './NewLocationList.styles';
import { LocationListElement } from '../NewProviderSearch.types';
import { Condition } from '@nomi-health-inc/components-ui';
import { EmptyState } from './EmptyState';
import { useEffect } from 'react';

interface NewLocationListProps {
  activeLocationId: string | null;
  locationList: LocationListElement[];
  onLocationClick: (locationId: string) => void;
}

export function NewLocationList({ activeLocationId, locationList, onLocationClick }: NewLocationListProps) {
  useEffect(() => {
    if (!activeLocationId) {
      return;
    }
    document.getElementById(activeLocationId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }, [activeLocationId]);

  return (
    <List sx={searchResultsContainerSx}>
      <Condition when={locationList.length === 0}>
        <EmptyState />
      </Condition>
      <Condition when={locationList.length > 0}>
        {locationList.map((location, index) => (
          <Box
            id={`${location.id}`}
            key={`${location.name}-${location.coordinates}-${index}`}
            onClick={() => onLocationClick(location.id)}
            sx={listItemWrapSx}
            data-id={`location-${location.id}`}
          >
            {activeLocationId === location.id && <Box sx={listItemActiveIndicatorBarSx} />}
            <Stack sx={listItemContainerSx}>
              <Stack direction="row" alignItems="baseline">
                <Stack flex={8}>
                  <Typography sx={listItemHeaderSx}>{location.name}</Typography>
                  <Typography sx={listItemDetailsSx}>{location.firstAddressLine}</Typography>
                  <Typography sx={listItemDetailsSx}>{location.secondAddressLine}</Typography>
                </Stack>
                <Stack flex={2} spacing={0.25} alignItems="flex-end">
                  <Condition when={location.distanceInMiles !== null}>
                    <Typography sx={listItemRightSideTextSx}>~{location.distanceInMiles} Mi.</Typography>
                  </Condition>
                  <Condition when={location.numberOfProviders > 1}>
                    <Typography sx={listItemRightSideTextSx}>{`${location.numberOfProviders} ${
                      location.numberOfProviders === 1 ? 'Provider' : 'Providers'
                    }`}</Typography>
                  </Condition>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Condition>
    </List>
  );
}
