import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GuttersContainer from '../../../../../../components/GuttersContainer';
import NMEMemberContactInfoConfirmationForm from '../../MemberContactInfoConfirmationForm';
import { MemberRelationship, useGetDependentByIdQuery, useUpdateContactInfoMutation } from '../../../../../../app/graphql/_generated/hooks';
import { extractDependentInfo } from './DependentDetailPage.utils';
import { Condition, ModalSpinner } from '@nomi-health-inc/components-ui';
import { DependentMemberContactInfo, EditableDependentContactInfo } from './DependentDetail.types';
import { sessionStorage } from '../../../../../../app/storageApi';
import { storage_keys } from '../../../../../../app/constants';
import { ConfirmedDependent } from '../DependentsListPage/DependentsList.types';
import { DependentMemberContactInfoEditForm } from './DependentMemberContactInfoEditForm/DependentMemberContactInfoEditForm';
import { Typography } from '@mui/material';
import { welcomeTitleSx } from '../../../NMEWelcomePage/WelcomePage.styles';
import { normalisePhoneNumber } from '../../../../../../utils/format-phone';

export function DependentDetailPage() {
  const [initialDependentInfo, setInitialDependentInfo] = useState<DependentMemberContactInfo>();
  const [updatedDependentInfo, setUpdatedDependentInfo] = useState<EditableDependentContactInfo>();
  const navigate = useNavigate();
  const { id: dependentId } = useParams();
  const [runUpdateContactInfoMutation, { loading: areUpdatingContactInfo, error: contactInfoUpdateError }] = useUpdateContactInfoMutation();

  const [areEditing, setAreEditing] = useState<boolean>(false);

  const { loading } = useGetDependentByIdQuery({
    variables: {
      id: dependentId ?? '',
    },
    onCompleted: (data) => setInitialDependentInfo(extractDependentInfo(data)),
  });

  const onConfirmDependent = useCallback(async () => {
    if (!dependentId) {
      return;
    }

    if (updatedDependentInfo && initialDependentInfo?.partyId) {
      await runUpdateContactInfoMutation({
        variables: {
          party_id: initialDependentInfo?.partyId,
          postal_address: updatedDependentInfo.mailingAddress,
          email: initialDependentInfo.email,
          city: updatedDependentInfo.city,
          state: updatedDependentInfo.state,
          zip: updatedDependentInfo.zipCode,
          phone_number: normalisePhoneNumber(updatedDependentInfo.phoneNumber),
        },
      });

      if (contactInfoUpdateError) {
        // eslint-disable-next-line no-console
        console.log('Failed to update contact information.', contactInfoUpdateError);
        return;
      }
    }

    const confirmedDependents = sessionStorage.getObjValue<Array<ConfirmedDependent>>(storage_keys.confirmed_dependents);

    if (!confirmedDependents) {
      sessionStorage.setItem(storage_keys.confirmed_dependents, [
        {
          isConfirmed: true,
          id: dependentId,
        },
      ]);
    } else {
      const foundDependentIndex = confirmedDependents.findIndex((element) => element.id === dependentId);

      if (foundDependentIndex === -1) {
        confirmedDependents.push({ isConfirmed: true, id: dependentId });
      } else {
        confirmedDependents.splice(foundDependentIndex, 1, {
          isConfirmed: true,
          id: dependentId,
        });
      }
      sessionStorage.setItem(storage_keys.confirmed_dependents, confirmedDependents);
    }

    navigate(-1);
  }, [
    contactInfoUpdateError,
    dependentId,
    initialDependentInfo?.email,
    initialDependentInfo?.partyId,
    navigate,
    runUpdateContactInfoMutation,
    updatedDependentInfo,
  ]);

  const handleEditCommit = useCallback((data: EditableDependentContactInfo) => {
    setUpdatedDependentInfo(data);
    setAreEditing(false);
  }, []);

  const contactInfoToDisplay: DependentMemberContactInfo = {
    id: initialDependentInfo?.id ?? '',
    partyId: initialDependentInfo?.partyId ?? '',
    firstName: initialDependentInfo?.firstName ?? '',
    lastName: initialDependentInfo?.lastName ?? '',
    email: initialDependentInfo?.email ?? '',
    memberRelationship: initialDependentInfo?.memberRelationship ?? MemberRelationship.Undefined,
    mailingAddress: updatedDependentInfo?.mailingAddress ?? initialDependentInfo?.mailingAddress ?? '',
    city: updatedDependentInfo?.city ?? initialDependentInfo?.city ?? '',
    state: updatedDependentInfo?.state ?? initialDependentInfo?.state ?? '',
    zipCode: updatedDependentInfo?.zipCode ?? initialDependentInfo?.zipCode ?? '',
    phoneNumber: updatedDependentInfo?.phoneNumber || initialDependentInfo?.phoneNumber || '',
    dateOfBirth: initialDependentInfo?.dateOfBirth || '',
  };

  return (
    <GuttersContainer>
      <Typography sx={welcomeTitleSx} component="h1">
        What is your preferred contact info?
      </Typography>
      <Condition when={!!initialDependentInfo}>
        {areEditing ? (
          <DependentMemberContactInfoEditForm
            onSubmit={handleEditCommit}
            onBackButtonPress={() => setAreEditing(false)}
            contactInfo={contactInfoToDisplay}
          />
        ) : (
          <NMEMemberContactInfoConfirmationForm
            contactInfo={contactInfoToDisplay}
            isLoading={loading}
            onSubmit={onConfirmDependent}
            onUpdateInformationButtonPress={() => setAreEditing(true)}
          />
        )}
      </Condition>
      <ModalSpinner loading={loading || areUpdatingContactInfo} text="Loading" />
    </GuttersContainer>
  );
}
