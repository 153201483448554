import { Box, Dialog } from '@mui/material';
import { dialogSx, nomiLogoContainerSx } from './AnimatedNomiLogoLoadingModal.styles';

interface AnimatedNomiLogoLoadingModal {
  loading: boolean;
}

export function AnimatedNomiLogoLoadingModal({ loading }: AnimatedNomiLogoLoadingModal) {
  return (
    <Dialog open={loading} aria-labelledby="Loading spinner" aria-describedby="Loading content" sx={dialogSx}>
      <Box sx={nomiLogoContainerSx}>
        <Box sx={{ width: '6rem', height: 'auto' }} component="img" src="/images/nomi-loader.gif" />
      </Box>
    </Dialog>
  );
}
