// TODO: Should be taken from nomi-components library
// if you see this comment please review if we can resolve the lint issue
// or replace comment with short explanation
import { Input } from '@nomi-health-inc/components-ui';
import formatStringByPattern from 'format-string-by-pattern';

function MaskedInput(props) {
  const { value, onChange, mask, format } = props;
  let updatePass = (e) => {
    let newValue = e.target.value;
    // Account for multi-character unicode masks
    let newLength = newValue.length / mask.length;
    let oldLength = value.length * mask.length;

    // NOTE: This assumes all edits happen at the end of the password.
    // To support mid word character adding, we can find the current cursor position using refs
    // The idea is to determine the start and stop based on the cursor position to slice the newValue and splice it into value at the same spot. It's complicated by the emoji support since those have length > 1

    if (newValue !== '') {
      // We've added characters at the end
      if (value.length < newLength) {
        onChange(formatStringByPattern(format, newValue.slice(oldLength)));
      }

      // We've removed characters from the end
      if (value.length > newLength) {
        onChange(formatStringByPattern(format, value.slice(0, newLength)));
      }
    } else {
      onChange('');
    }
  };

  let formattedView;

  if (mask === '') {
    formattedView = formatStringByPattern(format, value);
  } else {
    formattedView = mask.repeat(value.length);
    if (format) {
      formattedView = formatStringByPattern(format, value).replaceAll(/[\d]/g, mask);
    }
  }

  return <Input data-id="masked-input" variant="filled" {...props} value={value === '' ? value : formattedView} onChange={updatePass} />;
}

export default MaskedInput;
