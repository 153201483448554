import { MemberByFieldQuery } from '../app/graphql/_generated/hooks';

export type MemberDetailsData = {
  id: string;
  first_name: string;
  names: string;
  subscriber_id: string;
  dependent_members: DependentNamesAndId[];
  plan_code: string;
  effective_date: string;
  group_id: string;
};

export type DependentNamesAndId = {
  names?: string;
  subscriber_id?: string;
};

export function extractData(data: MemberByFieldQuery['memberByFields']): MemberDetailsData {
  const member_id = data?._id || '';
  const first_name = data?.party?.person?.current_name?.names?.[0] || '';
  const effective_date = data?.coverage_start_date || '';
  const plan_code = data?.plan_code || '';
  const names = data?.party?.person?.current_name?.names?.join(' ') || '';
  const subscriber_id = data?.subscriber_id || '';
  const dependent_members =
    data?.dependent_members?.map((dependent): DependentNamesAndId => {
      const dependent_names = dependent?.party?.person?.current_name?.names?.join(' ') || '';
      const dependent_subscriber_id = dependent?.subscriber_id || '';

      return {
        names: dependent_names,
        subscriber_id: dependent_subscriber_id,
      };
    }) || [];
  const group_id = (): string => {
    const userPlan = data?.sponsor?.plans?.find((plan) => {
      return plan?.member_ids?.includes(member_id);
    });

    return userPlan ? String(userPlan?.group_id) : '';
  };

  return {
    id: member_id,
    first_name: first_name,
    names: names,
    subscriber_id: subscriber_id,
    dependent_members: dependent_members,
    plan_code: plan_code,
    effective_date: effective_date,
    group_id: group_id(),
  };
}

export function formatDate(date: string | undefined): string {
  if (!date || date.length < 10) {
    return '';
  }
  date = date.substring(5, 7) + '/' + date.substring(8, 10) + '/' + date.substring(0, 4);

  return date;
}
