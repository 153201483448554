import { Box, Link, Typography } from '@mui/material';
import PageTitle from '../components/PageTitle';
import EmailIcon from '@mui/icons-material/Email';
import { CONTACT_DATA } from '../app/constants';

const styles = {
  wrapper: {
    padding: '0 16px',
    alignItems: 'center',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
  },
  img: { marginTop: '36px', marginBottom: '8px' },
  title: { marginBottom: '32px', fontSize: '32px', fontWeight: '500' },
  subtitle: { marginBottom: '15px', fontSize: '14px', fontWeight: '500' },
  linkBox: {
    alignItems: 'center',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    marginBottom: '35px',
  },
  linkBoxTitle: { fontSize: '14px', fontWeight: '700' },
  link: {
    fontSize: '14px',
    fontWeight: '300',
    color: 'custom.textColor',
    marginTop: '5px',
  },
  linkIcon: { mb: -0.5, mr: 1, color: '#0D41C0' },
};

function ContactUsPage() {
  return (
    <>
      <PageTitle title="Contact Us" />
      <Box sx={styles.wrapper}>
        <Box component="img" alt="Contact Us" src="/images/contact-us.svg" sx={styles.img} />
        <Typography sx={styles.title}>We’re here to help</Typography>

        <Typography sx={styles.subtitle}>Need help with your Payments?</Typography>
        <Typography sx={styles.linkBoxTitle}>Contact Nomi Health</Typography>
        <Box sx={styles.linkBox}>
          <Link sx={styles.link} href={`mailto:${CONTACT_DATA.nomiEmail}`} underline="none">
            <EmailIcon sx={styles.linkIcon} fontSize="small" />
            {CONTACT_DATA.nomiEmail}
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default ContactUsPage;
