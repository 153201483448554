import { Theme } from '@mui/material';

export const styles = {
  container: {
    padding: '0 20px 20px 20px',
    maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
  },
  mapHeightContainer: {
    height: '523px',
    maxHeight: '523px',
    overflow: 'auto',
  },
  title: {
    height: 'auto',
    fontSize: '36px',
    lineHeight: '44px',
    fontWeight: '700',
    letterSpacing: '-0.72px',
    color: (theme: Theme) => theme.palette.custom.primaryGrey,
  },
  noPadding: {
    padding: '0',
  },
  marginTop: {
    marginTop: '.5rem',
  },
  leftChevronIcon: {
    margin: 0,
    marginRight: '-12px',
    fontSize: '35px',
    color: (theme: Theme) => theme.palette.custom.veryDarkBlue,
  },
  inNetworkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inNetworkTitleText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '100%',
    color: '#1E1F22',
  },
};
