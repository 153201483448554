import { Grid } from '@mui/material';
import { styled } from '@mui/system';

const GuttersContainer = styled(Grid, {
  shouldForwardProp: (prop: string) => prop !== 'withBackground',
})<{ withBackground?: boolean }>(({ theme, withBackground }) => ({
  padding: '0 6rem',
  backgroundColor: withBackground ? theme.palette.form.inputBackground : 'none',
  [theme.breakpoints.down('sm')]: {
    padding: '0 2rem',
  },
}));

export default GuttersContainer;
