import { useCallback, useState } from 'react';
import { SpecialtyToTaxonomies, useGetNetworkSpecialtiesLazyQuery } from '../../app/graphql/_generated/hooks';
import { NETWORK_ID } from '../../app/constants';
import { getSpecialtiesForNetwork } from '../../app/rest-api/public-provider-search';

export type UseCheckPermissionReturn = {
  getSpecialtiesInNetwork: (isPublicSearch: boolean) => void;
  allSpecialtyToTaxonomies: SpecialtyToTaxonomies[];
};

export const useSpecialtiesInNetwork = (): UseCheckPermissionReturn => {
  const [runSpecialitiesQuery] = useGetNetworkSpecialtiesLazyQuery({
    variables: { id: NETWORK_ID },
  });

  const [allSpecialtyToTaxonomies, setAllSpecialtyToTaxonomies] = useState<SpecialtyToTaxonomies[]>([]);

  const getSpecialtyToTaxonomiesUsingPublicRestAPI = useCallback(async () => {
    const result = await getSpecialtiesForNetwork().catch(() => {
      // eslint-disable-next-line no-console
      console.log('Failed to retrieve specialties');
    });

    if (result) {
      setAllSpecialtyToTaxonomies(result);
    } else {
      setAllSpecialtyToTaxonomies([]);
    }
  }, []);

  const getSpecialtyToTaxonomiesUsingPrivateGraphQLAPI = useCallback(async () => {
    const result = await runSpecialitiesQuery();

    if (result && result.data?.getNetworkSpecialties) {
      setAllSpecialtyToTaxonomies(result.data.getNetworkSpecialties);
    } else {
      setAllSpecialtyToTaxonomies([]);
    }
  }, [runSpecialitiesQuery]);

  const getSpecialtiesInNetwork = useCallback(
    (isPublicSearch: boolean) => {
      if (isPublicSearch) {
        getSpecialtyToTaxonomiesUsingPublicRestAPI();
      } else {
        getSpecialtyToTaxonomiesUsingPrivateGraphQLAPI();
      }
    },
    [getSpecialtyToTaxonomiesUsingPrivateGraphQLAPI, getSpecialtyToTaxonomiesUsingPublicRestAPI]
  );

  return { getSpecialtiesInNetwork, allSpecialtyToTaxonomies };
};
