export function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const charactersLength = characters.length;
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export async function generateCodeChallenge(): Promise<{ code_challenge: string; code_verifier: string }> {
  const code_verifier = generateCodeVerifier();
  const encoder = new TextEncoder();
  const data = encoder.encode(code_verifier);
  const digest = await crypto.subtle.digest('SHA-256', data);
  const digestArray = new Uint8Array(digest);

  const code_challenge = base64UrlEncode(digestArray);

  return { code_challenge, code_verifier };
}

function base64UrlEncode(array: Uint8Array): string {
  return window
    .btoa(String.fromCharCode.apply(null, array as unknown as number[]))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

export function generateCodeVerifier(): string {
  return generateRandomString(96);
}
