import { SxProps, Theme } from '@mui/material';

export const columnContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
} as SxProps<Theme>;

export const flexGrowSx = {
  flexGrow: '1',
} as SxProps<Theme>;

export const searchResultsContainerSx = {
  ...columnContainerSx,
  ...flexGrowSx,
  paddingTop: '1.25rem',
} as SxProps<Theme>;

export const searchAgainButtonContainerSx = {
  width: '100%',
  position: 'relative',
  padding: '0 1.25rem',
} as SxProps<Theme>;

export const leftChevronImageSx = {
  position: 'absolute',
  left: '1rem',
  top: '.5rem',
  padding: '0 1.25rem',
} as SxProps<Theme>;

export const searchResultsHeaderControlsContainerSx = {
  width: '100%',
} as SxProps<Theme>;

export const searchAgainButtonSx = {
  height: '2.5rem',
  width: '100%',
  marginBottom: '1rem',
  borderRadius: '3.125rem',
  border: (theme) => `1px solid ${theme.palette.custom.link}`,
} as SxProps<Theme>;

export const headerAndZipCodeContainerSx = {
  width: '100%',
  marginBottom: '.5rem',
  alignItems: 'center',
};

export const specialtyPillSx = {
  height: '22px',
  alignItems: 'center',
  padding: '0.375rem 0 0.25rem 0.75rem',
  backgroundColor: (theme: Theme) => theme.palette.custom.link,
  borderRadius: '50px',
};

export const specialtyPillTextSx = {
  maxWidth: '111px',
  color: 'white',
  fontSize: '12px',
  lineHeight: '100%',
  fontWeight: '700',
  letterSpacing: 0.96,
};

export const specialtyPillClearButtonSx = {
  color: 'white',
  mb: '2px',
  mr: '0',
  width: '14px',
  height: '14px',
} as SxProps<Theme>;

export const headerSx = {
  marginTop: '4px',
  alignSelf: 'flex-start',
  lineHeight: '100%',
  padding: '0 0.5rem 0 1.25rem',
  fontSize: '1.125rem',
} as SxProps<Theme>;

export const zipCodeContainerSx: SxProps<Theme> = {
  padding: '0 1.25rem',
  display: 'flex',
  direction: 'row',
  alignItems: 'center',
};

export const zipCodeTextSx: SxProps<Theme> = {
  marginTop: '1px',
  marginLeft: '3px',
  lineHeight: '14px',
  fontWeight: '400',
};

export const searchResultListsContainerSx: SxProps<Theme> = {
  maxHeight: '100%',
  overflowY: 'auto',
  ...columnContainerSx,
};

export const resultsTabSwitcherSx: SxProps<Theme> = {
  width: '100%',
  height: '1.375rem',
  color: (theme: Theme) => theme.palette.custom.veryDarkBlue,
  fontSize: '1.25rem',
  fontWeight: 700,
  marginBottom: '.5rem',
  padding: '0 1.25rem',
};

export const tabHeaderSx: SxProps<Theme> = {
  cursor: 'pointer',
};

export const underlinedTabHeaderSx: SxProps<Theme> = {
  ...tabHeaderSx,
  borderBottom: (theme: Theme) => `1px solid ${theme.palette.custom.veryDarkBlue}`,
};

export const groupCoverageContainerSx: SxProps<Theme> = {
  height: '2.125rem',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
};

export const groupCoverageTextSx: SxProps<Theme> = {
  marginTop: '.125rem',
  marginLeft: '.75rem',
  lineHeight: '14px',
  fontWeight: '400',
  color: (theme: Theme) => theme.palette.custom.warningRed,
};

export const groupCoverageIconSx: SxProps<Theme> = {
  width: '15px',
  height: '15px',
  marginLeft: '1.5rem',
};
