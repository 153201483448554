import { CssBaseline, useTheme } from '@mui/material';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './app/history';
import AuthGuardRoute from './features/authV2/AuthGuardRoute';
import { DataDogAnalyticsRoute } from './features/analytics/DataDogAnalyticsRoute';
import { HorizontalBg } from './components/HorizontalBg';
import NMELoginPage from './pages/auth/NMEOnboarding/NMELoginPage';
import NMEPrimaryContactInfoConfirmationPage from './pages/auth/NMEOnboarding/ContactInfoConfirmation/NMEPrimaryMemberConfirmationPage';
import NMEOTPDeviceAndTermsPage from './pages/auth/NMEOnboarding/NMEOTPDeviceAndTermsPage';
import NMEOTPPage from './pages/auth/NMEOnboarding/NMEOTPPage';
import NMEWelcomePage from './pages/auth/NMEOnboarding/NMEWelcomePage';
import NMEPracticeFacilityDetailsPage from './pages/NMEPracticeFacilityDetailsPage';
import NMEProviderSearchPage from './pages/NMEProviderSearchPage';
import NMEWalletPage from './pages/NMEWalletPage';
import PublicProviderSearchPage from './pages/PublicProviderSearchPage';
import { CURRENT_ENV_NAME, EnvironmentEnum } from './app/environments';
import PublicPracticeFacilityDetailsPage from './pages/PublicPracticeFacilityDetailsPage';
import { DependentDetailPage, DependentsListPage } from './pages/auth/NMEOnboarding/ContactInfoConfirmation/NMEDependents';
import { ROUTES } from './constants/routes';
import { useEffect } from 'react';
import { sessionStorage } from './app/storageApi';
import { storage_keys } from './app/constants';
import { useAuth } from './features/authV2/AuthContext';

function NMEApp() {
  // this is for cheatsheat for defined colors
  const isProd = EnvironmentEnum.Production === CURRENT_ENV_NAME;
  const theme = useTheme();
  const { isAuthenticated, logout } = useAuth();

  if (!isProd) {
    // eslint-disable-next-line no-console
    console.log('mui theme - ', theme);
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (sessionStorage.getItem(storage_keys.closed_tab_expires_in)) {
      const shouldExpireIn = Number(sessionStorage.getItem(storage_keys.closed_tab_expires_in));

      if (Date.now() > shouldExpireIn) {
        logout();
      }
    }

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = '';
      sessionStorage.setItem(storage_keys.closed_tab_expires_in, String(new Date(new Date().getTime() + 15 * 60000).getTime()));
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isAuthenticated, logout]);

  return (
    <HistoryRouter history={history as never}>
      <CssBaseline />
      <Routes>
        <Route element={<DataDogAnalyticsRoute />}>
          <Route element={<AuthGuardRoute />}>
            <Route path={ROUTES.root} element={<NMEWalletPage />} />
            <Route path={ROUTES.providerSearch} element={<NMEProviderSearchPage />} />
            <Route path={`${ROUTES.practiceFacility}/:id`} element={<NMEPracticeFacilityDetailsPage />} />
            <Route
              path={ROUTES.confirmPrimaryContactInfo}
              element={
                <HorizontalBg blockLogo={true}>
                  <NMEPrimaryContactInfoConfirmationPage />
                </HorizontalBg>
              }
            />
            <Route
              path={ROUTES.dependents}
              element={
                <HorizontalBg blockLogo={true}>
                  <DependentsListPage />
                </HorizontalBg>
              }
            />
            <Route
              path={`${ROUTES.dependents}/:id`}
              element={
                <HorizontalBg blockLogo={true}>
                  <DependentDetailPage />
                </HorizontalBg>
              }
            />
            <Route path={ROUTES.welcome} element={<NMEWelcomePage />} />
          </Route>
          <Route
            path={ROUTES.otpConfirmationMethod}
            element={
              <HorizontalBg blockLogo={true}>
                <NMEOTPDeviceAndTermsPage />
              </HorizontalBg>
            }
          />
          <Route
            path={ROUTES.signIn}
            element={
              <HorizontalBg blockLogo={true}>
                <NMELoginPage />
              </HorizontalBg>
            }
          />
          <Route
            path={ROUTES.otp}
            element={
              <HorizontalBg blockLogo={true}>
                <NMEOTPPage />
              </HorizontalBg>
            }
          />
          <Route path={ROUTES.publicProviderSearch} element={<PublicProviderSearchPage />} />
          <Route path={`${ROUTES.publicPracticeFacility}/:id`} element={<PublicPracticeFacilityDetailsPage />} />
        </Route>
      </Routes>
    </HistoryRouter>
  );
}

export default NMEApp;
