import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, useTheme } from '@mui/material';
import { Input } from '@nomi-health-inc/components-ui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { stylesFromTheme } from '../../styles';
import { NMEEmailFormSchema } from './NMEEmailFormSchema';
import { IEmailFormData } from './NMEEmailForm.types';
import { NMEFloatingCard, NMEFloatingCardTitleStyled } from '../../../../../components/NMEFloatingCard';
import { useCallback, useEffect } from 'react';
import { NMEPageTitle } from '../../../../../components/NMEPageTitle';

interface IEmailFormProps {
  onSubmit: (data: IEmailFormData) => Promise<void>;
  shouldDisableSubmitButton: boolean;
  noConfirmDevices: boolean;
}

function NMEEmailForm({ onSubmit, shouldDisableSubmitButton, noConfirmDevices }: IEmailFormProps) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors: formErrors, isValid: isFormValid },
  } = useForm<IEmailFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(NMEEmailFormSchema),
    values: {
      email: '',
    },
  });

  const onEmailSubmit = useCallback<SubmitHandler<IEmailFormData>>(
    async (data) => {
      try {
        await onSubmit(data);
      } catch (err) {
        if (!(err instanceof Error)) {
          return;
        }

        setError('email', {
          message: 'Email address does not match our records. Please contact support@nomihealth.com for more',
        });
      }
    },
    [onSubmit, setError]
  );

  useEffect(() => {
    if (noConfirmDevices) {
      setError('email', { type: 'email', message: "The user doesn't have any confirmation methods, please contact support" });
    }
  }, [noConfirmDevices, setError]);

  return (
    <Box sx={styles.contentLoginWrap}>
      <Box sx={styles.contentLoginBlock}>
        <NMEPageTitle>Login</NMEPageTitle>
        <NMEFloatingCard>
          <NMEFloatingCardTitleStyled>To access your account, please enter your email address below.</NMEFloatingCardTitleStyled>
          <Box sx={styles.input}>
            <Controller
              control={control}
              {...register('email')}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  sx={styles.input}
                  variant="filled"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  helperText={formErrors.email?.message}
                  error={!!formErrors.email}
                  fullWidth
                  autoFocus
                />
              )}
            />
          </Box>
        </NMEFloatingCard>
      </Box>
      <Box sx={styles.footerSection}>
        <Button
          color="primary"
          variant="contained"
          sx={styles.continueButton}
          disabled={!isFormValid || shouldDisableSubmitButton}
          onClick={handleSubmit(onEmailSubmit)}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default NMEEmailForm;
