import { Condition } from '@nomi-health-inc/components-ui';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../components/Header';
import WithFooterWrapper from '../../components/WithFooterWrapper';
import { useIdleTimer } from '../../hooks/useIdleTimer';
import { useFeatureFlags } from '../featureFlags/FeatureFlagsContext';
import { useAuth } from './AuthContext';
import { storage_keys } from '../../app/constants';

function AuthGuardRoute(): JSX.Element {
  const { logoutTimeout = 15 } = useFeatureFlags();

  const { areShowingNewMemberExperience } = useFeatureFlags();
  const { isAuthenticated, logout } = useAuth();
  const { pathname } = useLocation();

  useIdleTimer(logoutTimeout, logout);

  if (!isAuthenticated()) {
    if (pathname.includes('/pay-bill/')) {
      localStorage.setItem(storage_keys.bill_to_pay, pathname.substring(10));
    }
    return <Navigate to="/sign-in" replace />;
  }

  return (
    <>
      <Condition when={areShowingNewMemberExperience}>
        <Outlet />
      </Condition>

      <Condition when={!areShowingNewMemberExperience}>
        <WithFooterWrapper>
          {pathname !== '/auth/terms' && <Header />}
          <Outlet />
        </WithFooterWrapper>
      </Condition>
    </>
  );
}

export default AuthGuardRoute;
