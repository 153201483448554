import { Box, Stack, Typography } from '@mui/material';
import { addressInfoTextSx, columnContainerSx, containerSx, headerTextSx } from './PracticeFacilityAddress.styles';
import { FacilityAddress } from '../../../pages/NMEPracticeFacilityDetailsPage/NMEPracticeFacilityDetailsPage.utils';
import { Condition } from '@nomi-health-inc/components-ui';

interface PracticeFacilityAddressProps {
  shouldDisplayInTwoColumns: boolean;
  practiceFacilityName: string;
  practiceFacilityStreetAddress2: string;
  facilityAddress: FacilityAddress;
}

export function PracticeFacilityAddress({
  shouldDisplayInTwoColumns,
  practiceFacilityName,
  practiceFacilityStreetAddress2,
  facilityAddress,
}: PracticeFacilityAddressProps) {
  return (
    <Box sx={shouldDisplayInTwoColumns ? containerSx : columnContainerSx}>
      <Typography sx={headerTextSx}>Office</Typography>
      <Condition when={shouldDisplayInTwoColumns}>
        <Box width="38px" />
      </Condition>
      <Stack>
        {practiceFacilityName && <Typography sx={addressInfoTextSx}>{practiceFacilityName}</Typography>}
        <Typography sx={addressInfoTextSx}>{facilityAddress.street_address1}</Typography>
        {practiceFacilityStreetAddress2 && <Typography sx={addressInfoTextSx}>{practiceFacilityStreetAddress2}</Typography>}
        <Typography sx={addressInfoTextSx}>
          {facilityAddress.city_town} {facilityAddress.state_province}, {facilityAddress.postal_code}
        </Typography>
      </Stack>
    </Box>
  );
}
