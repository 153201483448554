import { IPracticeFacilityDetailsResponse } from '../../app/rest-api/public-provider-search/types';
import { FacilityAddress, PracticeFacilityDetailsData } from '../NMEPracticeFacilityDetailsPage/NMEPracticeFacilityDetailsPage.utils';
import { ProviderType } from '../../app/graphql/_generated/hooks';

const defaultAddress: FacilityAddress = {
  street_address1: '',
  street_address2: '',
  city_town: '',
  postal_code: '',
  state_province: '',
};

const mapProviderTypeToString = (type: ProviderType) => {
  switch (type) {
    case ProviderType.PArnp:
      return 'ARNP';

    case ProviderType.PDo:
      return 'DO';

    case ProviderType.PMd:
      return 'MD';

    case ProviderType.PPa:
      return 'PA';
  }
};

export function extractData(data: IPracticeFacilityDetailsResponse): PracticeFacilityDetailsData {
  const facility = data.facility_model;
  const facilityName = facility?.name;
  const address = facility?.location?.postal_address;
  const coordinates = facility?.location?.geolocation?.coordinates;
  const latLong = coordinates?.length === 2 ? [coordinates[1], coordinates[0]] : [];
  let facilityAddress = defaultAddress;

  if (address) {
    facilityAddress = {
      street_address1: address.street_address1 || '',
      street_address2: address.street_address2,
      city_town: address.city_town || '',
      state_province: address.state_province || '',
      postal_code: address.postal_code || '',
    };
  }

  const practiceFacilityName = data?.name;
  const practiceFacilityStreetAddress2 = data?.street_address_2;

  const providerFullNames: string[] = [];

  data?.providers_at_facility?.forEach((p) => {
    const providerNames = p?.provider?.party?.person?.current_name?.names;
    const providerType = p?.provider?.type;

    if (!providerNames || providerNames.length === 0) {
      return;
    }

    const fullName = providerNames.join(' ');

    if (providerType && providerType !== ProviderType.PUnknownType) {
      const titleAsString = mapProviderTypeToString(providerType);

      providerFullNames.push(`${fullName}, ${titleAsString}`);
    } else {
      providerFullNames.push(fullName);
    }
  });

  return {
    practiceFacilityName: practiceFacilityName || '',
    latLong: latLong,
    practiceFacilityStreetAddress2: practiceFacilityStreetAddress2 || '',
    facilityName: facilityName || practiceFacilityName || '',
    facilityAddress: facilityAddress,
    providerFullNames: providerFullNames,
  };
}
