import { SxProps, Theme } from '@mui/material';

export const searchResultsContainerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxWidth: (theme: Theme) => theme.breakpoints.values.sm,
  padding: '0',
};

export const listItemWrapSx: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  padding: '.75rem 1.25rem .75rem 1.25rem',
  '&:hover': {
    backgroundColor: (theme: Theme) => theme.palette.custom.lightBlue,
  },
};

export const listItemContainerSx: SxProps<Theme> = {
  flexShrink: '0',
  width: '100%',
  position: 'relative',
};

export const listItemActiveIndicatorBarSx: SxProps<Theme> = {
  position: 'absolute',
  left: '0',
  top: '0',
  width: '8px',
  height: '100%',
  backgroundColor: (theme: Theme) => theme.palette.custom.link,
};

export const listItemHeaderSx: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.custom.veryDarkBlue,
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '100%',
  marginBottom: '.125rem',
};

export const listItemDetailsSx: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: 'normal',
};

export const listItemDistanceSx: SxProps<Theme> = {
  fontWeight: 300,
  fontSize: '.75rem',
  lineHeight: 'normal',
};
