import * as yup from 'yup';

export const NMEEmailFormSchema = yup
  .object({
    email: yup
      .string()
      .required('Email address is required')
      .email('Email address must be valid.')
      .test(
        'offline',
        'It appears that you are offline. Please check your internet connection and try login again.',
        () => navigator.onLine
      ),
  })
  .required();
