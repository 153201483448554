import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useMemo, useState } from 'react';
import { getCurrentUserPartyId } from '../../../app/graphql/auth.utils';
import { useGetStripePublicKeyQuery, useSetupIntentMutation } from '../../../app/graphql/_generated/hooks';
import { useAppDispatch } from '../../../app/hooks';
import PageTitle from '../../../components/PageTitle';
import { AddForm } from '../../../components/Stripe';
import { saveIntent } from '../../../features/paymentMethods/paymentMethodSlice';
import LoadingScreen from '../../Loading';
import './stripeElementsFixes.css';

function AddCardPage() {
  const dispatch = useAppDispatch();
  const partyId = getCurrentUserPartyId();
  const [pubKey, setPubKey] = useState('');
  const [loading, setLoading] = useState(false);

  let key: string | null | undefined;

  const [setupIntentMutation, { loading: intentLoading }] = useSetupIntentMutation({
    onCompleted: (result) => {
      const intent = result.stripeSetupIntent?.clientSecret;

      intent && dispatch(saveIntent(intent));
    },
  });

  const { loading: keyLoading } = useGetStripePublicKeyQuery({
    onCompleted: (result) => {
      key = result.stripe_config?.public_key;
      key && setPubKey(key);

      partyId && setupIntentMutation({ variables: { party_id: partyId } });
    },
  });

  const stripePromise = useMemo(() => {
    if (pubKey) {
      return loadStripe(pubKey);
    }
    return new Promise<Stripe | null>((resolve) => resolve(null));
  }, [pubKey]);

  return (
    <Elements stripe={stripePromise}>
      <PageTitle title="New Card" />
      {(keyLoading || intentLoading || loading) && <LoadingScreen />}
      <AddForm setLoader={setLoading} />
    </Elements>
  );
}

export default AddCardPage;
