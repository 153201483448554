import * as yup from 'yup';

export const NMEMemberContactInfoEditFormSchema = yup
  .object({
    mailingAddress: yup.string().required('Mailing address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().required('ZIP code is required'),
    phoneNumber: yup
      .string()
      .required('Mobile number is required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be valid'),
  })
  .required();
