import { Coordinate } from './ProviderSearchPage.types';

export const latLongOfMiamiZipCode: Coordinate = {
  longitude: -80.19871,
  latitude: 25.778319,
};
export const zipCodeInMiami = '33101';

export const latLongOfOklahomaCityZipCode = {
  lng: -97.51267500014963,
  lat: 35.46556929574102,
};
export const zipCodeInOklahomaCity = '73102';

export const actualCenterOfUSA = { lat: 39.5, lng: -98.35 };
export const adjustedCenterOfUSA = { lat: 39.5, lng: -110.35 }; // adjusting eastward to show in center of the right three-quarters of search page

export const numberOfMetersInAMile = 1609.34;
export const countyWideRadiusInMeters = 100 * numberOfMetersInAMile;
export const stateWideRadiusInMeters = 300 * numberOfMetersInAMile;
export const regionWideRadiusInMeters = 700 * numberOfMetersInAMile;
export const nationWideRadiusInMeters = 10000 * numberOfMetersInAMile;
