import { Box, Link } from '@mui/material';
import { styles } from './styles';
import { MemberDetailsData } from '../../pages/WalletPage.utils';

const IdCardConstants = {
  payerId: '1Nomi',
  eligibilityCheck: '855-601-1900',
  claimSubmission: 'getcare@nomihealth.com',
};

function IdCard({ memberDetails }: { memberDetails: MemberDetailsData | null }) {
  return (
    <Box sx={styles.idCardContainer}>
      <Box sx={styles.IdCardRow}>
        <Box sx={styles.twoColumn}>
          <Box sx={styles.titleText}>name</Box>
          <Box sx={styles.dataText}>{memberDetails?.names}</Box>
        </Box>
        <Box sx={styles.twoColumn}>
          <Box sx={styles.titleText}>member id</Box>
          <Box sx={styles.dataText}>{memberDetails?.subscriber_id}</Box>
        </Box>
      </Box>
      <Box sx={styles.IdCardRow}>
        <Box sx={styles.twoColumn}>
          <Box sx={styles.titleText}>group id</Box>
          <Box sx={styles.dataText}>{memberDetails?.group_id}</Box>
        </Box>
        <Box sx={styles.twoColumn}>
          <Box sx={styles.titleText}>payer id</Box>
          <Box sx={styles.dataText}>{IdCardConstants.payerId}</Box>
        </Box>
      </Box>
      <Box sx={[styles.IdCardRow, { flexDirection: 'column', mb: 0 }]}>
        <Box sx={styles.titleText}>need help?</Box>
        <Link href={`tel:${IdCardConstants.eligibilityCheck}`} sx={[styles.dataText, styles.linkDataText]}>
          {IdCardConstants.eligibilityCheck}
        </Link>
        <Link href={`mailto:${IdCardConstants.claimSubmission}`} sx={[styles.dataText, styles.linkDataText]}>
          {IdCardConstants.claimSubmission}
        </Link>
      </Box>
    </Box>
  );
}

export default IdCard;
