import { ButtonBase, Typography, Container } from '@mui/material';

import { StatusLabel } from '../StatusLable';
import { dateMMddyyyy } from '../../utils/format-date';
import { IBillFragment, IEncounterFragment } from '../../app/graphql/_generated/hooks';
import { formatAmount } from '../../utils/format-amount';

interface IBillWithInvoiceId extends IBillFragment {
  invoice_id: string;
}

const styles = {
  wrap: {
    display: 'flex',
    padding: 0,
    width: '100%',
    alignItems: 'stretch',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 0,
    padding: 0,
  },
  leftRow: {
    flex: 1.5,
  },
  rightRow: { alignItems: 'flex-end', flex: 1 },
  amount: {
    fontSize: '22px',
    fontWeight: '500',
    textAlign: 'right',
    fontFamily: 'Cera Pro',
  },
  facility: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    paddingBottom: '4px',
  },
  date: { fontSize: '12px', textAlign: 'left' },
};

export interface ShortBillInfoProps {
  bill?: IBillWithInvoiceId | null;
  encounter?: IEncounterFragment | null;
  onPress?: () => void;
  notPaid?: boolean;
}

function ShortBillInfo({ bill, encounter, onPress, notPaid }: ShortBillInfoProps) {
  return (
    <ButtonBase data-id="short-bill-info" sx={styles.wrap} onClick={onPress}>
      <Container sx={[styles.row, styles.leftRow]}>
        <Typography data-id="facility-name" sx={[styles.facility]}>
          {encounter?.practice_facility?.facility_model?.name}
        </Typography>
        <Typography data-id="service_date" sx={styles.date}>{`${!notPaid ? 'Date of Service: ' : ''}${dateMMddyyyy(
          encounter?.service?.service_time
        )}`}</Typography>
      </Container>
      <Container data-id="bill-state" sx={[styles.row, styles.rightRow]}>
        <Typography sx={[styles.amount]}>{`$${formatAmount(bill?.bill_total_pennies)}`}</Typography>
        {!notPaid && <StatusLabel status={bill?.bill_state} />}
      </Container>
    </ButtonBase>
  );
}

export default ShortBillInfo;
