// src/contexts/authContext.tsx
import { createContext, useContext } from 'react';
import { LoginResponseBodyWithStatus } from '../../app/rest-api/new-member-experience-login/types';

export interface IAuthContext {
  isAuthenticated: () => boolean;
  loginWithPingRedirect: (loginHint: string, pingClientId?: string) => Promise<void>;
  loginWithRestApi: (email: string, firstName?: string, dateOfBirth?: string) => Promise<LoginResponseBodyWithStatus>;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const useAuth = () => {
  return useContext(AuthContext);
};
