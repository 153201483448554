import { CURRENT_ENVIRONMENT } from './environments';

export const AUTH_STORAGE_KEY = 'auth';
export const PROVIDER_DASHBOARD = '#';

// export const AUTH_URL = 'https://staging.telluswellness.com/oauth2/authorize';
export const AUTH_URL = 'https://nomi-connect.west.stable.nomihealth-sandbox.com/auth/sign-in';

// looks like we don not use it anywhere keep it in a commnent, just to make sure that  we dont need it anymore
// export const CLIENT_ID = 'f2zbCT5Smv4zFyZi2mnXKM29INvXPSWOi2C3BI3-_5c';

export const NOMI_WEBSITE = 'https://nomihealth.com';

export const storage_keys = {
  access_token: 'member_access_token',
  bill_to_pay: 'billToPay',
  client_id: 'member_client_id',
  closed_tab_expires_in: 'closed_tab_expires_in',
  code_verifier: 'member_code_verifier',
  confirmed_dependents: 'confirmed_dependents',
  default_payment_method: 'defPM',
  email_otp_device: 'email_otp_device',
  expires_in: 'member_expires_in',
  id_token: 'member_id_token',
  otp_device_type_preference: 'otp_device_type_preference',
  pkce_state: 'member_pkce_state',
  refresh_token: 'member_refresh_token',
  saved_otp_method_id: 'saved_otp_method_id',
  sms_otp_device: 'sms_otp_device',
  storage_timestamp: 'member_storage_timestamp',
  user_email_key: 'email',
  user_has_agreed_to_accept_terms: 'user_has_agreed_to_accept_terms',
  user_needs_to_confirm_contact_info: 'user_needs_to_confirm_contact_info',
  user_party_id_key: 'party_id',
};

export const PING_URL = {
  token: `https://auth.pingone.com/${CURRENT_ENVIRONMENT.PING.ENVIRONMENT_ID}/as/token`,
  authorise: `https://auth.pingone.com/${CURRENT_ENVIRONMENT.PING.ENVIRONMENT_ID}/as/authorize`,
  logout: `https://auth.pingone.com/${CURRENT_ENVIRONMENT.PING.ENVIRONMENT_ID}/as/signoff`,
};

export const CONTACT_DATA = {
  tellusEmail: 'email@domain.com',
  tellusPhone: '888-888-5555',
  nomiEmail: 'tellus@support.nomihealth.com',
  nomiPhone: '888-888-5555',
};

export const NETWORK_ID = CURRENT_ENVIRONMENT.NETWORK_ID;

export const NEW_MEMBER_EXPERIENCE_FEATURE_FLAG_KEY = 'new_member_experience_feature_flag';
export const LOGOUT_TIMEOUT = 'logout_timeout';

export const SOCIAL_LINKS = {
  fb: 'https://www.facebook.com/Nomi-Health-106470774923973/',
  tw: 'https://twitter.com/nomihealth',
  ln: 'https://www.linkedin.com/company/nomi-health/',
};
export const LEGAL_LINKS = {
  hipaa: 'https://nomihealth.com/hipaa/',
  privacy: 'https://nomihealth.com/privacypolicy/',
  ccpa: 'https://nomihealth.com/ccpa',
  support: '#',
  legal: 'https://nomihealth.com/terms-of-service/',
};
