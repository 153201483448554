import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <F extends (...args: any[]) => any>(callback: F, delay: number): ((...args: Parameters<F>) => void) => {
  const debounceRef = useRef<ReturnType<typeof setTimeout>>();

  const debouncedFunction = useCallback(
    (...args: Parameters<F>) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedFunction;
};
