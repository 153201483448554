import { Box, Button, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Condition } from '@nomi-health-inc/components-ui';
import {
  backButtonSx,
  backButtonContainerSx,
  careGuideContactInfoBackgroundSx,
  careGuideContactInfoCallToActionTitleSx,
  careGuideContactInfoDetailsSx,
  contactACareGuideBlurbSx,
  contactACareGuideTitleSx,
  contentContainerSx,
  detailsContainerSx,
  detailValueSx,
  envelopeIconSx,
  firstAddressLineSx,
  hospitalAddressContainerSx,
  hospitalIconSx,
  mobilePhoneIconSx,
  referralCallToActionTitleSx,
  scrollableContentContainerSx,
  secondAddressLineSx,
  seeMoreOrLessProvidersButtonSx,
  submitAReferralButtonSx,
} from './NewCCNLocationDetails.styles';
import { ChevronLeft } from '@mui/icons-material';
import { useCallback, useMemo, useState } from 'react';
import { usePracticeFacilityDetails } from './usePracticeFacilityDetails';
import { boldInfoText, highDeductiblePlanSx } from '../NewProviderSearch.styles';
import { AnimatedNomiLogoLoadingModal } from '../../../LinearLoadingModal/AnimatedNomiLogoLoadingModal';

interface NewCCNLocationDetailsProps {
  practiceFacilityId: string | null;
  onBackButtonClick: () => void;
}

export function NewCCNLocationDetails({ practiceFacilityId, onBackButtonClick }: NewCCNLocationDetailsProps) {
  const [areExpandingProvidersList, setAreExpandingProvidersList] = useState(false);
  const { locationDetails: details, loading } = usePracticeFacilityDetails(practiceFacilityId);

  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const toggleProviderList = useCallback(() => {
    setAreExpandingProvidersList(!areExpandingProvidersList);
  }, [areExpandingProvidersList, setAreExpandingProvidersList]);

  const providersToDisplay = useMemo(() => {
    if (!details?.providers) {
      return [];
    } else if (areExpandingProvidersList || details.providers.length <= 3) {
      return details.providers;
    } else {
      return details.providers.slice(0, 3);
    }
  }, [areExpandingProvidersList, details?.providers]);

  return (
    <Stack sx={contentContainerSx}>
      <Condition when={areOnMobileDevice}>
        <Box sx={highDeductiblePlanSx}>
          <Typography variant="body5">Part of a High Deductible Plan?</Typography>
          <Typography variant="body5">
            Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>

      <Box sx={backButtonContainerSx}>
        <Button variant="text" onClick={onBackButtonClick} sx={backButtonSx}>
          <ChevronLeft />
          Go Back
        </Button>
      </Box>

      <AnimatedNomiLogoLoadingModal loading={loading} />

      <Box sx={scrollableContentContainerSx}>
        <Condition when={!loading}>
          <Stack sx={detailsContainerSx}>
            <Typography variant="h5">{details?.name}</Typography>
            {providersToDisplay?.map((provider, index) => {
              return (
                <Typography key={`${index}-${provider}`} sx={detailValueSx}>
                  {provider}
                </Typography>
              );
            })}
            <Condition when={details?.providers && details?.providers.length > 3}>
              <Box onClick={toggleProviderList} sx={seeMoreOrLessProvidersButtonSx}>
                {areExpandingProvidersList ? 'See Less' : 'See More'}
              </Box>
            </Condition>
            <Stack direction="row" alignItems="flex-start" sx={hospitalAddressContainerSx}>
              <Stack>
                <Stack direction="row" alignItems="center">
                  <Box component="img" alt="hospital-icon" src="/images/hospital.svg" sx={hospitalIconSx} />
                  <Typography sx={firstAddressLineSx}>{details?.firstAddressLine || '-'}</Typography>
                </Stack>
                <Typography sx={secondAddressLineSx}>{details?.secondAddressLine || '-'}</Typography>
              </Stack>
            </Stack>
            <Typography variant="body4" sx={contactACareGuideTitleSx}>
              Contact A Care Guide
            </Typography>
            <Typography sx={contactACareGuideBlurbSx}>
              Care guides can help you understand your costs, answer questions, and guide you through your journey to set an appointment
            </Typography>
          </Stack>
          <Stack alignItems="center" spacing={1} sx={careGuideContactInfoBackgroundSx}>
            <Typography sx={careGuideContactInfoCallToActionTitleSx}>Call, text, or email a care guide </Typography>
            <Stack direction="row" alignItems="center">
              <Box component="img" alt="mobile-phone-icon" src="/images/mobile-phone.svg" sx={mobilePhoneIconSx} />
              <Link href="tel:+18556011900" underline="always" sx={careGuideContactInfoDetailsSx}>
                855-601-1900
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box component="img" alt="envelope-icon" src="/images/envelope.svg" sx={envelopeIconSx} />
              <Link href="mailto:getcare@nomihealth.com" underline="always" sx={careGuideContactInfoDetailsSx}>
                getcare@nomihealth.com
              </Link>
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <Typography sx={referralCallToActionTitleSx}>Already have a referral to send us?</Typography>
            <Button variant="text" href="https://nomihealth.jotform.com/232564708447059" target="_blank" sx={submitAReferralButtonSx}>
              Submit a referral
            </Button>
          </Stack>
        </Condition>
      </Box>
    </Stack>
  );
}
