import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tellusLogoSx } from './TellusLogo.styles';

export function TellusLogo({ blockLogo }: { blockLogo?: boolean }) {
  const navigate = useNavigate();

  return (
    <Box
      component="img"
      alt="Tellus Logo"
      src="/images/tellus-logo.svg"
      sx={tellusLogoSx}
      onClick={() => {
        !blockLogo && navigate('/');
      }}
    />
  );
}
