import { Box, Typography } from '@mui/material';
import NMEFloatingCard from '../NMEFloatingCard/NMEFloatingCard';
import { styles } from './SearchPracticeFacilityCard.styles';
import SearchPracticeFacilityForm from './SearchPracticeFacilityForm';

function SearchPracticeFacilityCard() {
  return (
    <Box sx={styles.wrap}>
      <NMEFloatingCard>
        <Typography variant="h5" fontWeight="500">
          Find a Provider
        </Typography>
        <SearchPracticeFacilityForm />
      </NMEFloatingCard>
    </Box>
  );
}

export default SearchPracticeFacilityCard;
