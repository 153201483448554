import { Theme } from '@mui/material';

export const styles = {
  backButton: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '0',
  },
  leftChevronIcon: {
    fontSize: '35px',
    color: (theme: Theme) => theme.palette.custom.veryDarkBlue,
  },
  reverseRowContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
    width: '100vw',
    height: '100%',
    maxHeight: '528px',
    maxWidth: '1000px',
    marginTop: 0,
    marginX: 'auto',
  },
  flexGrow: {
    flexGrow: '1',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
};
