import { useCallback, useEffect } from 'react';
import { Box, Button, IconButton, InputAdornment, Link, MenuItem, Select, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  boldInfoText,
  dropdownClearButtonSx,
  highDeductiblePlanSx,
  locateIconSx,
  primaryButtonContainerSx,
  searchFormContainerSx,
  searchFormHeaderSx,
  searchFormInputFieldsContainerSx,
  searchFormInputSx,
  searchFormSelectSx,
  searchFormSx,
  selectLabelSx,
} from './NewProviderSearch.styles';
import { Controller, useForm } from 'react-hook-form';
import { Condition, Input } from '@nomi-health-inc/components-ui';
import { DownChevronIcon } from '../DownChevronIcon';
import ClearIcon from '@mui/icons-material/Clear';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { NewProviderSearchFormSchema } from './NewProviderSearchFormSchema';
import { SpecialtyToTaxonomies } from '../../../app/graphql/_generated/hooks';

export interface IProviderSearchForm {
  groupID: string;
  selectedSpecialtyName: string;
  providerOrOfficeSearchTerm: string;
  zipCode: string;
}

function NewProviderSearchForm({
  onFormSubmit,
  getPreviousSearchParameters,
  currentUserLocationZipCode,
  centerMapOnCurrentUserLocation,
  providerSpecialties,
  wasGroupIdInvalid,
}: {
  onFormSubmit: (formData: IProviderSearchForm) => void;
  getPreviousSearchParameters: () => IProviderSearchForm | null;
  currentUserLocationZipCode: string | null;
  centerMapOnCurrentUserLocation: () => void;
  providerSpecialties: SpecialtyToTaxonomies[];
  wasGroupIdInvalid: boolean;
}) {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IProviderSearchForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(NewProviderSearchFormSchema),
    defaultValues: getPreviousSearchParameters() || {
      groupID: '',
      selectedSpecialtyName: '',
      providerOrOfficeSearchTerm: '',
      zipCode: '',
    },
  });

  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (currentUserLocationZipCode) {
      setValue('zipCode', currentUserLocationZipCode, { shouldValidate: true });
    }
  }, [currentUserLocationZipCode, setValue]);

  useEffect(() => {
    if (wasGroupIdInvalid) {
      setError('groupID', { message: 'No match found, please try again' }, { shouldFocus: true });
    }
  }, [setError, wasGroupIdInvalid]);

  const handleLocateMeButtonClick = useCallback(() => {
    if (!currentUserLocationZipCode) {
      return;
    }

    setValue('zipCode', currentUserLocationZipCode, { shouldValidate: true });
    centerMapOnCurrentUserLocation();
  }, [currentUserLocationZipCode, setValue, centerMapOnCurrentUserLocation]);

  return (
    <Box sx={searchFormContainerSx}>
      <Condition when={areOnMobileDevice}>
        <Box sx={highDeductiblePlanSx}>
          <Typography variant="body5">Part of a High Deductible Plan?</Typography>
          <Typography variant="body5">
            Call a care navigator:
            <Link href="tel:+18556011900" sx={boldInfoText}>
              855-601-1900
            </Link>
          </Typography>
        </Box>
      </Condition>

      <Typography sx={searchFormHeaderSx}>Search for care</Typography>

      <Box sx={searchFormInputFieldsContainerSx}>
        <form onSubmit={handleSubmit(onFormSubmit)} data-id="new-search-form" style={searchFormSx}>
          <Controller
            name="groupID"
            control={control}
            render={({ field }) => (
              <Input
                variant="outlined"
                label="Group ID"
                shouldShowRequiredText={true}
                placeholder="123456789"
                inputRef={field.ref}
                inputProps={{ 'data-id': 'groupIDInput' }}
                error={!!errors.groupID}
                errorMessage={errors.groupID?.message}
                errorDisplayLocation="upper-right"
                onChange={field.onChange}
                value={field.value}
                sx={searchFormInputSx}
              />
            )}
          />
          <Typography sx={selectLabelSx}>Specialty</Typography>
          <Controller
            name="selectedSpecialtyName"
            control={control}
            render={({ field }) => (
              <Select
                id="specialty-name-dropdown"
                variant="outlined"
                sx={searchFormSelectSx}
                IconComponent={
                  !field.value
                    ? DownChevronIcon
                    : () => (
                        <IconButton disableRipple onClick={() => field.onChange('')}>
                          <ClearIcon sx={dropdownClearButtonSx} />
                        </IconButton>
                      )
                }
                displayEmpty
                defaultValue=""
                renderValue={(value: string) => {
                  return value === '' ? 'Select One' : value;
                }}
                onChange={field.onChange}
                value={field.value}
                inputProps={{ 'data-id': 'specialtyDropdown' }}
              >
                {providerSpecialties.map((specialty) => {
                  return specialty.specialty ? (
                    <MenuItem key={specialty.specialty} value={specialty.specialty}>
                      {specialty.specialty}
                    </MenuItem>
                  ) : null;
                })}
              </Select>
            )}
          />
          <Controller
            name="providerOrOfficeSearchTerm"
            control={control}
            render={({ field }) => (
              <Input
                variant="outlined"
                label="Provider or Office"
                placeholder="Provider or Office"
                inputProps={{ 'data-id': 'providerOrOfficeSearchTermInput' }}
                onChange={field.onChange}
                value={field.value}
                sx={searchFormInputSx}
              />
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <Input
                variant="outlined"
                label="ZIP Code"
                placeholder="84003"
                inputRef={field.ref}
                inputProps={{ 'data-id': 'zipCodeInput' }}
                onChange={field.onChange}
                value={field.value}
                disabled={currentUserLocationZipCode === null}
                error={!!errors.zipCode}
                errorMessage={errors.zipCode?.message}
                errorDisplayLocation="upper-right"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={handleLocateMeButtonClick}>
                      <Box sx={locateIconSx} component="img" alt="map-user-location" src="/images/map/locator.svg" />
                    </InputAdornment>
                  ),
                }}
                sx={{ ...searchFormInputSx, marginBottom: '0px' }}
              />
            )}
          />
          <Button color="primary" variant="contained" sx={primaryButtonContainerSx} type="submit" data-id="searchProviderButton">
            Search
          </Button>
        </form>
      </Box>

      <Box flex={1} />
    </Box>
  );
}

export default NewProviderSearchForm;
