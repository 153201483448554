import { numberOfMetersInAMile } from './ProviderSearchPage.consts';
import { IPracticeFacilityInAreaFragment, Maybe } from '../../app/graphql/_generated/hooks';
import { IPracticeFacilityInArea } from '../../app/rest-api/public-provider-search/types';
import { PracticeFacilityForDisplay } from './ProviderSearchPage.types';

export function convertGraphQLResponseToPracticeFacilityList(
  response?: Maybe<IPracticeFacilityInAreaFragment>[] | null
): PracticeFacilityForDisplay[] {
  const results: PracticeFacilityForDisplay[] = [];

  response?.forEach((practiceFacilityInArea: IPracticeFacilityInAreaFragment | null) => {
    if (!practiceFacilityInArea) {
      return;
    }

    const facilityToDisplay = convertToPracticeFacilityToDisplay(practiceFacilityInArea);

    if (facilityToDisplay) {
      results.push(facilityToDisplay);
    }
  });

  return results;
}

export function convertPublicRestAPIResponseToPracticeFacilityList(
  practiceFacilitiesInArea: IPracticeFacilityInArea[]
): PracticeFacilityForDisplay[] {
  const results: PracticeFacilityForDisplay[] = [];

  practiceFacilitiesInArea?.forEach((practiceFacilityInArea: IPracticeFacilityInArea) => {
    const facilityToDisplay = convertToPracticeFacilityToDisplay(practiceFacilityInArea);

    if (facilityToDisplay) {
      results.push(facilityToDisplay);
    }
  });

  return results;
}

function convertToPracticeFacilityToDisplay(
  practiceFacilityInArea: IPracticeFacilityInArea | IPracticeFacilityInAreaFragment
): PracticeFacilityForDisplay | null {
  const distanceInMetersFromUser = practiceFacilityInArea?.distance;
  const practiceFacilityId = practiceFacilityInArea?.practice_facility?._id;
  const facility = practiceFacilityInArea?.practice_facility?.facility_model;
  const facilityName = facility?.name ?? practiceFacilityInArea?.practice_facility?.name;
  const postalAddress = facility?.location?.postal_address;
  const city = postalAddress?.city_town;
  const state = postalAddress?.state_province;
  const zipCode = postalAddress?.postal_code;
  const coordinates = facility?.location?.geolocation?.coordinates;

  if (
    !practiceFacilityId ||
    !distanceInMetersFromUser ||
    !facility ||
    !facilityName ||
    !postalAddress?.street_address1 ||
    !city ||
    !state ||
    !zipCode ||
    !coordinates ||
    coordinates.length < 2
  ) {
    return null;
  }

  return {
    id: practiceFacilityId.toString(),
    name: facilityName,
    firstAddressLine: postalAddress.street_address2
      ? `${postalAddress?.street_address1} ${postalAddress?.street_address2} ${postalAddress?.unit_number}`.trimEnd()
      : `${postalAddress?.street_address1} ${postalAddress?.unit_number}`.trimEnd(),
    secondAddressLine: `${city}, ${state} ${zipCode}`,
    coordinates: coordinates, // [longitude, latitude]
    distanceInMiles: Math.ceil(distanceInMetersFromUser / numberOfMetersInAMile),
    zipCode: zipCode,
  };
}

export function isZipCodeInvalid(zipCode: string) {
  return zipCode.length !== 5 && zipCode.length !== 10;
}
