import { SxProps, Theme } from '@mui/material';

export const contentContainerSx = {
  height: '100%',
  paddingTop: '1.25rem',
} as SxProps<Theme>;

export const scrollableContentContainerSx = {
  overflowY: 'auto',
  maxHeight: '100%',
} as SxProps<Theme>;

export const detailsContainerSx = {
  margin: '0 1.5rem',
} as SxProps<Theme>;

export const backButtonContainerSx = {
  paddingBottom: '.5rem',
  width: '100%',
  backgroundColor: 'white',
} as SxProps<Theme>;

export const backButtonSx = {
  marginLeft: '1rem',
  padding: '0 .5rem 0 0',
  height: '2rem',
  lineHeight: '20px',
} as SxProps<Theme>;

export const locationNameSx = {
  color: (theme) => theme.palette.custom.primaryGrey,
} as SxProps<Theme>;

export const careGuideBlurbContainerSx = {
  marginTop: '.5rem',
  padding: '1.25rem 1.25rem',
} as SxProps<Theme>;

export const careGuideContactInfoBackgroundSx = {
  padding: '1.25rem 0',
  marginBottom: '1.25rem',
  backgroundColor: (theme) => theme.palette.custom.veryDarkBlue,
} as SxProps<Theme>;

export const contactACareGuideTitleSx = {
  color: (theme) => theme.palette.custom.darkBlue,
  fontWeight: (theme) => theme.typography.fontWeightBold,
} as SxProps<Theme>;

export const contactACareGuideBlurbSx = {
  fontSize: '.875rem',
  lineHeight: '1.375rem',
  color: (theme) => theme.palette.custom.primaryGrey,
  fontWeight: (theme) => theme.typography.fontWeightRegular,
} as SxProps<Theme>;

export const careGuideContactInfoCallToActionTitleSx = {
  fontSize: '.875rem',
  lineHeight: '100%',
  color: '#FFFFFF',
  fontWeight: (theme) => theme.typography.fontWeightBold,
} as SxProps<Theme>;

export const careGuideContactInfoDetailsSx = {
  height: '.875rem',
  fontSize: '.875rem',
  color: '#FFFFFF',
  textDecorationColor: (theme) => theme.palette.custom.grey,
  fontWeight: (theme) => theme.typography.fontWeightRegular,
} as SxProps<Theme>;

export const mobilePhoneIconSx = {
  width: '10px',
  height: '15px',
  marginRight: '.25rem',
} as SxProps<Theme>;

export const envelopeIconSx = {
  width: '14px',
  height: '11px',
  marginRight: '.25rem',
} as SxProps<Theme>;
