import { ChangeEvent, useCallback, KeyboardEvent } from 'react';
import { Box, Button, MenuItem, Select, SelectChangeEvent, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { styles } from './ProviderSearch.styles';
import { PracticeFacilityForDisplay } from './ProviderSearchPage.types';
import { isZipCodeInvalid } from './ProviderSearchPage.utils';
import EmptyResults from './EmptyResults';
import PracticeFacilityList from './PracticeFacilityList';
import { SpecialtyToTaxonomies } from '../../app/graphql/_generated/hooks';
import { DownChevronIcon } from './DownChevronIcon';

function ProviderSearchListSection({
  activePracticeFacilityId,
  areConductingExpandingSearchForProviders,
  centerMapOnZipCode,
  hasUserExecutedProviderSearchPreviously,
  searchForProvidersWithExpandingSearchRadiuses,
  setZipCode,
  updateSelectedSpecialty,
  selectedSpecialty,
  searchResults,
  zipCode,
  isPublicSearch,
  providerSpecialties,
}: {
  activePracticeFacilityId: string;
  areConductingExpandingSearchForProviders: boolean;
  centerMapOnZipCode: (zipCode: string) => void;
  hasUserExecutedProviderSearchPreviously: boolean;
  searchForProvidersWithExpandingSearchRadiuses: (zipCode: string, selectedSpecialty: SpecialtyToTaxonomies) => void;
  setZipCode: (zipCode: string) => void;
  updateSelectedSpecialty: (specialtyToTaxonomies: SpecialtyToTaxonomies) => void;
  selectedSpecialty: SpecialtyToTaxonomies | null;
  searchResults: PracticeFacilityForDisplay[];
  zipCode: string;
  isPublicSearch?: boolean;
  providerSpecialties: SpecialtyToTaxonomies[];
}) {
  const handleZipCodeEdit = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setZipCode(event.target.value);
    },
    [setZipCode]
  );

  const handleZipCodeFieldBlur = useCallback(() => {
    if (!isZipCodeInvalid(zipCode)) {
      centerMapOnZipCode(zipCode);
    }

    if (hasUserExecutedProviderSearchPreviously && selectedSpecialty) {
      searchForProvidersWithExpandingSearchRadiuses(zipCode, selectedSpecialty);
    }
  }, [
    hasUserExecutedProviderSearchPreviously,
    zipCode,
    selectedSpecialty,
    centerMapOnZipCode,
    searchForProvidersWithExpandingSearchRadiuses,
  ]);

  const handleZipCodeFieldKeyPress = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (!isZipCodeInvalid(zipCode)) {
        centerMapOnZipCode(zipCode);
      }

      if (hasUserExecutedProviderSearchPreviously && selectedSpecialty) {
        searchForProvidersWithExpandingSearchRadiuses(zipCode, selectedSpecialty);
      }
    },
    [hasUserExecutedProviderSearchPreviously, zipCode, selectedSpecialty, centerMapOnZipCode, searchForProvidersWithExpandingSearchRadiuses]
  );

  const handleSpecialtySelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newSpecialtyToTaxonomies = providerSpecialties.find((entry) => entry.specialty === event.target.value);

      if (!newSpecialtyToTaxonomies || !newSpecialtyToTaxonomies.taxonomies) {
        return;
      }

      updateSelectedSpecialty(newSpecialtyToTaxonomies);

      if (hasUserExecutedProviderSearchPreviously) {
        searchForProvidersWithExpandingSearchRadiuses(zipCode, newSpecialtyToTaxonomies);
      }
    },
    [
      hasUserExecutedProviderSearchPreviously,
      zipCode,
      updateSelectedSpecialty,
      providerSpecialties,
      searchForProvidersWithExpandingSearchRadiuses,
    ]
  );

  const handleStartSearchButtonPress = useCallback(() => {
    if (selectedSpecialty) {
      searchForProvidersWithExpandingSearchRadiuses(zipCode, selectedSpecialty);
    }
  }, [zipCode, selectedSpecialty, searchForProvidersWithExpandingSearchRadiuses]);

  const isTabletOrDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  const heightRestrictionStyle = () => {
    if (isTabletOrDesktop) {
      return styles.mapHeightContainer;
    }

    return isPublicSearch ? styles.contentBelowMapAndTitleContainer : styles.contentBelowMapAndHeaderContainer;
  };

  return (
    <Box sx={[styles.flexGrow, styles.columnContainer, heightRestrictionStyle()]}>
      <Box sx={[styles.searchFieldsContainer, isTablet && styles.slimContainer]}>
        <Box sx={{ width: '38%', display: 'flex', flexDirection: 'column' }}>
          <Typography sx={styles.searchFieldHeader}>ZIP Code</Typography>
          <TextField
            variant="filled"
            value={zipCode}
            onBlur={handleZipCodeFieldBlur}
            onKeyPress={handleZipCodeFieldKeyPress}
            onChange={handleZipCodeEdit}
            disabled={areConductingExpandingSearchForProviders}
            inputProps={{ 'data-id': 'zipCodeInput' }}
          />
        </Box>
        <Box sx={{ width: '4%' }} />
        <Box sx={{ width: '58%', display: 'flex', flexDirection: 'column' }}>
          <Typography sx={styles.searchFieldHeader}>Specialty</Typography>
          <Select
            variant="filled"
            IconComponent={DownChevronIcon}
            displayEmpty
            defaultValue=""
            disabled={areConductingExpandingSearchForProviders}
            renderValue={(value: string) => {
              return value === '' ? 'Select' : value;
            }}
            value={selectedSpecialty?.specialty || ''}
            sx={styles.specialtyDropdown}
            onChange={handleSpecialtySelect}
            inputProps={{ 'data-id': 'specialtyDropdown' }}
          >
            {providerSpecialties.map((specialty) => {
              return specialty.specialty ? (
                <MenuItem key={specialty.specialty} value={specialty.specialty}>
                  {specialty.specialty}
                </MenuItem>
              ) : null;
            })}
          </Select>
        </Box>
      </Box>
      {!hasUserExecutedProviderSearchPreviously ? (
        <>
          <Box sx={{ height: '36px' }} />
          <Button
            color="primary"
            variant="contained"
            disabled={!selectedSpecialty || isZipCodeInvalid(zipCode) || areConductingExpandingSearchForProviders}
            sx={styles.primaryButtonContainer}
            onClick={handleStartSearchButtonPress}
            data-id="searchProviderButton"
          >
            Start Search
          </Button>
        </>
      ) : (
        <>
          {searchResults.length === 0 ? (
            <EmptyResults />
          ) : (
            <PracticeFacilityList
              activePracticeFacilityId={activePracticeFacilityId}
              practiceFacilities={searchResults}
              zipCode={zipCode}
              specialtyToTaxonomies={selectedSpecialty}
              isPublicSearch={isPublicSearch}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default ProviderSearchListSection;
