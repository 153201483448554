import { storage_keys } from './constants';

type InputValue = string | boolean | ObjectDefault | Array<unknown>;

const getStorage = (storage: Storage) => {
  return {
    getItem(key: string) {
      const val = storage.getItem(key);

      return val;
    },
    getObjValue<T>(key: string): T | null {
      const val = storage.getItem(key);

      return val === null ? null : JSON.parse(val);
    },
    removeItem(key: string) {
      storage.removeItem(key);
    },
    setItem(key: string, value: InputValue) {
      const val = typeof value === 'object' ? JSON.stringify(value) : String(value);

      storage.setItem(key, val);
    },
    setItems(items: { [key: string]: string }) {
      Object.entries(items).forEach((item) => {
        this.setItem(item[0], item[1]);
      });
    },
  };
};

// make it easy to swap local <-> session for authStorage
export const authStorage = getStorage(window.sessionStorage);
export const localStorage = getStorage(window.localStorage);
export const sessionStorage = getStorage(window.sessionStorage);

export const clearAuthStorage = () => {
  const partyId = authStorage.getItem(storage_keys.user_party_id_key);

  if (partyId) {
    authStorage.removeItem(partyId);
  }

  authStorage.removeItem(storage_keys.user_party_id_key);
  authStorage.removeItem(storage_keys.access_token);
  authStorage.removeItem(storage_keys.id_token);
  authStorage.removeItem(storage_keys.refresh_token);
  authStorage.removeItem(storage_keys.expires_in);
};
