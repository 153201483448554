import { useCallback, useMemo } from 'react';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { Input } from '@nomi-health-inc/components-ui';
import { Controller, UseFormReturn } from 'react-hook-form';
import { NMEFloatingCard, NMEFloatingCardTitleStyled } from '../../../../../components/NMEFloatingCard';
import { Content, stylesFromTheme } from '../../styles';
import { sendToDifferentOTPMethodButtonSx, sendNewCodeButtonContainerSx } from './NMEOTPForm.styles';
import { localStorage } from '../../../../../app/storageApi';
import { storage_keys } from '../../../../../app/constants';
import { ILoginDevice, OTPDeviceType } from '../../../../../app/rest-api/new-member-experience-login/types';
import { NMEPageTitle } from '../../../../../components/NMEPageTitle';

export interface IOTPFormData {
  otp: string;
}

interface IOTPFormProps {
  onSubmit: (data: IOTPFormData) => void;
  onRequestOfOTP: () => void;
  onRequestOfOTPByAlternativeMethod: () => void;
  preferredOTPDeviceType: string;
  shouldDisableSubmitButton: boolean;
  isMultipleOtp: boolean;
  formMethods: UseFormReturn<IOTPFormData>;
}

function NMEOTPForm({
  onSubmit,
  onRequestOfOTP,
  onRequestOfOTPByAlternativeMethod,
  preferredOTPDeviceType,
  shouldDisableSubmitButton,
  isMultipleOtp,
  formMethods,
}: IOTPFormProps) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors, isValid: isFormValid },
  } = formMethods;

  const maskedOtpDestination = useMemo(() => {
    const smsDevice = localStorage.getObjValue<ILoginDevice>(storage_keys.sms_otp_device);
    const emailDevice = localStorage.getObjValue<ILoginDevice>(storage_keys.email_otp_device);

    return preferredOTPDeviceType === OTPDeviceType.SMS ? smsDevice?.phone : emailDevice?.email;
  }, [preferredOTPDeviceType]);

  const getAlternativeOTPDeliveryTypeAsString = useCallback(() => {
    return preferredOTPDeviceType === OTPDeviceType.SMS ? 'Email' : 'Phone';
  }, [preferredOTPDeviceType]);

  return (
    <Stack flex={1} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Content>
        <Box data-id="page-title-container" sx={styles.pageTitleContainer}>
          <NMEPageTitle>A few quick steps to get started</NMEPageTitle>
        </Box>

        <NMEFloatingCard>
          <NMEFloatingCardTitleStyled>We sent a code to {maskedOtpDestination}. Please enter the code below.</NMEFloatingCardTitleStyled>
          <Box sx={{ ...styles.input, position: 'relative' }}>
            <Controller
              control={control}
              {...register('otp')}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    ref={null}
                    inputProps={{ 'data-id': 'otp-input' }}
                    sx={{ ...styles.input, mb: theme.spacing(2) }}
                    variant="filled"
                    label="Code"
                    placeholder="Enter Code"
                    helperText={formErrors.otp?.message}
                    error={!!formErrors.otp}
                    fullWidth
                  />
                  {isMultipleOtp && (
                    <Button
                      data-id="otp-method-toggle-button"
                      onClick={onRequestOfOTPByAlternativeMethod}
                      sx={sendToDifferentOTPMethodButtonSx}
                      disableRipple
                    >
                      Use {getAlternativeOTPDeliveryTypeAsString()} Instead
                    </Button>
                  )}
                </>
              )}
            />
          </Box>
        </NMEFloatingCard>
      </Content>
      <Box sx={styles.footerSection}>
        <Box sx={sendNewCodeButtonContainerSx}>
          <Button data-id="resend-otp-button" onClick={onRequestOfOTP} disableRipple>
            Send New Code
          </Button>
        </Box>
        <Button
          data-id="continue-button"
          type="submit"
          color="primary"
          variant="contained"
          sx={styles.continueButton}
          disabled={!isFormValid || shouldDisableSubmitButton}
        >
          Continue
        </Button>
      </Box>
    </Stack>
  );
}

export default NMEOTPForm;
