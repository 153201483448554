import axios from 'axios';
import fileDownload from 'js-file-download';

export async function fetchIDCardPDFDataAndShare(pdfUrl: string, primaryMemberNames: string) {
  const pdfDownloadResponse = await fetch(pdfUrl).catch((reason) => {
    // eslint-disable-next-line no-console
    console.log('PDF data download failed.', reason);
    return;
  });

  if (pdfDownloadResponse) {
    const buffer = await pdfDownloadResponse.arrayBuffer();
    const pdf = new File([buffer], `${primaryMemberNames} Nomi Membership Card.pdf`, { type: 'application/pdf' });

    if (!navigator.canShare || !navigator.canShare({ files: [pdf] })) {
      // eslint-disable-next-line no-console
      console.log('This browser cannot share member card PDF.');

      // download file instead share option
      axios
        .get(pdfUrl, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, `${primaryMemberNames} Nomi Membership Card.pdf`);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      return;
    }

    navigator.share({ files: [pdf] }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Error occurred during ID card PDF Share:', error);
    });
  }
}
