import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { styles } from './styles';
import { IAddFormProps } from './types';
import CardNumberField from './fields/CardNumberField';
import CardExpField from './fields/CardExpField';
import CardCvcField from './fields/CardCvcField';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { stripeIntent } from '../../features/paymentMethods/paymentMethodSlice';
import { getCurrentBillId, saveCurrentBillId } from '../../features/bills/billsSlice';
import { useGetPaymentMethodsLazyQuery } from '../../app/graphql/_generated/hooks';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';
import { storage_keys } from '../../app/constants';

function AddFormRaw({ setLoader }: IAddFormProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const intentId = useAppSelector(stripeIntent);
  const currentBillId = useAppSelector(getCurrentBillId);
  const elements = useElements();

  const [numberValid, updateNumberValidation] = useState(false);
  const [expValid, updateExpValidation] = useState(false);
  const [codeValid, updateCodeValidation] = useState(false);
  const [cardError, setCardError] = useState('');
  const [expError, setExpError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [asyncError, setAsyncError] = useState<string | null>(null);

  const partyId = getCurrentUserPartyId();
  const [getPaymentMethodsQuery] = useGetPaymentMethodsLazyQuery({
    variables: { id: partyId },
    onCompleted: () => {
      if (currentBillId) {
        navigate(`/pay-bill/${currentBillId}`, { replace: true });
        dispatch(saveCurrentBillId(''));
      } else {
        navigate('/', { replace: true });
      }
    },
    fetchPolicy: 'network-only',
  });

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoader(true);
    if (stripe) {
      await stripe
        .confirmCardSetup(intentId, {
          payment_method: {
            // eslint-disable-next-line
            // @ts-ignore
            card: elements.getElement('cardNumber'),
            billing_details: {},
          },
        })
        .then((data) => {
          if (data.error) {
            setAsyncError('Card authorization failed. Please make sure your card details are correct and try again.');
            setLoader(false);
            return;
          }
          if (typeof data?.setupIntent?.payment_method === 'string') {
            sessionStorage.setItem(storage_keys.default_payment_method, data.setupIntent.payment_method);
          }
          getPaymentMethodsQuery();
        });
    }
  };

  return (
    <>
      <Box sx={{ padding: '0 16px' }}>
        <form onSubmit={handleSubmit}>
          <Box sx={styles.card.field}>
            <CardNumberField
              stripe={stripe}
              elements={elements}
              setCardError={setCardError}
              updateValidation={updateNumberValidation}
              setAsyncError={setAsyncError}
            />
            <Typography sx={styles.card.errorText}>{cardError}</Typography>
          </Box>
          <Box sx={{ display: 'flex', marginBottom: '24px' }}>
            <Box sx={{ width: '49%', marginRight: '2%' }}>
              <Box sx={styles.card.field}>
                <CardExpField
                  stripe={stripe}
                  elements={elements}
                  setExpError={setExpError}
                  updateValidation={updateExpValidation}
                  setAsyncError={setAsyncError}
                />
                <Typography sx={{ ...styles.card.errorText, ...{ bottom: '-34px' } }}>{expError}</Typography>
              </Box>
            </Box>
            <Box sx={{ marginBottom: '10px', width: '49%' }}>
              <Box sx={styles.card.field}>
                <CardCvcField
                  stripe={stripe}
                  elements={elements}
                  setCvcError={setCvcError}
                  updateValidation={updateCodeValidation}
                  setAsyncError={setAsyncError}
                />
                <Typography sx={{ ...styles.card.errorText, ...{ bottom: '-34px' } }}>{cvcError}</Typography>
              </Box>
            </Box>
          </Box>

          <Button
            data-id="continue"
            disabled={!(numberValid && expValid && codeValid && !asyncError)}
            type="submit"
            color="primary"
            variant="contained"
            sx={styles.continueBtn}
          >
            Continue
          </Button>
          {asyncError && <Typography sx={styles.card.asyncError}>{asyncError}</Typography>}
        </form>
      </Box>
    </>
  );
}

export default memo(AddFormRaw);
