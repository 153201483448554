import { Condition, ModalSpinner } from '@nomi-health-inc/components-ui';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage_keys } from '../../../../app/constants';
import { LoginResponseBodyWithStatus } from '../../../../app/rest-api/new-member-experience-login/types';
import { localStorage } from '../../../../app/storageApi';
import { useAuth } from '../../../../features/authV2/AuthContext';
import NMEEmailForm from './NMEEmailForm';
import { IEmailFormData } from './NMEEmailForm/NMEEmailForm.types';
import NMEFirstNameAndDateOfBirthForm from './NMEFirstNameAndDateOfBirthForm';
import { IFirstNameAndDateOfBirthFormData } from './NMEFirstNameAndDateOfBirthForm/NMEFirstNameAndDateOfBirthForm.types';
import { getActiveOTPDevices, sendOTPToDeviceType } from './NMELoginPage.utils';
import { format } from 'date-fns';
import { ROUTES } from '../../../../constants/routes';
import { OnBoardingPageWrapper } from '../styles';

function NMELoginPage() {
  const navigate = useNavigate();
  const { loginWithRestApi } = useAuth();

  const [noConfirmDevices, setNoConfirmDevices] = useState<boolean>(false);
  const [areAwaitingAPIResponse, setAreAwaitingAPIResponse] = useState<boolean>(false);
  const [lastEmailSubmitted, setLastEmailSubmitted] = useState<string>('');
  const [haveSubmittedADuplicateEmail, setHaveSubmittedADuplicateEmail] = useState<boolean>(false);

  const handleLoginSuccessResponse = useCallback(
    (response: LoginResponseBodyWithStatus | void) => {
      if (!response || (response.status !== 200 && response.status !== 202)) {
        return;
      }

      const { id: pingFlowID, devices } = response;

      if (devices === null && response.status === 200) {
        setNoConfirmDevices(true);
        return;
      }

      const { email, phone } = getActiveOTPDevices(devices);

      email ? localStorage.setItem(storage_keys.email_otp_device, { ...email }) : localStorage.removeItem(storage_keys.email_otp_device);
      phone ? localStorage.setItem(storage_keys.sms_otp_device, { ...phone }) : localStorage.removeItem(storage_keys.sms_otp_device);

      const savedOtpMethodId = localStorage.getItem(storage_keys.saved_otp_method_id);

      const savedDevice = devices?.find((device) => device.id === savedOtpMethodId);

      if (savedDevice && response.status === 200) {
        if (devices.length > 1) {
          sendOTPToDeviceType(savedDevice.type, pingFlowID);
        }
        localStorage.setItem(storage_keys.otp_device_type_preference, savedDevice.type);

        navigate(ROUTES.otp, { state: { pingFlowID, isMultipleOtp: devices.length > 1 } });
      } else if (response.status === 200) {
        navigate(ROUTES.otpConfirmationMethod, { state: { shouldDisplayTermsAndConditionsCheckbox: false, pingFlowID, devices } });
      } else if (response.status === 202) {
        localStorage.setItem(storage_keys.user_needs_to_confirm_contact_info, 'true');
        navigate(ROUTES.otpConfirmationMethod, { state: { shouldDisplayTermsAndConditionsCheckbox: true, pingFlowID, devices } });
      }
    },
    [navigate]
  );

  const onEmailSubmit = useCallback(
    async ({ email }: IEmailFormData) => {
      try {
        setAreAwaitingAPIResponse(true);
        setLastEmailSubmitted(email);

        const response = await loginWithRestApi(email);

        handleLoginSuccessResponse(response);
      } catch (err) {
        if (!(err instanceof Error)) {
          return;
        }

        if (err.message === 'Account with email already exists') {
          setHaveSubmittedADuplicateEmail(true);
        } else {
          throw err;
        }
      } finally {
        setAreAwaitingAPIResponse(false);
      }
    },
    [loginWithRestApi, handleLoginSuccessResponse]
  );

  const onFirstNameAndDateOfBirthSubmit = useCallback(
    async ({ firstName, dateOfBirth }: IFirstNameAndDateOfBirthFormData) => {
      try {
        setAreAwaitingAPIResponse(true);

        const formattedDateOfBirth = format(dateOfBirth, 'yyyy-MM-dd');

        const response = await loginWithRestApi(lastEmailSubmitted, firstName, formattedDateOfBirth);

        handleLoginSuccessResponse(response);
      } finally {
        setAreAwaitingAPIResponse(false);
      }
    },
    [lastEmailSubmitted, loginWithRestApi, handleLoginSuccessResponse]
  );

  return (
    <>
      <OnBoardingPageWrapper>
        <Condition when={!haveSubmittedADuplicateEmail}>
          <NMEEmailForm onSubmit={onEmailSubmit} shouldDisableSubmitButton={areAwaitingAPIResponse} noConfirmDevices={noConfirmDevices} />
        </Condition>

        <Condition when={haveSubmittedADuplicateEmail}>
          <NMEFirstNameAndDateOfBirthForm
            onSubmit={onFirstNameAndDateOfBirthSubmit}
            shouldDisableSubmitButton={areAwaitingAPIResponse}
            noConfirmDevices={noConfirmDevices}
          />
        </Condition>
      </OnBoardingPageWrapper>
      <ModalSpinner loading={areAwaitingAPIResponse} text="Processing" />
    </>
  );
}

export default NMELoginPage;
