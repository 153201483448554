import { Stack, styled, Theme } from '@mui/material';

export const stylesFromTheme = (theme: Theme) => ({
  mainWrapper: {
    padding: '0 6rem',
    backgroundColor: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem',
    },
    flex: 1,
    justifyContent: 'space-between',
    mt: '35px',
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  pageTitleContainer: {
    display: 'flex',
  },
  input: {
    borderRadius: 'unset',
    marginTop: '25px',
    '& .MuiFormLabel-root': {
      fontWeight: '500',
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'left',
    },
    '& .MuiFilledInput-input': {
      borderRadius: 'inherit',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '100%',
    fontWeight: '700',
    marginTop: ({ spacing }: Theme) => spacing(1.5),
    padding: '0',
    height: '20px',
    ' img': { marginLeft: '10px' },
  },
  continueButton: {
    mt: ({ spacing }: Theme) => spacing(3),
    mb: ({ spacing }: Theme) => spacing(4.5),
    width: '100%',
  },
  contentLoginBlock: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentLoginWrap: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

export const Content = styled(Stack)(() => ({
  flex: 1,
  position: 'relative',
  justifyContent: 'center',
}));

export const OnBoardingPageWrapper = styled(Stack)(({ theme }) => ({
  padding: '0 6rem',
  backgroundColor: 'none',
  flex: 1,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '0 2rem',
  },
}));
