import { Box } from '@mui/material';
import { styles } from './NMEProviderSearchPage.styles';
import { ProviderSearch } from '../../components/ProviderSearch/ProviderSearch';
import { HeaderNav } from '../../components/Header';
import { Footer } from '../../components/Footer';

export default function NMEProviderSearchPage() {
  return (
    <Box sx={styles.screenContainer}>
      <HeaderNav />
      <ProviderSearch />
      <Box flex={1} />
      <Footer />
    </Box>
  );
}
