import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { Input, Select } from '@nomi-health-inc/components-ui';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserPartyId } from '../../../app/graphql/auth.utils';
import {
  GetAlloyAndBankStatusesDocument,
  GetPaymentMethodsDocument,
  OnboardingType,
  useBankAccountOnBoardingMutation,
  useGetAlloyAndBankStatusesLazyQuery,
} from '../../../app/graphql/_generated/hooks';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CustomizedDialog from '../../../components/Dialog';
import PageTitle from '../../../components/PageTitle';
import { getCurrentBillId, saveCurrentBillId } from '../../../features/bills/billsSlice';
import { getBankStatusValue } from '../../../utils/gqlResultsFormatters/bankAccountStatus';
import { useUserPaymentMethods } from '../../../utils/gqlResultsFormatters/useUserPaymentMethods.hook';
import LoadingScreen from '../../Loading';
import { BankAccSchema } from './schemas';
import { styles } from './styles';

type AccountType = 'savings' | 'checking';

interface IBankForm {
  firstName: string;
  lastName: string;
  routingNumber: string;
  accType: AccountType;
  accNumber: number;
  accNumber2: number;
  formValid: boolean;
}

const accountTypeOptions = [
  {
    value: 'savings',
    label: 'Savings',
  },
  {
    value: 'checking',
    label: 'Checking',
  },
];

function AddBankPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [onboardingStarted, setOnboardingStarted] = useState(false);
  const [open, setOpen] = useState(false);

  const currentBillId = useAppSelector(getCurrentBillId);
  const { paymentMethods = [] } = useUserPaymentMethods();
  const partyId = getCurrentUserPartyId();
  const [getAlloyAndBankStatuses] = useGetAlloyAndBankStatusesLazyQuery({
    onCompleted: (data) => {
      const lastBankStatus = getBankStatusValue(data);

      if (onboardingStarted && lastBankStatus === 'MANUAL_REVIEW') {
        navigate('/payment-method/add/bank-account/verification');
      } else if (onboardingStarted && lastBankStatus === 'APPROVED' && currentBillId) {
        navigate(`/pay-bill/${currentBillId}`);
        dispatch(saveCurrentBillId(''));
      } else if (onboardingStarted && lastBankStatus === 'APPROVED') {
        navigate('/');
      }
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
    watch,
  } = useForm<IBankForm>({
    mode: 'onChange',
    resolver: yupResolver(BankAccSchema),
  });

  useEffect(() => {
    if (getValues('accNumber') && getValues('accNumber2')) {
      trigger('accNumber2');
    }
    getAlloyAndBankStatuses({ variables: { id: partyId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('accNumber'), getAlloyAndBankStatuses, getValues, trigger, partyId]);

  const navigateToAllPaymentMethods = () => {
    navigate('/payment-method/all', { replace: true });
  };

  const handleSuccessAddedCard = () => {
    setOnboardingStarted(true);
  };

  const handleFailedAddedCard = () => {
    navigate('/payment-method/add/bank-account/error', { replace: true });
  };

  const [bankAccountOnboarding, { loading: bankOnboardLoading }] = useBankAccountOnBoardingMutation({
    onCompleted: handleSuccessAddedCard,
    refetchQueries: [
      { query: GetAlloyAndBankStatusesDocument, variables: { id: partyId } },
      { query: GetPaymentMethodsDocument, variables: { id: partyId } },
    ],
    onError: handleFailedAddedCard,
  });

  const onSubmit = (data: IBankForm) => {
    const { accType, routingNumber, accNumber } = data;
    const party_id = getCurrentUserPartyId();
    const typeReformat = `EXTERNAL_TYPE_${accType === 'savings' ? 'SAVINGS' : 'CHECKING'}`;
    const alreadyExistingBankAcc = paymentMethods.find(
      (paymentMethod) =>
        paymentMethod &&
        'bank_account' in paymentMethod &&
        paymentMethod?.bank_account?.routing_number === routingNumber &&
        paymentMethod?.bank_account?.account_number_last_four === String(accNumber).slice(-4) &&
        paymentMethod?.bank_account?.external_type === typeReformat
    );

    if (alreadyExistingBankAcc) {
      handleClickOpen();
      return;
    }

    const pmData = {
      onboarding_type: OnboardingType.BankAccount,
      party_id,
      bank_account_request: {
        account_type: accType,
        account_number: String(accNumber),
        account_holder: 'john smith',
        bank_account_name: 'Bank Account',
        routing_number: routingNumber,
      },
    };

    bankAccountOnboarding({ variables: { bankAccountOnboardingRequest: pmData } });
  };

  useEffect(() => {
    if (getValues('accNumber') && getValues('accNumber2')) {
      trigger('accNumber2');
    }
    // TODO: if you see this comment please review if we can resolve the lint issue
    // or replace comment with short explanation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('accNumber')]);

  return (
    <>
      <PageTitle title="New Bank Account" />
      {bankOnboardLoading && <LoadingScreen />}
      <Box sx={[{ padding: '0 16px' }, styles.formWrap]}>
        <form onSubmit={handleSubmit(onSubmit)} data-id="new-ba-form">
          <Controller
            control={control}
            {...register('accType')}
            render={({ field }) => (
              <Select
                data-id="acc-type"
                {...field}
                variant="filled"
                label="Account Type"
                helperText={errors.accNumber?.message}
                error={!!errors.accNumber}
                fullWidth
                options={accountTypeOptions}
                displayEmpty
                renderValue={field.value ? undefined : () => 'Select'}
                sx={field.value ? styles.marginBottom : [styles.opacity, styles.marginBottom]}
              />
            )}
          />
          <Controller
            control={control}
            {...register('routingNumber')}
            render={({ field }) => (
              <Input
                data-id="r-number"
                {...field}
                variant="filled"
                label="Routing Number"
                placeholder="E.g. 123456789"
                inputProps={{ maxLength: 9 }}
                helperText={errors.routingNumber?.message}
                error={!!errors.routingNumber}
              />
            )}
          />

          <Controller
            control={control}
            {...register('accNumber')}
            render={({ field }) => (
              <Input
                {...field}
                data-id="acc-number"
                variant="filled"
                label="Account Number"
                placeholder="E.g. 12341234"
                inputProps={{ maxLength: 17 }}
                helperText={errors.accNumber?.message}
                error={!!errors.accNumber}
              />
            )}
          />

          <Controller
            control={control}
            {...register('accNumber2')}
            render={({ field }) => (
              <Input
                data-id="acc-number-2"
                {...field}
                variant="filled"
                label="Confirm Account Number"
                placeholder="E.g. 12341234"
                inputProps={{ maxLength: 17 }}
                helperText={errors.accNumber2?.message}
                error={!!errors.accNumber2}
              />
            )}
          />
          <Button data-id="submit-btn" type="submit" color="primary" variant="contained" sx={styles.continueBtn} disabled={!isValid}>
            Save
          </Button>
        </form>
        <CustomizedDialog open={open} onClose={handleClose} title="Duplicate Bank Account">
          <Typography sx={styles.confirmText}>The bank account you entered has already been added to your payment methods.</Typography>
          <Button data-id="modal-ok-btn" variant="contained" fullWidth onClick={handleClose}>
            OK
          </Button>
          <Button data-id="view-pm" color="primary" variant="text" fullWidth onClick={navigateToAllPaymentMethods}>
            View Payment Methods
          </Button>
        </CustomizedDialog>
      </Box>
    </>
  );
}

export default AddBankPage;
