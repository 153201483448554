import { GetAlloyAndBankStatusesQuery, ReferenceAccountFragment } from '../../app/graphql/_generated/hooks';

export function getBankStatus(data: GetAlloyAndBankStatusesQuery | undefined) {
  const referencesData = data?.complianceInfoForParty?.references as ReferenceAccountFragment[];
  const filteredBankData = referencesData?.filter((account) => account.reference_type === 'GIACT_VERIFICATION');

  let failedAttempts = 0;

  filteredBankData?.forEach((acc) =>
    acc?.decision === 'DENIED' || acc?.decision === 'MANUAL_DENIED' ? (failedAttempts += 1) : failedAttempts
  );
  return failedAttempts >= 2;
}

export function getBankStatusValue(data: GetAlloyAndBankStatusesQuery | undefined) {
  const referencesData = data?.complianceInfoForParty?.references as ReferenceAccountFragment[];
  const filteredBankData = referencesData?.filter((account) => account.reference_type === 'GIACT_VERIFICATION') || [];
  const sortedByDateBankData =
    // avoid data mutation
    [...filteredBankData]?.sort((acc1, acc2) => {
      // set 1 milisecond for edge when one of the date is not set
      const date1 = acc1.effective_dates?.from_date || 1;
      const date2 = acc2.effective_dates?.from_date || 1;
      // set default utc to 0 it handle edge of the edgecases when date is not valid
      const utc1 = new Date(date1).valueOf() || 0;
      const utc2 = new Date(date2).valueOf() || 0;

      return utc2 - utc1;
    }) || [];

  return sortedByDateBankData[0]?.decision;
}
