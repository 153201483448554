const palette = {
  palette: {
    error: {
      main: '#D63541',
    },
    custom: {
      lightBlue: '#E5F1FE',
      veryDarkBlue: '#002266',
      darkBlue: '#343D4F',
      lightGreen: '#3DDBCD',
      warningYellow: '#F9E9D2',
      warningRed: '#E40032',
      warningText: '#9A571D',
      errorBackground: '#F5D2D2',
      grey: '#78849E',
      lightGrey: '#F8F9FD',
      heavyGrey: '#54617B',
      primaryGrey: '#1E1F22',
      link: '#0D41C0',
    },
  },
};

export default palette;
