import { Box, ButtonBase, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { saveCurrentBillId } from '../../../features/bills/billsSlice';
import { selectCurrentPM } from '../../../features/paymentMethods/paymentMethodSlice';
import { useUserPaymentMethods } from '../../../utils/gqlResultsFormatters/useUserPaymentMethods.hook';
import BoxWithShadow from '../../boxes/BoxWithShadow';
import { BankPaymentMethod, CardPaymentMethod } from '../BankPaymentMethod';
import { styles } from './styles';

export interface PaymentMethodChangeProps {
  seeAll?: boolean;
  error?: boolean;
}

function PaymentMethodChange({ seeAll, error }: PaymentMethodChangeProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let payMethod = useAppSelector(selectCurrentPM);
  const { paymentMethods: allPaymentMethods } = useUserPaymentMethods();
  const paymentMethods = allPaymentMethods?.filter((payMethod) => payMethod?.status !== 'HOLD') || [];

  payMethod = payMethod ? payMethod : paymentMethods[0];

  return (
    <BoxWithShadow
      error={error}
      title="Payment Method"
      linkText={seeAll ? 'See All' : null}
      linkUrl={seeAll ? '/payment-method/all' : null}
    >
      {payMethod ? (
        <Box sx={styles.wrapper}>
          {payMethod && payMethod.account_type === 'STRIPE_CUSTOMER' ? (
            <CardPaymentMethod data={payMethod} />
          ) : (
            <BankPaymentMethod data={payMethod} />
          )}
          <ButtonBase
            data-id="navigate-btn"
            sx={styles.button}
            onClick={() => {
              if (id) {
                dispatch(saveCurrentBillId(id));
              }
              navigate('/payment-method/change');
            }}
          >
            Change
          </ButtonBase>
        </Box>
      ) : (
        <Link data-id="add-pm-btn" sx={styles.link} underline="none" onClick={() => navigate('/payment-method/add')}>
          + Add payment method
        </Link>
      )}
    </BoxWithShadow>
  );
}

export default PaymentMethodChange;
