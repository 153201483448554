import DoneIcon from '@mui/icons-material/Done';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, Divider, Radio, RadioGroup, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { alpha, styled } from '@mui/material/styles';
import { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';
import { useGetAlloyAndBankStatusesQuery } from '../../app/graphql/_generated/hooks';
import PageTitle from '../../components/PageTitle';
import { getAlloyStatus } from '../../utils/gqlResultsFormatters/alloyStatus';
import { getBankStatusValue } from '../../utils/gqlResultsFormatters/bankAccountStatus';
import LoadingScreen from '../Loading';
import { styles } from './AddPaymentMethod.styles';
import { MyFormControlLabelProps, StyledFormControlLabelProps } from './AddPaymentMethod.types';

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => {
  const { verificationInProgress, ...rest } = props;

  return (
    <Box>
      <FormControlLabel {...rest} />
      {verificationInProgress && <Typography sx={styles.verificationText}>Verification in Progress</Typography>}
      <Divider />
    </Box>
  );
})(() => ({
  '.MuiFormControlLabel-label': {
    padding: '25px 0',
    ...styles.controlLabelText,
  },
}));

function MyFormControlLabel(props: MyFormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

// eslint-disable-next-line react/no-multi-comp
function BankAccountVerificationInProgressLabel() {
  return (
    <Box display="flex" alignItems="center">
      <Typography sx={{ ...styles.controlLabelText, color: alpha(styles.controlLabelText.color, 0.3), width: '9.625rem' }}>
        Bank Account
      </Typography>
      <Typography sx={{ ...styles.controlLabelErrorText, ml: '0.4rem' }}>Verification in Progress</Typography>
    </Box>
  );
}

// eslint-disable-next-line react/no-multi-comp
function AddPaymentMethodPage() {
  const navigate = useNavigate();
  const partyId = getCurrentUserPartyId();
  const { data: alloyAndBankData, loading } = useGetAlloyAndBankStatusesQuery({
    variables: { id: partyId },
  });
  const [method, setMethod] = useState('card');

  const isAlloyPassed = getAlloyStatus(alloyAndBankData);
  const lastBankStatus = getBankStatusValue(alloyAndBankData);

  return (
    <>
      <PageTitle title="Add Payment Method" />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box
          sx={{
            padding: '0 16px',
            marginTop: '50px',
            '.MuiFormControlLabel-root': {
              marginLeft: 0,
              width: '100%',
            },
          }}
        >
          <RadioGroup
            name="use-radio-group"
            defaultValue={method}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setMethod((event.target as HTMLInputElement).value);
            }}
          >
            <MyFormControlLabel
              value="card"
              label="Credit / Debit Card"
              control={<Radio icon={<Box sx={styles.icon} />} checkedIcon={<DoneIcon sx={styles.icon} />} />}
            />
            {isAlloyPassed ? (
              <MyFormControlLabel
                value="bank"
                label="Bank Account"
                control={<Radio icon={<Box sx={styles.icon} />} checkedIcon={<DoneIcon sx={styles.icon} />} />}
                verificationInProgress={lastBankStatus === 'MANUAL_REVIEW'}
              />
            ) : (
              <>
                <Box display="flex" alignItems="center" sx={{ padding: '25px 0' }}>
                  <WarningRoundedIcon sx={{ color: styles.warningIcon.color, margin: '0 9px', ...styles.icon }} />
                  <BankAccountVerificationInProgressLabel />
                </Box>
                <Divider />
              </>
            )}
          </RadioGroup>

          <Button
            data-id="continue-btn"
            color="primary"
            variant="contained"
            sx={styles.continueBtn}
            onClick={() => {
              if (method === 'bank') {
                navigate('/payment-method/add/bank-account', { replace: true });
              } else {
                navigate('/payment-method/add/card', { replace: true });
              }
            }}
          >
            Continue
          </Button>
        </Box>
      )}
    </>
  );
}

export default AddPaymentMethodPage;
