import { useMemo } from 'react';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';
import { useGetPaymentMethodsQuery } from '../../app/graphql/_generated/hooks';
import { getPaymentMethodsQueryResultsFormatter } from './paymentMethods';

export function useUserPaymentMethods() {
  const partyId = getCurrentUserPartyId();
  const { loading, data } = useGetPaymentMethodsQuery({
    variables: { id: partyId },
  });

  const paymentMethods = useMemo(() => data && getPaymentMethodsQueryResultsFormatter(data), [data]);

  return { loading, paymentMethods };
}
