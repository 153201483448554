import './iFrameResizerContentUtil';
import PublicProviderSearchThemeProvider from '../../components/ThemeProvider/PublicProviderSearchThemeProvider';
import { NewProviderSearch } from '../../components/ProviderSearch/NewProviderSearch/NewProviderSearch';

function PublicProviderSearchPage() {
  return (
    <PublicProviderSearchThemeProvider>
      <NewProviderSearch />
    </PublicProviderSearchThemeProvider>
  );
}

export default PublicProviderSearchPage;
