import { SpecialtyToTaxonomies, useGetPracticeFacilitiesInAreaLazyQuery } from '../../app/graphql/_generated/hooks';
import { useCallback, useState } from 'react';
import { getPracticeFacilitiesInArea } from '../../app/rest-api/public-provider-search';
import {
  convertGraphQLResponseToPracticeFacilityList,
  convertPublicRestAPIResponseToPracticeFacilityList,
  isZipCodeInvalid,
} from './ProviderSearchPage.utils';
import {
  countyWideRadiusInMeters,
  nationWideRadiusInMeters,
  regionWideRadiusInMeters,
  stateWideRadiusInMeters,
} from './ProviderSearchPage.consts';
import { PracticeFacilityForDisplay } from './ProviderSearchPage.types';

export type UseCheckPermissionReturn = {
  searchForProviders: (zipCode: string, specialtyToTaxonomies: SpecialtyToTaxonomies | null) => void;
  providerSearchResults: PracticeFacilityForDisplay[];
  hasProviderSearchBeenExecuted: boolean;
  areLoadingProviderSearchResults: boolean;
};

export const useProviderSearch = (isPublicSearch: boolean): UseCheckPermissionReturn => {
  const [runSearchForPracticeFacilitiesInAreaQuery] = useGetPracticeFacilitiesInAreaLazyQuery();
  const [searchResults, setSearchResults] = useState<PracticeFacilityForDisplay[]>([]);
  const [hasUserExecutedProviderSearchPreviously, setHasUserExecutedProviderSearchPreviously] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const searchUsingPublicRestAPI = useCallback(async (radius: number, zipCode: string, taxonomies: string[]) => {
    const result = await getPracticeFacilitiesInArea(zipCode, taxonomies, radius).catch(() => {
      // eslint-disable-next-line no-console
      console.log('Failed to get practice facilities in area');
    });

    if (!result) {
      return [];
    }

    return convertPublicRestAPIResponseToPracticeFacilityList(result);
  }, []);

  const searchUsingPrivateGraphQLAPI = useCallback(
    async (radius: number, zipCode: string, taxonomies: string[]) => {
      const result = await runSearchForPracticeFacilitiesInAreaQuery({
        variables: {
          zip_code: zipCode,
          taxonomies: taxonomies,
          search_radius_in_meters: radius,
        },
        fetchPolicy: 'network-only',
      });

      if (result.data) {
        return convertGraphQLResponseToPracticeFacilityList(result.data.practiceFacilitiesInArea);
      }

      throw result.error;
    },
    [runSearchForPracticeFacilitiesInAreaQuery]
  );

  const searchForProvidersInRadius = useCallback(
    async (radius: number, zipCode: string, taxonomies: string[]) => {
      if (isPublicSearch) {
        return searchUsingPublicRestAPI(radius, zipCode, taxonomies);
      } else {
        return searchUsingPrivateGraphQLAPI(radius, zipCode, taxonomies);
      }
    },
    [isPublicSearch, searchUsingPublicRestAPI, searchUsingPrivateGraphQLAPI]
  );

  const searchForProvidersWithExpandingSearchRadiuses = useCallback(
    async (zipCode: string, specialtyToTaxonomies: SpecialtyToTaxonomies | null) => {
      if (isZipCodeInvalid(zipCode) || !specialtyToTaxonomies || !specialtyToTaxonomies.taxonomies) {
        return;
      }

      setLoading(true);

      const { taxonomies } = specialtyToTaxonomies;

      await searchForProvidersInRadius(countyWideRadiusInMeters, zipCode, taxonomies)
        .then((providers) => (providers.length > 0 ? providers : searchForProvidersInRadius(stateWideRadiusInMeters, zipCode, taxonomies)))
        .then((providers) => (providers.length > 0 ? providers : searchForProvidersInRadius(regionWideRadiusInMeters, zipCode, taxonomies)))
        .then((providers) => (providers.length > 0 ? providers : searchForProvidersInRadius(nationWideRadiusInMeters, zipCode, taxonomies)))
        .then((providers) => setSearchResults(providers))
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('[Error searching for providers]:', error);
        });

      setLoading(false);
      setHasUserExecutedProviderSearchPreviously(true);
    },
    [searchForProvidersInRadius]
  );

  return {
    searchForProviders: searchForProvidersWithExpandingSearchRadiuses,
    providerSearchResults: searchResults,
    hasProviderSearchBeenExecuted: hasUserExecutedProviderSearchPreviously,
    areLoadingProviderSearchResults: loading,
  };
};
