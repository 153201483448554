import { SxProps, Theme } from '@mui/material';

export const optionalTextSx: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  right: '-4px',
  padding: '0 8px',
  color: (theme: Theme) => theme.palette.form.placeholder,
  fontSize: '11px',
  fontWeight: '400',
  fontStyle: 'italic',
};

export const stateDropdownLabelSx: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.custom.darkBlue,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 'normal',
};
