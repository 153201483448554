import { useLocation } from 'react-router-dom';
import { Error } from '../../components/Error';
import { ErrorProps } from '../../components/Error/Error';
import { HorizontalBg } from '../../components/HorizontalBg';

function ErrorPage() {
  const location = useLocation();
  const { action, text = 'Something Went Wrong', description } = (location.state as ErrorProps) || {};

  return (
    <HorizontalBg>
      <Error action={action} text={text} description={description} />
    </HorizontalBg>
  );
}

export default ErrorPage;
