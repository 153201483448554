import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { floatingCardSx } from './NMEFloatingCard.styles';

function NMEFloatingCard({ children }: { children: ReactNode }) {
  return (
    <Box data-id="floating-card" sx={floatingCardSx}>
      {children}
    </Box>
  );
}

export default NMEFloatingCard;
