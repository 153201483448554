import { CURRENT_ENV_NAME, EnvironmentEnum } from '../../app/environments';
import { datadogRum } from '@datadog/browser-rum';

const isProd = EnvironmentEnum.Production === CURRENT_ENV_NAME;
const isQA = EnvironmentEnum.QualityAssurance === CURRENT_ENV_NAME;

if (isProd || isQA) {
  datadogRum.init({
    applicationId: 'c49dd78f-5e2b-41b3-be3e-354a6a15297d',
    clientToken: 'pub442c5f48db363ba055e718942564c335',
    site: 'datadoghq.com',
    env: CURRENT_ENV_NAME,
    service: 'member-portal-web',
    version: '0.1.0', // should be updated along with version in package.json
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: 'mask',
    trackUserInteractions: true,
    trackViewsManually: true,
    trackResources: true,
    trackLongTasks: true,
    storeContextsAcrossPages: true,
    silentMultipleInit: true,
  });
}
