import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IBillNew, IPaymentDetails, IPlanUtilization } from '../api/bills/types';

export interface BillsState {
  status: 'idle' | 'loading' | 'failed';
  payDetails: null | IPaymentDetails;
  currentBill: IBillNew | null;
  currentBillId: string | null;
  code: number | null;
  planUtilization: Array<IPlanUtilization>;
}

const initialState: BillsState = {
  currentBill: null,
  currentBillId: null,
  payDetails: null,
  status: 'idle',
  code: null,
  planUtilization: [],
};

export const billsSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    saveCurrentBillId: (state, action: PayloadAction<string>) => {
      state.currentBillId = action.payload;
    },
    clearBillsState: () => {
      return initialState;
    },
    savePlanUtilization: (state, action: PayloadAction<IPlanUtilization[]>) => {
      state.planUtilization = action.payload;
    },
  },
});

export const { saveCurrentBillId, clearBillsState, savePlanUtilization } = billsSlice.actions;

export const getCurrentBillId = (state: RootState) => state.bills.currentBillId;
export const planUtilization = (state: RootState) => state.bills.planUtilization;
export default billsSlice.reducer;
