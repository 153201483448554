import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import GuttersContainer from '../../../../components/GuttersContainer';
import { NomiLogo } from '../../../../components/NomiLogo/NomiLogo';
import { ROUTES } from '../../../../constants/routes';
import {
  contentContainerSx,
  getStartedButtonSx,
  lineItemIconSx,
  lineItemsContainerSx,
  lineItemTextSx,
  welcomeTitleSx,
} from './WelcomePage.styles';

function NMEWelcomePage() {
  const navigate = useNavigate();

  const onClickOfGetStarted = useCallback(() => {
    navigate(ROUTES.root);
  }, [navigate]);

  return (
    <Box sx={contentContainerSx}>
      <GuttersContainer>
        <NomiLogo />
        <Typography sx={welcomeTitleSx}>How can we help?</Typography>
        <Stack spacing="36px" sx={lineItemsContainerSx}>
          <Stack direction="row" spacing="22px" alignItems="center">
            <Box component="img" alt="ID Card" src="/images/id-card.svg" sx={lineItemIconSx} />
            <Typography sx={lineItemTextSx}>Manage & share your Nomi ID card</Typography>
          </Stack>
          <Stack direction="row" spacing="22px" alignItems="center">
            <Box component="img" alt="Map" src="/images/map.svg" sx={lineItemIconSx} />
            <Typography sx={lineItemTextSx}>Find the healthcare provider that’s right for you</Typography>
          </Stack>
          <Stack direction="row" spacing="22px" alignItems="center">
            <Box component="img" alt="Nurse" src="/images/white-nurse.svg" sx={lineItemIconSx} />
            <Typography sx={lineItemTextSx}>Speak to a Care Navigator to coordinate care</Typography>
          </Stack>
        </Stack>
        <Button data-id="get-started-button" color="secondary" variant="contained" sx={getStartedButtonSx} onClick={onClickOfGetStarted}>
          Get Started
        </Button>
      </GuttersContainer>
    </Box>
  );
}

export default NMEWelcomePage;
