import { NetworkAccessType, SpecialtyToTaxonomies } from '../../../app/graphql/_generated/hooks';
import { useCallback } from 'react';
import { LocationListElement, ProviderListElement } from './NewProviderSearch.types';
import { nationWideRadiusInMeters } from '../ProviderSearchPage.consts';
import { callNewProviderSearchPublicAPI } from '../../../app/rest-api/public-provider-search';
import {
  convertNewPublicSearchRestAPIResponseToLocationsToDisplay,
  convertNewPublicSearchRestAPIResponseToProvidersToDisplay,
} from './NewProviderSearch.utils';
import { INewPracticeFacilitySearchResult, INewProviderAndFacilityResult } from '../../../app/rest-api/public-provider-search/types';
import { datadogRum } from '@datadog/browser-rum';

export enum PublicSearchType {
  PracticeFacility = 'PRACTICE_FACILITY',
  Provider = 'PROVIDER',
}

interface ProviderSearchResponse {
  wasGroupIdInvalid: boolean;
  wasGroupIdForCostContainmentNetwork: boolean;
  practiceFacilities: LocationListElement[];
  providers: ProviderListElement[];
}

export type UseNewProviderSearchHookReturn = {
  executeSearch: (
    zipCode: string,
    specialtyToTaxonomies: SpecialtyToTaxonomies | null,
    groupID: string | null,
    locationNameSearchTerm: string | null
  ) => Promise<ProviderSearchResponse | null>;
};

export const useNewPublicProviderSearch = (): UseNewProviderSearchHookReturn => {
  const callSearchAPI = useCallback(
    async (
      searchType: PublicSearchType,
      zipCode: string,
      taxonomies: string[] | null,
      groupID: string | null,
      searchTerm: string | null
    ): Promise<{
      wasGroupIdInvalid: boolean;
      areShowingOnlyCCNResults: boolean;
      results: LocationListElement[] | ProviderListElement[];
    }> => {
      const response = await callNewProviderSearchPublicAPI(
        searchType,
        zipCode,
        nationWideRadiusInMeters,
        taxonomies || [],
        groupID || '',
        searchTerm || ''
      );

      if (response.errors && response.errors.length > 0) {
        return {
          wasGroupIdInvalid: response.errors.includes('Unknown Group ID'),
          areShowingOnlyCCNResults: false,
          results: [],
        };
      }

      let results: LocationListElement[] | ProviderListElement[];

      if (searchType === PublicSearchType.PracticeFacility) {
        results = convertNewPublicSearchRestAPIResponseToLocationsToDisplay(response.data.results as INewPracticeFacilitySearchResult[]);
      } else {
        results = convertNewPublicSearchRestAPIResponseToProvidersToDisplay(response.data.results as INewProviderAndFacilityResult[]);
      }

      return {
        wasGroupIdInvalid: false,
        areShowingOnlyCCNResults: response.data.network_access_type === NetworkAccessType.NatCcn,
        results,
      };
    },
    []
  );

  const executeSearchForPracticeFacilitiesAndProviders = useCallback(
    (
      zipCode: string,
      specialtyToTaxonomies: SpecialtyToTaxonomies | null,
      groupID: string | null,
      searchTerm: string | null
    ): Promise<ProviderSearchResponse | null> => {
      const taxonomies = specialtyToTaxonomies?.taxonomies || [];

      return Promise.all([
        callSearchAPI(PublicSearchType.PracticeFacility, zipCode, taxonomies, groupID, searchTerm),
        callSearchAPI(PublicSearchType.Provider, zipCode, taxonomies, groupID, searchTerm),
      ])
        .then(([practiceFacilitiesResult, providersResult]) => {
          datadogRum.addAction('Public Provider Search', {
            zip: zipCode,
            specialty: specialtyToTaxonomies ? specialtyToTaxonomies.specialty : '',
            'group-id': groupID || '',
            'search-term': searchTerm || '',
            'practice-facility-results-count': practiceFacilitiesResult.results.length,
            'provider-results-count': providersResult.results.length,
            'was-ccn-only-group-id': practiceFacilitiesResult.areShowingOnlyCCNResults,
            'was-group-id-invalid': practiceFacilitiesResult.wasGroupIdInvalid,
          });

          return {
            wasGroupIdInvalid: practiceFacilitiesResult.wasGroupIdInvalid,
            wasGroupIdForCostContainmentNetwork: practiceFacilitiesResult.areShowingOnlyCCNResults,
            practiceFacilities: practiceFacilitiesResult.results as LocationListElement[],
            providers: providersResult.results as ProviderListElement[],
          };
        })
        .catch((error) => {
          datadogRum.addAction('Failed Public Provider Search', {
            errorMessage: error?.message ?? '',
          });
          return null;
        });
    },
    [callSearchAPI]
  );

  return {
    executeSearch: executeSearchForPracticeFacilitiesAndProviders,
  };
};
