import { useCallback } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import ContactACareGuide from './ContactACareGuide/ContactACareGuide';
import { PracticeFacilityAddress } from './PracticeFacilityAddress/PracticeFacilityAddress';
import { styles } from './PracticeFacilityDetails.styles';
import { ProviderNamesList } from './ProviderNamesList/ProviderNamesList';
import { PracticeFacilityDetailsData } from '../../pages/NMEPracticeFacilityDetailsPage/NMEPracticeFacilityDetailsPage.utils';
import { Condition } from '@nomi-health-inc/components-ui';
import { ChevronLeft } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

interface PracticeFacilityDetailsProps {
  details: PracticeFacilityDetailsData;
  isPublicSearch: boolean;
  areDisplayingLeftOfMap?: boolean;
}

function PracticeFacilityDetails({ details, isPublicSearch, areDisplayingLeftOfMap = false }: PracticeFacilityDetailsProps) {
  const navigate = useNavigate();
  const { state: navigationState } = useLocation();

  const goBackToProviderSearch = useCallback(() => {
    const destinationRoute = isPublicSearch ? ROUTES.publicProviderSearch : ROUTES.providerSearch;

    navigate(destinationRoute, { state: navigationState });
  }, [navigationState, navigate, isPublicSearch]);

  return (
    <Stack direction="row" alignItems="flex-start">
      <Condition when={isPublicSearch}>
        <IconButton
          disableRipple={true}
          aria-label="back"
          onClick={goBackToProviderSearch}
          sx={[styles.noPadding, !areDisplayingLeftOfMap && styles.marginTop]}
        >
          <ChevronLeft sx={styles.leftChevronIcon} />
        </IconButton>
      </Condition>
      <Box sx={[styles.container, areDisplayingLeftOfMap && styles.mapHeightContainer]}>
        <Stack direction="row" alignItems="flex-start" sx={[!areDisplayingLeftOfMap && styles.marginTop]}>
          <Stack>
            <Typography sx={[styles.title]}>{details.facilityName}</Typography>
            <Box sx={styles.inNetworkContainer}>
              <Box component="img" alt="nomi plus icon" src="/images/nomi-plus-icon.svg" sx={{ width: '13px', height: '13px' }} />
              <Typography ml={1} sx={styles.inNetworkTitleText}>
                In the Nomi Network
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <ProviderNamesList names={details.providerFullNames} />

        <ContactACareGuide />

        <PracticeFacilityAddress
          shouldDisplayInTwoColumns={areDisplayingLeftOfMap}
          practiceFacilityName={details.practiceFacilityName}
          practiceFacilityStreetAddress2={details.practiceFacilityStreetAddress2}
          facilityAddress={details.facilityAddress}
        />
      </Box>
    </Stack>
  );
}

export default PracticeFacilityDetails;
