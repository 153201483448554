import { createTheme, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { ThemeProvider as NomiThemeProvider } from '@nomi-health-inc/components-ui';
import publicProviderSearchTypographyOverride from './publicProviderSearchTypography';
import palette from '../ThemeProvider/palette';

export const overrideTypography = (theme: Theme): Theme => ({
  ...theme,
  typography: {
    ...theme.typography,
    ...publicProviderSearchTypographyOverride.typography,
  },
});

function PublicProviderSearchThemeProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <NomiThemeProvider>
        <MuiThemeProvider theme={(outerTheme) => overrideTypography(createTheme(outerTheme, palette))}>{children}</MuiThemeProvider>
      </NomiThemeProvider>
    </>
  );
}

export default PublicProviderSearchThemeProvider;
