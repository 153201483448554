import { Box, Stack, Typography } from '@mui/material';
import { styles } from './ProviderSearch.styles';

export default function EmptyResults() {
  return (
    <Stack sx={styles.providerEmptySearchResultsContainer}>
      <Box sx={{ height: '3rem', flexShrink: '1' }} />
      <Box component="img" alt="magnifying glass" src="/images/magnifying-glass.svg" sx={styles.dropdownIcon} />
      <Typography sx={styles.emptySearchResultsTitle}>No Results Found</Typography>
    </Stack>
  );
}
