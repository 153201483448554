import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Typography } from '@mui/material';
import PageTitle from '../../components/PageTitle';
import BoxWithShadow from '../../components/boxes/BoxWithShadow';
import { styles } from './styles';
import { dateMMddyyyy } from '../../utils/format-date';
import { useBillByIdQuery } from '../../app/graphql/_generated/hooks';
import { formatAmount } from '../../utils/format-amount';
import _get from 'lodash/get';
import { last } from 'lodash';
import LoadingScreen from '../Loading';

function PayBillSuccessPage() {
  const navigate = useNavigate();
  const params = useParams();
  const currentBillId = params.id || '';
  const { loading: loadingInvoice, data: queryInvoiceResponse } = useBillByIdQuery({
    variables: { invoice_id: currentBillId },
  });

  const currentInvoice = queryInvoiceResponse?.invoiceById;
  const practiceFacilityName = currentInvoice?.encounter?.practice_facility?.facility_model?.name || '';
  const providerNames = _get(currentInvoice, 'line_items[0].rendering_provider.party.person.current_name.names', []).join(' ');
  const memberBill = currentInvoice?.bills?.find((bill) => bill?.bill_type === 'MEMBER');
  const employerBill = currentInvoice?.bills?.find((bill) => bill?.bill_type === 'SPONSOR');
  const dateOfService = memberBill ? dateMMddyyyy(currentInvoice?.encounter?.service?.service_time) : null;
  const dateOfPayment = memberBill?.audit_logs ? last(memberBill?.audit_logs)?.transitioned_at_time : new Date().toISOString();
  const formattedDateOfPayment = dateOfPayment ? dateMMddyyyy(dateOfPayment) : null;

  const bankName = memberBill?.payment_method_account
    ? `${memberBill?.payment_method_account?.bank_account?.external_type === 'EXTERNAL_TYPE_SAVINGS' ? 'Savings' : 'Checking'}  •• ${
        memberBill?.payment_method_account?.bank_account?.account_number_last_four
      }`
    : null;
  const cardName = memberBill?.payment_method_stripe
    ? `${memberBill?.payment_method_stripe?.brand} •• ${memberBill?.payment_method_stripe?.last4}`
    : null;

  const paymentMethodName = bankName || cardName;

  return (
    <>
      <PageTitle hideBack title="Payment Submitted" />
      {loadingInvoice ? (
        <LoadingScreen />
      ) : (
        <Box sx={{ padding: '0 16px' }}>
          <BoxWithShadow data-id="success-card">
            <Typography data-id="practice-facility-name" sx={styles.title}>
              {practiceFacilityName}
            </Typography>
            <Typography data-id="provider-name" sx={styles.subtitle}>
              Provider: {providerNames}
            </Typography>
            <Typography data-id="date-of-service" sx={styles.subtitle}>
              Date of Service: {dateOfService}
            </Typography>

            {currentInvoice?.line_items && <Typography sx={styles.serviceInfo}>Covered Services</Typography>}
            {currentInvoice?.line_items &&
              currentInvoice.line_items.map((service) => {
                return (
                  <Box sx={styles.serviceInfoBlock} key={Math.random()}>
                    <Box>
                      <Typography data-id={`service-${service.charged_code?.code}`} sx={styles.serviceInfoBlock.name}>
                        {service.long_tw_desc}
                        {service?.quantity && service.quantity > 1 && ` x ${service.quantity}`}
                      </Typography>
                    </Box>
                    <Typography sx={styles.serviceInfoBlock.price}>${formatAmount(service.charge_pennies)}</Typography>
                  </Box>
                );
              })}
            {/* --------------------TODO: DISPLAY LIST OF UNCOVERED SERVICES-------------------- */}

            <Divider sx={{ margin: '12px 0' }} />

            <Box data-id="employer-bill" sx={styles.priceBlock}>
              <Typography sx={{ color: '#78849E' }}>Your Employer Pays:</Typography>
              <Typography data-id="employer-bill-total" sx={{ color: '#78849E' }}>
                ${formatAmount(employerBill?.bill_total_pennies)}
              </Typography>
            </Box>
            <Box data-id="member-bill" sx={styles.priceBlock}>
              <Typography>You Owe:</Typography>
              <Typography data-id="member-bill-total">${formatAmount(memberBill?.bill_total_pennies)}</Typography>
            </Box>

            <Divider sx={{ margin: '12px 0' }} />

            <Box data-id="date" sx={styles.finalDataBlock}>
              <Typography sx={styles.finalDataBlock.text}>Bill Paid Date:</Typography>
              <Typography data-id="payment-date" sx={styles.finalDataBlock.text}>
                {formattedDateOfPayment}
              </Typography>
            </Box>
            <Box sx={styles.finalDataBlock}>
              <Typography sx={styles.finalDataBlock.text}>Payment Method:</Typography>
              <Typography data-id="payment-method" sx={[styles.finalDataBlock.text, { textTransform: 'capitalize' }]}>
                {paymentMethodName}
              </Typography>
            </Box>
            <Box sx={styles.finalDataBlock}>
              <Typography sx={styles.finalDataBlock.text}>Amount Paid:</Typography>
              <Typography data-id="amount-paid" sx={styles.finalDataBlock.text}>
                ${formatAmount(memberBill?.bill_total_pennies)}
              </Typography>
            </Box>
          </BoxWithShadow>
          <Button
            data-id="done-btn"
            color="primary"
            variant="contained"
            sx={styles.doneBtn}
            onClick={() => {
              navigate('/');
            }}
          >
            Done
          </Button>
        </Box>
      )}
    </>
  );
}

export default PayBillSuccessPage;
