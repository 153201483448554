import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import type { MapRef, ViewStateChangeEvent } from 'react-map-gl';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CURRENT_ENVIRONMENT } from '../../../app/environments';
import { LIGHT_MAP_STYLE } from '../../../constants/map';
import {
  mapContainerSx,
  mapControlsBoxSx,
  mapPinProviderCountTextSx,
  userLocationButtonSx,
  zoomBoxSx,
  zoomIconSx,
} from './NewProviderSearch.styles';
import { LocationListElement, ProviderListElement } from './NewProviderSearch.types';
import { Condition } from '@nomi-health-inc/components-ui';
import {
  DEFAULT_MAP_ZOOM_LEVEL_ON_DESKTOP,
  DEFAULT_MAP_ZOOM_LEVEL_ON_MOBILE,
  MAP_ZOOM_LEVEL_LOWER_BOUND,
  MAP_ZOOM_LEVEL_UPPER_BOUND,
  ZIP_CODE_WITH_SURROUNDINGS_ZOOM_LEVEL,
} from './NewProviderSearch.consts';
import { actualCenterOfUSA, adjustedCenterOfUSA } from '../ProviderSearchPage.consts';
import { CoordinateWithZipCode } from './NewProviderSearchPage.types';

function NewProviderSearchMap({
  selectedSearchResultId,
  activeSearchResultId,
  chosenUserPosition,
  zipCodeBeingSearched,
  mapRef,
  searchResults,
  setActiveSearchResultId,
}: {
  selectedSearchResultId: string | null | undefined;
  activeSearchResultId: string | null;
  chosenUserPosition: CoordinateWithZipCode | null;
  zipCodeBeingSearched: string | undefined;
  mapRef: MutableRefObject<MapRef | null>;
  searchResults: LocationListElement[] | ProviderListElement[];
  setActiveSearchResultId: (id: string) => void;
}) {
  const [areAtZoomLevelToShowProviderCounts, setAreAtZoomLevelToShowProviderCounts] = useState(false);
  const areOnMobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const centerOfUSA = useMemo(() => (areOnMobileDevice ? actualCenterOfUSA : adjustedCenterOfUSA), [areOnMobileDevice]);

  const handleZoomInButtonClick = useCallback(() => {
    mapRef.current?.zoomIn();
  }, [mapRef]);

  const handleZoomOutButtonClick = useCallback(() => {
    mapRef.current?.zoomOut();
  }, [mapRef]);

  const handleLocateMeButtonClick = useCallback(() => {
    if (!chosenUserPosition) {
      return;
    }

    mapRef.current?.setCenter(
      chosenUserPosition
        ? {
            lat: chosenUserPosition.latitude,
            lng: chosenUserPosition.longitude,
          }
        : centerOfUSA
    );
    mapRef.current?.setZoom(ZIP_CODE_WITH_SURROUNDINGS_ZOOM_LEVEL);
  }, [mapRef, chosenUserPosition, centerOfUSA]);

  const showEntiretyOfUSA = useCallback(() => {
    mapRef.current?.setCenter(centerOfUSA);
    mapRef.current?.setZoom(areOnMobileDevice ? DEFAULT_MAP_ZOOM_LEVEL_ON_MOBILE : DEFAULT_MAP_ZOOM_LEVEL_ON_DESKTOP);
  }, [mapRef, centerOfUSA, areOnMobileDevice]);

  useEffect(() => {
    if (searchResults.length === 0) {
      showEntiretyOfUSA();

      return;
    }

    const distanceOfResultClosestToSearchZIP = searchResults[0].distanceInMiles || 0;
    const distanceCutoffForSearchWindow = distanceOfResultClosestToSearchZIP + 10;

    const lngArray = [] as number[];
    const latArray = [] as number[];

    for (const result of searchResults) {
      if (result.distanceInMiles && result.distanceInMiles > distanceCutoffForSearchWindow) {
        break;
      }

      lngArray.push(result.coordinates[0]);
      latArray.push(result.coordinates[1]);
    }

    if (chosenUserPosition && zipCodeBeingSearched === chosenUserPosition.zipCode) {
      lngArray.push(chosenUserPosition.longitude);
      latArray.push(chosenUserPosition.latitude);
    }

    const maxLng = Math.max(...lngArray);
    const minLng = Math.min(...lngArray);
    const maxLat = Math.max(...latArray);
    const minLat = Math.min(...latArray);

    if (maxLat && minLat && maxLng && minLng) {
      mapRef?.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 110, left: 50, bottom: 60, right: 50 },
        }
      );
    }
  }, [mapRef, zipCodeBeingSearched, searchResults, chosenUserPosition, showEntiretyOfUSA]);

  const searchResultMarkers = useMemo(() => {
    return searchResults.map((searchResult, index) => {
      const isSelected = selectedSearchResultId === searchResult.id;
      const isActive = activeSearchResultId === searchResult.id;
      const isLocationWithMultipleProviders =
        searchResult.type === 'location' && (searchResult as LocationListElement).numberOfProviders > 1;

      if (selectedSearchResultId && !isSelected) {
        return null;
      }

      return (
        <Marker
          key={`marker-${searchResult.id}-${index}`}
          latitude={searchResult?.coordinates[1]}
          longitude={searchResult?.coordinates[0]}
          anchor="bottom"
          style={{ cursor: 'pointer' }}
        >
          <Box
            component="img"
            src={`/images/map/${isSelected || isActive ? 'pin-active' : 'pin-default'}.svg`}
            onClick={() => {
              setActiveSearchResultId(searchResult.id);
            }}
          />
          <Condition when={isLocationWithMultipleProviders && areAtZoomLevelToShowProviderCounts}>
            <Typography sx={mapPinProviderCountTextSx}>{(searchResult as LocationListElement).numberOfProviders}</Typography>
          </Condition>
        </Marker>
      );
    });
  }, [searchResults, selectedSearchResultId, activeSearchResultId, setActiveSearchResultId, areAtZoomLevelToShowProviderCounts]);

  mapRef.current?.once('render', () => {
    mapRef.current?.resize(); // fix for issue of map display on initial load on mobile web in resizing iframe
  });

  const handleZoom = useCallback(
    (event: ViewStateChangeEvent) => {
      const areZoomedInClose = event.viewState.zoom >= ZIP_CODE_WITH_SURROUNDINGS_ZOOM_LEVEL;

      if (areZoomedInClose !== areAtZoomLevelToShowProviderCounts) {
        setAreAtZoomLevelToShowProviderCounts(areZoomedInClose);
      }
    },
    [areAtZoomLevelToShowProviderCounts]
  );

  return (
    <Box sx={mapContainerSx}>
      <Map
        style={{ position: 'absolute', top: '0', bottom: '0', width: '100%' }}
        ref={mapRef}
        mapboxAccessToken={CURRENT_ENVIRONMENT.MAPBOX_API_TOKEN}
        mapStyle={LIGHT_MAP_STYLE}
        onZoom={handleZoom}
        minZoom={MAP_ZOOM_LEVEL_LOWER_BOUND}
        maxZoom={MAP_ZOOM_LEVEL_UPPER_BOUND}
        initialViewState={{
          latitude: centerOfUSA.lat,
          longitude: centerOfUSA.lng,
          zoom: areOnMobileDevice ? DEFAULT_MAP_ZOOM_LEVEL_ON_MOBILE : DEFAULT_MAP_ZOOM_LEVEL_ON_DESKTOP,
        }}
        renderWorldCopies={false}
      >
        <Condition when={chosenUserPosition}>
          <Marker key="userPosition" latitude={chosenUserPosition?.latitude} longitude={chosenUserPosition?.longitude}>
            <Box component="img" src="/images/map/user-dot-nomi-cyan.svg" />
          </Marker>
        </Condition>
        {searchResultMarkers}
      </Map>
      <Box sx={mapControlsBoxSx}>
        <IconButton aria-label="map-user-location" onClick={handleLocateMeButtonClick} sx={userLocationButtonSx}>
          <Box component="img" alt="map-user-location" src="/images/map/locator.svg" />
        </IconButton>
        <Box sx={zoomBoxSx}>
          <IconButton aria-label="zoom-decrease" onClick={handleZoomOutButtonClick} sx={zoomIconSx}>
            <Box component="img" alt="zoom-decrease" src="/images/map/zoom-minus.svg" />
          </IconButton>
          <IconButton aria-label="zoom-increase" onClick={handleZoomInButtonClick} sx={zoomIconSx}>
            <Box component="img" alt="zoom-increase" src="/images/map/zoom-plus.svg" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default NewProviderSearchMap;
