import { Box, Button, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage_keys } from '../../../app/constants';
import { useAddSsnMutation, useTriggerAlloyCheckMutation } from '../../../app/graphql/_generated/hooks';
import { getCurrentUserPartyId } from '../../../app/graphql/auth.utils';
import { localStorage } from '../../../app/storageApi';
import PageTitle from '../../../components/PageTitle';
import { MaskedInput } from '../../../components/maskedInput';
import { useRestricted } from '../../../features/authV2/RestrictedContext';
import LoadingScreen from '../../Loading';
import { styles } from './styles';

interface ISsnForm {
  ssn: string;
  ssn2: string;
}

function AddSsnPage() {
  const navigate = useNavigate();
  const { checkIfUserHasAddedSsn } = useRestricted();
  const [checkingIfUserHasSsn, setCheckingIfUserHasSsn] = useState(true);

  const storedBillId = localStorage.getItem(storage_keys.bill_to_pay);
  const partyIdInLocalStorage = localStorage.getItem(storage_keys.user_party_id_key);
  const userSessionState = partyIdInLocalStorage ? localStorage.getItem(partyIdInLocalStorage) : null;

  const [ssn, setSsn] = useState('');
  const [ssnErrors, setSsnError] = useState({ message: '' });
  const [ssn2, setSsn2] = useState('');
  const [ssn2Errors, setSsn2Error] = useState({ message: '' });
  const [ssnFocused, setSsnFocused] = useState(false);
  const [confirmFocused, setConfirmFocused] = useState(false);
  const format = '999-99-9999';
  const [alloyCheck, { loading: alloyCheckLoading }] = useTriggerAlloyCheckMutation();

  const onAlloyEndNavigation = () => {
    if (storedBillId) {
      navigate(`/pay-bill/${storedBillId}`);
    } else {
      navigate('/');
    }
  };

  const [addSsnMutation, { loading: ssnLoading }] = useAddSsnMutation({
    onCompleted: () => {
      alloyCheck({
        variables: {
          party_id: getCurrentUserPartyId(),
        },
        onCompleted: () => {
          partyIdInLocalStorage &&
            userSessionState &&
            localStorage.setItem(partyIdInLocalStorage, {
              ...JSON.parse(userSessionState),
              ...{ hasSsn: true },
            });
          onAlloyEndNavigation();
        },
      }).finally(() => onAlloyEndNavigation());
    },
    onError: () => {
      navigate('/add-ssn-error');
    },
  });

  const onSubmit = (data: ISsnForm) => {
    const partyId = getCurrentUserPartyId();
    const ssn = data.ssn.replaceAll('-', '');

    addSsnMutation({
      variables: {
        party_id: partyId,
        ssn_number: ssn,
      },
    });
  };

  const doValidation = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'ssn') {
      if (ssn === '') {
        setSsnError({ message: 'SSN is required' });
      } else if (ssn.length < 11) {
        setSsnError({ message: 'Incorrect Format' });
      } else if (!Number.isInteger(Number(ssn.replaceAll('-', '')))) {
        setSsnError({ message: 'Incorrect Format' });
      } else {
        setSsnError({ message: '' });
      }

      if (ssn2 !== '' && ssn !== ssn2) {
        setSsn2Error({ message: 'SSN does not match' });
      } else {
        setSsn2Error({ message: '' });
      }
    }
    if (e.target.name === 'ssn2') {
      if (ssn2 === '') {
        setSsn2Error({ message: 'Confirm SSN is required' });
      } else if (ssn2.length < 11) {
        setSsn2Error({ message: 'Incorrect Format' });
      } else if (!Number.isInteger(Number(ssn2.replaceAll('-', '')))) {
        setSsn2Error({ message: 'Incorrect Format' });
      } else if (ssn !== ssn2) {
        setSsn2Error({ message: 'SSN does not match' });
      } else {
        setSsn2Error({ message: '' });
      }
    }
  };

  useEffect(() => {
    checkIfUserHasAddedSsn().then((hasUserSsn: boolean) => {
      if (hasUserSsn) {
        if (storedBillId) {
          navigate(`/pay-bill/${storedBillId}`);
        } else {
          navigate('/');
        }
      }

      setCheckingIfUserHasSsn(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle title="Add Social Security Number" hideBack />
      {(ssnLoading || alloyCheckLoading || checkingIfUserHasSsn) && <LoadingScreen />}
      <Box sx={styles.contentBlock}>
        <Typography sx={styles.noteText}>
          In order to verify your identity, please provide your social security number. You'll only have to do this once.
        </Typography>

        <Box sx={styles.formWrap}>
          <MaskedInput
            name="ssn"
            value={ssn}
            onFocus={() => setSsnFocused(true)}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              doValidation(event);
              setSsnFocused(false);
            }}
            onChange={setSsn}
            helperText={ssnErrors.message}
            error={!!ssnErrors.message}
            mask={ssnFocused ? '' : '*'}
            format={format}
            label="SSN"
            placeholder="Enter SSN"
          />
          <MaskedInput
            name="ssn2"
            value={ssn2}
            onFocus={() => setConfirmFocused(true)}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              setConfirmFocused(false);
              doValidation(event);
            }}
            onChange={setSsn2}
            helperText={ssn2Errors.message}
            error={!!ssn2Errors.message}
            mask={confirmFocused ? '' : '*'}
            format={format}
            label="Confirm SSN"
            placeholder="Confirm SSN"
          />
        </Box>

        <Button
          data-id="add-ssn-btn"
          type="submit"
          color="primary"
          variant="contained"
          sx={styles.continueBtn}
          disabled={ssn && ssn2 ? ssnErrors.message !== '' || ssn2Errors.message !== '' : true}
          onClick={() => {
            onSubmit({ ssn, ssn2 });
          }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}

export default AddSsnPage;
