import { memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

import { styles, stylesForm } from '../styles';
import { IFieldProps } from '../types';

function CardNumberField({
  stripe,
  elements,
  setCardError,
  updateValidation,
  setAsyncError,
}: RequireAtLeastOne<IFieldProps, 'setCardError'>) {
  stripe?.elements({
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Lato:300',
      },
    ],
  });

  useEffect(() => {
    const cardNumberElement = elements?.create('cardNumber', {
      style: stylesForm,
      placeholder: 'Enter Card Number',
    });

    cardNumberElement?.mount('#card-number-element');

    cardNumberElement?.on('change', function (event: StripeCardNumberElementChangeEvent) {
      updateValidation(event?.complete);
      setCardError(event?.error?.message || '');
      setAsyncError(null);
    });
  });

  return (
    <Box data-id="card-number" sx={styles.card.wrap}>
      <label htmlFor="card-number-element" style={styles.card.label}>
        Card Number
      </label>
      <Box style={styles.card.inputWrap}>
        <div id="card-number-element" />
      </Box>
    </Box>
  );
}
export default memo(CardNumberField);
