import { SxProps, Theme } from '@mui/material';

export const contentContainerSx = {
  height: '100%',
  paddingTop: '1.25rem',
} as SxProps<Theme>;

export const scrollableContentContainerSx = {
  overflowY: 'auto',
  maxHeight: '100%',
} as SxProps<Theme>;

export const detailsContainerSx = {
  margin: '0 1.5rem',
} as SxProps<Theme>;

export const detailValueSx = {
  color: (theme) => theme.palette.custom.primaryGrey,
  fontSize: '.875rem',
  lineHeight: '1rem',
  fontWeight: '400',
} as SxProps<Theme>;

export const firstAddressLineSx = {
  ...detailValueSx,
  height: '12px',
} as SxProps<Theme>;

export const secondAddressLineSx = {
  ...detailValueSx,
  marginTop: '.25rem',
  marginLeft: '1.875rem',
} as SxProps<Theme>;

export const backButtonContainerSx = {
  paddingBottom: '.5rem',
  width: '100%',
  backgroundColor: 'white',
} as SxProps<Theme>;

export const backButtonSx = {
  marginLeft: '1rem',
  padding: '0 .5rem 0 0',
  height: '2rem',
  lineHeight: '20px',
} as SxProps<Theme>;

export const hospitalAddressContainerSx = {
  margin: '.75rem 0 1rem 0',
} as SxProps<Theme>;

export const hospitalIconSx = {
  marginRight: '.75rem',
  width: '18px',
  height: '15px',
} as SxProps<Theme>;

export const contactACareGuideTitleSx = {
  color: (theme) => theme.palette.custom.darkBlue,
  fontWeight: (theme) => theme.typography.fontWeightBold,
} as SxProps<Theme>;

export const careGuideContactInfoBackgroundSx = {
  marginTop: '1.875rem',
  padding: '1.25rem 0',
  backgroundColor: (theme) => theme.palette.custom.veryDarkBlue,
} as SxProps<Theme>;

export const contactACareGuideBlurbSx = {
  fontSize: '.875rem',
  lineHeight: '1.375rem',
  color: (theme) => theme.palette.custom.primaryGrey,
  fontWeight: (theme) => theme.typography.fontWeightRegular,
} as SxProps<Theme>;

export const careGuideContactInfoCallToActionTitleSx = {
  fontSize: '.875rem',
  lineHeight: '100%',
  color: '#FFFFFF',
  fontWeight: (theme) => theme.typography.fontWeightBold,
} as SxProps<Theme>;

export const careGuideContactInfoDetailsSx = {
  height: '.875rem',
  fontSize: '.875rem',
  color: '#FFFFFF',
  textDecorationColor: (theme) => theme.palette.custom.grey,
  fontWeight: (theme) => theme.typography.fontWeightRegular,
} as SxProps<Theme>;

export const mobilePhoneIconSx = {
  width: '10px',
  height: '15px',
  marginRight: '.25rem',
} as SxProps<Theme>;

export const envelopeIconSx = {
  width: '14px',
  height: '11px',
  marginRight: '.25rem',
} as SxProps<Theme>;

export const referralCallToActionTitleSx = {
  marginTop: '1.875rem',
  fontSize: '.875rem',
  lineHeight: '1rem',
  color: (theme) => theme.palette.custom.primaryGrey,
  fontWeight: (theme) => theme.typography.fontWeightRegular,
} as SxProps<Theme>;

export const submitAReferralButtonSx = {
  height: '1.5rem',
  marginTop: '.25rem',
  marginBottom: '1.25rem',
  padding: '0 10px',
  fontSize: '1rem',
  lineHeight: '20px',
  fontWeight: '600',
} as SxProps<Theme>;

export const seeMoreOrLessProvidersButtonSx = {
  color: (theme) => theme.palette.custom.link,
  fontSize: '.875rem',
  fontWeight: '400',
  cursor: 'pointer',
} as SxProps<Theme>;
