import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import LoadingScreen from './Loading';
import PageTitle from '../components/PageTitle';
import BoxWithShadow from '../components/boxes/BoxWithShadow';
import ShortBillInfo from '../components/partials/ShortBillInfo';
import { BillState, BillType, IBillFragment, IInvoiceFragment, SortDirection, useGetInvoicesQuery } from '../app/graphql/_generated/hooks';
import { IBillWithInvoiceData } from './types';
import { getCurrentUserPartyId } from '../app/graphql/auth.utils';
import { Error } from '../components/Error';

function PaymentHistoryPage() {
  const navigate = useNavigate();
  const partyId = getCurrentUserPartyId();

  const { loading, data, error } = useGetInvoicesQuery({
    variables: {
      filter: {
        bill_state: [BillState.BillPaid],
        bill_type: [BillType.Member],
        party_id: partyId,
      },
      options: { limit: 50, offset: 0, sort: [{ field: 'audit.created_at', direction: SortDirection.Desc }] },
      bill_type: [BillType.Member],
      bill_state: [BillState.BillPaid],
    },
    fetchPolicy: 'network-only',
  });

  const bills = useMemo(() => {
    return data?.invoices?.results.reduce((acc: IBillWithInvoiceData[], invoice: IInvoiceFragment) => {
      const billsWithInvoiceId =
        invoice?.bills?.map((bill: IBillFragment | null) => ({ ...bill, invoice_id: invoice._id, encounter: invoice.encounter })) || [];

      return [...acc, ...billsWithInvoiceId];
    }, []);
  }, [data]);

  const onPressOnShortBill = (bill?: IBillWithInvoiceData | null) => () => {
    if (!bill) {
      return;
    }
    navigate(`/bills/${bill.invoice_id}`);
  };

  let content = <LoadingScreen />;

  if (error) {
    content = <Error text="Something Went Wrong" description="Having trouble viewing history?" />;
  } else if (!loading) {
    content = (
      <Box sx={styles.box}>
        {bills?.length ? (
          bills?.map((bill) => {
            return (
              <BoxWithShadow key={bill?.invoice_id}>
                <ShortBillInfo bill={bill} encounter={bill.encounter} onPress={onPressOnShortBill(bill)} notPaid={true} />
              </BoxWithShadow>
            );
          })
        ) : (
          <BoxWithShadow>
            <Typography sx={styles.empty}>There are currently no paid bills</Typography>
          </BoxWithShadow>
        )}
      </Box>
    );
  }

  return (
    <Fragment>
      <PageTitle backUrl="/" title="Payment History" />
      {content}
    </Fragment>
  );
}

export default PaymentHistoryPage;
