import { ApolloClient, createHttpLink, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { storage_keys } from '../constants';
import { CURRENT_ENVIRONMENT } from '../environments';
import { authStorage, clearAuthStorage } from '../storageApi';
import { getDataFromToken } from './auth.utils';
import { refreshAndStoreTokens } from './refreshToken';

const httpLink = createHttpLink({ uri: `${CURRENT_ENVIRONMENT.GQL_BASE_URL}graph/query` });

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 1,
    retryIf: (error) => !!error,
  },
});

const authLink = setContext(async (_, { headers }) => {
  let token = authStorage.getItem(storage_keys.id_token) || '';

  if (token) {
    const decodedToken = getDataFromToken(token);
    const currentTime = Math.floor(new Date().getTime() / 1000);

    if (decodedToken.exp < currentTime) {
      const freshToken = await refreshAndStoreTokens();

      token = freshToken.id_token ? freshToken.id_token : token;
    }
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors || networkError) {
    const errors = [...(graphQLErrors || []), networkError];

    for (const err of errors) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = err as any;
      const errorCode = error?.extensions?.code;

      if (errorCode === 'UNAUTHENTICATED' || error?.statusCode === 403 || error?.statusCode === 401) {
        return new Observable((observer) => {
          refreshAndStoreTokens()
            .then(() => {
              const updatedIdToken = authStorage.getItem(storage_keys.id_token);
              const newHeaders = {
                ...operation.getContext().headers,
                authorization: `Bearer ${updatedIdToken}`,
              };

              operation.setContext({ headers: newHeaders });

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              clearAuthStorage();
              window.location.replace('/sign-in');
              // eslint-disable-next-line no-console
              console.log('[Error refreshing tokens]:', error);
            });
        });
      }
    }
  }
});

const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(retryLink).concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
});

export default apolloClient;
