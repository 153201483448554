import { Box, Typography } from '@mui/material';
import { containerSx, headerTextSx, nameTextSx } from './ProviderNamesList.styles';

interface ProviderNamesListProps {
  names: string[];
}

export function ProviderNamesList({ names }: ProviderNamesListProps) {
  return (
    <Box sx={containerSx}>
      <Typography sx={headerTextSx}>Providers</Typography>
      {names.map((name) => (
        <Typography sx={nameTextSx} key={`provider-name-${name}`}>
          {name}
        </Typography>
      ))}
    </Box>
  );
}
