import { MemberRelationship } from '../../../../../app/graphql/_generated/hooks';

export const formatMemberRelationship = (relationship?: MemberRelationship): string => {
  if (!relationship) {
    return '';
  }

  const words = relationship.split('_');
  const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  return formattedWords.join(' ');
};
