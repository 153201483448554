import { ButtonProps, Button } from '@mui/material';
import { primaryActionButtonSx } from './NMEActionButton.styles';

function NMEPrimaryActionButton({ children, ...props }: ButtonProps) {
  return (
    <Button color="primary" variant="contained" sx={primaryActionButtonSx} {...props}>
      {children}
    </Button>
  );
}

export default NMEPrimaryActionButton;
