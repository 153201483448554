import { Typography } from '@mui/material';
import { ReactNode } from 'react';

function NMEPageTitle({ children }: { children: ReactNode }) {
  return (
    <Typography
      sx={[
        ({ palette, spacing }) => ({
          fontSize: '2.25rem',
          lineHeight: '45px',
          fontWeight: '700',
          color: palette.custom.white,
          marginBottom: spacing(1),
        }),
      ]}
      component="h1"
    >
      {children}
    </Typography>
  );
}

export default NMEPageTitle;
