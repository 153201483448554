import { Box } from '@mui/material';
import PageTitle from '../components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { dateMMddyyyy } from '../utils/format-date';
import { BillDetailsSection } from '../components/BillDetailsSection';
import LoadingScreen from './Loading';
import { Error } from '../components/Error';
import { useBillByIdQuery } from '../app/graphql/_generated/hooks';

function BillDetails() {
  const params = useParams();
  const navigate = useNavigate();

  const invoiceId = params.id || '';

  const { loading, data, error } = useBillByIdQuery({
    variables: { invoice_id: invoiceId },
    onCompleted: (data) => {
      const currentBill = data?.invoiceById?.bills?.find((bill) => bill?.bill_type === 'MEMBER');
      const billAudit = currentBill?.audit_logs?.find(
        (log) => log?.remittance_state_transition?.to_state === 'BILL_PAID' || log?.remittance_state_transition?.to_state === 'BILL_PENDING'
      );
      const dateOfServiceDate = currentBill ? dateMMddyyyy(data?.invoiceById?.encounter?.service?.service_time) : null;
      const dateOfPaymentDate = currentBill?.cash_payment ? dateOfServiceDate : billAudit?.transitioned_at_time;

      if ((!currentBill?.payment_method && !currentBill?.cash_payment) || !dateOfPaymentDate) {
        navigate(`/pay-bill/${invoiceId}`);
      }
    },
  });

  if (loading) {
    return (
      <>
        <PageTitle title="Bill Details" />
        <LoadingScreen />
      </>
    );
  }

  return (
    <>
      <PageTitle title="Bill Details" />
      {error ? (
        <Error text="Something Went Wrong" description="Having trouble viewing you bills?" />
      ) : (
        <Box sx={{ padding: '0 16px' }}>
          <BillDetailsSection currentInvoice={data} />
        </Box>
      )}
    </>
  );
}

export default BillDetails;
