import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useGetUserNameByPartyIdLazyQuery } from '../../app/graphql/_generated/hooks';
import AccountMenu from './AccountMenu';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';

function UserMenu() {
  const currentUserPartyId = getCurrentUserPartyId();
  const [getUserNameByPartyId, { data }] = useGetUserNameByPartyIdLazyQuery();

  useEffect(() => {
    getUserNameByPartyId({ variables: { id: currentUserPartyId } });
  }, [getUserNameByPartyId, currentUserPartyId]);

  const names = data?.party?.person?.current_name?.names;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        data-id="user-name"
        sx={{
          fontFamily: 'Cera Pro',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '20px',
          lineHeight: '25px',
          color: '#343D4F',
        }}
      >
        {names && names.length ? names.join(' ') : null}
      </Typography>
      <AccountMenu />
    </Box>
  );
}

export default UserMenu;
