import { Theme } from '@mui/material';

export const styles = {
  idCardContainer: {
    borderStyle: 'solid',
    borderRadius: '20px',
    borderWidth: '1px',
    color: 'white',
    width: '345px',
    padding: '20px 25px',
    marginBottom: '1rem',
    backgroundImage: 'url("/images/id-card-pattern.svg")',
    backgroundPosition: 'top 50px right 0px',
    backgroundRepeat: 'no-repeat',
  },
  IdCardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  titleText: {
    fontSize: '11px',
    fontWeight: '500',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    letterSpacing: '1.265px',
  },
  dataText: {
    fontSize: '14px',
    fontWeight: '300',
    paddingRight: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkDataText: {
    color: (theme: Theme) => theme.palette.custom.white,
    textDecoration: 'none',
  },
  icon: {
    width: '18px',
  },
  twoColumn: {
    width: '50%',
  },
};
