/* eslint-disable react/no-multi-comp */
import { Box, MenuItem, Select, TextField, SelectChangeEvent, Typography, IconButton, Button } from '@mui/material';
import { styles } from './SearchPracticeFacilityForm.styles';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SpecialtyToTaxonomies, useGetNetworkSpecialtiesLazyQuery } from '../../app/graphql/_generated/hooks';
import { NETWORK_ID } from '../../app/constants';
import { getZipCodeFromLatLong } from '../../app/third-party-api/mapbox';
import { zipCodeInMiami } from '../ProviderSearch/ProviderSearchPage.consts';
import { isZipCodeInvalid } from '../ProviderSearch/ProviderSearchPage.utils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { ModalSpinner } from '@nomi-health-inc/components-ui';

function DownChevronIcon(muiProps: object) {
  return <Box {...muiProps} component="img" alt="down-chevron" src="/images/chevron-down.svg" sx={styles.dropdownIcon} />;
}
function SearchPracticeFacilityForm() {
  const navigate = useNavigate();
  const [zipCode, setZipCode] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [allSpecialtyToTaxonomies, setAllSpecialtyToTaxonomies] = useState<SpecialtyToTaxonomies[]>([]);
  const [selectedSpecialtyToTaxonomies, setSelectedSpecialtyToTaxonomies] = useState<SpecialtyToTaxonomies | null>(null);

  const [runSpecialitiesQuery] = useGetNetworkSpecialtiesLazyQuery({
    variables: { id: NETWORK_ID },
  });

  const getSpecialtyToTaxonomiesUsingPrivateGraphQLAPI = useCallback(async () => {
    const result = await runSpecialitiesQuery();

    if (result && result.data?.getNetworkSpecialties) {
      setAllSpecialtyToTaxonomies(result.data.getNetworkSpecialties);
    } else {
      setAllSpecialtyToTaxonomies([]);
    }
  }, [runSpecialitiesQuery]);

  const handleSpecialtySelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newSpecialtyToTaxonomies = allSpecialtyToTaxonomies.find((entry) => entry.specialty === event.target.value);

      if (!newSpecialtyToTaxonomies || !newSpecialtyToTaxonomies.taxonomies) {
        return;
      }

      setSelectedSpecialtyToTaxonomies(newSpecialtyToTaxonomies);
    },
    [setSelectedSpecialtyToTaxonomies, allSpecialtyToTaxonomies]
  );

  const handleZipCodeEdit = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setZipCode(event.target.value);
    },
    [setZipCode]
  );

  const defineUsersZip = useCallback((userLocationCoordinates: { coords: { latitude: number; longitude: number } }) => {
    const userLocationCoords = userLocationCoordinates.coords;

    getZipCodeFromLatLong(userLocationCoords.latitude, userLocationCoords.longitude).then((userLocationZipCode: string | null) => {
      setShowLoader(false);
      if (userLocationZipCode) {
        setZipCode(userLocationZipCode);
      } else {
        setZipCode(zipCodeInMiami);
      }
    });
  }, []);

  const detectZipCode = () => {
    setShowLoader(true);
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        const onLocationFetchFailure = () => {
          setShowLoader(false);
          setZipCode(zipCodeInMiami);
        };

        if (result.state === 'denied') {
          onLocationFetchFailure();
        }

        navigator.geolocation.getCurrentPosition(
          (userLocationCoordinates) => defineUsersZip(userLocationCoordinates),
          onLocationFetchFailure
        );

        result.onchange = function () {
          if (result.state === 'denied') {
            onLocationFetchFailure();
          } else if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition((userLocationCoordinates) => defineUsersZip(userLocationCoordinates));
          }
        };
      })
      .catch(() => {
        setShowLoader(false);
        setZipCode(zipCodeInMiami);
      });
  };

  const startSearch = () => {
    navigate(ROUTES.providerSearch, { state: { zip: zipCode, specialty: selectedSpecialtyToTaxonomies } });
  };

  useEffect(() => {
    getSpecialtyToTaxonomiesUsingPrivateGraphQLAPI();
  }, [getSpecialtyToTaxonomiesUsingPrivateGraphQLAPI]);

  return (
    <Box sx={styles.searchFieldsContainer}>
      <Box sx={styles.fieldsBlock}>
        <Box sx={styles.zipFieldWrap}>
          <Typography sx={styles.searchFieldHeader}>ZIP Code</Typography>
          <TextField
            size="small"
            variant="filled"
            value={zipCode}
            onChange={handleZipCodeEdit}
            inputProps={{ 'data-id': 'zip-code-input' }}
          />
          <IconButton aria-label="location" onClick={detectZipCode} sx={styles.locationBtn}>
            <Box component="img" alt="Back" src="/images/location-icon.svg" />
          </IconButton>
        </Box>
        <Box sx={styles.specialtyFieldWrap}>
          <Typography sx={styles.searchFieldHeader}>Specialty</Typography>
          <Select
            variant="filled"
            IconComponent={DownChevronIcon}
            displayEmpty
            defaultValue=""
            renderValue={(value: string) => {
              return value === '' ? 'Select' : value;
            }}
            value={selectedSpecialtyToTaxonomies?.specialty || ''}
            sx={styles.specialtyDropdown}
            onChange={handleSpecialtySelect}
            inputProps={{ 'data-id': 'specialty-dropdown' }}
          >
            {allSpecialtyToTaxonomies.map((specialty) => {
              return specialty.specialty ? (
                <MenuItem key={specialty.specialty} value={specialty.specialty} sx={styles.dropdownLabel}>
                  {specialty.specialty}
                </MenuItem>
              ) : null;
            })}
          </Select>
        </Box>
      </Box>
      <Button
        color="primary"
        variant="contained"
        data-id="search-provider-button"
        disabled={!selectedSpecialtyToTaxonomies || isZipCodeInvalid(zipCode)}
        sx={styles.searchBtn}
        onClick={startSearch}
      >
        Start Search
      </Button>
      <ModalSpinner loading={showLoader} text="Processing" />
    </Box>
  );
}

export default SearchPracticeFacilityForm;
