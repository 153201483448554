import { CssBaseline } from '@mui/material';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './app/history';
import { HorizontalBg } from './components/HorizontalBg';
import AuthGuardRoute from './features/authV2/AuthGuardRoute';
import Callback from './features/authV2/Callback';
import OldMemberExperienceLoginPage from './pages/auth/OldMemberExperienceLogin/OldMemberExperienceLoginPage';
import TermsPage from './pages/auth/OldMemberExperienceLogin/TermsPage';
import BillDetailsPage from './pages/BillDetails';
import BillsPage from './pages/BillsPage';
import ContactUsPage from './pages/ContactUsPage';
import FAQPage from './pages/faq/FAQPage';
import TellusHomePage from './pages/TellusHomePage';
import PayBillPage from './pages/pay-bill/PayBillPage';
import PayBillSuccessPage from './pages/pay-bill/PayBillSuccessPage';
import AddBankPage from './pages/payment-method/add/AddBankPage';
import AddCardPage from './pages/payment-method/add/AddCardPage';
import AddSsnPage from './pages/payment-method/add/AddSsnPage';
import AddSsnError from './pages/payment-method/add/AddSsnError';
import AddPaymentMethodPage from './pages/payment-method/AddPaymentMethodPage';
import PaymentHistoryPage from './pages/PaymentHistoryPage';
import AllPaymentsPage from './pages/AllPaymentsPage';
import SelectPaymentPage from './pages/SelectPaymentMethod';
import ThankYouScreen from './pages/ThankYouScreen';
import AddBankAccountError from './pages/payment-method/add/AddBankAccountError';
import AddBankAccountVerification from './pages/payment-method/add/AddBankAccountVerification';
import RestrictedGuardRoute from './features/authV2/RestrictedGuardRoute';
import ErrorPage from './pages/error/ErrorPage';
import WithFooterWrapper from './components/WithFooterWrapper';
import PublicProviderSearchPage from './pages/PublicProviderSearchPage';
import PublicPracticeFacilityDetailsPage from './pages/PublicPracticeFacilityDetailsPage';
import { ROUTES } from './constants/routes';
import { useFaviconAndTitle } from './app/hooks/useFaviconAndTitle';
import { DataDogAnalyticsRoute } from './features/analytics/DataDogAnalyticsRoute';

function OldMemberExperienceApp() {
  const initialPathLoaded = window.location.pathname.toLowerCase();
  let faviconPath = '/favicon-tellus.ico';
  let tabTitle = 'Tellus Wellness';

  if (initialPathLoaded === ROUTES.publicProviderSearch || initialPathLoaded.startsWith(ROUTES.publicPracticeFacility)) {
    faviconPath = '/favicon.png';
    tabTitle = 'Nomi Health';
  }

  useFaviconAndTitle(faviconPath, tabTitle);

  return (
    <HistoryRouter history={history as never}>
      <CssBaseline />
      <Routes>
        <Route element={<DataDogAnalyticsRoute />}>
          <Route element={<AuthGuardRoute />}>
            <Route element={<RestrictedGuardRoute />}>
              <Route path="/" element={<TellusHomePage />} />
              <Route path="/pay-bill/:id" element={<PayBillPage />} />
              <Route path="/pay-bill/:id/success" element={<PayBillSuccessPage />} />
              <Route path="/bills" element={<BillsPage />} />
              <Route path="/bills/:id" element={<BillDetailsPage />} />
              <Route path="/payment-history" element={<PaymentHistoryPage />} />
              <Route path="/payment-method/change" element={<SelectPaymentPage />} />
              <Route path="/payment-method/all" element={<AllPaymentsPage />} />
              <Route path="/payment-method/add" element={<AddPaymentMethodPage />} />
              <Route path="/payment-method/add/card" element={<AddCardPage />} />
              <Route path="/payment-method/add/bank-account" element={<AddBankPage />} />
              <Route path="/payment-method/add/bank-account/error" element={<AddBankAccountError />} />
              <Route path="/payment-method/add/bank-account/verification" element={<AddBankAccountVerification />} />
              <Route path="/thank-you" element={<ThankYouScreen />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
            </Route>
            <Route path="/add-ssn" element={<AddSsnPage />} />
            <Route path="/add-ssn-error" element={<AddSsnError />} />
            <Route
              path="/auth/terms"
              element={
                <HorizontalBg blockLogo={true}>
                  <TermsPage />
                </HorizontalBg>
              }
            />
          </Route>
          <Route
            path="/sign-in"
            element={
              <HorizontalBg>
                <WithFooterWrapper>
                  <OldMemberExperienceLoginPage />
                </WithFooterWrapper>
              </HorizontalBg>
            }
          />
          <Route path="/callback" element={<Callback />} />
          <Route
            path="/error"
            element={
              <WithFooterWrapper>
                <ErrorPage />
              </WithFooterWrapper>
            }
          />
          <Route path="/public-provider-search" element={<PublicProviderSearchPage />} />
          <Route path="/public-practice-facility/:id" element={<PublicPracticeFacilityDetailsPage />} />
        </Route>
      </Routes>
    </HistoryRouter>
  );
}

export default OldMemberExperienceApp;
