/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Input, ModalSpinner } from '@nomi-health-inc/components-ui';
import { Controller, useForm } from 'react-hook-form';
import { CURRENT_ENVIRONMENT, CURRENT_ENV_NAME, EnvironmentEnum } from '../../../app/environments';
import { localStorage } from '../../../app/storageApi';
import GuttersContainer from '../../../components/GuttersContainer';
import { PageWrapper } from '../../../components/PageWrapper';
import { useAuth } from '../../../features/authV2/AuthContext';
import usePublicSearch from '../../../features/authV2/usePublicSearch';
import { styles } from './styles';
import { emailValidityError, LoginSchema } from './LoginFormSchema';

interface ILoginForm {
  email: string;
  formValid: boolean;
}

const IGNORE_SEARCH = 'IGNORE_SEARCH';
const TELLUS_LOGIN_FLAG = 'DISABLE_TELLUS';

function OldMemberExperienceLoginPage() {
  const { fetchUserName, status } = usePublicSearch();
  const { loginWithPingRedirect } = useAuth();

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({
    mode: 'onChange',
    resolver: yupResolver(LoginSchema),
  });
  const isProd = EnvironmentEnum.Production === CURRENT_ENV_NAME;
  const isTellusDisabled = Boolean(localStorage.getItem(TELLUS_LOGIN_FLAG)) === true;

  const onSubmit = async (data: ILoginForm) => {
    const { username } = await fetchUserName(data.email);
    const ignoreSearch = localStorage.getItem(IGNORE_SEARCH);

    // This check has to be removed when netscope issue will be resolved
    if (isProd || ignoreSearch !== 'ignore') {
      if (username) {
        return loginWithPingRedirect(username || '');
      }
      setError('email', { type: 'email', message: emailValidityError });
    } else {
      loginWithPingRedirect(username || '');
    }
  };

  return (
    <>
      {/* This temporary workaround to support 2 flows of login*/}
      {!isTellusDisabled ? (
        <GuttersContainer container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.title} component="h1">
              Sign in
            </Typography>
            <PageWrapper>
              <Box sx={styles.contentBlock}>
                <Box sx={styles.input}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={styles.continueBtn}
                    onClick={() => loginWithPingRedirect('', CURRENT_ENVIRONMENT.PING.TELLUS_CLIENT_ID)}
                  >
                    Login with Tellus Wellness
                  </Button>
                </Box>
              </Box>
              <ModalSpinner loading={status === 'loading'} text="Processing" />
            </PageWrapper>
          </Grid>
        </GuttersContainer>
      ) : (
        <GuttersContainer container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.title} component="h1">
              Create account
            </Typography>
            <PageWrapper>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={styles.contentBlock}>
                  <Typography sx={styles.contentBlock.text}>To access your account, please enter your email address below.</Typography>
                  <Box sx={styles.input}>
                    <Controller
                      control={control}
                      {...register('email')}
                      render={({ field }) => (
                        <Input
                          data-id="email"
                          {...field}
                          sx={styles.input}
                          variant="filled"
                          label="Email Address"
                          placeholder="Enter Email Address"
                          inputProps={{}}
                          helperText={errors.email?.message}
                          error={!!errors.email}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Button data-id="continue" type="submit" color="primary" variant="contained" sx={styles.continueBtn} disabled={!isValid}>
                  Continue
                </Button>
              </form>
              <ModalSpinner loading={status === 'loading'} text="Processing" />
            </PageWrapper>
          </Grid>
        </GuttersContainer>
      )}
    </>
  );
}

export default OldMemberExperienceLoginPage;
