import { PING_URL, storage_keys } from '../constants';
import { CURRENT_ENVIRONMENT } from '../environments';
import { authStorage, clearAuthStorage } from '../storageApi';
import { getPartyIdFromToken } from './auth.utils';

export const refreshTokens = async () => {
  const refreshToken = authStorage.getItem(storage_keys.refresh_token) || '';
  const clientId = authStorage.getItem(storage_keys.client_id) || CURRENT_ENVIRONMENT.PING.CLIENT_ID;

  const requestBody = new URLSearchParams();

  requestBody.append('grant_type', 'refresh_token');
  requestBody.append('refresh_token', refreshToken);
  requestBody.append('client_id', clientId);

  try {
    const response = await fetch(PING_URL.token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestBody.toString(),
    });

    if (!response.ok) {
      clearAuthStorage();
      window.location.replace('/sign-in');
      throw new Error('Failed to refresh tokens');
    }

    const newAuthData = await response.json();

    return newAuthData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error refreshing tokens:', error);
    throw error;
  }
};

export const refreshAndStoreTokens = () => {
  return refreshTokens().then((data) => {
    if (data) {
      const tokenExpiryTime = Math.floor(Date.now() / 1000) + data.expires_in;
      // Save new tokens to auth storage and retry the operation
      const authDataToStore = {
        [storage_keys.access_token]: data.access_token,
        [storage_keys.id_token]: data.id_token,
        [storage_keys.refresh_token]: data.refresh_token,
        [storage_keys.expires_in]: tokenExpiryTime,
      };

      const partyId = getPartyIdFromToken(data.id_token);

      if (partyId) {
        authDataToStore[storage_keys.user_party_id_key] = partyId;
      }

      authStorage.setItems(authDataToStore);
    }
    return data;
  });
};
