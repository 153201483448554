import { Theme } from '@mui/material';

export const styles = {
  title: {
    fontSize: '16px',
    fontWeight: '500',
  },
  subtitle: { fontSize: '12px' },
  serviceInfo: {
    fontSize: '24px',
    fontWeight: '500',
    marginTop: '15px',
    marginBottom: '-10px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    alignItems: 'start',
    ' p': {
      fontSize: '14px',
      minWidth: '35%',
    },
  },
  serviceInfoBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginTop: '20px',
    ' p': {
      fontSize: '16px',
      minWidth: '35%',
    },
    name: {
      textTransform: 'none',
    },
    price: {
      textAlign: 'right',
      fontWeight: '500',
    },
    quantity: {
      fontSize: '12px',
      display: 'block',
    },
  },
  priceBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    ' p': {
      fontSize: '16px',
      fontWeight: '500',
    },
  },
  payBtn: {
    width: '100%',
    marginTop: '25px',
  },
  doneBtn: {
    width: '100%',
    marginTop: '40px',
  },
  textBtn: {
    width: '100%',
    marginTop: '10px',
    textTransform: 'none !important',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '70px',
  },
  finalDataBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    text: {
      fontSize: '16px',
      fontWeight: '400',
    },
  },
  errorText: {
    color: '#D63541',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 400,
    marginTop: '30px',
    marginBottom: '10px',
  },
  conclusionBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: { margin: '12px 0' },
  billError: {
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '0 15%',
    color: (theme: Theme) => theme.palette.custom.grey,
    marginTop: '50px',
  },
};
