import { ModalSpinner } from '@nomi-health-inc/components-ui';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { storage_keys } from '../../../../app/constants';
import { getPartyIdFromToken } from '../../../../app/graphql/auth.utils';
import { PolicyType, useAcceptPolicyMutation, useGetTermsAcceptanceStatusForUserLazyQuery } from '../../../../app/graphql/_generated/hooks';
import { sendOTP } from '../../../../app/rest-api/new-member-experience-login';
import { authStorage, clearAuthStorage, localStorage } from '../../../../app/storageApi';
import { sendOTPToDeviceType } from '../NMELoginPage/NMELoginPage.utils';
import NMEOTPForm from './NMEOTPForm';
import { IOTPFormData } from './NMEOTPForm/NMEOTPForm.types';
import { areTermsAccepted } from './NMEOTPPage.utils';
import { OTPDeviceType } from '../../../../app/rest-api/new-member-experience-login/types';
import { ROUTES } from '../../../../constants/routes';
import { OnBoardingPageWrapper } from '../styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { NMEOTPFormSchema } from './NMEOTPForm/NMEOTPFormSchema';

interface OTPPageNavigationState {
  pingFlowID: string;
  isMultipleOtp: boolean;
}

function NMEOTPPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pingFlowID, isMultipleOtp } = location.state as OTPPageNavigationState;

  const [loading, setIsLoading] = useState<boolean>(false);
  const defaultDeviceState =
    (localStorage.getItem(storage_keys.otp_device_type_preference) as OTPDeviceType) || (OTPDeviceType.SMS as OTPDeviceType);
  const [preferredOTPDeviceType, setPreferredOTPDeviceType] = useState<OTPDeviceType>(defaultDeviceState);
  const [runAcceptPolicyMutation, { error: policyAcceptMutationError }] = useAcceptPolicyMutation();
  const [runQueryForPolicyAcceptance, { error: policyAcceptanceQueryError }] = useGetTermsAcceptanceStatusForUserLazyQuery();

  const formMethods = useForm<IOTPFormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(NMEOTPFormSchema),
    values: {
      otp: '',
    },
  });

  const onOTPSubmit = async (data: IOTPFormData) => {
    setIsLoading(true);

    const otpSendResponse = await sendOTP(pingFlowID, data.otp).catch((reason) => {
      formMethods.setError('otp', {
        message: reason.message,
      });
    });

    if (!otpSendResponse) {
      // eslint-disable-next-line no-console
      console.log('Aborting login due to OTP send failure.');
      setIsLoading(false);
      return;
    }

    authStorage.setItem(storage_keys.access_token, otpSendResponse.access_token);
    authStorage.setItem(storage_keys.id_token, otpSendResponse.id_token);
    const tokenExpiryTime = Math.floor(Date.now() / 1000) + otpSendResponse.expires_in;

    authStorage.setItem(storage_keys.expires_in, tokenExpiryTime.toString());

    const partyId = getPartyIdFromToken(otpSendResponse.id_token);

    const userHasAcceptedTermsOnOTPSetupPage = localStorage.getItem(storage_keys.user_has_agreed_to_accept_terms) !== null;

    if (userHasAcceptedTermsOnOTPSetupPage) {
      const response = await runAcceptPolicyMutation({
        variables: {
          party_id: partyId,
          policy_type: PolicyType.PTermsAndConditions,
        },
      });

      if (policyAcceptMutationError || !areTermsAccepted(response.data?.acceptPolicy?.person)) {
        clearAuthStorage();
        // eslint-disable-next-line no-console
        console.log('Aborting login due to failure to accept terms and conditions.', policyAcceptMutationError);
        setIsLoading(false);
        return;
      }

      localStorage.removeItem(storage_keys.user_has_agreed_to_accept_terms);
    } else {
      const response = await runQueryForPolicyAcceptance({ variables: { id: partyId } });

      if (policyAcceptanceQueryError || !areTermsAccepted(response.data?.party?.person)) {
        clearAuthStorage();
        // eslint-disable-next-line no-console
        console.log('Aborting login due to failure to accept terms and conditions.', policyAcceptanceQueryError);
        setIsLoading(false);
        navigate(ROUTES.otpConfirmationMethod, { state: { shouldDisplayTermsAndConditionsCheckbox: true, pingFlowID } });
        return;
      }
    }

    setIsLoading(false);

    if (localStorage.getItem(storage_keys.user_needs_to_confirm_contact_info) !== null) {
      navigate(ROUTES.confirmPrimaryContactInfo);
    } else {
      navigate(ROUTES.root);
    }
  };

  const togglePreferredOTPDeviceTypeAndSendOTP = useCallback(async () => {
    setIsLoading(true);
    const newPreference = preferredOTPDeviceType === OTPDeviceType.SMS ? OTPDeviceType.EMAIL : OTPDeviceType.SMS;

    await sendOTPToDeviceType(newPreference, pingFlowID);

    localStorage.setItem(storage_keys.otp_device_type_preference, newPreference);
    setPreferredOTPDeviceType(newPreference);
    setIsLoading(false);
  }, [preferredOTPDeviceType, pingFlowID]);

  const sendOTPToPreferredDevice = useCallback(async () => {
    setIsLoading(true);

    await sendOTPToDeviceType(preferredOTPDeviceType, pingFlowID);

    setIsLoading(false);
  }, [preferredOTPDeviceType, pingFlowID]);

  return (
    <>
      <OnBoardingPageWrapper>
        <NMEOTPForm
          formMethods={formMethods}
          onSubmit={onOTPSubmit}
          onRequestOfOTP={sendOTPToPreferredDevice}
          onRequestOfOTPByAlternativeMethod={togglePreferredOTPDeviceTypeAndSendOTP}
          preferredOTPDeviceType={preferredOTPDeviceType}
          shouldDisableSubmitButton={loading}
          isMultipleOtp={isMultipleOtp}
        />
      </OnBoardingPageWrapper>
      <ModalSpinner loading={loading} text="Processing" />
    </>
  );
}

export default NMEOTPPage;
