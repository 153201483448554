const getFontFamily = () => ['Harmonia Sans', 'Helvetica', 'Arial', 'sans-serif'].join(', ');

const publicProviderSearchTypography = {
  typography: {
    fontFamily: getFontFamily(),
    h1: {
      fontSize: '2.5rem',
      lineHeight: 1.33,
      fontFamily: getFontFamily(),
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 1.25,
      fontFamily: getFontFamily(),
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.25,
      fontFamily: getFontFamily(),
    },
    h4: {
      fontSize: '1.75rem',
      lineHeight: 1.25,
      fontFamily: getFontFamily(),
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: 1.25,
      fontFamily: getFontFamily(),
    },
    h6: {
      fontSize: '1.5rem',
      lineHeight: 1.25,
      fontFamily: getFontFamily(),
    },

    body1: {
      fontFamily: getFontFamily(),
      fontSize: '1.25rem',
      lineHeight: 1.25,
    },
    body2: {
      fontFamily: getFontFamily(),
      fontSize: '1.25rem',
      lineHeight: 1.25,
    },
    body3: {
      fontFamily: getFontFamily(),
      fontSize: '1rem',
      lineHeight: 1.25,
    },
    body4: {
      fontFamily: getFontFamily(),
      fontSize: '1rem',
      lineHeight: 1.25,
    },
    body5: {
      fontFamily: getFontFamily(),
      fontSize: '.875rem',
      lineHeight: 1.25,
    },
    body6: {
      fontFamily: getFontFamily(),
      fontSize: '.875rem',
      lineHeight: 1.25,
    },
    body7: {
      fontFamily: getFontFamily(),
      fontSize: '.75rem',
      lineHeight: 1.25,
    },
    body8: {
      fontFamily: getFontFamily(),
      fontSize: '.75rem',
      lineHeight: 1.25,
    },

    button: {
      fontFamily: getFontFamily(),
      fontSize: '1rem',
      lineHeight: 0.8,
    },
  },
};

export default publicProviderSearchTypography;
