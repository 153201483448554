import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const styles = {
  wrapper: {
    background: '#ffffff',
    height: 'calc(100vh - 250px)',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {},
  title: {
    fontSize: '32px',
    fontWeight: '600',
    margin: '30px 0 15px',
    textAlign: 'center',
  },
  text: {
    fontSize: '15px',
    fontWeight: '400',
    margin: '0 0 25px 0',
    textAlign: 'center',
  },
  btn: { width: 'calc(100% - 32px)' },

  textBtn: {
    width: '100%',
    marginTop: '10px',
    textTransform: 'none !important',
  },
};

function AddBankAccountVerification() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.wrapper}>
      <Box component="img" alt="Verifying!" src="/images/verifying.svg" sx={styles.icon} />
      <Typography sx={styles.title}>Verifying Account</Typography>
      <Typography sx={styles.text}>
        We need additional time to verify your bank account. Please check back later or add a card to continue now.
      </Typography>
      <Button sx={{ ...styles.btn, marginBottom: '25px' }} variant="outlined" onClick={() => navigate(-3)}>
        Go Back
      </Button>
    </Box>
  );
}

export default AddBankAccountVerification;
