import { Components, Theme } from '@mui/material';

import CeraProBlackItalic from '../../../assets/fonts/Cera-Pro-Black-Italic.woff2';
import CeraProBlack from '../../../assets/fonts/Cera-Pro-Black.woff2';
import CeraProBoldItalic from '../../../assets/fonts/Cera-Pro-Bold-Italic.woff2';
import CeraProBold from '../../../assets/fonts/Cera-Pro-Bold.woff2';
import CeraProLightItalic from '../../../assets/fonts/Cera-Pro-Light-Italic.woff2';
import CeraProLight from '../../../assets/fonts/Cera-Pro-Light.woff2';
import CeraProMediumItalic from '../../../assets/fonts/Cera-Pro-Medium-Italic.woff2';
import CeraProMedium from '../../../assets/fonts/Cera-Pro-Medium.woff2';
import CeraProRegularItalic from '../../../assets/fonts/Cera-Pro-Regular-Italic.woff2';
import CeraProRegular from '../../../assets/fonts/Cera-Pro-Regular.woff2';
import CeraProThinItalic from '../../../assets/fonts/Cera-Pro-Thin-Italic.woff2';
import CeraProThin from '../../../assets/fonts/Cera-Pro-Thin.woff2';

import HarmoniaSansBlackItalic from '../../../assets/fonts/Harmonia-Sans-Black-Italic.woff2';
import HarmoniaSansBlack from '../../../assets/fonts/Harmonia-Sans-Black.woff2';
import HarmoniaSansBoldItalic from '../../../assets/fonts/Harmonia-Sans-Bold-Italic.woff2';
import HarmoniaSansBold from '../../../assets/fonts/Harmonia-Sans-Bold.woff2';
import HarmoniaSansSemiBoldItalic from '../../../assets/fonts/Harmonia-Sans-SemiBold-Italic.woff2';
import HarmoniaSansSemiBold from '../../../assets/fonts/Harmonia-Sans-SemiBold.woff2';
import HarmoniaSansRegularItalic from '../../../assets/fonts/Harmonia-Sans-Regular-Italic.woff2';
import HarmoniaSansRegular from '../../../assets/fonts/Harmonia-Sans-Regular.woff2';
import HarmoniaSansLightItalic from '../../../assets/fonts/Harmonia-Sans-Light-Italic.woff2';
import HarmoniaSansLight from '../../../assets/fonts/Harmonia-Sans-Light.woff2';

const CssBaseline = ({ palette }: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: `
    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Thin'), url(${CeraProThin}) format('woff2');
      font-weight: 100;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Thin-Italic'), url(${CeraProThinItalic}) format('woff2');
      font-weight: 100;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Light'), url(${CeraProLight}) format('woff2');
      font-weight: 200;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Light-Italic'), url(${CeraProLightItalic}) format('woff2');
      font-weight: 200;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Regular'), url(${CeraProRegular}) format('woff2');
      font-weight: 400;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Regular-Italic'), url(${CeraProRegularItalic}) format('woff2');
      font-weight: 400;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Medium'), url(${CeraProMedium}) format('woff2');
      font-weight: 500;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Medium-Italic'), url(${CeraProMediumItalic}) format('woff2');
      font-weight: 500;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Bold'), url(${CeraProBold}) format('woff2');
      font-weight: 700;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Bold-Italic'), url(${CeraProBoldItalic}) format('woff2');
      font-weight: 700;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

    }


    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Black'), url(${CeraProBlack}) format('woff2');
      font-weight: 800;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Cera Pro";
      src: local('Cera-Pro-Black-Italic'), url(${CeraProBlackItalic}) format('woff2');
      font-weight: 800;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Light'), url(${HarmoniaSansLight}) format('woff2');
      font-weight: 200;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Light-Italic'), url(${HarmoniaSansLightItalic}) format('woff2');
      font-weight: 200;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Regular'), url(${HarmoniaSansRegular}) format('woff2');
      font-weight: 400;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Regular-Italic'), url(${HarmoniaSansRegularItalic}) format('woff2');
      font-weight: 400;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Semi-Bold'), url(${HarmoniaSansSemiBold}) format('woff2');
      font-weight: 600;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Semi-Bold-Italic'), url(${HarmoniaSansSemiBoldItalic}) format('woff2');
      font-weight: 600;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Bold'), url(${HarmoniaSansBold}) format('woff2');
      font-weight: 700;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Bold-Italic'), url(${HarmoniaSansBoldItalic}) format('woff2');
      font-weight: 700;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Black'), url(${HarmoniaSansBlack}) format('woff2');
      font-weight: 800;
      font-style: normal;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    @font-face {
      font-family: "Harmonia Sans";
      src: local('Harmonia-Sans-Black-Italic'), url(${HarmoniaSansBlackItalic}) format('woff2');
      font-weight: 800;
      font-style: italic;
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }

    body: {
      color: ${palette.primary.contrastText}
    }
  `,
  },
});

export default CssBaseline;
