import { SxProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const floatingCardSx = {
  display: 'flex',
  flexDirection: 'column',
  background: ({ palette }) => palette.custom.white,
  border: '1px solid rgba(52, 61, 79, 0.1)',
  boxShadow: '0px 2px 8px rgba(66, 84, 124, 0.2)',
  borderRadius: '20px',
  padding: '24px 24px 36px',
} as SxProps<Theme>;

export const NMEFloatingCardTitleStyled = styled(Typography)(() => ({
  height: '2.5rem',
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: '400',
}));
