import { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Theme, useMediaQuery } from '@mui/material';
import Map, { Marker, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CURRENT_ENVIRONMENT } from '../../../app/environments';
import { styles } from './PublicProviderDetailsMap.styles';
import { PracticeFacilityDetailsData } from '../../../pages/NMEPracticeFacilityDetailsPage/NMEPracticeFacilityDetailsPage.utils';
import { Coordinate } from '../../ProviderSearch/ProviderSearchPage.types';
import { DEFAULT_ZOOM_LEVEL, LIGHT_MAP_STYLE } from '../../../constants/map';
import { latLongOfMiamiZipCode } from '../../ProviderSearch/ProviderSearchPage.consts';

function PublicProviderDetailsMap({ practiceFacilityDetails }: { practiceFacilityDetails: PracticeFacilityDetailsData }) {
  const [userPosition, setUserPosition] = useState<Coordinate | null>(null);

  const practiceFacilityCoordinate: Coordinate | null =
    practiceFacilityDetails.latLong && practiceFacilityDetails.latLong?.length === 2
      ? {
          latitude: practiceFacilityDetails.latLong[0],
          longitude: practiceFacilityDetails.latLong[1],
        }
      : latLongOfMiamiZipCode;

  const [mapCenterCoordinate, setMapCenterCoordinate] = useState<Coordinate | null>(practiceFacilityCoordinate);

  const [mapZoomLevel, setZoomLevel] = useState<number>(DEFAULT_ZOOM_LEVEL);

  const handleMapDrag = useCallback(
    (event: ViewStateChangeEvent) => {
      setMapCenterCoordinate({ latitude: event.viewState.latitude, longitude: event.viewState.longitude });
    },
    [setMapCenterCoordinate]
  );

  const handleMapViewStateChange = useCallback(
    (event: ViewStateChangeEvent) => {
      setZoomLevel(event.viewState.zoom);
    },
    [setZoomLevel]
  );

  const displayUserLocationOnMap = (shouldCenterOnUser = false) => {
    if (navigator.permissions && navigator.permissions.query && navigator.geolocation && navigator.geolocation.getCurrentPosition) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'denied') {
          return;
        }

        navigator.geolocation.getCurrentPosition(
          (userLocationCoordinates) => {
            const userLocationCoords = userLocationCoordinates.coords;

            setUserPosition({
              latitude: userLocationCoords.latitude,
              longitude: userLocationCoords.longitude,
            });

            if (shouldCenterOnUser) {
              setZoomLevel(DEFAULT_ZOOM_LEVEL);
              setMapCenterCoordinate({
                latitude: userLocationCoords.latitude,
                longitude: userLocationCoords.longitude,
              });
            }
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.log('error retrieving user location', error);
          },
          {
            enableHighAccuracy: false,
            maximumAge: 100 * 60,
          }
        );
      });
    }
  };

  const handleLocateMeMapButton = useCallback(() => {
    if (userPosition) {
      setMapCenterCoordinate(userPosition);
      setZoomLevel(DEFAULT_ZOOM_LEVEL);
    } else {
      displayUserLocationOnMap(true);
    }
  }, [userPosition]);

  const isTabletOrDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    displayUserLocationOnMap();
  }, []);

  return (
    <Box sx={styles.columnContainer}>
      <Box sx={[styles.mapContainer, isTabletOrDesktop ? styles.largeRoundedMap : styles.smallMapHeight]}>
        {mapCenterCoordinate && (
          <Map
            style={{ position: 'absolute', top: '0', bottom: '0', width: '100%' }}
            mapboxAccessToken={CURRENT_ENVIRONMENT.MAPBOX_API_TOKEN}
            longitude={mapCenterCoordinate?.longitude}
            latitude={mapCenterCoordinate?.latitude}
            onDrag={handleMapDrag}
            onZoomEnd={handleMapViewStateChange}
            mapStyle={LIGHT_MAP_STYLE}
            zoom={mapZoomLevel}
          >
            {userPosition && (
              <Marker key="userPosition" latitude={userPosition.latitude} longitude={userPosition.longitude}>
                <Box component="img" src="/images/map/user-dot-nomi-cyan.svg" />
              </Marker>
            )}
            {practiceFacilityCoordinate && (
              <Marker
                key="practice-facility-marker"
                latitude={practiceFacilityCoordinate.latitude}
                longitude={practiceFacilityCoordinate.longitude}
              >
                <Box component="img" src="/images/map/pin-active.svg" />
              </Marker>
            )}
          </Map>
        )}
        <Box sx={styles.mapControlsBox}>
          <IconButton aria-label="map-user-location" onClick={handleLocateMeMapButton} sx={styles.userLocationButton}>
            <Box component="img" alt="map-user-location" src="/images/map/locator.svg" />
          </IconButton>
          <Box sx={styles.zoomBox}>
            <IconButton
              aria-label="zoom-decrease"
              onClick={() => setZoomLevel(mapZoomLevel >= 1 ? mapZoomLevel - 1 : 0)}
              sx={styles.zoomIcon}
            >
              <Box component="img" alt="zoom-decrease" src="/images/map/zoom-minus.svg" />
            </IconButton>
            <IconButton
              aria-label="zoom-increase"
              onClick={() => setZoomLevel(mapZoomLevel <= 24 ? mapZoomLevel + 1 : 25)}
              sx={styles.zoomIcon}
            >
              <Box component="img" alt="zoom-increase" src="/images/map/zoom-plus.svg" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PublicProviderDetailsMap;
