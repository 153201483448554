import { GetPlanUtilizationQuery, MedicalCodeGroup } from '../../app/graphql/_generated/hooks';
import { IPlanUtilization } from '../../features/api/bills/types';

export function getPlanUtilizationQueryResultFormatter(data: GetPlanUtilizationQuery) {
  const planData: IPlanUtilization[] = [];

  if (data.party?.memberships) {
    data.party?.memberships[0]?.plan_utilizations?.utilizations?.map(
      (item) =>
        item &&
        planData.push({
          amount_used: Number(item.amount_used_pennies),
          label: (item.reference_code as MedicalCodeGroup).name,
          quantity_remaining: Number(item.quantity_remaining),
          quantity_used: Number(item.quantity_used),
        })
    );
  }

  return planData;
}
