import { Box, Link, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface BoxWithShadowProps {
  title?: string;
  error?: boolean;
  linkText?: string | null;
  linkUrl?: string | null;
  children?: React.ReactNode;
}

function BoxWithShadow({ title, linkText, linkUrl, children, error }: BoxWithShadowProps) {
  const navigate = useNavigate();

  return (
    <Box>
      {title || linkText ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '24px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Cera Pro',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '20px',
              lineHeight: '25px',
              color: (theme: Theme) => theme.palette.custom.textColor,
            }}
          >
            {title}
          </Typography>

          {linkText && linkUrl ? (
            <Link
              data-id="see-all-btn"
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                cursor: 'pointer',
              }}
              underline="none"
              onClick={() => navigate(linkUrl)}
            >
              {linkText}
            </Link>
          ) : null}
        </Box>
      ) : null}

      <Box
        sx={{
          boxShadow: ' 0px 2px 8px rgba(66, 84, 124, 0.2)',
          borderRadius: '10px',
          padding: '16px',
          marginTop: '5px',
          border: (theme: Theme) => (error ? `1px solid ${theme.palette.error.main}` : 'none'),
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default BoxWithShadow;
