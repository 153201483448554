import axios from 'axios';
import { PING_URL, storage_keys } from '../../app/constants';
import { CURRENT_ENVIRONMENT } from '../../app/environments';
import { refreshAndStoreTokens } from '../../app/graphql/refreshToken';
import { authStorage, clearAuthStorage } from '../../app/storageApi';
import { AuthContext } from './AuthContext';
import { generateCodeChallenge } from './pkce';
import { newMemberExperienceLogin, URLS } from '../../app/rest-api/new-member-experience-login';
import { LoginResponseBodyWithStatus } from '../../app/rest-api/new-member-experience-login/types';
import browserHistory from '../../app/history';

function AuthProvider({ children }: { children: React.ReactNode }) {
  const isAuthenticated = () => {
    const tokenExpiryTime = Number(authStorage.getItem(storage_keys.expires_in)) || 0;
    const currentTime = Math.floor(Date.now() / 1000);

    return !!authStorage.getItem(storage_keys.access_token) && currentTime < tokenExpiryTime;
  };

  const loginWithRestApi = async (email: string, firstName?: string, dateOfBirth?: string): Promise<LoginResponseBodyWithStatus> => {
    return newMemberExperienceLogin(email, firstName, dateOfBirth);
  };

  const loginWithPingRedirect = async (loginHint: string, client_id?: string): Promise<void> => {
    const { code_verifier, code_challenge } = await generateCodeChallenge();
    const state = 'deep_link';

    authStorage.setItem(storage_keys.pkce_state, state);
    authStorage.setItem(storage_keys.client_id, client_id || CURRENT_ENVIRONMENT.PING.CLIENT_ID);

    authStorage.setItem(storage_keys.code_verifier, code_verifier);
    const queryParams = new URLSearchParams({
      response_type: 'code',
      client_id: client_id || CURRENT_ENVIRONMENT.PING.CLIENT_ID,
      state,
      redirect_uri: CURRENT_ENVIRONMENT.PING.REDIRECT_URI,
      code_challenge,
      code_challenge_method: 'S256',
      login_hint: loginHint,
    });

    window.open(`${PING_URL.authorise}?${queryParams.toString()}`, '_self');
  };

  const logout = async () => {
    const tokenExpiryTime = Number(authStorage.getItem(storage_keys.expires_in)) || 0;
    const currentTime = Math.floor(Date.now() / 1000);

    if (currentTime > tokenExpiryTime - 1) {
      await refreshAndStoreTokens().catch(() => {
        clearAuthStorage();
        window.location.replace('/sign-in');
      });
    }

    try {
      const idToken = authStorage.getItem(storage_keys.id_token);

      if (idToken) {
        axios.get(URLS.logout, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('LogOut Error: ', err);
    } finally {
      clearAuthStorage();
      browserHistory.replace('/sign-in');
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loginWithPingRedirect, loginWithRestApi, logout }}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
