import { useEffect, useState } from 'react';
import { getPracticeFacilityDetails } from '../../../../app/rest-api/public-provider-search';
import { extractLocationDetails } from '../NewProviderSearch.utils';
import { LocationDetails } from '../NewProviderSearch.types';

export const usePracticeFacilityDetails = (practiceFacilityId?: string | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [locationDetails, setLocationDetails] = useState<LocationDetails | null>(null);

  useEffect(() => {
    if (!practiceFacilityId) {
      return;
    }

    const fetchPracticeFacilityDetails = async () => {
      setLoading(true);

      try {
        const response = await getPracticeFacilityDetails(practiceFacilityId);
        const details = extractLocationDetails(response);

        setLocationDetails(details);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPracticeFacilityDetails();
  }, [practiceFacilityId]);

  return { locationDetails, loading };
};
