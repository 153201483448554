import { Stack, Typography } from '@mui/material';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';
import { contactInfoIconSx, contactMethodContainerSx, contactMethodSx, containerSx, detailsSx, titleSx } from './ContactACareGuide.styles';

export default function ContactACareGuide() {
  return (
    <Stack sx={containerSx}>
      <Typography sx={titleSx}>Contact a Care Guide</Typography>
      <Typography sx={detailsSx}>
        Care guides can help you understand your costs, answer questions, and guide you through your journey to set an appointment
      </Typography>
      <Stack direction="row" spacing={1} sx={contactMethodContainerSx}>
        <PhoneEnabledIcon sx={contactInfoIconSx} />
        <Typography sx={contactMethodSx}>(855) 601-1900</Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={contactMethodContainerSx}>
        <EmailIcon sx={contactInfoIconSx} />
        <Typography sx={contactMethodSx}>getcare@nomihealth.com</Typography>
      </Stack>
    </Stack>
  );
}
