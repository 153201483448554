/* eslint-disable no-nested-ternary */
import { ReactNode } from 'react';
import { Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IPaymentMethod } from '../../features/api/paymentMethods/types';
import { useUserPaymentMethods } from '../../utils/gqlResultsFormatters/useUserPaymentMethods.hook';
import BoxWithShadow from '../boxes/BoxWithShadow';
import { BankPaymentMethod, CardPaymentMethod } from './BankPaymentMethod';

export interface PaymentMethodActionProps {
  seeAll?: boolean;
  change?: boolean;
  remove?: boolean;
  select?: boolean;
  shadow?: boolean;
  children?: ReactNode;
  data?: IPaymentMethod;
}

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    width: '100%',
    padding: '10px 0',
  },
};

function DefaultPaymentMethod() {
  const navigate = useNavigate();
  const { paymentMethods: allPaymentMethods = [] } = useUserPaymentMethods();
  const paymentMethods = allPaymentMethods?.filter((payMethod) => payMethod?.status !== 'HOLD');
  const currentPaymentMethod = paymentMethods?.length ? paymentMethods[0] : null;

  return (
    <BoxWithShadow
      title="Payment Method"
      linkText={currentPaymentMethod ? 'See All' : null}
      linkUrl={currentPaymentMethod ? '/payment-method/all' : null}
    >
      <Box sx={styles.wrapper}>
        {currentPaymentMethod ? (
          currentPaymentMethod.account_type === 'STRIPE_CUSTOMER' ? (
            <CardPaymentMethod data={currentPaymentMethod} />
          ) : (
            <BankPaymentMethod data={currentPaymentMethod} />
          )
        ) : (
          <Link data-id="add-pm-btn" sx={styles.link} underline="none" onClick={() => navigate('/payment-method/add')}>
            + Add payment method
          </Link>
        )}
      </Box>
    </BoxWithShadow>
  );
}

export default DefaultPaymentMethod;
