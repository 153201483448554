import { Box, Stack, Typography } from '@mui/material';
import { Container, CheckboxStyled, Name } from './DependentItem.styles';

type Props = {
  firstName: string;
  lastName: string;
  isConfirmed: boolean;
  onPress: () => void;
};

export function DependentItem({ firstName, lastName, isConfirmed, onPress }: Props) {
  return (
    <Container onClick={onPress} data-id="dependent-item">
      <Stack flexDirection="column">
        <Name variant="body6">
          {firstName} {lastName}
        </Name>
        <Typography variant="body7" fontWeight={300}>
          Dependent
        </Typography>
      </Stack>

      <CheckboxStyled
        disabled
        checked={isConfirmed}
        icon={<Box component="img" alt="Unchecked" src="/images/unchecked-checkbox.svg" />}
        checkedIcon={<Box component="img" alt="Checked" src="/images/checked-checkbox.svg" />}
      />
    </Container>
  );
}
