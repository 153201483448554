import { SxProps, Theme } from '@mui/material';

export const contentContainerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: (theme) => theme.palette.custom.darkBlue,
  backgroundImage: 'url("/images/header-pattern.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right top',
};

export const lineItemsContainerSx: SxProps<Theme> = {
  marginTop: '36px',
  margin: '36px 36px 0 18px',
  height: '100%',
};

export const lineItemTextSx: SxProps<Theme> = {
  color: 'white',
  fontSize: '18px',
  fontWeight: 300,
  lineHeight: '28px',
};

export const welcomeTitleSx: SxProps<Theme> = {
  marginTop: ({ spacing }: Theme) => spacing(4),
  color: 'white',
  fontSize: '36px',
  letterSpacing: '-0.72px',
  lineHeight: '44px',
  fontWeight: 700,
};

export const lineItemIconSx: SxProps<Theme> = {
  width: '40px',
  height: '40px',
};

export const getStartedButtonSx = (theme: Theme) => {
  return {
    position: 'absolute',
    bottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      left: '40px',
      right: '40px',
    },
    [theme.breakpoints.up('sm')]: {
      left: '6rem',
      right: '6rem',
    },
    height: '52px',
    backgroundColor: 'white',
  };
};
