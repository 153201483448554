/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from '@mui/material';
import isAfter from 'date-fns/isAfter';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentUserPartyId } from '../../app/graphql/auth.utils';
import {
  BillByIdDocument,
  GetPaymentMethodsQuery,
  RemittanceCreationPathType,
  useBillByIdQuery,
  useGetAlloyAndBankStatusesLazyQuery,
  useGetPaymentMethodsQuery,
  usePayBillWithCreditCardMutation,
  useSubmitRemittanceMutation,
} from '../../app/graphql/_generated/hooks';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { BillDetailsSection } from '../../components/BillDetailsSection';
import PageTitle from '../../components/PageTitle';
import PaymentMethodChange from '../../components/partials/pay-method/PaymentMethodChange';
import { PaymentMethod } from '../../features/api/paymentMethods/types';
import { saveCurrentBillId } from '../../features/bills/billsSlice';
import { savePaymentMethods, selectCurrentPM, setPaymentMethod } from '../../features/paymentMethods/paymentMethodSlice';
import { formatAmount } from '../../utils/format-amount';
import { dateMMddyyyy } from '../../utils/format-date';
import { getAlloyStatus } from '../../utils/gqlResultsFormatters/alloyStatus';
import { getPaymentMethodsQueryResultsFormatter } from '../../utils/gqlResultsFormatters/paymentMethods';
import { useUserPaymentMethods } from '../../utils/gqlResultsFormatters/useUserPaymentMethods.hook';
import LoadingScreen from '../Loading';
import { styles } from './styles';
import { storage_keys } from '../../app/constants';

function hasUnverified(data: GetPaymentMethodsQuery | undefined) {
  const unverifiedAccounts = data?.party?.accounts?.accounts?.find((account) => account?.status === 'UNVERIFIED');

  return !!unverifiedAccounts;
}

const PAYMENT_PROCESS_ERROR =
  'We were unable to process your payment. Please update your payment method or contact your bank for more information.';

function PayBillPage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [pollCount, setPollCount] = useState(0);
  const [polling, setPolling] = useState(true);
  const [paymentError, setPaymentError] = useState('');
  const maxPolls = 4;

  let currentPaymentMethod = useAppSelector(selectCurrentPM);
  const { paymentMethods } = useUserPaymentMethods();

  currentPaymentMethod = currentPaymentMethod ? currentPaymentMethod : paymentMethods?.find((payMethod) => payMethod?.status !== 'HOLD');

  const isExpiredCard =
    currentPaymentMethod && currentPaymentMethod.account_type === 'STRIPE_CUSTOMER'
      ? !isAfter(new Date(Number(currentPaymentMethod?.exp_year), Number(currentPaymentMethod?.exp_month), 1), new Date())
      : false;

  const invoiceId = params.id || '';

  const { loading, data: invoiceQueryResult } = useBillByIdQuery({
    variables: { invoice_id: invoiceId },
    onCompleted: (data) => {
      const currentBill = data?.invoiceById?.bills?.find((bill) => bill?.bill_type === 'MEMBER');
      const billAudit = currentBill?.audit_logs?.find(
        (log) => log?.remittance_state_transition?.to_state === 'BILL_PAID' || log?.remittance_state_transition?.to_state === 'BILL_PENDING'
      );
      const dateOfServiceDate = currentBill ? dateMMddyyyy(data?.invoiceById?.encounter?.service?.service_time) : '';
      const dateOfPaymentDate = currentBill?.cash_payment ? dateOfServiceDate : billAudit?.transitioned_at_time;

      if ((currentBill?.payment_method || currentBill?.cash_payment) && dateOfPaymentDate) {
        navigate(`/bills/${invoiceId}`);
      }
    },
  });

  const memberBill = invoiceQueryResult?.invoiceById?.bills?.find((bill) => bill?.bill_type === 'MEMBER');
  const party_id = getCurrentUserPartyId();
  const {
    data: pmData,
    loading: pmLoading,
    startPolling,
    stopPolling,
  } = useGetPaymentMethodsQuery({
    variables: { id: party_id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      setPollCount(pollCount + 1);
      const pmList = getPaymentMethodsQueryResultsFormatter(result) as PaymentMethod[];

      if (pmList) {
        dispatch(savePaymentMethods(pmList));
      }
    },
  });

  const handleSuccessPaid = () => {
    navigate(`/pay-bill/${invoiceId}/success`);
  };

  const [submitRemittanceMutation, { loading: submitRemittanceLoading }] = useSubmitRemittanceMutation({
    refetchQueries: [{ query: BillByIdDocument, variables: { invoice_id: invoiceId } }],
    onCompleted: () => {
      handleSuccessPaid();
    },
  });

  const [payBillWithCreditCardMutation, { loading: payBillWithCreditCardLoading }] = usePayBillWithCreditCardMutation({
    refetchQueries: [{ query: BillByIdDocument, variables: { invoice_id: invoiceId } }],
    onCompleted: () => {
      handleSuccessPaid();
    },
    onError: () => {
      setPaymentError(PAYMENT_PROCESS_ERROR);
    },
  });

  const [getAlloyAndBankStatuses, { data: AlloyAndBankData, loading: statusesLoading }] = useGetAlloyAndBankStatusesLazyQuery();
  const isAlloyPassed = getAlloyStatus(AlloyAndBankData);
  const errorCondition = !isAlloyPassed && currentPaymentMethod && !('brand' in currentPaymentMethod);

  const confirmPay = () => {
    dispatch(saveCurrentBillId(''));
    setPaymentError('');
    currentPaymentMethod && currentPaymentMethod.account_type === 'STRIPE_CUSTOMER'
      ? payBillWithCreditCardMutation({
          variables: {
            party_id: party_id,
            bill_id: memberBill?.id || '',
            payment_method_id: currentPaymentMethod?.account_id || '',
          },
        })
      : submitRemittanceMutation({
          variables: {
            remittance_creation_path: RemittanceCreationPathType.PayBillPath,
            bill_id: memberBill?.id || '',
            invoice_id: invoiceId,
            external_account_id: currentPaymentMethod?.account_id || '',
          },
        });
  };

  useEffect(() => {
    getAlloyAndBankStatuses({ variables: { id: party_id } });
  }, [getAlloyAndBankStatuses, party_id]);

  useEffect(() => {
    const savedPM = sessionStorage.getItem(storage_keys.default_payment_method);

    if (savedPM && currentPaymentMethod?.account_id !== savedPM && paymentMethods?.length) {
      dispatch(setPaymentMethod(paymentMethods?.find((payMethod) => payMethod && payMethod?.account_id === savedPM)));
    }
  }, [currentPaymentMethod?.account_id, dispatch, paymentMethods]);

  useEffect(() => {
    localStorage.removeItem(storage_keys.bill_to_pay);
  }, []);

  useEffect(() => {
    setPolling(true);
    startPolling(2000);
    if (!hasUnverified(pmData) || pollCount >= maxPolls) {
      stopPolling();
      setPolling(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmData, pollCount]);

  return (
    <>
      <PageTitle title="Pay Bill" />
      {loading || submitRemittanceLoading || payBillWithCreditCardLoading || pmLoading || statusesLoading || polling ? (
        <LoadingScreen />
      ) : memberBill ? (
        <Box sx={{ padding: '0 16px' }}>
          <BillDetailsSection currentInvoice={invoiceQueryResult} />

          {!memberBill?.payment_method && !memberBill?.cash_payment && (
            <Box>
              <PaymentMethodChange error={!currentPaymentMethod ? false : !!errorCondition} />

              {(!currentPaymentMethod ? false : errorCondition) && (
                <Typography sx={styles.errorText}>
                  We are processing your account at this time. To make a payment now, please add another payment method. Need help? Contact
                  us at 555-555-1212.
                </Typography>
              )}
              <Button
                data-id="confirm"
                color="primary"
                variant="contained"
                sx={styles.payBtn}
                disabled={!currentPaymentMethod || errorCondition || isExpiredCard}
                onClick={confirmPay}
              >
                {`Confirm & Pay $${formatAmount(memberBill?.bill_total_pennies)}`}
              </Button>
              {paymentError && <Typography sx={styles.errorText}>{paymentError}</Typography>}
              <Button
                data-id="question-btn"
                color="primary"
                variant="text"
                sx={styles.textBtn}
                onClick={() => {
                  window.open('mailTo:tellus@support.nomihealth.com', '_blank');
                }}
              >
                I have a question about this bill.
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Typography sx={styles.billError}>Bill not found</Typography>
        </Box>
      )}
    </>
  );
}

export default PayBillPage;
