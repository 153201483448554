import { Box, Link, Tooltip, IconButton, Menu, MenuItem, useMediaQuery, Theme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { styles } from './HeaderNav.styles';
import { LEGAL_LINKS } from '../../app/constants';
import { useAuth } from '../../features/authV2/AuthContext';
import { ROUTES } from '../../constants/routes';

function HeaderNav() {
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box sx={[styles.wrap, isMobile && { mb: 0 }]}>
      <Box
        component="img"
        data-id="logo"
        alt="Logo"
        src="/images/new-logo.svg"
        onClick={() => {
          navigate(ROUTES.root);
        }}
      />
      <Box display="flex" flexDirection="row">
        {/* WILL BE AVAILABLE IN FUTURE RELEASE, so let keep it */}
        {/*<Link sx={styles.link} href="#">*/}
        {/*  <Box component="img" src="/images/userIcon.svg" />*/}
        {/*  Account*/}
        {/*</Link>*/}
        {/*<Link sx={styles.link} href="#">*/}
        {/*  <Box component="img" src="/images/headset.svg" />*/}
        {/*  Support*/}
        {/*</Link>*/}
        <Tooltip title="Account menu">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={styles.burger}
          >
            {open ? <CloseIcon sx={styles.burgerIconSize} /> : <MenuIcon sx={styles.burgerIconSize} />}
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={styles.menuPaper}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
            <Link target="blank" href={LEGAL_LINKS.legal} sx={styles.menuLink}>
              <Box component="img" src="/images/legal-icon.svg" />
              Legal
            </Link>
          </MenuItem>
          {isAuthenticated() && (
            <MenuItem onClick={logout} sx={styles.menuItem}>
              <Box component="img" src="/images/logout-icon.svg" />
              Logout
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  );
}

export default HeaderNav;
