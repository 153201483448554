import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountType: any;
  Any: any;
  FundingPreferenceType: any;
  Time: any;
};

export type AccountInformation = {
  __typename?: 'AccountInformation';
  account_number_1?: Maybe<Scalars['String']>;
  account_number_2?: Maybe<Scalars['String']>;
  account_number_qualifier?: Maybe<X12Code>;
  benefit_status_code?: Maybe<X12Code>;
  description?: Maybe<Scalars['String']>;
  id_code?: Maybe<Scalars['String']>;
  id_code_qualifier?: Maybe<X12Code>;
  name?: Maybe<Scalars['String']>;
  payment_method_type_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type Accounts = {
  __typename?: 'Accounts';
  Version?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<FinancialAccount>>>;
};

export type AccountsAccountsArgs = {
  account_types?: InputMaybe<Array<Scalars['AccountType']>>;
};

export type AccountsInput = {
  accounts?: InputMaybe<Array<InputMaybe<FinancialAccountInput>>>;
};

export type ActionsIndicated = {
  __typename?: 'ActionsIndicated';
  element_metadata?: Maybe<X12Metadata>;
};

export type ActionsIndicatedInput = {
  element_metadata?: InputMaybe<X12MetadataInput>;
};

export type AddEmailInput = {
  email: Scalars['String'];
};

export type AddEmailResponse = {
  __typename?: 'AddEmailResponse';
  party?: Maybe<Party>;
  result: AddEmailResult;
};

export enum AddEmailResult {
  EmailAdded = 'EMAIL_ADDED',
  NoChange = 'NO_CHANGE',
}

export type AddParentInput = {
  parent_id: Scalars['ID'];
};

export type AddPhoneInput = {
  phone: Scalars['String'];
};

export type AdditionalLoopCategories = {
  __typename?: 'AdditionalLoopCategories';
  loop_identifier_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city_town?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state_province?: InputMaybe<Scalars['String']>;
  street_address1?: InputMaybe<Scalars['String']>;
  street_address2?: InputMaybe<Scalars['String']>;
};

export type AddressObject = {
  __typename?: 'AddressObject';
  city_town?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state_province?: Maybe<Scalars['String']>;
  street_address1?: Maybe<Scalars['String']>;
  street_address2?: Maybe<Scalars['String']>;
};

export type AdjudicateEncounter = {
  __typename?: 'AdjudicateEncounter';
  fee_schedule_id?: Maybe<Scalars['String']>;
  member_responsibility_pennies?: Maybe<Scalars['Int']>;
  member_service_fee_pennies?: Maybe<Scalars['Int']>;
  service_fee_percentage?: Maybe<Scalars['Float']>;
  services_summary?: Maybe<Array<Maybe<AdjudicationLine>>>;
  sponsor_responsibility_pennies?: Maybe<Scalars['Int']>;
  sponsor_service_fee_pennies?: Maybe<Scalars['Int']>;
  total_pennies?: Maybe<Scalars['Int']>;
};

export type AdjudicateEncounterInput = {
  cpt_codes_to_units: Array<InputMaybe<CptCodesToUnitsInput>>;
  encounter_id: Scalars['ID'];
};

export type AdjudicationLine = {
  __typename?: 'AdjudicationLine';
  amount_per_unit_pennies?: Maybe<Scalars['Int']>;
  amount_total_pennies?: Maybe<Scalars['Int']>;
  cpt_code?: Maybe<Scalars['String']>;
  fee_long_desc?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Int']>;
};

export type AdjustmentIdentifier = {
  __typename?: 'AdjustmentIdentifier';
  adjustment_reason_code?: Maybe<Scalars['String']>;
  element_metadata?: Maybe<X12Metadata>;
  reference_identification?: Maybe<Scalars['String']>;
};

export type AdjustmentIdentifierInput = {
  adjustment_reason_code?: InputMaybe<Scalars['String']>;
  element_metadata?: InputMaybe<X12MetadataInput>;
  reference_identification?: InputMaybe<Scalars['String']>;
};

export type AdminManualReview = {
  acting_user_id: Scalars['String'];
  bank_account_id: Scalars['String'];
  description: Scalars['String'];
  pass_manual_review: Scalars['Boolean'];
};

export type AmbulanceCertification = {
  __typename?: 'AmbulanceCertification';
  ambulance_transport_reason_code?: Maybe<Scalars['String']>;
  patient_weight?: Maybe<FloatWrapper>;
  patient_weight_units?: Maybe<Scalars['String']>;
  round_trip_purpose_description?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  stretcher_purpose_description?: Maybe<Scalars['String']>;
  transport_distance?: Maybe<FloatWrapper>;
  transport_distance_units?: Maybe<Scalars['String']>;
};

export type AmbulanceCertificationInput = {
  ambulance_transport_reason_code?: InputMaybe<Scalars['String']>;
  patient_weight?: InputMaybe<FloatWrapperInput>;
  patient_weight_units?: InputMaybe<Scalars['String']>;
  round_trip_purpose_description?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  stretcher_purpose_description?: InputMaybe<Scalars['String']>;
  transport_distance?: InputMaybe<FloatWrapperInput>;
  transport_distance_units?: InputMaybe<Scalars['String']>;
};

export type AmbulanceDropoffLocation = {
  __typename?: 'AmbulanceDropoffLocation';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type AmbulanceDropoffLocation837I = {
  __typename?: 'AmbulanceDropoffLocation837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type AmbulanceDropoffLocation837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type AmbulanceDropoffLocationInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type AmbulancePickupLocation = {
  __typename?: 'AmbulancePickupLocation';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type AmbulancePickupLocation837I = {
  __typename?: 'AmbulancePickupLocation837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type AmbulancePickupLocation837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type AmbulancePickupLocationInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type Amount = {
  __typename?: 'Amount';
  amount_qualifier_code?: Maybe<X12Code>;
  credit_debit_flag?: Maybe<X12Code>;
  monetary_amount?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type AmountInput = {
  amount_qualifier_code?: InputMaybe<X12CodeInput>;
  credit_debit_flag?: InputMaybe<X12CodeInput>;
  monetary_amount?: InputMaybe<FloatWrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type AttendingProvider = {
  __typename?: 'AttendingProvider';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  provider_info?: Maybe<ProviderInfo>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type AttendingProviderInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  provider_info?: InputMaybe<ProviderInfoInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type Audit = {
  __typename?: 'Audit';
  created_at?: Maybe<Scalars['String']>;
  created_by_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by_id?: Maybe<Scalars['String']>;
};

export type AuditInput = {
  created_at?: InputMaybe<Scalars['String']>;
  created_by_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
  updated_by_id?: InputMaybe<Scalars['String']>;
};

export type AuditLog = {
  __typename?: 'AuditLog';
  from_state: Scalars['String'];
  to_state: Scalars['String'];
  transition_name?: Maybe<Scalars['String']>;
  transitioned_at_time: Scalars['String'];
};

export type Bht = {
  __typename?: 'BHT';
  creation_date?: Maybe<Scalars['String']>;
  creation_time?: Maybe<Scalars['String']>;
  hierarchical_structure_code?: Maybe<X12Code>;
  originator_transaction_id?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  transaction_set_purpose_code?: Maybe<X12Code>;
  transaction_type?: Maybe<X12Code>;
};

export type BhtInput = {
  creation_date?: InputMaybe<Scalars['String']>;
  creation_time?: InputMaybe<Scalars['String']>;
  hierarchical_structure_code?: InputMaybe<X12CodeInput>;
  originator_transaction_id?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  transaction_set_purpose_code?: InputMaybe<X12CodeInput>;
  transaction_type?: InputMaybe<X12CodeInput>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  account_number_last_four?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  external_type?: Maybe<ExternalAccountType>;
  inbound_routing_proxy_ids?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  nick_name?: Maybe<Scalars['String']>;
  outbound_payment_proxy_id: Scalars['String'];
  routing_number?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  account_number_last_four?: InputMaybe<Scalars['String']>;
  bank_name?: InputMaybe<Scalars['String']>;
  external_type?: InputMaybe<ExternalAccountType>;
  inbound_routing_proxy_ids?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  nick_name?: InputMaybe<Scalars['String']>;
  outbound_payment_proxy_id: Scalars['String'];
  routing_number?: InputMaybe<Scalars['String']>;
};

export type BankAccountOnBoardingInput = {
  admin_manual_review?: InputMaybe<AdminManualReview>;
  alloy_check_disabled?: InputMaybe<Scalars['Boolean']>;
  bank_account_request?: InputMaybe<BankAccountRequest>;
  onboarding_type: OnboardingType;
  party_id: Scalars['ID'];
};

export type BankAccountRequest = {
  account_holder?: InputMaybe<Scalars['String']>;
  account_number: Scalars['String'];
  account_type: Scalars['String'];
  bank_account_name?: InputMaybe<Scalars['String']>;
  routing_number: Scalars['String'];
};

export type BatchGroup = IDocument & {
  __typename?: 'BatchGroup';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  batch_group_processing_info_type?: Maybe<BatchGroupProcessingInfoType>;
  ending_row?: Maybe<Scalars['Int']>;
  file_upload_id: Scalars['ID'];
  member_batch_group_processing_info?: Maybe<MemberBatchGroupProcessingInfo>;
  starting_row?: Maybe<Scalars['Int']>;
  total_records?: Maybe<Scalars['Int']>;
};

export enum BatchGroupProcessingInfoType {
  MemberBatchGroupInfoType = 'MEMBER_BATCH_GROUP_INFO_TYPE',
  UnknownBatchGroupInfoType = 'UNKNOWN_BATCH_GROUP_INFO_TYPE',
}

export type BenefitsHeader = {
  __typename?: 'BenefitsHeader';
  action_code?: Maybe<X12Code>;
  original_transaction_set_reference_number?: Maybe<Scalars['String']>;
  security_level_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  time_zone_code?: Maybe<X12Code>;
  transaction_set_creation_date?: Maybe<Scalars['String']>;
  transaction_set_creation_time?: Maybe<Scalars['String']>;
  transaction_set_purpose_code?: Maybe<X12Code>;
  transaction_set_reference_number?: Maybe<Scalars['String']>;
  transaction_type_code?: Maybe<X12Code>;
};

export type Bill = {
  __typename?: 'Bill';
  audit_logs?: Maybe<Array<Maybe<BillAuditLog>>>;
  bill_state?: Maybe<BillState>;
  bill_total?: Maybe<Scalars['Float']>;
  bill_total_pennies?: Maybe<Scalars['Int']>;
  bill_type?: Maybe<BillType>;
  cash_payment?: Maybe<Scalars['Boolean']>;
  fee_type?: Maybe<PaymentsFeeType>;
  id?: Maybe<Scalars['String']>;
  minimum_prefunded_amount?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  payment_method_account?: Maybe<FinancialAccount>;
  payment_method_stripe?: Maybe<CardData>;
  responsible_party?: Maybe<Party>;
  responsible_party_id: Scalars['String'];
  service_fee?: Maybe<Array<Scalars['Float']>>;
  service_fee_pennies?: Maybe<Array<Scalars['Int']>>;
};

export type BillAuditLog = {
  __typename?: 'BillAuditLog';
  remittance_state_transition?: Maybe<BillStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export enum BillReferenceType {
  Clawback = 'CLAWBACK',
  ExternalBill = 'EXTERNAL_BILL',
  NomiBill = 'NOMI_BILL',
  NomiMsrResponse = 'NOMI_MSR_RESPONSE',
  Reimbursement = 'REIMBURSEMENT',
  UnknownBillReferenceType = 'UNKNOWN_BILL_REFERENCE_TYPE',
}

export enum BillState {
  BillAdjudicated = 'BILL_ADJUDICATED',
  BillFailed = 'BILL_FAILED',
  BillPaid = 'BILL_PAID',
  BillPaidExternally = 'BILL_PAID_EXTERNALLY',
  BillPaymentCanceled = 'BILL_PAYMENT_CANCELED',
  BillPending = 'BILL_PENDING',
  BillSubmitted = 'BILL_SUBMITTED',
  BillUnknown = 'BILL_UNKNOWN',
}

export type BillStateTransition = {
  __typename?: 'BillStateTransition';
  from_state?: Maybe<BillState>;
  to_state?: Maybe<BillState>;
};

export enum BillType {
  Member = 'MEMBER',
  Sponsor = 'SPONSOR',
  Undefined = 'UNDEFINED',
}

export type BillingDetails = {
  __typename?: 'BillingDetails';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type BillingProvider = {
  __typename?: 'BillingProvider';
  address?: Maybe<PhysicalAddress>;
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type BillingProvider837I = {
  __typename?: 'BillingProvider837I';
  address?: Maybe<PhysicalAddress>;
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type BillingProvider837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type BillingProviderInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export enum BundleMethodology {
  BmAddOn = 'BM_ADD_ON',
  BmAnchorCode = 'BM_ANCHOR_CODE',
  BmFeeForService = 'BM_FEE_FOR_SERVICE',
  BmMultiCodeBundle = 'BM_MULTI_CODE_BUNDLE',
  BmUnknown = 'BM_UNKNOWN',
}

export type BundlePrioritization = {
  index?: InputMaybe<Scalars['String']>;
  is_prioritized?: InputMaybe<Scalars['Boolean']>;
};

export type BundleReduction = {
  index?: InputMaybe<Scalars['String']>;
  percentage?: InputMaybe<Scalars['String']>;
};

export type BundledFeeScheduleEntries = {
  __typename?: 'BundledFeeScheduleEntries';
  fee_sch_line_items?: Maybe<Array<BundledFeeScheduleEntry>>;
  total: Scalars['Int'];
};

export type BundledFeeScheduleEntriesInput = {
  fee_sch_line_items?: InputMaybe<Array<BundledFeeScheduleEntryInput>>;
};

export type BundledFeeScheduleEntry = {
  __typename?: 'BundledFeeScheduleEntry';
  allowed_amount_pennies?: Maybe<Scalars['Int']>;
  bundle_description?: Maybe<Scalars['String']>;
  bundle_methodology?: Maybe<BundleMethodology>;
  bundle_name?: Maybe<Scalars['String']>;
  medical_code_group?: Maybe<MedicalCodeGroup>;
  medical_code_group_id?: Maybe<Scalars['ID']>;
  rate_type?: Maybe<RateType>;
};

export type BundledFeeScheduleEntryInput = {
  allowed_amount_pennies?: InputMaybe<Scalars['Int']>;
  bundle_description?: InputMaybe<Scalars['String']>;
  bundle_methodology?: InputMaybe<BundleMethodology>;
  bundle_name?: InputMaybe<Scalars['String']>;
  medical_code_group_id?: InputMaybe<Scalars['ID']>;
  rate_type?: InputMaybe<RateType>;
};

export type BundledFeeScheduleExceptionEntries = {
  __typename?: 'BundledFeeScheduleExceptionEntries';
  fee_sch_line_items?: Maybe<Array<Maybe<BundledFeeScheduleExceptionEntry>>>;
};

export type BundledFeeScheduleExceptionEntry = {
  __typename?: 'BundledFeeScheduleExceptionEntry';
  applicable_line_items_idx?: Maybe<Array<Scalars['Int']>>;
  bundle_idx: Scalars['String'];
  bundled_fee_sch_entry: BundledFeeScheduleEntry;
};

export type Card = {
  __typename?: 'Card';
  card_number_last_four: Scalars['String'];
  effective_dates?: Maybe<EffectiveDates>;
  id: Scalars['String'];
  source?: Maybe<Source>;
  status: CardStatus;
};

export type CardData = {
  __typename?: 'CardData';
  account_type?: Maybe<Scalars['String']>;
  audit?: Maybe<Audit>;
  brand?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type CardDataInput = {
  account_type?: InputMaybe<Scalars['String']>;
  audit?: InputMaybe<AuditInput>;
  brand?: InputMaybe<Scalars['String']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  funding?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last4?: InputMaybe<Scalars['String']>;
};

export type CardOptions = {
  __typename?: 'CardOptions';
  mandateOptions?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  requestThreeDSecure: Scalars['String'];
};

export type CardPayment = {
  __typename?: 'CardPayment';
  clawback_amount: Scalars['Int'];
  status?: Maybe<CardPaymentStatus>;
  transaction_amount: Scalars['Int'];
};

export enum CardPaymentStatus {
  CApproved = 'C_APPROVED',
  CAwaitingApproval = 'C_AWAITING_APPROVAL',
  CDenied = 'C_DENIED',
  CPartiallyDenied = 'C_PARTIALLY_DENIED',
  CReceiptNeeded = 'C_RECEIPT_NEEDED',
  CReceiptRejected = 'C_RECEIPT_REJECTED',
  CUnknown = 'C_UNKNOWN',
}

export enum CardStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
}

export type Checks = {
  __typename?: 'Checks';
  addressLine1Check?: Maybe<Scalars['String']>;
  addressPostalCodeCheck?: Maybe<Scalars['String']>;
  cvcCheck?: Maybe<Scalars['String']>;
};

export enum ChildRelationship {
  AdoptedChild = 'ADOPTED_CHILD',
  Child = 'CHILD',
  Dependent = 'DEPENDENT',
  DomesticPartner = 'DOMESTIC_PARTNER',
  DomesticPartnerChild = 'DOMESTIC_PARTNER_CHILD',
  FosterChild = 'FOSTER_CHILD',
  Spouse = 'SPOUSE',
  Unknown = 'UNKNOWN',
  Ward = 'WARD',
}

export type ChiropracticCertification = {
  __typename?: 'ChiropracticCertification';
  description_1?: Maybe<Scalars['String']>;
  description_2?: Maybe<Scalars['String']>;
  nature_of_condition?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ChiropracticCertificationInput = {
  description_1?: InputMaybe<Scalars['String']>;
  description_2?: InputMaybe<Scalars['String']>;
  nature_of_condition?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type CityStateZip = {
  __typename?: 'CityStateZip';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_subdivision?: Maybe<Scalars['String']>;
  location_identifier?: Maybe<Scalars['String']>;
  location_qualifier?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CityStateZipInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_subdivision?: InputMaybe<Scalars['String']>;
  location_identifier?: InputMaybe<Scalars['String']>;
  location_qualifier?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Claim = {
  __typename?: 'Claim';
  claim_amount?: Maybe<FloatWrapper>;
  claim_filing_indicator_code?: Maybe<Scalars['String']>;
  claim_identifier?: Maybe<Scalars['String']>;
  claim_status_code?: Maybe<Scalars['String']>;
  claim_submission_reason_code?: Maybe<Scalars['String']>;
  delay_reason_code?: Maybe<X12Code>;
  level_of_service?: Maybe<Scalars['String']>;
  non_institutional_claim_type_code?: Maybe<Scalars['String']>;
  patient_signature_source_code?: Maybe<X12Code>;
  provider_accept_assignment?: Maybe<X12Code>;
  provider_agreement_code?: Maybe<Scalars['String']>;
  provider_benefits_assignment?: Maybe<X12Code>;
  provider_signature_indicator?: Maybe<X12Code>;
  related_causes_information?: Maybe<RelatedCausesInfo>;
  release_of_information_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  service_location?: Maybe<HealthCareServiceLocation>;
  special_program_code?: Maybe<Scalars['String']>;
  yes_no?: Maybe<X12Code>;
  yes_no_2?: Maybe<X12Code>;
  yes_no_3?: Maybe<X12Code>;
};

export enum ClaimBillType {
  CbtAsc = 'CBT_ASC',
  CbtInpatient = 'CBT_INPATIENT',
  CbtOutpatient = 'CBT_OUTPATIENT',
  CbtProfessional = 'CBT_PROFESSIONAL',
  CbtUnknown = 'CBT_UNKNOWN',
}

export type ClaimContactInfoInput = {
  contact_function_code?: InputMaybe<X12CodeInput>;
  contact_inquiry_reference?: InputMaybe<Scalars['String']>;
  contact_name?: InputMaybe<Scalars['String']>;
  contact_number?: InputMaybe<Scalars['String']>;
  contact_number_2?: InputMaybe<Scalars['String']>;
  contact_number_3?: InputMaybe<Scalars['String']>;
  contact_number_qualifier?: InputMaybe<X12CodeInput>;
  contact_number_qualifier_2?: InputMaybe<X12CodeInput>;
  contact_number_qualifier_3?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ClaimInformation = {
  __typename?: 'ClaimInformation';
  ambulance_certification?: Maybe<AmbulanceCertification>;
  ambulance_dropoff_location?: Maybe<AmbulanceDropoffLocation>;
  ambulance_pickup_location?: Maybe<AmbulancePickupLocation>;
  amounts?: Maybe<Array<Maybe<Amount>>>;
  chiropractic_certification?: Maybe<ChiropracticCertification>;
  claim?: Maybe<Claim>;
  claim_pricing_info?: Maybe<ClaimPricingRepricingInformation>;
  conditions?: Maybe<Array<Maybe<ConditionIndicatorSegment>>>;
  contract_info?: Maybe<ContractInformation>;
  dates?: Maybe<Array<Maybe<Date>>>;
  diagnoses?: Maybe<Array<Maybe<Diagnosis>>>;
  file_info?: Maybe<Array<Maybe<FileInformation>>>;
  line_items?: Maybe<Array<Maybe<LineItem>>>;
  loop_metadata?: Maybe<X12Metadata>;
  notes?: Maybe<Array<Maybe<SpecialInstruction>>>;
  other_subscriber_info?: Maybe<Array<Maybe<OtherSubscriberInfo>>>;
  pwk?: Maybe<Array<Maybe<ClaimSupplementalInformation>>>;
  reference?: Maybe<Array<Maybe<Reference>>>;
  referring_providers?: Maybe<Array<Maybe<ReferringProvider>>>;
  rendering_provider?: Maybe<RenderingProvider>;
  service_facility_location?: Maybe<ServiceFacilityLocation>;
  supervising_provider?: Maybe<SupervisingProvider>;
};

export type ClaimInformation837I = {
  __typename?: 'ClaimInformation837I';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  attending_provider?: Maybe<AttendingProvider>;
  claim?: Maybe<Claim>;
  claim_pricing_info?: Maybe<ClaimPricingRepricingInformation>;
  contract_info?: Maybe<ContractInformation>;
  crc?: Maybe<ConditionIndicatorSegment>;
  dates?: Maybe<Array<Maybe<Date>>>;
  diagnosis?: Maybe<Array<Maybe<Diagnosis>>>;
  file_info?: Maybe<Array<Maybe<FileInformation>>>;
  institutional_claim_code?: Maybe<InstitutionalClaimCode>;
  line_items?: Maybe<Array<Maybe<LineItem837I>>>;
  loop_metadata?: Maybe<X12Metadata>;
  notes?: Maybe<Array<Maybe<SpecialInstruction>>>;
  operating_physician_name?: Maybe<Physician_Provider>;
  other_operating_physician?: Maybe<Physician_Provider>;
  other_subscriber_info?: Maybe<Array<Maybe<OtherSubscriberInfo837I>>>;
  pwk?: Maybe<Array<Maybe<ClaimSupplementalInformation>>>;
  reference?: Maybe<Array<Maybe<Reference>>>;
  referring_provider?: Maybe<Physician_Provider>;
  rendering_provider_name?: Maybe<Physician_Provider>;
  service_facility_location?: Maybe<ServiceFacilityLocation837I>;
};

export type ClaimInformation837IInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  attending_provider?: InputMaybe<AttendingProviderInput>;
  claim?: InputMaybe<ClaimInput837I>;
  claim_pricing_info?: InputMaybe<ClaimPricingRepricingInformationInput>;
  contract_info?: InputMaybe<ContractInformationInput>;
  crc?: InputMaybe<ConditionIndicatorSegmentInput>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  diagnosis?: InputMaybe<Array<InputMaybe<DiagnosisInput>>>;
  file_info?: InputMaybe<Array<InputMaybe<FileInformationInput>>>;
  institutional_claim_code?: InputMaybe<InstitutionalClaimCodeInput>;
  line_items?: InputMaybe<Array<InputMaybe<LineItem837IInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  notes?: InputMaybe<Array<InputMaybe<SpecialInstructionInput>>>;
  operating_physician_name?: InputMaybe<Physician_ProviderInput>;
  other_operating_physician?: InputMaybe<Physician_ProviderInput>;
  other_subscriber_info?: InputMaybe<Array<InputMaybe<OtherSubscriberInfo837IInput>>>;
  pwk?: InputMaybe<Array<InputMaybe<ClaimSupplementalInformationInput>>>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  referring_provider?: InputMaybe<Physician_ProviderInput>;
  rendering_provider_name?: InputMaybe<Physician_ProviderInput>;
  service_facility_location?: InputMaybe<ServiceFacilityLocation837IInput>;
};

export type ClaimInformationInput = {
  ambulance_certification?: InputMaybe<AmbulanceCertificationInput>;
  ambulance_dropoff_location?: InputMaybe<AmbulanceDropoffLocationInput>;
  ambulance_pickup_location?: InputMaybe<AmbulancePickupLocationInput>;
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  chiropractic_certification?: InputMaybe<ChiropracticCertificationInput>;
  claim?: InputMaybe<ClaimInput>;
  claim_pricing_info?: InputMaybe<ClaimPricingRepricingInformationInput>;
  conditions?: InputMaybe<Array<InputMaybe<ConditionIndicatorSegmentInput>>>;
  contract_info?: InputMaybe<ContractInformationInput>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  diagnoses?: InputMaybe<Array<InputMaybe<DiagnosisInput>>>;
  file_info?: InputMaybe<Array<InputMaybe<FileInformationInput>>>;
  line_items?: InputMaybe<Array<InputMaybe<LineItemInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  notes?: InputMaybe<Array<InputMaybe<SpecialInstructionInput>>>;
  other_subscriber_info?: InputMaybe<Array<InputMaybe<OtherSubscriberInfoInput>>>;
  pwk?: InputMaybe<Array<InputMaybe<ClaimSupplementalInformationInput>>>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  referring_providers?: InputMaybe<Array<InputMaybe<ReferringProviderInput>>>;
  rendering_provider?: InputMaybe<RenderingProviderInput>;
  service_facility_location?: InputMaybe<ServiceFacilityLocationInput>;
  supervising_provider?: InputMaybe<SupervisingProviderInput>;
};

export type ClaimInput = {
  claim_amount?: InputMaybe<FloatWrapperInput>;
  claim_filing_indicator_code?: InputMaybe<Scalars['String']>;
  claim_identifier?: InputMaybe<Scalars['String']>;
  claim_status_code?: InputMaybe<Scalars['String']>;
  claim_submission_reason_code?: InputMaybe<Scalars['String']>;
  delay_reason_code?: InputMaybe<X12CodeInput>;
  level_of_service?: InputMaybe<Scalars['String']>;
  non_institutional_claim_type_code?: InputMaybe<Scalars['String']>;
  patient_signature_source_code?: InputMaybe<X12CodeInput>;
  provider_accept_assignment?: InputMaybe<X12CodeInput>;
  provider_agreement_code?: InputMaybe<Scalars['String']>;
  provider_benefits_assignment?: InputMaybe<X12CodeInput>;
  provider_signature_indicator?: InputMaybe<X12CodeInput>;
  related_causes_information?: InputMaybe<RelatedCausesInfoInput>;
  release_of_information_code?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  service_location?: InputMaybe<HealthCareServiceLocationInput>;
  special_program_code?: InputMaybe<Scalars['String']>;
  yes_no?: InputMaybe<X12CodeInput>;
  yes_no_2?: InputMaybe<X12CodeInput>;
  yes_no_3?: InputMaybe<X12CodeInput>;
};

export type ClaimInput837I = {
  claim_amount?: InputMaybe<FloatWrapperInput837I>;
  claim_filing_indicator_code?: InputMaybe<Scalars['String']>;
  claim_identifier?: InputMaybe<Scalars['String']>;
  claim_status_code?: InputMaybe<Scalars['String']>;
  claim_submission_reason_code?: InputMaybe<Scalars['String']>;
  delay_reason_code?: InputMaybe<X12CodeInput>;
  level_of_service?: InputMaybe<Scalars['String']>;
  non_institutional_claim_type_code?: InputMaybe<Scalars['String']>;
  patient_signature_source_code?: InputMaybe<X12CodeInput>;
  provider_accept_assignment?: InputMaybe<X12CodeInput>;
  provider_agreement_code?: InputMaybe<Scalars['String']>;
  provider_benefits_assignment?: InputMaybe<X12CodeInput>;
  provider_signature_indicator?: InputMaybe<X12CodeInput>;
  related_causes_information?: InputMaybe<RelatedCausesInfoInput>;
  release_of_information_code?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  service_location?: InputMaybe<HealthCareServiceLocationInput>;
  special_program_code?: InputMaybe<Scalars['String']>;
  yes_no?: InputMaybe<X12CodeInput>;
  yes_no_2?: InputMaybe<X12CodeInput>;
  yes_no_3?: InputMaybe<X12CodeInput>;
};

export type ClaimPaymentInfoLoop = {
  __typename?: 'ClaimPaymentInfoLoop';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  claim_adjustments?: Maybe<Array<Maybe<LineAdjustment>>>;
  claim_payment_info?: Maybe<ClaimPaymentInformation>;
  contact_infos?: Maybe<Array<Maybe<ContactInfo>>>;
  date_times?: Maybe<Array<Maybe<DateTimeReference>>>;
  inpatient_adjudication?: Maybe<InpatientAdjudicationInformation>;
  loop_metadata?: Maybe<X12Metadata>;
  names?: Maybe<Array<Maybe<Name>>>;
  outpatient_adjudication?: Maybe<OutpatientAdjudicationInformation>;
  qty?: Maybe<Array<Maybe<Quantity>>>;
  references?: Maybe<Array<Maybe<Reference>>>;
  svc_payment_info_loop?: Maybe<Array<Maybe<ServicePaymentInfoLoop>>>;
};

export type ClaimPaymentInfoLoopInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  claim_adjustments?: InputMaybe<Array<InputMaybe<LineAdjustmentInput>>>;
  claim_payment_info?: InputMaybe<ClaimPaymentInformationInput>;
  contact_infos?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  date_times?: InputMaybe<Array<InputMaybe<DateTimeReferenceInput>>>;
  inpatient_adjudication?: InputMaybe<InpatientAdjudicationInformationInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  names?: InputMaybe<Array<InputMaybe<NameInput>>>;
  outpatient_adjudication?: InputMaybe<OutpatientAdjudicationInformationInput>;
  qty?: InputMaybe<Array<InputMaybe<QuantityInput>>>;
  references?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  svc_payment_info_loop?: InputMaybe<Array<InputMaybe<ServicePaymentInfoLoopInput>>>;
};

export type ClaimPaymentInformation = {
  __typename?: 'ClaimPaymentInformation';
  claim_charge_amount?: Maybe<FloatWrapper>;
  claim_filing_indicator_code?: Maybe<Scalars['String']>;
  claim_frequency_code?: Maybe<Scalars['String']>;
  claim_payment_amount?: Maybe<FloatWrapper>;
  claim_status_code?: Maybe<Scalars['String']>;
  discharge_fraction?: Maybe<FloatWrapper>;
  drg_code?: Maybe<Scalars['String']>;
  drg_weight?: Maybe<FloatWrapper>;
  facility_code?: Maybe<Scalars['String']>;
  patient_control_number?: Maybe<Scalars['String']>;
  patient_responsibility_amount?: Maybe<FloatWrapper>;
  patient_status_code?: Maybe<Scalars['String']>;
  payer_control_number?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  yes_no?: Maybe<Scalars['String']>;
};

export type ClaimPaymentInformationInput = {
  claim_charge_amount?: InputMaybe<FloatWrapperInput>;
  claim_filing_indicator_code?: InputMaybe<Scalars['String']>;
  claim_frequency_code?: InputMaybe<Scalars['String']>;
  claim_payment_amount?: InputMaybe<FloatWrapperInput>;
  claim_status_code?: InputMaybe<Scalars['String']>;
  discharge_fraction?: InputMaybe<FloatWrapperInput>;
  drg_code?: InputMaybe<Scalars['String']>;
  drg_weight?: InputMaybe<FloatWrapperInput>;
  facility_code?: InputMaybe<Scalars['String']>;
  patient_control_number?: InputMaybe<Scalars['String']>;
  patient_responsibility_amount?: InputMaybe<FloatWrapperInput>;
  patient_status_code?: InputMaybe<Scalars['String']>;
  payer_control_number?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  yes_no?: InputMaybe<Scalars['String']>;
};

export type ClaimPricingRepricingInformation = {
  __typename?: 'ClaimPricingRepricingInformation';
  approved_service_unit_count?: Maybe<FloatWrapper>;
  exception_code?: Maybe<X12Code>;
  flat_rate_amount?: Maybe<FloatWrapper>;
  policy_compliance_code?: Maybe<X12Code>;
  pricing_methodology?: Maybe<X12Code>;
  product_service_id_2?: Maybe<Scalars['String']>;
  product_service_id_qualifier?: Maybe<Scalars['String']>;
  reject_reason_code?: Maybe<X12Code>;
  repriced_allowed_amount?: Maybe<FloatWrapper>;
  repriced_approved_amount?: Maybe<FloatWrapper>;
  repriced_approved_drg_code?: Maybe<Scalars['String']>;
  repriced_approved_revenue_code?: Maybe<Scalars['String']>;
  repriced_saving_amount?: Maybe<FloatWrapper>;
  repricing_organization_identifier?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  unit_measurement_code?: Maybe<X12Code>;
};

export type ClaimPricingRepricingInformationInput = {
  approved_service_unit_count?: InputMaybe<FloatWrapperInput>;
  exception_code?: InputMaybe<X12CodeInput>;
  flat_rate_amount?: InputMaybe<FloatWrapperInput>;
  policy_compliance_code?: InputMaybe<X12CodeInput>;
  pricing_methodology?: InputMaybe<X12CodeInput>;
  product_service_id_2?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier?: InputMaybe<Scalars['String']>;
  reject_reason_code?: InputMaybe<X12CodeInput>;
  repriced_allowed_amount?: InputMaybe<FloatWrapperInput>;
  repriced_approved_amount?: InputMaybe<FloatWrapperInput>;
  repriced_approved_drg_code?: InputMaybe<Scalars['String']>;
  repriced_approved_revenue_code?: InputMaybe<Scalars['String']>;
  repriced_saving_amount?: InputMaybe<FloatWrapperInput>;
  repricing_organization_identifier?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  unit_measurement_code?: InputMaybe<X12CodeInput>;
};

export enum ClaimSource {
  CsClaimOpsPortal = 'CS_CLAIM_OPS_PORTAL',
  CsEdi837I = 'CS_EDI837I',
  CsEdi837P = 'CS_EDI837P',
  CsNetworkAdminPortal = 'CS_NETWORK_ADMIN_PORTAL',
  CsProviderPortal = 'CS_PROVIDER_PORTAL',
  CsUnknown = 'CS_UNKNOWN',
}

export type ClaimSupplementalInformation = {
  __typename?: 'ClaimSupplementalInformation';
  actions_indicated?: Maybe<ActionsIndicated>;
  attachment_control_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['String']>;
  identification_code_qualifier?: Maybe<Scalars['String']>;
  report_copies_needed?: Maybe<Scalars['String']>;
  report_transmission_code?: Maybe<X12Code>;
  report_type_code?: Maybe<X12Code>;
  requests_category_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ClaimSupplementalInformationInput = {
  actions_indicated?: InputMaybe<ActionsIndicatedInput>;
  attachment_control_number?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<Scalars['String']>;
  identification_code_qualifier?: InputMaybe<Scalars['String']>;
  report_copies_needed?: InputMaybe<Scalars['String']>;
  report_transmission_code?: InputMaybe<X12CodeInput>;
  report_type_code?: InputMaybe<X12CodeInput>;
  requests_category_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ClaimsProcessingInfo = {
  __typename?: 'ClaimsProcessingInfo';
  edi_file_summary?: Maybe<Edi837FileSummary>;
  invoice_id?: Maybe<Scalars['String']>;
  number_of_claims?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['String']>;
  total_amount_pennies?: Maybe<Scalars['Int']>;
};

export type ClaimsProcessingInfoInput = {
  edi_file_summary?: InputMaybe<Edi837FileSummaryInput>;
  invoice_id?: InputMaybe<Scalars['String']>;
  number_of_claims?: InputMaybe<Scalars['Int']>;
  total_amount?: InputMaybe<Scalars['String']>;
  total_amount_pennies?: InputMaybe<Scalars['Int']>;
};

export enum ClearingHousePreferenceType {
  Availity = 'AVAILITY',
  ChangeHealthcare = 'CHANGE_HEALTHCARE',
  UnknownClearingHouseType = 'UNKNOWN_CLEARING_HOUSE_TYPE',
}

export type CodedMedicalService = {
  __typename?: 'CodedMedicalService';
  code: Scalars['String'];
  codeset: MedicalCodeSetType;
  medical_code?: Maybe<ReferenceCode>;
  medical_code_definition_id: Scalars['ID'];
  modifier_codes?: Maybe<Array<Scalars['String']>>;
};

export type CodedMedicalServiceInput = {
  code: Scalars['String'];
  codeset: MedicalCodeSetType;
  medical_code_definition_id?: InputMaybe<Scalars['ID']>;
  modifier_codes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ComplianceDecision {
  Approved = 'APPROVED',
  ComplianceAlert = 'COMPLIANCE_ALERT',
  Denied = 'DENIED',
  ManualApproved = 'MANUAL_APPROVED',
  ManualDenied = 'MANUAL_DENIED',
  ManualReview = 'MANUAL_REVIEW',
  Rejected = 'REJECTED',
  UnknownComplianceDecision = 'UNKNOWN_COMPLIANCE_DECISION',
}

export type ComplianceInfo = IDocument & {
  __typename?: 'ComplianceInfo';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  for_party_id: Scalars['ID'];
  giact_manual_review: Scalars['Boolean'];
  references?: Maybe<Array<ComplianceReference>>;
};

export type ComplianceReference = IEffectiveDates & {
  __typename?: 'ComplianceReference';
  account_number_last_four: Scalars['String'];
  bank_account_id: Scalars['ID'];
  decision: ComplianceDecision;
  deleted?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  effective_dates?: Maybe<EffectiveDates>;
  raw_vendor_request: Scalars['String'];
  raw_vendor_response: Scalars['String'];
  reference_id: Scalars['ID'];
  reference_type: ComplianceReferenceType;
};

export enum ComplianceReferenceType {
  AlloyEvaluation = 'ALLOY_EVALUATION',
  AlloyManualReview = 'ALLOY_MANUAL_REVIEW',
  GiactVerification = 'GIACT_VERIFICATION',
  UnknownComplianceReference = 'UNKNOWN_COMPLIANCE_REFERENCE',
}

export type CompositeDiagnosisCodePointer = {
  __typename?: 'CompositeDiagnosisCodePointer';
  diagnosis_ptr1?: Maybe<Uint32Wrapper>;
  diagnosis_ptr2?: Maybe<Uint32Wrapper>;
  diagnosis_ptr3?: Maybe<Uint32Wrapper>;
  diagnosis_ptr4?: Maybe<Uint32Wrapper>;
  diagnosis_ptr5?: Maybe<Uint32Wrapper>;
  diagnosis_ptr6?: Maybe<Uint32Wrapper>;
  diagnosis_ptr7?: Maybe<Uint32Wrapper>;
  diagnosis_ptr8?: Maybe<Uint32Wrapper>;
  element_metadata?: Maybe<X12Metadata>;
};

export type CompositeDiagnosisCodePointerInput = {
  diagnosis_ptr1?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr2?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr3?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr4?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr5?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr6?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr7?: InputMaybe<Uint32WrapperInput>;
  diagnosis_ptr8?: InputMaybe<Uint32WrapperInput>;
  element_metadata?: InputMaybe<X12MetadataInput>;
};

export type CompositeEthnicityIdentifier = {
  __typename?: 'CompositeEthnicityIdentifier';
  element_metadata?: Maybe<X12Metadata>;
};

export type CompositeEthnicityIdentifierInput = {
  element_metadata?: InputMaybe<X12MetadataInput>;
};

export type CompositeMedicalProcedure = {
  __typename?: 'CompositeMedicalProcedure';
  element_metadata?: Maybe<X12Metadata>;
  procedure_code?: Maybe<Scalars['String']>;
  procedure_code_description?: Maybe<Scalars['String']>;
  procedure_modifier_1?: Maybe<Scalars['String']>;
  procedure_modifier_2?: Maybe<Scalars['String']>;
  procedure_modifier_3?: Maybe<Scalars['String']>;
  procedure_modifier_4?: Maybe<Scalars['String']>;
  product_service_id_2?: Maybe<Scalars['String']>;
  product_service_id_qualifier?: Maybe<X12Code>;
};

export type CompositeMedicalProcedureInput = {
  element_metadata?: InputMaybe<X12MetadataInput>;
  procedure_code?: InputMaybe<Scalars['String']>;
  procedure_code_description?: InputMaybe<Scalars['String']>;
  procedure_modifier_1?: InputMaybe<Scalars['String']>;
  procedure_modifier_2?: InputMaybe<Scalars['String']>;
  procedure_modifier_3?: InputMaybe<Scalars['String']>;
  procedure_modifier_4?: InputMaybe<Scalars['String']>;
  product_service_id_2?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier?: InputMaybe<X12CodeInput>;
};

export type CompositeReferenceCode = {
  __typename?: 'CompositeReferenceCode';
  element_metadata?: Maybe<X12Metadata>;
  id1?: Maybe<Scalars['String']>;
  id2?: Maybe<Scalars['String']>;
  id3?: Maybe<Scalars['String']>;
  id_qualifier?: Maybe<X12Code>;
  id_qualifier_1?: Maybe<X12Code>;
  id_qualifier_2?: Maybe<X12Code>;
};

export type CompositeReferenceCodeInput = {
  element_metadata?: InputMaybe<X12MetadataInput>;
  id1?: InputMaybe<Scalars['String']>;
  id2?: InputMaybe<Scalars['String']>;
  id3?: InputMaybe<Scalars['String']>;
  id_qualifier?: InputMaybe<X12CodeInput>;
  id_qualifier_1?: InputMaybe<X12CodeInput>;
  id_qualifier_2?: InputMaybe<X12CodeInput>;
};

export type CompositeUnitOfMeasure = {
  __typename?: 'CompositeUnitOfMeasure';
  code_qualifier?: Maybe<X12Code>;
  element_metadata?: Maybe<X12Metadata>;
  exponent_1?: Maybe<Scalars['String']>;
  exponent_2?: Maybe<Scalars['String']>;
  exponent_3?: Maybe<Scalars['String']>;
  exponent_4?: Maybe<Scalars['String']>;
  exponent_5?: Maybe<Scalars['String']>;
  multiplier_1?: Maybe<Scalars['String']>;
  multiplier_2?: Maybe<Scalars['String']>;
  multiplier_3?: Maybe<Scalars['String']>;
  multiplier_4?: Maybe<Scalars['String']>;
  multiplier_5?: Maybe<Scalars['String']>;
  unit_measurement_code_2?: Maybe<Scalars['String']>;
  unit_measurement_code_3?: Maybe<Scalars['String']>;
  unit_measurement_code_4?: Maybe<Scalars['String']>;
  unit_measurement_code_5?: Maybe<Scalars['String']>;
};

export type CompositeUnitOfMeasureInput = {
  code_qualifier?: InputMaybe<X12CodeInput>;
  element_metadata?: InputMaybe<X12MetadataInput>;
  exponent_1?: InputMaybe<Scalars['String']>;
  exponent_2?: InputMaybe<Scalars['String']>;
  exponent_3?: InputMaybe<Scalars['String']>;
  exponent_4?: InputMaybe<Scalars['String']>;
  exponent_5?: InputMaybe<Scalars['String']>;
  multiplier_1?: InputMaybe<Scalars['String']>;
  multiplier_2?: InputMaybe<Scalars['String']>;
  multiplier_3?: InputMaybe<Scalars['String']>;
  multiplier_4?: InputMaybe<Scalars['String']>;
  multiplier_5?: InputMaybe<Scalars['String']>;
  unit_measurement_code_2?: InputMaybe<Scalars['String']>;
  unit_measurement_code_3?: InputMaybe<Scalars['String']>;
  unit_measurement_code_4?: InputMaybe<Scalars['String']>;
  unit_measurement_code_5?: InputMaybe<Scalars['String']>;
};

export type ConditionIndicatorSegment = {
  __typename?: 'ConditionIndicatorSegment';
  code_qualifier?: Maybe<Scalars['String']>;
  condition_code_applies_indicator?: Maybe<X12Code>;
  condition_indicator_1?: Maybe<Scalars['String']>;
  condition_indicator_2?: Maybe<Scalars['String']>;
  condition_indicator_3?: Maybe<Scalars['String']>;
  condition_indicator_4?: Maybe<Scalars['String']>;
  condition_indicator_5?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ConditionIndicatorSegmentInput = {
  code_qualifier?: InputMaybe<Scalars['String']>;
  condition_code_applies_indicator?: InputMaybe<X12CodeInput>;
  condition_indicator_1?: InputMaybe<Scalars['String']>;
  condition_indicator_2?: InputMaybe<Scalars['String']>;
  condition_indicator_3?: InputMaybe<Scalars['String']>;
  condition_indicator_4?: InputMaybe<Scalars['String']>;
  condition_indicator_5?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ConfirmPaymentIntent = {
  id: Scalars['String'];
  paymentMethodID?: InputMaybe<Scalars['String']>;
};

export type ConfirmPaymentIntentResponse = {
  __typename?: 'ConfirmPaymentIntentResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ConflictingBundleException = {
  __typename?: 'ConflictingBundleException';
  bundled_fee_sch_entries: BundledFeeScheduleExceptionEntries;
};

export type Contact = IDocument & {
  __typename?: 'Contact';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  contact_type?: Maybe<ContactType>;
  email_address?: Maybe<EmailAddress>;
  fax_number?: Maybe<FaxNumber>;
  phone_number?: Maybe<PhoneNumber>;
  postal_address?: Maybe<PostalAddress>;
  social_media_handle?: Maybe<SocialMediaHandle>;
  website_address?: Maybe<WebsiteAddress>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  contact_function_code?: Maybe<X12Code>;
  contact_inquiry_reference?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  contact_number_2?: Maybe<Scalars['String']>;
  contact_number_3?: Maybe<Scalars['String']>;
  contact_number_qualifier?: Maybe<X12Code>;
  contact_number_qualifier_2?: Maybe<X12Code>;
  contact_number_qualifier_3?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ContactInfoContact_Inquiry_ReferenceArgs = {
  detokenize?: Scalars['Boolean'];
};

export type ContactInfoContact_NameArgs = {
  detokenize?: Scalars['Boolean'];
};

export type ContactInfoContact_NumberArgs = {
  detokenize?: Scalars['Boolean'];
};

export type ContactInfoContact_Number_2Args = {
  detokenize?: Scalars['Boolean'];
};

export type ContactInfoContact_Number_3Args = {
  detokenize?: Scalars['Boolean'];
};

export type ContactInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_address?: InputMaybe<PostalAddressInput>;
  preferred_name?: InputMaybe<Scalars['String']>;
};

export enum ContactType {
  EdiAddress = 'EDI_ADDRESS',
  EmailAddress = 'EMAIL_ADDRESS',
  FaxNumber = 'FAX_NUMBER',
  PhoneNumber = 'PHONE_NUMBER',
  PostalAddress = 'POSTAL_ADDRESS',
  SocialMediaHandle = 'SOCIAL_MEDIA_HANDLE',
  WebsiteAddress = 'WEBSITE_ADDRESS',
}

export type Context = {
  __typename?: 'Context';
  context_id?: Maybe<ContextId>;
  loop_id_code?: Maybe<Scalars['String']>;
  segment_id_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  segment_position?: Maybe<PositionInSegment>;
  segment_reference?: Maybe<ReferenceInSegment>;
  ts_segment_position?: Maybe<Uint32Wrapper>;
};

export type ContextId = {
  __typename?: 'ContextID';
  context_name?: Maybe<Scalars['String']>;
  context_ref?: Maybe<Scalars['String']>;
  element_metadata?: Maybe<X12Metadata>;
};

export type Contract = IDocument &
  IEffectiveDates & {
    __typename?: 'Contract';
    _id: Scalars['ID'];
    admin_fee_percentage?: Maybe<Scalars['Float']>;
    audit?: Maybe<Audit>;
    contract_type?: Maybe<ContractType>;
    effective_dates?: Maybe<EffectiveDates>;
    features?: Maybe<ContractFeatures>;
    fee_info?: Maybe<FeeInfo>;
    file_upload_ids?: Maybe<Array<Scalars['ID']>>;
    file_upload_objects?: Maybe<Array<FileUpload>>;
    network?: Maybe<Network>;
    payer_contract?: Maybe<PayerContract>;
    payment_terms?: Maybe<PaymentTerms>;
    provider_group_contract?: Maybe<ProviderGroupContract>;
    sponsor_contract?: Maybe<SponsorContract>;
  };

export type ContractCoverage = {
  __typename?: 'ContractCoverage';
  payments?: Maybe<Scalars['Boolean']>;
  pricing?: Maybe<Scalars['Boolean']>;
};

export type ContractCoverageInput = {
  payments?: InputMaybe<Scalars['Boolean']>;
  pricing?: InputMaybe<Scalars['Boolean']>;
};

export type ContractFeatures = {
  __typename?: 'ContractFeatures';
  coverage?: Maybe<ContractCoverage>;
  network_access?: Maybe<NetworkAccess>;
};

export type ContractFeaturesInput = {
  coverage?: InputMaybe<ContractCoverageInput>;
  network_access?: InputMaybe<NetworkAccessInput>;
};

export type ContractFilter = {
  as_of_date?: InputMaybe<Scalars['String']>;
  network_id: Scalars['String'];
  organization_name?: InputMaybe<Scalars['String']>;
};

export type ContractInformation = {
  __typename?: 'ContractInformation';
  contact_type_code?: Maybe<X12Code>;
  contract_amount?: Maybe<FloatWrapper>;
  contract_code?: Maybe<Scalars['String']>;
  contract_percentage?: Maybe<FloatWrapper>;
  contract_version_identifier?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  terms_discount_percent?: Maybe<Scalars['String']>;
};

export type ContractInformationInput = {
  contact_type_code?: InputMaybe<X12CodeInput>;
  contract_amount?: InputMaybe<FloatWrapperInput>;
  contract_code?: InputMaybe<Scalars['String']>;
  contract_percentage?: InputMaybe<FloatWrapperInput>;
  contract_version_identifier?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  terms_discount_percent?: InputMaybe<Scalars['String']>;
};

export type ContractInput = {
  contract_type: ContractType;
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  features?: InputMaybe<ContractFeaturesInput>;
  fee_info?: InputMaybe<FeeInfoInput>;
  file_upload_ids?: InputMaybe<Array<Scalars['ID']>>;
  payer_contract?: InputMaybe<PayerContractInput>;
  payment_terms?: InputMaybe<PaymentTermsInput>;
  provider_group_contract?: InputMaybe<ProviderGroupContractInput>;
  sponsor_contract?: InputMaybe<SponsorContractInput>;
};

export enum ContractType {
  CtPayerContract = 'CT_PAYER_CONTRACT',
  CtProviderGroupContract = 'CT_PROVIDER_GROUP_CONTRACT',
  CtSponsorContract = 'CT_SPONSOR_CONTRACT',
  CtUnknown = 'CT_UNKNOWN',
}

export type ContractUpdateInput = {
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  fee_info?: InputMaybe<FeeInfoInput>;
  network_access?: InputMaybe<NetworkAccessInput>;
  sponsor_contract?: InputMaybe<UpdateSponsorContractInput>;
};

export enum ContractUploadType {
  CutAmendment = 'CUT_AMENDMENT',
  CutOriginal = 'CUT_ORIGINAL',
  CutUnknown = 'CUT_UNKNOWN',
}

export type Contracts = Paginatable & {
  __typename?: 'Contracts';
  offset: Scalars['Int'];
  results: Array<Contract>;
  total: Scalars['Int'];
};

export type CoordinationOfBenefits = {
  __typename?: 'CoordinationOfBenefits';
  coordination_code?: Maybe<X12Code>;
  payer_responsibility_code?: Maybe<X12Code>;
  reference_id?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  service_code?: Maybe<X12Code>;
};

export type Count = {
  __typename?: 'Count';
  count: Scalars['Int'];
};

export type Coverage = {
  __typename?: 'Coverage';
  count_1?: Maybe<Uint32Wrapper>;
  count_2?: Maybe<Uint32Wrapper>;
  coverage_code?: Maybe<X12Code>;
  description?: Maybe<Scalars['String']>;
  drug_house_code?: Maybe<X12Code>;
  insurance_line_code?: Maybe<X12Code>;
  maintenance_code?: Maybe<X12Code>;
  maintenance_reason_code?: Maybe<X12Code>;
  response_code_1?: Maybe<X12Code>;
  response_code_2?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  underwriting_decision_code?: Maybe<X12Code>;
};

export type CoverageCriteria = {
  __typename?: 'CoverageCriteria';
  expression: Expression;
  not_covered_reason: Scalars['String'];
};

export type CoverageCriteriaInput = {
  expression: ExpressionInput;
  not_covered_reason: Scalars['String'];
};

export enum CoverageInstruction {
  CarrierJudgment = 'CARRIER_JUDGMENT',
  CoverageUnknown = 'COVERAGE_UNKNOWN',
  NonCoveredByMedicare = 'NON_COVERED_BY_MEDICARE',
  NonCoveredByMedicareStatute = 'NON_COVERED_BY_MEDICARE_STATUTE',
  NotPayableByMedicare = 'NOT_PAYABLE_BY_MEDICARE',
  SpecialCoverageInstructionsApply = 'SPECIAL_COVERAGE_INSTRUCTIONS_APPLY',
}

export type CoverageLimit = {
  __typename?: 'CoverageLimit';
  interval_multiple?: Maybe<Scalars['Int']>;
  interval_type: IntervalType;
  limit_type: LimitType;
  max_amount_pennies?: Maybe<Scalars['Int']>;
  max_quantity?: Maybe<Scalars['Int']>;
};

export type CoverageLimitInput = {
  interval_multiple?: InputMaybe<Scalars['Int']>;
  interval_type: IntervalType;
  limit_type: LimitType;
  max_amount_pennies?: InputMaybe<Scalars['Int']>;
  max_quantity?: InputMaybe<Scalars['Int']>;
};

export type CoverageLimitUtilization = {
  __typename?: 'CoverageLimitUtilization';
  amount_pennies_remaining?: Maybe<Scalars['Int']>;
  amount_used_pennies?: Maybe<Scalars['Int']>;
  hash_code: Scalars['String'];
  quantity_remaining?: Maybe<Scalars['Int']>;
  quantity_used?: Maybe<Scalars['Int']>;
  reference_code?: Maybe<ReferenceCodeOrCodeGroup>;
};

export type CoverageProvision = {
  __typename?: 'CoverageProvision';
  action?: Maybe<ProvisionAction>;
  coverage_types?: Maybe<Array<CoverageType>>;
  criteria?: Maybe<Array<CoverageCriteria>>;
  limits?: Maybe<Array<CoverageLimit>>;
  spec?: Maybe<Specification>;
};

export type CoverageProvisionInput = {
  action?: InputMaybe<ProvisionAction>;
  coverage_types?: InputMaybe<Array<CoverageType>>;
  criteria?: InputMaybe<Array<CoverageCriteriaInput>>;
  limits?: InputMaybe<Array<CoverageLimitInput>>;
  spec?: InputMaybe<PreInvoiceSpecificationInput>;
};

export enum CoverageType {
  MemberPlanCard = 'MEMBER_PLAN_CARD',
  PlanAccount = 'PLAN_ACCOUNT',
}

export type CptCodeAndUnits = {
  __typename?: 'CptCodeAndUnits';
  cpt_code: Scalars['String'];
  units: Scalars['String'];
};

export type CptCodeMetadata = {
  __typename?: 'CptCodeMetadata';
  complexity_option: Scalars['String'];
  destination_type: DestinationType;
  drop_down_description: Scalars['String'];
  long_description: Scalars['String'];
  options_name: Scalars['String'];
  plain_english: Scalars['String'];
  region_option: Scalars['String'];
  service_type: Scalars['String'];
  source_type: SourceType;
  time_option: Scalars['String'];
};

export type CptCodeMetadataInput = {
  destination_type?: InputMaybe<DestinationType>;
  source_type?: InputMaybe<SourceType>;
};

export type CptCodesToUnitsInput = {
  cpt_code: Scalars['String'];
  fee_drop_down_desc?: InputMaybe<Scalars['String']>;
  service_type: Scalars['String'];
  units: Scalars['Int'];
};

export type CreateContactInput = {
  contact_type: ContactType;
  email_address?: InputMaybe<EmailAddressInput>;
  phone_number?: InputMaybe<PhoneNumberInput>;
  postal_address?: InputMaybe<PostalAddressInput>;
  website?: InputMaybe<WebsiteAddressInput>;
};

export type CreateEncounterResponse = {
  __typename?: 'CreateEncounterResponse';
  encounter?: Maybe<Encounter>;
  errors: Array<Error>;
};

export type CreateInvoiceInput = {
  line_items?: InputMaybe<Array<ServiceLineInput>>;
  patient_member_id: Scalars['ID'];
  place_of_service_code?: InputMaybe<PlaceOfService>;
  primary_member_id: Scalars['ID'];
  provider_group_id: Scalars['ID'];
};

export type CreateLinkInput = {
  file_upload_type: FileUploadType;
  invoice_id?: InputMaybe<Scalars['ID']>;
  remittance_group_id?: InputMaybe<Scalars['ID']>;
};

export type CreateNewRcmUserByAdmin = {
  email: Scalars['String'];
  names?: InputMaybe<Array<Scalars['String']>>;
  organization_ids?: InputMaybe<Array<Scalars['String']>>;
  policies_accepted?: InputMaybe<Array<PolicyInput>>;
  user_id?: InputMaybe<Scalars['String']>;
  user_roles?: InputMaybe<Array<UserRole>>;
};

export type CreateUserInput = {
  identity_config?: InputMaybe<IdentityConfigurationInput>;
  party_id: Scalars['ID'];
  platform_id?: InputMaybe<Scalars['ID']>;
  platform_name?: InputMaybe<Scalars['String']>;
  role_membership_ids?: InputMaybe<Array<Scalars['ID']>>;
  role_memberships?: InputMaybe<Array<Scalars['String']>>;
  user_roles?: InputMaybe<Array<UserRole>>;
};

export type Currency = {
  __typename?: 'Currency';
  currency_code?: Maybe<Scalars['String']>;
  currency_code_2?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  date_2?: Maybe<Scalars['String']>;
  date_3?: Maybe<Scalars['String']>;
  date_4?: Maybe<Scalars['String']>;
  date_5?: Maybe<Scalars['String']>;
  date_qualifier?: Maybe<Scalars['String']>;
  date_qualifier_2?: Maybe<Scalars['String']>;
  date_qualifier_3?: Maybe<Scalars['String']>;
  date_qualifier_4?: Maybe<Scalars['String']>;
  date_qualifier_5?: Maybe<Scalars['String']>;
  entity_id?: Maybe<X12Code>;
  entity_id_2?: Maybe<X12Code>;
  exchange_rate?: Maybe<FloatWrapper>;
  market_exchange_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  time?: Maybe<Scalars['String']>;
  time_2?: Maybe<Scalars['String']>;
  time_3?: Maybe<Scalars['String']>;
  time_4?: Maybe<Scalars['String']>;
  time_5?: Maybe<Scalars['String']>;
};

export type CurrencyInput = {
  currency_code?: InputMaybe<Scalars['String']>;
  currency_code_2?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  date_2?: InputMaybe<Scalars['String']>;
  date_3?: InputMaybe<Scalars['String']>;
  date_4?: InputMaybe<Scalars['String']>;
  date_5?: InputMaybe<Scalars['String']>;
  date_qualifier?: InputMaybe<Scalars['String']>;
  date_qualifier_2?: InputMaybe<Scalars['String']>;
  date_qualifier_3?: InputMaybe<Scalars['String']>;
  date_qualifier_4?: InputMaybe<Scalars['String']>;
  date_qualifier_5?: InputMaybe<Scalars['String']>;
  entity_id?: InputMaybe<X12CodeInput>;
  entity_id_2?: InputMaybe<X12CodeInput>;
  exchange_rate?: InputMaybe<FloatWrapperInput>;
  market_exchange_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  time?: InputMaybe<Scalars['String']>;
  time_2?: InputMaybe<Scalars['String']>;
  time_3?: InputMaybe<Scalars['String']>;
  time_4?: InputMaybe<Scalars['String']>;
  time_5?: InputMaybe<Scalars['String']>;
};

export type CurrentNameInput = {
  names: Array<Scalars['String']>;
  names_unified: Array<Scalars['String']>;
  preferred_name?: InputMaybe<Scalars['String']>;
};

export type DmeCertification = {
  __typename?: 'DMECertification';
  certification_type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration_length?: Maybe<Scalars['String']>;
  duration_units?: Maybe<X12Code>;
  insulin_dependent_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type DmeCertificationInput = {
  certification_type?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration_length?: InputMaybe<Scalars['String']>;
  duration_units?: InputMaybe<X12CodeInput>;
  insulin_dependent_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type DmeService = {
  __typename?: 'DMEService';
  composite_medical_procedure_identifier?: Maybe<CompositeMedicalProcedure>;
  duration?: Maybe<Scalars['String']>;
  duration_units?: Maybe<X12Code>;
  frequency_code?: Maybe<Scalars['String']>;
  prognosis_code?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['String']>;
  rental_price?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type DmeServiceInput = {
  composite_medical_procedure_identifier?: InputMaybe<CompositeMedicalProcedureInput>;
  duration?: InputMaybe<Scalars['String']>;
  duration_units?: InputMaybe<X12CodeInput>;
  frequency_code?: InputMaybe<Scalars['String']>;
  prognosis_code?: InputMaybe<Scalars['String']>;
  purchase_price?: InputMaybe<Scalars['String']>;
  rental_price?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum DataSource {
  DsEpisode6 = 'DS_EPISODE6',
  DsEveryoneHealthPortal = 'DS_EVERYONE_HEALTH_PORTAL',
  DsGarfield = 'DS_GARFIELD',
  DsIncrease = 'DS_INCREASE',
  DsUnit21 = 'DS_UNIT21',
  DsUnknown = 'DS_UNKNOWN',
}

export type Date = {
  __typename?: 'Date';
  date?: Maybe<Scalars['String']>;
  date_format?: Maybe<X12Code>;
  date_qualifier?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type DateInput = {
  date?: InputMaybe<Scalars['String']>;
  date_format?: InputMaybe<X12CodeInput>;
  date_qualifier?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type DateTimeReference = {
  __typename?: 'DateTimeReference';
  date?: Maybe<Scalars['String']>;
  date_qualifier?: Maybe<Scalars['String']>;
  date_time_period?: Maybe<Scalars['String']>;
  date_time_period_qualifier?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  time?: Maybe<Scalars['String']>;
  time_code?: Maybe<Scalars['String']>;
};

export type DateTimeReferenceInput = {
  date?: InputMaybe<Scalars['String']>;
  date_qualifier?: InputMaybe<Scalars['String']>;
  date_time_period?: InputMaybe<Scalars['String']>;
  date_time_period_qualifier?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  time?: InputMaybe<Scalars['String']>;
  time_code?: InputMaybe<Scalars['String']>;
};

export type DeductibleSpecification = {
  __typename?: 'DeductibleSpecification';
  deductible_limit_type: LimitType;
  max_amount_pennies?: Maybe<Scalars['Int']>;
};

export type DeductibleSpecificationInput = {
  deductible_limit_type: LimitType;
  max_amount_pennies?: InputMaybe<Scalars['Int']>;
};

export type DeletedBoolResponse = {
  __typename?: 'DeletedBoolResponse';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type Demographic = {
  __typename?: 'Demographic';
  basis_of_verification?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  code_list?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  date_format?: Maybe<X12Code>;
  gender?: Maybe<X12Code>;
  industry_code?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  quantity?: Maybe<FloatWrapper>;
  race_ethnicity?: Maybe<Array<Maybe<CompositeEthnicityIdentifier>>>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type DemographicBirthdateArgs = {
  detokenize?: Scalars['Boolean'];
};

export type DemographicCitizenshipArgs = {
  detokenize?: Scalars['Boolean'];
};

export type DemographicCountryArgs = {
  detokenize?: Scalars['Boolean'];
};

export type DemographicInput = {
  basis_of_verification?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  citizenship?: InputMaybe<Scalars['String']>;
  code_list?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  date_format?: InputMaybe<X12CodeInput>;
  gender?: InputMaybe<X12CodeInput>;
  industry_code?: InputMaybe<Scalars['String']>;
  marital_status?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<FloatWrapperInput>;
  race_ethnicity?: InputMaybe<Array<InputMaybe<CompositeEthnicityIdentifierInput>>>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum DestinationType {
  DestinationTypeUnknown = 'DESTINATION_TYPE_UNKNOWN',
  Internal = 'INTERNAL',
  Ui = 'UI',
}

export type Diagnosis = {
  __typename?: 'Diagnosis';
  diag_code_1?: Maybe<HealthCareCodeInfo>;
  diag_code_2?: Maybe<HealthCareCodeInfo>;
  diag_code_3?: Maybe<HealthCareCodeInfo>;
  diag_code_4?: Maybe<HealthCareCodeInfo>;
  diag_code_5?: Maybe<HealthCareCodeInfo>;
  diag_code_6?: Maybe<HealthCareCodeInfo>;
  diag_code_7?: Maybe<HealthCareCodeInfo>;
  diag_code_8?: Maybe<HealthCareCodeInfo>;
  diag_code_9?: Maybe<HealthCareCodeInfo>;
  diag_code_10?: Maybe<HealthCareCodeInfo>;
  diag_code_11?: Maybe<HealthCareCodeInfo>;
  diag_code_12?: Maybe<HealthCareCodeInfo>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type DiagnosisInput = {
  diag_code_1?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_2?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_3?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_4?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_5?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_6?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_7?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_8?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_9?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_10?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_11?: InputMaybe<HealthCareCodeInfoInput>;
  diag_code_12?: InputMaybe<HealthCareCodeInfoInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum Direction {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
  UnknownDirection = 'UNKNOWN_DIRECTION',
}

export type DisabilityInformation = {
  __typename?: 'DisabilityInformation';
  amount?: Maybe<FloatWrapper>;
  disability_type_code?: Maybe<X12Code>;
  id_qualifier?: Maybe<X12Code>;
  medical_code?: Maybe<Scalars['String']>;
  occupation_code?: Maybe<X12Code>;
  product_option_code?: Maybe<X12Code>;
  quantity?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
  work_intensity_code?: Maybe<X12Code>;
};

export type DocumentError = {
  __typename?: 'DocumentError';
  error_messages?: Maybe<Array<Scalars['String']>>;
  field_name?: Maybe<Scalars['String']>;
};

export type DocumentErrorInput = {
  error_messages?: InputMaybe<Array<Scalars['String']>>;
  field_name?: InputMaybe<Scalars['String']>;
};

export enum DocumentErrorType {
  DocumentRowProcessingErrorType = 'DOCUMENT_ROW_PROCESSING_ERROR_TYPE',
  DocumentValidationErrorType = 'DOCUMENT_VALIDATION_ERROR_TYPE',
  UnknownDocumentErrorType = 'UNKNOWN_DOCUMENT_ERROR_TYPE',
}

export type DocumentSummary = {
  __typename?: 'DocumentSummary';
  document_error_type?: Maybe<DocumentErrorType>;
  document_errors?: Maybe<Array<DocumentError>>;
  roster_id?: Maybe<Scalars['String']>;
  row_id?: Maybe<Scalars['String']>;
  status?: Maybe<ProcessingStatus>;
};

export type DocumentSummaryInput = {
  document_error_type?: InputMaybe<DocumentErrorType>;
  document_errors?: InputMaybe<Array<DocumentErrorInput>>;
  roster_id?: InputMaybe<Scalars['String']>;
  row_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProcessingStatus>;
};

export type DrugId = {
  __typename?: 'DrugId';
  drug_identification?: Maybe<ItemIdentification>;
  drug_quantity?: Maybe<ItemPricingInformation>;
  loop_metadata?: Maybe<X12Metadata>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type DrugIdInput = {
  drug_identification?: InputMaybe<ItemIdentificationInput>;
  drug_quantity?: InputMaybe<ItemPricingInformationInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type DrugIdentification = {
  __typename?: 'DrugIdentification';
  item_info?: Maybe<ItemIdentification>;
  item_pricing_info?: Maybe<ItemPricingInformation>;
  loop_metadata?: Maybe<X12Metadata>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type DrugIdentificationInput = {
  item_info?: InputMaybe<ItemIdentificationInput>;
  item_pricing_info?: InputMaybe<ItemPricingInformationInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type Edi835 = {
  __typename?: 'EDI835';
  fg_trailer?: Maybe<FunctionalGroupTrailer>;
  gs?: Maybe<Gs>;
  ic_trailer?: Maybe<InterchangeControlTrailer>;
  isa?: Maybe<Isa>;
  loop_metadata?: Maybe<X12Metadata>;
  transaction_set?: Maybe<Array<Maybe<TransactionSet835>>>;
};

export type Edi835Input = {
  fg_trailer?: InputMaybe<FunctionalGroupTrailerInput>;
  gs?: InputMaybe<GsInput>;
  ic_trailer?: InputMaybe<InterchangeControlTrailerInput>;
  isa?: InputMaybe<IsaInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  transaction_set?: InputMaybe<Array<InputMaybe<TransactionSet835Input>>>;
};

export type Edi837I = {
  __typename?: 'EDI837I';
  fg_trailer?: Maybe<FunctionalGroupTrailer>;
  gs?: Maybe<Gs>;
  ic_trailer?: Maybe<InterchangeControlTrailer>;
  isa?: Maybe<Isa>;
  loop_metadata?: Maybe<X12Metadata>;
  transaction_set?: Maybe<Array<Maybe<TransactionSet837I>>>;
};

export type Edi837IInput = {
  fg_trailer?: InputMaybe<FunctionalGroupTrailerInput>;
  gs?: InputMaybe<GsInput>;
  ic_trailer?: InputMaybe<InterchangeControlTrailerInput>;
  isa?: InputMaybe<IsaInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  transaction_set?: InputMaybe<Array<InputMaybe<TransactionSet837IInput>>>;
};

export type Edi837P = {
  __typename?: 'EDI837P';
  fg_trailer?: Maybe<FunctionalGroupTrailer>;
  gs?: Maybe<Gs>;
  ic_trailer?: Maybe<InterchangeControlTrailer>;
  isa?: Maybe<Isa>;
  loop_metadata?: Maybe<X12Metadata>;
  transaction_set?: Maybe<Array<Maybe<TransactionSet837>>>;
};

export type Edi837PInput = {
  fg_trailer?: InputMaybe<FunctionalGroupTrailerInput>;
  gs?: InputMaybe<GsInput>;
  ic_trailer?: InputMaybe<InterchangeControlTrailerInput>;
  isa?: InputMaybe<IsaInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  transaction_set?: InputMaybe<Array<InputMaybe<TransactionSet837Input>>>;
};

export type EtlMetadata = IDocument & {
  __typename?: 'ETLMetadata';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  data_source?: Maybe<DataSource>;
  docdb_event?: Maybe<GarfieldEvent>;
  e6_event?: Maybe<Episode6Event>;
  etl_type?: Maybe<EtlType>;
  increase_cursor_event?: Maybe<IncreaseCursorEvent>;
  increase_event?: Maybe<IncreaseEvent>;
  purge_data?: Maybe<Scalars['Boolean']>;
  table_in_snowflake?: Maybe<Scalars['Boolean']>;
  unit21_cursor_event?: Maybe<Unit21CursorEvent>;
};

export type EtlMetadataFilter = {
  datasource?: InputMaybe<DataSource>;
  etl_type?: InputMaybe<EtlType>;
};

export type EtlMetadataInput = {
  _id: Scalars['ID'];
  data_source?: InputMaybe<DataSource>;
  etl_type?: InputMaybe<EtlType>;
  purge_data?: InputMaybe<Scalars['Boolean']>;
  table_in_snowflake?: InputMaybe<Scalars['Boolean']>;
};

export enum EtlType {
  EtlApi = 'ETL_API',
  EtlBatch = 'ETL_BATCH',
  EtlStreaming = 'ETL_STREAMING',
  EtlUnknown = 'ETL_UNKNOWN',
}

export type Edi834FileSummary = {
  __typename?: 'Edi834FileSummary';
  edi_file_errors?: Maybe<Array<Maybe<EdiFileError>>>;
  error_count?: Maybe<Scalars['Int']>;
  total_transaction_sets?: Maybe<Scalars['Int']>;
};

export type Edi834FileSummaryInput = {
  edi_file_errors?: InputMaybe<Array<InputMaybe<EdiFileErrorInput>>>;
  error_count?: InputMaybe<Scalars['Int']>;
  total_transaction_sets?: InputMaybe<Scalars['Int']>;
};

export type Edi835FileSummary = {
  __typename?: 'Edi835FileSummary';
  adjustments_count?: Maybe<Scalars['Int']>;
  edi_file_errors?: Maybe<Array<Maybe<EdiFileError>>>;
  error_count?: Maybe<Scalars['Int']>;
  number_of_claims?: Maybe<Scalars['Int']>;
  total_adjustments?: Maybe<Scalars['Float']>;
  total_credits?: Maybe<Scalars['Float']>;
  total_debits?: Maybe<Scalars['Float']>;
  total_extracted_claims?: Maybe<Scalars['Int']>;
  total_transaction_sets?: Maybe<Scalars['Int']>;
};

export type Edi835FileSummaryInput = {
  adjustments_count?: InputMaybe<Scalars['Int']>;
  edi_file_errors?: InputMaybe<Array<InputMaybe<EdiFileErrorInput>>>;
  error_count?: InputMaybe<Scalars['Int']>;
  number_of_claims?: InputMaybe<Scalars['Int']>;
  total_adjustments?: InputMaybe<Scalars['Float']>;
  total_credits?: InputMaybe<Scalars['Float']>;
  total_debits?: InputMaybe<Scalars['Float']>;
  total_extracted_claims?: InputMaybe<Scalars['Int']>;
  total_transaction_sets?: InputMaybe<Scalars['Int']>;
};

export type Edi837FileSummary = {
  __typename?: 'Edi837FileSummary';
  edi_file_errors?: Maybe<Array<Maybe<EdiFileError>>>;
  error_count?: Maybe<Scalars['Int']>;
  number_of_claims?: Maybe<Scalars['Int']>;
  total_extracted_claims?: Maybe<Scalars['Int']>;
  total_patient_loop_amount?: Maybe<Scalars['Float']>;
  total_patient_loop_claims?: Maybe<Scalars['Int']>;
  total_subscriber_loop_amount?: Maybe<Scalars['Float']>;
  total_subscriber_loop_claims?: Maybe<Scalars['Int']>;
  total_transaction_sets?: Maybe<Scalars['Int']>;
};

export type Edi837FileSummaryInput = {
  edi_file_errors?: InputMaybe<Array<InputMaybe<EdiFileErrorInput>>>;
  error_count?: InputMaybe<Scalars['Int']>;
  number_of_claims?: InputMaybe<Scalars['Int']>;
  total_extracted_claims?: InputMaybe<Scalars['Int']>;
  total_patient_loop_amount?: InputMaybe<Scalars['Float']>;
  total_patient_loop_claims?: InputMaybe<Scalars['Int']>;
  total_subscriber_loop_amount?: InputMaybe<Scalars['Float']>;
  total_subscriber_loop_claims?: InputMaybe<Scalars['Int']>;
  total_transaction_sets?: InputMaybe<Scalars['Int']>;
};

export type EdiFileError = {
  __typename?: 'EdiFileError';
  error_message?: Maybe<Scalars['String']>;
  error_type?: Maybe<Scalars['String']>;
  line_numbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  segment_name?: Maybe<Scalars['String']>;
  transaction_sets?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type EdiFileErrorInput = {
  error_message?: InputMaybe<Scalars['String']>;
  error_type?: InputMaybe<Scalars['String']>;
  line_numbers?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  segment_name?: InputMaybe<Scalars['String']>;
  transaction_sets?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type EditSponsorPlanInput = {
  sponsorId: Scalars['ID'];
  sponsorPlans: Array<SponsorPlanInput>;
};

export type EffectiveDates = {
  __typename?: 'EffectiveDates';
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
};

export type EffectiveDatesInput = {
  from_date?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type EligibilityFileProcessingInfo = {
  __typename?: 'EligibilityFileProcessingInfo';
  edi_file_summary?: Maybe<Edi834FileSummary>;
  eligibility_file_totals?: Maybe<EligibilityFileTotals>;
  eligibility_row_errors?: Maybe<Array<Maybe<EligibilityRowErrors>>>;
  format_type?: Maybe<EligibilityFormatType>;
  group_id?: Maybe<Scalars['String']>;
  sponsor_id?: Maybe<Scalars['String']>;
  total_row_errors?: Maybe<Scalars['Int']>;
};

export type EligibilityFileProcessingInfoInput = {
  edi_file_summary?: InputMaybe<Edi834FileSummaryInput>;
  eligibility_file_totals?: InputMaybe<EligibilityFileTotalsInput>;
  eligibility_row_errors?: InputMaybe<Array<InputMaybe<EligibilityRowErrorsInput>>>;
  format_type?: InputMaybe<EligibilityFormatType>;
  group_id?: InputMaybe<Scalars['String']>;
  sponsor_id?: InputMaybe<Scalars['String']>;
  total_row_errors?: InputMaybe<Scalars['Int']>;
};

export type EligibilityFileTotals = {
  __typename?: 'EligibilityFileTotals';
  member_counts?: Maybe<MemberCounts>;
  missing_dependent_ssn?: Maybe<Scalars['Int']>;
  missing_dependent_uid?: Maybe<Scalars['Int']>;
  missing_primary_ssn?: Maybe<Scalars['Int']>;
  missing_team_member_id?: Maybe<Scalars['Int']>;
  missing_uid?: Maybe<Scalars['Int']>;
  rows_transformed?: Maybe<Scalars['Int']>;
  unique_member_ids?: Maybe<Scalars['Int']>;
};

export type EligibilityFileTotalsInput = {
  member_counts?: InputMaybe<MemberCountsInput>;
  missing_dependent_ssn?: InputMaybe<Scalars['Int']>;
  missing_dependent_uid?: InputMaybe<Scalars['Int']>;
  missing_primary_ssn?: InputMaybe<Scalars['Int']>;
  missing_team_member_id?: InputMaybe<Scalars['Int']>;
  missing_uid?: InputMaybe<Scalars['Int']>;
  rows_transformed?: InputMaybe<Scalars['Int']>;
  unique_member_ids?: InputMaybe<Scalars['Int']>;
};

export enum EligibilityFormatType {
  EftEdi834 = 'EFT_EDI834',
  EftUmrCsv = 'EFT_UMR_CSV',
  EftUnknown = 'EFT_UNKNOWN',
  EftWorkdayCsv = 'EFT_WORKDAY_CSV',
}

export type EligibilityRowErrors = {
  __typename?: 'EligibilityRowErrors';
  error_message?: Maybe<Scalars['String']>;
  error_total_frequency?: Maybe<Scalars['Int']>;
};

export type EligibilityRowErrorsInput = {
  error_message?: InputMaybe<Scalars['String']>;
  error_total_frequency?: InputMaybe<Scalars['Int']>;
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  email: Scalars['String'];
};

export type EmailAddressEmailArgs = {
  detokenize?: Scalars['Boolean'];
};

export type EmailAddressInput = {
  email: Scalars['String'];
};

export type EmploymentClass = {
  __typename?: 'EmploymentClass';
  employment_class_code_1?: Maybe<X12Code>;
  employment_class_code_2?: Maybe<X12Code>;
  employment_class_code_3?: Maybe<X12Code>;
  information_status_code?: Maybe<X12Code>;
  occupation_code?: Maybe<X12Code>;
  pct_as_decimal?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type Encounter = IDocument & {
  __typename?: 'Encounter';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  external_reference_id: Scalars['ID'];
  first_service_date: Scalars['String'];
  invoice_ids?: Maybe<Array<Scalars['ID']>>;
  invoices?: Maybe<Array<Invoice>>;
  latest_service_date: Scalars['String'];
  member?: Maybe<Member>;
  member_id: Scalars['ID'];
  member_party_id: Scalars['ID'];
  practice_facility?: Maybe<PracticeFacility>;
  practice_facility_id: Scalars['ID'];
  provider_group_ids?: Maybe<Array<Scalars['ID']>>;
  service?: Maybe<EncounterService>;
  status: EncounterStatus;
};

export type EncounterAlreadyExists = Error & {
  __typename?: 'EncounterAlreadyExists';
  code: Scalars['Int'];
  message: Scalars['String'];
  status: Scalars['Int'];
  type: Scalars['String'];
};

export type EncounterBillingFacility = {
  __typename?: 'EncounterBillingFacility';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type EncounterBillingFacilityInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type EncounterBillingProvider = {
  __typename?: 'EncounterBillingProvider';
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
};

export type EncounterBillingProviderInput = {
  name?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
};

export type EncounterClaim = {
  __typename?: 'EncounterClaim';
  billing_facility?: Maybe<EncounterBillingFacility>;
  billing_provider?: Maybe<EncounterBillingProvider>;
  cash_payment?: Maybe<Scalars['Boolean']>;
  encounter_id?: Maybe<Scalars['String']>;
  has_patient?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<EncounterPatient>;
  payment_info?: Maybe<PaymentInfo>;
  rendering_facility?: Maybe<EncounterRenderingFacility>;
  rendering_provider?: Maybe<EncounterRenderingProvider>;
  service_lines?: Maybe<Array<Maybe<LineItems>>>;
  subscriber?: Maybe<EncounterSubscriber>;
};

export type EncounterClaimInput = {
  billing_facility?: InputMaybe<EncounterBillingFacilityInput>;
  billing_provider?: InputMaybe<BillingProviderInput>;
  cash_payment?: InputMaybe<Scalars['Boolean']>;
  encounter_id?: InputMaybe<Scalars['String']>;
  has_patient?: InputMaybe<Scalars['Boolean']>;
  patient?: InputMaybe<EncounterPatientInput>;
  payment_info?: InputMaybe<PaymentInfoInput>;
  rendering_facility?: InputMaybe<EncounterRenderingFacilityInput>;
  rendering_provider?: InputMaybe<EncounterRenderingProviderInput>;
  service_lines?: InputMaybe<Array<InputMaybe<LineItemsInput>>>;
  subscriber?: InputMaybe<EncounterSubscriberInput>;
};

export type EncounterCreateInput = {
  external_reference_id?: InputMaybe<Scalars['ID']>;
  first_service_date?: InputMaybe<Scalars['String']>;
  latest_service_date?: InputMaybe<Scalars['String']>;
  member_id: Scalars['ID'];
  member_party_id: Scalars['ID'];
  practice_facility_id: Scalars['ID'];
  service?: InputMaybe<EncounterServiceInput>;
  status: EncounterStatus;
};

export type EncounterFilters = {
  invoice_ids?: InputMaybe<Array<Scalars['ID']>>;
  member_ids?: InputMaybe<Array<Scalars['ID']>>;
  practice_facility_id: Scalars['ID'];
  status: EncounterStatus;
};

export type EncounterPatient = {
  __typename?: 'EncounterPatient';
  address?: Maybe<Address>;
  date_of_birth?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  insured_member_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  social_security_number?: Maybe<Scalars['String']>;
};

export type EncounterPatientInput = {
  address?: InputMaybe<AddressInput>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  insured_member_id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  social_security_number?: InputMaybe<Scalars['String']>;
};

export type EncounterQuery = {
  __typename?: 'EncounterQuery';
  member_id: Scalars['ID'];
  practice_facility_id: Scalars['ID'];
  status: EncounterStatus;
};

export type EncounterRenderingFacility = {
  __typename?: 'EncounterRenderingFacility';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type EncounterRenderingFacilityInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type EncounterRenderingProvider = {
  __typename?: 'EncounterRenderingProvider';
  name?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
};

export type EncounterRenderingProviderInput = {
  name?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
};

export type EncounterService = {
  __typename?: 'EncounterService';
  service_time: Scalars['String'];
};

export type EncounterServiceInput = {
  service_time: Scalars['String'];
};

export enum EncounterStatus {
  CheckedIn = 'CHECKED_IN',
  Closed = 'CLOSED',
  Open = 'OPEN',
  Reopened = 'REOPENED',
}

export type EncounterSubscriber = {
  __typename?: 'EncounterSubscriber';
  address?: Maybe<Address>;
  date_of_birth?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  insured_member_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  social_security_number?: Maybe<Scalars['String']>;
};

export type EncounterSubscriberInput = {
  address?: InputMaybe<AddressInput>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  insured_member_id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  social_security_number?: InputMaybe<Scalars['String']>;
};

export type EncounterUpdateInput = {
  status?: InputMaybe<EncounterStatus>;
};

export type Entitlement = {
  __typename?: 'Entitlement';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type EntitlementOutput = {
  __typename?: 'EntitlementOutput';
  entitlement_active: Scalars['Boolean'];
  msg: Scalars['String'];
  user_id: Scalars['String'];
};

export type EntityName = {
  __typename?: 'EntityName';
  effective_dates?: Maybe<EffectiveDates>;
  name: Scalars['String'];
};

export type EntityNameInput = {
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type Episode6Event = {
  __typename?: 'Episode6Event';
  table?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type Error = {
  code: Scalars['Int'];
  message: Scalars['String'];
  status: Scalars['Int'];
  type: Scalars['String'];
};

export type Event = {
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  event_errors?: Maybe<Array<Scalars['String']>>;
  event_type: Scalars['String'];
};

export enum ExceptionSourceProcess {
  EspClaim = 'ESP_CLAIM',
  EspPayment = 'ESP_PAYMENT',
  EspUnknown = 'ESP_UNKNOWN',
}

export enum ExceptionType {
  EtCritical = 'ET_CRITICAL',
  EtNonCritical = 'ET_NON_CRITICAL',
  EtUnknown = 'ET_UNKNOWN',
}

export type Expression = {
  __typename?: 'Expression';
  lhs: ExpressionVariable;
  operand: ExpressionOperand;
  rhs: Scalars['String'];
};

export type ExpressionInput = {
  lhs: ExpressionVariable;
  operand: ExpressionOperand;
  rhs: Scalars['String'];
};

export enum ExpressionOperand {
  Equals = 'EQUALS',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  In = 'IN',
  Within = 'WITHIN',
}

export enum ExpressionVariable {
  DiagnosisCode = 'DIAGNOSIS_CODE',
  MerchantCode = 'MERCHANT_CODE',
  PerMemberDeductibleSpend = 'PER_MEMBER_DEDUCTIBLE_SPEND',
  ProcedureCode = 'PROCEDURE_CODE',
  ProviderCode = 'PROVIDER_CODE',
  ServiceTypeCode = 'SERVICE_TYPE_CODE',
}

export enum ExternalAccountIdType {
  IncreaseAccount = 'INCREASE_ACCOUNT',
  IncreaseBookkeepingAccount = 'INCREASE_BOOKKEEPING_ACCOUNT',
  Other = 'OTHER',
  StripeBankAccount = 'STRIPE_BANK_ACCOUNT',
}

export enum ExternalAccountType {
  ExternalTypeChecking = 'EXTERNAL_TYPE_CHECKING',
  ExternalTypeSavings = 'EXTERNAL_TYPE_SAVINGS',
  ExternalTypeUnknown = 'EXTERNAL_TYPE_UNKNOWN',
}

export type ExternalEventsMetadata = {
  __typename?: 'ExternalEventsMetadata';
  bookkeeping_event_id: Scalars['String'];
  e6_transaction_id: Scalars['String'];
  increase_transaction_id: Scalars['String'];
  nomi_fee_account_transfer_id: Scalars['String'];
  trace_number: Scalars['String'];
  virtual_card_id: Scalars['String'];
  virtual_card_payment_id: Scalars['String'];
  virtual_ledger_account_id: Scalars['String'];
};

export type ExternalId = IEffectiveDates & {
  __typename?: 'ExternalId';
  effective_dates?: Maybe<EffectiveDates>;
  id_number: Scalars['ID'];
  id_type: ExternalAccountIdType;
  issuer: Scalars['String'];
};

export type ExternalIdInput = {
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  id_number: Scalars['ID'];
  id_type: ExternalAccountIdType;
  issuer: Scalars['String'];
};

export enum ExternalIdSource {
  SrcEhPortal = 'SRC_EH_PORTAL',
  SrcNomiOpenNetwork = 'SRC_NOMI_OPEN_NETWORK',
  SrcOther = 'SRC_OTHER',
  SrcSano = 'SRC_SANO',
  SrcTellus = 'SRC_TELLUS',
}

export enum ExternalIdType {
  TypeFeeScheduleId = 'TYPE_FEE_SCHEDULE_ID',
  TypeOther = 'TYPE_OTHER',
}

export type ExternalIdentityProviderInfo = {
  __typename?: 'ExternalIdentityProviderInfo';
  external_identity_provider_id: Scalars['ID'];
};

export type ExternalInvoiceId = {
  __typename?: 'ExternalInvoiceID';
  id_number: Scalars['String'];
  id_type?: Maybe<ExternalInvoiceIdType>;
};

export enum ExternalInvoiceIdType {
  EdiPatientControlNumber = 'EDI_PATIENT_CONTROL_NUMBER',
  ExternalPricerId = 'EXTERNAL_PRICER_ID',
  Other = 'OTHER',
}

export enum ExternalMemberType {
  MemberId = 'MEMBER_ID',
  MemberSubscriberId = 'MEMBER_SUBSCRIBER_ID',
  Other = 'OTHER',
}

export type ExternalReferenceIdQuery = {
  __typename?: 'ExternalReferenceIDQuery';
  external_reference_id?: Maybe<Scalars['String']>;
};

export type Fake_FinancialAccountInput = {
  account_type?: InputMaybe<Scalars['AccountType']>;
  funding_preference?: InputMaybe<Scalars['FundingPreferenceType']>;
};

export type Facility = IDocument & {
  __typename?: 'Facility';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  contacts?: Maybe<Array<Maybe<FacilityContact>>>;
  location?: Maybe<Location>;
  name?: Maybe<Scalars['String']>;
  persons_at_facility?: Maybe<Array<Maybe<Scalars['String']>>>;
  persons_at_facility_temp?: Maybe<Array<Maybe<PersonsAtFacilityTemp>>>;
  practice_facility?: Maybe<PracticeFacility>;
};

export type FacilityContact = {
  __typename?: 'FacilityContact';
  contact_id: Scalars['ID'];
  contact_model?: Maybe<Contact>;
  effective_dates?: Maybe<EffectiveDates>;
  is_primary?: Maybe<Scalars['Boolean']>;
  purpose?: Maybe<Scalars['String']>;
};

export type FacilityContactInput = {
  contact_id: Scalars['ID'];
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  purpose?: InputMaybe<Scalars['String']>;
};

export type FacilityCreateInput = {
  contacts?: InputMaybe<Array<InputMaybe<FacilityContactInput>>>;
  location_input: LocationInput;
  name?: InputMaybe<Scalars['String']>;
  persons_at_facility?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaxNumber = {
  __typename?: 'FaxNumber';
  fax: Scalars['String'];
};

export type Fee = {
  __typename?: 'Fee';
  amount_pennies: Scalars['Int'];
  fee_reason: FeeReason;
  to_account_id: Scalars['ID'];
};

export type FeeInfo = {
  __typename?: 'FeeInfo';
  fee?: Maybe<Scalars['Float']>;
  fee_percentage?: Maybe<Scalars['Int']>;
  fee_type?: Maybe<FeeType>;
  operator?: Maybe<Scalars['String']>;
  rule_type?: Maybe<RuleType>;
};

export type FeeInfoInput = {
  fee?: InputMaybe<Scalars['Float']>;
  fee_percentage?: InputMaybe<Scalars['Int']>;
  fee_type?: InputMaybe<FeeType>;
  operator?: InputMaybe<Scalars['String']>;
  rule_type?: InputMaybe<RuleType>;
};

export enum FeeReason {
  NomiServiceFee = 'NOMI_SERVICE_FEE',
  StripServiceFee = 'STRIP_SERVICE_FEE',
}

export type FeeSchedule = IDocument & {
  __typename?: 'FeeSchedule';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  fee_line?: Maybe<Array<Maybe<FeeScheduleLine>>>;
  fee_schedule?: Maybe<Array<FeeScheduleService>>;
};

export type FeeScheduleAssignment = {
  __typename?: 'FeeScheduleAssignment';
  effective_dates?: Maybe<EffectiveDates>;
  feeScheduleType?: Maybe<FeeScheduleType>;
  fee_schedule?: Maybe<FeeScheduleV2>;
  fee_schedule_id: Scalars['ID'];
  fee_schedule_options?: Maybe<FeeScheduleOptions>;
  practice_facilities?: Maybe<Array<Maybe<PracticeFacility>>>;
  practice_facilities_assigned?: Maybe<Array<Maybe<Scalars['ID']>>>;
  provider_group_ids_assigned?: Maybe<Array<Maybe<Scalars['ID']>>>;
  provider_groups_assigned?: Maybe<Array<Maybe<ProviderGroup>>>;
};

export type FeeScheduleAssignmentInput = {
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  feeScheduleType?: InputMaybe<FeeScheduleType>;
  fee_schedule_id: Scalars['ID'];
  provider_group_ids_assigned?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum FeeScheduleCodeType {
  FctApcIndicator = 'FCT_APC_INDICATOR',
  FctAscIndicator = 'FCT_ASC_INDICATOR',
  FctCpt = 'FCT_CPT',
  FctDrg = 'FCT_DRG',
  FctRevenueCode = 'FCT_REVENUE_CODE',
  FctStoploss = 'FCT_STOPLOSS',
  FctUnknown = 'FCT_UNKNOWN',
}

export enum FeeScheduleComplexityOption {
  Between_30And_44Minutes = 'BETWEEN_30_AND_44_MINUTES',
  Between_45And_59Minutes = 'BETWEEN_45_AND_59_MINUTES',
  High = 'HIGH',
  LessThan_30Minutes = 'LESS_THAN_30_MINUTES',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NoComplexityOptions = 'NO_COMPLEXITY_OPTIONS',
}

export enum FeeScheduleFormatType {
  FstBundled = 'FST_BUNDLED',
  FstProfessional = 'FST_PROFESSIONAL',
  FstUnknown = 'FST_UNKNOWN',
}

export type FeeScheduleLine = {
  __typename?: 'FeeScheduleLine';
  allowed_amount: Scalars['Float'];
  allowed_amount_pennies?: Maybe<Scalars['Int']>;
  complexity_option?: Maybe<FeeScheduleComplexityOption>;
  cpt_code: Scalars['String'];
  fee_drop_down_desc?: Maybe<Scalars['String']>;
  fee_long_desc: Scalars['String'];
  limits_mins_translation?: Maybe<Scalars['Int']>;
  limits_per_visit?: Maybe<Scalars['Int']>;
  options_name?: Maybe<FeeScheduleOptionName>;
  ref_code_id?: Maybe<Scalars['String']>;
  region_option?: Maybe<FeeScheduleRegionOption>;
  service_type: FeeScheduleServiceType;
  time_option?: Maybe<FeeScheduleTimeOption>;
};

export enum FeeScheduleOptionName {
  Complexity = 'COMPLEXITY',
  NoOption = 'NO_OPTION',
  Region = 'REGION',
  Time = 'TIME',
  Unit = 'UNIT',
}

export type FeeScheduleOptions = {
  __typename?: 'FeeScheduleOptions';
  count?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FeeSchedulePriorityEntries = {
  __typename?: 'FeeSchedulePriorityEntries';
  claim_bill_type?: Maybe<ClaimBillType>;
  priority_entries?: Maybe<Array<FeeSchedulePriorityEntry>>;
  priority_type?: Maybe<FeeSchedulePriorityEntriesType>;
};

export type FeeSchedulePriorityEntriesInput = {
  claim_bill_type?: InputMaybe<ClaimBillType>;
  priority_entries?: InputMaybe<Array<FeeSchedulePriorityEntryInput>>;
  priority_type?: InputMaybe<FeeSchedulePriorityEntriesType>;
};

export enum FeeSchedulePriorityEntriesType {
  FpeInstitutionalCarveOuts = 'FPE_INSTITUTIONAL_CARVE_OUTS',
  FpeProfessionalPriorityEntries = 'FPE_PROFESSIONAL_PRIORITY_ENTRIES',
  FpeUnknown = 'FPE_UNKNOWN',
}

export type FeeSchedulePriorityEntry = {
  __typename?: 'FeeSchedulePriorityEntry';
  allowed_amount_pennies?: Maybe<Scalars['Int']>;
  code_type?: Maybe<FeeScheduleCodeType>;
  medical_code_group_ids?: Maybe<Array<Scalars['String']>>;
  medical_code_groups?: Maybe<Array<Maybe<MedicalCodeGroup>>>;
  percentage_factor?: Maybe<Scalars['Float']>;
  rate_type?: Maybe<RateType>;
  reference_code_id?: Maybe<Array<Scalars['String']>>;
  threshold_amount_pennies?: Maybe<Scalars['Int']>;
};

export type FeeSchedulePriorityEntryInput = {
  allowed_amount_pennies?: InputMaybe<Scalars['Int']>;
  code_type?: InputMaybe<FeeScheduleCodeType>;
  codes_specifications?: InputMaybe<Array<Scalars['String']>>;
  percentage_factor?: InputMaybe<Scalars['Float']>;
  rate_type?: InputMaybe<RateType>;
  threshold_amount_pennies?: InputMaybe<Scalars['Int']>;
};

export type FeeScheduleProcessingInfo = {
  __typename?: 'FeeScheduleProcessingInfo';
  audit_logs?: Maybe<Array<FeeScheduleUploadAuditLog>>;
  contract_id?: Maybe<Scalars['String']>;
  effective_dates?: Maybe<EffectiveDates>;
  error?: Maybe<Scalars['Boolean']>;
  error_log?: Maybe<Scalars['String']>;
  facility_npis?: Maybe<Array<Maybe<Scalars['String']>>>;
  fee_schedule_type?: Maybe<FeeScheduleFormatType>;
  fee_schedule_upload_state?: Maybe<FeeScheduleUploadState>;
  party_id?: Maybe<Scalars['String']>;
  processed_count?: Maybe<Scalars['Int']>;
  provider_group_id?: Maybe<Scalars['String']>;
  provider_group_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  total_records?: Maybe<Scalars['Int']>;
  upload_name?: Maybe<Scalars['String']>;
};

export type FeeScheduleProcessingInfoInput = {
  audit_logs?: InputMaybe<Array<FeeScheduleUploadAuditLogInput>>;
  contract_id?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['Boolean']>;
  error_log?: InputMaybe<Scalars['String']>;
  facility_npis?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fee_schedule_type?: InputMaybe<FeeScheduleFormatType>;
  fee_schedule_upload_state?: InputMaybe<FeeScheduleUploadState>;
  party_id?: InputMaybe<Scalars['String']>;
  processed_count?: InputMaybe<Scalars['Int']>;
  provider_group_id?: InputMaybe<Scalars['String']>;
  provider_group_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  total_records?: InputMaybe<Scalars['Int']>;
  upload_name?: InputMaybe<Scalars['String']>;
};

export enum FeeScheduleRegionOption {
  FiveRegions = 'FIVE_REGIONS',
  NoRegionOptions = 'NO_REGION_OPTIONS',
  OneOrMoreRegions = 'ONE_OR_MORE_REGIONS',
  OneOrTwoRegions = 'ONE_OR_TWO_REGIONS',
  ThreeOrFourRegions = 'THREE_OR_FOUR_REGIONS',
}

export type FeeScheduleService = {
  __typename?: 'FeeScheduleService';
  service_descriptions?: Maybe<Array<ServiceDescription>>;
  service_name: Scalars['String'];
  service_name_ui: Scalars['String'];
};

export enum FeeScheduleServiceType {
  Acupuncture = 'ACUPUNCTURE',
  Chiropractic = 'CHIROPRACTIC',
  MassageTherapy = 'MASSAGE_THERAPY',
  Naturopathic = 'NATUROPATHIC',
  NotAvailable = 'NOT_AVAILABLE',
  NutritionalCounseling = 'NUTRITIONAL_COUNSELING',
}

export enum FeeScheduleTimeOption {
  AdditionalTime = 'ADDITIONAL_TIME',
  FirstTime = 'FIRST_TIME',
  MultipleTime = 'MULTIPLE_TIME',
  NoTimeOptions = 'NO_TIME_OPTIONS',
}

export enum FeeScheduleType {
  FtBundled = 'FT_BUNDLED',
  FtInstitutional = 'FT_INSTITUTIONAL',
  FtProfessional = 'FT_PROFESSIONAL',
  FtProfessionalRbp = 'FT_PROFESSIONAL_RBP',
  FtUnknown = 'FT_UNKNOWN',
}

export type FeeScheduleUploadAuditLog = {
  __typename?: 'FeeScheduleUploadAuditLog';
  fee_schedule_upload_state_transition?: Maybe<FeeScheduleUploadStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export type FeeScheduleUploadAuditLogInput = {
  fee_schedule_upload_state_transition?: InputMaybe<FeeScheduleUploadStateTransitionInput>;
  transitioned_at_time?: InputMaybe<Scalars['String']>;
};

export enum FeeScheduleUploadState {
  FsuAssociatedToPracticeFacilities = 'FSU_ASSOCIATED_TO_PRACTICE_FACILITIES',
  FsuCreated = 'FSU_CREATED',
  FsuFeeScheduleCreated = 'FSU_FEE_SCHEDULE_CREATED',
  FsuReferenceCodesCreated = 'FSU_REFERENCE_CODES_CREATED',
  FsuUnknown = 'FSU_UNKNOWN',
}

export type FeeScheduleUploadStateTransition = {
  __typename?: 'FeeScheduleUploadStateTransition';
  from_state?: Maybe<FeeScheduleUploadState>;
  to_state?: Maybe<FeeScheduleUploadState>;
};

export type FeeScheduleUploadStateTransitionInput = {
  from_state?: InputMaybe<FeeScheduleUploadState>;
  to_state?: InputMaybe<FeeScheduleUploadState>;
};

export type FeeScheduleV2 = IDocument & {
  __typename?: 'FeeScheduleV2';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  bundled_fee_sch_entries?: Maybe<BundledFeeScheduleEntries>;
  external_id?: Maybe<FeeScheduleV2ExternalId>;
  fee_schedule_type?: Maybe<FeeScheduleType>;
  file_upload_id: Scalars['ID'];
  institutional_fee_sch_entries?: Maybe<InstitutionalFeeScheduleEntries>;
  professional_fee_sch_entries?: Maybe<ProfessionalFeeScheduleEntries>;
  professional_rbp_fee_sch_entries?: Maybe<ProfessionalRbpFeeScheduleEntries>;
  related_provider_groups?: Maybe<Array<ProviderGroup>>;
};

export type FeeScheduleV2ExternalId = {
  __typename?: 'FeeScheduleV2ExternalId';
  id_number?: Maybe<Scalars['String']>;
  id_source?: Maybe<ExternalIdSource>;
  id_type?: Maybe<ExternalIdType>;
};

export type FeeScheduleV2Input = {
  bundled_fee_sch_entries?: InputMaybe<BundledFeeScheduleEntriesInput>;
  contract_id?: InputMaybe<Scalars['String']>;
  effective_from?: InputMaybe<Scalars['String']>;
  fee_schedule_type?: InputMaybe<FeeScheduleType>;
  institutional_fee_sch_entries?: InputMaybe<InstitutionalFeeScheduleEntriesInput>;
  network_id: Scalars['String'];
  party_id: Scalars['String'];
  professional_fee_sch_entries?: InputMaybe<ProfessionalFeeScheduleEntriesInput>;
  professional_rbp_fee_sch_entries?: InputMaybe<ProfessionalRbpFeeScheduleEntriesInput>;
  provider_group_ids?: InputMaybe<Array<Scalars['String']>>;
  replacing_corrupted_fs?: InputMaybe<Scalars['Boolean']>;
};

export enum FeeType {
  CftNetworkFee = 'CFT_NETWORK_FEE',
  CftServiceFee = 'CFT_SERVICE_FEE',
  CftTransactionFee = 'CFT_TRANSACTION_FEE',
  CftUnknown = 'CFT_UNKNOWN',
}

export enum FileExtensionType {
  Csv = 'CSV',
  Docx = 'DOCX',
  Json = 'JSON',
  Pdf = 'PDF',
  Xlsx = 'XLSX',
}

export enum FileFormatType {
  FftCsv = 'FFT_CSV',
  FftDocx = 'FFT_DOCX',
  FftEdi834 = 'FFT_EDI834',
  FftEdi835 = 'FFT_EDI835',
  FftEdi837I = 'FFT_EDI837I',
  FftEdi837P = 'FFT_EDI837P',
  FftJson = 'FFT_JSON',
  FftPdf = 'FFT_PDF',
  FftUnknown = 'FFT_UNKNOWN',
  FftXlsx = 'FFT_XLSX',
}

export type FileInformation = {
  __typename?: 'FileInformation';
  composite_unit_of_measure?: Maybe<Scalars['String']>;
  fixed_format_information?: Maybe<Scalars['String']>;
  record_format_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type FileInformationInput = {
  composite_unit_of_measure?: InputMaybe<Scalars['String']>;
  fixed_format_information?: InputMaybe<Scalars['String']>;
  record_format_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type FileUpload = IDocument & {
  __typename?: 'FileUpload';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  bucket_name?: Maybe<Scalars['String']>;
  claims_processing_info?: Maybe<ClaimsProcessingInfo>;
  direction?: Maybe<Direction>;
  eligibility_file_processing_info?: Maybe<EligibilityFileProcessingInfo>;
  extended_party_entity_type?: Maybe<PartyEntityType>;
  extended_party_id?: Maybe<Scalars['String']>;
  fee_schedule_processing_info?: Maybe<FeeScheduleProcessingInfo>;
  file_format_type?: Maybe<FileFormatType>;
  file_type?: Maybe<FileUploadType>;
  file_upload_purpose?: Maybe<Scalars['String']>;
  file_url: Scalars['String'];
  finalized_time_stamp?: Maybe<Scalars['String']>;
  is_finalized?: Maybe<Scalars['Boolean']>;
  member_roster_processing_info?: Maybe<MemberRosterProcessingInfo>;
  object_key?: Maybe<Scalars['String']>;
  old_file_url: Scalars['String'];
  parent_file_upload_id?: Maybe<Scalars['String']>;
  plan_document_info?: Maybe<PlanDocumentInfo>;
  processing_info_type?: Maybe<ProcessingInfoType>;
  provider_group_contract_processing_info?: Maybe<ProviderGroupContractProcessingInfo>;
  provider_roster_processing_info?: Maybe<ProviderRosterProcessingInfo>;
  remittance_processing_info?: Maybe<RemittanceProcessingInfo>;
  root_folder?: Maybe<Scalars['String']>;
  source?: Maybe<FileUploadSource>;
  updated_time_stamp?: Maybe<Scalars['String']>;
  upload_time_stamp?: Maybe<Scalars['String']>;
  uploaded_by_id?: Maybe<Scalars['String']>;
  uploaded_record_count?: Maybe<Scalars['Int']>;
};

export type FileUploadInput = {
  bucket_name?: InputMaybe<Scalars['String']>;
  claims_processing_info?: InputMaybe<ClaimsProcessingInfoInput>;
  direction?: InputMaybe<Direction>;
  eligibility_file_processing_info?: InputMaybe<EligibilityFileProcessingInfoInput>;
  extended_party_entity_type?: InputMaybe<PartyEntityType>;
  extended_party_id?: InputMaybe<Scalars['String']>;
  fee_schedule_processing_info?: InputMaybe<FeeScheduleProcessingInfoInput>;
  file_format_type?: InputMaybe<FileFormatType>;
  file_type?: InputMaybe<FileUploadType>;
  file_upload_purpose?: InputMaybe<Scalars['String']>;
  file_url: Scalars['String'];
  finalized_time_stamp?: InputMaybe<Scalars['String']>;
  is_finalized?: InputMaybe<Scalars['Boolean']>;
  member_roster_processing_info?: InputMaybe<MemberRosterProcessingInfoInput>;
  object_key?: InputMaybe<Scalars['String']>;
  parent_file_upload_id?: InputMaybe<Scalars['String']>;
  processing_info_type?: InputMaybe<ProcessingInfoType>;
  provider_roster_processing_info?: InputMaybe<ProviderRosterProcessingInfoInput>;
  remittance_processing_info?: InputMaybe<RemittanceProcessingInfoInput>;
  root_folder?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<FileUploadSource>;
  updated_time_stamp?: InputMaybe<Scalars['String']>;
  upload_time_stamp?: InputMaybe<Scalars['String']>;
  uploaded_by_id?: InputMaybe<Scalars['String']>;
  uploaded_record_count?: InputMaybe<Scalars['Int']>;
};

export type FileUploadQueryInput = {
  bucket?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};

export enum FileUploadSource {
  FusExternalSftp = 'FUS_EXTERNAL_SFTP',
  FusNomiSftp = 'FUS_NOMI_SFTP',
  FusUnknown = 'FUS_UNKNOWN',
}

export enum FileUploadType {
  FutEdi834 = 'FUT_EDI834',
  FutEdi835 = 'FUT_EDI835',
  FutEdi837I = 'FUT_EDI837I',
  FutEdi837P = 'FUT_EDI837P',
  FutEop = 'FUT_EOP',
  FutFeeSchedule = 'FUT_FEE_SCHEDULE',
  FutGeneral = 'FUT_GENERAL',
  FutMemberRoster = 'FUT_MEMBER_ROSTER',
  FutPayeeRoster = 'FUT_PAYEE_ROSTER',
  FutPlanDocument = 'FUT_PLAN_DOCUMENT',
  FutProviderGroupContract = 'FUT_PROVIDER_GROUP_CONTRACT',
  FutProviderRoster = 'FUT_PROVIDER_ROSTER',
  FutRemittance = 'FUT_REMITTANCE',
  FutSponsorContract = 'FUT_SPONSOR_CONTRACT',
  FutUnknown = 'FUT_UNKNOWN',
  FutVirtualCard = 'FUT_VIRTUAL_CARD',
}

export type FinancialAccount = IEffectiveDates & {
  __typename?: 'FinancialAccount';
  account_id: Scalars['String'];
  account_type: Scalars['AccountType'];
  audit?: Maybe<Audit>;
  bank_account?: Maybe<BankAccount>;
  effective_dates?: Maybe<EffectiveDates>;
  external_ids?: Maybe<Array<ExternalId>>;
  is_onboarding: Scalars['Boolean'];
  ledger_account?: Maybe<LedgerAccount>;
  status: FinancialAccountStatus;
  stripe_customer_account?: Maybe<Array<Maybe<CardData>>>;
};

export type FinancialAccountInput = {
  account_id: Scalars['String'];
  account_type: Scalars['AccountType'];
  audit?: InputMaybe<AuditInput>;
  bank_account?: InputMaybe<BankAccountInput>;
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  external_ids?: InputMaybe<Array<ExternalIdInput>>;
  is_onboarding: Scalars['Boolean'];
  ledger_account?: InputMaybe<LedgerAccountInput>;
  status: FinancialAccountStatus;
  stripe_customer_account?: InputMaybe<Array<InputMaybe<CardDataInput>>>;
};

export enum FinancialAccountStatus {
  AccountStatusUnknown = 'ACCOUNT_STATUS_UNKNOWN',
  Active = 'ACTIVE',
  BankAccountRemoved = 'BANK_ACCOUNT_REMOVED',
  Denied = 'DENIED',
  Hold = 'HOLD',
  Terminated = 'TERMINATED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type FloatWrapper = {
  __typename?: 'FloatWrapper';
  integer?: Maybe<Scalars['Int']>;
  nanos?: Maybe<Scalars['Int']>;
};

export type FloatWrapperInput = {
  integer?: InputMaybe<Scalars['String']>;
  nanos?: InputMaybe<Scalars['Int']>;
};

export type FloatWrapperInput837I = {
  integer?: InputMaybe<Scalars['String']>;
  nanos?: InputMaybe<Scalars['String']>;
};

export type FormIdentification = {
  __typename?: 'FormIdentification';
  industry_code?: Maybe<IndustryCode>;
  loop_metadata?: Maybe<X12Metadata>;
  supporting_docs?: Maybe<Array<Maybe<SupportingDoc>>>;
};

export type FormIdentificationInput = {
  industry_code?: InputMaybe<IndustryCodeInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  supporting_docs?: InputMaybe<Array<InputMaybe<SupportingDocInput>>>;
};

export enum FormatType {
  PruNomi = 'PRU_NOMI',
  PruQuest = 'PRU_QUEST',
  PruTellus = 'PRU_TELLUS',
  UnknownFormatType = 'UNKNOWN_FORMAT_TYPE',
}

export type FunctionalGroupResponseHeader = {
  __typename?: 'FunctionalGroupResponseHeader';
  functional_id_code?: Maybe<Scalars['String']>;
  group_control_number?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  version_id_code?: Maybe<Scalars['String']>;
};

export type FunctionalGroupResponseTrailer = {
  __typename?: 'FunctionalGroupResponseTrailer';
  acknowledgement_code?: Maybe<Scalars['String']>;
  fg_syntax_error_code_1?: Maybe<Scalars['String']>;
  fg_syntax_error_code_2?: Maybe<Scalars['String']>;
  fg_syntax_error_code_3?: Maybe<Scalars['String']>;
  fg_syntax_error_code_4?: Maybe<Scalars['String']>;
  fg_syntax_error_code_5?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  transaction_sets_accepted?: Maybe<Uint32Wrapper>;
  transaction_sets_included?: Maybe<Uint32Wrapper>;
  transaction_sets_received?: Maybe<Uint32Wrapper>;
};

export type FunctionalGroupTrailer = {
  __typename?: 'FunctionalGroupTrailer';
  control_number?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  transaction_set_count?: Maybe<Uint32Wrapper>;
};

export type FunctionalGroupTrailerInput = {
  control_number?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  transaction_set_count?: InputMaybe<Uint32WrapperInput>;
};

export type Gs = {
  __typename?: 'GS';
  application_receiver_code?: Maybe<Scalars['String']>;
  application_sender_code?: Maybe<Scalars['String']>;
  function_group_creation_time?: Maybe<Scalars['String']>;
  functional_group_creation_date?: Maybe<Scalars['String']>;
  functional_identifier_code?: Maybe<Scalars['String']>;
  group_control_number?: Maybe<Scalars['String']>;
  responsible_agency_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  version_identifier_code?: Maybe<Scalars['String']>;
};

export type GsInput = {
  application_receiver_code?: InputMaybe<Scalars['String']>;
  application_sender_code?: InputMaybe<Scalars['String']>;
  function_group_creation_time?: InputMaybe<Scalars['String']>;
  functional_group_creation_date?: InputMaybe<Scalars['String']>;
  functional_identifier_code?: InputMaybe<Scalars['String']>;
  group_control_number?: InputMaybe<Scalars['String']>;
  responsible_agency_code?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  version_identifier_code?: InputMaybe<Scalars['String']>;
};

export type GarfieldEvent = {
  __typename?: 'GarfieldEvent';
  collection?: Maybe<Scalars['String']>;
  resume_token?: Maybe<ResumeToken>;
  timestamp?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export enum GenderType {
  Female = 'FEMALE',
  GenderUnknown = 'GENDER_UNKNOWN',
  Male = 'MALE',
  Other = 'OTHER',
}

export type GenerationInfo = {
  __typename?: 'GenerationInfo';
  fee_schedule_id?: Maybe<Scalars['String']>;
  provider_contract?: Maybe<Contract>;
  provider_contract_id?: Maybe<Scalars['String']>;
  sender_party_id?: Maybe<Scalars['String']>;
  sponsor_contract?: Maybe<Contract>;
  sponsor_contract_id?: Maybe<Scalars['String']>;
  sponsor_party_id?: Maybe<Scalars['String']>;
  tpa_contract?: Maybe<Contract>;
  tpa_contract_id?: Maybe<Scalars['String']>;
  tpa_party?: Maybe<Party>;
  tpa_party_id?: Maybe<Scalars['String']>;
};

export enum GeoLocationSource {
  GeoSourceMapbox = 'GEO_SOURCE_MAPBOX',
  GeoSourceUnknown = 'GEO_SOURCE_UNKNOWN',
}

export enum GeoLocationType {
  Point = 'Point',
  UnknownGeoLocationType = 'UNKNOWN_GEO_LOCATION_TYPE',
}

export type Geolocation = {
  __typename?: 'Geolocation';
  coordinates?: Maybe<Array<Scalars['Float']>>;
  distance?: Maybe<Scalars['Float']>;
  source?: Maybe<GeoLocationSource>;
  type?: Maybe<GeoLocationType>;
};

export type GetBySponsorTypeInput = {
  type: SponsorType;
};

export type GetRemittanceGroupsForRcmInput = {
  payee_party_ids?: InputMaybe<Array<Scalars['ID']>>;
  payer_party_id?: InputMaybe<Scalars['ID']>;
  payment_id?: InputMaybe<Scalars['ID']>;
  payment_status?: InputMaybe<Array<RemittanceGroupState>>;
  payment_time?: InputMaybe<PaymentDate>;
  payment_type?: InputMaybe<PaymentVehicleType>;
  provider_group_party_ids?: InputMaybe<Array<Scalars['ID']>>;
  tpa_name?: InputMaybe<Scalars['String']>;
};

export type GovernmentIdInput = {
  id_number: Scalars['String'];
  id_type: GovernmentIdType;
};

export enum GovernmentIdType {
  AlloyEntityToken = 'ALLOY_ENTITY_TOKEN',
  BirthCertificate = 'BIRTH_CERTIFICATE',
  CaqhNumber = 'CAQH_NUMBER',
  Citizen = 'CITIZEN',
  DriversLicense = 'DRIVERS_LICENSE',
  Duns = 'DUNS',
  IncreaseEntityId = 'INCREASE_ENTITY_ID',
  MedicalLicenseNumber = 'MEDICAL_LICENSE_NUMBER',
  Military = 'MILITARY',
  NaicsCode = 'NAICS_CODE',
  NppesNpi = 'NPPES_NPI',
  Other = 'OTHER',
  Passport = 'PASSPORT',
  Resident = 'RESIDENT',
  SicCode = 'SIC_CODE',
  SocialSecurity = 'SOCIAL_SECURITY',
  TaxId = 'TAX_ID',
  Uid = 'UID',
  Visitor = 'VISITOR',
}

export type GovernmentIssuedId = IEffectiveDates & {
  __typename?: 'GovernmentIssuedId';
  effective_dates?: Maybe<EffectiveDates>;
  id_number: Scalars['String'];
  id_type: GovernmentIdType;
};

export type GovernmentIssuedIdId_NumberArgs = {
  detokenize?: Scalars['Boolean'];
};

export type HealthCareCodeInfo = {
  __typename?: 'HealthCareCodeInfo';
  code?: Maybe<Scalars['String']>;
  codelist?: Maybe<X12Code>;
  datetime?: Maybe<Scalars['String']>;
  datetime_format?: Maybe<X12Code>;
  element_metadata?: Maybe<X12Metadata>;
  industry_code?: Maybe<Scalars['String']>;
  monetary_amount?: Maybe<FloatWrapper>;
  quantity?: Maybe<FloatWrapper>;
  version_identifier?: Maybe<Scalars['String']>;
  yes_no?: Maybe<X12Code>;
};

export type HealthCareCodeInfoInput = {
  code?: InputMaybe<Scalars['String']>;
  codelist?: InputMaybe<X12CodeInput>;
  datetime?: InputMaybe<Scalars['String']>;
  datetime_format?: InputMaybe<X12CodeInput>;
  element_metadata?: InputMaybe<X12MetadataInput>;
  industry_code?: InputMaybe<Scalars['String']>;
  monetary_amount?: InputMaybe<FloatWrapperInput>;
  quantity?: InputMaybe<FloatWrapperInput>;
  version_identifier?: InputMaybe<Scalars['String']>;
  yes_no?: InputMaybe<X12CodeInput>;
};

export type HealthCareServiceLocation = {
  __typename?: 'HealthCareServiceLocation';
  claim_frequency_type_code?: Maybe<X12Code>;
  element_metadata?: Maybe<X12Metadata>;
  facility_code_qualifier?: Maybe<X12Code>;
  facility_code_value?: Maybe<X12Code>;
};

export type HealthCareServiceLocationInput = {
  claim_frequency_type_code?: InputMaybe<X12CodeInput>;
  element_metadata?: InputMaybe<X12MetadataInput>;
  facility_code_qualifier?: InputMaybe<X12CodeInput>;
  facility_code_value?: InputMaybe<X12CodeInput>;
};

export type HealthInformation = {
  __typename?: 'HealthInformation';
  current_health_condition_code?: Maybe<X12Code>;
  description_1?: Maybe<Scalars['String']>;
  description_2?: Maybe<Scalars['String']>;
  health_related_code?: Maybe<X12Code>;
  height?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
  weight_1?: Maybe<FloatWrapper>;
  weight_2?: Maybe<FloatWrapper>;
};

export type Hierarchy = {
  __typename?: 'Hierarchy';
  child_code?: Maybe<X12Code>;
  level?: Maybe<Uint32Wrapper>;
  parent_id?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
  type?: Maybe<X12Code>;
};

export type HierarchyInput = {
  child_code?: InputMaybe<X12CodeInput>;
  level?: InputMaybe<Uint32WrapperInput>;
  parent_id?: InputMaybe<Uint32WrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  type?: InputMaybe<X12CodeInput>;
};

export type IDocument = {
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
};

export type IEffectiveDates = {
  effective_dates?: Maybe<EffectiveDates>;
};

export type Isa = {
  __typename?: 'ISA';
  acknowledgement_requested?: Maybe<Scalars['String']>;
  authorization_info?: Maybe<Scalars['String']>;
  authorization_info_qualifier?: Maybe<X12Code>;
  component_element_separator?: Maybe<Scalars['String']>;
  interchange_date?: Maybe<Scalars['String']>;
  interchange_number?: Maybe<Scalars['String']>;
  interchange_time?: Maybe<Scalars['String']>;
  interchange_version_number?: Maybe<Scalars['String']>;
  receiver_id?: Maybe<Scalars['String']>;
  receiver_id_qualifier?: Maybe<Scalars['String']>;
  repetition_separator?: Maybe<Scalars['String']>;
  security_info?: Maybe<Scalars['String']>;
  security_info_qualifier?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  sender_id?: Maybe<Scalars['String']>;
  sender_id_qualifier?: Maybe<Scalars['String']>;
  usage_indicator?: Maybe<X12Code>;
};

export type IsaSender_IdArgs = {
  detokenize?: Scalars['Boolean'];
};

export type IsaInput = {
  acknowledgement_requested?: InputMaybe<Scalars['String']>;
  authorization_info?: InputMaybe<Scalars['String']>;
  authorization_info_qualifier?: InputMaybe<X12CodeInput>;
  component_element_separator?: InputMaybe<Scalars['String']>;
  interchange_date?: InputMaybe<Scalars['String']>;
  interchange_number?: InputMaybe<Scalars['String']>;
  interchange_time?: InputMaybe<Scalars['String']>;
  interchange_version_number?: InputMaybe<Scalars['String']>;
  receiver_id?: InputMaybe<Scalars['String']>;
  receiver_id_qualifier?: InputMaybe<Scalars['String']>;
  repetition_separator?: InputMaybe<Scalars['String']>;
  security_info?: InputMaybe<Scalars['String']>;
  security_info_qualifier?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  sender_id?: InputMaybe<Scalars['String']>;
  sender_id_qualifier?: InputMaybe<Scalars['String']>;
  usage_indicator?: InputMaybe<X12CodeInput>;
};

export type IdentificationCard = {
  __typename?: 'IdentificationCard';
  action_code?: Maybe<X12Code>;
  identification_card_type_code?: Maybe<X12Code>;
  plan_coverage_description?: Maybe<Scalars['String']>;
  quantity?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type Identity = {
  __typename?: 'Identity';
  audit_logs?: Maybe<Array<AuditLog>>;
  email_device?: Maybe<MfaDevice>;
  identity_id: Scalars['ID'];
  identity_provider: IdentityProvider;
  ping_onboarding_state: Scalars['String'];
  sms_device?: Maybe<MfaDevice>;
  username: Scalars['String'];
};

export type IdentityConfigurationInput = {
  okta_identity_config?: InputMaybe<OktaConfigurationInput>;
  ping_identity_config?: InputMaybe<PingConfigurationInput>;
};

export enum IdentityProvider {
  IpExternal = 'IP_EXTERNAL',
  IpOkta = 'IP_OKTA',
  IpPing = 'IP_PING',
  UnknownIdentityProvider = 'UNKNOWN_IDENTITY_PROVIDER',
}

export type IdentityProviderInfo = {
  __typename?: 'IdentityProviderInfo';
  external_identity_provider_info?: Maybe<ExternalIdentityProviderInfo>;
  identity_provider: IdentityProvider;
  ping_identity_provider_info?: Maybe<PingIdentityProviderInfo>;
};

export type ImplementationDataElementNote = {
  __typename?: 'ImplementationDataElementNote';
  bad_data_element_copy?: Maybe<Scalars['String']>;
  reference_number?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
  segment_position?: Maybe<PositionInSegment>;
  syntax_error_code?: Maybe<Scalars['String']>;
};

export type ImplementationDataSegmentNote = {
  __typename?: 'ImplementationDataSegmentNote';
  loop_id_code?: Maybe<Scalars['String']>;
  segment_id_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  syntax_error_code?: Maybe<Scalars['String']>;
  ts_segment_position?: Maybe<Uint32Wrapper>;
};

export enum ImplementationStatus {
  Complete = 'COMPLETE',
  InviteSent = 'INVITE_SENT',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  UnknownStatus = 'UNKNOWN_STATUS',
}

export type IncludedCode = {
  __typename?: 'IncludedCode';
  code: Scalars['String'];
  codeSet?: Maybe<MedicalCodeSetType>;
  included_code_type?: Maybe<IncludedCodeType>;
};

export enum IncludedCodeType {
  IctAddOnCode = 'ICT_ADD_ON_CODE',
  IctAnchorCode = 'ICT_ANCHOR_CODE',
  IctComplementaryCode = 'ICT_COMPLEMENTARY_CODE',
  IctUnknown = 'ICT_UNKNOWN',
}

export type IncludedRanges = {
  __typename?: 'IncludedRanges';
  codeset: MedicalCodeSetType;
  ending_code: Scalars['String'];
  included_range_type?: Maybe<IncludedCodeType>;
  starting_code?: Maybe<Scalars['String']>;
};

export type IncludedRangesInput = {
  codeset?: InputMaybe<MedicalCodeSetType>;
  ending_code?: InputMaybe<Scalars['String']>;
  starting_code?: InputMaybe<Scalars['String']>;
};

export type Income = {
  __typename?: 'Income';
  currency_code?: Maybe<X12Code>;
  frequency_code?: Maybe<X12Code>;
  location_id_code?: Maybe<Scalars['String']>;
  salary_grade_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  wage_amount?: Maybe<FloatWrapper>;
  work_hours_count?: Maybe<FloatWrapper>;
};

export type IncreaseCardAuthorizationMetadata = {
  __typename?: 'IncreaseCardAuthorizationMetadata';
  account_id: Scalars['ID'];
  authorization_rejection_reason: Scalars['String'];
  authorized?: Maybe<Scalars['Boolean']>;
  card_id: Scalars['ID'];
  currency: Scalars['String'];
  merchant_acceptor_id: Scalars['ID'];
  merchant_category_code: Scalars['String'];
  merchant_city: Scalars['String'];
  merchant_country: Scalars['String'];
  merchant_descriptor: Scalars['String'];
  merchant_name: Scalars['String'];
  merchant_state: Scalars['String'];
  pending_transaction_id: Scalars['ID'];
  presentment_amount_pennies: Scalars['Int'];
  settlement_amount_pennies: Scalars['Int'];
};

export type IncreaseCursorEvent = {
  __typename?: 'IncreaseCursorEvent';
  cursor?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type IncreaseEvent = {
  __typename?: 'IncreaseEvent';
  table?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type IncreaseWebhookEvent = Event &
  IDocument & {
    __typename?: 'IncreaseWebhookEvent';
    _id: Scalars['ID'];
    audit?: Maybe<Audit>;
    event_errors?: Maybe<Array<Scalars['String']>>;
    event_type: Scalars['String'];
    increase_webhook_event: IncreaseWebhookEventContent;
  };

export type IncreaseWebhookEventContent = {
  __typename?: 'IncreaseWebhookEventContent';
  associated_object_id: Scalars['ID'];
  associated_object_type: Scalars['String'];
  category: Scalars['String'];
  created_at: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type IndustryCode = {
  __typename?: 'IndustryCode';
  code_list_qualifier?: Maybe<Scalars['String']>;
  remark_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type IndustryCodeInput = {
  code_list_qualifier?: InputMaybe<Scalars['String']>;
  remark_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type InformationReceiverStatus = {
  __typename?: 'InformationReceiverStatus';
  action_code?: Maybe<X12Code>;
  check_number?: Maybe<Scalars['String']>;
  free_form_message_text?: Maybe<Scalars['String']>;
  health_care_claim_status_1?: Maybe<InformationReceiverStatusCode>;
  health_care_claim_status_2?: Maybe<InformationReceiverStatusCode>;
  health_care_claim_status_3?: Maybe<InformationReceiverStatusCode>;
  monetary_amount_1?: Maybe<FloatWrapper>;
  monetary_amount_2?: Maybe<Scalars['String']>;
  payment_method_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  status_information_effective_date_1?: Maybe<Scalars['String']>;
  status_information_effective_date_2?: Maybe<Scalars['String']>;
  status_information_effective_date_3?: Maybe<Scalars['String']>;
};

export type InformationReceiverStatusCode = {
  __typename?: 'InformationReceiverStatusCode';
  claim_status_code_1?: Maybe<X12Code>;
  claim_status_code_2?: Maybe<X12Code>;
  code_list?: Maybe<X12Code>;
  element_metadata?: Maybe<X12Metadata>;
  entity_id?: Maybe<X12Code>;
};

export type InpatientAdjudicationInformation = {
  __typename?: 'InpatientAdjudicationInformation';
  capital_amount?: Maybe<FloatWrapper>;
  capital_ime_amount?: Maybe<FloatWrapper>;
  capital_outlier_amount?: Maybe<FloatWrapper>;
  claim_drg_amount?: Maybe<FloatWrapper>;
  claim_payment_remark_code_1?: Maybe<Scalars['String']>;
  claim_payment_remark_code_2?: Maybe<Scalars['String']>;
  claim_payment_remark_code_3?: Maybe<Scalars['String']>;
  claim_payment_remark_code_4?: Maybe<Scalars['String']>;
  claim_payment_remark_code_5?: Maybe<Scalars['String']>;
  component_billed_amount?: Maybe<FloatWrapper>;
  cost_report_day_count?: Maybe<FloatWrapper>;
  covered_days?: Maybe<Uint32Wrapper>;
  disproporionate_share_amount?: Maybe<FloatWrapper>;
  dsh_drg_amount?: Maybe<FloatWrapper>;
  federal_specific_amount?: Maybe<FloatWrapper>;
  fsp_drg_amount?: Maybe<FloatWrapper>;
  hospital_specific_amount?: Maybe<FloatWrapper>;
  hsp_drg_amount?: Maybe<FloatWrapper>;
  indirect_teaching_amount?: Maybe<FloatWrapper>;
  lifetime_psychiatric_days?: Maybe<Uint32Wrapper>;
  monetary_amount_1?: Maybe<Scalars['String']>;
  monetary_amount_16?: Maybe<FloatWrapper>;
  old_capital_amount?: Maybe<FloatWrapper>;
  pass_through_amount?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type InpatientAdjudicationInformationInput = {
  capital_amount?: InputMaybe<FloatWrapperInput>;
  capital_ime_amount?: InputMaybe<FloatWrapperInput>;
  capital_outlier_amount?: InputMaybe<FloatWrapperInput>;
  claim_drg_amount?: InputMaybe<FloatWrapperInput>;
  claim_payment_remark_code_1?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_2?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_3?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_4?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_5?: InputMaybe<Scalars['String']>;
  component_billed_amount?: InputMaybe<FloatWrapperInput>;
  cost_report_day_count?: InputMaybe<FloatWrapperInput>;
  covered_days?: InputMaybe<Uint32WrapperInput>;
  disproporionate_share_amount?: InputMaybe<FloatWrapperInput>;
  dsh_drg_amount?: InputMaybe<FloatWrapperInput>;
  federal_specific_amount?: InputMaybe<FloatWrapperInput>;
  fsp_drg_amount?: InputMaybe<FloatWrapperInput>;
  hospital_specific_amount?: InputMaybe<FloatWrapperInput>;
  hsp_drg_amount?: InputMaybe<FloatWrapperInput>;
  indirect_teaching_amount?: InputMaybe<FloatWrapperInput>;
  lifetime_psychiatric_days?: InputMaybe<Uint32WrapperInput>;
  monetary_amount_1?: InputMaybe<Scalars['String']>;
  monetary_amount_16?: InputMaybe<FloatWrapperInput>;
  old_capital_amount?: InputMaybe<FloatWrapperInput>;
  pass_through_amount?: InputMaybe<FloatWrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type InstitutionalClaimCode = {
  __typename?: 'InstitutionalClaimCode';
  admission_source_code?: Maybe<X12Code>;
  admission_type_code?: Maybe<X12Code>;
  nursing_home_residential_status_code?: Maybe<Scalars['String']>;
  patient_status_code?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type InstitutionalClaimCodeInput = {
  admission_source_code?: InputMaybe<X12CodeInput>;
  admission_type_code?: InputMaybe<X12CodeInput>;
  nursing_home_residential_status_code?: InputMaybe<Scalars['String']>;
  patient_status_code?: InputMaybe<Uint32WrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type InstitutionalFeeScheduleEntries = {
  __typename?: 'InstitutionalFeeScheduleEntries';
  asc_fee_sch_entry?: Maybe<InstitutionalFeeScheduleEntry>;
  global_carve_outs?: Maybe<FeeSchedulePriorityEntries>;
  inpatient_fee_sch_entry?: Maybe<InstitutionalFeeScheduleEntry>;
  outpatient_fee_sch_entry?: Maybe<InstitutionalFeeScheduleEntry>;
  total: Scalars['Int'];
};

export type InstitutionalFeeScheduleEntriesInput = {
  asc_fee_sch_entry?: InputMaybe<InstitutionalFeeScheduleEntryInput>;
  global_carve_outs?: InputMaybe<FeeSchedulePriorityEntriesInput>;
  inpatient_fee_sch_entry?: InputMaybe<InstitutionalFeeScheduleEntryInput>;
  outpatient_fee_sch_entry?: InputMaybe<InstitutionalFeeScheduleEntryInput>;
};

export type InstitutionalFeeScheduleEntry = {
  __typename?: 'InstitutionalFeeScheduleEntry';
  base_rate_pennies?: Maybe<Scalars['Int']>;
  carve_outs?: Maybe<FeeSchedulePriorityEntries>;
  claim_bill_type?: Maybe<ClaimBillType>;
  code_type?: Maybe<FeeScheduleCodeType>;
  percentage_of_medicare?: Maybe<Scalars['Float']>;
};

export type InstitutionalFeeScheduleEntryInput = {
  base_rate_pennies?: InputMaybe<Scalars['Int']>;
  carve_outs?: InputMaybe<FeeSchedulePriorityEntriesInput>;
  claim_bill_type?: InputMaybe<ClaimBillType>;
  code_type?: InputMaybe<FeeScheduleCodeType>;
  percentage_of_medicare?: InputMaybe<Scalars['Float']>;
};

export type InsuranceDetail = {
  __typename?: 'InsuranceDetail';
  benefit_status_code?: Maybe<X12Code>;
  birth_sequence_number?: Maybe<Uint32Wrapper>;
  city?: Maybe<Scalars['String']>;
  cobra_qualifying_event_code?: Maybe<X12Code>;
  confidientiality_code?: Maybe<X12Code>;
  country_code?: Maybe<X12Code>;
  datetime_format?: Maybe<X12Code>;
  datetime_period?: Maybe<Scalars['String']>;
  employment_status_code?: Maybe<X12Code>;
  maintenance_code?: Maybe<X12Code>;
  medicare_status_code?: Maybe<MedicareStatusCode>;
  reason_code?: Maybe<X12Code>;
  relationship_code?: Maybe<X12Code>;
  response_code_1?: Maybe<X12Code>;
  response_code_2?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  state_code?: Maybe<X12Code>;
  student_status_code?: Maybe<X12Code>;
};

export type InterchangeControlTrailer = {
  __typename?: 'InterchangeControlTrailer';
  control_number?: Maybe<Scalars['String']>;
  functional_group_count?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type InterchangeControlTrailerInput = {
  control_number?: InputMaybe<Scalars['String']>;
  functional_group_count?: InputMaybe<Uint32WrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum IntervalType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type IntstitutionalServiceLine = {
  __typename?: 'IntstitutionalServiceLine';
  composite_medical_procedure_identifier?: Maybe<CompositeMedicalProcedure>;
  denied_charge_amount?: Maybe<FloatWrapper>;
  level_of_care_code?: Maybe<Scalars['String']>;
  line_item_charge_amount?: Maybe<FloatWrapper>;
  nursing_home_residential_status_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  service_line_revenue_code?: Maybe<Scalars['String']>;
  service_unit_count?: Maybe<FloatWrapper>;
  unit_measurement_code?: Maybe<X12Code>;
  unit_rate?: Maybe<Scalars['String']>;
  yes_no?: Maybe<Scalars['String']>;
};

export type IntstitutionalServiceLineInput = {
  composite_medical_procedure_identifier?: InputMaybe<CompositeMedicalProcedureInput>;
  denied_charge_amount?: InputMaybe<FloatWrapperInput837I>;
  level_of_care_code?: InputMaybe<Scalars['String']>;
  line_item_charge_amount?: InputMaybe<FloatWrapperInput837I>;
  nursing_home_residential_status_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  service_line_revenue_code?: InputMaybe<Scalars['String']>;
  service_unit_count?: InputMaybe<FloatWrapperInput>;
  unit_measurement_code?: InputMaybe<X12CodeInput>;
  unit_rate?: InputMaybe<Scalars['String']>;
  yes_no?: InputMaybe<Scalars['String']>;
};

export type Invite = {
  __typename?: 'Invite';
  acceptedAt?: Maybe<Scalars['String']>;
  expiresAt: Scalars['String'];
  key: Scalars['String'];
  token: Scalars['String'];
  type: TokenType;
};

export type Invoice = IDocument & {
  __typename?: 'Invoice';
  _id: Scalars['ID'];
  approved_date?: Maybe<Scalars['String']>;
  audit?: Maybe<Audit>;
  billed_date: Scalars['String'];
  bills?: Maybe<Array<Maybe<Bill>>>;
  claim_source?: Maybe<Scalars['String']>;
  contract_rule_id?: Maybe<Scalars['ID']>;
  decision_date?: Maybe<Scalars['String']>;
  encounter?: Maybe<Encounter>;
  encounter_id: Scalars['ID'];
  external_ids?: Maybe<Array<ExternalInvoiceId>>;
  fee_schedule_id?: Maybe<Scalars['String']>;
  fee_type?: Maybe<PaymentsFeeType>;
  invoice_allowed_amount_pennies: Scalars['Int'];
  invoice_resolution?: Maybe<InvoiceResolution>;
  invoice_state: InvoiceState;
  invoice_total_pennies: Scalars['Int'];
  line_items?: Maybe<Array<ServiceLine>>;
  network_id?: Maybe<Scalars['ID']>;
  patient_member?: Maybe<Member>;
  patient_member_id: Scalars['ID'];
  payment_due_date?: Maybe<Scalars['String']>;
  place_of_service_code?: Maybe<PlaceOfService>;
  plan_code?: Maybe<Scalars['String']>;
  practice_facility?: Maybe<PracticeFacility>;
  practice_facility_id?: Maybe<Scalars['String']>;
  primary_member?: Maybe<Member>;
  primary_member_id: Scalars['ID'];
  provider_group?: Maybe<ProviderGroup>;
  provider_group_id: Scalars['ID'];
  raw_claim_id: Scalars['ID'];
  raw_invoice_id: Scalars['ID'];
  received_date?: Maybe<Scalars['String']>;
  reject_reason?: Maybe<Scalars['String']>;
  rendering_provider?: Maybe<Provider>;
  rendering_provider_id?: Maybe<Scalars['String']>;
  service_dates?: Maybe<Scalars['String']>;
  service_fee_pennies: Scalars['Int'];
  service_fee_percentage?: Maybe<Scalars['Float']>;
  service_practice_facility?: Maybe<PracticeFacility>;
  source: InvoiceSource;
  spec?: Maybe<Specification>;
};

export type InvoiceBillsArgs = {
  bill_state?: InputMaybe<Array<BillState>>;
  bill_type?: InputMaybe<Array<BillType>>;
};

export type InvoiceExceptionDetail = {
  __typename?: 'InvoiceExceptionDetail';
  description?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
};

export type InvoiceExceptionDetailsKeyValuePair = {
  __typename?: 'InvoiceExceptionDetailsKeyValuePair';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<InvoiceExceptionDetail>>>;
};

export type InvoiceExceptionResponse = {
  __typename?: 'InvoiceExceptionResponse';
  conflicting_bundle_exception_details?: Maybe<ConflictingBundleException>;
  invoice?: Maybe<Invoice>;
  multiple_bundle_exception_details?: Maybe<MultipleBundleException>;
};

export type InvoiceReason = {
  __typename?: 'InvoiceReason';
  description: Scalars['String'];
  exception_date?: Maybe<Scalars['String']>;
  exception_metadata?: Maybe<InvoiceExceptionDetailsKeyValuePair>;
  exception_source_process?: Maybe<ExceptionSourceProcess>;
  exception_type?: Maybe<ExceptionType>;
  is_resolved?: Maybe<Scalars['Boolean']>;
  reason_type: Scalars['String'];
  resolved_date?: Maybe<Scalars['String']>;
};

export type InvoiceResolution = IDocument & {
  __typename?: 'InvoiceResolution';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  audit_logs?: Maybe<Array<InvoiceStateTransitionAuditLog>>;
  exceptions?: Maybe<Array<Maybe<InvoiceReason>>>;
  generated?: Maybe<GenerationInfo>;
  invoice_id: Scalars['ID'];
  metadata?: Maybe<InvoiceResolutionMetadata>;
  priced?: Maybe<PricingEventInfo>;
  repricing?: Maybe<RepricingInfo>;
  resolved?: Maybe<ResolvedInfo>;
};

export type InvoiceResolutionMetadata = {
  __typename?: 'InvoiceResolutionMetadata';
  claim_type?: Maybe<Scalars['String']>;
  network_access_type?: Maybe<NetworkAccessType>;
  raw_claim?: Maybe<RawClaim>;
  raw_claim_file_upload_id?: Maybe<Scalars['String']>;
  raw_claim_id?: Maybe<Scalars['String']>;
  raw_remit?: Maybe<RawRemittance>;
  raw_remit_file_upload_id?: Maybe<Scalars['String']>;
  raw_remit_id?: Maybe<Scalars['String']>;
  source: InvoiceSource;
};

export enum InvoiceSource {
  ClaimOpsPortal = 'CLAIM_OPS_PORTAL',
  Edi835 = 'EDI835',
  Edi837I = 'EDI837I',
  Edi837P = 'EDI837P',
  IsUnknown = 'IS_UNKNOWN',
  NetworkAdminPortal = 'NETWORK_ADMIN_PORTAL',
  ProviderPortal = 'PROVIDER_PORTAL',
}

export enum InvoiceState {
  Adjudicated = 'ADJUDICATED',
  Approved = 'APPROVED',
  Created = 'CREATED',
  Exception = 'EXCEPTION',
  ExternallyProcessed = 'EXTERNALLY_PROCESSED',
  Initialized = 'INITIALIZED',
  ManualReview = 'MANUAL_REVIEW',
  Paid = 'PAID',
  PaidExternally = 'PAID_EXTERNALLY',
  ParsedRemittanceInstruction = 'PARSED_REMITTANCE_INSTRUCTION',
  Priced = 'PRICED',
  Rejected = 'REJECTED',
  Screened = 'SCREENED',
  Settled = 'SETTLED',
  Submitted = 'SUBMITTED',
  Unsubmitted = 'UNSUBMITTED',
}

export type InvoiceStateDetailsInput = {
  externallyProcessed?: InputMaybe<InvoiceStateDetailsInput_ExternallyProcessed>;
  rejected?: InputMaybe<InvoiceStateDetailsInput_Rejected>;
  settled?: InputMaybe<InvoiceStateDetailsInput_Settled>;
  submitted?: InputMaybe<InvoiceStateDetailsInput_Submitted>;
};

export type InvoiceStateDetailsInput_ExternallyProcessed = {
  external_processing_reason?: InputMaybe<Scalars['String']>;
};

export type InvoiceStateDetailsInput_Rejected = {
  rejection_reason?: InputMaybe<Scalars['String']>;
};

export type InvoiceStateDetailsInput_Settled = {
  timestamp?: InputMaybe<Scalars['String']>;
};

export type InvoiceStateDetailsInput_Submitted = {
  deductibleMet: Scalars['Boolean'];
  patientResponsibility: Scalars['Int'];
};

export type InvoiceStateTransition = {
  __typename?: 'InvoiceStateTransition';
  from_state?: Maybe<InvoiceState>;
  reasons?: Maybe<Array<InvoiceReason>>;
  to_state?: Maybe<InvoiceState>;
};

export type InvoiceStateTransitionAuditLog = {
  __typename?: 'InvoiceStateTransitionAuditLog';
  invoice_state_transition?: Maybe<InvoiceStateTransition>;
  transition_audit?: Maybe<Audit>;
  transitioned_at_time?: Maybe<Scalars['String']>;
  transitioned_by_party?: Maybe<Party>;
  transitioned_by_party_id?: Maybe<Scalars['String']>;
};

export enum InvoiceTransitionName {
  InvoiceAdjudicate = 'INVOICE_ADJUDICATE',
  InvoiceApprove = 'INVOICE_APPROVE',
  InvoiceCreate = 'INVOICE_CREATE',
  InvoiceException = 'INVOICE_EXCEPTION',
  InvoiceExternallyProcessed = 'INVOICE_EXTERNALLY_PROCESSED',
  InvoiceManualReview = 'INVOICE_MANUAL_REVIEW',
  InvoiceParseRemittanceInstruction = 'INVOICE_PARSE_REMITTANCE_INSTRUCTION',
  InvoicePay = 'INVOICE_PAY',
  InvoicePrice = 'INVOICE_PRICE',
  InvoiceReject = 'INVOICE_REJECT',
  InvoiceScreen = 'INVOICE_SCREEN',
  InvoiceSettle = 'INVOICE_SETTLE',
  InvoiceSubmit = 'INVOICE_SUBMIT',
  InvoiceUnknown = 'INVOICE_UNKNOWN',
  InvoiceUnsubmit = 'INVOICE_UNSUBMIT',
  PaidExternally = 'PAID_EXTERNALLY',
}

export type InvoiceUpdateInput = {
  bundles_prioritization?: InputMaybe<Array<InputMaybe<BundlePrioritization>>>;
  bundles_reduction?: InputMaybe<Array<InputMaybe<BundleReduction>>>;
  patient_member_id?: InputMaybe<Scalars['String']>;
  primary_member_id?: InputMaybe<Scalars['String']>;
  provider_group_id?: InputMaybe<Scalars['String']>;
  service_line_prices?: InputMaybe<Array<ServiceLinePricesInput>>;
  stoploss_resolution?: InputMaybe<StoplossResolution>;
};

export type InvoiceWithErr = {
  __typename?: 'InvoiceWithErr';
  error?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

export type Invoices = Paginatable & {
  __typename?: 'Invoices';
  offset: Scalars['Int'];
  results: Array<Invoice>;
  total: Scalars['Int'];
};

export type InvoicesFilter = {
  bill_state?: InputMaybe<Array<BillState>>;
  bill_type?: InputMaybe<Array<BillType>>;
  invoice_source?: InputMaybe<Array<InvoiceSource>>;
  invoice_state?: InputMaybe<Array<InvoiceState>>;
  invoice_state_transition_filter?: InputMaybe<StateTransitionFilter>;
  network_access_types?: InputMaybe<Array<NetworkAccessType>>;
  party_id?: InputMaybe<Scalars['ID']>;
  patient_search_term?: InputMaybe<Scalars['String']>;
  provider_group_ids?: InputMaybe<Array<Scalars['ID']>>;
  provider_group_party_ids?: InputMaybe<Array<Scalars['ID']>>;
  service_date?: InputMaybe<ServiceDate>;
  tpa_name?: InputMaybe<Scalars['String']>;
};

export type ItemIdentification = {
  __typename?: 'ItemIdentification';
  assigned_identification?: Maybe<Scalars['String']>;
  national_drug_code?: Maybe<Scalars['String']>;
  product_service_id_2?: Maybe<Scalars['String']>;
  product_service_id_3?: Maybe<Scalars['String']>;
  product_service_id_4?: Maybe<Scalars['String']>;
  product_service_id_5?: Maybe<Scalars['String']>;
  product_service_id_6?: Maybe<Scalars['String']>;
  product_service_id_7?: Maybe<Scalars['String']>;
  product_service_id_8?: Maybe<Scalars['String']>;
  product_service_id_9?: Maybe<Scalars['String']>;
  product_service_id_10?: Maybe<Scalars['String']>;
  product_service_id_11?: Maybe<Scalars['String']>;
  product_service_id_12?: Maybe<Scalars['String']>;
  product_service_id_13?: Maybe<Scalars['String']>;
  product_service_id_14?: Maybe<Scalars['String']>;
  product_service_id_15?: Maybe<Scalars['String']>;
  product_service_id_qualifier_1?: Maybe<X12Code>;
  product_service_id_qualifier_2?: Maybe<Scalars['String']>;
  product_service_id_qualifier_3?: Maybe<Scalars['String']>;
  product_service_id_qualifier_4?: Maybe<Scalars['String']>;
  product_service_id_qualifier_5?: Maybe<Scalars['String']>;
  product_service_id_qualifier_6?: Maybe<Scalars['String']>;
  product_service_id_qualifier_7?: Maybe<Scalars['String']>;
  product_service_id_qualifier_8?: Maybe<Scalars['String']>;
  product_service_id_qualifier_9?: Maybe<Scalars['String']>;
  product_service_id_qualifier_10?: Maybe<Scalars['String']>;
  product_service_id_qualifier_11?: Maybe<Scalars['String']>;
  product_service_id_qualifier_12?: Maybe<Scalars['String']>;
  product_service_id_qualifier_13?: Maybe<Scalars['String']>;
  product_service_id_qualifier_14?: Maybe<Scalars['String']>;
  product_service_id_qualifier_15?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ItemIdentificationInput = {
  assigned_identification?: InputMaybe<Scalars['String']>;
  national_drug_code?: InputMaybe<Scalars['String']>;
  product_service_id_2?: InputMaybe<Scalars['String']>;
  product_service_id_3?: InputMaybe<Scalars['String']>;
  product_service_id_4?: InputMaybe<Scalars['String']>;
  product_service_id_5?: InputMaybe<Scalars['String']>;
  product_service_id_6?: InputMaybe<Scalars['String']>;
  product_service_id_7?: InputMaybe<Scalars['String']>;
  product_service_id_8?: InputMaybe<Scalars['String']>;
  product_service_id_9?: InputMaybe<Scalars['String']>;
  product_service_id_10?: InputMaybe<Scalars['String']>;
  product_service_id_11?: InputMaybe<Scalars['String']>;
  product_service_id_12?: InputMaybe<Scalars['String']>;
  product_service_id_13?: InputMaybe<Scalars['String']>;
  product_service_id_14?: InputMaybe<Scalars['String']>;
  product_service_id_15?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_1?: InputMaybe<X12CodeInput>;
  product_service_id_qualifier_2?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_3?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_4?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_5?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_6?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_7?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_8?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_9?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_10?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_11?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_12?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_13?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_14?: InputMaybe<Scalars['String']>;
  product_service_id_qualifier_15?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ItemPricingInformation = {
  __typename?: 'ItemPricingInformation';
  basis_of_unit_price_code?: Maybe<Scalars['String']>;
  class_of_trade_code?: Maybe<Scalars['String']>;
  condition_value?: Maybe<Scalars['String']>;
  monetary_amount?: Maybe<Scalars['String']>;
  multiple_price_quantity?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['String']>;
  national_drug_unit_count?: Maybe<FloatWrapper>;
  price_identifier_code?: Maybe<Scalars['String']>;
  price_multiplier_qualifier?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  unit_of_measure?: Maybe<CompositeUnitOfMeasure>;
  unit_price?: Maybe<Scalars['String']>;
};

export type ItemPricingInformationInput = {
  basis_of_unit_price_code?: InputMaybe<Scalars['String']>;
  class_of_trade_code?: InputMaybe<Scalars['String']>;
  condition_value?: InputMaybe<Scalars['String']>;
  monetary_amount?: InputMaybe<Scalars['String']>;
  multiple_price_quantity?: InputMaybe<Scalars['String']>;
  multiplier?: InputMaybe<Scalars['String']>;
  national_drug_unit_count?: InputMaybe<FloatWrapperInput>;
  price_identifier_code?: InputMaybe<Scalars['String']>;
  price_multiplier_qualifier?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  unit_of_measure?: InputMaybe<CompositeUnitOfMeasureInput>;
  unit_price?: InputMaybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  description?: Maybe<Scalars['String']>;
  id_code_qualifier?: Maybe<X12Code>;
  language_code?: Maybe<Scalars['String']>;
  language_proficiency_indicator?: Maybe<X12Code>;
  language_use_indicator?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type LedgerAccount = {
  __typename?: 'LedgerAccount';
  internal_routing_proxy_ids: Scalars['String'];
  outbound_payment_proxy_id: Scalars['String'];
  payment_preferences?: Maybe<Array<Maybe<PaymentPreferences>>>;
  primary: Scalars['Boolean'];
  recipient_payment_preference?: Maybe<Array<Maybe<PaymentPreferences>>>;
};

export type LedgerAccountInput = {
  internal_routing_proxy_ids: Scalars['String'];
  outbound_payment_proxy_id: Scalars['String'];
  payment_preferences?: InputMaybe<Array<InputMaybe<PaymentPreferencesInput>>>;
  primary: Scalars['Boolean'];
};

export type LegalOrganization = {
  __typename?: 'LegalOrganization';
  legal_organization_type?: Maybe<LegalOrganizationType>;
};

export type LegalOrganizationInput = {
  legal_organization_type?: InputMaybe<LegalOrganizationType>;
};

export enum LegalOrganizationType {
  Affiliate = 'AFFILIATE',
  Parent = 'PARENT',
  Subsidiary = 'SUBSIDIARY',
}

export enum LimitType {
  Combined = 'COMBINED',
  PerMember = 'PER_MEMBER',
}

export type LineAdjudication = {
  __typename?: 'LineAdjudication';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  claim_adjustment?: Maybe<Array<Maybe<LineAdjustment>>>;
  dates?: Maybe<Array<Maybe<Date>>>;
  loop_metadata?: Maybe<X12Metadata>;
  service_line_adjudication?: Maybe<LineAdjudicationInformation>;
};

export type LineAdjudication837I = {
  __typename?: 'LineAdjudication837I';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  claim_adjustments?: Maybe<Array<Maybe<LineAdjustment>>>;
  dates?: Maybe<Array<Maybe<Date>>>;
  loop_metadata?: Maybe<X12Metadata>;
  service_line_adjudication?: Maybe<LineAdjudicationInformation>;
};

export type LineAdjudication837IInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  claim_adjustments?: InputMaybe<Array<InputMaybe<LineAdjustmentInput>>>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  service_line_adjudication?: InputMaybe<LineAdjudicationInformationInput>;
};

export type LineAdjudicationInformation = {
  __typename?: 'LineAdjudicationInformation';
  bundled_line_number?: Maybe<Scalars['String']>;
  composite_medical_procedure_identifier?: Maybe<CompositeMedicalProcedure>;
  other_payer_primary_id?: Maybe<Scalars['String']>;
  paid_service_unit_code?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
  service_line_paid_amount?: Maybe<FloatWrapper>;
  service_line_revenue_code?: Maybe<Scalars['String']>;
};

export type LineAdjudicationInformationInput = {
  bundled_line_number?: InputMaybe<Scalars['String']>;
  composite_medical_procedure_identifier?: InputMaybe<CompositeMedicalProcedureInput>;
  other_payer_primary_id?: InputMaybe<Scalars['String']>;
  paid_service_unit_code?: InputMaybe<FloatWrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  service_line_paid_amount?: InputMaybe<FloatWrapperInput>;
  service_line_revenue_code?: InputMaybe<Scalars['String']>;
};

export type LineAdjudicationInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  claim_adjustment?: InputMaybe<Array<InputMaybe<LineAdjustmentInput>>>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  service_line_adjudication?: InputMaybe<LineAdjudicationInformationInput>;
};

export type LineAdjustment = {
  __typename?: 'LineAdjustment';
  adjustment_amount_1?: Maybe<FloatWrapper>;
  adjustment_amount_2?: Maybe<FloatWrapper>;
  adjustment_amount_3?: Maybe<FloatWrapper>;
  adjustment_amount_4?: Maybe<FloatWrapper>;
  adjustment_amount_5?: Maybe<FloatWrapper>;
  adjustment_amount_6?: Maybe<FloatWrapper>;
  adjustment_quantity_1?: Maybe<FloatWrapper>;
  adjustment_quantity_2?: Maybe<FloatWrapper>;
  adjustment_quantity_3?: Maybe<FloatWrapper>;
  adjustment_quantity_4?: Maybe<FloatWrapper>;
  adjustment_quantity_5?: Maybe<FloatWrapper>;
  adjustment_quantity_6?: Maybe<FloatWrapper>;
  adjustment_reason_code_1?: Maybe<Scalars['String']>;
  adjustment_reason_code_2?: Maybe<Scalars['String']>;
  adjustment_reason_code_3?: Maybe<Scalars['String']>;
  adjustment_reason_code_4?: Maybe<Scalars['String']>;
  adjustment_reason_code_5?: Maybe<Scalars['String']>;
  adjustment_reason_code_6?: Maybe<Scalars['String']>;
  claim_adjustment_group_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type LineAdjustmentInput = {
  adjustment_amount_1?: InputMaybe<FloatWrapperInput>;
  adjustment_amount_2?: InputMaybe<FloatWrapperInput>;
  adjustment_amount_3?: InputMaybe<FloatWrapperInput>;
  adjustment_amount_4?: InputMaybe<FloatWrapperInput>;
  adjustment_amount_5?: InputMaybe<FloatWrapperInput>;
  adjustment_amount_6?: InputMaybe<FloatWrapperInput>;
  adjustment_quantity_1?: InputMaybe<FloatWrapperInput>;
  adjustment_quantity_2?: InputMaybe<FloatWrapperInput>;
  adjustment_quantity_3?: InputMaybe<FloatWrapperInput>;
  adjustment_quantity_4?: InputMaybe<FloatWrapperInput>;
  adjustment_quantity_5?: InputMaybe<FloatWrapperInput>;
  adjustment_quantity_6?: InputMaybe<FloatWrapperInput>;
  adjustment_reason_code_1?: InputMaybe<Scalars['String']>;
  adjustment_reason_code_2?: InputMaybe<Scalars['String']>;
  adjustment_reason_code_3?: InputMaybe<Scalars['String']>;
  adjustment_reason_code_4?: InputMaybe<Scalars['String']>;
  adjustment_reason_code_5?: InputMaybe<Scalars['String']>;
  adjustment_reason_code_6?: InputMaybe<Scalars['String']>;
  claim_adjustment_group_code?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type LineHeader = {
  __typename?: 'LineHeader';
  assigned_number?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type LineHeaderInput = {
  assigned_number?: InputMaybe<Uint32WrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type LineItem = {
  __typename?: 'LineItem';
  ambulance_certification?: Maybe<AmbulanceCertification>;
  ambulance_dropoff_location?: Maybe<AmbulanceDropoffLocation>;
  ambulance_pickup_location?: Maybe<AmbulancePickupLocation>;
  amounts?: Maybe<Array<Maybe<Amount>>>;
  claim_pricing_info?: Maybe<ClaimPricingRepricingInformation>;
  conditions?: Maybe<Array<Maybe<ConditionIndicatorSegment>>>;
  contract_info?: Maybe<ContractInformation>;
  dates?: Maybe<Array<Maybe<Date>>>;
  dme_certification?: Maybe<DmeCertification>;
  dme_service?: Maybe<DmeService>;
  drug_identification?: Maybe<DrugIdentification>;
  file_info?: Maybe<Array<Maybe<FileInformation>>>;
  form_identification?: Maybe<Array<Maybe<FormIdentification>>>;
  header_number?: Maybe<LineHeader>;
  line_adjudication?: Maybe<Array<Maybe<LineAdjudication>>>;
  loop_metadata?: Maybe<X12Metadata>;
  notes?: Maybe<Array<Maybe<SpecialInstruction>>>;
  ordering_provider?: Maybe<OrderingProvider>;
  purchased_service_info?: Maybe<PurchasedServiceInfo>;
  purchased_service_provider?: Maybe<PurchasedServiceProvider>;
  pwk?: Maybe<Array<Maybe<ClaimSupplementalInformation>>>;
  qty?: Maybe<Array<Maybe<Quantity>>>;
  reference?: Maybe<Array<Maybe<Reference>>>;
  referring_provider?: Maybe<ReferringProvider>;
  rendering_provider?: Maybe<RenderingProvider>;
  service?: Maybe<ProfessionalService>;
  service_facility_location?: Maybe<ServiceFacilityLocation>;
  supervising_provider?: Maybe<SupervisingProvider>;
  test_results?: Maybe<Array<Maybe<TestResult>>>;
};

export type LineItem837I = {
  __typename?: 'LineItem837I';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  claim_pricing_info?: Maybe<ClaimPricingRepricingInformation>;
  dates?: Maybe<Array<Maybe<Date>>>;
  drug_id?: Maybe<DrugId>;
  header_number?: Maybe<LineHeader>;
  line_adjudication?: Maybe<Array<Maybe<LineAdjudication837I>>>;
  loop_metadata?: Maybe<X12Metadata>;
  notes?: Maybe<Array<Maybe<SpecialInstruction>>>;
  operating_physician?: Maybe<PhysicianProviderName>;
  other_operating_physician?: Maybe<PhysicianProviderName>;
  pwk?: Maybe<Array<Maybe<ClaimSupplementalInformation>>>;
  reference?: Maybe<Array<Maybe<Reference>>>;
  referring_provider?: Maybe<PhysicianProviderName>;
  rendering_provider?: Maybe<PhysicianProviderName>;
  service_line?: Maybe<IntstitutionalServiceLine>;
};

export type LineItem837IInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  claim_pricing_info?: InputMaybe<ClaimPricingRepricingInformationInput>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  drug_id?: InputMaybe<DrugIdInput>;
  header_number?: InputMaybe<LineHeaderInput>;
  line_adjudication?: InputMaybe<Array<InputMaybe<LineAdjudication837IInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  notes?: InputMaybe<Array<InputMaybe<SpecialInstructionInput>>>;
  operating_physician?: InputMaybe<PhysicianProviderNameInput>;
  other_operating_physician?: InputMaybe<PhysicianProviderNameInput>;
  pwk?: InputMaybe<Array<InputMaybe<ClaimSupplementalInformationInput>>>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  referring_provider?: InputMaybe<PhysicianProviderNameInput>;
  rendering_provider?: InputMaybe<PhysicianProviderNameInput>;
  service_line?: InputMaybe<IntstitutionalServiceLineInput>;
};

export type LineItemInput = {
  ambulance_certification?: InputMaybe<AmbulanceCertificationInput>;
  ambulance_dropoff_location?: InputMaybe<AmbulanceDropoffLocationInput>;
  ambulance_pickup_location?: InputMaybe<AmbulancePickupLocationInput>;
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  claim_pricing_info?: InputMaybe<ClaimPricingRepricingInformationInput>;
  conditions?: InputMaybe<Array<InputMaybe<ConditionIndicatorSegmentInput>>>;
  contract_info?: InputMaybe<ContractInformationInput>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  dme_certification?: InputMaybe<DmeCertificationInput>;
  dme_service?: InputMaybe<DmeServiceInput>;
  drug_identification?: InputMaybe<DrugIdentificationInput>;
  file_info?: InputMaybe<Array<InputMaybe<FileInformationInput>>>;
  form_identification?: InputMaybe<Array<InputMaybe<FormIdentificationInput>>>;
  header_number?: InputMaybe<LineHeaderInput>;
  line_adjudication?: InputMaybe<Array<InputMaybe<LineAdjudicationInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  notes?: InputMaybe<Array<InputMaybe<SpecialInstructionInput>>>;
  ordering_provider?: InputMaybe<OrderingProviderInput>;
  purchased_service_info?: InputMaybe<PurchasedServiceInfoInput>;
  purchased_service_provider?: InputMaybe<PurchasedServiceProviderInput>;
  pwk?: InputMaybe<Array<InputMaybe<ClaimSupplementalInformationInput>>>;
  qty?: InputMaybe<Array<InputMaybe<QuantityInput>>>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  referring_provider?: InputMaybe<ReferringProviderInput>;
  rendering_provider?: InputMaybe<RenderingProviderInput>;
  service?: InputMaybe<ProfessionalServiceInput>;
  service_facility_location?: InputMaybe<ServiceFacilityLocationInput>;
  supervising_provider?: InputMaybe<SupervisingProviderInput>;
  test_results?: InputMaybe<Array<InputMaybe<TestResultInput>>>;
};

export type LineItems = {
  __typename?: 'LineItems';
  charge?: Maybe<Scalars['Float']>;
  charge_pennies?: Maybe<Scalars['Int']>;
  charged_code?: Maybe<CodedMedicalService>;
  place_of_service?: Maybe<PlaceOfService>;
  provider_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  service_type?: Maybe<Scalars['String']>;
};

export type LineItemsInput = {
  charge?: InputMaybe<Scalars['Float']>;
  charge_pennies?: InputMaybe<Scalars['Int']>;
  charged_code?: InputMaybe<CodedMedicalServiceInput>;
  diagnosis_codes?: InputMaybe<Array<InputMaybe<CodedMedicalServiceInput>>>;
  from_date?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  raw_charged_code?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export enum LineOfBusinessType {
  LobCcn = 'LOB_CCN',
  LobFrn = 'LOB_FRN',
  LobPaymentOnly = 'LOB_PAYMENT_ONLY',
  LobTw = 'LOB_TW',
  LobUnknown = 'LOB_UNKNOWN',
}

export type Location = {
  __typename?: 'Location';
  geolocation?: Maybe<Geolocation>;
  postal_address?: Maybe<PostalAddress>;
};

export type LocationCreateInput = {
  email_address?: InputMaybe<EmailAddressInput>;
  location_input: LocationInput;
  name: Scalars['String'];
  npi: Scalars['String'];
  phone_number?: InputMaybe<PhoneNumberInput>;
  street_address_2?: InputMaybe<Scalars['String']>;
  taxonomy_codes?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<PracticeFacilityType>;
  website?: InputMaybe<WebsiteAddressInput>;
};

export type LocationInput = {
  postal_address?: InputMaybe<PostalAddressInput>;
};

export type LoopTrailer = {
  __typename?: 'LoopTrailer';
  loop_identifier_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type MedicalCode = {
  __typename?: 'MedicalCode';
  children_categories?: Maybe<Array<Scalars['ID']>>;
  excludes_1_notes: Scalars['String'];
  excludes_2_notes: Scalars['String'];
  included_ranges?: Maybe<IncludedRanges>;
  inclusion_terms: Scalars['String'];
  instructions: Scalars['String'];
  isa_category: Scalars['Boolean'];
  isa_modifier: Scalars['Boolean'];
  medical_codeset: MedicalCodeSetType;
  medicare_coverage_instruction: CoverageInstruction;
  medium_description: Scalars['String'];
  note: Scalars['String'];
  parent_category: Scalars['ID'];
  short_description: Scalars['String'];
};

export type MedicalCodeGroup = IDocument & {
  __typename?: 'MedicalCodeGroup';
  _id: Scalars['ID'];
  anchorIndividualCodes?: Maybe<Array<Scalars['String']>>;
  anchorRangeCodes?: Maybe<Array<Maybe<IncludedRanges>>>;
  audit?: Maybe<Audit>;
  complementaryIndividualCode?: Maybe<Array<Scalars['String']>>;
  complementaryRangeCodes?: Maybe<Array<Maybe<IncludedRanges>>>;
  description: Scalars['String'];
  includedCodes?: Maybe<Array<Maybe<IncludedCode>>>;
  includedIndividualCodes?: Maybe<Array<Scalars['String']>>;
  includedRangeCodes?: Maybe<Array<Maybe<IncludedRanges>>>;
  includedRanges?: Maybe<Array<Maybe<IncludedRanges>>>;
  medicalCodeGroupType: MedicalCodeGroupType;
  name: Scalars['String'];
};

export enum MedicalCodeGroupType {
  MctBundle = 'MCT_BUNDLE',
  MctCodeGroup = 'MCT_CODE_GROUP',
  MctUnknown = 'MCT_UNKNOWN',
}

export type MedicalCodeInput = {
  included_ranges?: InputMaybe<IncludedRangesInput>;
  isa_category?: InputMaybe<Scalars['Boolean']>;
  medical_codeset: MedicalCodeSetType;
};

export enum MedicalCodeSetType {
  Abf = 'ABF',
  Abk = 'ABK',
  Cdt = 'CDT',
  CodeSetUnknown = 'CODE_SET_UNKNOWN',
  Cpt = 'CPT',
  Fake = 'FAKE',
  Hcpcs = 'HCPCS',
  IcdCm = 'ICD_CM',
  IcdPcs = 'ICD_PCS',
  Ndc = 'NDC',
}

export type MedicareStatusCode = {
  __typename?: 'MedicareStatusCode';
  element_metadata?: Maybe<X12Metadata>;
  eligibility_reason_code_1?: Maybe<X12Code>;
  eligibility_reason_code_2?: Maybe<X12Code>;
  eligibility_reason_code_3?: Maybe<X12Code>;
  medicare_plan_code?: Maybe<X12Code>;
};

export type Member = IDocument & {
  __typename?: 'Member';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  audit_logs?: Maybe<Array<MemberOnboardingAuditLog>>;
  card?: Maybe<Card>;
  card_id: Scalars['String'];
  card_info?: Maybe<Array<Maybe<Card>>>;
  coverage_dates?: Maybe<Array<Maybe<EffectiveDates>>>;
  coverage_end_date?: Maybe<Scalars['String']>;
  coverage_start_date?: Maybe<Scalars['String']>;
  dependent_members?: Maybe<Array<Member>>;
  dependents?: Maybe<Array<Scalars['String']>>;
  employee_id: Scalars['String'];
  encounters?: Maybe<Array<Encounter>>;
  external_ids?: Maybe<Array<Maybe<MemberExternalId>>>;
  external_reference_health_id?: Maybe<Scalars['String']>;
  lifecycle_state?: Maybe<MemberLifecycleState>;
  member_id: Scalars['String'];
  member_relationship?: Maybe<MemberRelationship>;
  onboarding_state?: Maybe<MemberOnboardingState>;
  party?: Maybe<Party>;
  party_id: Scalars['String'];
  payment_documentation?: Maybe<Array<PaymentDocumentation>>;
  plan_code: Scalars['String'];
  plan_utilizations?: Maybe<PlanUtilization>;
  primary_member_employee_id?: Maybe<Scalars['String']>;
  service_time?: Maybe<Scalars['String']>;
  sponsor?: Maybe<Sponsor>;
  sponsor_id: Scalars['ID'];
  subscriber_id: Scalars['String'];
};

export type MemberBatchGroupProcessingInfo = {
  __typename?: 'MemberBatchGroupProcessingInfo';
  audit_logs?: Maybe<Array<MemberRosterBatchGroupAuditLog>>;
  member_roster_batch_group_state?: Maybe<MemberRosterBatchGroupState>;
  processing_error: Scalars['String'];
};

export type MemberCounts = {
  __typename?: 'MemberCounts';
  dependent?: Maybe<Scalars['Int']>;
  primary?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type MemberCountsInput = {
  dependent?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
};

export type MemberExternalId = {
  __typename?: 'MemberExternalId';
  id_number: Scalars['String'];
  id_type: ExternalMemberType;
};

export enum MemberFormatType {
  MruNetworks = 'MRU_NETWORKS',
  MruNomi = 'MRU_NOMI',
  MruQuest = 'MRU_QUEST',
  MruTellus = 'MRU_TELLUS',
  UnknownFormatType = 'UNKNOWN_FORMAT_TYPE',
}

export type MemberInput = {
  card_id?: InputMaybe<Scalars['String']>;
  party_id?: InputMaybe<Scalars['String']>;
};

export enum MemberLifecycleState {
  Active = 'ACTIVE',
  Identified = 'IDENTIFIED',
  Onboarding = 'ONBOARDING',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN',
}

export type MemberOnboardingAuditLog = {
  __typename?: 'MemberOnboardingAuditLog';
  member_onboarding_state_transition?: Maybe<MemberOnboardingStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export enum MemberOnboardingState {
  OAddedToSponsorPlan = 'O_ADDED_TO_SPONSOR_PLAN',
  OAlloyVerificationAdded = 'O_ALLOY_VERIFICATION_ADDED',
  OCreated = 'O_CREATED',
  OFailed = 'O_FAILED',
  OIncreaseVirtualCardCreated = 'O_INCREASE_VIRTUAL_CARD_CREATED',
  OMemberAlloyRequiredFieldsChecked = 'O_MEMBER_ALLOY_REQUIRED_FIELDS_CHECKED',
  OMemberSsnAndEmailChecked = 'O_MEMBER_SSN_AND_EMAIL_CHECKED',
  OMemberSsnChecked = 'O_MEMBER_SSN_CHECKED',
  OPaymentPreferenceAdded = 'O_PAYMENT_PREFERENCE_ADDED',
  OPingEntityCreated = 'O_PING_ENTITY_CREATED',
  OPlanUtilizationCreated = 'O_PLAN_UTILIZATION_CREATED',
  OPrimaryLedgerCreated = 'O_PRIMARY_LEDGER_CREATED',
  OStripeAccountCreated = 'O_STRIPE_ACCOUNT_CREATED',
  OUnknown = 'O_UNKNOWN',
  OWelcomeEmailSent = 'O_WELCOME_EMAIL_SENT',
}

export type MemberOnboardingStateTransition = {
  __typename?: 'MemberOnboardingStateTransition';
  from_state?: Maybe<MemberOnboardingState>;
  to_state?: Maybe<MemberOnboardingState>;
};

export enum MemberRelationship {
  Child = 'CHILD',
  Dependent = 'DEPENDENT',
  DomesticPartner = 'DOMESTIC_PARTNER',
  Primary = 'PRIMARY',
  Spouse = 'SPOUSE',
  Undefined = 'UNDEFINED',
}

export type MemberRosterBatchGroupAuditLog = {
  __typename?: 'MemberRosterBatchGroupAuditLog';
  member_batch_group_state_transition?: Maybe<MemberRosterBatchGroupStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export enum MemberRosterBatchGroupState {
  MbgContactsCreated = 'MBG_CONTACTS_CREATED',
  MbgCreated = 'MBG_CREATED',
  MbgFailed = 'MBG_FAILED',
  MbgMembersCreated = 'MBG_MEMBERS_CREATED',
  MbgMembersWithSponsorPlanAssociated = 'MBG_MEMBERS_WITH_SPONSOR_PLAN_ASSOCIATED',
  MbgMemberDependentsWithPrimaryAssociated = 'MBG_MEMBER_DEPENDENTS_WITH_PRIMARY_ASSOCIATED',
  MbgMemberOnboardingStarted = 'MBG_MEMBER_ONBOARDING_STARTED',
  MbgPartiesCreated = 'MBG_PARTIES_CREATED',
  MbgPartyDependentsWithPrimaryAssociated = 'MBG_PARTY_DEPENDENTS_WITH_PRIMARY_ASSOCIATED',
  MbgSuccess = 'MBG_SUCCESS',
  MbgUnknown = 'MBG_UNKNOWN',
  MbgUsersCreated = 'MBG_USERS_CREATED',
}

export type MemberRosterBatchGroupStateTransition = {
  __typename?: 'MemberRosterBatchGroupStateTransition';
  from_state?: Maybe<MemberRosterBatchGroupState>;
  to_state?: Maybe<MemberRosterBatchGroupState>;
};

export type MemberRosterDocumentError = {
  __typename?: 'MemberRosterDocumentError';
  error_messages?: Maybe<Array<Scalars['String']>>;
  field_name?: Maybe<Scalars['String']>;
};

export type MemberRosterDocumentErrorInput = {
  error_messages?: InputMaybe<Array<Scalars['String']>>;
  field_name?: InputMaybe<Scalars['String']>;
};

export enum MemberRosterDocumentErrorType {
  MruDocumentMetadataErrorType = 'MRU_DOCUMENT_METADATA_ERROR_TYPE',
  MruDocumentRowProcessingErrorType = 'MRU_DOCUMENT_ROW_PROCESSING_ERROR_TYPE',
  MruDocumentTemplateErrorType = 'MRU_DOCUMENT_TEMPLATE_ERROR_TYPE',
  MruDocumentValidationErrorType = 'MRU_DOCUMENT_VALIDATION_ERROR_TYPE',
  MruUnknownDocumentErrorType = 'MRU_UNKNOWN_DOCUMENT_ERROR_TYPE',
}

export type MemberRosterDocumentSummary = {
  __typename?: 'MemberRosterDocumentSummary';
  document_error_type?: Maybe<MemberRosterDocumentErrorType>;
  document_errors?: Maybe<Array<MemberRosterDocumentError>>;
  group_id?: Maybe<Scalars['String']>;
  related_person_id?: Maybe<Scalars['String']>;
  roster_id?: Maybe<Scalars['String']>;
  row_id?: Maybe<Scalars['String']>;
  status?: Maybe<MemberRosterProcessingStatus>;
  team_member_id?: Maybe<Scalars['String']>;
};

export type MemberRosterDocumentSummaryInput = {
  document_error_type?: InputMaybe<MemberRosterDocumentErrorType>;
  document_errors?: InputMaybe<Array<MemberRosterDocumentErrorInput>>;
  group_id?: InputMaybe<Scalars['String']>;
  related_person_id?: InputMaybe<Scalars['String']>;
  roster_id?: InputMaybe<Scalars['String']>;
  row_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MemberRosterProcessingStatus>;
  team_member_id?: InputMaybe<Scalars['String']>;
};

export type MemberRosterProcessingInfo = {
  __typename?: 'MemberRosterProcessingInfo';
  audit_logs?: Maybe<Array<MemberRosterUploadAuditLog>>;
  batch_ids?: Maybe<Array<Scalars['String']>>;
  document_summary?: Maybe<Array<MemberRosterDocumentSummary>>;
  error?: Maybe<Scalars['Boolean']>;
  format_type?: Maybe<MemberFormatType>;
  member_counts?: Maybe<MemberCounts>;
  member_roster_upload_state?: Maybe<MemberRosterUploadState>;
  platform_id: Scalars['String'];
  processed_count?: Maybe<Scalars['Int']>;
  processed_member_counts?: Maybe<MemberCounts>;
  role_membership_id: Scalars['String'];
  sponsor_id?: Maybe<Scalars['String']>;
  sponsor_plans?: Maybe<Array<Scalars['String']>>;
  success_row_count?: Maybe<Scalars['Int']>;
  total_records?: Maybe<Scalars['Int']>;
  upload_name?: Maybe<Scalars['String']>;
};

export type MemberRosterProcessingInfoInput = {
  audit_logs?: InputMaybe<Array<MemberRosterUploadAuditLogInput>>;
  batch_ids?: InputMaybe<Array<Scalars['String']>>;
  document_summary?: InputMaybe<Array<MemberRosterDocumentSummaryInput>>;
  error?: InputMaybe<Scalars['Boolean']>;
  format_type?: InputMaybe<MemberFormatType>;
  member_roster_upload_state?: InputMaybe<MemberRosterUploadState>;
  platform_id: Scalars['String'];
  processed_count?: InputMaybe<Scalars['Int']>;
  role_membership_id: Scalars['String'];
  sponsor_id?: InputMaybe<Scalars['String']>;
  sponsor_plans?: InputMaybe<Array<Scalars['String']>>;
  success_row_count?: InputMaybe<Scalars['Int']>;
  total_records?: InputMaybe<Scalars['Int']>;
  upload_name?: InputMaybe<Scalars['String']>;
};

export enum MemberRosterProcessingStatus {
  MruProcessingStatusError = 'MRU_PROCESSING_STATUS_ERROR',
  MruProcessingStatusSuccess = 'MRU_PROCESSING_STATUS_SUCCESS',
  MruProcessingStatusUnknown = 'MRU_PROCESSING_STATUS_UNKNOWN',
}

export type MemberRosterUploadAuditLog = {
  __typename?: 'MemberRosterUploadAuditLog';
  member_roster_upload_state_transition?: Maybe<MemberRosterUploadStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export type MemberRosterUploadAuditLogInput = {
  member_roster_upload_state_transition?: InputMaybe<MemberRosterUploadStateTransitionInput>;
  transitioned_at_time?: InputMaybe<Scalars['String']>;
};

export enum MemberRosterUploadState {
  MruContactsCreated = 'MRU_CONTACTS_CREATED',
  MruCreated = 'MRU_CREATED',
  MruDependentPartiesCreated = 'MRU_DEPENDENT_PARTIES_CREATED',
  MruFailed = 'MRU_FAILED',
  MruFileValidated = 'MRU_FILE_VALIDATED',
  MruFileValidationFailed = 'MRU_FILE_VALIDATION_FAILED',
  MruMembersCreated = 'MRU_MEMBERS_CREATED',
  MruMemberDependentsAdded = 'MRU_MEMBER_DEPENDENTS_ADDED',
  MruMemberDependentsCreated = 'MRU_MEMBER_DEPENDENTS_CREATED',
  MruMemberDependentsRelationshipEstablished = 'MRU_MEMBER_DEPENDENTS_RELATIONSHIP_ESTABLISHED',
  MruMemberOnboardingCompleted = 'MRU_MEMBER_ONBOARDING_COMPLETED',
  MruMemberPartiesCreated = 'MRU_MEMBER_PARTIES_CREATED',
  MruMetadataValidated = 'MRU_METADATA_VALIDATED',
  MruPartiesCreated = 'MRU_PARTIES_CREATED',
  MruSuccess = 'MRU_SUCCESS',
  MruUnknown = 'MRU_UNKNOWN',
  MruWelcomeEmailSent = 'MRU_WELCOME_EMAIL_SENT',
}

export type MemberRosterUploadStateTransition = {
  __typename?: 'MemberRosterUploadStateTransition';
  from_state?: Maybe<MemberRosterUploadState>;
  to_state?: Maybe<MemberRosterUploadState>;
};

export type MemberRosterUploadStateTransitionInput = {
  from_state?: InputMaybe<MemberRosterUploadState>;
  to_state?: InputMaybe<MemberRosterUploadState>;
};

export type MfaDevice = {
  __typename?: 'MfaDevice';
  contact_id: Scalars['ID'];
};

export type MultipleBundleException = {
  __typename?: 'MultipleBundleException';
  bundled_fee_sch_entries: BundledFeeScheduleExceptionEntries;
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateRawClaimAndInitializeInvoice?: Maybe<RawClaim>;
  CreateRawRemittanceAndPriceInvoice?: Maybe<RawRemittance>;
  FAKE_createFinancialAccount?: Maybe<FinancialAccount>;
  OnboardRCMBankAccount?: Maybe<FinancialAccount>;
  acceptPolicy?: Maybe<Party>;
  acceptToken?: Maybe<Invite>;
  addEmail?: Maybe<AddEmailResponse>;
  addGovernmentId?: Maybe<Party>;
  addNewRCMUser?: Maybe<EntitlementOutput>;
  addParentId?: Maybe<Party>;
  addPhone?: Maybe<Party>;
  addPlantoSponsor?: Maybe<Sponsor>;
  addPostalAddress?: Maybe<Party>;
  addRCMSharedBankAccountToOrganization?: Maybe<RcmBankAccountInfo>;
  addRCMUserByExistingUser?: Maybe<EntitlementOutput>;
  addRoleMemberships?: Maybe<User>;
  attachOrgToRCMUser?: Maybe<EntitlementOutput>;
  bankAccountOnBoarding?: Maybe<FinancialAccount>;
  confirmPaymentIntent?: Maybe<ConfirmPaymentIntentResponse>;
  createAndOnboardUser?: Maybe<User>;
  createAndOnboardUsers?: Maybe<Array<Maybe<User>>>;
  createContract?: Maybe<Contract>;
  createETLMetadata?: Maybe<EtlMetadata>;
  createEncounter?: Maybe<CreateEncounterResponse>;
  createFeeScheduleV2?: Maybe<FeeScheduleV2>;
  createFileUpload?: Maybe<FileUpload>;
  createLocation?: Maybe<PracticeFacility>;
  createManualClaimInvoice?: Maybe<Invoice>;
  createNewUser?: Maybe<User>;
  createOrUpdateParty?: Maybe<Party>;
  createPDFDownloadLink?: Maybe<Scalars['String']>;
  createParties?: Maybe<Array<Maybe<Party>>>;
  createParty?: Maybe<Party>;
  createPartyForUser?: Maybe<Party>;
  createPaymentClient?: Maybe<PaymentClient>;
  createPaymentIntent?: Maybe<StripePaymentIntent>;
  createProvider?: Maybe<Provider>;
  createProviderGroup?: Maybe<ProviderGroup>;
  createProviderGroupContractMetadata?: Maybe<CreateProviderGroupContractMetadataResponse>;
  createProviderPortalUser?: Maybe<EntitlementOutput>;
  createReferenceCodes?: Maybe<Array<Maybe<Result>>>;
  deleteAccount?: Maybe<FinancialAccount>;
  editSponsorPlan?: Maybe<Sponsor>;
  firstOrCreateContact?: Maybe<Contact>;
  firstOrCreateFacility?: Maybe<Facility>;
  generateToken?: Maybe<Invite>;
  getS3PresignedPutURL?: Maybe<S3PresignedPutUrlOutput>;
  onboardProviderGroup?: Maybe<OnboardProviderGroupResponse>;
  onboardProviderGroupForPayment?: Maybe<ProviderGroup>;
  onboardSponsor?: Maybe<Sponsor>;
  payBill?: Maybe<Remittance>;
  ping: Scalars['Boolean'];
  removeRCMBankAccountFromUser?: Maybe<StatusOutput>;
  removeRCMPermissionFromUser?: Maybe<EntitlementOutput>;
  removeRoleMemberships?: Maybe<User>;
  reprocessRemittanceGroupState?: Maybe<ReprocessRemitGroupStateResponse>;
  revertAndResubmitInvoice?: Maybe<InvoiceWithErr>;
  sendAlertEmailToSupport?: Maybe<SendEmailResponse>;
  sendFeedbackEmail?: Maybe<SendEmailResponse>;
  setRecipientPaymentPreference?: Maybe<Party>;
  stripeRemovePaymentMethod?: Maybe<DeletedBoolResponse>;
  stripeSetupIntent?: Maybe<SetupIntent>;
  submitPreInvoice?: Maybe<Scalars['String']>;
  submitRemittance?: Maybe<Remittance>;
  transitionInvoice?: Maybe<InvoiceWithErr>;
  triggerAlloyCheck?: Maybe<ComplianceInfo>;
  updateContactInfo?: Maybe<Party>;
  updateContract?: Maybe<UpdateContractResponse>;
  updateEmployerGroupContract?: Maybe<Contract>;
  updateEncounter?: Maybe<Encounter>;
  updateFileUpload?: Maybe<FileUpload>;
  updateInvoice?: Maybe<UpdateInvoiceResponse>;
  updateLocation?: Maybe<PracticeFacility>;
  updateProvider?: Maybe<Provider>;
  updateSponsor?: Maybe<Sponsor>;
  uploadContractFile?: Maybe<Contract>;
};

export type MutationCreateRawClaimAndInitializeInvoiceArgs = {
  rawClaimInput: RawClaimInput;
};

export type MutationCreateRawRemittanceAndPriceInvoiceArgs = {
  rawRemittanceInput: RawRemittanceInput;
};

export type MutationFake_CreateFinancialAccountArgs = {
  input: Fake_FinancialAccountInput;
};

export type MutationOnboardRcmBankAccountArgs = {
  bankAccountOnboardingRequest: BankAccountOnBoardingInput;
};

export type MutationAcceptPolicyArgs = {
  partyId: Scalars['ID'];
  policyType: PolicyType;
};

export type MutationAcceptTokenArgs = {
  token: Scalars['String'];
  type: TokenType;
};

export type MutationAddEmailArgs = {
  email: AddEmailInput;
  partyId: Scalars['ID'];
};

export type MutationAddGovernmentIdArgs = {
  governmentId: GovernmentIdInput;
  partyID: Scalars['ID'];
};

export type MutationAddNewRcmUserArgs = {
  RCMInput: CreateNewRcmUserByAdmin;
};

export type MutationAddParentIdArgs = {
  parent: AddParentInput;
  partyID: Scalars['ID'];
};

export type MutationAddPhoneArgs = {
  partyId: Scalars['ID'];
  phone: AddPhoneInput;
};

export type MutationAddPlantoSponsorArgs = {
  plan?: InputMaybe<PlanInput>;
  sponsorId: Scalars['String'];
};

export type MutationAddPostalAddressArgs = {
  address: PostalAddressInput;
  partyId: Scalars['ID'];
};

export type MutationAddRcmSharedBankAccountToOrganizationArgs = {
  orgPartyId: Scalars['ID'];
  orgWithBankAccountInput: OrgWithBankAccountInput;
};

export type MutationAddRcmUserByExistingUserArgs = {
  RCMInput: CreateNewRcmUserByAdmin;
};

export type MutationAddRoleMembershipsArgs = {
  roleMembershipIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type MutationAttachOrgToRcmUserArgs = {
  RCMInput: AttachOrgToRcmUserInput;
};

export type MutationBankAccountOnBoardingArgs = {
  bankAccountOnboardingRequest: BankAccountOnBoardingInput;
};

export type MutationConfirmPaymentIntentArgs = {
  confirmPaymentIntentInput?: InputMaybe<ConfirmPaymentIntent>;
};

export type MutationCreateAndOnboardUserArgs = {
  user?: InputMaybe<CreateUserInput>;
};

export type MutationCreateAndOnboardUsersArgs = {
  usersInput?: InputMaybe<Array<InputMaybe<CreateUserInput>>>;
};

export type MutationCreateContractArgs = {
  createContractInput: ContractInput;
};

export type MutationCreateEtlMetadataArgs = {
  etlMetadata?: InputMaybe<EtlMetadataInput>;
};

export type MutationCreateEncounterArgs = {
  encounter?: InputMaybe<EncounterCreateInput>;
};

export type MutationCreateFeeScheduleV2Args = {
  input: FeeScheduleV2Input;
};

export type MutationCreateFileUploadArgs = {
  input: FileUploadInput;
};

export type MutationCreateLocationArgs = {
  locationInput: LocationCreateInput;
  pgId: Scalars['ID'];
};

export type MutationCreateManualClaimInvoiceArgs = {
  createInvoiceInput: CreateInvoiceInput;
};

export type MutationCreateNewUserArgs = {
  userInput: CreateUserInput;
};

export type MutationCreateOrUpdatePartyArgs = {
  partyInput: PartyInput;
};

export type MutationCreatePdfDownloadLinkArgs = {
  createLinkInput: CreateLinkInput;
};

export type MutationCreatePartiesArgs = {
  partiesInput: Array<PartyInput>;
};

export type MutationCreatePartyArgs = {
  partyInput: PartyInput;
};

export type MutationCreatePartyForUserArgs = {
  partyInput: PartyInput;
};

export type MutationCreatePaymentClientArgs = {
  paymentClientInput?: InputMaybe<PaymentClientInput>;
};

export type MutationCreatePaymentIntentArgs = {
  billId: Scalars['ID'];
  confirm: Scalars['Boolean'];
  partyId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};

export type MutationCreateProviderArgs = {
  providerInput: ProviderInput;
};

export type MutationCreateProviderGroupArgs = {
  providerGroupInput: CreateProviderGroupInput;
};

export type MutationCreateProviderGroupContractMetadataArgs = {
  bucketName: Scalars['String'];
  formatType?: InputMaybe<FormatType>;
  networkName?: InputMaybe<Scalars['String']>;
  objectKey: Scalars['String'];
  providerGroupID: Scalars['ID'];
};

export type MutationCreateProviderPortalUserArgs = {
  input: ProviderPortalUserInput;
};

export type MutationCreateReferenceCodesArgs = {
  codes?: InputMaybe<Array<InputMaybe<ReferenceCodeInput>>>;
};

export type MutationDeleteAccountArgs = {
  accountID: Scalars['ID'];
  partyID: Scalars['ID'];
};

export type MutationEditSponsorPlanArgs = {
  input: EditSponsorPlanInput;
};

export type MutationFirstOrCreateContactArgs = {
  contactInput: CreateContactInput;
};

export type MutationFirstOrCreateFacilityArgs = {
  input?: InputMaybe<FacilityCreateInput>;
};

export type MutationGenerateTokenArgs = {
  key: Scalars['String'];
  type: TokenType;
};

export type MutationGetS3PresignedPutUrlArgs = {
  s3PresignedPutURLInputParam: S3PresignedPutUrlInput;
};

export type MutationOnboardProviderGroupArgs = {
  onboardProviderGroupInput: OnboardProviderGroupInput;
};

export type MutationOnboardProviderGroupForPaymentArgs = {
  input: OnboardProviderGroupForPaymentInput;
};

export type MutationOnboardSponsorArgs = {
  onboardSponsor: OnboardSponsorInput;
};

export type MutationPayBillArgs = {
  billId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  paymentMethodId?: Scalars['ID'];
};

export type MutationRemoveRcmBankAccountFromUserArgs = {
  RCMInput: RemoveBankAccountRcmInput;
};

export type MutationRemoveRcmPermissionFromUserArgs = {
  RCMInput: UserAsRcmInput;
};

export type MutationRemoveRoleMembershipsArgs = {
  roleMembershipIds: Array<Scalars['ID']>;
  userID: Scalars['ID'];
};

export type MutationReprocessRemittanceGroupStateArgs = {
  transitionNameInput: TransitionNameInput;
};

export type MutationRevertAndResubmitInvoiceArgs = {
  invoiceId: Scalars['ID'];
};

export type MutationSendAlertEmailToSupportArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
};

export type MutationSendFeedbackEmailArgs = {
  sendFeedbackEmailInput?: InputMaybe<SendFeedbackEmailInput>;
};

export type MutationSetRecipientPaymentPreferenceArgs = {
  input?: InputMaybe<SetRecipientPaymentPreferenceInput>;
};

export type MutationStripeRemovePaymentMethodArgs = {
  id: Scalars['ID'];
};

export type MutationStripeSetupIntentArgs = {
  partyId: Scalars['ID'];
};

export type MutationSubmitPreInvoiceArgs = {
  preInvoiceInput?: InputMaybe<PreInvoiceInput>;
};

export type MutationSubmitRemittanceArgs = {
  SubmitRemittanceInput: SubmitRemittanceInput;
};

export type MutationTransitionInvoiceArgs = {
  invoiceId: Scalars['ID'];
  stateDetails?: InputMaybe<InvoiceStateDetailsInput>;
  transitionName: InvoiceTransitionName;
};

export type MutationTriggerAlloyCheckArgs = {
  partyID: Scalars['ID'];
};

export type MutationUpdateContactInfoArgs = {
  contactInfoInput: ContactInfoInput;
  partyId: Scalars['ID'];
};

export type MutationUpdateContractArgs = {
  updateContractInput: UpdateContractInput;
};

export type MutationUpdateEmployerGroupContractArgs = {
  updateEmployerGroupContract: UpdateEmployerGroupContract;
};

export type MutationUpdateEncounterArgs = {
  encounter?: InputMaybe<EncounterUpdateInput>;
  id: Scalars['ID'];
};

export type MutationUpdateFileUploadArgs = {
  id: Scalars['ID'];
  input: FileUploadInput;
};

export type MutationUpdateInvoiceArgs = {
  updateInvoiceInput: UpdateInvoiceInput;
};

export type MutationUpdateLocationArgs = {
  locationInput: LocationCreateInput;
  practiceFacilityId: Scalars['ID'];
};

export type MutationUpdateProviderArgs = {
  providerInput: ProviderInput;
};

export type MutationUpdateSponsorArgs = {
  updateSponsor: UpdateSponsorInput;
};

export type MutationUploadContractFileArgs = {
  uploadContractInput: UploadContractInput;
};

export type Name = {
  __typename?: 'Name';
  entity_id?: Maybe<X12Code>;
  entity_relationship?: Maybe<Scalars['String']>;
  entity_type?: Maybe<X12Code>;
  entity_type_2?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  identification_code?: Maybe<Scalars['String']>;
  identification_code_qualifier?: Maybe<X12Code>;
  last_name_2?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  suffix?: Maybe<Scalars['String']>;
};

export type NameFirst_NameArgs = {
  detokenize?: Scalars['Boolean'];
};

export type NameIdentification_CodeArgs = {
  detokenize?: Scalars['Boolean'];
};

export type NameLast_Name_2Args = {
  detokenize?: Scalars['Boolean'];
};

export type NameMiddle_NameArgs = {
  detokenize?: Scalars['Boolean'];
};

export type NameOrganization_NameArgs = {
  detokenize?: Scalars['Boolean'];
};

export type NameInput = {
  entity_id?: InputMaybe<X12CodeInput>;
  entity_relationship?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<X12CodeInput>;
  entity_type_2?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  identification_code?: InputMaybe<Scalars['String']>;
  identification_code_qualifier?: InputMaybe<X12CodeInput>;
  last_name_2?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  organization_name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type Network = IDocument & {
  __typename?: 'Network';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  contract_ids?: Maybe<Array<Scalars['ID']>>;
  contracts?: Maybe<Array<Contract>>;
  name: Scalars['String'];
  party_id: Scalars['ID'];
  provider_group_ids?: Maybe<Array<Scalars['ID']>>;
  provider_groups?: Maybe<Array<ProviderGroup>>;
  specialties?: Maybe<Array<SpecialtyToTaxonomies>>;
  sponsors?: Maybe<Array<Scalars['ID']>>;
};

export type NetworkProvider_GroupsArgs = {
  withProvider?: InputMaybe<Scalars['ID']>;
};

export type NetworkAccess = {
  __typename?: 'NetworkAccess';
  access_type?: Maybe<NetworkAccessType>;
  network_id: Scalars['ID'];
};

export type NetworkAccessInput = {
  access_type?: InputMaybe<NetworkAccessType>;
  network_id: Scalars['ID'];
};

export enum NetworkAccessType {
  NatCcn = 'NAT_CCN',
  NatFrn = 'NAT_FRN',
  NatUnknown = 'NAT_UNKNOWN',
}

export type NetworkFeeSchedule = {
  __typename?: 'NetworkFeeSchedule';
  fee_schedule_id: Scalars['ID'];
  fee_schedule_type: FeeScheduleType;
};

export type NetworkInput = {
  _id?: InputMaybe<Scalars['ID']>;
  audit?: InputMaybe<AuditInput>;
  contract_ids?: InputMaybe<Array<Scalars['ID']>>;
  contracts?: InputMaybe<Array<ContractInput>>;
  name: Scalars['String'];
  party_id: Scalars['ID'];
  provider_group_ids?: InputMaybe<Array<Scalars['ID']>>;
  sponsors?: InputMaybe<Array<Scalars['ID']>>;
};

export type Networks = {
  __typename?: 'Networks';
  available: Array<Scalars['String']>;
  preferred?: Maybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  audit?: Maybe<Audit>;
  id: Scalars['String'];
  note: Scalars['String'];
};

export type NoteNoteArgs = {
  detokenize: Scalars['Boolean'];
};

export type OktaConfigurationInput = {
  identity_id: Scalars['ID'];
};

export type OnboardPlanInput = {
  class?: InputMaybe<Scalars['String']>;
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  plan_code: Scalars['String'];
  plan_type?: InputMaybe<PlanType>;
};

export type OnboardSponsorInput = {
  group_id: Scalars['String'];
  plan_id?: InputMaybe<Scalars['String']>;
  plans?: InputMaybe<Array<OnboardPlanInput>>;
  primary_payment_client_id?: InputMaybe<Scalars['ID']>;
  sponsor_name: Scalars['String'];
  tin: Scalars['String'];
  type?: InputMaybe<SponsorType>;
};

export enum OnboardingType {
  BankAccount = 'BANK_ACCOUNT',
  ManualReview = 'MANUAL_REVIEW',
}

export type Option = {
  __typename?: 'Option';
  accumulated_price_pennies: Scalars['String'];
  cpt_code: Scalars['String'];
  cpt_codes_and_units?: Maybe<Array<CptCodeAndUnits>>;
  fee_description: Scalars['String'];
  key: Scalars['String'];
  standalone_price_pennies: Scalars['String'];
};

export type Options = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SortOption>>;
};

export type OrderingProvider = {
  __typename?: 'OrderingProvider';
  address?: Maybe<PhysicalAddress>;
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type OrderingProviderInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type OrgWithBankAccountInput = {
  account_id: Scalars['ID'];
  party_id: Scalars['ID'];
};

export type Organization = {
  __typename?: 'Organization';
  clearing_house_preference?: Maybe<ClearingHousePreferenceType>;
  entity_name?: Maybe<EntityName>;
  legal_organization?: Maybe<LegalOrganization>;
  organization_kind?: Maybe<OrganizationKind>;
  policies?: Maybe<Array<Policy>>;
};

export type OrganizationInput = {
  entity_name?: InputMaybe<EntityNameInput>;
  legal_organization?: InputMaybe<LegalOrganizationInput>;
  organization_kind?: InputMaybe<OrganizationKind>;
};

export enum OrganizationKind {
  InformalOrganization = 'INFORMAL_ORGANIZATION',
  LegalOrganization = 'LEGAL_ORGANIZATION',
  Other = 'OTHER',
}

export type OtherInsuranceCoverageInformation = {
  __typename?: 'OtherInsuranceCoverageInformation';
  benefits_assignment_indicator?: Maybe<X12Code>;
  claim_filing_indicator_code?: Maybe<Scalars['String']>;
  claim_submission_reason_code?: Maybe<Scalars['String']>;
  patient_signature_source_code?: Maybe<Scalars['String']>;
  provider_agreement_code?: Maybe<Scalars['String']>;
  release_info_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type OtherInsuranceCoverageInformationInput = {
  benefits_assignment_indicator?: InputMaybe<X12CodeInput>;
  claim_filing_indicator_code?: InputMaybe<Scalars['String']>;
  claim_submission_reason_code?: InputMaybe<Scalars['String']>;
  patient_signature_source_code?: InputMaybe<Scalars['String']>;
  provider_agreement_code?: InputMaybe<Scalars['String']>;
  release_info_code?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type OtherPayerName837I = {
  __typename?: 'OtherPayerName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  date?: Maybe<Date>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type OtherPayerName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  date?: InputMaybe<DateInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type OtherSubscriberInfo = {
  __typename?: 'OtherSubscriberInfo';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  billing_provider?: Maybe<BillingProvider>;
  claim_adjustments?: Maybe<Array<Maybe<LineAdjustment>>>;
  loop_metadata?: Maybe<X12Metadata>;
  other_insurance_coverage?: Maybe<OtherInsuranceCoverageInformation>;
  outpatient_adjudication?: Maybe<OutpatientAdjudicationInformation>;
  payer_name?: Maybe<PayerName>;
  referring_provider?: Maybe<Array<Maybe<ReferringProvider>>>;
  rendering_provider?: Maybe<RenderingProvider>;
  service_facility_location?: Maybe<ServiceFacilityLocation>;
  subscriber?: Maybe<Subscriber>;
  subscriber_name?: Maybe<SubscriberName>;
  supervising_provider?: Maybe<SupervisingProvider>;
};

export type OtherSubscriberInfo837I = {
  __typename?: 'OtherSubscriberInfo837I';
  amt?: Maybe<Array<Maybe<Amount>>>;
  attending_provider?: Maybe<Physician_Provider>;
  billing_provider?: Maybe<Physician_Provider>;
  claim_adjustments?: Maybe<Array<Maybe<LineAdjustment>>>;
  inpatient_adjudication?: Maybe<InpatientAdjudicationInformation>;
  insurance_coverage?: Maybe<OtherInsuranceCoverageInformation>;
  loop_metadata?: Maybe<X12Metadata>;
  operating_physician?: Maybe<Physician_Provider>;
  other_payer_other_physician?: Maybe<Physician_Provider>;
  outpatient_adjudication?: Maybe<OutpatientAdjudicationInformation>;
  payer_name?: Maybe<OtherPayerName837I>;
  payer_service_facility?: Maybe<Physician_Provider>;
  referring_provider?: Maybe<Physician_Provider>;
  rendering_provider_name?: Maybe<Physician_Provider>;
  subscriber?: Maybe<Subscriber>;
  subscriber_name?: Maybe<OtherSubscriberName837I>;
};

export type OtherSubscriberInfo837IInput = {
  amt?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  attending_provider?: InputMaybe<Physician_ProviderInput>;
  billing_provider?: InputMaybe<Physician_ProviderInput>;
  claim_adjustments?: InputMaybe<Array<InputMaybe<LineAdjustmentInput>>>;
  inpatient_adjudication?: InputMaybe<InpatientAdjudicationInformationInput>;
  insurance_coverage?: InputMaybe<OtherInsuranceCoverageInformationInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  operating_physician?: InputMaybe<Physician_ProviderInput>;
  other_payer_other_physician?: InputMaybe<Physician_ProviderInput>;
  outpatient_adjudication?: InputMaybe<OutpatientAdjudicationInformationInput>;
  payer_name?: InputMaybe<OtherPayerName837IInput>;
  payer_service_facility?: InputMaybe<Physician_ProviderInput>;
  referring_provider?: InputMaybe<Physician_ProviderInput>;
  rendering_provider_name?: InputMaybe<Physician_ProviderInput>;
  subscriber?: InputMaybe<SubscriberInput>;
  subscriber_name?: InputMaybe<OtherSubscriberName837IInput>;
};

export type OtherSubscriberInfoInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  billing_provider?: InputMaybe<BillingProviderInput>;
  claim_adjustments?: InputMaybe<Array<InputMaybe<LineAdjustmentInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  other_insurance_coverage?: InputMaybe<OtherInsuranceCoverageInformationInput>;
  outpatient_adjudication?: InputMaybe<OutpatientAdjudicationInformationInput>;
  payer_name?: InputMaybe<PayerNameInput>;
  referring_provider?: InputMaybe<Array<InputMaybe<ReferringProviderInput>>>;
  rendering_provider?: InputMaybe<RenderingProviderInput>;
  service_facility_location?: InputMaybe<ServiceFacilityLocationInput>;
  subscriber?: InputMaybe<SubscriberInput>;
  subscriber_name?: InputMaybe<SubscriberNameInput>;
  supervising_provider?: InputMaybe<SupervisingProviderInput>;
};

export type OtherSubscriberName837I = {
  __typename?: 'OtherSubscriberName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type OtherSubscriberName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type OutpatientAdjudicationInformation = {
  __typename?: 'OutpatientAdjudicationInformation';
  claim_payment_remark_code_1?: Maybe<Scalars['String']>;
  claim_payment_remark_code_2?: Maybe<Scalars['String']>;
  claim_payment_remark_code_3?: Maybe<Scalars['String']>;
  claim_payment_remark_code_4?: Maybe<Scalars['String']>;
  claim_payment_remark_code_5?: Maybe<Scalars['String']>;
  hcpcs_payable_amount?: Maybe<FloatWrapper>;
  professional_component_billed_amount?: Maybe<FloatWrapper>;
  reimbursement_rate?: Maybe<FloatWrapper>;
  renal_disease_payment_amount?: Maybe<FloatWrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type OutpatientAdjudicationInformationInput = {
  claim_payment_remark_code_1?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_2?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_3?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_4?: InputMaybe<Scalars['String']>;
  claim_payment_remark_code_5?: InputMaybe<Scalars['String']>;
  hcpcs_payable_amount?: InputMaybe<FloatWrapperInput>;
  professional_component_billed_amount?: InputMaybe<FloatWrapperInput>;
  reimbursement_rate?: InputMaybe<FloatWrapperInput>;
  renal_disease_payment_amount?: InputMaybe<FloatWrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type PgFilter = {
  provider_id?: InputMaybe<Scalars['ID']>;
};

export type Paginatable = {
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginatableResults = {
  offset: Scalars['Int'];
  results?: Maybe<Array<IDocument>>;
  total: Scalars['Int'];
};

export type Party = IDocument & {
  __typename?: 'Party';
  _id: Scalars['ID'];
  accounts?: Maybe<Accounts>;
  audit?: Maybe<Audit>;
  child_relationship_to_parent?: Maybe<ChildRelationship>;
  compliance_info?: Maybe<ComplianceInfo>;
  contacts?: Maybe<Array<PartyContact>>;
  government_ids?: Maybe<Array<Maybe<GovernmentIssuedId>>>;
  is_internal?: Maybe<Scalars['Boolean']>;
  member_ids?: Maybe<Array<Scalars['String']>>;
  memberships?: Maybe<Array<Member>>;
  organization?: Maybe<Organization>;
  parent_id?: Maybe<Scalars['ID']>;
  party_type?: Maybe<PartyType>;
  payment_client?: Maybe<PaymentClient>;
  person?: Maybe<Person>;
  provider_groups?: Maybe<Array<ProviderGroup>>;
  sponsorship?: Maybe<Sponsor>;
};

export type PartyContactsArgs = {
  purpose?: InputMaybe<Scalars['String']>;
};

export type PartyGovernment_IdsArgs = {
  id_type?: InputMaybe<Array<GovernmentIdType>>;
};

export type PartyProvider_GroupsArgs = {
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type PartyContact = IEffectiveDates & {
  __typename?: 'PartyContact';
  contact?: Maybe<Contact>;
  contact_id: Scalars['String'];
  effective_dates?: Maybe<EffectiveDates>;
  is_primary?: Maybe<Scalars['Boolean']>;
  purpose?: Maybe<Scalars['String']>;
};

export type PartyContactInput = {
  contact_id?: InputMaybe<Scalars['ID']>;
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  purpose?: InputMaybe<Scalars['String']>;
};

export enum PartyEntityType {
  MemberEntityType = 'MEMBER_ENTITY_TYPE',
  PartyEntityType = 'PARTY_ENTITY_TYPE',
  PaymentClientEntityType = 'PAYMENT_CLIENT_ENTITY_TYPE',
  ProviderEntityType = 'PROVIDER_ENTITY_TYPE',
  ProviderGroupEntityType = 'PROVIDER_GROUP_ENTITY_TYPE',
  SponsorEntityType = 'SPONSOR_ENTITY_TYPE',
  UnknownPartyEntityType = 'UNKNOWN_PARTY_ENTITY_TYPE',
}

export type PartyInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contacts?: InputMaybe<Array<PartyContactInput>>;
  government_ids?: InputMaybe<Array<GovernmentIdInput>>;
  id: Scalars['String'];
  is_internal?: InputMaybe<Scalars['Boolean']>;
  member_ids?: InputMaybe<Array<Scalars['String']>>;
  organization?: InputMaybe<OrganizationInput>;
  parent_id?: InputMaybe<Scalars['ID']>;
  party_type: PartyType;
  person?: InputMaybe<PersonInput>;
};

export type PartyName = {
  __typename?: 'PartyName';
  entity_id_1?: Maybe<Scalars['String']>;
  entity_id_2?: Maybe<Scalars['String']>;
  entity_relationship?: Maybe<Scalars['String']>;
  identification_code?: Maybe<Scalars['String']>;
  identification_code_qualifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type PartyNameInput = {
  entity_id_1?: InputMaybe<Scalars['String']>;
  entity_id_2?: InputMaybe<Scalars['String']>;
  entity_relationship?: InputMaybe<Scalars['String']>;
  identification_code?: InputMaybe<Scalars['String']>;
  identification_code_qualifier?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum PartyType {
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
  Unknown = 'UNKNOWN',
}

export type Patient = {
  __typename?: 'Patient';
  death_date?: Maybe<Scalars['String']>;
  death_date_format?: Maybe<X12Code>;
  employment_status?: Maybe<Scalars['String']>;
  entity_relationship?: Maybe<X12Code>;
  patient_location?: Maybe<Scalars['String']>;
  pregnant?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  student_status?: Maybe<Scalars['String']>;
  weight?: Maybe<FloatWrapper>;
  weight_units?: Maybe<X12Code>;
};

export type PatientDeath_DateArgs = {
  detokenize?: Scalars['Boolean'];
};

export type PatientInput = {
  death_date?: InputMaybe<Scalars['String']>;
  death_date_format?: InputMaybe<X12CodeInput>;
  employment_status?: InputMaybe<Scalars['String']>;
  entity_relationship?: InputMaybe<X12CodeInput>;
  patient_location?: InputMaybe<Scalars['String']>;
  pregnant?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  student_status?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<FloatWrapperInput>;
  weight_units?: InputMaybe<X12CodeInput>;
};

export type PatientLoop = {
  __typename?: 'PatientLoop';
  claims?: Maybe<Array<Maybe<ClaimInformation>>>;
  hierarchy?: Maybe<Hierarchy>;
  loop_metadata?: Maybe<X12Metadata>;
  patient?: Maybe<Patient>;
  patient_name?: Maybe<PatientName>;
};

export type PatientLoop837I = {
  __typename?: 'PatientLoop837I';
  claims?: Maybe<Array<Maybe<ClaimInformation837I>>>;
  hierarchy?: Maybe<Hierarchy>;
  loop_metadata?: Maybe<X12Metadata>;
  patient?: Maybe<Patient>;
  patient_name?: Maybe<PatientName837I>;
};

export type PatientLoop837IInput = {
  claims?: InputMaybe<Array<InputMaybe<ClaimInformation837IInput>>>;
  hierarchy?: InputMaybe<HierarchyInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  patient?: InputMaybe<PatientInput>;
  patient_name?: InputMaybe<PatientName837IInput>;
};

export type PatientLoopInput = {
  claims?: InputMaybe<Array<InputMaybe<ClaimInformationInput>>>;
  hierarchy?: InputMaybe<HierarchyInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  patient?: InputMaybe<PatientInput>;
  patient_name?: InputMaybe<PatientNameInput>;
};

export type PatientName = {
  __typename?: 'PatientName';
  address?: Maybe<PhysicalAddress>;
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  demographic?: Maybe<Demographic>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PatientName837I = {
  __typename?: 'PatientName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  demographic?: Maybe<Demographic>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PatientName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  demographic?: InputMaybe<DemographicInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PatientNameInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  demographic?: InputMaybe<DemographicInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PayToAddressName = {
  __typename?: 'PayToAddressName';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type PayToAddressName837I = {
  __typename?: 'PayToAddressName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type PayToAddressName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type PayToAddressNameInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type PayToPlanName = {
  __typename?: 'PayToPlanName';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PayToPlanName837I = {
  __typename?: 'PayToPlanName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PayToPlanName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PayToPlanNameInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PayeeIdentificationLoop = {
  __typename?: 'PayeeIdentificationLoop';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  party_name?: Maybe<PartyName>;
  rdm?: Maybe<RemittanceDeliveryMethod>;
  references?: Maybe<Array<Maybe<Reference>>>;
};

export type PayeeIdentificationLoopInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  party_name?: InputMaybe<PartyNameInput>;
  rdm?: InputMaybe<RemittanceDeliveryMethodInput>;
  references?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PayerContract = {
  __typename?: 'PayerContract';
  auto_funding_disabled?: Maybe<Scalars['Boolean']>;
  party_id: Scalars['ID'];
};

export type PayerContractInput = {
  auto_funding_disabled?: InputMaybe<Scalars['Boolean']>;
  party_id: Scalars['ID'];
};

export type PayerFundingGroup = IDocument & {
  __typename?: 'PayerFundingGroup';
  _id: Scalars['ID'];
  audit: Audit;
  audit_logs?: Maybe<Array<PayerFundingGroupAuditLog>>;
  bookkeeping_entry_set_ids?: Maybe<Array<Scalars['ID']>>;
  for_ledger_id: Scalars['ID'];
  for_remittance_group_ids?: Maybe<Array<Scalars['ID']>>;
  from_time: Scalars['String'];
  funding_transaction_reference_id: Scalars['ID'];
  is_internal?: Maybe<Scalars['Boolean']>;
  payer_funding_group_state?: Maybe<PayerFundingGroupState>;
  remittance_groups?: Maybe<Array<RemittanceGroup>>;
  settled_at_time: Scalars['String'];
  submitted_at_time: Scalars['String'];
  to_time: Scalars['String'];
  total_amount_needed: Scalars['Float'];
  total_amount_needed_pennies: Scalars['Int'];
  total_fee_amount_pennies: Scalars['Int'];
  trace_number: Scalars['String'];
  with_common_payer_id: Scalars['ID'];
};

export type PayerFundingGroupAuditLog = {
  __typename?: 'PayerFundingGroupAuditLog';
  payer_funding_group_state_transition: PayerFundingGroupStateTransition;
  transitioned_at_time: Scalars['String'];
};

export type PayerFundingGroupMetadata = {
  __typename?: 'PayerFundingGroupMetadata';
  remittance_group_id: Scalars['String'];
  submitted_at_time: Scalars['String'];
  successful_at_time: Scalars['String'];
};

export enum PayerFundingGroupState {
  PfgCreated = 'PFG_CREATED',
  PfgFailed = 'PFG_FAILED',
  PfgFindingInitiated = 'PFG_FINDING_INITIATED',
  PfgFundingBookkept = 'PFG_FUNDING_BOOKKEPT',
  PfgFundingFailed = 'PFG_FUNDING_FAILED',
  PfgFundingPending = 'PFG_FUNDING_PENDING',
  PfgFundingSettled = 'PFG_FUNDING_SETTLED',
  PfgFundingSubmitted = 'PFG_FUNDING_SUBMITTED',
  PfgReadyForFunding = 'PFG_READY_FOR_FUNDING',
}

export type PayerFundingGroupStateTransition = {
  __typename?: 'PayerFundingGroupStateTransition';
  from_state: PayerFundingGroupState;
  to_state: PayerFundingGroupState;
};

export type PayerIdentificationLoop = {
  __typename?: 'PayerIdentificationLoop';
  address?: Maybe<PhysicalAddress>;
  contact_infos?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  party_name?: Maybe<PartyName>;
  references?: Maybe<Array<Maybe<Reference>>>;
};

export type PayerIdentificationLoopInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_infos?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  party_name?: InputMaybe<PartyNameInput>;
  references?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PayerName = {
  __typename?: 'PayerName';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  dates?: Maybe<Array<Maybe<Date>>>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PayerName837I = {
  __typename?: 'PayerName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PayerName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PayerNameInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  dates?: InputMaybe<Array<InputMaybe<DateInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String'];
  checks?: Maybe<Checks>;
  country: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  fingerprint: Scalars['String'];
  funding: Scalars['String'];
  generatedFrom?: Maybe<Scalars['String']>;
  last4: Scalars['String'];
  networks?: Maybe<Networks>;
  threeDSecureUsage?: Maybe<ThreeDSecureUsage>;
  wallet?: Maybe<Scalars['String']>;
};

export type PaymentClient = IDocument & {
  __typename?: 'PaymentClient';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  funding_source?: Maybe<Scalars['Boolean']>;
  implementation_status?: Maybe<ImplementationStatus>;
  party?: Maybe<Party>;
  party_id: Scalars['String'];
  payees?: Maybe<Array<Maybe<Scalars['String']>>>;
  payers?: Maybe<Array<Maybe<Scalars['String']>>>;
  payment_client_type?: Maybe<PaymentClientType>;
  primary_client_info?: Maybe<PrimaryPaymentClientInfo>;
  remittance_file_uploads?: Maybe<Array<Maybe<Scalars['String']>>>;
  secondary_client_info?: Maybe<SecondaryPaymentClientInfo>;
  state?: Maybe<PaymentClientState>;
};

export type PaymentClientInput = {
  audit?: InputMaybe<AuditInput>;
  funding_source?: InputMaybe<Scalars['Boolean']>;
  implementation_status?: InputMaybe<ImplementationStatus>;
  party_id: Scalars['String'];
  payment_client_type?: InputMaybe<PaymentClientType>;
  primary_client_info?: InputMaybe<PrimaryPaymentClientInfoInput>;
  remittance_file_uploads?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondary_client_info?: InputMaybe<SecondaryPaymentClientInfoInput>;
  state?: InputMaybe<PaymentClientState>;
};

export enum PaymentClientState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Onboarding = 'ONBOARDING',
  Preonboarding = 'PREONBOARDING',
  Unknown = 'UNKNOWN',
}

export enum PaymentClientType {
  CtPrimary = 'CT_PRIMARY',
  CtSecondary = 'CT_SECONDARY',
  CtUnknown = 'CT_UNKNOWN',
}

export enum PaymentCommunicationPreferenceType {
  Edi_835 = 'EDI_835',
  Eop = 'EOP',
  UnknownPaymentCommunicationPreference = 'UNKNOWN_PAYMENT_COMMUNICATION_PREFERENCE',
}

export type PaymentDate = {
  from_time: Scalars['String'];
  to_time: Scalars['String'];
};

export type PaymentDocumentation = IDocument & {
  __typename?: 'PaymentDocumentation';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  card_payment?: Maybe<CardPayment>;
  date_of_service: Scalars['String'];
  documentation_type?: Maybe<PaymentDocumentationType>;
  facility_name: Scalars['String'];
  member_id: Scalars['String'];
  notes?: Maybe<Array<Note>>;
  reimbursement?: Maybe<Reimbursement>;
  remittance_id: Scalars['String'];
  service_types?: Maybe<Array<Maybe<ServiceType>>>;
  transaction_ids?: Maybe<Array<Scalars['String']>>;
  transactions?: Maybe<Array<Transaction>>;
};

export enum PaymentDocumentationType {
  PCardPayment = 'P_CARD_PAYMENT',
  PReimbursement = 'P_REIMBURSEMENT',
}

export type PaymentFlowsAmountDetails = {
  __typename?: 'PaymentFlowsAmountDetails';
  tip?: Maybe<PaymentFlowsAmountDetailsResourceTip>;
};

export type PaymentFlowsAmountDetailsResourceTip = {
  __typename?: 'PaymentFlowsAmountDetailsResourceTip';
  /** Portion of the amount that corresponds to a tip. */
  amount?: Maybe<Scalars['Int']>;
};

export type PaymentHistories = Paginatable & {
  __typename?: 'PaymentHistories';
  offset: Scalars['Int'];
  results: Array<PaymentHistory>;
  total: Scalars['Int'];
};

export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  balance?: Maybe<Scalars['Int']>;
  claim_id?: Maybe<Scalars['ID']>;
  display_id?: Maybe<Scalars['String']>;
  employer_bill_total?: Maybe<Scalars['Int']>;
  employer_paid?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  names?: Maybe<Array<Scalars['String']>>;
  patient_bill_total?: Maybe<Scalars['Int']>;
  patient_paid?: Maybe<Scalars['Int']>;
  service_date?: Maybe<Scalars['String']>;
};

export type PaymentHistoryQuery = {
  options?: Options;
  query?: InputMaybe<Scalars['String']>;
};

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  admin_fee?: Maybe<Scalars['Float']>;
  bundled_rate?: Maybe<Scalars['Float']>;
  due_from_employer?: Maybe<Scalars['Float']>;
  invoice_amount?: Maybe<Scalars['Float']>;
  patient_paid?: Maybe<Scalars['Float']>;
};

export type PaymentInfoInput = {
  admin_fee?: InputMaybe<Scalars['Float']>;
  bundled_rate?: InputMaybe<Scalars['Float']>;
  due_from_employer?: InputMaybe<Scalars['Float']>;
  invoice_amount?: InputMaybe<Scalars['Float']>;
  patient_paid?: InputMaybe<Scalars['Float']>;
};

export enum PaymentIntentStatus {
  Canceled = 'CANCELED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED',
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billingDetails?: Maybe<BillingDetails>;
  card?: Maybe<PaymentCard>;
  created: Scalars['Int'];
  customer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  liveMode: Scalars['Boolean'];
  object: Scalars['String'];
  redaction?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type PaymentMethodOptions = {
  __typename?: 'PaymentMethodOptions';
  card?: Maybe<CardOptions>;
};

export type PaymentPreferences = IEffectiveDates & {
  __typename?: 'PaymentPreferences';
  effective_dates?: Maybe<EffectiveDates>;
  funding_preference?: Maybe<Scalars['FundingPreferenceType']>;
  payment_cadence: Scalars['String'];
  payment_vehicle?: Maybe<PaymentVehicle>;
};

export type PaymentPreferencesInput = {
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  funding_preference?: InputMaybe<Scalars['FundingPreferenceType']>;
  payment_cadence: Scalars['String'];
};

export type PaymentTerms = {
  __typename?: 'PaymentTerms';
  payment_vehicle?: Maybe<PaymentVehicle>;
  time_to_pay?: Maybe<TimeToPay>;
};

export type PaymentTermsInput = {
  payment_vehicle: PaymentVehicle;
  time_to_pay: TimeToPayInput;
};

export enum PaymentVehicle {
  PvBank = 'PV_BANK',
  PvManualCheck = 'PV_MANUAL_CHECK',
  PvOptOut = 'PV_OPT_OUT',
  PvUnknown = 'PV_UNKNOWN',
  PvVirtualCard = 'PV_VIRTUAL_CARD',
  PvVirtualTransferOnly = 'PV_VIRTUAL_TRANSFER_ONLY',
}

export enum PaymentVehicleType {
  PvBank = 'PV_BANK',
  PvManualCheck = 'PV_MANUAL_CHECK',
  PvUnknown = 'PV_UNKNOWN',
  PvVirtualCard = 'PV_VIRTUAL_CARD',
  PvVirtualTransferOnly = 'PV_VIRTUAL_TRANSFER_ONLY',
}

export enum PaymentsFeeType {
  FtServiceFees = 'FT_SERVICE_FEES',
  FtTransaction = 'FT_TRANSACTION',
  FtUnknown = 'FT_UNKNOWN',
}

export type Person = {
  __typename?: 'Person';
  birth_date?: Maybe<Scalars['String']>;
  current_name: PersonName;
  gender_type?: Maybe<GenderType>;
  policies?: Maybe<Array<Maybe<Policy>>>;
  users?: Maybe<Array<Maybe<UserInfo>>>;
};

export type PersonAtFacilityFilter = {
  person_id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<PersonsAtFacilityState>;
};

export type PersonInput = {
  birth_date?: InputMaybe<Scalars['String']>;
  current_name?: InputMaybe<CurrentNameInput>;
  policies_accepted?: InputMaybe<Array<PolicyInput>>;
};

export type PersonName = {
  __typename?: 'PersonName';
  names?: Maybe<Array<Scalars['String']>>;
  names_unified?: Maybe<Array<Scalars['String']>>;
  preferred_name?: Maybe<Scalars['String']>;
};

export type PersonNameNamesArgs = {
  detokenize: Scalars['Boolean'];
};

export type PersonNameNames_UnifiedArgs = {
  detokenize: Scalars['Boolean'];
};

export type PersonNamePreferred_NameArgs = {
  detokenize: Scalars['Boolean'];
};

export enum PersonsAtFacilityState {
  PafOnboarded = 'PAF_ONBOARDED',
  PafOnboarding = 'PAF_ONBOARDING',
  PafUnknown = 'PAF_UNKNOWN',
}

export type PersonsAtFacilityTemp = {
  __typename?: 'PersonsAtFacilityTemp';
  effective_dates?: Maybe<EffectiveDates>;
  persons_at_facility_id?: Maybe<Scalars['String']>;
  state?: Maybe<PersonsAtFacilityState>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  phone: Scalars['String'];
};

export type PhoneNumberPhoneArgs = {
  detokenize?: Scalars['Boolean'];
};

export type PhoneNumberInput = {
  phone: Scalars['String'];
};

export type PhysicalAddress = {
  __typename?: 'PhysicalAddress';
  line_1?: Maybe<Scalars['String']>;
  line_2?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type PhysicalAddressLine_1Args = {
  detokenize?: Scalars['Boolean'];
};

export type PhysicalAddressLine_2Args = {
  detokenize?: Scalars['Boolean'];
};

export type PhysicalAddressInput = {
  line_1?: InputMaybe<Scalars['String']>;
  line_2?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type PhysicianProviderName = {
  __typename?: 'PhysicianProviderName';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PhysicianProviderNameInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type Physician_Provider = {
  __typename?: 'Physician_Provider';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type Physician_ProviderInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type PingConfigurationInput = {
  ping_user?: InputMaybe<PingUserCreationInput>;
  username: Scalars['String'];
};

export type PingIdentityProviderInfo = {
  __typename?: 'PingIdentityProviderInfo';
  email_contact_purpose_key: Scalars['String'];
  identity_provider: Scalars['ID'];
  phone_contact_purpose_key: Scalars['String'];
  ping_group_id: Scalars['ID'];
  ping_population_id: Scalars['ID'];
};

export type PingNameInput = {
  family: Scalars['String'];
  given: Scalars['String'];
};

export type PingUserCreationInput = {
  email: Scalars['String'];
  email_nickname: Scalars['String'];
  identity_provider_id?: InputMaybe<Scalars['String']>;
  name: PingNameInput;
  party_id: Scalars['ID'];
  ping_group_id: Scalars['String'];
  ping_population_id: Scalars['String'];
};

export enum PlaceOfService {
  PosAirWaterAmbulance = 'POS_AIR_WATER_AMBULANCE',
  PosAmbulatorySurgicalCenter = 'POS_AMBULATORY_SURGICAL_CENTER',
  PosAssistedLivingFacility = 'POS_ASSISTED_LIVING_FACILITY',
  PosBirthingCenter = 'POS_BIRTHING_CENTER',
  PosCommunityMentalHealthCenter = 'POS_COMMUNITY_MENTAL_HEALTH_CENTER',
  PosComprehensiveInpatientRehabFacility = 'POS_COMPREHENSIVE_INPATIENT_REHAB_FACILITY',
  PosCorrectionalFacility = 'POS_CORRECTIONAL_FACILITY',
  PosCustodialCareFacility = 'POS_CUSTODIAL_CARE_FACILITY',
  PosEmergencyRoom = 'POS_EMERGENCY_ROOM',
  PosEsrdTreatmentFacility = 'POS_ESRD_TREATMENT_FACILITY',
  PosFederallyQualifiedHealthCenter = 'POS_FEDERALLY_QUALIFIED_HEALTH_CENTER',
  PosGroupHome = 'POS_GROUP_HOME',
  PosHome = 'POS_HOME',
  PosHomelessShelter = 'POS_HOMELESS_SHELTER',
  PosHospice = 'POS_HOSPICE',
  PosIhsFreeStandingFacility = 'POS_IHS_FREE_STANDING_FACILITY',
  PosIhsProviderBasedFacility = 'POS_IHS_PROVIDER_BASED_FACILITY',
  PosIndependentClinic = 'POS_INDEPENDENT_CLINIC',
  PosIndependentLab = 'POS_INDEPENDENT_LAB',
  PosInpatientHospital = 'POS_INPATIENT_HOSPITAL',
  PosInpatientPsychFacility = 'POS_INPATIENT_PSYCH_FACILITY',
  PosIntermediateCareFacility = 'POS_INTERMEDIATE_CARE_FACILITY',
  PosLandAmbulance = 'POS_LAND_AMBULANCE',
  PosLeftBlank = 'POS_LEFT_BLANK',
  PosMassImmunizationCenter = 'POS_MASS_IMMUNIZATION_CENTER',
  PosMilitaryTreatmentFacility = 'POS_MILITARY_TREATMENT_FACILITY',
  PosMobileUnit = 'POS_MOBILE_UNIT',
  PosNonresidentialOpioidTreatmentFacility = 'POS_NONRESIDENTIAL_OPIOID_TREATMENT_FACILITY',
  PosNonresidentialSubstanceAbuseTreatmentFacility = 'POS_NONRESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY',
  PosNursingFacility = 'POS_NURSING_FACILITY',
  PosOffcampusOutpatientHospital = 'POS_OFFCAMPUS_OUTPATIENT_HOSPITAL',
  PosOffice = 'POS_OFFICE',
  PosOncampusOutpatientHospital = 'POS_ONCAMPUS_OUTPATIENT_HOSPITAL',
  PosOtherCoded = 'POS_OTHER_CODED',
  PosOtherNotCoded = 'POS_OTHER_NOT_CODED',
  PosOutpatientRehabFacility = 'POS_OUTPATIENT_REHAB_FACILITY',
  PosPartialHospitalizationPsychFacility = 'POS_PARTIAL_HOSPITALIZATION_PSYCH_FACILITY',
  PosPharmacy = 'POS_PHARMACY',
  PosPsychResidentialTreatmentCenter = 'POS_PSYCH_RESIDENTIAL_TREATMENT_CENTER',
  PosPublicHealthClinic = 'POS_PUBLIC_HEALTH_CLINIC',
  PosResidentialSubstanceAbuseTreatmentFacility = 'POS_RESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY',
  PosRuralHealthClinic = 'POS_RURAL_HEALTH_CLINIC',
  PosSchool = 'POS_SCHOOL',
  PosSkilledNursingFacility = 'POS_SKILLED_NURSING_FACILITY',
  PosTelehealth = 'POS_TELEHEALTH',
  PosTemporaryLodging = 'POS_TEMPORARY_LODGING',
  PosTribal_638FreeStandingFacility = 'POS_TRIBAL_638_FREE_STANDING_FACILITY',
  PosTribal_638ProviderBasedFacility = 'POS_TRIBAL_638_PROVIDER_BASED_FACILITY',
  PosUrgentCareFacility = 'POS_URGENT_CARE_FACILITY',
  PosWalkinRetailHealthClinic = 'POS_WALKIN_RETAIL_HEALTH_CLINIC',
  PosWorksite = 'POS_WORKSITE',
}

export type Plan = IEffectiveDates & {
  __typename?: 'Plan';
  accounts?: Maybe<Accounts>;
  benefit_plan_ref: Scalars['String'];
  card_profile_id: Scalars['String'];
  copay?: Maybe<Scalars['Float']>;
  copay_pennies?: Maybe<Scalars['Int']>;
  effective_dates?: Maybe<EffectiveDates>;
  fee_schedule_id?: Maybe<Scalars['String']>;
  file_uploads?: Maybe<Array<Maybe<FileUpload>>>;
  group_id?: Maybe<Scalars['String']>;
  group_name?: Maybe<Scalars['String']>;
  lifecycle_state?: Maybe<PlanLifecycleState>;
  member_ids?: Maybe<Array<Scalars['ID']>>;
  network?: Maybe<Network>;
  network_id?: Maybe<Scalars['String']>;
  plan_account_id: Scalars['String'];
  plan_benefits?: Maybe<PlanBenefits>;
  plan_code: Scalars['String'];
  plan_name: Scalars['String'];
  plan_type?: Maybe<PlanType>;
};

export type PlanBenefits = IDocument & {
  __typename?: 'PlanBenefits';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  coverage_types?: Maybe<Array<CoverageType>>;
  deductibles?: Maybe<Array<DeductibleSpecification>>;
  provisions?: Maybe<Array<CoverageProvision>>;
  spec?: Maybe<Specification>;
};

export type PlanBenefitsInput = {
  _id?: InputMaybe<Scalars['ID']>;
  audit?: InputMaybe<AuditInput>;
  coverage_types?: InputMaybe<Array<CoverageType>>;
  deductibles?: InputMaybe<Array<DeductibleSpecificationInput>>;
  provisions?: InputMaybe<Array<CoverageProvisionInput>>;
  spec?: InputMaybe<PreInvoiceSpecificationInput>;
};

export type PlanBenefitsMetadata = {
  __typename?: 'PlanBenefitsMetadata';
  case_number: Scalars['String'];
  originating_member_id: Scalars['ID'];
  plan_code: Scalars['String'];
  reason: Scalars['String'];
  receiving_member_id: Scalars['ID'];
};

export type PlanDocumentInfo = {
  __typename?: 'PlanDocumentInfo';
  file_name: Scalars['String'];
  is_active: Scalars['Boolean'];
  plan_code: Scalars['String'];
  sponsor_id: Scalars['String'];
};

export type PlanInput = {
  accounts?: InputMaybe<AccountsInput>;
  benefit_plan_ref: Scalars['String'];
  card_profile_id: Scalars['String'];
  copay?: InputMaybe<Scalars['Float']>;
  copay_pennies?: InputMaybe<Scalars['Int']>;
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  fee_schedule_id?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['String']>;
  group_name?: InputMaybe<Scalars['String']>;
  lifecycle_state?: InputMaybe<PlanLifecycleState>;
  member_ids?: InputMaybe<Array<Scalars['ID']>>;
  network?: InputMaybe<NetworkInput>;
  network_id?: InputMaybe<Scalars['String']>;
  plan_account_id: Scalars['String'];
  plan_benefits?: InputMaybe<PlanBenefitsInput>;
  plan_code: Scalars['String'];
  plan_name: Scalars['String'];
};

export enum PlanLifecycleState {
  PlanActive = 'PLAN_ACTIVE',
  PlanCreated = 'PLAN_CREATED',
  PlanOnboarded = 'PLAN_ONBOARDED',
}

export type PlanProvider = {
  __typename?: 'PlanProvider';
  _id: Scalars['ID'];
  party_id?: Maybe<Scalars['ID']>;
  provider_groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sponsors?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum PlanType {
  PtHdhp = 'PT_HDHP',
  PtNonHdhp = 'PT_NON_HDHP',
  PtUnknown = 'PT_UNKNOWN',
}

export type PlanUtilization = IDocument & {
  __typename?: 'PlanUtilization';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  benefit_plan_id?: Maybe<Scalars['String']>;
  primary_member: Scalars['String'];
  transaction_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  utilizations?: Maybe<Array<Maybe<CoverageLimitUtilization>>>;
};

export type Platform = IDocument & {
  __typename?: 'Platform';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  identity_providers?: Maybe<Array<IdentityProviderInfo>>;
  name: Scalars['String'];
};

export type Policy = {
  __typename?: 'Policy';
  accepted: Scalars['Boolean'];
  audit: Audit;
  policy_type: PolicyType;
  reference: PolicyReference;
};

export type PolicyInput = {
  accepted: Scalars['Boolean'];
  audit?: InputMaybe<AuditInput>;
  policy_type: PolicyType;
};

export type PolicyReference = {
  __typename?: 'PolicyReference';
  reference_id: Scalars['String'];
  reference_type: PolicyReferenceType;
};

export enum PolicyReferenceType {
  PrtFinancialAccount = 'PRT_FINANCIAL_ACCOUNT',
  PrtUnknown = 'PRT_UNKNOWN',
}

export enum PolicyType {
  PAchEnrollment = 'P_ACH_ENROLLMENT',
  PHealthcare = 'P_HEALTHCARE',
  PTermsAndConditions = 'P_TERMS_AND_CONDITIONS',
  PUnknown = 'P_UNKNOWN',
  PUseOfEmployeeBankingInformation = 'P_USE_OF_EMPLOYEE_BANKING_INFORMATION',
}

export type PositionInSegment = {
  __typename?: 'PositionInSegment';
  component_position?: Maybe<Uint32Wrapper>;
  element_metadata?: Maybe<X12Metadata>;
  element_position?: Maybe<Uint32Wrapper>;
  repeating_element_position?: Maybe<Uint32Wrapper>;
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  building_name: Scalars['String'];
  city_town: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  postal_code: Scalars['String'];
  state_province: Scalars['String'];
  street_address1: Scalars['String'];
  street_address2?: Maybe<Scalars['String']>;
  unit_number: Scalars['String'];
};

export type PostalAddressStreet_Address1Args = {
  detokenize?: Scalars['Boolean'];
};

export type PostalAddressStreet_Address2Args = {
  detokenize?: Scalars['Boolean'];
};

export type PostalAddressInput = {
  city_town: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  postal_code: Scalars['String'];
  state_province: Scalars['String'];
  street_address1: Scalars['String'];
};

export type PostalAddressSearchResponse = {
  __typename?: 'PostalAddressSearchResponse';
  building_name?: Maybe<Scalars['String']>;
  city_town: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postal_code: Scalars['String'];
  state_province: Scalars['String'];
  street_address1: Scalars['String'];
  street_address2?: Maybe<Scalars['String']>;
  unit_number?: Maybe<Scalars['String']>;
};

export type PracticeFacilities = PaginatableResults & {
  __typename?: 'PracticeFacilities';
  offset: Scalars['Int'];
  results: Array<PracticeFacility>;
  total: Scalars['Int'];
};

export type PracticeFacilitiesAreaInput = {
  distance: Scalars['Int'];
  zip: Scalars['String'];
};

export type PracticeFacilitiesFilter = {
  area?: InputMaybe<PracticeFacilitiesAreaInput>;
  taxonomies?: InputMaybe<Array<Scalars['String']>>;
};

export type PracticeFacilitiesSearchResults = {
  __typename?: 'PracticeFacilitiesSearchResults';
  network_access_type?: Maybe<Scalars['String']>;
  results?: Maybe<Array<PracticeFacilityResponse>>;
};

export type PracticeFacility = IDocument & {
  __typename?: 'PracticeFacility';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  contacts?: Maybe<Array<FacilityContact>>;
  facility: Scalars['ID'];
  facility_model?: Maybe<Facility>;
  fee_schedule_ids?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  practice_specialties?: Maybe<Array<Scalars['String']>>;
  primary_contact: Scalars['String'];
  provider_group?: Maybe<ProviderGroup>;
  provider_group_id?: Maybe<Scalars['String']>;
  providers_at_facility?: Maybe<Array<ProvidersIn>>;
  street_address_2: Scalars['String'];
  taxonomy_ref_codes?: Maybe<Array<ReferenceCode>>;
  taxonomy_ref_ids?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<PracticeFacilityType>;
};

export type PracticeFacilityProviders_At_FacilityArgs = {
  only_active?: Scalars['Boolean'];
};

export type PracticeFacilityInAreaResponse = {
  __typename?: 'PracticeFacilityInAreaResponse';
  distance?: Maybe<Scalars['Float']>;
  practice_facility?: Maybe<PracticeFacility>;
};

export type PracticeFacilityResponse = {
  __typename?: 'PracticeFacilityResponse';
  _id: Scalars['String'];
  geolocation?: Maybe<Geolocation>;
  name?: Maybe<Scalars['String']>;
  postal_address?: Maybe<PostalAddressSearchResponse>;
  providers?: Maybe<Array<ProviderSearchResponse>>;
  taxonomy_ref_ids?: Maybe<Array<Scalars['String']>>;
};

export type PracticeFacilitySearchResponse = {
  __typename?: 'PracticeFacilitySearchResponse';
  _id: Scalars['String'];
  geolocation?: Maybe<Geolocation>;
  name?: Maybe<Scalars['String']>;
  postal_address?: Maybe<PostalAddressSearchResponse>;
  taxonomy_ref_ids?: Maybe<Array<Scalars['String']>>;
};

export enum PracticeFacilityType {
  PfAsc = 'PF_ASC',
  PfOffice = 'PF_OFFICE',
  PfUnknownType = 'PF_UNKNOWN_TYPE',
}

export type PreInvoiceAddressInput = {
  city_town?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state_province?: InputMaybe<Scalars['String']>;
  street_address1?: InputMaybe<Scalars['String']>;
  street_address2?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceBillingFacilityInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceBillingProviderInput = {
  name?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceCodedMedicalServiceInput = {
  code: Scalars['String'];
  codeset: MedicalCodeSetType;
  medical_code_definition_id?: InputMaybe<Scalars['ID']>;
  modifier_codes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PreInvoiceInput = {
  billing_facility?: InputMaybe<PreInvoiceBillingFacilityInput>;
  billing_provider?: InputMaybe<PreInvoiceBillingProviderInput>;
  cash_payment?: InputMaybe<Scalars['Boolean']>;
  encounter_id?: InputMaybe<Scalars['ID']>;
  has_patient?: InputMaybe<Scalars['Boolean']>;
  invoice_id?: InputMaybe<Scalars['ID']>;
  line_items?: InputMaybe<Array<InputMaybe<PreInvoiceServiceLineInput>>>;
  member_id?: InputMaybe<Scalars['ID']>;
  patient?: InputMaybe<PreInvoicePatientInput>;
  payment_info?: InputMaybe<PreInvoicePaymentInfoInput>;
  pcn?: InputMaybe<Scalars['String']>;
  place_of_service_code?: InputMaybe<PlaceOfService>;
  relationship_to_insured?: InputMaybe<Scalars['String']>;
  rendering_facility?: InputMaybe<PreInvoiceRenderingFacilityInput>;
  rendering_provider?: InputMaybe<PreInvoiceRenderingProviderInput>;
  service_lines?: InputMaybe<Array<InputMaybe<PreInvoiceLineItemsInput>>>;
  source: InvoiceSource;
  spec?: InputMaybe<PreInvoiceSpecificationInput>;
  subscriber?: InputMaybe<PreInvoiceSubscriberInput>;
};

export type PreInvoiceLineItemsInput = {
  charge?: InputMaybe<Scalars['Float']>;
  charge_pennies?: InputMaybe<Scalars['Int']>;
  charged_code?: InputMaybe<PreInvoiceCodedMedicalServiceInput>;
  diagnosis_codes?: InputMaybe<Array<InputMaybe<PreInvoiceCodedMedicalServiceInput>>>;
  from_date?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  raw_charged_code?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type PreInvoicePatientInput = {
  address?: InputMaybe<PreInvoiceAddressInput>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  insured_member_id?: InputMaybe<Scalars['ID']>;
  last_name?: InputMaybe<Scalars['String']>;
  social_security_number?: InputMaybe<Scalars['String']>;
};

export type PreInvoicePaymentInfoInput = {
  admin_fee?: InputMaybe<Scalars['Float']>;
  bundled_rate?: InputMaybe<Scalars['Float']>;
  due_from_employer?: InputMaybe<Scalars['Float']>;
  invoice_amount?: InputMaybe<Scalars['Float']>;
  patient_paid?: InputMaybe<Scalars['Float']>;
};

export type PreInvoiceRenderingFacilityInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceRenderingProviderInput = {
  name?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceServiceLineInput = {
  charge?: InputMaybe<Scalars['Float']>;
  charge_pennies?: InputMaybe<Scalars['Int']>;
  charged_code?: InputMaybe<PreInvoiceCodedMedicalServiceInput>;
  place_of_service?: InputMaybe<PlaceOfService>;
  provider_name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  service_type?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceSpecificationInput = {
  description?: InputMaybe<Scalars['String']>;
  displayId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ref?: InputMaybe<Scalars['String']>;
};

export type PreInvoiceSubscriberInput = {
  address?: InputMaybe<PreInvoiceAddressInput>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  insured_member_id?: InputMaybe<Scalars['ID']>;
  last_name?: InputMaybe<Scalars['String']>;
  social_security_number?: InputMaybe<Scalars['String']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  service_line_items?: Maybe<Array<ServiceItemResolution>>;
  total_amount_pennies?: Maybe<Scalars['Int']>;
};

export type PricingEventInfo = {
  __typename?: 'PricingEventInfo';
  external_pricing?: Maybe<Pricing>;
  nomi_pricing?: Maybe<Pricing>;
};

export type PricingStep = {
  __typename?: 'PricingStep';
  allowed_amount_pennies?: Maybe<Scalars['Int']>;
  type?: Maybe<PricingStepType>;
};

export enum PricingStepType {
  PsBundledFeeScheduleApplication = 'PS_BUNDLED_FEE_SCHEDULE_APPLICATION',
  PsCollectionApplication = 'PS_COLLECTION_APPLICATION',
  PsFeeScheduleApplication = 'PS_FEE_SCHEDULE_APPLICATION',
  PsGlobalCarveOut = 'PS_GLOBAL_CARVE_OUT',
  PsInstitutionalFeeScheduleApplication = 'PS_INSTITUTIONAL_FEE_SCHEDULE_APPLICATION',
  PsLesserOfBilledCharges = 'PS_LESSER_OF_BILLED_CHARGES',
  PsPayerCompass = 'PS_PAYER_COMPASS',
  PsPercentBilledCharges = 'PS_PERCENT_BILLED_CHARGES',
  PsProfessionalFeeScheduleApplication = 'PS_PROFESSIONAL_FEE_SCHEDULE_APPLICATION',
  PsReferenceBasedFeeScheduleApplication = 'PS_REFERENCE_BASED_FEE_SCHEDULE_APPLICATION',
  PsStoploss = 'PS_STOPLOSS',
  PsUnknown = 'PS_UNKNOWN',
}

export enum PrimaryClientType {
  PctNetwork = 'PCT_NETWORK',
  PctTpa = 'PCT_TPA',
  PctUnknown = 'PCT_UNKNOWN',
}

export type PrimaryPaymentClientInfo = {
  __typename?: 'PrimaryPaymentClientInfo';
  client_type?: Maybe<PrimaryClientType>;
  secondary_payment_client_ids?: Maybe<Array<Scalars['String']>>;
};

export type PrimaryPaymentClientInfoInput = {
  client_type?: InputMaybe<PrimaryClientType>;
};

export enum ProcessingInfoType {
  ClaimsProcessingInfoType = 'CLAIMS_PROCESSING_INFO_TYPE',
  EligibilityFileProcessingInfoType = 'ELIGIBILITY_FILE_PROCESSING_INFO_TYPE',
  FeeScheduleProcessingInfoType = 'FEE_SCHEDULE_PROCESSING_INFO_TYPE',
  GeneralProcessingInfoType = 'GENERAL_PROCESSING_INFO_TYPE',
  MemberRosterProcessingInfoType = 'MEMBER_ROSTER_PROCESSING_INFO_TYPE',
  PayeeRosterProcessingInfoType = 'PAYEE_ROSTER_PROCESSING_INFO_TYPE',
  PlanDocumentInfoType = 'PLAN_DOCUMENT_INFO_TYPE',
  ProviderGroupContractInfoType = 'PROVIDER_GROUP_CONTRACT_INFO_TYPE',
  ProviderRosterProcessingInfoType = 'PROVIDER_ROSTER_PROCESSING_INFO_TYPE',
  RemittanceProcessingInfoType = 'REMITTANCE_PROCESSING_INFO_TYPE',
  UnknownProcessingInfoType = 'UNKNOWN_PROCESSING_INFO_TYPE',
}

export enum ProcessingStatus {
  ProcessingStatusError = 'PROCESSING_STATUS_ERROR',
  ProcessingStatusSuccess = 'PROCESSING_STATUS_SUCCESS',
  ProcessingStatusUnknown = 'PROCESSING_STATUS_UNKNOWN',
}

export type ProfessionalFeeScheduleEntries = {
  __typename?: 'ProfessionalFeeScheduleEntries';
  fee_sch_line_items?: Maybe<Array<ProfessionalFeeScheduleEntry>>;
  total: Scalars['Int'];
};

export type ProfessionalFeeScheduleEntriesInput = {
  fee_sch_line_items?: InputMaybe<Array<ProfessionalFeeScheduleEntryInput>>;
};

export type ProfessionalFeeScheduleEntry = {
  __typename?: 'ProfessionalFeeScheduleEntry';
  allowed_amount_pennies?: Maybe<Scalars['Int']>;
  cpt_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ref_code?: Maybe<ReferenceCode>;
  ref_code_id?: Maybe<Scalars['String']>;
};

export type ProfessionalFeeScheduleEntryInput = {
  allowed_amount_pennies?: InputMaybe<Scalars['Int']>;
  cpt_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ref_code?: InputMaybe<ReferenceCodeInput>;
};

export type ProfessionalRbpFeeScheduleEntries = {
  __typename?: 'ProfessionalRBPFeeScheduleEntries';
  fee_sch_line_items?: Maybe<Array<ProfessionalRbpFeeScheduleEntry>>;
  priority_line_items?: Maybe<FeeSchedulePriorityEntries>;
  total: Scalars['Int'];
};

export type ProfessionalRbpFeeScheduleEntriesInput = {
  fee_sch_line_items?: InputMaybe<Array<ProfessionalRbpFeeScheduleEntryInput>>;
  priority_line_items?: InputMaybe<FeeSchedulePriorityEntriesInput>;
};

export type ProfessionalRbpFeeScheduleEntry = {
  __typename?: 'ProfessionalRBPFeeScheduleEntry';
  code_type?: Maybe<FeeScheduleCodeType>;
  medical_code_group_ids?: Maybe<Array<Scalars['String']>>;
  medical_code_groups?: Maybe<Array<Maybe<MedicalCodeGroup>>>;
  percentage_factor?: Maybe<Scalars['Float']>;
  rate_type: RateType;
  reference_code_ids?: Maybe<Array<Scalars['String']>>;
  reference_fee_schedule_type: ReferenceFeeScheduleType;
};

export type ProfessionalRbpFeeScheduleEntryInput = {
  code_type?: InputMaybe<FeeScheduleCodeType>;
  codes_specifications?: InputMaybe<Array<Scalars['String']>>;
  percentage_factor?: InputMaybe<Scalars['Float']>;
  rate_type: RateType;
  reference_code_ids?: InputMaybe<Array<Scalars['String']>>;
  reference_fee_schedule_type: ReferenceFeeScheduleType;
};

export type ProfessionalService = {
  __typename?: 'ProfessionalService';
  copay_status?: Maybe<X12Code>;
  diagnosis_code_pointer?: Maybe<CompositeDiagnosisCodePointer>;
  emergency?: Maybe<X12Code>;
  epsdt?: Maybe<X12Code>;
  facility_code?: Maybe<Scalars['String']>;
  family_planning?: Maybe<X12Code>;
  healthcare_professional_shortage_area_code?: Maybe<Scalars['String']>;
  level_of_care_code?: Maybe<Scalars['String']>;
  monetary_amount?: Maybe<FloatWrapper>;
  monetary_amount_2?: Maybe<FloatWrapper>;
  monetary_amount_3?: Maybe<FloatWrapper>;
  multiple_procedure_code?: Maybe<Scalars['String']>;
  national_assigned_review_value?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  procedure?: Maybe<CompositeMedicalProcedure>;
  provider_agreement_code?: Maybe<Scalars['String']>;
  quantity?: Maybe<FloatWrapper>;
  reference_id?: Maybe<Scalars['String']>;
  review_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  service_type_code?: Maybe<Scalars['String']>;
  units?: Maybe<X12Code>;
};

export type ProfessionalServiceInput = {
  copay_status?: InputMaybe<X12CodeInput>;
  diagnosis_code_pointer?: InputMaybe<CompositeDiagnosisCodePointerInput>;
  emergency?: InputMaybe<X12CodeInput>;
  epsdt?: InputMaybe<X12CodeInput>;
  facility_code?: InputMaybe<Scalars['String']>;
  family_planning?: InputMaybe<X12CodeInput>;
  healthcare_professional_shortage_area_code?: InputMaybe<Scalars['String']>;
  level_of_care_code?: InputMaybe<Scalars['String']>;
  monetary_amount?: InputMaybe<FloatWrapperInput>;
  monetary_amount_2?: InputMaybe<FloatWrapperInput>;
  monetary_amount_3?: InputMaybe<FloatWrapperInput>;
  multiple_procedure_code?: InputMaybe<Scalars['String']>;
  national_assigned_review_value?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  procedure?: InputMaybe<CompositeMedicalProcedureInput>;
  provider_agreement_code?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<FloatWrapperInput>;
  reference_id?: InputMaybe<Scalars['String']>;
  review_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  service_type_code?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<X12CodeInput>;
};

export type Provider = IDocument & {
  __typename?: 'Provider';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  lifecycle_state?: Maybe<ProviderLifecycleState>;
  npi: Scalars['String'];
  party?: Maybe<Party>;
  party_id: Scalars['ID'];
  practice_facilities?: Maybe<Array<Scalars['String']>>;
  practice_facilities_models: Array<Maybe<PracticeFacility>>;
  reference_code_ids?: Maybe<Array<Scalars['ID']>>;
  reference_codes?: Maybe<Array<ReferenceCode>>;
  specialties?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<ProviderType>;
};

export type ProviderPractice_Facilities_ModelsArgs = {
  providerGroupId?: InputMaybe<Scalars['ID']>;
};

export type ProviderAdjustment = {
  __typename?: 'ProviderAdjustment';
  adjustment_id_1?: Maybe<AdjustmentIdentifier>;
  adjustment_id_2?: Maybe<AdjustmentIdentifier>;
  adjustment_id_3?: Maybe<AdjustmentIdentifier>;
  adjustment_id_4?: Maybe<AdjustmentIdentifier>;
  adjustment_id_5?: Maybe<AdjustmentIdentifier>;
  adjustment_id_6?: Maybe<AdjustmentIdentifier>;
  fiscal_period_date?: Maybe<Scalars['String']>;
  provider_adjustment_amount_1?: Maybe<FloatWrapper>;
  provider_adjustment_amount_2?: Maybe<FloatWrapper>;
  provider_adjustment_amount_3?: Maybe<FloatWrapper>;
  provider_adjustment_amount_4?: Maybe<FloatWrapper>;
  provider_adjustment_amount_5?: Maybe<FloatWrapper>;
  provider_adjustment_amount_6?: Maybe<FloatWrapper>;
  provider_identifier?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ProviderAdjustmentInput = {
  adjustment_id_1?: InputMaybe<AdjustmentIdentifierInput>;
  adjustment_id_2?: InputMaybe<AdjustmentIdentifierInput>;
  adjustment_id_3?: InputMaybe<AdjustmentIdentifierInput>;
  adjustment_id_4?: InputMaybe<AdjustmentIdentifierInput>;
  adjustment_id_5?: InputMaybe<AdjustmentIdentifierInput>;
  adjustment_id_6?: InputMaybe<AdjustmentIdentifierInput>;
  fiscal_period_date?: InputMaybe<Scalars['String']>;
  provider_adjustment_amount_1?: InputMaybe<FloatWrapperInput>;
  provider_adjustment_amount_2?: InputMaybe<FloatWrapperInput>;
  provider_adjustment_amount_3?: InputMaybe<FloatWrapperInput>;
  provider_adjustment_amount_4?: InputMaybe<FloatWrapperInput>;
  provider_adjustment_amount_5?: InputMaybe<FloatWrapperInput>;
  provider_adjustment_amount_6?: InputMaybe<FloatWrapperInput>;
  provider_identifier?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ProviderAndFacilityResponse = {
  __typename?: 'ProviderAndFacilityResponse';
  practice_facility: PracticeFacilitySearchResponse;
  provider?: Maybe<ProviderSearchResponse>;
};

export type ProviderChangeReason = {
  __typename?: 'ProviderChangeReason';
  action_code?: Maybe<X12Code>;
  date?: Maybe<Scalars['String']>;
  entity_id_code?: Maybe<X12Code>;
  maintenance_reason_code?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  time?: Maybe<Scalars['String']>;
};

export enum ProviderFormatType {
  PruNomi = 'PRU_NOMI',
  PruQuest = 'PRU_QUEST',
  PruTellus = 'PRU_TELLUS',
  UnknownFormatType = 'UNKNOWN_FORMAT_TYPE',
}

export type ProviderGroup = IDocument & {
  __typename?: 'ProviderGroup';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  authorized_official?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Contract>>;
  credentials?: Maybe<Scalars['String']>;
  entity_type?: Maybe<ProviderGroupType>;
  lifecycle_state?: Maybe<ProviderGroupLifecycleState>;
  name?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  party_id: Scalars['ID'];
  party_object?: Maybe<Party>;
  practice_facilities?: Maybe<Array<Scalars['ID']>>;
  practice_facility_objects?: Maybe<Array<PracticeFacility>>;
  providers_in_group?: Maybe<Array<ProvidersIn>>;
  specialties?: Maybe<Scalars['String']>;
  subsidiaries?: Maybe<Array<ProviderGroup>>;
};

export type ProviderGroupContractsArgs = {
  networkID: Scalars['ID'];
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderGroupPractice_Facility_ObjectsArgs = {
  filter?: InputMaybe<PgFilter>;
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProviderGroupProviders_In_GroupArgs = {
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProviderGroupSubsidiariesArgs = {
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProviderGroupAddressInput = {
  city: Scalars['String'];
  state: Scalars['String'];
  street_address: Scalars['String'];
  zip: Scalars['String'];
};

export type ProviderGroupContract = {
  __typename?: 'ProviderGroupContract';
  fee_schedule?: Maybe<FeeSchedule>;
  fee_schedule_assignments?: Maybe<Array<Maybe<FeeScheduleAssignment>>>;
  fee_schedule_id: Scalars['ID'];
  party?: Maybe<Party>;
  party_id: Scalars['ID'];
  provider_group_id?: Maybe<Scalars['ID']>;
  providers?: Maybe<Providers>;
  subsidiary_parties?: Maybe<Array<Party>>;
  subsidiary_party_ids?: Maybe<Array<Scalars['ID']>>;
  subsidiary_provider_group_ids?: Maybe<Array<Scalars['ID']>>;
  subsidiary_provider_groups?: Maybe<Array<ProviderGroup>>;
};

export type ProviderGroupContractProvidersArgs = {
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProviderGroupContractInput = {
  fee_schedule_assignments?: InputMaybe<Array<FeeScheduleAssignmentInput>>;
  fee_schedule_id?: InputMaybe<Scalars['ID']>;
  party_id: Scalars['ID'];
  provider_group_id?: InputMaybe<Scalars['ID']>;
  subsidiary_party_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProviderGroupContractProcessingInfo = {
  __typename?: 'ProviderGroupContractProcessingInfo';
  contract_upload_type?: Maybe<ContractUploadType>;
  effective_dates?: Maybe<EffectiveDates>;
  file_name: Scalars['String'];
};

export enum ProviderGroupLifecycleState {
  PgContracted = 'PG_CONTRACTED',
  PgCredentialed = 'PG_CREDENTIALED',
  PgEngaging = 'PG_ENGAGING',
  PgIdentified = 'PG_IDENTIFIED',
  PgIncorporated = 'PG_INCORPORATED',
  PgIneligible = 'PG_INELIGIBLE',
  PgIntegrated = 'PG_INTEGRATED',
  PgNonParticipating = 'PG_NON_PARTICIPATING',
  PgOnboarded = 'PG_ONBOARDED',
  PgTerminated = 'PG_TERMINATED',
  PgUnknownLifecycleState = 'PG_UNKNOWN_LIFECYCLE_STATE',
}

export type ProviderGroupNameResponse = {
  __typename?: 'ProviderGroupNameResponse';
  name?: Maybe<Scalars['String']>;
  party_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
};

export type ProviderGroupNameResponseTinArgs = {
  detokenize?: Scalars['Boolean'];
};

export type ProviderGroupStatesFilter = {
  as_of_date?: InputMaybe<Scalars['String']>;
  lifecycle_states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  network_id: Scalars['String'];
  organization_name?: InputMaybe<Scalars['String']>;
};

export enum ProviderGroupType {
  IndividualPhysician = 'INDIVIDUAL_PHYSICIAN',
  MedicalGroup = 'MEDICAL_GROUP',
  Unknown = 'UNKNOWN',
}

export type ProviderGroups = PaginatableResults & {
  __typename?: 'ProviderGroups';
  offset: Scalars['Int'];
  results: Array<ProviderGroup>;
  total: Scalars['Int'];
};

export type ProviderInfo = {
  __typename?: 'ProviderInfo';
  identification_code?: Maybe<Scalars['String']>;
  provider_id_qualifier_code?: Maybe<X12Code>;
  provider_org_code?: Maybe<Scalars['String']>;
  provider_type?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
  specialty_info?: Maybe<ProviderSpecialtyInfo>;
  state?: Maybe<Scalars['String']>;
};

export type ProviderInfoInput = {
  identification_code?: InputMaybe<Scalars['String']>;
  provider_id_qualifier_code?: InputMaybe<X12CodeInput>;
  provider_org_code?: InputMaybe<Scalars['String']>;
  provider_type?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  specialty_info?: InputMaybe<ProviderSpecialtyInfoInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type ProviderInput = {
  _id?: InputMaybe<Scalars['ID']>;
  caqh_number: Scalars['String'];
  contract_id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  license_number: Scalars['String'];
  middle_initial?: InputMaybe<Scalars['String']>;
  practice_ids?: InputMaybe<Array<Scalars['ID']>>;
  provider_npi: Scalars['String'];
  provider_type: ProviderType;
  taxonomy_codes?: InputMaybe<Array<Scalars['String']>>;
};

export enum ProviderLifecycleState {
  Credentialed = 'CREDENTIALED',
  Identified = 'IDENTIFIED',
  Incorporated = 'INCORPORATED',
  Ineligible = 'INELIGIBLE',
  Integrated = 'INTEGRATED',
  Nonparticipating = 'NONPARTICIPATING',
  Terminated = 'TERMINATED',
}

export type ProviderLoop = {
  __typename?: 'ProviderLoop';
  bpname?: Maybe<BillingProvider>;
  currency?: Maybe<Currency>;
  hierarchy?: Maybe<Hierarchy>;
  loop_metadata?: Maybe<X12Metadata>;
  provider_info?: Maybe<ProviderInfo>;
  ptaname?: Maybe<PayToAddressName>;
  ptpname?: Maybe<PayToPlanName>;
};

export type ProviderLoop837I = {
  __typename?: 'ProviderLoop837I';
  bpname?: Maybe<BillingProvider837I>;
  currency?: Maybe<Currency>;
  hierarchy?: Maybe<Hierarchy>;
  loop_metadata?: Maybe<X12Metadata>;
  provider_info?: Maybe<ProviderInfo>;
  ptaname?: Maybe<PayToAddressName837I>;
  ptpname?: Maybe<PayToPlanName837I>;
};

export type ProviderLoop837IInput = {
  bpname?: InputMaybe<BillingProvider837IInput>;
  currency?: InputMaybe<CurrencyInput>;
  hierarchy?: InputMaybe<HierarchyInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  provider_info?: InputMaybe<ProviderInfoInput>;
  ptaname?: InputMaybe<PayToAddressName837IInput>;
  ptpname?: InputMaybe<PayToPlanName837IInput>;
};

export type ProviderLoopInput = {
  bpname?: InputMaybe<BillingProviderInput>;
  currency?: InputMaybe<CurrencyInput>;
  hierarchy?: InputMaybe<HierarchyInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  provider_info?: InputMaybe<ProviderInfoInput>;
  ptaname?: InputMaybe<PayToAddressNameInput>;
  ptpname?: InputMaybe<PayToPlanNameInput>;
};

export type ProviderPortalUserInput = {
  email: Scalars['String'];
  group_tin: Scalars['String'];
  names: Array<Scalars['String']>;
  pin: Scalars['String'];
  policies_accepted?: InputMaybe<Array<PolicyInput>>;
  user_roles?: InputMaybe<Array<UserRole>>;
};

export enum ProviderRelationship {
  PrAffiliated = 'PR_AFFILIATED',
  PrAssociated = 'PR_ASSOCIATED',
}

export enum ProviderRosterFormatType {
  Nomi = 'NOMI',
  Quest = 'QUEST',
  Tellus = 'TELLUS',
}

export type ProviderRosterProcessingInfo = {
  __typename?: 'ProviderRosterProcessingInfo';
  audit_logs?: Maybe<Array<ProviderRosterUploadAuditLog>>;
  document_summary?: Maybe<Array<Maybe<DocumentSummary>>>;
  error?: Maybe<Scalars['Boolean']>;
  format_type?: Maybe<ProviderFormatType>;
  network_name?: Maybe<Scalars['String']>;
  processed_count?: Maybe<Scalars['Int']>;
  provider_group_id?: Maybe<Scalars['String']>;
  provider_roster_upload_state?: Maybe<ProviderRosterUploadState>;
  success_row_count?: Maybe<Scalars['Int']>;
  total_records?: Maybe<Scalars['Int']>;
  upload_name?: Maybe<Scalars['String']>;
};

export type ProviderRosterProcessingInfoInput = {
  audit_logs?: InputMaybe<Array<ProviderRosterUploadAuditLogInput>>;
  document_summary?: InputMaybe<Array<DocumentSummaryInput>>;
  error?: InputMaybe<Scalars['Boolean']>;
  format_type?: InputMaybe<ProviderFormatType>;
  network_name?: InputMaybe<Scalars['String']>;
  processed_count?: InputMaybe<Scalars['Int']>;
  provider_group_id?: InputMaybe<Scalars['String']>;
  provider_roster_upload_state?: InputMaybe<ProviderRosterUploadState>;
  success_row_count?: InputMaybe<Scalars['Int']>;
  total_records?: InputMaybe<Scalars['Int']>;
  upload_name?: InputMaybe<Scalars['String']>;
};

export type ProviderRosterUploadAuditLog = {
  __typename?: 'ProviderRosterUploadAuditLog';
  provider_roster_upload_state_transition?: Maybe<ProviderRosterUploadStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export type ProviderRosterUploadAuditLogInput = {
  provider_roster_upload_state_transition?: InputMaybe<ProviderRosterUploadStateTransitionInput>;
  transitioned_at_time?: InputMaybe<Scalars['String']>;
};

export enum ProviderRosterUploadState {
  PruAdminCreated = 'PRU_ADMIN_CREATED',
  PruAdminPingEntityCreated = 'PRU_ADMIN_PING_ENTITY_CREATED',
  PruAssociatedPracticeFacilityToProviderGroup = 'PRU_ASSOCIATED_PRACTICE_FACILITY_TO_PROVIDER_GROUP',
  PruAssociatedProviderGroupToNetwork = 'PRU_ASSOCIATED_PROVIDER_GROUP_TO_NETWORK',
  PruContactsCreated = 'PRU_CONTACTS_CREATED',
  PruCreated = 'PRU_CREATED',
  PruFacilityCreated = 'PRU_FACILITY_CREATED',
  PruFailed = 'PRU_FAILED',
  PruFileValidated = 'PRU_FILE_VALIDATED',
  PruMetadataValidated = 'PRU_METADATA_VALIDATED',
  PruPracticeFacilityCreated = 'PRU_PRACTICE_FACILITY_CREATED',
  PruProviderContactsCreated = 'PRU_PROVIDER_CONTACTS_CREATED',
  PruProviderCreated = 'PRU_PROVIDER_CREATED',
  PruProviderGroupsCreated = 'PRU_PROVIDER_GROUPS_CREATED',
  PruProviderGroupBankAccountOnboarded = 'PRU_PROVIDER_GROUP_BANK_ACCOUNT_ONBOARDED',
  PruProviderGroupConnectedAccountCreated = 'PRU_PROVIDER_GROUP_CONNECTED_ACCOUNT_CREATED',
  PruProviderGroupPartyCreated = 'PRU_PROVIDER_GROUP_PARTY_CREATED',
  PruProviderPartyCreated = 'PRU_PROVIDER_PARTY_CREATED',
  PruProviderPingEntityCreated = 'PRU_PROVIDER_PING_ENTITY_CREATED',
  PruSuccess = 'PRU_SUCCESS',
  PruUnknown = 'PRU_UNKNOWN',
}

export type ProviderRosterUploadStateTransition = {
  __typename?: 'ProviderRosterUploadStateTransition';
  from_state?: Maybe<ProviderRosterUploadState>;
  to_state?: Maybe<ProviderRosterUploadState>;
};

export type ProviderRosterUploadStateTransitionInput = {
  from_state?: InputMaybe<ProviderRosterUploadState>;
  to_state?: InputMaybe<ProviderRosterUploadState>;
};

export type ProviderSearchResponse = {
  __typename?: 'ProviderSearchResponse';
  names?: Maybe<Array<Scalars['String']>>;
  reference_code_ids?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
};

export type ProviderSpecialtyInfo = {
  __typename?: 'ProviderSpecialtyInfo';
  element_metadata?: Maybe<X12Metadata>;
};

export type ProviderSpecialtyInfoInput = {
  element_metadata?: InputMaybe<X12MetadataInput>;
};

export enum ProviderType {
  PArnp = 'P_ARNP',
  PDo = 'P_DO',
  PMd = 'P_MD',
  PPa = 'P_PA',
  PUnknownType = 'P_UNKNOWN_TYPE',
}

export type Providers = Paginatable & {
  __typename?: 'Providers';
  offset: Scalars['Int'];
  results: Array<Provider>;
  total: Scalars['Int'];
};

export type ProvidersIn = IEffectiveDates & {
  __typename?: 'ProvidersIn';
  effective_dates?: Maybe<EffectiveDates>;
  provider?: Maybe<Provider>;
  provider_id: Scalars['String'];
  relationship?: Maybe<ProviderRelationship>;
};

export type ProvidersSearchFilter = {
  distance?: Scalars['Int'];
  group_id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  taxonomies?: InputMaybe<Array<Scalars['String']>>;
  zip: Scalars['String'];
};

export type ProvidersSearchResponse = {
  __typename?: 'ProvidersSearchResponse';
  network_access_type?: Maybe<Scalars['String']>;
  results?: Maybe<Array<ProviderAndFacilityResponse>>;
};

export enum ProvisionAction {
  Excluded = 'EXCLUDED',
  Provided = 'PROVIDED',
}

export type PurchasedServiceInfo = {
  __typename?: 'PurchasedServiceInfo';
  monetary_amount?: Maybe<Scalars['String']>;
  psp_identifier?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  state?: Maybe<Scalars['String']>;
};

export type PurchasedServiceInfoInput = {
  monetary_amount?: InputMaybe<Scalars['String']>;
  psp_identifier?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type PurchasedServiceProvider = {
  __typename?: 'PurchasedServiceProvider';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type PurchasedServiceProviderInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type Quantity = {
  __typename?: 'Quantity';
  composite_unit_of_measure?: Maybe<Scalars['String']>;
  free_form_information?: Maybe<Scalars['String']>;
  quantity?: Maybe<FloatWrapper>;
  quantity_qualifier?: Maybe<X12Code>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type QuantityInput = {
  composite_unit_of_measure?: InputMaybe<Scalars['String']>;
  free_form_information?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<FloatWrapperInput>;
  quantity_qualifier?: InputMaybe<X12CodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type Query = {
  __typename?: 'Query';
  MedicalCodeGroup?: Maybe<MedicalCodeGroup>;
  PaymentHistories: PaymentHistories;
  SearchInvoiceByRawRemittance835ID?: Maybe<Invoice>;
  adjudicateEncounter?: Maybe<AdjudicateEncounter>;
  batchGroup?: Maybe<BatchGroup>;
  batchGroupForFileUpload?: Maybe<Array<BatchGroup>>;
  complianceInfoForParty?: Maybe<ComplianceInfo>;
  contractByID?: Maybe<Contract>;
  contractsByEmployerGroupStates?: Maybe<Contracts>;
  contractsByProviderGroupStates?: Maybe<Contracts>;
  contractsQuery?: Maybe<Contracts>;
  doesCurrentUserHavePermission?: Maybe<Scalars['Boolean']>;
  encounterByFields: Array<Maybe<Encounter>>;
  etlMetadata?: Maybe<EtlMetadata>;
  etlMetadataByFields?: Maybe<EtlMetadata>;
  etlMetadataQuery?: Maybe<Array<Maybe<EtlMetadata>>>;
  facilitiesByPersonsAtFacility?: Maybe<Array<Maybe<Facility>>>;
  feeSchedule?: Maybe<FeeSchedule>;
  feeScheduleV2?: Maybe<FeeScheduleV2>;
  fileUpload?: Maybe<FileUpload>;
  fileUploadQuery?: Maybe<FileUpload>;
  getInvoiceExceptionDetails?: Maybe<InvoiceExceptionResponse>;
  getInvoiceTpaNames?: Maybe<Array<Maybe<UniqueNames>>>;
  getNetworkSpecialties?: Maybe<Array<SpecialtyToTaxonomies>>;
  getNetworkSpecialtiesRealTime?: Maybe<Array<SpecialtyToTaxonomies>>;
  getProviderGroupsForRCM: ProviderGroups;
  getRCMUserBankAccounts?: Maybe<Array<RcmBankAccountInfo>>;
  getRCMUserOrganizations?: Maybe<Array<RcmOrganization>>;
  getRemittanceGroupsForRCM: RemittanceGroups;
  getRemittanceGroupsPayerNamesListForRCM?: Maybe<Array<Maybe<UniqueNames>>>;
  getS3PresignedGetURL?: Maybe<S3PresignedGetUrlOutput>;
  getS3PresignedGetURLOne?: Maybe<S3PresignedGetUrlOutput>;
  invoiceByBillId?: Maybe<Invoice>;
  invoiceByClaimId?: Maybe<Invoice>;
  invoiceById?: Maybe<Invoice>;
  invoiceByPatientName?: Maybe<Invoices>;
  invoices?: Maybe<Invoices>;
  invoicesForRCM?: Maybe<Invoices>;
  member?: Maybe<Member>;
  memberByFields?: Maybe<Member>;
  memberCardUrl?: Maybe<Scalars['String']>;
  memberQuery: Array<Member>;
  party?: Maybe<Party>;
  partyQuery?: Maybe<Party>;
  partyQueryByGovernmentId?: Maybe<Array<Maybe<Party>>>;
  paymentClientQuery: Array<PaymentClient>;
  paymentDocumentation?: Maybe<PaymentDocumentation>;
  ping: Scalars['Boolean'];
  platform?: Maybe<Platform>;
  platformByName?: Maybe<Platform>;
  practiceFacilitiesByPartyIds: PracticeFacilities;
  practiceFacilitiesInArea?: Maybe<Array<PracticeFacilityInAreaResponse>>;
  practiceFacilitiesQuery?: Maybe<PracticeFacilities>;
  practiceFacility?: Maybe<PracticeFacility>;
  practiceFacilityByFacilityID?: Maybe<PracticeFacility>;
  providerById?: Maybe<Provider>;
  providerGroupByID?: Maybe<ProviderGroup>;
  providerGroupByNPI?: Maybe<Array<ProviderGroup>>;
  providerGroupByTINAndPIN?: Maybe<ProviderGroupNameResponse>;
  providerGroupsByPartyIds?: Maybe<Array<ProviderGroup>>;
  providerGroupsWithProvider?: Maybe<Array<ProviderGroup>>;
  providersQuery?: Maybe<Providers>;
  queryPaymentClientByID?: Maybe<PaymentClient>;
  queryPaymentClientByPartyID: Array<PaymentClient>;
  queryReferenceCodes?: Maybe<Array<Maybe<Result>>>;
  querySponsorByGroupId?: Maybe<Array<Maybe<Sponsor>>>;
  querySponsorById?: Maybe<Sponsor>;
  querySponsorByInternalOrExternal?: Maybe<Array<Maybe<Sponsor>>>;
  queryTaxonomyCodesBySpecialty?: Maybe<Array<Maybe<SpecialtyTaxonomyCodes>>>;
  remittanceGroupById?: Maybe<RemittanceGroup>;
  roleMembershipByFields: Array<Maybe<RoleMembership>>;
  roleMemberships?: Maybe<Array<RoleMembership>>;
  roles?: Maybe<Roles>;
  searchPracticeFacilitiesWithFilter?: Maybe<PracticeFacilitiesSearchResults>;
  searchProvidersWithFilter?: Maybe<ProvidersSearchResponse>;
  stripe_config?: Maybe<StripeConfig>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  viewRCMUsers?: Maybe<Array<Maybe<RcmUser>>>;
  virtualCardByRemittanceGroupId: VirtualCardPdfResponse;
  virtualCardPdf: VirtualCardPdfResponse;
};

export type QueryMedicalCodeGroupArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentHistoriesArgs = {
  facility_id: Scalars['ID'];
  query?: InputMaybe<PaymentHistoryQuery>;
};

export type QuerySearchInvoiceByRawRemittance835IdArgs = {
  rawRemittanceID: Scalars['ID'];
};

export type QueryAdjudicateEncounterArgs = {
  input?: InputMaybe<AdjudicateEncounterInput>;
};

export type QueryBatchGroupArgs = {
  id: Scalars['ID'];
};

export type QueryBatchGroupForFileUploadArgs = {
  fileUploadId: Scalars['ID'];
  options?: InputMaybe<Options>;
};

export type QueryComplianceInfoForPartyArgs = {
  forPartyId: Scalars['ID'];
};

export type QueryContractByIdArgs = {
  defaultOptionForFeeSchedule?: InputMaybe<Options>;
  id: Scalars['ID'];
  optionsForFeeSchedule?: InputMaybe<Array<InputMaybe<Options>>>;
};

export type QueryContractsByEmployerGroupStatesArgs = {
  filter: SponsorsFilter;
  options?: InputMaybe<Options>;
};

export type QueryContractsByProviderGroupStatesArgs = {
  filter: ProviderGroupStatesFilter;
  options?: InputMaybe<Options>;
};

export type QueryContractsQueryArgs = {
  filter: ContractFilter;
  options?: InputMaybe<Options>;
};

export type QueryDoesCurrentUserHavePermissionArgs = {
  permission: Scalars['String'];
};

export type QueryEncounterByFieldsArgs = {
  filters: EncounterFilters;
  options?: Options;
};

export type QueryEtlMetadataArgs = {
  id: Scalars['ID'];
};

export type QueryEtlMetadataByFieldsArgs = {
  etlMetadataFilter?: InputMaybe<EtlMetadataFilter>;
};

export type QueryEtlMetadataQueryArgs = {
  etlMetadataFilter?: InputMaybe<EtlMetadataFilter>;
};

export type QueryFacilitiesByPersonsAtFacilityArgs = {
  filter?: InputMaybe<PersonAtFacilityFilter>;
};

export type QueryFeeScheduleArgs = {
  id: Scalars['ID'];
};

export type QueryFeeScheduleV2Args = {
  id: Scalars['ID'];
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryFileUploadArgs = {
  id: Scalars['ID'];
};

export type QueryFileUploadQueryArgs = {
  input: FileUploadQueryInput;
};

export type QueryGetInvoiceExceptionDetailsArgs = {
  invoiceID: Scalars['ID'];
};

export type QueryGetNetworkSpecialtiesArgs = {
  id: Scalars['ID'];
};

export type QueryGetNetworkSpecialtiesRealTimeArgs = {
  id: Scalars['ID'];
};

export type QueryGetProviderGroupsForRcmArgs = {
  options?: InputMaybe<Options>;
};

export type QueryGetRcmUserBankAccountsArgs = {
  RCMInput?: InputMaybe<GetRcmUserBankAccountsInput>;
};

export type QueryGetRemittanceGroupsForRcmArgs = {
  filter?: InputMaybe<GetRemittanceGroupsForRcmInput>;
  options?: InputMaybe<Options>;
};

export type QueryGetS3PresignedGetUrlArgs = {
  file_object_ids: Array<Scalars['ID']>;
};

export type QueryGetS3PresignedGetUrlOneArgs = {
  file_object_id: Scalars['ID'];
};

export type QueryInvoiceByBillIdArgs = {
  id: Scalars['ID'];
};

export type QueryInvoiceByClaimIdArgs = {
  claimId: Scalars['String'];
};

export type QueryInvoiceByIdArgs = {
  id: Scalars['ID'];
};

export type QueryInvoiceByPatientNameArgs = {
  options?: InputMaybe<Options>;
  patientName: Scalars['String'];
};

export type QueryInvoicesArgs = {
  filter: InvoicesFilter;
  options?: InputMaybe<Options>;
};

export type QueryInvoicesForRcmArgs = {
  filter: InvoicesFilter;
  options?: InputMaybe<Options>;
};

export type QueryMemberArgs = {
  id: Scalars['ID'];
};

export type QueryMemberByFieldsArgs = {
  member?: InputMaybe<MemberInput>;
};

export type QueryMemberCardUrlArgs = {
  id: Scalars['ID'];
};

export type QueryMemberQueryArgs = {
  partyFilter: QueryByNamesAndDobInput;
};

export type QueryPartyArgs = {
  id: Scalars['ID'];
};

export type QueryPartyQueryArgs = {
  partyFilter?: InputMaybe<QueryByNamesAndDobInput>;
};

export type QueryPartyQueryByGovernmentIdArgs = {
  governmentIdFilter?: InputMaybe<QueryByGovernmentIdInput>;
};

export type QueryPaymentClientQueryArgs = {
  clientType?: InputMaybe<PaymentClientType>;
  options?: Options;
  orgNameFilter: Scalars['String'];
};

export type QueryPaymentDocumentationArgs = {
  id: Scalars['ID'];
};

export type QueryPlatformArgs = {
  id: Scalars['ID'];
};

export type QueryPlatformByNameArgs = {
  name: Scalars['String'];
};

export type QueryPracticeFacilitiesByPartyIdsArgs = {
  options?: InputMaybe<Options>;
  party_ids?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPracticeFacilitiesInAreaArgs = {
  filter?: InputMaybe<PracticeFacilitiesFilter>;
  options?: InputMaybe<Options>;
};

export type QueryPracticeFacilitiesQueryArgs = {
  address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Options>;
  provider_id?: InputMaybe<Scalars['String']>;
};

export type QueryPracticeFacilityArgs = {
  id: Scalars['ID'];
};

export type QueryPracticeFacilityByFacilityIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderGroupByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderGroupByNpiArgs = {
  npi: Scalars['String'];
};

export type QueryProviderGroupByTinAndPinArgs = {
  input: TinAndPinInput;
};

export type QueryProviderGroupsByPartyIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  options?: InputMaybe<Options>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryProviderGroupsWithProviderArgs = {
  providerID: Scalars['ID'];
};

export type QueryProvidersQueryArgs = {
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Options>;
};

export type QueryQueryPaymentClientByIdArgs = {
  id: Scalars['ID'];
};

export type QueryQueryPaymentClientByPartyIdArgs = {
  id: Scalars['ID'];
};

export type QueryQueryReferenceCodesArgs = {
  codes?: InputMaybe<Array<InputMaybe<ReferenceCodeInput>>>;
};

export type QueryQuerySponsorByGroupIdArgs = {
  groupId: Scalars['String'];
};

export type QueryQuerySponsorByIdArgs = {
  id: Scalars['ID'];
};

export type QueryQuerySponsorByInternalOrExternalArgs = {
  input: GetBySponsorTypeInput;
  options?: InputMaybe<Options>;
};

export type QueryRemittanceGroupByIdArgs = {
  id: Scalars['ID'];
};

export type QueryRoleMembershipByFieldsArgs = {
  filters: RoleMembershipInput;
  options?: Options;
};

export type QuerySearchPracticeFacilitiesWithFilterArgs = {
  filter: ProvidersSearchFilter;
  options?: Options;
};

export type QuerySearchProvidersWithFilterArgs = {
  filter: ProvidersSearchFilter;
  options?: Options;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  options?: InputMaybe<Options>;
  users?: InputMaybe<Array<InputMaybe<CreateUserInput>>>;
};

export type QueryVirtualCardByRemittanceGroupIdArgs = {
  remittance_group_id: Scalars['ID'];
};

export type QueryVirtualCardPdfArgs = {
  input: VirtualCardPdfInput;
};

export type QueryByGovernmentIdInput = {
  id_number: Scalars['String'];
  id_type: GovernmentIdType;
};

export type QueryByNamesAndDobInput = {
  person: UnifiedNamesPersonInput;
};

export type RcmBankAccountInfo = {
  __typename?: 'RCMBankAccountInfo';
  account_id: Scalars['String'];
  account_number_last_four: Scalars['String'];
  account_type: Scalars['String'];
  bank_name: Scalars['String'];
  party?: Maybe<Party>;
  party_id: Scalars['String'];
  status: Scalars['String'];
};

export type RcmOrganization = {
  __typename?: 'RCMOrganization';
  party?: Maybe<Party>;
  party_id: Scalars['String'];
};

export type RcmUser = {
  __typename?: 'RCMUser';
  organizations?: Maybe<Array<RcmOrganization>>;
  party: Party;
  party_id: Scalars['String'];
  user: User;
  user_id: Scalars['String'];
};

export enum RateType {
  RtBundledRate = 'RT_BUNDLED_RATE',
  RtCaseRate = 'RT_CASE_RATE',
  RtFeeForService = 'RT_FEE_FOR_SERVICE',
  RtPercentage = 'RT_PERCENTAGE',
  RtPerDiem = 'RT_PER_DIEM',
  RtPerUnit = 'RT_PER_UNIT',
  RtPerVisit = 'RT_PER_VISIT',
  RtUnknown = 'RT_UNKNOWN',
}

export type RawClaim = IDocument & {
  __typename?: 'RawClaim';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  claim_source: ClaimSource;
  edi837i_claim?: Maybe<Edi837I>;
  edi837p_claim?: Maybe<Edi837P>;
  encounter_claim?: Maybe<EncounterClaim>;
  file_upload?: Maybe<FileUpload>;
  file_upload_id?: Maybe<Scalars['String']>;
  network?: Maybe<Network>;
  network_id?: Maybe<Scalars['String']>;
  spec?: Maybe<Specification>;
};

export type RawClaimInput = {
  claim_source: ClaimSource;
  edi837i_claim?: InputMaybe<Edi837IInput>;
  edi837p_claim?: InputMaybe<Edi837PInput>;
  encounter_claim?: InputMaybe<EncounterClaimInput>;
  file_upload_id?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<SpecificationInput>;
};

export type RawRemittance = IDocument & {
  __typename?: 'RawRemittance';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  edi_835_remittance?: Maybe<Edi835>;
  file_upload?: Maybe<FileUpload>;
  file_upload_id?: Maybe<Scalars['String']>;
  source?: Maybe<RemittanceSource>;
  spec?: Maybe<Specification>;
};

export type RawRemittanceInput = {
  edi835_input?: InputMaybe<Edi835Input>;
  file_upload_id: Scalars['ID'];
  source: RemittanceSource;
  spec?: InputMaybe<SpecificationInput>;
};

export type Reason = {
  __typename?: 'Reason';
  other: Scalars['String'];
  reasons?: Maybe<Array<ReimbursementReason>>;
};

export type ReasonOtherArgs = {
  detokenize: Scalars['Boolean'];
};

export type Receipt = {
  __typename?: 'Receipt';
  content_type: Scalars['String'];
  file_name: Scalars['String'];
  key: Scalars['String'];
  status: ReceiptStatus;
};

export enum ReceiptStatus {
  SActive = 'S_ACTIVE',
  SRejected = 'S_REJECTED',
}

export type ReceiverName = {
  __typename?: 'ReceiverName';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type ReceiverName837I = {
  __typename?: 'ReceiverName837I';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type ReceiverName837IInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type ReceiverNameInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type Reference = {
  __typename?: 'Reference';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  id_qualifier?: Maybe<X12Code>;
  other_codes?: Maybe<CompositeReferenceCode>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ReferenceIdArgs = {
  detokenize?: Scalars['Boolean'];
};

export type ReferenceCode = IDocument & {
  __typename?: 'ReferenceCode';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  code: Scalars['String'];
  codeset_version: Scalars['String'];
  cpt_code_meta_data?: Maybe<CptCodeMetadata>;
  description: Scalars['String'];
  medical_code?: Maybe<MedicalCode>;
  ordered_ranking?: Maybe<Scalars['Int']>;
  provider_speciality_taxonomy_code?: Maybe<TaxonomyCode>;
  reference_code_type: ReferenceCodeType;
  spec?: Maybe<Specification>;
};

export type ReferenceCodeInput = {
  _id?: InputMaybe<Scalars['ID']>;
  code: Scalars['String'];
  codeset_version?: InputMaybe<Scalars['String']>;
  cpt_code_meta_data?: InputMaybe<CptCodeMetadataInput>;
  description?: InputMaybe<Scalars['String']>;
  medical_code?: InputMaybe<MedicalCodeInput>;
  provider_speciality_taxonomy_code?: InputMaybe<TaxonomyCodeInput>;
  reference_code_type: ReferenceCodeType;
  spec?: InputMaybe<SpecificationInput>;
};

export type ReferenceCodeOrCodeGroup = MedicalCodeGroup | ReferenceCode;

export enum ReferenceCodeType {
  CodeTypeUnknown = 'CODE_TYPE_UNKNOWN',
  MedicalCode = 'MEDICAL_CODE',
  PlaceOfService = 'PLACE_OF_SERVICE',
  ProviderSpecialtyTaxonomy = 'PROVIDER_SPECIALTY_TAXONOMY',
}

export enum ReferenceFeeScheduleType {
  RfstAsaAnesthesia = 'RFST_ASA_ANESTHESIA',
  RfstAsp = 'RFST_ASP',
  RfstClinicalLabFs = 'RFST_CLINICAL_LAB_FS',
  RfstDmePos = 'RFST_DME_POS',
  RfstNomiGap = 'RFST_NOMI_GAP',
  RfstNomiVacs = 'RFST_NOMI_VACS',
  RfstOther = 'RFST_OTHER',
  RfstPen = 'RFST_PEN',
  RfstRbrvs = 'RFST_RBRVS',
  RfstUnknown = 'RFST_UNKNOWN',
  RsftFeeForService = 'RSFT_FEE_FOR_SERVICE',
}

export type ReferenceInSegment = {
  __typename?: 'ReferenceInSegment';
  data_element_ref_number?: Maybe<Uint32Wrapper>;
  data_element_ref_number2?: Maybe<Uint32Wrapper>;
  element_metadata?: Maybe<X12Metadata>;
};

export type ReferenceInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_qualifier?: InputMaybe<X12CodeInput>;
  other_codes?: InputMaybe<CompositeReferenceCodeInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ReferringProvider = {
  __typename?: 'ReferringProvider';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type ReferringProviderInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type Reimbursement = {
  __typename?: 'Reimbursement';
  reason: Reason;
  reimbursement_amount_approved: Scalars['Int'];
  reimbursement_amount_requested: Scalars['Int'];
};

export enum ReimbursementReason {
  RCardReaderDown = 'R_CARD_READER_DOWN',
  RForgotUseCard = 'R_FORGOT_USE_CARD',
  RInvalidCardDetailsDataEntry = 'R_INVALID_CARD_DETAILS_DATA_ENTRY',
  RWalletPayNotAccepted = 'R_WALLET_PAY_NOT_ACCEPTED',
}

export type RelatedCausesInfo = {
  __typename?: 'RelatedCausesInfo';
  country?: Maybe<Scalars['String']>;
  element_metadata?: Maybe<X12Metadata>;
  related_causes_code?: Maybe<X12Code>;
  related_causes_code_2?: Maybe<X12Code>;
  related_causes_code_3?: Maybe<X12Code>;
  state?: Maybe<Scalars['String']>;
};

export type RelatedCausesInfoInput = {
  country?: InputMaybe<Scalars['String']>;
  element_metadata?: InputMaybe<X12MetadataInput>;
  related_causes_code?: InputMaybe<X12CodeInput>;
  related_causes_code_2?: InputMaybe<X12CodeInput>;
  related_causes_code_3?: InputMaybe<X12CodeInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type Remittance = IDocument & {
  __typename?: 'Remittance';
  _id: Scalars['ID'];
  amount: Scalars['Float'];
  amount_pennies: Scalars['Int'];
  audit?: Maybe<Audit>;
  audit_logs?: Maybe<Array<RemittanceAuditLog>>;
  bill_reference_id: Scalars['ID'];
  bill_reference_type: BillReferenceType;
  due_date: Scalars['String'];
  fee_type?: Maybe<PaymentsFeeType>;
  from_ledger_account: Scalars['ID'];
  from_payer_id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  minimum_prefunded_amount?: Maybe<Scalars['Int']>;
  remittance_group?: Maybe<RemittanceGroup>;
  remittance_state: RemittanceState;
  service_fee_amount?: Maybe<Scalars['Float']>;
  service_fee_amount_pennies?: Maybe<Scalars['Int']>;
  statement_descriptor: Scalars['String'];
  to_ledger_account: Scalars['ID'];
  to_payee_id: Scalars['ID'];
};

export type RemittanceAuditLog = {
  __typename?: 'RemittanceAuditLog';
  remittance_state_transition: RemittanceGroupStateTransition;
  transitioned_at_time: Scalars['String'];
};

export enum RemittanceCreationPathType {
  AdminTransactionPath = 'ADMIN_TRANSACTION_PATH',
  BillingPath = 'BILLING_PATH',
  ClawbackPath = 'CLAWBACK_PATH',
  InvoiceBillPath = 'INVOICE_BILL_PATH',
  PayBillPath = 'PAY_BILL_PATH',
  ReimbursementPath = 'REIMBURSEMENT_PATH',
  UnknownRemittanceCreationPath = 'UNKNOWN_REMITTANCE_CREATION_PATH',
}

export type RemittanceDeliveryMethod = {
  __typename?: 'RemittanceDeliveryMethod';
  communication_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference_identifier_1?: Maybe<Scalars['String']>;
  reference_identifier_2?: Maybe<Scalars['String']>;
  report_transmission_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type RemittanceDeliveryMethodInput = {
  communication_number?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reference_identifier_1?: InputMaybe<Scalars['String']>;
  reference_identifier_2?: InputMaybe<Scalars['String']>;
  report_transmission_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type RemittanceGroup = IDocument & {
  __typename?: 'RemittanceGroup';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  audit_logs?: Maybe<Array<RemittanceGroupAuditLog>>;
  bookkeeping_entry_set_ids?: Maybe<Array<Scalars['ID']>>;
  fee_type?: Maybe<PaymentsFeeType>;
  from_account: Scalars['ID'];
  from_payer_id: Scalars['ID'];
  from_time: Scalars['String'];
  is_internal?: Maybe<Scalars['Boolean']>;
  minimum_prefunded_amount?: Maybe<Scalars['Int']>;
  net_amount_pennies?: Maybe<Scalars['Int']>;
  nomi_fee_account_transfer_id: Scalars['String'];
  payee?: Maybe<Party>;
  payer?: Maybe<Party>;
  payment_transaction_reference_id: Scalars['ID'];
  payment_vehicle: PaymentVehicle;
  remittance_group_state: RemittanceGroupState;
  remittance_ids: Array<Scalars['ID']>;
  remittances?: Maybe<Array<Remittance>>;
  service_fee_amount: Scalars['Float'];
  service_fee_amount_pennies?: Maybe<Scalars['Int']>;
  spec?: Maybe<Specification>;
  statement_descriptor: Scalars['String'];
  submitted_at_time?: Maybe<Scalars['String']>;
  successful_at_time?: Maybe<Scalars['String']>;
  to_account: Scalars['ID'];
  to_payee_id: Scalars['ID'];
  to_time: Scalars['String'];
  total_amount: Scalars['Float'];
  total_amount_pennies: Scalars['Int'];
  trace_number: Scalars['String'];
  virtual_card_id: Scalars['String'];
};

export type RemittanceGroupAuditLog = {
  __typename?: 'RemittanceGroupAuditLog';
  remittance_group_state_transition?: Maybe<RemittanceGroupStateTransition>;
  transitioned_at_time?: Maybe<Scalars['String']>;
};

export enum RemittanceGroupInTransitionName {
  RgAlertPaymentVehicleNotFound = 'RG_ALERT_PAYMENT_VEHICLE_NOT_FOUND',
  RgBookkeepVirtualTransfer = 'RG_BOOKKEEP_VIRTUAL_TRANSFER',
  RgBookkeepVirtualWithdraw = 'RG_BOOKKEEP_VIRTUAL_WITHDRAW',
  RgBookkeepWithdrawal = 'RG_BOOKKEEP_WITHDRAWAL',
  RgCancelPayment = 'RG_CANCEL_PAYMENT',
  RgCancelVirtualCard = 'RG_CANCEL_VIRTUAL_CARD',
  RgCompletePayment = 'RG_COMPLETE_PAYMENT',
  RgFailFunding = 'RG_FAIL_FUNDING',
  RgFailPayment = 'RG_FAIL_PAYMENT',
  RgFailRemittanceGroup = 'RG_FAIL_REMITTANCE_GROUP',
  RgInitiateFunding = 'RG_INITIATE_FUNDING',
  RgInitiateWithdraw = 'RG_INITIATE_WITHDRAW',
  RgIssueVirtualCard = 'RG_ISSUE_VIRTUAL_CARD',
  RgPerformAdminFeeTransfer = 'RG_PERFORM_ADMIN_FEE_TRANSFER',
  RgPerformBookkeeping = 'RG_PERFORM_BOOKKEEPING',
  RgPerformFeeVirtualTransfer = 'RG_PERFORM_FEE_VIRTUAL_TRANSFER',
  RgPerformVirtualTransfer = 'RG_PERFORM_VIRTUAL_TRANSFER',
  RgPrepareForCardPaymentSettlement = 'RG_PREPARE_FOR_CARD_PAYMENT_SETTLEMENT',
  RgPrepareForFunding = 'RG_PREPARE_FOR_FUNDING',
  RgPrepareForPayment = 'RG_PREPARE_FOR_PAYMENT',
  RgPrepareForSettle = 'RG_PREPARE_FOR_SETTLE',
  RgPrepareForSubmit = 'RG_PREPARE_FOR_SUBMIT',
  RgResetCardPaymentSettlement = 'RG_RESET_CARD_PAYMENT_SETTLEMENT',
  RgRgTransitionFailed = 'RG_RG_TRANSITION_FAILED',
  RgSendForManualReview = 'RG_SEND_FOR_MANUAL_REVIEW',
  RgSendPaymentCommunications = 'RG_SEND_PAYMENT_COMMUNICATIONS',
  RgSettleCardPayment = 'RG_SETTLE_CARD_PAYMENT',
  RgUnknownRemittanceGroupTransitionName = 'RG_UNKNOWN_REMITTANCE_GROUP_TRANSITION_NAME',
}

export type RemittanceGroupMetadata = {
  __typename?: 'RemittanceGroupMetadata';
  admin_fee_pennies: Scalars['Int'];
  payment_transaction_reference_id: Scalars['String'];
  submitted_at_time: Scalars['String'];
  successful_at_time: Scalars['String'];
};

export enum RemittanceGroupState {
  RgAdminFeeTransferCompleted = 'RG_ADMIN_FEE_TRANSFER_COMPLETED',
  RgBookkeepingCompleted = 'RG_BOOKKEEPING_COMPLETED',
  RgCreated = 'RG_CREATED',
  RgFailed = 'RG_FAILED',
  RgFeeVirtualTransferCompleted = 'RG_FEE_VIRTUAL_TRANSFER_COMPLETED',
  RgFunded = 'RG_FUNDED',
  RgFunding = 'RG_FUNDING',
  RgFundingFailed = 'RG_FUNDING_FAILED',
  RgFundingInitiated = 'RG_FUNDING_INITIATED',
  RgInsufficientFunds = 'RG_INSUFFICIENT_FUNDS',
  RgManualReview = 'RG_MANUAL_REVIEW',
  RgPaymentCancelled = 'RG_PAYMENT_CANCELLED',
  RgPaymentCommunicationSent = 'RG_PAYMENT_COMMUNICATION_SENT',
  RgPaymentFailed = 'RG_PAYMENT_FAILED',
  RgPaymentInitiated = 'RG_PAYMENT_INITIATED',
  RgPaymentPending = 'RG_PAYMENT_PENDING',
  RgPaymentSettled = 'RG_PAYMENT_SETTLED',
  RgPaymentSubmitted = 'RG_PAYMENT_SUBMITTED',
  RgPaymentSucceeded = 'RG_PAYMENT_SUCCEEDED',
  RgPaymentVehicleNotFound = 'RG_PAYMENT_VEHICLE_NOT_FOUND',
  RgReadyForFunding = 'RG_READY_FOR_FUNDING',
  RgReadyForPayment = 'RG_READY_FOR_PAYMENT',
  RgUnknown = 'RG_UNKNOWN',
  RgVirtualCardCanceled = 'RG_VIRTUAL_CARD_CANCELED',
  RgVirtualCardIssued = 'RG_VIRTUAL_CARD_ISSUED',
  RgVirtualCardPaymentSettlementPending = 'RG_VIRTUAL_CARD_PAYMENT_SETTLEMENT_PENDING',
  RgVirtualTransferBookkept = 'RG_VIRTUAL_TRANSFER_BOOKKEPT',
  RgVirtualTransferCompleted = 'RG_VIRTUAL_TRANSFER_COMPLETED',
  RgWithdrawalBookkept = 'RG_WITHDRAWAL_BOOKKEPT',
  RgWithdrawTransferBookkept = 'RG_WITHDRAW_TRANSFER_BOOKKEPT',
}

export type RemittanceGroupStateTransition = {
  __typename?: 'RemittanceGroupStateTransition';
  from_state?: Maybe<RemittanceGroupState>;
  to_state?: Maybe<RemittanceGroupState>;
};

export type RemittanceGroups = PaginatableResults & {
  __typename?: 'RemittanceGroups';
  offset: Scalars['Int'];
  results: Array<RemittanceGroup>;
  total: Scalars['Int'];
};

export type RemittanceHeader = {
  __typename?: 'RemittanceHeader';
  account_number?: Maybe<Scalars['String']>;
  account_number_qualifier_1?: Maybe<Scalars['String']>;
  account_number_qualifier_2?: Maybe<Scalars['String']>;
  account_number_qualifier_3?: Maybe<Scalars['String']>;
  business_function_code?: Maybe<Scalars['String']>;
  check_issue_date?: Maybe<Scalars['String']>;
  credit_debit_flag?: Maybe<Scalars['String']>;
  dfi_id_number?: Maybe<Scalars['String']>;
  dfi_id_qualifier_1?: Maybe<Scalars['String']>;
  dfi_id_qualifier_2?: Maybe<Scalars['String']>;
  dfi_id_qualifier_3?: Maybe<Scalars['String']>;
  monetary_amount?: Maybe<Scalars['String']>;
  originating_company_identifier?: Maybe<Scalars['String']>;
  originating_company_supplemental_code?: Maybe<Scalars['String']>;
  payment_format_code?: Maybe<Scalars['String']>;
  payment_method_code?: Maybe<Scalars['String']>;
  receiver_account_number?: Maybe<Scalars['String']>;
  receiver_dfi_id?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  sender_account_number?: Maybe<Scalars['String']>;
  sender_dfi_id?: Maybe<Scalars['String']>;
  transaction_handle_code?: Maybe<Scalars['String']>;
};

export type RemittanceHeaderAccount_NumberArgs = {
  detokenize?: Scalars['Boolean'];
};

export type RemittanceHeaderReceiver_Account_NumberArgs = {
  detokenize?: Scalars['Boolean'];
};

export type RemittanceHeaderSender_Account_NumberArgs = {
  detokenize?: Scalars['Boolean'];
};

export type RemittanceHeaderInput = {
  account_number?: InputMaybe<Scalars['String']>;
  account_number_qualifier_1?: InputMaybe<Scalars['String']>;
  account_number_qualifier_2?: InputMaybe<Scalars['String']>;
  account_number_qualifier_3?: InputMaybe<Scalars['String']>;
  business_function_code?: InputMaybe<Scalars['String']>;
  check_issue_date?: InputMaybe<Scalars['String']>;
  credit_debit_flag?: InputMaybe<Scalars['String']>;
  dfi_id_number?: InputMaybe<Scalars['String']>;
  dfi_id_qualifier_1?: InputMaybe<Scalars['String']>;
  dfi_id_qualifier_2?: InputMaybe<Scalars['String']>;
  dfi_id_qualifier_3?: InputMaybe<Scalars['String']>;
  monetary_amount?: InputMaybe<Scalars['String']>;
  originating_company_identifier?: InputMaybe<Scalars['String']>;
  originating_company_supplemental_code?: InputMaybe<Scalars['String']>;
  payment_format_code?: InputMaybe<Scalars['String']>;
  payment_method_code?: InputMaybe<Scalars['String']>;
  receiver_account_number?: InputMaybe<Scalars['String']>;
  receiver_dfi_id?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  sender_account_number?: InputMaybe<Scalars['String']>;
  sender_dfi_id?: InputMaybe<Scalars['String']>;
  transaction_handle_code?: InputMaybe<Scalars['String']>;
};

export type RemittanceInformationLoop = {
  __typename?: 'RemittanceInformationLoop';
  claim_payment_info_loop?: Maybe<Array<Maybe<ClaimPaymentInfoLoop>>>;
  header_number?: Maybe<LineHeader>;
  loop_metadata?: Maybe<X12Metadata>;
  supplemental_transaction_statistics?: Maybe<TransactionSupplementalStatistics>;
  transaction_statistics?: Maybe<TransactionStatistics>;
};

export type RemittanceInformationLoopInput = {
  claim_payment_info_loop?: InputMaybe<Array<InputMaybe<ClaimPaymentInfoLoopInput>>>;
  header_number?: InputMaybe<LineHeaderInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  supplemental_transaction_statistics?: InputMaybe<TransactionSupplementalStatisticsInput>;
  transaction_statistics?: InputMaybe<TransactionStatisticsInput>;
};

export type RemittanceProcessingInfo = {
  __typename?: 'RemittanceProcessingInfo';
  edi_file_summary?: Maybe<Edi835FileSummary>;
  invoice_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_of_claims?: Maybe<Scalars['Int']>;
  number_of_claims_accepted?: Maybe<Scalars['Int']>;
  number_of_claims_rejected?: Maybe<Scalars['Int']>;
  remittance_group_id?: Maybe<Scalars['String']>;
  remittance_group_parent_file_upload_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  remittance_id?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['String']>;
  total_amount_pennies?: Maybe<Scalars['Int']>;
  total_value_of_accepted_claims?: Maybe<Scalars['String']>;
  total_value_of_rejected_claims?: Maybe<Scalars['String']>;
};

export type RemittanceProcessingInfoInput = {
  edi_file_summary?: InputMaybe<Edi835FileSummaryInput>;
  invoice_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  number_of_claims?: InputMaybe<Scalars['Int']>;
  number_of_claims_accepted?: InputMaybe<Scalars['Int']>;
  number_of_claims_rejected?: InputMaybe<Scalars['Int']>;
  remittance_group_id?: InputMaybe<Scalars['String']>;
  remittance_group_parent_file_upload_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  remittance_id?: InputMaybe<Scalars['String']>;
  total_amount?: InputMaybe<Scalars['String']>;
  total_amount_pennies?: InputMaybe<Scalars['Int']>;
  total_value_of_accepted_claims?: InputMaybe<Scalars['String']>;
  total_value_of_rejected_claims?: InputMaybe<Scalars['String']>;
};

export enum RemittanceSource {
  RsTpa = 'RS_TPA',
  RsUnknown = 'RS_UNKNOWN',
}

export enum RemittanceState {
  RAddedToGroup = 'R_ADDED_TO_GROUP',
  RCreated = 'R_CREATED',
  RFunded = 'R_FUNDED',
  RFundingFailed = 'R_FUNDING_FAILED',
  RFundingInitiated = 'R_FUNDING_INITIATED',
  RPaymentFailed = 'R_PAYMENT_FAILED',
  RPaymentInitiated = 'R_PAYMENT_INITIATED',
  RPaymentInProgress = 'R_PAYMENT_IN_PROGRESS',
  RPaymentPending = 'R_PAYMENT_PENDING',
  RPaymentSubmitted = 'R_PAYMENT_SUBMITTED',
  RPaymentSucceeded = 'R_PAYMENT_SUCCEEDED',
  RReadyForFunding = 'R_READY_FOR_FUNDING',
  RReadyForPayment = 'R_READY_FOR_PAYMENT',
  RRemittanceFailTransactionMonitoring = 'R_REMITTANCE_FAIL_TRANSACTION_MONITORING',
  RRemittanceHoldTransactionMonitoring = 'R_REMITTANCE_HOLD_TRANSACTION_MONITORING',
  RRemittancePassTransactionMonitoring = 'R_REMITTANCE_PASS_TRANSACTION_MONITORING',
  RRemittancePaymentVehicleNotFound = 'R_REMITTANCE_PAYMENT_VEHICLE_NOT_FOUND',
  RUnknown = 'R_UNKNOWN',
  RVirtualTransferCompleted = 'R_VIRTUAL_TRANSFER_COMPLETED',
}

export type RemittanceStateTransition = {
  __typename?: 'RemittanceStateTransition';
  from_state: RemittanceState;
  to_state: RemittanceState;
};

export type RemoveBankAccountRcmInput = {
  account_id: Scalars['String'];
  account_status: Scalars['String'];
  party_id: Scalars['String'];
};

export type RenderingProvider = {
  __typename?: 'RenderingProvider';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  provider_info?: Maybe<ProviderInfo>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type RenderingProviderInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  provider_info?: InputMaybe<ProviderInfoInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type RepricingInfo = {
  __typename?: 'RepricingInfo';
  pricing_methodology?: Maybe<Scalars['String']>;
  reject_reason_code?: Maybe<Scalars['String']>;
  repriced_allowed_amount_pennies?: Maybe<Scalars['Int']>;
  repriced_lines?: Maybe<Array<Maybe<ServiceItemResolution>>>;
  repricing_organization_id?: Maybe<Scalars['String']>;
};

export type ResolvedInfo = {
  __typename?: 'ResolvedInfo';
  final_pricing?: Maybe<Pricing>;
};

export type Result = {
  __typename?: 'Result';
  error?: Maybe<Scalars['String']>;
  object?: Maybe<IDocument>;
};

export type ResumeToken = {
  __typename?: 'ResumeToken';
  _data?: Maybe<Scalars['String']>;
};

export type Role = IDocument & {
  __typename?: 'Role';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  entitlements?: Maybe<Array<Entitlement>>;
  name: Scalars['String'];
  role_ids?: Maybe<Array<Scalars['String']>>;
};

export type RoleMembership = IDocument & {
  __typename?: 'RoleMembership';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  member_user_ids?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  role_ids?: Maybe<Array<Scalars['ID']>>;
  roles?: Maybe<Array<Role>>;
  subject_ids?: Maybe<Array<Scalars['ID']>>;
};

export type RoleMembershipInput = {
  member_user_ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  role_ids?: InputMaybe<Array<Scalars['ID']>>;
  subject_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Roles = Paginatable & {
  __typename?: 'Roles';
  offset: Scalars['Int'];
  results?: Maybe<Array<Role>>;
  total: Scalars['Int'];
};

export enum RuleType {
  RtAdd = 'RT_ADD',
  RtDeduct = 'RT_DEDUCT',
  RtUnknown = 'RT_UNKNOWN',
}

export type S3PresignedGetUrlOutput = {
  __typename?: 'S3PresignedGetURLOutput';
  s3_presigned_get_url: Scalars['String'];
};

export type S3PresignedPutUrlFeeScheduleInput = {
  party_id?: InputMaybe<Scalars['ID']>;
  practice_facility_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  provider_group_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  replacing_corrupted_fs?: InputMaybe<Scalars['Boolean']>;
};

export type S3PresignedPutUrlInput = {
  contract_id?: InputMaybe<Scalars['String']>;
  contract_upload_type?: InputMaybe<ContractUploadType>;
  effective_dates?: InputMaybe<EffectiveDatesInput>;
  fee_schedule_input?: InputMaybe<S3PresignedPutUrlFeeScheduleInput>;
  file_extension_type: FileExtensionType;
  file_name?: InputMaybe<Scalars['String']>;
  file_upload_id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  network_id?: InputMaybe<Scalars['String']>;
  plan_code?: InputMaybe<Scalars['String']>;
  provider_group_id?: InputMaybe<Scalars['String']>;
  provider_roster_format_type?: InputMaybe<ProviderRosterFormatType>;
  remittance_group?: InputMaybe<Scalars['ID']>;
  sponsor_id?: InputMaybe<Scalars['String']>;
  upload_file_type: UploadFileType;
};

export type S3PresignedPutUrlOutput = {
  __typename?: 'S3PresignedPutURLOutput';
  file_upload_id?: Maybe<Scalars['String']>;
  s3_presigned_put_url?: Maybe<Scalars['String']>;
};

export enum SecondaryClientType {
  SctSponsor = 'SCT_SPONSOR',
  SctUnknown = 'SCT_UNKNOWN',
}

export type SecondaryPaymentClientInfo = {
  __typename?: 'SecondaryPaymentClientInfo';
  client_type?: Maybe<SecondaryClientType>;
  primary_payment_client?: Maybe<PaymentClient>;
  primary_payment_client_id: Scalars['String'];
};

export type SecondaryPaymentClientInfoInput = {
  client_type?: InputMaybe<SecondaryClientType>;
  primary_payment_client_id: Scalars['ID'];
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  messageId?: Maybe<Scalars['String']>;
};

export type ServiceDate = {
  from_date: Scalars['String'];
  to_date: Scalars['String'];
};

export type ServiceDescription = {
  __typename?: 'ServiceDescription';
  cpt_codes?: Maybe<Array<Scalars['String']>>;
  option_name: Scalars['String'];
  options?: Maybe<Array<Option>>;
  service_description: Scalars['String'];
};

export type ServiceFacilityLocation = {
  __typename?: 'ServiceFacilityLocation';
  address?: Maybe<PhysicalAddress>;
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type ServiceFacilityLocation837I = {
  __typename?: 'ServiceFacilityLocation837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type ServiceFacilityLocation837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type ServiceFacilityLocationInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type ServiceItemResolution = {
  __typename?: 'ServiceItemResolution';
  decision_trail?: Maybe<Array<Maybe<PricingStep>>>;
  line_item_allowed_amount_pennies?: Maybe<Scalars['Int']>;
  line_number?: Maybe<Scalars['Int']>;
  pricing_methodology_code?: Maybe<Scalars['String']>;
  pricing_rule_id?: Maybe<Scalars['String']>;
};

export type ServiceLine = {
  __typename?: 'ServiceLine';
  charge_pennies?: Maybe<Scalars['Int']>;
  charged_code?: Maybe<CodedMedicalService>;
  diagnosis_code_pointer?: Maybe<Array<Maybe<Scalars['Int']>>>;
  diagnosis_codes?: Maybe<Array<CodedMedicalService>>;
  from_date?: Maybe<Scalars['String']>;
  line_allowed_amount_pennies?: Maybe<Scalars['Int']>;
  line_number?: Maybe<Scalars['Int']>;
  long_tw_desc: Scalars['String'];
  place_of_service_code: PlaceOfService;
  quantity?: Maybe<Scalars['Int']>;
  raw_charged_code: Scalars['String'];
  rendering_provider?: Maybe<Provider>;
  rendering_provider_id: Scalars['ID'];
  revenue_code?: Maybe<Scalars['String']>;
  service_type: Scalars['String'];
  to_date?: Maybe<Scalars['String']>;
};

export type ServiceLineInformation = {
  __typename?: 'ServiceLineInformation';
  composite_medical_procedure_identifier_1?: Maybe<CompositeMedicalProcedure>;
  composite_medical_procedure_identifier_2?: Maybe<CompositeMedicalProcedure>;
  line_item_charge_amount?: Maybe<FloatWrapper>;
  monetary_amount?: Maybe<Scalars['String']>;
  original_units_service_count?: Maybe<Scalars['String']>;
  quantity_1?: Maybe<Scalars['String']>;
  revenue_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type ServiceLineInformationInput = {
  composite_medical_procedure_identifier_1?: InputMaybe<CompositeMedicalProcedureInput>;
  composite_medical_procedure_identifier_2?: InputMaybe<CompositeMedicalProcedureInput>;
  line_item_charge_amount?: InputMaybe<FloatWrapperInput>;
  monetary_amount?: InputMaybe<Scalars['String']>;
  original_units_service_count?: InputMaybe<Scalars['String']>;
  quantity_1?: InputMaybe<Scalars['String']>;
  revenue_code?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type ServiceLineInput = {
  billed_charges_pennies: Scalars['Int'];
  cpt_code: Scalars['String'];
  date_of_service: Scalars['String'];
  diagnosis_codes?: InputMaybe<Array<Scalars['String']>>;
  modifier_codes?: InputMaybe<Array<Scalars['String']>>;
  place_of_service_code?: InputMaybe<PlaceOfService>;
  units: Scalars['Int'];
};

export type ServiceLinePricesInput = {
  nomi_price_pennies: Scalars['Int'];
  pricing_methodology_code?: InputMaybe<Scalars['String']>;
};

export type ServicePaymentInfoLoop = {
  __typename?: 'ServicePaymentInfoLoop';
  amounts?: Maybe<Array<Maybe<Amount>>>;
  claim_adjustments?: Maybe<Array<Maybe<LineAdjustment>>>;
  date_times?: Maybe<Array<Maybe<DateTimeReference>>>;
  industry_codes?: Maybe<Array<Maybe<IndustryCode>>>;
  loop_metadata?: Maybe<X12Metadata>;
  qty?: Maybe<Array<Maybe<Quantity>>>;
  references?: Maybe<Array<Maybe<Reference>>>;
  svc_payment_info?: Maybe<ServiceLineInformation>;
};

export type ServicePaymentInfoLoopInput = {
  amounts?: InputMaybe<Array<InputMaybe<AmountInput>>>;
  claim_adjustments?: InputMaybe<Array<InputMaybe<LineAdjustmentInput>>>;
  date_times?: InputMaybe<Array<InputMaybe<DateTimeReferenceInput>>>;
  industry_codes?: InputMaybe<Array<InputMaybe<IndustryCodeInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  qty?: InputMaybe<Array<InputMaybe<QuantityInput>>>;
  references?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  svc_payment_info?: InputMaybe<ServiceLineInformationInput>;
};

export enum ServiceType {
  StExcludedServices = 'ST_EXCLUDED_SERVICES',
  StIncorrectInsuranceType = 'ST_INCORRECT_INSURANCE_TYPE',
  StMisuseOfFunds = 'ST_MISUSE_OF_FUNDS',
  StNonPreventative = 'ST_NON_PREVENTATIVE',
  StPreventative = 'ST_PREVENTATIVE',
  StPreventativeAndNonPreventative = 'ST_PREVENTATIVE_AND_NON_PREVENTATIVE',
}

export type SetRecipientPaymentPreferenceInput = {
  party_id: Scalars['ID'];
  payment_vehicle: PaymentVehicle;
};

export type SetupError = {
  __typename?: 'SetupError';
  code?: Maybe<Scalars['String']>;
  docUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  type?: Maybe<Scalars['String']>;
};

export type SetupIntent = {
  __typename?: 'SetupIntent';
  application?: Maybe<Scalars['String']>;
  automaticPaymentMethods?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  clientSecret: Scalars['String'];
  created: Scalars['Int'];
  customer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flowDirections?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastSetupError?: Maybe<SetupError>;
  latestAttempt?: Maybe<Scalars['String']>;
  liveMode: Scalars['Boolean'];
  mandate?: Maybe<Scalars['String']>;
  nextAction?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  onBehalfOf?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodOptions?: Maybe<PaymentMethodOptions>;
  paymentMethodTypes: Array<Scalars['String']>;
  redaction?: Maybe<Scalars['String']>;
  singleUseMandate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  usage: Scalars['String'];
};

export type Sint32Wrapper = {
  __typename?: 'Sint32Wrapper';
  value?: Maybe<Scalars['Int']>;
};

export type SocialMediaHandle = {
  __typename?: 'SocialMediaHandle';
  handle: Scalars['String'];
  social_media_type: Scalars['String'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortOption = {
  direction: SortDirection;
  field: Scalars['String'];
  ignore_case?: InputMaybe<Scalars['Boolean']>;
};

export enum Source {
  Increase = 'INCREASE',
  UnknownSource = 'UNKNOWN_SOURCE',
}

export enum SourceType {
  Automated = 'AUTOMATED',
  Hybrid = 'HYBRID',
  Manual = 'MANUAL',
  SourceTypeUnknown = 'SOURCE_TYPE_UNKNOWN',
}

export type SpecialInstruction = {
  __typename?: 'SpecialInstruction';
  note_reference_code?: Maybe<X12Code>;
  note_text?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type SpecialInstructionInput = {
  note_reference_code?: InputMaybe<X12CodeInput>;
  note_text?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export type SpecialtyTaxonomyCodes = {
  __typename?: 'SpecialtyTaxonomyCodes';
  specialty?: Maybe<Scalars['String']>;
  taxonomy_codes?: Maybe<Array<Maybe<TaxonomyCodes>>>;
};

export type SpecialtyToTaxonomies = {
  __typename?: 'SpecialtyToTaxonomies';
  specialty?: Maybe<Scalars['String']>;
  taxonomies?: Maybe<Array<Scalars['String']>>;
};

export type Specification = {
  __typename?: 'Specification';
  description?: Maybe<Scalars['String']>;
  displayId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
};

export type SpecificationInput = {
  description?: InputMaybe<Scalars['String']>;
  displayId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ref?: InputMaybe<Scalars['String']>;
};

export type SpiceDbRelationships = IDocument & {
  __typename?: 'SpiceDBRelationships';
  _id: Scalars['ID'];
  active: Scalars['Boolean'];
  audit?: Maybe<Audit>;
  object: Scalars['String'];
  object_id: Scalars['String'];
  optional_relationship: Scalars['String'];
  relationship: Scalars['String'];
  subject: Scalars['String'];
  subject_id: Scalars['String'];
};

export type Sponsor = IDocument & {
  __typename?: 'Sponsor';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  lifecycle_state?: Maybe<SponsorLifecycleState>;
  party?: Maybe<Party>;
  party_id?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  type?: Maybe<SponsorType>;
};

export type SponsorContract = {
  __typename?: 'SponsorContract';
  admin_fee_percentage: Scalars['Float'];
  auto_funding_disabled: Scalars['Boolean'];
  party_id: Scalars['ID'];
  payment_client?: Maybe<PaymentClient>;
  primary_payment_client_id?: Maybe<Scalars['ID']>;
  sponsor?: Maybe<Sponsor>;
  sponsor_id: Scalars['ID'];
};

export type SponsorContractInput = {
  admin_fee_percentage?: InputMaybe<Scalars['Float']>;
  auto_funding_disabled?: InputMaybe<Scalars['Boolean']>;
  party_id?: InputMaybe<Scalars['ID']>;
  primary_payment_client_id?: InputMaybe<Scalars['ID']>;
  sponsor_id: Scalars['ID'];
};

export enum SponsorLifecycleState {
  Active = 'ACTIVE',
  Contracted = 'CONTRACTED',
  Engaging = 'ENGAGING',
  Identified = 'IDENTIFIED',
  Onboarded = 'ONBOARDED',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN',
}

export type SponsorPlanInput = {
  effectiveDates: EffectiveDatesInput;
  planCode: Scalars['String'];
};

export enum SponsorType {
  StExternal = 'ST_EXTERNAL',
  StInternal = 'ST_INTERNAL',
  StUnknown = 'ST_UNKNOWN',
}

export type SponsorsFilter = {
  as_of_date?: InputMaybe<Scalars['String']>;
  lifecycle_states?: InputMaybe<Array<SponsorLifecycleState>>;
  network_id?: InputMaybe<Scalars['String']>;
  sponsor_name?: InputMaybe<Scalars['String']>;
};

export type StateTransitionFilter = {
  invoice_from_state?: InputMaybe<InvoiceState>;
  invoice_to_state?: InputMaybe<InvoiceState>;
  invoice_transition_end_date?: InputMaybe<Scalars['String']>;
  invoice_transition_start_date?: InputMaybe<Scalars['String']>;
};

export enum Status {
  Complete = 'COMPLETE',
  Pending = 'PENDING',
  Waiting = 'WAITING',
}

export type StatusOutput = {
  __typename?: 'StatusOutput';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type StoplossResolution = {
  is_accepted?: InputMaybe<Scalars['Boolean']>;
};

export type StripeConfig = {
  __typename?: 'StripeConfig';
  public_key?: Maybe<Scalars['String']>;
};

/**
 * A PaymentIntent guides you through the process of collecting a payment from your customer.
 * We recommend that you create exactly one PaymentIntent for each order or
 * customer session in your system. You can reference the PaymentIntent later to
 * see the history of payment attempts for a particular session.
 *
 * A PaymentIntent transitions through
 * [multiple statuses](https://stripe.com/docs/payments/intents#intent-statuses)
 * throughout its lifetime as it interfaces with Stripe.js to perform
 * authentication flows and ultimately creates at most one successful charge.
 *
 * Related guide: [Payment Intents API](https://stripe.com/docs/payments/payment-intents).
 */
export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  /** Amount intended to be collected by this PaymentIntent. A positive integer representing how much to charge in the [smallest currency unit](https://stripe.com/docs/currencies#zero-decimal) (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). The minimum amount is $0.50 US or [equivalent in charge currency](https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts). The amount value supports up to eight digits (e.g., a value of 99999999 for a USD charge of $999,999.99). */
  amount: Scalars['Int'];
  /** Amount that can be captured from this PaymentIntent. */
  amountCapturable?: Maybe<Scalars['Int']>;
  amountDetails?: Maybe<PaymentFlowsAmountDetails>;
  /** Amount that was collected by this PaymentIntent. */
  amountReceived?: Maybe<Scalars['Int']>;
  /** The amount of the application fee (if any) that will be requested to be applied to the payment and transferred to the application owner's Stripe account. The amount of the application fee collected will be capped at the total payment amount. For more information, see the PaymentIntents [use case for connected accounts](https://stripe.com/docs/payments/connected-accounts). */
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  /** Populated when `status` is `canceled`, this is the time at which the PaymentIntent was canceled. Measured in seconds since the Unix epoch. */
  canceledAt?: Maybe<Scalars['Int']>;
  /**
   * The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key.
   *
   * The client secret can be used to complete a payment from your frontend. It should not be stored, logged, or exposed to anyone other than the customer. Make sure that you have TLS enabled on any page that includes the client secret.
   *
   * Refer to our docs to [accept a payment](https://stripe.com/docs/payments/accept-a-payment?ui=elements) and learn about how `client_secret` should be handled.
   */
  clientSecret?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  /** An arbitrary string attached to the object. Often useful for displaying to users. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** The list of payment method types (e.g. card) that this PaymentIntent is allowed to use. */
  paymentMethodTypes: Array<Maybe<Scalars['String']>>;
  /** Email address that the receipt for the resulting payment will be sent to. If `receipt_email` is specified for a payment in live mode, a receipt will be sent regardless of your [email settings](https://dashboard.stripe.com/account/emails). */
  receiptEmail?: Maybe<Scalars['String']>;
  /** For non-card charges, you can use this value as the complete description that appears on your customers’ statements. Must contain at least one letter, maximum 22 characters. */
  statementDescriptor?: Maybe<Scalars['String']>;
  /** Provides information about a card payment that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that’s set on the account to form the complete statement descriptor. Maximum 22 characters for the concatenated descriptor. */
  statementDescriptorSuffix?: Maybe<Scalars['String']>;
  /** Status of this PaymentIntent, one of `requires_payment_method`, `requires_confirmation`, `requires_action`, `processing`, `requires_capture`, `canceled`, or `succeeded`. Read more about each PaymentIntent [status](https://stripe.com/docs/payments/intents#intent-statuses). */
  status: PaymentIntentStatus;
  /** A string that identifies the resulting payment as part of a group. See the PaymentIntents [use case for connected accounts](https://stripe.com/docs/payments/connected-accounts) for details. */
  transferGroup?: Maybe<Scalars['String']>;
};

export type SubmitRemittanceInput = {
  amount_pennies?: InputMaybe<Scalars['Int']>;
  bill_id?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['String']>;
  external_account_id?: InputMaybe<Scalars['String']>;
  fee_type?: InputMaybe<FeeType>;
  invoice_id?: InputMaybe<Scalars['String']>;
  line_of_business?: InputMaybe<LineOfBusinessType>;
  member_id?: InputMaybe<Scalars['String']>;
  minimum_prefunded_amount?: InputMaybe<Scalars['Int']>;
  payee_account_id?: InputMaybe<Scalars['String']>;
  payee_id?: InputMaybe<Scalars['String']>;
  payee_identifier?: InputMaybe<Scalars['String']>;
  payee_npi?: InputMaybe<Scalars['String']>;
  payer_account_id?: InputMaybe<Scalars['String']>;
  payer_id?: InputMaybe<Scalars['String']>;
  payer_identifier?: InputMaybe<Scalars['String']>;
  payer_npi?: InputMaybe<Scalars['String']>;
  payment_communication_preference?: InputMaybe<Array<PaymentCommunicationPreferenceType>>;
  payment_vehicle?: InputMaybe<PaymentVehicleType>;
  plan_code?: InputMaybe<Scalars['String']>;
  remittance_creation_path: RemittanceCreationPathType;
  service_fees_pennies?: InputMaybe<Scalars['Int']>;
  statement_descriptor?: InputMaybe<Scalars['String']>;
};

export type SubmitterName = {
  __typename?: 'SubmitterName';
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type SubmitterName837I = {
  __typename?: 'SubmitterName837I';
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
};

export type SubmitterName837IInput = {
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type SubmitterNameInput = {
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  benefits_coordination_code?: Maybe<Scalars['String']>;
  claim_filing_type?: Maybe<X12Code>;
  client_relationship?: Maybe<X12Code>;
  employment_status?: Maybe<Scalars['String']>;
  insurance_type?: Maybe<X12Code>;
  payer_responsibility?: Maybe<X12Code>;
  plan_program?: Maybe<Scalars['String']>;
  policy_group_number?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  yes_no?: Maybe<X12Code>;
};

export type SubscriberInput = {
  benefits_coordination_code?: InputMaybe<Scalars['String']>;
  claim_filing_type?: InputMaybe<X12CodeInput>;
  client_relationship?: InputMaybe<X12CodeInput>;
  employment_status?: InputMaybe<Scalars['String']>;
  insurance_type?: InputMaybe<X12CodeInput>;
  payer_responsibility?: InputMaybe<X12CodeInput>;
  plan_program?: InputMaybe<Scalars['String']>;
  policy_group_number?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  yes_no?: InputMaybe<X12CodeInput>;
};

export type SubscriberLoop = {
  __typename?: 'SubscriberLoop';
  claims?: Maybe<Array<Maybe<ClaimInformation>>>;
  hierarchy?: Maybe<Hierarchy>;
  loop_metadata?: Maybe<X12Metadata>;
  patient?: Maybe<Patient>;
  payer_name?: Maybe<PayerName>;
  subscriber?: Maybe<Subscriber>;
  subscriber_name?: Maybe<SubscriberName>;
};

export type SubscriberLoop837I = {
  __typename?: 'SubscriberLoop837I';
  claims?: Maybe<Array<Maybe<ClaimInformation837I>>>;
  hierarchy?: Maybe<Hierarchy>;
  loop_metadata?: Maybe<X12Metadata>;
  payer_name?: Maybe<PayerName837I>;
  subscriber?: Maybe<Subscriber>;
  subscriber_name?: Maybe<SubscriberName837I>;
};

export type SubscriberLoop837IInput = {
  claims?: InputMaybe<Array<InputMaybe<ClaimInformation837IInput>>>;
  hierarchy?: InputMaybe<HierarchyInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  payer_name?: InputMaybe<PayerName837IInput>;
  subscriber?: InputMaybe<SubscriberInput>;
  subscriber_name?: InputMaybe<SubscriberName837IInput>;
};

export type SubscriberLoopInput = {
  claims?: InputMaybe<Array<InputMaybe<ClaimInformationInput>>>;
  hierarchy?: InputMaybe<HierarchyInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  patient?: InputMaybe<PatientInput>;
  payer_name?: InputMaybe<PayerNameInput>;
  subscriber?: InputMaybe<SubscriberInput>;
  subscriber_name?: InputMaybe<SubscriberNameInput>;
};

export type SubscriberName = {
  __typename?: 'SubscriberName';
  address?: Maybe<PhysicalAddress>;
  contact_info?: Maybe<Array<Maybe<ContactInfo>>>;
  csz?: Maybe<CityStateZip>;
  demographic?: Maybe<Demographic>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type SubscriberName837I = {
  __typename?: 'SubscriberName837I';
  address?: Maybe<PhysicalAddress>;
  csz?: Maybe<CityStateZip>;
  demographic?: Maybe<Demographic>;
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type SubscriberName837IInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  csz?: InputMaybe<CityStateZipInput>;
  demographic?: InputMaybe<DemographicInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type SubscriberNameInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  contact_info?: InputMaybe<Array<InputMaybe<ClaimContactInfoInput>>>;
  csz?: InputMaybe<CityStateZipInput>;
  demographic?: InputMaybe<DemographicInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type SupervisingProvider = {
  __typename?: 'SupervisingProvider';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type SupervisingProvider837I = {
  __typename?: 'SupervisingProvider837I';
  loop_metadata?: Maybe<X12Metadata>;
  name?: Maybe<Name>;
  reference?: Maybe<Array<Maybe<Reference>>>;
};

export type SupervisingProvider837IInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type SupervisingProviderInput = {
  loop_metadata?: InputMaybe<X12MetadataInput>;
  name?: InputMaybe<NameInput>;
  reference?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
};

export type SupportingDoc = {
  __typename?: 'SupportingDoc';
  date_response?: Maybe<Uint32Wrapper>;
  other_response?: Maybe<Scalars['String']>;
  percent_response?: Maybe<Scalars['String']>;
  question_number?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  yn_response?: Maybe<X12Code>;
};

export type SupportingDocInput = {
  date_response?: InputMaybe<Uint32WrapperInput>;
  other_response?: InputMaybe<Scalars['String']>;
  percent_response?: InputMaybe<Scalars['String']>;
  question_number?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  yn_response?: InputMaybe<X12CodeInput>;
};

export type Ta1 = {
  __typename?: 'TA1';
  acknowledgement_code?: Maybe<Scalars['String']>;
  interchange_control_number?: Maybe<Scalars['String']>;
  interchange_date?: Maybe<Scalars['String']>;
  interchange_time?: Maybe<Scalars['String']>;
  note_code?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type TinAndPinInput = {
  group_tin: Scalars['String'];
  pin: Scalars['String'];
};

export enum Tpa {
  Angle = 'Angle',
  Nomi = 'Nomi',
}

export type TaxonomyCode = {
  __typename?: 'TaxonomyCode';
  classification: Scalars['String'];
  codeset_version: Scalars['String'];
  definition: Scalars['String'];
  display_name: Scalars['String'];
  grouping: Scalars['String'];
  notes: Scalars['String'];
  section: Scalars['String'];
  source: Scalars['String'];
  specialization: Scalars['String'];
};

export type TaxonomyCodeInput = {
  classification?: InputMaybe<Scalars['String']>;
  codeset_version?: InputMaybe<Scalars['String']>;
  definition?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  grouping?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  specialization?: InputMaybe<Scalars['String']>;
};

export type TaxonomyCodes = {
  __typename?: 'TaxonomyCodes';
  _id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
};

export type TestResult = {
  __typename?: 'TestResult';
  attribute_code?: Maybe<Scalars['String']>;
  code_list_qualifier?: Maybe<Scalars['String']>;
  industry_code?: Maybe<Scalars['String']>;
  measurement_qualifier?: Maybe<Scalars['String']>;
  measurement_ref_id_code?: Maybe<Scalars['String']>;
  measurement_value?: Maybe<Scalars['String']>;
  method_or_device?: Maybe<Scalars['String']>;
  range_max?: Maybe<Scalars['String']>;
  range_min?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  significance_code?: Maybe<Scalars['String']>;
  surface_layer_position_code?: Maybe<Scalars['String']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

export type TestResultInput = {
  attribute_code?: InputMaybe<Scalars['String']>;
  code_list_qualifier?: InputMaybe<Scalars['String']>;
  industry_code?: InputMaybe<Scalars['String']>;
  measurement_qualifier?: InputMaybe<Scalars['String']>;
  measurement_ref_id_code?: InputMaybe<Scalars['String']>;
  measurement_value?: InputMaybe<Scalars['String']>;
  method_or_device?: InputMaybe<Scalars['String']>;
  range_max?: InputMaybe<Scalars['String']>;
  range_min?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  significance_code?: InputMaybe<Scalars['String']>;
  surface_layer_position_code?: InputMaybe<Scalars['String']>;
  unit_of_measure?: InputMaybe<Scalars['String']>;
};

export type ThreeDSecureUsage = {
  __typename?: 'ThreeDSecureUsage';
  supported: Scalars['Boolean'];
};

export type TimeToPay = {
  __typename?: 'TimeToPay';
  time_units?: Maybe<TimeToPayUnits>;
  unit_quantity?: Maybe<Scalars['Int']>;
};

export type TimeToPayInput = {
  time_units: TimeToPayUnits;
  unit_quantity: Scalars['Int'];
};

export enum TimeToPayUnits {
  TpuDays = 'TPU_DAYS',
  TpuHours = 'TPU_HOURS',
  TpuUnknown = 'TPU_UNKNOWN',
}

export enum TokenType {
  VirtualCardPin = 'VIRTUAL_CARD_PIN',
}

export type Transaction = IDocument & {
  __typename?: 'Transaction';
  _id: Scalars['ID'];
  amount_pennies: Scalars['Int'];
  audit?: Maybe<Audit>;
  description: Scalars['String'];
  fees?: Maybe<Array<Fee>>;
  from_account_id: Scalars['ID'];
  from_party_id: Scalars['ID'];
  metadata?: Maybe<TransactionMetadata>;
  real_time_decision_id: Scalars['String'];
  source_id: Scalars['ID'];
  to_account_id: Scalars['ID'];
  to_party_id: Scalars['ID'];
  transaction_source_type: TransactionSource;
  transaction_type: TransactionType;
};

export type TransactionMetadata = {
  __typename?: 'TransactionMetadata';
  external_events_metadata?: Maybe<ExternalEventsMetadata>;
  increase_card_authorization_metadata?: Maybe<IncreaseCardAuthorizationMetadata>;
  payer_funding_group_metadata?: Maybe<PayerFundingGroupMetadata>;
  plan_benefits_metadata?: Maybe<PlanBenefitsMetadata>;
  remittance_group_metadata?: Maybe<RemittanceGroupMetadata>;
};

export type TransactionSet835 = {
  __typename?: 'TransactionSet835';
  currency?: Maybe<Currency>;
  date_times?: Maybe<Array<Maybe<DateTimeReference>>>;
  loop_metadata?: Maybe<X12Metadata>;
  payee_id_loop?: Maybe<PayeeIdentificationLoop>;
  payer_id_loop?: Maybe<PayerIdentificationLoop>;
  provider_adjustment?: Maybe<Array<Maybe<ProviderAdjustment>>>;
  references?: Maybe<Array<Maybe<Reference>>>;
  remit_info_loop?: Maybe<Array<Maybe<RemittanceInformationLoop>>>;
  remittance_header?: Maybe<RemittanceHeader>;
  trn?: Maybe<TransmissionReceiptControl>;
  ts_header?: Maybe<TransactionSetHeader>;
  ts_trailer?: Maybe<TransactionSetTrailer>;
};

export type TransactionSet835Input = {
  currency?: InputMaybe<CurrencyInput>;
  date_times?: InputMaybe<Array<InputMaybe<DateTimeReferenceInput>>>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  payee_id_loop?: InputMaybe<PayeeIdentificationLoopInput>;
  payer_id_loop?: InputMaybe<PayerIdentificationLoopInput>;
  provider_adjustment?: InputMaybe<Array<InputMaybe<ProviderAdjustmentInput>>>;
  references?: InputMaybe<Array<InputMaybe<ReferenceInput>>>;
  remit_info_loop?: InputMaybe<Array<InputMaybe<RemittanceInformationLoopInput>>>;
  remittance_header?: InputMaybe<RemittanceHeaderInput>;
  trn?: InputMaybe<TransmissionReceiptControlInput>;
  ts_header?: InputMaybe<TransactionSetHeaderInput>;
  ts_trailer?: InputMaybe<TransactionSetTrailerInput>;
};

export type TransactionSet837 = {
  __typename?: 'TransactionSet837';
  bht?: Maybe<Bht>;
  loop_metadata?: Maybe<X12Metadata>;
  patient_loops?: Maybe<Array<Maybe<PatientLoop>>>;
  provider_loops?: Maybe<Array<Maybe<ProviderLoop>>>;
  receiver_name?: Maybe<ReceiverName>;
  submitter_name?: Maybe<SubmitterName>;
  subscriber_loops?: Maybe<Array<Maybe<SubscriberLoop>>>;
  ts_header?: Maybe<TransactionSetHeader>;
  ts_trailer?: Maybe<TransactionSetTrailer>;
};

export type TransactionSet837I = {
  __typename?: 'TransactionSet837I';
  bht?: Maybe<Bht>;
  loop_metadata?: Maybe<X12Metadata>;
  patient_loops?: Maybe<Array<Maybe<PatientLoop837I>>>;
  provider_loops?: Maybe<Array<Maybe<ProviderLoop837I>>>;
  receiver_name?: Maybe<ReceiverName837I>;
  submitter_name?: Maybe<SubmitterName837I>;
  subscriber_loops?: Maybe<Array<Maybe<SubscriberLoop837I>>>;
  ts_header?: Maybe<TransactionSetHeader>;
  ts_trailer?: Maybe<TransactionSetTrailer>;
};

export type TransactionSet837IInput = {
  bht?: InputMaybe<BhtInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  patient_loops?: InputMaybe<Array<InputMaybe<PatientLoop837IInput>>>;
  provider_loops?: InputMaybe<Array<InputMaybe<ProviderLoop837IInput>>>;
  receiver_name?: InputMaybe<ReceiverName837IInput>;
  submitter_name?: InputMaybe<SubmitterName837IInput>;
  subscriber_loops?: InputMaybe<Array<InputMaybe<SubscriberLoop837IInput>>>;
  ts_header?: InputMaybe<TransactionSetHeaderInput>;
  ts_trailer?: InputMaybe<TransactionSetTrailerInput>;
};

export type TransactionSet837Input = {
  bht?: InputMaybe<BhtInput>;
  loop_metadata?: InputMaybe<X12MetadataInput>;
  patient_loops?: InputMaybe<Array<InputMaybe<PatientLoopInput>>>;
  provider_loops?: InputMaybe<Array<InputMaybe<ProviderLoopInput>>>;
  receiver_name?: InputMaybe<ReceiverNameInput>;
  submitter_name?: InputMaybe<SubmitterNameInput>;
  subscriber_loops?: InputMaybe<Array<InputMaybe<SubscriberLoopInput>>>;
  ts_header?: InputMaybe<TransactionSetHeaderInput>;
  ts_trailer?: InputMaybe<TransactionSetTrailerInput>;
};

export type TransactionSetHeader = {
  __typename?: 'TransactionSetHeader';
  implementation_convention?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  transaction_set_control_number?: Maybe<Scalars['String']>;
  transaction_set_identifier_code?: Maybe<Scalars['String']>;
};

export type TransactionSetHeaderInput = {
  implementation_convention?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  transaction_set_control_number?: InputMaybe<Scalars['String']>;
  transaction_set_identifier_code?: InputMaybe<Scalars['String']>;
};

export type TransactionSetResponseHeader = {
  __typename?: 'TransactionSetResponseHeader';
  implementation_convention_ref?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  ts_control_number?: Maybe<Scalars['String']>;
  ts_id?: Maybe<Scalars['String']>;
};

export type TransactionSetResponseTrailer = {
  __typename?: 'TransactionSetResponseTrailer';
  segment_metadata?: Maybe<X12Metadata>;
  ts_acknowledgement_code?: Maybe<Scalars['String']>;
  ts_syntax_error_code_1?: Maybe<Scalars['String']>;
  ts_syntax_error_code_2?: Maybe<Scalars['String']>;
  ts_syntax_error_code_3?: Maybe<Scalars['String']>;
  ts_syntax_error_code_4?: Maybe<Scalars['String']>;
  ts_syntax_error_code_5?: Maybe<Scalars['String']>;
};

export type TransactionSetTrailer = {
  __typename?: 'TransactionSetTrailer';
  control_number?: Maybe<Scalars['String']>;
  segment_count?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type TransactionSetTrailerInput = {
  control_number?: InputMaybe<Scalars['String']>;
  segment_count?: InputMaybe<Uint32WrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum TransactionSource {
  IncreaseCardAuthorization = 'INCREASE_CARD_AUTHORIZATION',
  PayerFundingGroup = 'PAYER_FUNDING_GROUP',
  PlanBenefits = 'PLAN_BENEFITS',
  RemittanceGroup = 'REMITTANCE_GROUP',
  StripeCardPayment = 'STRIPE_CARD_PAYMENT',
}

export type TransactionStatistics = {
  __typename?: 'TransactionStatistics';
  claim_charge_amount?: Maybe<FloatWrapper>;
  facility_code?: Maybe<Scalars['String']>;
  fiscal_period_date?: Maybe<Scalars['String']>;
  hcpcs_charge_amount?: Maybe<FloatWrapper>;
  hcpcs_payable_amount?: Maybe<FloatWrapper>;
  monetary_amount_1?: Maybe<Scalars['String']>;
  monetary_amount_2?: Maybe<Scalars['String']>;
  monetary_amount_3?: Maybe<Scalars['String']>;
  monetary_amount_4?: Maybe<Scalars['String']>;
  monetary_amount_5?: Maybe<Scalars['String']>;
  monetary_amount_6?: Maybe<Scalars['String']>;
  monetary_amount_7?: Maybe<Scalars['String']>;
  monetary_amount_8?: Maybe<Scalars['String']>;
  monetary_amount_9?: Maybe<Scalars['String']>;
  monetary_amount_10?: Maybe<Scalars['String']>;
  msp_payer_amount?: Maybe<FloatWrapper>;
  nonlab_charge_amount?: Maybe<FloatWrapper>;
  patient_liability_amount?: Maybe<FloatWrapper>;
  patient_reimbursement_amount?: Maybe<FloatWrapper>;
  pip_adjustment_amount?: Maybe<FloatWrapper>;
  pip_claim_count?: Maybe<Uint32Wrapper>;
  professional_component_amount?: Maybe<FloatWrapper>;
  provider_id?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  total_claim_count?: Maybe<Uint32Wrapper>;
};

export type TransactionStatisticsInput = {
  claim_charge_amount?: InputMaybe<FloatWrapperInput>;
  facility_code?: InputMaybe<Scalars['String']>;
  fiscal_period_date?: InputMaybe<Scalars['String']>;
  hcpcs_charge_amount?: InputMaybe<FloatWrapperInput>;
  hcpcs_payable_amount?: InputMaybe<FloatWrapperInput>;
  monetary_amount_1?: InputMaybe<Scalars['String']>;
  monetary_amount_2?: InputMaybe<Scalars['String']>;
  monetary_amount_3?: InputMaybe<Scalars['String']>;
  monetary_amount_4?: InputMaybe<Scalars['String']>;
  monetary_amount_5?: InputMaybe<Scalars['String']>;
  monetary_amount_6?: InputMaybe<Scalars['String']>;
  monetary_amount_7?: InputMaybe<Scalars['String']>;
  monetary_amount_8?: InputMaybe<Scalars['String']>;
  monetary_amount_9?: InputMaybe<Scalars['String']>;
  monetary_amount_10?: InputMaybe<Scalars['String']>;
  msp_payer_amount?: InputMaybe<FloatWrapperInput>;
  nonlab_charge_amount?: InputMaybe<FloatWrapperInput>;
  patient_liability_amount?: InputMaybe<FloatWrapperInput>;
  patient_reimbursement_amount?: InputMaybe<FloatWrapperInput>;
  pip_adjustment_amount?: InputMaybe<FloatWrapperInput>;
  pip_claim_count?: InputMaybe<Uint32WrapperInput>;
  professional_component_amount?: InputMaybe<FloatWrapperInput>;
  provider_id?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  total_claim_count?: InputMaybe<Uint32WrapperInput>;
};

export type TransactionSupplementalStatistics = {
  __typename?: 'TransactionSupplementalStatistics';
  average_drg_weight?: Maybe<FloatWrapper>;
  capital_amount?: Maybe<FloatWrapper>;
  cost_outlier_amount?: Maybe<FloatWrapper>;
  cost_report_day_count?: Maybe<Uint32Wrapper>;
  covered_day_count?: Maybe<Uint32Wrapper>;
  day_outlier_amount?: Maybe<FloatWrapper>;
  discharge_count?: Maybe<Uint32Wrapper>;
  disproporionate_share_amount?: Maybe<FloatWrapper>;
  drg_amount?: Maybe<FloatWrapper>;
  drg_length_of_stay?: Maybe<Uint32Wrapper>;
  dsh_drg_amount?: Maybe<FloatWrapper>;
  federal_specific_amount?: Maybe<FloatWrapper>;
  fsp_drg_amount?: Maybe<FloatWrapper>;
  hospital_specific_amount?: Maybe<FloatWrapper>;
  hsp_drg_amount?: Maybe<FloatWrapper>;
  indirect_medical_education_amount?: Maybe<FloatWrapper>;
  msp_passthrough_amount?: Maybe<FloatWrapper>;
  noncovered_day_count?: Maybe<Uint32Wrapper>;
  outlier_day_count?: Maybe<Uint32Wrapper>;
  segment_metadata?: Maybe<X12Metadata>;
};

export type TransactionSupplementalStatisticsInput = {
  average_drg_weight?: InputMaybe<FloatWrapperInput>;
  capital_amount?: InputMaybe<FloatWrapperInput>;
  cost_outlier_amount?: InputMaybe<FloatWrapperInput>;
  cost_report_day_count?: InputMaybe<Uint32WrapperInput>;
  covered_day_count?: InputMaybe<Uint32WrapperInput>;
  day_outlier_amount?: InputMaybe<FloatWrapperInput>;
  discharge_count?: InputMaybe<Uint32WrapperInput>;
  disproporionate_share_amount?: InputMaybe<FloatWrapperInput>;
  drg_amount?: InputMaybe<FloatWrapperInput>;
  drg_length_of_stay?: InputMaybe<Uint32WrapperInput>;
  dsh_drg_amount?: InputMaybe<FloatWrapperInput>;
  federal_specific_amount?: InputMaybe<FloatWrapperInput>;
  fsp_drg_amount?: InputMaybe<FloatWrapperInput>;
  hospital_specific_amount?: InputMaybe<FloatWrapperInput>;
  hsp_drg_amount?: InputMaybe<FloatWrapperInput>;
  indirect_medical_education_amount?: InputMaybe<FloatWrapperInput>;
  msp_passthrough_amount?: InputMaybe<FloatWrapperInput>;
  noncovered_day_count?: InputMaybe<Uint32WrapperInput>;
  outlier_day_count?: InputMaybe<Uint32WrapperInput>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
};

export enum TransactionType {
  TtFunding = 'TT_FUNDING',
  TtOutbound = 'TT_OUTBOUND',
  TtRevenue = 'TT_REVENUE',
  TtUnknown = 'TT_UNKNOWN',
}

export type TransitionNameInput = {
  remittance_group_id: Scalars['ID'];
  transition_name: RemittanceGroupInTransitionName;
};

export type TransmissionReceiptControl = {
  __typename?: 'TransmissionReceiptControl';
  originating_company_identifier?: Maybe<Scalars['String']>;
  reference_identification_1?: Maybe<Scalars['String']>;
  reference_identification_2?: Maybe<Scalars['String']>;
  segment_metadata?: Maybe<X12Metadata>;
  trace_type_code?: Maybe<Scalars['String']>;
};

export type TransmissionReceiptControlOriginating_Company_IdentifierArgs = {
  detokenize?: Scalars['Boolean'];
};

export type TransmissionReceiptControlReference_Identification_2Args = {
  detokenize?: Scalars['Boolean'];
};

export type TransmissionReceiptControlInput = {
  originating_company_identifier?: InputMaybe<Scalars['String']>;
  reference_identification_1?: InputMaybe<Scalars['String']>;
  reference_identification_2?: InputMaybe<Scalars['String']>;
  segment_metadata?: InputMaybe<X12MetadataInput>;
  trace_type_code?: InputMaybe<Scalars['String']>;
};

export type Uint32Wrapper = {
  __typename?: 'Uint32Wrapper';
  value?: Maybe<Scalars['Int']>;
};

export type Uint32WrapperInput = {
  value?: InputMaybe<Scalars['Int']>;
};

export type UnifiedNamesInput = {
  names_unified: Array<Scalars['String']>;
};

export type UnifiedNamesPersonInput = {
  birth_date?: InputMaybe<Scalars['String']>;
  current_name?: InputMaybe<UnifiedNamesInput>;
};

export type UniqueNames = {
  __typename?: 'UniqueNames';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UniqueNamesAggResult = {
  __typename?: 'UniqueNamesAggResult';
  _id?: Maybe<Scalars['ID']>;
  unique_names?: Maybe<UniqueNames>;
};

export type Unit21CursorEvent = {
  __typename?: 'Unit21CursorEvent';
  cursor?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type UpdateContractInput = {
  id: Scalars['ID'];
  input: ContractUpdateInput;
};

export type UpdateContractResponse = {
  __typename?: 'UpdateContractResponse';
  contract?: Maybe<Contract>;
  errors: Array<Error>;
};

export type UpdateEmployerGroupContract = {
  contract_update: UpdateContractInput;
  sponsor_update: UpdateSponsorInput;
};

export type UpdateInvoiceInput = {
  id: Scalars['ID'];
  input: InvoiceUpdateInput;
};

export type UpdateInvoiceResponse = {
  __typename?: 'UpdateInvoiceResponse';
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type UpdateSponsorContractInput = {
  admin_fee_percentage: Scalars['Float'];
  auto_funding_disabled?: InputMaybe<Scalars['Boolean']>;
  primary_payment_client_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateSponsorInput = {
  group_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  primary_payment_client_id?: InputMaybe<Scalars['String']>;
  sponsor_name?: InputMaybe<Scalars['String']>;
};

export type UploadContractInput = {
  file_upload_id: Scalars['ID'];
  network_id: Scalars['ID'];
  party_id: Scalars['ID'];
};

export enum UploadFileType {
  FeeScheduleBundled = 'FEE_SCHEDULE_BUNDLED',
  FeeScheduleProfessional = 'FEE_SCHEDULE_PROFESSIONAL',
  ProviderContract = 'PROVIDER_CONTRACT',
  ProviderRoster = 'PROVIDER_ROSTER',
  SponsorContract = 'SPONSOR_CONTRACT',
  SponsorPlanDocument = 'SPONSOR_PLAN_DOCUMENT',
}

export type User = IDocument & {
  __typename?: 'User';
  _id: Scalars['ID'];
  audit?: Maybe<Audit>;
  identities?: Maybe<Array<Identity>>;
  party?: Maybe<Party>;
  party_id: Scalars['ID'];
  platform?: Maybe<Platform>;
  platform_id: Scalars['ID'];
  role_membership_ids?: Maybe<Array<Scalars['ID']>>;
  user_roles?: Maybe<Array<UserRole>>;
};

export type UserAsRcmInput = {
  organization_ids?: InputMaybe<Array<Scalars['String']>>;
  party_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  user_id: Scalars['String'];
  user_type?: Maybe<UserType>;
};

export enum UserRole {
  UrOther = 'UR_OTHER',
  UrPhysician = 'UR_PHYSICIAN',
  UrPracticeAdministrator = 'UR_PRACTICE_ADMINISTRATOR',
  UrRevenueCycleManager = 'UR_REVENUE_CYCLE_MANAGER',
  UrUnkown = 'UR_UNKOWN',
}

export enum UserType {
  UUnknown = 'U_UNKNOWN',
  UPing = 'u_PING',
}

export type VirtualCardPdfInput = {
  Addr1: Scalars['String'];
  Addr2: Scalars['String'];
  Amount: Scalars['String'];
  CVCCode: Scalars['String'];
  CardNo: Scalars['String'];
  Expiration: Scalars['String'];
  Link: Scalars['String'];
  PaymentId: Scalars['String'];
  ProviderAddr1: Scalars['String'];
  ProviderAddr2: Scalars['String'];
  ProviderName: Scalars['String'];
  ProviderPin: Scalars['String'];
  TPA: Tpa;
};

export type VirtualCardPdfResponse = {
  __typename?: 'VirtualCardPdfResponse';
  url: Scalars['String'];
};

export type WebsiteAddress = {
  __typename?: 'WebsiteAddress';
  website: Scalars['String'];
};

export type WebsiteAddressWebsiteArgs = {
  detokenize?: Scalars['Boolean'];
};

export type WebsiteAddressInput = {
  website: Scalars['String'];
};

export type X12Code = {
  __typename?: 'X12Code';
  code?: Maybe<Scalars['String']>;
  element_number?: Maybe<Scalars['String']>;
};

export type X12CodeInput = {
  code?: InputMaybe<Scalars['String']>;
  element_number?: InputMaybe<Scalars['String']>;
};

export type X12Metadata = {
  __typename?: 'X12Metadata';
  metadata?: Maybe<Scalars['String']>;
  seg_name?: Maybe<Scalars['String']>;
};

export type X12MetadataInput = {
  metadata?: InputMaybe<Scalars['String']>;
  seg_name?: InputMaybe<Scalars['String']>;
};

export type AttachOrgToRcmUserInput = {
  organization_ids?: InputMaybe<Array<Scalars['String']>>;
  pin: Scalars['String'];
};

export type CreateProviderGroupContractMetadataResponse = {
  __typename?: 'createProviderGroupContractMetadataResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateProviderGroupInput = {
  authorized_official?: InputMaybe<Scalars['String']>;
  credentials?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<ProviderGroupType>;
  lifecycle_state?: InputMaybe<ProviderGroupLifecycleState>;
  name?: InputMaybe<Scalars['String']>;
  network_id: Scalars['ID'];
  npi: Scalars['ID'];
  party_id: Scalars['ID'];
};

export type CreateSubsidiaryInput = {
  address: ProviderGroupAddressInput;
  group_name: Scalars['String'];
  group_npi: Scalars['String'];
  group_tin: Scalars['String'];
};

export type GetRcmUserBankAccountsInput = {
  include_hard_denied?: InputMaybe<Scalars['Boolean']>;
  remove_duplicates?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<FinancialAccountStatus>>;
};

export type OnboardProviderGroupForPaymentInput = {
  address: ProviderGroupAddressInput;
  email?: InputMaybe<Scalars['String']>;
  group_name: Scalars['String'];
  group_npi: Scalars['String'];
  group_tin: Scalars['String'];
};

export type OnboardProviderGroupInput = {
  address: ProviderGroupAddressInput;
  email?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<ProviderGroupType>;
  group_name: Scalars['String'];
  group_npi: Scalars['String'];
  group_tin: Scalars['String'];
  network_id: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  subsidiaries?: InputMaybe<Array<InputMaybe<CreateSubsidiaryInput>>>;
};

export type OnboardProviderGroupResponse = {
  __typename?: 'onboardProviderGroupResponse';
  provider_group_id: Scalars['ID'];
  provider_group_party_id: Scalars['ID'];
  provider_groups?: Maybe<Array<Maybe<ProviderGroup>>>;
  subsidiary_party_ids?: Maybe<Array<Scalars['ID']>>;
  subsidiary_provider_group_ids?: Maybe<Array<Scalars['ID']>>;
};

export type ReprocessRemitGroupStateResponse = {
  __typename?: 'reprocessRemitGroupStateResponse';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendFeedbackEmailInput = {
  message?: InputMaybe<Scalars['String']>;
  receiver_email: Scalars['String'];
};

export type AcceptPolicyMutationVariables = Exact<{
  party_id: Scalars['ID'];
  policy_type: PolicyType;
}>;

export type AcceptPolicyMutation = {
  __typename?: 'Mutation';
  acceptPolicy?: {
    __typename?: 'Party';
    person?: {
      __typename?: 'Person';
      policies?: Array<{ __typename?: 'Policy'; policy_type: PolicyType; accepted: boolean } | null> | null;
    } | null;
  } | null;
};

export type IPersonFragment = {
  __typename?: 'Person';
  policies?: Array<{ __typename?: 'Policy'; policy_type: PolicyType; accepted: boolean } | null> | null;
};

export type IPolicyFragment = { __typename?: 'Policy'; policy_type: PolicyType; accepted: boolean };

export type AddEmailMutationVariables = Exact<{
  partyId: Scalars['ID'];
  email: AddEmailInput;
}>;

export type AddEmailMutation = {
  __typename?: 'Mutation';
  addEmail?: {
    __typename?: 'AddEmailResponse';
    result: AddEmailResult;
    party?: {
      __typename?: 'Party';
      _id: string;
      contacts?: Array<{
        __typename?: 'PartyContact';
        contact?: { __typename?: 'Contact'; _id: string; email_address?: { __typename?: 'EmailAddress'; email: string } | null } | null;
      }> | null;
    } | null;
  } | null;
};

export type AddSsnMutationVariables = Exact<{
  party_id: Scalars['ID'];
  ssn_number: Scalars['String'];
}>;

export type AddSsnMutation = { __typename?: 'Mutation'; addGovernmentId?: { __typename?: 'Party'; party_type?: PartyType | null } | null };

export type CheckSsnQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CheckSsnQuery = {
  __typename?: 'Query';
  party?: {
    __typename?: 'Party';
    government_ids?: Array<{ __typename?: 'GovernmentIssuedId'; id_type: GovernmentIdType } | null> | null;
  } | null;
};

export type GetAlloyAndBankStatusesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAlloyAndBankStatusesQuery = {
  __typename?: 'Query';
  complianceInfoForParty?: {
    __typename?: 'ComplianceInfo';
    references?: Array<{
      __typename?: 'ComplianceReference';
      decision: ComplianceDecision;
      reference_type: ComplianceReferenceType;
      effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null } | null;
    }> | null;
  } | null;
};

export type ReferenceAccountFragment = {
  __typename?: 'ComplianceReference';
  decision: ComplianceDecision;
  reference_type: ComplianceReferenceType;
  effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null } | null;
};

export type GetDependentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDependentByIdQuery = {
  __typename?: 'Query';
  member?: {
    __typename?: 'Member';
    _id: string;
    member_relationship?: MemberRelationship | null;
    party?: {
      __typename?: 'Party';
      _id: string;
      person?: {
        __typename?: 'Person';
        birth_date?: string | null;
        current_name: { __typename?: 'PersonName'; names?: Array<string> | null; preferred_name?: string | null };
      } | null;
      contacts?: Array<{
        __typename?: 'PartyContact';
        is_primary?: boolean | null;
        purpose?: string | null;
        effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
        contact?: {
          __typename?: 'Contact';
          postal_address?: {
            __typename?: 'PostalAddress';
            street_address1: string;
            street_address2?: string | null;
            unit_number: string;
            city_town: string;
            state_province: string;
            postal_code: string;
          } | null;
          phone_number?: { __typename?: 'PhoneNumber'; phone: string } | null;
          email_address?: { __typename?: 'EmailAddress'; email: string } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetDependentsQueryVariables = Exact<{
  member: MemberInput;
}>;

export type GetDependentsQuery = {
  __typename?: 'Query';
  memberByFields?: {
    __typename?: 'Member';
    dependent_members?: Array<{
      __typename?: 'Member';
      _id: string;
      member_relationship?: MemberRelationship | null;
      party?: {
        __typename?: 'Party';
        _id: string;
        person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetTermsAcceptanceStatusForUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTermsAcceptanceStatusForUserQuery = {
  __typename?: 'Query';
  party?: {
    __typename?: 'Party';
    _id: string;
    person?: {
      __typename?: 'Person';
      policies?: Array<{ __typename?: 'Policy'; policy_type: PolicyType; accepted: boolean } | null> | null;
    } | null;
  } | null;
};

export type GetUserNameByPartyIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserNameByPartyIdQuery = {
  __typename?: 'Query';
  party?: {
    __typename?: 'Party';
    person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
  } | null;
};

export type GetPartyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPartyByIdQuery = {
  __typename?: 'Query';
  party?: {
    __typename?: 'Party';
    person?: {
      __typename?: 'Person';
      birth_date?: string | null;
      current_name: { __typename?: 'PersonName'; names?: Array<string> | null; preferred_name?: string | null };
    } | null;
    contacts?: Array<{
      __typename?: 'PartyContact';
      is_primary?: boolean | null;
      purpose?: string | null;
      effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
      contact?: {
        __typename?: 'Contact';
        postal_address?: {
          __typename?: 'PostalAddress';
          street_address1: string;
          street_address2?: string | null;
          unit_number: string;
          city_town: string;
          state_province: string;
          postal_code: string;
        } | null;
        phone_number?: { __typename?: 'PhoneNumber'; phone: string } | null;
        email_address?: { __typename?: 'EmailAddress'; email: string } | null;
      } | null;
    }> | null;
  } | null;
};

export type IPartyContactFragment = {
  __typename?: 'PartyContact';
  is_primary?: boolean | null;
  purpose?: string | null;
  effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
  contact?: {
    __typename?: 'Contact';
    postal_address?: {
      __typename?: 'PostalAddress';
      street_address1: string;
      street_address2?: string | null;
      unit_number: string;
      city_town: string;
      state_province: string;
      postal_code: string;
    } | null;
    phone_number?: { __typename?: 'PhoneNumber'; phone: string } | null;
    email_address?: { __typename?: 'EmailAddress'; email: string } | null;
  } | null;
};

export type IPostalAddressFragment = {
  __typename?: 'PostalAddress';
  street_address1: string;
  street_address2?: string | null;
  unit_number: string;
  city_town: string;
  state_province: string;
  postal_code: string;
};

export type TriggerAlloyCheckMutationVariables = Exact<{
  party_id: Scalars['ID'];
}>;

export type TriggerAlloyCheckMutation = {
  __typename?: 'Mutation';
  triggerAlloyCheck?: { __typename?: 'ComplianceInfo'; _id: string } | null;
};

export type UpdateContactInfoMutationVariables = Exact<{
  party_id: Scalars['ID'];
  preferred_name?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  postal_address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  phone_number: Scalars['String'];
}>;

export type UpdateContactInfoMutation = {
  __typename?: 'Mutation';
  updateContactInfo?: {
    __typename?: 'Party';
    _id: string;
    contacts?: Array<{
      __typename?: 'PartyContact';
      is_primary?: boolean | null;
      purpose?: string | null;
      effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
      contact?: {
        __typename?: 'Contact';
        postal_address?: {
          __typename?: 'PostalAddress';
          street_address1: string;
          street_address2?: string | null;
          city_town: string;
          state_province: string;
          postal_code: string;
        } | null;
        phone_number?: { __typename?: 'PhoneNumber'; phone: string } | null;
        email_address?: { __typename?: 'EmailAddress'; email: string } | null;
      } | null;
    }> | null;
  } | null;
};

export type BillByIdQueryVariables = Exact<{
  invoice_id: Scalars['ID'];
}>;

export type BillByIdQuery = {
  __typename?: 'Query';
  invoiceById?: {
    __typename?: 'Invoice';
    _id: string;
    invoice_state: InvoiceState;
    invoice_total_pennies: number;
    service_fee_pennies: number;
    line_items?: Array<{
      __typename?: 'ServiceLine';
      quantity?: number | null;
      long_tw_desc: string;
      charge_pennies?: number | null;
      rendering_provider_id: string;
      raw_charged_code: string;
      rendering_provider?: {
        __typename?: 'Provider';
        party?: {
          __typename?: 'Party';
          person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
        } | null;
      } | null;
      charged_code?: {
        __typename?: 'CodedMedicalService';
        code: string;
        codeset: MedicalCodeSetType;
        medical_code?: {
          __typename?: 'ReferenceCode';
          cpt_code_meta_data?: { __typename?: 'CptCodeMetadata'; long_description: string } | null;
        } | null;
      } | null;
    }> | null;
    encounter?: {
      __typename?: 'Encounter';
      practice_facility_id: string;
      practice_facility?: {
        __typename?: 'PracticeFacility';
        facility_model?: { __typename?: 'Facility'; name?: string | null } | null;
      } | null;
      service?: { __typename?: 'EncounterService'; service_time: string } | null;
      member?: {
        __typename?: 'Member';
        party?: {
          __typename?: 'Party';
          person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
          accounts?: {
            __typename?: 'Accounts';
            accounts?: Array<{
              __typename?: 'FinancialAccount';
              account_id: string;
              account_type: any;
              status: FinancialAccountStatus;
              bank_account?: {
                __typename?: 'BankAccount';
                name?: string | null;
                nick_name?: string | null;
                external_type?: ExternalAccountType | null;
                account_number_last_four?: string | null;
                bank_name?: string | null;
                routing_number?: string | null;
              } | null;
              stripe_customer_account?: Array<{
                __typename?: 'CardData';
                id?: string | null;
                last4?: string | null;
                brand?: string | null;
                exp_month?: number | null;
                exp_year?: number | null;
              } | null> | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null;
    bills?: Array<{
      __typename?: 'Bill';
      id?: string | null;
      bill_state?: BillState | null;
      bill_type?: BillType | null;
      bill_total?: number | null;
      cash_payment?: boolean | null;
      payment_method?: string | null;
      bill_total_pennies?: number | null;
      service_fee_pennies?: Array<number> | null;
      payment_method_account?: {
        __typename?: 'FinancialAccount';
        account_type: any;
        account_id: string;
        bank_account?: {
          __typename?: 'BankAccount';
          account_number_last_four?: string | null;
          external_type?: ExternalAccountType | null;
        } | null;
      } | null;
      payment_method_stripe?: { __typename?: 'CardData'; id?: string | null; brand?: string | null; last4?: string | null } | null;
      audit_logs?: Array<{
        __typename?: 'BillAuditLog';
        transitioned_at_time?: string | null;
        remittance_state_transition?: {
          __typename?: 'BillStateTransition';
          from_state?: BillState | null;
          to_state?: BillState | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetInvoicesQueryVariables = Exact<{
  filter: InvoicesFilter;
  options?: InputMaybe<Options>;
  bill_type?: InputMaybe<Array<BillType> | BillType>;
  bill_state?: InputMaybe<Array<BillState> | BillState>;
}>;

export type GetInvoicesQuery = {
  __typename?: 'Query';
  invoices?: {
    __typename?: 'Invoices';
    offset: number;
    total: number;
    results: Array<{
      __typename?: 'Invoice';
      _id: string;
      invoice_state: InvoiceState;
      primary_member_id: string;
      encounter_id: string;
      patient_member_id: string;
      line_items?: Array<{
        __typename?: 'ServiceLine';
        charge_pennies?: number | null;
        rendering_provider_id: string;
        raw_charged_code: string;
        rendering_provider?: {
          __typename?: 'Provider';
          party?: {
            __typename?: 'Party';
            person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
          } | null;
        } | null;
        charged_code?: {
          __typename?: 'CodedMedicalService';
          code: string;
          codeset: MedicalCodeSetType;
          medical_code?: {
            __typename?: 'ReferenceCode';
            cpt_code_meta_data?: { __typename?: 'CptCodeMetadata'; long_description: string } | null;
          } | null;
        } | null;
      }> | null;
      bills?: Array<{
        __typename?: 'Bill';
        id?: string | null;
        bill_state?: BillState | null;
        bill_type?: BillType | null;
        bill_total?: number | null;
        cash_payment?: boolean | null;
        payment_method?: string | null;
        bill_total_pennies?: number | null;
        service_fee_pennies?: Array<number> | null;
        payment_method_account?: {
          __typename?: 'FinancialAccount';
          account_type: any;
          account_id: string;
          bank_account?: {
            __typename?: 'BankAccount';
            account_number_last_four?: string | null;
            external_type?: ExternalAccountType | null;
          } | null;
        } | null;
        payment_method_stripe?: { __typename?: 'CardData'; id?: string | null; brand?: string | null; last4?: string | null } | null;
        audit_logs?: Array<{
          __typename?: 'BillAuditLog';
          transitioned_at_time?: string | null;
          remittance_state_transition?: {
            __typename?: 'BillStateTransition';
            from_state?: BillState | null;
            to_state?: BillState | null;
          } | null;
        } | null> | null;
      } | null> | null;
      encounter?: {
        __typename?: 'Encounter';
        practice_facility_id: string;
        practice_facility?: {
          __typename?: 'PracticeFacility';
          facility_model?: { __typename?: 'Facility'; name?: string | null } | null;
        } | null;
        service?: { __typename?: 'EncounterService'; service_time: string } | null;
        member?: {
          __typename?: 'Member';
          party?: {
            __typename?: 'Party';
            person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
            accounts?: {
              __typename?: 'Accounts';
              accounts?: Array<{
                __typename?: 'FinancialAccount';
                account_id: string;
                account_type: any;
                status: FinancialAccountStatus;
                bank_account?: {
                  __typename?: 'BankAccount';
                  name?: string | null;
                  nick_name?: string | null;
                  external_type?: ExternalAccountType | null;
                  account_number_last_four?: string | null;
                  bank_name?: string | null;
                  routing_number?: string | null;
                } | null;
                stripe_customer_account?: Array<{
                  __typename?: 'CardData';
                  id?: string | null;
                  last4?: string | null;
                  brand?: string | null;
                  exp_month?: number | null;
                  exp_year?: number | null;
                } | null> | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ComplianceInfoForPartyQueryVariables = Exact<{
  party_id: Scalars['ID'];
}>;

export type ComplianceInfoForPartyQuery = {
  __typename?: 'Query';
  complianceInfoForParty?: {
    __typename?: 'ComplianceInfo';
    _id: string;
    for_party_id: string;
    references?: Array<{
      __typename?: 'ComplianceReference';
      reference_id: string;
      reference_type: ComplianceReferenceType;
      decision: ComplianceDecision;
      effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
    }> | null;
  } | null;
};

export type IComplianceInfoFragment = {
  __typename?: 'ComplianceInfo';
  _id: string;
  for_party_id: string;
  references?: Array<{
    __typename?: 'ComplianceReference';
    reference_id: string;
    reference_type: ComplianceReferenceType;
    decision: ComplianceDecision;
    effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
  }> | null;
};

export type IComplianceReferenceFragment = {
  __typename?: 'ComplianceReference';
  reference_id: string;
  reference_type: ComplianceReferenceType;
  decision: ComplianceDecision;
  effective_dates?: { __typename?: 'EffectiveDates'; from_date?: string | null; to_date?: string | null } | null;
};

export type IInvoiceFragment = {
  __typename?: 'Invoice';
  _id: string;
  invoice_state: InvoiceState;
  primary_member_id: string;
  encounter_id: string;
  patient_member_id: string;
  line_items?: Array<{
    __typename?: 'ServiceLine';
    charge_pennies?: number | null;
    rendering_provider_id: string;
    raw_charged_code: string;
    rendering_provider?: {
      __typename?: 'Provider';
      party?: {
        __typename?: 'Party';
        person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
      } | null;
    } | null;
    charged_code?: {
      __typename?: 'CodedMedicalService';
      code: string;
      codeset: MedicalCodeSetType;
      medical_code?: {
        __typename?: 'ReferenceCode';
        cpt_code_meta_data?: { __typename?: 'CptCodeMetadata'; long_description: string } | null;
      } | null;
    } | null;
  }> | null;
  bills?: Array<{
    __typename?: 'Bill';
    id?: string | null;
    bill_state?: BillState | null;
    bill_type?: BillType | null;
    bill_total?: number | null;
    cash_payment?: boolean | null;
    payment_method?: string | null;
    bill_total_pennies?: number | null;
    service_fee_pennies?: Array<number> | null;
    payment_method_account?: {
      __typename?: 'FinancialAccount';
      account_type: any;
      account_id: string;
      bank_account?: {
        __typename?: 'BankAccount';
        account_number_last_four?: string | null;
        external_type?: ExternalAccountType | null;
      } | null;
    } | null;
    payment_method_stripe?: { __typename?: 'CardData'; id?: string | null; brand?: string | null; last4?: string | null } | null;
    audit_logs?: Array<{
      __typename?: 'BillAuditLog';
      transitioned_at_time?: string | null;
      remittance_state_transition?: {
        __typename?: 'BillStateTransition';
        from_state?: BillState | null;
        to_state?: BillState | null;
      } | null;
    } | null> | null;
  } | null> | null;
  encounter?: {
    __typename?: 'Encounter';
    practice_facility_id: string;
    practice_facility?: {
      __typename?: 'PracticeFacility';
      facility_model?: { __typename?: 'Facility'; name?: string | null } | null;
    } | null;
    service?: { __typename?: 'EncounterService'; service_time: string } | null;
    member?: {
      __typename?: 'Member';
      party?: {
        __typename?: 'Party';
        person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
        accounts?: {
          __typename?: 'Accounts';
          accounts?: Array<{
            __typename?: 'FinancialAccount';
            account_id: string;
            account_type: any;
            status: FinancialAccountStatus;
            bank_account?: {
              __typename?: 'BankAccount';
              name?: string | null;
              nick_name?: string | null;
              external_type?: ExternalAccountType | null;
              account_number_last_four?: string | null;
              bank_name?: string | null;
              routing_number?: string | null;
            } | null;
            stripe_customer_account?: Array<{
              __typename?: 'CardData';
              id?: string | null;
              last4?: string | null;
              brand?: string | null;
              exp_month?: number | null;
              exp_year?: number | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IServiceLineFragment = {
  __typename?: 'ServiceLine';
  charge_pennies?: number | null;
  rendering_provider_id: string;
  raw_charged_code: string;
  rendering_provider?: {
    __typename?: 'Provider';
    party?: {
      __typename?: 'Party';
      person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
    } | null;
  } | null;
  charged_code?: {
    __typename?: 'CodedMedicalService';
    code: string;
    codeset: MedicalCodeSetType;
    medical_code?: {
      __typename?: 'ReferenceCode';
      cpt_code_meta_data?: { __typename?: 'CptCodeMetadata'; long_description: string } | null;
    } | null;
  } | null;
};

export type IBillFragment = {
  __typename?: 'Bill';
  id?: string | null;
  bill_state?: BillState | null;
  bill_type?: BillType | null;
  bill_total?: number | null;
  cash_payment?: boolean | null;
  payment_method?: string | null;
  bill_total_pennies?: number | null;
  service_fee_pennies?: Array<number> | null;
  payment_method_account?: {
    __typename?: 'FinancialAccount';
    account_type: any;
    account_id: string;
    bank_account?: {
      __typename?: 'BankAccount';
      account_number_last_four?: string | null;
      external_type?: ExternalAccountType | null;
    } | null;
  } | null;
  payment_method_stripe?: { __typename?: 'CardData'; id?: string | null; brand?: string | null; last4?: string | null } | null;
  audit_logs?: Array<{
    __typename?: 'BillAuditLog';
    transitioned_at_time?: string | null;
    remittance_state_transition?: { __typename?: 'BillStateTransition'; from_state?: BillState | null; to_state?: BillState | null } | null;
  } | null> | null;
};

export type IEncounterFragment = {
  __typename?: 'Encounter';
  practice_facility_id: string;
  practice_facility?: { __typename?: 'PracticeFacility'; facility_model?: { __typename?: 'Facility'; name?: string | null } | null } | null;
  service?: { __typename?: 'EncounterService'; service_time: string } | null;
  member?: {
    __typename?: 'Member';
    party?: {
      __typename?: 'Party';
      person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
      accounts?: {
        __typename?: 'Accounts';
        accounts?: Array<{
          __typename?: 'FinancialAccount';
          account_id: string;
          account_type: any;
          status: FinancialAccountStatus;
          bank_account?: {
            __typename?: 'BankAccount';
            name?: string | null;
            nick_name?: string | null;
            external_type?: ExternalAccountType | null;
            account_number_last_four?: string | null;
            bank_name?: string | null;
            routing_number?: string | null;
          } | null;
          stripe_customer_account?: Array<{
            __typename?: 'CardData';
            id?: string | null;
            last4?: string | null;
            brand?: string | null;
            exp_month?: number | null;
            exp_year?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type IPartyFragment = {
  __typename?: 'Party';
  person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
  accounts?: {
    __typename?: 'Accounts';
    accounts?: Array<{
      __typename?: 'FinancialAccount';
      account_id: string;
      account_type: any;
      status: FinancialAccountStatus;
      bank_account?: {
        __typename?: 'BankAccount';
        name?: string | null;
        nick_name?: string | null;
        external_type?: ExternalAccountType | null;
        account_number_last_four?: string | null;
        bank_name?: string | null;
        routing_number?: string | null;
      } | null;
      stripe_customer_account?: Array<{
        __typename?: 'CardData';
        id?: string | null;
        last4?: string | null;
        brand?: string | null;
        exp_month?: number | null;
        exp_year?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type IFinancialAccountFragment = {
  __typename?: 'FinancialAccount';
  account_id: string;
  account_type: any;
  status: FinancialAccountStatus;
  bank_account?: {
    __typename?: 'BankAccount';
    name?: string | null;
    nick_name?: string | null;
    external_type?: ExternalAccountType | null;
    account_number_last_four?: string | null;
    bank_name?: string | null;
    routing_number?: string | null;
  } | null;
  stripe_customer_account?: Array<{
    __typename?: 'CardData';
    id?: string | null;
    last4?: string | null;
    brand?: string | null;
    exp_month?: number | null;
    exp_year?: number | null;
  } | null> | null;
};

export type IBankAccountFragment = {
  __typename?: 'BankAccount';
  name?: string | null;
  nick_name?: string | null;
  external_type?: ExternalAccountType | null;
  account_number_last_four?: string | null;
  bank_name?: string | null;
  routing_number?: string | null;
};

export type ICardDataFragment = {
  __typename?: 'CardData';
  id?: string | null;
  last4?: string | null;
  brand?: string | null;
  exp_month?: number | null;
  exp_year?: number | null;
};

export type GetMemberIdCardUrlQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;

export type GetMemberIdCardUrlQuery = { __typename?: 'Query'; memberCardUrl?: string | null };

export type MemberByFieldQueryVariables = Exact<{
  member: MemberInput;
}>;

export type MemberByFieldQuery = {
  __typename?: 'Query';
  memberByFields?: {
    __typename?: 'Member';
    _id: string;
    subscriber_id: string;
    plan_code: string;
    card_id: string;
    coverage_start_date?: string | null;
    dependents?: Array<string> | null;
    party?: {
      __typename?: 'Party';
      person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
    } | null;
    dependent_members?: Array<{
      __typename?: 'Member';
      subscriber_id: string;
      card_id: string;
      party?: {
        __typename?: 'Party';
        person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
      } | null;
    }> | null;
    sponsor?: {
      __typename?: 'Sponsor';
      plans?: Array<{
        __typename?: 'Plan';
        group_id?: string | null;
        group_name?: string | null;
        member_ids?: Array<string> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PayBillMutationVariables = Exact<{
  invoice_id: Scalars['ID'];
  bill_id: Scalars['ID'];
  payment_method_id: Scalars['ID'];
}>;

export type PayBillMutation = {
  __typename?: 'Mutation';
  payBill?: {
    __typename?: 'Remittance';
    bill_reference_id: string;
    amount_pennies: number;
    bill_reference_type: BillReferenceType;
    due_date: string;
    from_ledger_account: string;
    from_payer_id: string;
    remittance_state: RemittanceState;
    service_fee_amount_pennies?: number | null;
    to_ledger_account: string;
    to_payee_id: string;
  } | null;
};

export type PayBillWithCreditCardMutationVariables = Exact<{
  party_id: Scalars['ID'];
  bill_id: Scalars['ID'];
  payment_method_id: Scalars['String'];
}>;

export type PayBillWithCreditCardMutation = {
  __typename?: 'Mutation';
  createPaymentIntent?: {
    __typename?: 'StripePaymentIntent';
    amount: number;
    amountReceived?: number | null;
    created: number;
    currency: string;
    description?: string | null;
    id: string;
    paymentMethodTypes: Array<string | null>;
    status: PaymentIntentStatus;
  } | null;
};

export type BankAccountOnBoardingMutationVariables = Exact<{
  bankAccountOnboardingRequest: BankAccountOnBoardingInput;
}>;

export type BankAccountOnBoardingMutation = {
  __typename?: 'Mutation';
  bankAccountOnBoarding?: {
    __typename?: 'FinancialAccount';
    account_id: string;
    is_onboarding: boolean;
    status: FinancialAccountStatus;
  } | null;
};

export type StripeFieldsFragment = {
  __typename?: 'CardData';
  id?: string | null;
  exp_month?: number | null;
  exp_year?: number | null;
  account_type?: string | null;
  brand?: string | null;
  funding?: string | null;
  last4?: string | null;
  audit?: { __typename?: 'Audit'; created_at?: string | null } | null;
};

export type BankAccountFieldsFragment = {
  __typename?: 'BankAccount';
  name?: string | null;
  bank_name?: string | null;
  account_number_last_four?: string | null;
  external_type?: ExternalAccountType | null;
  routing_number?: string | null;
};

export type GetPaymentMethodsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: 'Query';
  party?: {
    __typename?: 'Party';
    party_type?: PartyType | null;
    accounts?: {
      __typename?: 'Accounts';
      accounts?: Array<{
        __typename?: 'FinancialAccount';
        account_id: string;
        account_type: any;
        status: FinancialAccountStatus;
        audit?: { __typename?: 'Audit'; created_at?: string | null } | null;
        stripe_customer_account?: Array<{
          __typename?: 'CardData';
          id?: string | null;
          exp_month?: number | null;
          exp_year?: number | null;
          account_type?: string | null;
          brand?: string | null;
          funding?: string | null;
          last4?: string | null;
          audit?: { __typename?: 'Audit'; created_at?: string | null } | null;
        } | null> | null;
        bank_account?: {
          __typename?: 'BankAccount';
          name?: string | null;
          bank_name?: string | null;
          account_number_last_four?: string | null;
          external_type?: ExternalAccountType | null;
          routing_number?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetPlanUtilizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPlanUtilizationQuery = {
  __typename?: 'Query';
  party?: {
    __typename?: 'Party';
    memberships?: Array<{
      __typename?: 'Member';
      plan_utilizations?: {
        __typename?: 'PlanUtilization';
        _id: string;
        utilizations?: Array<{
          __typename?: 'CoverageLimitUtilization';
          hash_code: string;
          amount_used_pennies?: number | null;
          amount_pennies_remaining?: number | null;
          quantity_remaining?: number | null;
          quantity_used?: number | null;
          reference_code?: { __typename?: 'MedicalCodeGroup'; name: string } | { __typename?: 'ReferenceCode' } | null;
        } | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type GetPracticeFacilityByIdQueryVariables = Exact<{
  practiceFacilityId: Scalars['ID'];
}>;

export type GetPracticeFacilityByIdQuery = {
  __typename?: 'Query';
  practiceFacility?: {
    __typename?: 'PracticeFacility';
    name: string;
    street_address_2: string;
    facility_model?: {
      __typename?: 'Facility';
      name?: string | null;
      location?: {
        __typename?: 'Location';
        postal_address?: {
          __typename?: 'PostalAddress';
          street_address1: string;
          city_town: string;
          state_province: string;
          postal_code: string;
        } | null;
      } | null;
    } | null;
    providers_at_facility?: Array<{
      __typename?: 'ProvidersIn';
      provider?: {
        __typename?: 'Provider';
        type?: ProviderType | null;
        party?: {
          __typename?: 'Party';
          person?: { __typename?: 'Person'; current_name: { __typename?: 'PersonName'; names?: Array<string> | null } } | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetNetworkSpecialtiesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNetworkSpecialtiesQuery = {
  __typename?: 'Query';
  getNetworkSpecialties?: Array<{
    __typename?: 'SpecialtyToTaxonomies';
    specialty?: string | null;
    taxonomies?: Array<string> | null;
  }> | null;
};

export type GetPracticeFacilitiesInAreaQueryVariables = Exact<{
  zip_code: Scalars['String'];
  search_radius_in_meters: Scalars['Int'];
  taxonomies?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPracticeFacilitiesInAreaQuery = {
  __typename?: 'Query';
  practiceFacilitiesInArea?: Array<{
    __typename?: 'PracticeFacilityInAreaResponse';
    distance?: number | null;
    practice_facility?: {
      __typename?: 'PracticeFacility';
      _id: string;
      name: string;
      facility_model?: {
        __typename?: 'Facility';
        _id: string;
        name?: string | null;
        location?: {
          __typename?: 'Location';
          postal_address?: {
            __typename?: 'PostalAddress';
            street_address1: string;
            street_address2?: string | null;
            unit_number: string;
            city_town: string;
            state_province: string;
            postal_code: string;
          } | null;
          geolocation?: { __typename?: 'Geolocation'; coordinates?: Array<number> | null } | null;
        } | null;
      } | null;
    } | null;
  }> | null;
};

export type IPracticeFacilityInAreaFragment = {
  __typename?: 'PracticeFacilityInAreaResponse';
  distance?: number | null;
  practice_facility?: {
    __typename?: 'PracticeFacility';
    _id: string;
    name: string;
    facility_model?: {
      __typename?: 'Facility';
      _id: string;
      name?: string | null;
      location?: {
        __typename?: 'Location';
        postal_address?: {
          __typename?: 'PostalAddress';
          street_address1: string;
          street_address2?: string | null;
          unit_number: string;
          city_town: string;
          state_province: string;
          postal_code: string;
        } | null;
        geolocation?: { __typename?: 'Geolocation'; coordinates?: Array<number> | null } | null;
      } | null;
    } | null;
  } | null;
};

export type IPracticeFacilityFragment = {
  __typename?: 'PracticeFacility';
  _id: string;
  name: string;
  facility_model?: {
    __typename?: 'Facility';
    _id: string;
    name?: string | null;
    location?: {
      __typename?: 'Location';
      postal_address?: {
        __typename?: 'PostalAddress';
        street_address1: string;
        street_address2?: string | null;
        unit_number: string;
        city_town: string;
        state_province: string;
        postal_code: string;
      } | null;
      geolocation?: { __typename?: 'Geolocation'; coordinates?: Array<number> | null } | null;
    } | null;
  } | null;
};

export type SubmitRemittanceMutationVariables = Exact<{
  remittance_creation_path: RemittanceCreationPathType;
  invoice_id: Scalars['String'];
  bill_id: Scalars['String'];
  external_account_id: Scalars['String'];
}>;

export type SubmitRemittanceMutation = {
  __typename?: 'Mutation';
  submitRemittance?: {
    __typename?: 'Remittance';
    bill_reference_id: string;
    amount_pennies: number;
    bill_reference_type: BillReferenceType;
    due_date: string;
    from_ledger_account: string;
    from_payer_id: string;
    remittance_state: RemittanceState;
    service_fee_amount_pennies?: number | null;
    to_ledger_account: string;
    to_payee_id: string;
  } | null;
};

export type RemovePayMethodMutationVariables = Exact<{
  party_id: Scalars['ID'];
  account_id: Scalars['ID'];
}>;

export type RemovePayMethodMutation = {
  __typename?: 'Mutation';
  deleteAccount?: { __typename?: 'FinancialAccount'; account_id: string; status: FinancialAccountStatus } | null;
};

export type RemoveStripePayMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveStripePayMethodMutation = {
  __typename?: 'Mutation';
  stripeRemovePaymentMethod?: { __typename?: 'DeletedBoolResponse'; deleted?: boolean | null } | null;
};

export type GetStripePublicKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetStripePublicKeyQuery = {
  __typename?: 'Query';
  stripe_config?: { __typename?: 'StripeConfig'; public_key?: string | null } | null;
};

export type SetupIntentMutationVariables = Exact<{
  party_id: Scalars['ID'];
}>;

export type SetupIntentMutation = {
  __typename?: 'Mutation';
  stripeSetupIntent?: { __typename?: 'SetupIntent'; id: string; clientSecret: string } | null;
};

export const IPolicyFragmentDoc = gql`
  fragment IPolicy on Policy {
    policy_type
    accepted
  }
`;
export const IPersonFragmentDoc = gql`
  fragment IPerson on Person {
    policies {
      ...IPolicy
    }
  }
  ${IPolicyFragmentDoc}
`;
export const ReferenceAccountFragmentDoc = gql`
  fragment ReferenceAccount on ComplianceReference {
    decision
    reference_type
    effective_dates {
      from_date
    }
  }
`;
export const IPostalAddressFragmentDoc = gql`
  fragment IPostalAddress on PostalAddress {
    street_address1(detokenize: true)
    street_address2(detokenize: true)
    unit_number
    city_town
    state_province
    postal_code
  }
`;
export const IPartyContactFragmentDoc = gql`
  fragment IPartyContact on PartyContact {
    is_primary
    effective_dates {
      from_date
      to_date
    }
    purpose
    contact {
      postal_address {
        ...IPostalAddress
      }
      phone_number {
        phone(detokenize: true)
      }
      email_address {
        email(detokenize: true)
      }
    }
  }
  ${IPostalAddressFragmentDoc}
`;
export const IComplianceReferenceFragmentDoc = gql`
  fragment IComplianceReference on ComplianceReference {
    reference_id
    reference_type
    decision
    effective_dates {
      from_date
      to_date
    }
  }
`;
export const IComplianceInfoFragmentDoc = gql`
  fragment IComplianceInfo on ComplianceInfo {
    _id
    for_party_id
    references {
      ...IComplianceReference
    }
  }
  ${IComplianceReferenceFragmentDoc}
`;
export const IServiceLineFragmentDoc = gql`
  fragment IServiceLine on ServiceLine {
    charge_pennies
    rendering_provider_id
    raw_charged_code
    rendering_provider {
      party {
        person {
          current_name {
            names(detokenize: true)
          }
        }
      }
    }
    charged_code {
      code
      codeset
      medical_code {
        cpt_code_meta_data {
          long_description
        }
      }
    }
  }
`;
export const IBillFragmentDoc = gql`
  fragment IBill on Bill {
    id
    bill_state
    bill_type
    bill_total
    cash_payment
    payment_method
    payment_method_account {
      account_type
      account_id
      bank_account {
        account_number_last_four
        external_type
      }
    }
    payment_method_stripe {
      id
      brand
      last4
    }
    bill_total_pennies
    service_fee_pennies
    audit_logs {
      transitioned_at_time
      remittance_state_transition {
        from_state
        to_state
      }
    }
  }
`;
export const IBankAccountFragmentDoc = gql`
  fragment IBankAccount on BankAccount {
    name
    nick_name
    external_type
    account_number_last_four
    bank_name
    routing_number
  }
`;
export const ICardDataFragmentDoc = gql`
  fragment ICardData on CardData {
    id
    last4
    brand
    exp_month
    exp_year
  }
`;
export const IFinancialAccountFragmentDoc = gql`
  fragment IFinancialAccount on FinancialAccount {
    account_id
    account_type
    bank_account {
      ...IBankAccount
    }
    stripe_customer_account {
      ...ICardData
    }
    status
  }
  ${IBankAccountFragmentDoc}
  ${ICardDataFragmentDoc}
`;
export const IPartyFragmentDoc = gql`
  fragment IParty on Party {
    person {
      current_name {
        names(detokenize: true)
      }
    }
    accounts {
      accounts {
        ...IFinancialAccount
      }
    }
  }
  ${IFinancialAccountFragmentDoc}
`;
export const IEncounterFragmentDoc = gql`
  fragment IEncounter on Encounter {
    practice_facility_id
    practice_facility {
      facility_model {
        name
      }
    }
    service {
      service_time
    }
    member {
      party {
        ...IParty
      }
    }
  }
  ${IPartyFragmentDoc}
`;
export const IInvoiceFragmentDoc = gql`
  fragment IInvoice on Invoice {
    _id
    line_items {
      ...IServiceLine
    }
    bills(bill_type: $bill_type, bill_state: $bill_state) {
      ...IBill
    }
    invoice_state
    primary_member_id
    encounter_id
    encounter {
      ...IEncounter
    }
    patient_member_id
  }
  ${IServiceLineFragmentDoc}
  ${IBillFragmentDoc}
  ${IEncounterFragmentDoc}
`;
export const StripeFieldsFragmentDoc = gql`
  fragment StripeFields on CardData {
    id
    exp_month
    exp_year
    account_type
    brand
    funding
    audit {
      created_at
    }
    last4
  }
`;
export const BankAccountFieldsFragmentDoc = gql`
  fragment BankAccountFields on BankAccount {
    name
    bank_name
    account_number_last_four
    external_type
    routing_number
  }
`;
export const IPracticeFacilityFragmentDoc = gql`
  fragment IPracticeFacility on PracticeFacility {
    _id
    name
    facility_model {
      _id
      name
      location {
        postal_address {
          street_address1(detokenize: true)
          street_address2(detokenize: true)
          unit_number
          city_town
          state_province
          postal_code
        }
        geolocation {
          coordinates
        }
      }
    }
  }
`;
export const IPracticeFacilityInAreaFragmentDoc = gql`
  fragment IPracticeFacilityInArea on PracticeFacilityInAreaResponse {
    distance
    practice_facility {
      ...IPracticeFacility
    }
  }
  ${IPracticeFacilityFragmentDoc}
`;
export const AcceptPolicyDocument = gql`
  mutation acceptPolicy($party_id: ID!, $policy_type: PolicyType!) {
    acceptPolicy(partyId: $party_id, policyType: $policy_type) {
      person {
        ...IPerson
      }
    }
  }
  ${IPersonFragmentDoc}
`;
export type AcceptPolicyMutationFn = Apollo.MutationFunction<AcceptPolicyMutation, AcceptPolicyMutationVariables>;

/**
 * __useAcceptPolicyMutation__
 *
 * To run a mutation, you first call `useAcceptPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPolicyMutation, { data, loading, error }] = useAcceptPolicyMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *      policy_type: // value for 'policy_type'
 *   },
 * });
 */
export function useAcceptPolicyMutation(baseOptions?: Apollo.MutationHookOptions<AcceptPolicyMutation, AcceptPolicyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptPolicyMutation, AcceptPolicyMutationVariables>(AcceptPolicyDocument, options);
}
export type AcceptPolicyMutationHookResult = ReturnType<typeof useAcceptPolicyMutation>;
export type AcceptPolicyMutationResult = Apollo.MutationResult<AcceptPolicyMutation>;
export type AcceptPolicyMutationOptions = Apollo.BaseMutationOptions<AcceptPolicyMutation, AcceptPolicyMutationVariables>;
export const AddEmailDocument = gql`
  mutation addEmail($partyId: ID!, $email: AddEmailInput!) {
    addEmail(partyId: $partyId, email: $email) {
      result
      party {
        _id
        contacts {
          contact {
            _id
            email_address {
              email(detokenize: true)
            }
          }
        }
      }
    }
  }
`;
export type AddEmailMutationFn = Apollo.MutationFunction<AddEmailMutation, AddEmailMutationVariables>;

/**
 * __useAddEmailMutation__
 *
 * To run a mutation, you first call `useAddEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailMutation, { data, loading, error }] = useAddEmailMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddEmailMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailMutation, AddEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEmailMutation, AddEmailMutationVariables>(AddEmailDocument, options);
}
export type AddEmailMutationHookResult = ReturnType<typeof useAddEmailMutation>;
export type AddEmailMutationResult = Apollo.MutationResult<AddEmailMutation>;
export type AddEmailMutationOptions = Apollo.BaseMutationOptions<AddEmailMutation, AddEmailMutationVariables>;
export const AddSsnDocument = gql`
  mutation addSsn($party_id: ID!, $ssn_number: String!) {
    addGovernmentId(partyID: $party_id, governmentId: { id_type: SOCIAL_SECURITY, id_number: $ssn_number }) {
      party_type
    }
  }
`;
export type AddSsnMutationFn = Apollo.MutationFunction<AddSsnMutation, AddSsnMutationVariables>;

/**
 * __useAddSsnMutation__
 *
 * To run a mutation, you first call `useAddSsnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSsnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSsnMutation, { data, loading, error }] = useAddSsnMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *      ssn_number: // value for 'ssn_number'
 *   },
 * });
 */
export function useAddSsnMutation(baseOptions?: Apollo.MutationHookOptions<AddSsnMutation, AddSsnMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSsnMutation, AddSsnMutationVariables>(AddSsnDocument, options);
}
export type AddSsnMutationHookResult = ReturnType<typeof useAddSsnMutation>;
export type AddSsnMutationResult = Apollo.MutationResult<AddSsnMutation>;
export type AddSsnMutationOptions = Apollo.BaseMutationOptions<AddSsnMutation, AddSsnMutationVariables>;
export const CheckSsnDocument = gql`
  query checkSSN($id: ID!) {
    party(id: $id) {
      government_ids(id_type: [SOCIAL_SECURITY]) {
        id_type
      }
    }
  }
`;

/**
 * __useCheckSsnQuery__
 *
 * To run a query within a React component, call `useCheckSsnQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSsnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSsnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckSsnQuery(baseOptions: Apollo.QueryHookOptions<CheckSsnQuery, CheckSsnQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckSsnQuery, CheckSsnQueryVariables>(CheckSsnDocument, options);
}
export function useCheckSsnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckSsnQuery, CheckSsnQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckSsnQuery, CheckSsnQueryVariables>(CheckSsnDocument, options);
}
export type CheckSsnQueryHookResult = ReturnType<typeof useCheckSsnQuery>;
export type CheckSsnLazyQueryHookResult = ReturnType<typeof useCheckSsnLazyQuery>;
export type CheckSsnQueryResult = Apollo.QueryResult<CheckSsnQuery, CheckSsnQueryVariables>;
export const GetAlloyAndBankStatusesDocument = gql`
  query getAlloyAndBankStatuses($id: ID!) {
    complianceInfoForParty(forPartyId: $id) {
      references {
        ...ReferenceAccount
      }
    }
  }
  ${ReferenceAccountFragmentDoc}
`;

/**
 * __useGetAlloyAndBankStatusesQuery__
 *
 * To run a query within a React component, call `useGetAlloyAndBankStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlloyAndBankStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlloyAndBankStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAlloyAndBankStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAlloyAndBankStatusesQuery, GetAlloyAndBankStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlloyAndBankStatusesQuery, GetAlloyAndBankStatusesQueryVariables>(GetAlloyAndBankStatusesDocument, options);
}
export function useGetAlloyAndBankStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAlloyAndBankStatusesQuery, GetAlloyAndBankStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAlloyAndBankStatusesQuery, GetAlloyAndBankStatusesQueryVariables>(GetAlloyAndBankStatusesDocument, options);
}
export type GetAlloyAndBankStatusesQueryHookResult = ReturnType<typeof useGetAlloyAndBankStatusesQuery>;
export type GetAlloyAndBankStatusesLazyQueryHookResult = ReturnType<typeof useGetAlloyAndBankStatusesLazyQuery>;
export type GetAlloyAndBankStatusesQueryResult = Apollo.QueryResult<GetAlloyAndBankStatusesQuery, GetAlloyAndBankStatusesQueryVariables>;
export const GetDependentByIdDocument = gql`
  query getDependentById($id: ID!) {
    member(id: $id) {
      _id
      member_relationship
      party {
        _id
        person {
          current_name {
            names(detokenize: true)
            preferred_name(detokenize: true)
          }
          birth_date
        }
        contacts {
          ...IPartyContact
        }
      }
    }
  }
  ${IPartyContactFragmentDoc}
`;

/**
 * __useGetDependentByIdQuery__
 *
 * To run a query within a React component, call `useGetDependentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDependentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDependentByIdQuery, GetDependentByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDependentByIdQuery, GetDependentByIdQueryVariables>(GetDependentByIdDocument, options);
}
export function useGetDependentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDependentByIdQuery, GetDependentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDependentByIdQuery, GetDependentByIdQueryVariables>(GetDependentByIdDocument, options);
}
export type GetDependentByIdQueryHookResult = ReturnType<typeof useGetDependentByIdQuery>;
export type GetDependentByIdLazyQueryHookResult = ReturnType<typeof useGetDependentByIdLazyQuery>;
export type GetDependentByIdQueryResult = Apollo.QueryResult<GetDependentByIdQuery, GetDependentByIdQueryVariables>;
export const GetDependentsDocument = gql`
  query GetDependents($member: MemberInput!) {
    memberByFields(member: $member) {
      dependent_members {
        _id
        member_relationship
        party {
          _id
          person {
            current_name {
              names(detokenize: true)
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetDependentsQuery__
 *
 * To run a query within a React component, call `useGetDependentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependentsQuery({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useGetDependentsQuery(baseOptions: Apollo.QueryHookOptions<GetDependentsQuery, GetDependentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDependentsQuery, GetDependentsQueryVariables>(GetDependentsDocument, options);
}
export function useGetDependentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDependentsQuery, GetDependentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDependentsQuery, GetDependentsQueryVariables>(GetDependentsDocument, options);
}
export type GetDependentsQueryHookResult = ReturnType<typeof useGetDependentsQuery>;
export type GetDependentsLazyQueryHookResult = ReturnType<typeof useGetDependentsLazyQuery>;
export type GetDependentsQueryResult = Apollo.QueryResult<GetDependentsQuery, GetDependentsQueryVariables>;
export const GetTermsAcceptanceStatusForUserDocument = gql`
  query getTermsAcceptanceStatusForUser($id: ID!) {
    party(id: $id) {
      _id
      person {
        policies {
          policy_type
          accepted
        }
      }
    }
  }
`;

/**
 * __useGetTermsAcceptanceStatusForUserQuery__
 *
 * To run a query within a React component, call `useGetTermsAcceptanceStatusForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsAcceptanceStatusForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsAcceptanceStatusForUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTermsAcceptanceStatusForUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetTermsAcceptanceStatusForUserQuery, GetTermsAcceptanceStatusForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTermsAcceptanceStatusForUserQuery, GetTermsAcceptanceStatusForUserQueryVariables>(
    GetTermsAcceptanceStatusForUserDocument,
    options
  );
}
export function useGetTermsAcceptanceStatusForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTermsAcceptanceStatusForUserQuery, GetTermsAcceptanceStatusForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTermsAcceptanceStatusForUserQuery, GetTermsAcceptanceStatusForUserQueryVariables>(
    GetTermsAcceptanceStatusForUserDocument,
    options
  );
}
export type GetTermsAcceptanceStatusForUserQueryHookResult = ReturnType<typeof useGetTermsAcceptanceStatusForUserQuery>;
export type GetTermsAcceptanceStatusForUserLazyQueryHookResult = ReturnType<typeof useGetTermsAcceptanceStatusForUserLazyQuery>;
export type GetTermsAcceptanceStatusForUserQueryResult = Apollo.QueryResult<
  GetTermsAcceptanceStatusForUserQuery,
  GetTermsAcceptanceStatusForUserQueryVariables
>;
export const GetUserNameByPartyIdDocument = gql`
  query getUserNameByPartyId($id: ID!) {
    party(id: $id) {
      person {
        current_name {
          names(detokenize: true)
        }
      }
    }
  }
`;

/**
 * __useGetUserNameByPartyIdQuery__
 *
 * To run a query within a React component, call `useGetUserNameByPartyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNameByPartyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNameByPartyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserNameByPartyIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserNameByPartyIdQuery, GetUserNameByPartyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserNameByPartyIdQuery, GetUserNameByPartyIdQueryVariables>(GetUserNameByPartyIdDocument, options);
}
export function useGetUserNameByPartyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserNameByPartyIdQuery, GetUserNameByPartyIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserNameByPartyIdQuery, GetUserNameByPartyIdQueryVariables>(GetUserNameByPartyIdDocument, options);
}
export type GetUserNameByPartyIdQueryHookResult = ReturnType<typeof useGetUserNameByPartyIdQuery>;
export type GetUserNameByPartyIdLazyQueryHookResult = ReturnType<typeof useGetUserNameByPartyIdLazyQuery>;
export type GetUserNameByPartyIdQueryResult = Apollo.QueryResult<GetUserNameByPartyIdQuery, GetUserNameByPartyIdQueryVariables>;
export const GetPartyByIdDocument = gql`
  query getPartyById($id: ID!) {
    party(id: $id) {
      person {
        current_name {
          names(detokenize: true)
          preferred_name(detokenize: true)
        }
        birth_date
      }
      contacts {
        ...IPartyContact
      }
    }
  }
  ${IPartyContactFragmentDoc}
`;

/**
 * __useGetPartyByIdQuery__
 *
 * To run a query within a React component, call `useGetPartyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPartyByIdQuery, GetPartyByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartyByIdQuery, GetPartyByIdQueryVariables>(GetPartyByIdDocument, options);
}
export function useGetPartyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartyByIdQuery, GetPartyByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartyByIdQuery, GetPartyByIdQueryVariables>(GetPartyByIdDocument, options);
}
export type GetPartyByIdQueryHookResult = ReturnType<typeof useGetPartyByIdQuery>;
export type GetPartyByIdLazyQueryHookResult = ReturnType<typeof useGetPartyByIdLazyQuery>;
export type GetPartyByIdQueryResult = Apollo.QueryResult<GetPartyByIdQuery, GetPartyByIdQueryVariables>;
export const TriggerAlloyCheckDocument = gql`
  mutation triggerAlloyCheck($party_id: ID!) {
    triggerAlloyCheck(partyID: $party_id) {
      _id
    }
  }
`;
export type TriggerAlloyCheckMutationFn = Apollo.MutationFunction<TriggerAlloyCheckMutation, TriggerAlloyCheckMutationVariables>;

/**
 * __useTriggerAlloyCheckMutation__
 *
 * To run a mutation, you first call `useTriggerAlloyCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerAlloyCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerAlloyCheckMutation, { data, loading, error }] = useTriggerAlloyCheckMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *   },
 * });
 */
export function useTriggerAlloyCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<TriggerAlloyCheckMutation, TriggerAlloyCheckMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerAlloyCheckMutation, TriggerAlloyCheckMutationVariables>(TriggerAlloyCheckDocument, options);
}
export type TriggerAlloyCheckMutationHookResult = ReturnType<typeof useTriggerAlloyCheckMutation>;
export type TriggerAlloyCheckMutationResult = Apollo.MutationResult<TriggerAlloyCheckMutation>;
export type TriggerAlloyCheckMutationOptions = Apollo.BaseMutationOptions<TriggerAlloyCheckMutation, TriggerAlloyCheckMutationVariables>;
export const UpdateContactInfoDocument = gql`
  mutation updateContactInfo(
    $party_id: ID!
    $preferred_name: String
    $email: String!
    $postal_address: String!
    $city: String!
    $state: String!
    $zip: String!
    $phone_number: String!
  ) {
    updateContactInfo(
      partyId: $party_id
      contactInfoInput: {
        preferred_name: $preferred_name
        email: $email
        postal_address: { street_address1: $postal_address, city_town: $city, state_province: $state, postal_code: $zip }
        phone_number: $phone_number
      }
    ) {
      _id
      contacts {
        is_primary
        effective_dates {
          from_date
          to_date
        }
        purpose
        contact {
          postal_address {
            street_address1(detokenize: true)
            street_address2(detokenize: true)
            city_town
            state_province
            postal_code
          }
          phone_number {
            phone(detokenize: true)
          }
          email_address {
            email(detokenize: true)
          }
        }
      }
    }
  }
`;
export type UpdateContactInfoMutationFn = Apollo.MutationFunction<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;

/**
 * __useUpdateContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInfoMutation, { data, loading, error }] = useUpdateContactInfoMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *      preferred_name: // value for 'preferred_name'
 *      email: // value for 'email'
 *      postal_address: // value for 'postal_address'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useUpdateContactInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>(UpdateContactInfoDocument, options);
}
export type UpdateContactInfoMutationHookResult = ReturnType<typeof useUpdateContactInfoMutation>;
export type UpdateContactInfoMutationResult = Apollo.MutationResult<UpdateContactInfoMutation>;
export type UpdateContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;
export const BillByIdDocument = gql`
  query billById($invoice_id: ID!) {
    invoiceById(id: $invoice_id) {
      _id
      invoice_state
      invoice_total_pennies
      service_fee_pennies
      line_items {
        quantity
        long_tw_desc
        ...IServiceLine
      }
      encounter {
        ...IEncounter
      }
      bills {
        ...IBill
      }
    }
  }
  ${IServiceLineFragmentDoc}
  ${IEncounterFragmentDoc}
  ${IBillFragmentDoc}
`;

/**
 * __useBillByIdQuery__
 *
 * To run a query within a React component, call `useBillByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillByIdQuery({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useBillByIdQuery(baseOptions: Apollo.QueryHookOptions<BillByIdQuery, BillByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillByIdQuery, BillByIdQueryVariables>(BillByIdDocument, options);
}
export function useBillByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillByIdQuery, BillByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillByIdQuery, BillByIdQueryVariables>(BillByIdDocument, options);
}
export type BillByIdQueryHookResult = ReturnType<typeof useBillByIdQuery>;
export type BillByIdLazyQueryHookResult = ReturnType<typeof useBillByIdLazyQuery>;
export type BillByIdQueryResult = Apollo.QueryResult<BillByIdQuery, BillByIdQueryVariables>;
export const GetInvoicesDocument = gql`
  query GetInvoices($filter: InvoicesFilter!, $options: Options, $bill_type: [BillType!], $bill_state: [BillState!]) {
    invoices(filter: $filter, options: $options) {
      offset
      total
      results {
        ...IInvoice
      }
    }
  }
  ${IInvoiceFragmentDoc}
`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      options: // value for 'options'
 *      bill_type: // value for 'bill_type'
 *      bill_state: // value for 'bill_state'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
}
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const ComplianceInfoForPartyDocument = gql`
  query complianceInfoForParty($party_id: ID!) {
    complianceInfoForParty(forPartyId: $party_id) {
      ...IComplianceInfo
    }
  }
  ${IComplianceInfoFragmentDoc}
`;

/**
 * __useComplianceInfoForPartyQuery__
 *
 * To run a query within a React component, call `useComplianceInfoForPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceInfoForPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceInfoForPartyQuery({
 *   variables: {
 *      party_id: // value for 'party_id'
 *   },
 * });
 */
export function useComplianceInfoForPartyQuery(
  baseOptions: Apollo.QueryHookOptions<ComplianceInfoForPartyQuery, ComplianceInfoForPartyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplianceInfoForPartyQuery, ComplianceInfoForPartyQueryVariables>(ComplianceInfoForPartyDocument, options);
}
export function useComplianceInfoForPartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ComplianceInfoForPartyQuery, ComplianceInfoForPartyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ComplianceInfoForPartyQuery, ComplianceInfoForPartyQueryVariables>(ComplianceInfoForPartyDocument, options);
}
export type ComplianceInfoForPartyQueryHookResult = ReturnType<typeof useComplianceInfoForPartyQuery>;
export type ComplianceInfoForPartyLazyQueryHookResult = ReturnType<typeof useComplianceInfoForPartyLazyQuery>;
export type ComplianceInfoForPartyQueryResult = Apollo.QueryResult<ComplianceInfoForPartyQuery, ComplianceInfoForPartyQueryVariables>;
export const GetMemberIdCardUrlDocument = gql`
  query GetMemberIdCardURL($memberId: ID!) {
    memberCardUrl(id: $memberId)
  }
`;

/**
 * __useGetMemberIdCardUrlQuery__
 *
 * To run a query within a React component, call `useGetMemberIdCardUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberIdCardUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberIdCardUrlQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetMemberIdCardUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetMemberIdCardUrlQuery, GetMemberIdCardUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberIdCardUrlQuery, GetMemberIdCardUrlQueryVariables>(GetMemberIdCardUrlDocument, options);
}
export function useGetMemberIdCardUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMemberIdCardUrlQuery, GetMemberIdCardUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberIdCardUrlQuery, GetMemberIdCardUrlQueryVariables>(GetMemberIdCardUrlDocument, options);
}
export type GetMemberIdCardUrlQueryHookResult = ReturnType<typeof useGetMemberIdCardUrlQuery>;
export type GetMemberIdCardUrlLazyQueryHookResult = ReturnType<typeof useGetMemberIdCardUrlLazyQuery>;
export type GetMemberIdCardUrlQueryResult = Apollo.QueryResult<GetMemberIdCardUrlQuery, GetMemberIdCardUrlQueryVariables>;
export const MemberByFieldDocument = gql`
  query MemberByField($member: MemberInput!) {
    memberByFields(member: $member) {
      _id
      subscriber_id
      plan_code
      party {
        person {
          current_name {
            names(detokenize: true)
          }
        }
      }
      card_id
      coverage_start_date
      dependent_members {
        party {
          person {
            current_name {
              names(detokenize: true)
            }
          }
        }
        subscriber_id
        card_id
      }
      sponsor {
        plans {
          group_id
          group_name
          member_ids
        }
      }
      dependents
    }
  }
`;

/**
 * __useMemberByFieldQuery__
 *
 * To run a query within a React component, call `useMemberByFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberByFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberByFieldQuery({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useMemberByFieldQuery(baseOptions: Apollo.QueryHookOptions<MemberByFieldQuery, MemberByFieldQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberByFieldQuery, MemberByFieldQueryVariables>(MemberByFieldDocument, options);
}
export function useMemberByFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberByFieldQuery, MemberByFieldQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberByFieldQuery, MemberByFieldQueryVariables>(MemberByFieldDocument, options);
}
export type MemberByFieldQueryHookResult = ReturnType<typeof useMemberByFieldQuery>;
export type MemberByFieldLazyQueryHookResult = ReturnType<typeof useMemberByFieldLazyQuery>;
export type MemberByFieldQueryResult = Apollo.QueryResult<MemberByFieldQuery, MemberByFieldQueryVariables>;
export const PayBillDocument = gql`
  mutation payBill($invoice_id: ID!, $bill_id: ID!, $payment_method_id: ID!) {
    payBill(invoiceId: $invoice_id, billId: $bill_id, paymentMethodId: $payment_method_id) {
      bill_reference_id
      amount_pennies
      bill_reference_type
      due_date
      from_ledger_account
      from_payer_id
      remittance_state
      service_fee_amount_pennies
      to_ledger_account
      to_payee_id
    }
  }
`;
export type PayBillMutationFn = Apollo.MutationFunction<PayBillMutation, PayBillMutationVariables>;

/**
 * __usePayBillMutation__
 *
 * To run a mutation, you first call `usePayBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payBillMutation, { data, loading, error }] = usePayBillMutation({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *      bill_id: // value for 'bill_id'
 *      payment_method_id: // value for 'payment_method_id'
 *   },
 * });
 */
export function usePayBillMutation(baseOptions?: Apollo.MutationHookOptions<PayBillMutation, PayBillMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayBillMutation, PayBillMutationVariables>(PayBillDocument, options);
}
export type PayBillMutationHookResult = ReturnType<typeof usePayBillMutation>;
export type PayBillMutationResult = Apollo.MutationResult<PayBillMutation>;
export type PayBillMutationOptions = Apollo.BaseMutationOptions<PayBillMutation, PayBillMutationVariables>;
export const PayBillWithCreditCardDocument = gql`
  mutation payBillWithCreditCard($party_id: ID!, $bill_id: ID!, $payment_method_id: String!) {
    createPaymentIntent(partyId: $party_id, billId: $bill_id, paymentMethodId: $payment_method_id, confirm: true) {
      amount
      amountReceived
      created
      currency
      description
      id
      paymentMethodTypes
      status
    }
  }
`;
export type PayBillWithCreditCardMutationFn = Apollo.MutationFunction<
  PayBillWithCreditCardMutation,
  PayBillWithCreditCardMutationVariables
>;

/**
 * __usePayBillWithCreditCardMutation__
 *
 * To run a mutation, you first call `usePayBillWithCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayBillWithCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payBillWithCreditCardMutation, { data, loading, error }] = usePayBillWithCreditCardMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *      bill_id: // value for 'bill_id'
 *      payment_method_id: // value for 'payment_method_id'
 *   },
 * });
 */
export function usePayBillWithCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<PayBillWithCreditCardMutation, PayBillWithCreditCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayBillWithCreditCardMutation, PayBillWithCreditCardMutationVariables>(PayBillWithCreditCardDocument, options);
}
export type PayBillWithCreditCardMutationHookResult = ReturnType<typeof usePayBillWithCreditCardMutation>;
export type PayBillWithCreditCardMutationResult = Apollo.MutationResult<PayBillWithCreditCardMutation>;
export type PayBillWithCreditCardMutationOptions = Apollo.BaseMutationOptions<
  PayBillWithCreditCardMutation,
  PayBillWithCreditCardMutationVariables
>;
export const BankAccountOnBoardingDocument = gql`
  mutation bankAccountOnBoarding($bankAccountOnboardingRequest: BankAccountOnBoardingInput!) {
    bankAccountOnBoarding(bankAccountOnboardingRequest: $bankAccountOnboardingRequest) {
      account_id
      is_onboarding
      status
    }
  }
`;
export type BankAccountOnBoardingMutationFn = Apollo.MutationFunction<
  BankAccountOnBoardingMutation,
  BankAccountOnBoardingMutationVariables
>;

/**
 * __useBankAccountOnBoardingMutation__
 *
 * To run a mutation, you first call `useBankAccountOnBoardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankAccountOnBoardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankAccountOnBoardingMutation, { data, loading, error }] = useBankAccountOnBoardingMutation({
 *   variables: {
 *      bankAccountOnboardingRequest: // value for 'bankAccountOnboardingRequest'
 *   },
 * });
 */
export function useBankAccountOnBoardingMutation(
  baseOptions?: Apollo.MutationHookOptions<BankAccountOnBoardingMutation, BankAccountOnBoardingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BankAccountOnBoardingMutation, BankAccountOnBoardingMutationVariables>(BankAccountOnBoardingDocument, options);
}
export type BankAccountOnBoardingMutationHookResult = ReturnType<typeof useBankAccountOnBoardingMutation>;
export type BankAccountOnBoardingMutationResult = Apollo.MutationResult<BankAccountOnBoardingMutation>;
export type BankAccountOnBoardingMutationOptions = Apollo.BaseMutationOptions<
  BankAccountOnBoardingMutation,
  BankAccountOnBoardingMutationVariables
>;
export const GetPaymentMethodsDocument = gql`
  query GetPaymentMethods($id: ID!) {
    party(id: $id) {
      party_type
      accounts {
        accounts(account_types: [STRIPE_CUSTOMER, EXTERNAL_BANK]) {
          account_id
          account_type
          status
          audit {
            created_at
          }
          stripe_customer_account {
            ...StripeFields
          }
          bank_account {
            ...BankAccountFields
          }
        }
      }
    }
  }
  ${StripeFieldsFragmentDoc}
  ${BankAccountFieldsFragmentDoc}
`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
}
export function useGetPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
}
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export const GetPlanUtilizationDocument = gql`
  query GetPlanUtilization($id: ID!) {
    party(id: $id) {
      memberships {
        plan_utilizations {
          _id
          utilizations {
            hash_code
            amount_used_pennies
            amount_pennies_remaining
            quantity_remaining
            quantity_used
            reference_code {
              ... on MedicalCodeGroup {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPlanUtilizationQuery__
 *
 * To run a query within a React component, call `useGetPlanUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanUtilizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanUtilizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlanUtilizationQuery, GetPlanUtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanUtilizationQuery, GetPlanUtilizationQueryVariables>(GetPlanUtilizationDocument, options);
}
export function useGetPlanUtilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlanUtilizationQuery, GetPlanUtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanUtilizationQuery, GetPlanUtilizationQueryVariables>(GetPlanUtilizationDocument, options);
}
export type GetPlanUtilizationQueryHookResult = ReturnType<typeof useGetPlanUtilizationQuery>;
export type GetPlanUtilizationLazyQueryHookResult = ReturnType<typeof useGetPlanUtilizationLazyQuery>;
export type GetPlanUtilizationQueryResult = Apollo.QueryResult<GetPlanUtilizationQuery, GetPlanUtilizationQueryVariables>;
export const GetPracticeFacilityByIdDocument = gql`
  query getPracticeFacilityById($practiceFacilityId: ID!) {
    practiceFacility(id: $practiceFacilityId) {
      name
      street_address_2
      facility_model {
        name
        location {
          postal_address {
            street_address1(detokenize: true)
            city_town
            state_province
            postal_code
          }
        }
      }
      providers_at_facility {
        provider {
          party {
            person {
              current_name {
                names(detokenize: true)
              }
            }
          }
          type
        }
      }
    }
  }
`;

/**
 * __useGetPracticeFacilityByIdQuery__
 *
 * To run a query within a React component, call `useGetPracticeFacilityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeFacilityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeFacilityByIdQuery({
 *   variables: {
 *      practiceFacilityId: // value for 'practiceFacilityId'
 *   },
 * });
 */
export function useGetPracticeFacilityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPracticeFacilityByIdQuery, GetPracticeFacilityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPracticeFacilityByIdQuery, GetPracticeFacilityByIdQueryVariables>(GetPracticeFacilityByIdDocument, options);
}
export function useGetPracticeFacilityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPracticeFacilityByIdQuery, GetPracticeFacilityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPracticeFacilityByIdQuery, GetPracticeFacilityByIdQueryVariables>(GetPracticeFacilityByIdDocument, options);
}
export type GetPracticeFacilityByIdQueryHookResult = ReturnType<typeof useGetPracticeFacilityByIdQuery>;
export type GetPracticeFacilityByIdLazyQueryHookResult = ReturnType<typeof useGetPracticeFacilityByIdLazyQuery>;
export type GetPracticeFacilityByIdQueryResult = Apollo.QueryResult<GetPracticeFacilityByIdQuery, GetPracticeFacilityByIdQueryVariables>;
export const GetNetworkSpecialtiesDocument = gql`
  query getNetworkSpecialties($id: ID!) {
    getNetworkSpecialties(id: $id) {
      specialty
      taxonomies
    }
  }
`;

/**
 * __useGetNetworkSpecialtiesQuery__
 *
 * To run a query within a React component, call `useGetNetworkSpecialtiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkSpecialtiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkSpecialtiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNetworkSpecialtiesQuery(
  baseOptions: Apollo.QueryHookOptions<GetNetworkSpecialtiesQuery, GetNetworkSpecialtiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNetworkSpecialtiesQuery, GetNetworkSpecialtiesQueryVariables>(GetNetworkSpecialtiesDocument, options);
}
export function useGetNetworkSpecialtiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNetworkSpecialtiesQuery, GetNetworkSpecialtiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNetworkSpecialtiesQuery, GetNetworkSpecialtiesQueryVariables>(GetNetworkSpecialtiesDocument, options);
}
export type GetNetworkSpecialtiesQueryHookResult = ReturnType<typeof useGetNetworkSpecialtiesQuery>;
export type GetNetworkSpecialtiesLazyQueryHookResult = ReturnType<typeof useGetNetworkSpecialtiesLazyQuery>;
export type GetNetworkSpecialtiesQueryResult = Apollo.QueryResult<GetNetworkSpecialtiesQuery, GetNetworkSpecialtiesQueryVariables>;
export const GetPracticeFacilitiesInAreaDocument = gql`
  query getPracticeFacilitiesInArea($zip_code: String!, $search_radius_in_meters: Int!, $taxonomies: [String!]) {
    practiceFacilitiesInArea(
      filter: { area: { zip: $zip_code, distance: $search_radius_in_meters }, taxonomies: $taxonomies }
      options: { limit: 10000, offset: 0, sort: { field: "distance", direction: asc } }
    ) {
      ...IPracticeFacilityInArea
    }
  }
  ${IPracticeFacilityInAreaFragmentDoc}
`;

/**
 * __useGetPracticeFacilitiesInAreaQuery__
 *
 * To run a query within a React component, call `useGetPracticeFacilitiesInAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeFacilitiesInAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeFacilitiesInAreaQuery({
 *   variables: {
 *      zip_code: // value for 'zip_code'
 *      search_radius_in_meters: // value for 'search_radius_in_meters'
 *      taxonomies: // value for 'taxonomies'
 *   },
 * });
 */
export function useGetPracticeFacilitiesInAreaQuery(
  baseOptions: Apollo.QueryHookOptions<GetPracticeFacilitiesInAreaQuery, GetPracticeFacilitiesInAreaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPracticeFacilitiesInAreaQuery, GetPracticeFacilitiesInAreaQueryVariables>(
    GetPracticeFacilitiesInAreaDocument,
    options
  );
}
export function useGetPracticeFacilitiesInAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPracticeFacilitiesInAreaQuery, GetPracticeFacilitiesInAreaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPracticeFacilitiesInAreaQuery, GetPracticeFacilitiesInAreaQueryVariables>(
    GetPracticeFacilitiesInAreaDocument,
    options
  );
}
export type GetPracticeFacilitiesInAreaQueryHookResult = ReturnType<typeof useGetPracticeFacilitiesInAreaQuery>;
export type GetPracticeFacilitiesInAreaLazyQueryHookResult = ReturnType<typeof useGetPracticeFacilitiesInAreaLazyQuery>;
export type GetPracticeFacilitiesInAreaQueryResult = Apollo.QueryResult<
  GetPracticeFacilitiesInAreaQuery,
  GetPracticeFacilitiesInAreaQueryVariables
>;
export const SubmitRemittanceDocument = gql`
  mutation submitRemittance(
    $remittance_creation_path: RemittanceCreationPathType!
    $invoice_id: String!
    $bill_id: String!
    $external_account_id: String!
  ) {
    submitRemittance(
      SubmitRemittanceInput: {
        remittance_creation_path: $remittance_creation_path
        invoice_id: $invoice_id
        bill_id: $bill_id
        external_account_id: $external_account_id
      }
    ) {
      bill_reference_id
      amount_pennies
      bill_reference_type
      due_date
      from_ledger_account
      from_payer_id
      remittance_state
      service_fee_amount_pennies
      to_ledger_account
      to_payee_id
    }
  }
`;
export type SubmitRemittanceMutationFn = Apollo.MutationFunction<SubmitRemittanceMutation, SubmitRemittanceMutationVariables>;

/**
 * __useSubmitRemittanceMutation__
 *
 * To run a mutation, you first call `useSubmitRemittanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRemittanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRemittanceMutation, { data, loading, error }] = useSubmitRemittanceMutation({
 *   variables: {
 *      remittance_creation_path: // value for 'remittance_creation_path'
 *      invoice_id: // value for 'invoice_id'
 *      bill_id: // value for 'bill_id'
 *      external_account_id: // value for 'external_account_id'
 *   },
 * });
 */
export function useSubmitRemittanceMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitRemittanceMutation, SubmitRemittanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitRemittanceMutation, SubmitRemittanceMutationVariables>(SubmitRemittanceDocument, options);
}
export type SubmitRemittanceMutationHookResult = ReturnType<typeof useSubmitRemittanceMutation>;
export type SubmitRemittanceMutationResult = Apollo.MutationResult<SubmitRemittanceMutation>;
export type SubmitRemittanceMutationOptions = Apollo.BaseMutationOptions<SubmitRemittanceMutation, SubmitRemittanceMutationVariables>;
export const RemovePayMethodDocument = gql`
  mutation removePayMethod($party_id: ID!, $account_id: ID!) {
    deleteAccount(partyID: $party_id, accountID: $account_id) {
      account_id
      status
    }
  }
`;
export type RemovePayMethodMutationFn = Apollo.MutationFunction<RemovePayMethodMutation, RemovePayMethodMutationVariables>;

/**
 * __useRemovePayMethodMutation__
 *
 * To run a mutation, you first call `useRemovePayMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePayMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePayMethodMutation, { data, loading, error }] = useRemovePayMethodMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *      account_id: // value for 'account_id'
 *   },
 * });
 */
export function useRemovePayMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePayMethodMutation, RemovePayMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePayMethodMutation, RemovePayMethodMutationVariables>(RemovePayMethodDocument, options);
}
export type RemovePayMethodMutationHookResult = ReturnType<typeof useRemovePayMethodMutation>;
export type RemovePayMethodMutationResult = Apollo.MutationResult<RemovePayMethodMutation>;
export type RemovePayMethodMutationOptions = Apollo.BaseMutationOptions<RemovePayMethodMutation, RemovePayMethodMutationVariables>;
export const RemoveStripePayMethodDocument = gql`
  mutation removeStripePayMethod($id: ID!) {
    stripeRemovePaymentMethod(id: $id) {
      deleted
    }
  }
`;
export type RemoveStripePayMethodMutationFn = Apollo.MutationFunction<
  RemoveStripePayMethodMutation,
  RemoveStripePayMethodMutationVariables
>;

/**
 * __useRemoveStripePayMethodMutation__
 *
 * To run a mutation, you first call `useRemoveStripePayMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStripePayMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStripePayMethodMutation, { data, loading, error }] = useRemoveStripePayMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveStripePayMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveStripePayMethodMutation, RemoveStripePayMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveStripePayMethodMutation, RemoveStripePayMethodMutationVariables>(RemoveStripePayMethodDocument, options);
}
export type RemoveStripePayMethodMutationHookResult = ReturnType<typeof useRemoveStripePayMethodMutation>;
export type RemoveStripePayMethodMutationResult = Apollo.MutationResult<RemoveStripePayMethodMutation>;
export type RemoveStripePayMethodMutationOptions = Apollo.BaseMutationOptions<
  RemoveStripePayMethodMutation,
  RemoveStripePayMethodMutationVariables
>;
export const GetStripePublicKeyDocument = gql`
  query GetStripePublicKey {
    stripe_config {
      public_key
    }
  }
`;

/**
 * __useGetStripePublicKeyQuery__
 *
 * To run a query within a React component, call `useGetStripePublicKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePublicKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePublicKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripePublicKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStripePublicKeyQuery, GetStripePublicKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStripePublicKeyQuery, GetStripePublicKeyQueryVariables>(GetStripePublicKeyDocument, options);
}
export function useGetStripePublicKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStripePublicKeyQuery, GetStripePublicKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStripePublicKeyQuery, GetStripePublicKeyQueryVariables>(GetStripePublicKeyDocument, options);
}
export type GetStripePublicKeyQueryHookResult = ReturnType<typeof useGetStripePublicKeyQuery>;
export type GetStripePublicKeyLazyQueryHookResult = ReturnType<typeof useGetStripePublicKeyLazyQuery>;
export type GetStripePublicKeyQueryResult = Apollo.QueryResult<GetStripePublicKeyQuery, GetStripePublicKeyQueryVariables>;
export const SetupIntentDocument = gql`
  mutation SetupIntent($party_id: ID!) {
    stripeSetupIntent(partyId: $party_id) {
      id
      clientSecret
    }
  }
`;
export type SetupIntentMutationFn = Apollo.MutationFunction<SetupIntentMutation, SetupIntentMutationVariables>;

/**
 * __useSetupIntentMutation__
 *
 * To run a mutation, you first call `useSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupIntentMutation, { data, loading, error }] = useSetupIntentMutation({
 *   variables: {
 *      party_id: // value for 'party_id'
 *   },
 * });
 */
export function useSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<SetupIntentMutation, SetupIntentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetupIntentMutation, SetupIntentMutationVariables>(SetupIntentDocument, options);
}
export type SetupIntentMutationHookResult = ReturnType<typeof useSetupIntentMutation>;
export type SetupIntentMutationResult = Apollo.MutationResult<SetupIntentMutation>;
export type SetupIntentMutationOptions = Apollo.BaseMutationOptions<SetupIntentMutation, SetupIntentMutationVariables>;
