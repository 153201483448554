import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage_keys } from '../../app/constants';
import { AddEmailResult, useAddEmailMutation, useTriggerAlloyCheckMutation } from '../../app/graphql/_generated/hooks';
import { authStorage } from '../../app/storageApi';
import GuttersContainer from '../../components/GuttersContainer';
import { PageWrapper } from '../../components/PageWrapper';
import LoadingScreen from '../../pages/Loading';
import { handleAuthRedirect } from './Auth.utils';

function Callback() {
  const navigate = useNavigate();

  const [alloyCheck] = useTriggerAlloyCheckMutation();
  const [addEmailMutation] = useAddEmailMutation();

  useEffect(() => {
    async function handleAuthCallback() {
      try {
        await handleAuthRedirect();

        navigate('/auth/terms');

        const partyId = authStorage.getItem(storage_keys.user_party_id_key);
        const email = authStorage.getItem(storage_keys.user_email_key);

        if (partyId && email) {
          try {
            const response = await addEmailMutation({ variables: { partyId, email: { email } } });
            const result = response.data?.addEmail?.result;

            if (!!result && result !== AddEmailResult.NoChange) {
              // trigger alloy check
              await alloyCheck({ variables: { party_id: partyId } });
            }
          } catch (checkinError) {
            // eslint-disable-next-line no-console
            console.error('checkin error', checkinError);
          }
        }

        // Redirect to a protected route or the homepage after successful authentication
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error during authentication:', error);
        navigate('/sign-in');
        // Redirect to an error page or show an error message
      }
    }

    handleAuthCallback();
  }, [addEmailMutation, alloyCheck, navigate]);

  return (
    <GuttersContainer container spacing={2}>
      <Grid item xs={12}>
        <PageWrapper>
          <LoadingScreen />
        </PageWrapper>
      </Grid>
    </GuttersContainer>
  );
}

export default Callback;
