import { localStorage } from '../../app/storageApi';
import { LOGOUT_TIMEOUT, NEW_MEMBER_EXPERIENCE_FEATURE_FLAG_KEY } from '../../app/constants';
import { FeatureFlagsContext } from './FeatureFlagsContext';
import { useCallback, useMemo } from 'react';
import { CURRENT_ENV_NAME, EnvironmentEnum } from '../../app/environments';

function FeatureFlagsProvider({ children }: { children: React.ReactNode }) {
  const areShowingNewMemberExperience = useMemo(() => localStorage.getItem(NEW_MEMBER_EXPERIENCE_FEATURE_FLAG_KEY) === 'true', []);
  const setAreShowingNewMemberExperience = useCallback((newValue: boolean) => {
    localStorage.setItem(NEW_MEMBER_EXPERIENCE_FEATURE_FLAG_KEY, newValue);
  }, []);

  const isProd = EnvironmentEnum.Production === CURRENT_ENV_NAME;
  let logoutTimeout = 15;

  if (!isProd) {
    try {
      const timeout = Number(localStorage.getItem(LOGOUT_TIMEOUT));

      logoutTimeout = isNaN(timeout) || !timeout ? 15 : timeout;
    } catch (error) {
      logoutTimeout = 15;
    }
  }

  return (
    <FeatureFlagsContext.Provider
      value={{
        areShowingNewMemberExperience,
        setAreShowingNewMemberExperience,
        logoutTimeout,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export default FeatureFlagsProvider;
