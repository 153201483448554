import { useState, useCallback } from 'react';
import { CURRENT_ENVIRONMENT } from '../../app/environments';

export type RequestStatus = 'idle' | 'loading' | 'success' | 'error';

export type Response = {
  username: string;
  status?: RequestStatus;
};

interface UsePublicSearch {
  fetchUserName: (email: string, type?: string) => Promise<Response>;
  status: RequestStatus;
}

const usePublicSearch = (): UsePublicSearch => {
  const [status, setStatus] = useState<RequestStatus>('idle');

  const fetchUserName = useCallback(async (email: string, type = 'EMAIL') => {
    setStatus('loading');
    const raw = JSON.stringify({
      type,
      value: email,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: raw,
    };

    try {
      const response = await fetch(`${CURRENT_ENVIRONMENT.PUBLIC_BASE_URL}pingSearch`, requestOptions);
      const jsonData = await response.json();

      return jsonData;
      setStatus('success');
    } catch (err) {
      setStatus('error');
      return { status: 'error' };
    }
  }, []);

  return { fetchUserName, status };
};

export default usePublicSearch;
