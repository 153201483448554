import { useCallback, useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { useGetPracticeFacilityByIdQuery } from '../../app/graphql/_generated/hooks';
import { styles } from './NMEPracticeFacilityDetailsPage.styles';
import PracticeFacilityDetails from '../../components/PracticeFacilityDetails/PracticeFacilityDetails';
import { ModalSpinner } from '@nomi-health-inc/components-ui';
import { extractData, PracticeFacilityDetailsData } from './NMEPracticeFacilityDetailsPage.utils';
import { ROUTES } from '../../constants/routes';
import { Footer } from '../../components/Footer';

function NMEPracticeFacilityDetailsPage() {
  const navigate = useNavigate();
  const { state: navigationState } = useLocation();
  const params = useParams();

  const [practiceFacilityDetails, setPracticeFacilityDetails] = useState<PracticeFacilityDetailsData | null>(null);

  const { loading } = useGetPracticeFacilityByIdQuery({
    variables: {
      practiceFacilityId: params.id || '',
    },
    onCompleted: (data) => {
      setPracticeFacilityDetails(extractData(data));
    },
  });

  const goBackToProviderSearch = useCallback(() => {
    navigate(ROUTES.providerSearch, { state: navigationState });
  }, [navigationState, navigate]);

  return (
    <Box sx={styles.screenContainer}>
      <Header titleText="Care" />
      <Stack direction="row" sx={styles.fullWidthContainer}>
        <IconButton aria-label="back" onClick={goBackToProviderSearch} sx={styles.backButton}>
          <Box component="img" alt="Back" src="/images/back-arrow-icon.svg" />
        </IconButton>
        <Box flexGrow={1} />
      </Stack>
      {!loading && practiceFacilityDetails && <PracticeFacilityDetails isPublicSearch={false} details={practiceFacilityDetails} />}
      <Box flex={1} />
      <Footer />
      <ModalSpinner loading={loading} text="Processing" />
    </Box>
  );
}

export default NMEPracticeFacilityDetailsPage;
