import { Box, IconButton, Stack, Theme, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

export interface PageTitleProps {
  title: string;
  hideBack?: boolean;
  backUrl?: string;
  actions?: Array<JSX.Element>;
}

function PageTitle({ title, backUrl, actions, hideBack = false }: PageTitleProps) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: '0 16px',
        marginBottom: '25px',
      }}
    >
      {!hideBack && (
        <IconButton
          data-id="back-btn"
          onClick={() => (backUrl ? navigate(`${backUrl}`) : navigate(-1))}
          sx={{
            paddingLeft: 0,
            color: (theme: Theme) => theme.palette.custom.textColor,
            fontSize: 'small',
            marginBottom: '10px',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Typography
          data-id="page-title"
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            color: (theme: Theme) => theme.palette.custom.textColor,
          }}
        >
          {title}
        </Typography>
        <Stack direction="row">{actions}</Stack>
      </Stack>
    </Box>
  );
}

export default PageTitle;
