export const styles = {
  wrapper: {
    background: '#ffffff',
    padding: '0 16px',
    height: 'calc(100vh - 250px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {},
  title: {
    fontSize: '32px',
    fontWeight: '600',
    margin: '30px 0 15px',
    textAlign: 'center',
  },
  text: {
    fontSize: '15px',
    fontWeight: '400',
    margin: '0 0 25px 0',
    textAlign: 'center',
  },
  continueBtn: {
    width: '100%',
  },
  formWrap: {
    flex: 1,
    '.MuiFormControl-root': {
      width: '100%',
      marginBottom: 3,
    },
    '.MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: -20,
      marginLeft: 0,
    },
  },
  halfWidth: {
    display: 'flex',
    '> *': {
      width: '50%',
    },
  },
  noteTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#78849E',
    marginTop: '36px',
  },
  noteText: {
    fontSize: '14px',
    marginTop: '-25px',
    marginBottom: '35px',
  },
  card: {
    label: {
      color: '#343D4F',
      fontSize: '.875rem',
      lineHeight: '1.25',
    },
    inputWrap: {
      height: '36px',
      backgroundColor: '#F8F9FD',
      padding: '0 10px',
      borderRadius: '4px',
    },
    field: {
      marginBottom: '24px',
      position: 'relative',
    },
    wrap: {},
    errorText: {
      color: '#D63541',
      fontSize: '12px',
      bottom: '-20px',
      position: 'absolute',
    },
    asyncError: {
      color: '#D63541',
      fontSize: '12px',
      textAlign: 'center',
      marginTop: '12px',
    },
  },
  opacity: {
    '& .MuiSelect-select': {
      opacity: 0.5,
    },
  },
  marginBottom: {
    marginBottom: '24px',
  },
  errorText: {
    color: '#D63541',
    fontSize: '12px',
    marginTop: '16px',
  },
  confirmText: {
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '20px',
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 250px)',
    padding: '0 16px',
  },
};
