import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { nomiLogoSx } from './NomiLogo.styles';

export function NomiLogo({ blockLogo }: { blockLogo?: boolean }) {
  const navigate = useNavigate();

  return (
    <Box
      component="img"
      alt="Nomi Logo"
      src="/images/new-logo.svg"
      sx={nomiLogoSx}
      onClick={() => {
        !blockLogo && navigate('/');
      }}
    />
  );
}
