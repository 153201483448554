import { PING_URL, storage_keys } from '../../app/constants';
import { CURRENT_ENVIRONMENT } from '../../app/environments';
import { getDataFromToken } from '../../app/graphql/auth.utils';
import { authStorage } from '../../app/storageApi';

const handleAuthRedirect = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');

  if (!code || !state) {
    throw new Error('Invalid authentication response');
  }

  const codeVerifier = authStorage.getItem(storage_keys.code_verifier);

  if (!codeVerifier) {
    throw new Error('Missing code verifier');
  }

  await exchangeCodeForToken(code, codeVerifier);
};

const exchangeCodeForToken = async (code: string, codeVerifier: string) => {
  const client_id = sessionStorage.getItem(storage_keys.client_id) || CURRENT_ENVIRONMENT.PING.CLIENT_ID;
  const requestBody = new URLSearchParams();

  requestBody.append('grant_type', 'authorization_code');
  requestBody.append('code', code);
  requestBody.append('redirect_uri', CURRENT_ENVIRONMENT.PING.REDIRECT_URI);
  requestBody.append('client_id', client_id);
  requestBody.append('code_verifier', codeVerifier);

  const response = await fetch(PING_URL.token, {
    method: 'POST',
    body: requestBody.toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const data = await response.json();
  const tokenExpiryTime = Math.floor(Date.now() / 1000) + data.expires_in;

  if (data.access_token && data.id_token) {
    const authDataToStore = {
      [storage_keys.access_token]: data.access_token,
      [storage_keys.id_token]: data.id_token,
      [storage_keys.refresh_token]: data.refresh_token,
      [storage_keys.expires_in]: tokenExpiryTime,
    };

    const { party_id: partyId, email } = getDataFromToken(data.id_token);

    if (partyId) {
      authDataToStore[storage_keys.user_party_id_key] = partyId;
    }
    if (email) {
      authDataToStore[storage_keys.user_email_key] = email;
    }

    authStorage.setItems(authDataToStore);
  }
};

export { handleAuthRedirect };
